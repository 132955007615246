import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import animalsDB from "../../../database/animalsDB";
import groupsDB from "../../../database/groupsDB";
import {
    getSelectedAnimalForDocuments,
    getTechnologyGroupTransfers,
    getTransferEvents,
    makeGetAnimalsInGroup,
} from "../../../selectors/animalDocumentsSelectors";
import { makeGetBuildingsMap } from "../../../selectors/buildingsSelector";
import { getFarmID } from "../../../selectors/farmSelector";
import { getEmployeeList, getServiceList } from "../../../selectors/userSelector";
import { isMobile } from "../../../utils/MobileUtils";
import { checkIfUserHasPrivilegedAccess } from "../../../utils/NewRolesUtils";
import { employeesValueFormatter } from "../../../utils/RaportsUtils";
import { getManageSubgroups } from "../../../utils/SettingsUtils";
import { sortDateStrings } from "../../../utils/SortUtils";
import { formatLocationName } from "../../../utils/global-formatters/formatLocationName";
import TableGrid from "../../basics/table-grid/TableGrid";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import AnimalReferenceDocumentModalGrid from "../../grids/animal-reference-document/AnimalReferenceDocumentModalGrid";
import { GroupClickItem } from "../../grids/group-click-item/GroupClickItem";
import CollapsableContainer from "../containers/CollapsableContainer";
import TransferCounterComponent from "./TransferCounterComponent";
import memoizeOne from "memoize-one";

const makeMapStateToProps = () => {
    const getBuildingsMap = makeGetBuildingsMap();
    const params = memoizeOne({ showDeleted: true });
    const tryFilterTransfers = memoizeOne((transfers,isGroup) => {
        if(isGroup) return transfers.filter(({EvData: {RemovedReason}}) => !RemovedReason)
        return transfers
    })
    const getAnimalsInGroup = makeGetAnimalsInGroup();

    return (state, props) => {
        return {
            transfers: tryFilterTransfers(
                getTransferEvents(state),
                props.isGroup
            ),
            technologyGroupTransfers: getTechnologyGroupTransfers(state),
            buildingsMap: getBuildingsMap(state, params),
            animals: getAnimalsInGroup(state, props),
            employees: getEmployeeList(state),
            service: getServiceList(state),
            farm: getFarmID(state),
            selectedItem: getSelectedAnimalForDocuments(state),
        };
    };
};

class TransferGrid extends React.Component {
    constructor(props) {
        super(props);
        this.isMobile = isMobile();
        this.canManageSubgroups = getManageSubgroups();
    }

    dateValueFormatter = (value) => {
        return value ? moment(value).startOf("day").format("DD.MM.YY") : "";
    };

    locationValueFormatter = (value) => {
        const { buildingsMap } = this.props;
        return formatLocationName(value, { buildingsMap });
    };

    renderNewGroupID(event, field) {
        const {
            location: { search },
        } = this.props;
        if (search.includes(event.EvData[field])) return null;
        let group = groupsDB.findGroupByID(event.EvData[field], {
            showDeleted: true,
        });
        if (!group) return null;
        return (
            <span className="ms-2">
                (
                <AnimalReferenceDocumentModalGrid object={group}>
                    {group.GrNo1}
                </AnimalReferenceDocumentModalGrid>
                )
            </span>
        );
    }

    fromLocationFormatter = (value) => {
        if (value.TechnoGroupName) return this.technologyGroupFormatter(value);
        if (!value.EvData.SrcID) return null;
        return (
            <span>
                {this.locationValueFormatter(value.EvData.SrcID)}
                {this.renderNewGroupID(value, "OldGroupID")}
            </span>
        );
    };

    technologyGroupFormatter = (value) => {
        const { farm } = this.props;
        return <GroupClickItem {...value} farm={farm} />;
    };

    toLocationFormatter = (value) => {
        const {
            t,
            selectedItem: { AnmGrp },
        } = this.props;
        const {
            EvData: { DstID, initial, NewGroupID, GroupTransfer },
        } = value;
        if (initial) {
            let locName = formatLocationName(DstID);
            return `${t("firstAnimalLocation")}: ${locName}`;
        }
        if (!value.EvData.DstID) return null;
        if (value.EvData.VirtualLocation) return t("locationOfRemoved");
        let groupKey = null;
        if (NewGroupID) groupKey = "NewGroupID";
        else if (GroupTransfer !== AnmGrp) groupKey = "GroupTransfer";
        return (
            <span>
                {this.locationValueFormatter(value.EvData.DstID)}
                {this.renderNewGroupID(value, groupKey)}
            </span>
        );
    };

    employeesValueFormatter = (value) => {
        const { employees, service } = this.props;
        return employeesValueFormatter(value, employees, service);
    };

    animalFormatter = (AnmID) => {
        const { animals, isGroup } = this.props;
        const animal =
            animals.find((a) => a.AnmID === AnmID) ||
            animalsDB.getAnimalById(AnmID, {
                joinEvents: false,
                findDeleted: true,
            });
        if (!isGroup) return animal?.AnmNo1 || "";
        if (animal?.RFID || animal?.Tagged || this.canManageSubgroups) {
            return (
                <AnimalReferenceDocumentModalGrid
                    value={animal.AnmNo1}
                    object={animal}
                />
            );
        }
        return "";
    };

    animalLocationValueFormatter = (AnmID) => {
        const { animals } = this.props;
        const animal =
            animals.find((a) => a.AnmID === AnmID) ||
            animalsDB.getAnimalById(AnmID, {
                joinEvents: false,
                findDeleted: true,
            });
        return formatLocationName(animal?.DelPlcmntID || animal?.PlcmntID);
    };

    isIndividualAnimal = (animal) =>
        animal.hasOwnProperty("RFID") || animal.Tagged;

    hasIndividualAnimals = () => {
        const { transfers, animals } = this.props;
        const _animals = animals.filter(
            (animal) =>
                this.isIndividualAnimal(animal) &&
                transfers.some((e) => e.AnmID === animal.AnmID)
        );
        return _animals.length > 0;
    };

    isDefaultExpanded = () => {
        const { transfers = [] } = this.props;
        return transfers.length > 0;
    };

    render() {
        const { t, transfers, technologyGroupTransfers, isGroup, selectedItem } =
            this.props;
        const hasIndividualAnimals = this.hasIndividualAnimals();
        const events = [...transfers, ...technologyGroupTransfers].sort(
            (a, b) => b.EvTime - a.EvTime
        );
        const headers = [
            isGroup &&
            (hasIndividualAnimals || this.canManageSubgroups) && {
                name: t("animalNumber"),
                field: "AnmID",
                valueFormatter: this.animalFormatter,
            },
            {
                name: t("date"),
                field: "EvTime",
                valueFormatter: this.dateValueFormatter,
                customSort: sortDateStrings,
                _mobileDate: true,
            },
            isGroup && {
                name: t("amount"),
                field: "AnmCnt",
                _mobileHide: true,
                component: (props) => (
                    <TransferCounterComponent
                        {...props}
                        selectedItem={selectedItem}
                    />
                ),
                disableValueFormatterFilter: true,
                disableValueFormatterSort: true,
            },
            {
                name: t("from"),
                valueFormatter: this.fromLocationFormatter,
                colWidth: 4,
            },
            {
                name: t("to"),
                valueFormatter: this.toLocationFormatter,
                colWidth: 4,
            },
            {
                name: t("eventGrid.operator"),
                field: "EvData.OperID",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
                colWidth: 2,
            },
        ].filter((o) => !!o);
        return (
            <>
                <CollapsableContainer.Card
                    id="cy-transfer-card"
                    header={t("animalDocuments.transfers")}
                    defaultExpanded={this.isDefaultExpanded()}>
                    <TableGrid
                        showPagination={!this.mobile}
                        paginationItems={10}
                        scrollOnPageChange={false}
                        data={events}
                        headers={headers}
                        mobileRow={<DefaultMobileRow />}
                        rowClassName={this.rowClassNameFormatter}
                    />
                </CollapsableContainer.Card>
            </>
        );
    }
}

TransferGrid.defaultProps = {
    isGroup: false,
};

TransferGrid.propTypes = {
    isGroup: PropTypes.bool,
};

export default compose(
    withTranslation(),
    connect(makeMapStateToProps),
    withRouter
)(TransferGrid);
