import React from "react";
import AnimalDocumentsComments from "../comments/AnimalDocumentsComments";
import FallGrid from "../fall/FallGrid";
import GraftingProgramGridCard from "../grafting/GraftingProgramGridCard";
import SellGrid from "../sell/SellGrid";
import TransferGrid from "../transfer/TransferGrid";
import WeightingSingleChart from "../weighting/WeightingSingleChart";
import AnimalModificationList from "../animal-modification-list/AnimalModificationList";

export default function PorkerPigletCard() {
    return (
        <>
            <WeightingSingleChart/>
            <TransferGrid getDeletedAnimals/>
            <SellGrid/>
            <FallGrid/>
            <AnimalDocumentsComments/>
            <GraftingProgramGridCard/>
            <AnimalModificationList/>
        </>
    );
}
