import { NameSpaces } from "@wesstron/utils/Api/constants/nameSpaces";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import animalsDB from "../../../database/animalsDB";
import {
    getReclassifyList,
    makeGetAnimalsInGroup,
} from "../../../selectors/animalDocumentsSelectors";
import { getEmployeeList, getServiceList } from "../../../selectors/userSelector";
import { dateFormatter } from "../../../utils/AnimalDocumentsUtils";
import { isMobile } from "../../../utils/MobileUtils";
import { checkIfUserHasPrivilegedAccess } from "../../../utils/NewRolesUtils";
import { employeesValueFormatter } from "../../../utils/RaportsUtils";
import { sortAsString, sortDateStrings } from "../../../utils/SortUtils";
import { formatLocationName } from "../../../utils/global-formatters/formatLocationName";
import TableGrid from "../../basics/table-grid/TableGrid";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import CollapsableContainer from "../containers/CollapsableContainer";

function makeMapStateToProps() {
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    return (state) => ({
        employees: getEmployeeList(state),
        service: getServiceList(state),
        animals: getAnimalsInGroup(state),
        reclassifyEvents: getReclassifyList(state)
    });
}

class ReclassifyGrid extends React.Component {
    constructor(props) {
        super(props);
        this.showOperator = checkIfUserHasPrivilegedAccess();
        this.mobile = isMobile();
    }

    employeesValueFormatter = (value) => {
        const { employees, service } = this.props;
        return employeesValueFormatter(value, employees, service);
    };

    locationValueFormatter = (AnmID) => {
        const { animals } = this.props;
        const animal =
            animals.find((a) => a.AnmID === AnmID) ||
            animalsDB.getAnimalById(AnmID, {
                joinEvents: false,
                findDeleted: true,
            });
        return formatLocationName(animal?.PlcmntID);
    };

    render() {
        const { t, reclassifyEvents } = this.props;
        const headers = [
            {
                name: t("location"),
                field: "AnmID",
                valueFormatter: this.locationValueFormatter,
            },
            {
                name: t("rfidApp.newAnimalNumber"),
                field: "AnmNo1",
                customSort: sortAsString,
            },
            {
                name: t("date"),
                field: "EvTime",
                valueFormatter: dateFormatter,
                customSort: sortDateStrings,
                _mobileDate: true,
            },
            {
                name: t("eventGrid.operator"),
                field: "EvData.OperID",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: this.showOperator,
                colWidth: 2,
            },
            {
                name: t("comment"),
                field: "Comment",
            },
        ];
        return (
            <>
                <CollapsableContainer.Card
                    id="cy-reclassify-card"
                    header={t("animalDocuments.reclassifyGrid")}
                    defaultExpanded={reclassifyEvents.length > 0}>
                    <TableGrid
                        data={reclassifyEvents}
                        headers={headers}
                        mobileRow={<DefaultMobileRow />}
                        showPagination={!this.mobile}
                        paginationItems={10}
                        scrollOnPageChange={false}
                    />
                </CollapsableContainer.Card>
            </>
        );
    }
}

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect(makeMapStateToProps)
)(ReclassifyGrid);
