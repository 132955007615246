import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get, isArray, isEqual } from "lodash";
import "./_text-switch.scss";
import TextSwitchItem from "./TextSwitchItem";

class TextSwitch extends PureComponent {
    constructor(props) {
        super(props);
        this.state = TextSwitch.getValues(props)
    }

    static getValues = (props) => {
        const options = isArray(props.options) ? props.options : [];
        return {
            options: options,
            value: options.findIndex(option => isEqual(props.value, option.value)) === -1 ? get(props.options[0], "value") : props.value
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const newValues = TextSwitch.getValues(nextProps);
        if (!isEqual(prevState, newValues)) {
            return newValues;
        }
        return null;
    }


    render() {
        const { value, options } = this.state;
        console.log(this.state, this.props);
        const { onChange, className, disabled, label } = this.props;
        const selectedIndex = options.findIndex(option => isEqual(value, option.value));
        return (
            <React.Fragment>
                {
                    label && <label>{label}</label>
                }
                <div
                    className={`fetura-text-switch size-${options.length} ${disabled ? "disabled" : ""} pos-${selectedIndex}${className ? ` ${className}` : ""}`}>
                    {
                        options.map((option, index) => (
                            <TextSwitchItem key={`opt_${index}`} onChange={disabled ? null : onChange} selected={selectedIndex === index}
                                value={option.value} name={option.name} title={option.title}
                                disabled={option.disabled} />
                        ))
                    }
                </div>
            </React.Fragment>
        );
    }
}

TextSwitch.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
        disabled: PropTypes.bool
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

TextSwitch.defaultProps = {
    options: [],
    disabled: false,
    className: "mb-3"
};

export default TextSwitch;
