import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {isMobile} from "../utils/MobileUtils";
import {makeGetBuildingsMap} from "../selectors/buildingsSelector";
import {useSelector} from "react-redux";
import parametersDB from "../database/parametersDB";
import {getUtilResults} from "../utils/results/GeneralResultsUtils";
import {uniq} from "lodash";
import eventsDB from "../database/eventsDB";
import animalsDB from "../database/animalsDB";
import useLocalStorage from "./useLocalStorage";

export default function useCalculateGroupParams(group) {
    const [loading, setLoading] = useState(isMobile() ? true : false);

    const getBuildingsMapParams = useRef({showDeleted: true});
    const getBuildingsMap = useMemo(makeGetBuildingsMap, []);

    const buildingsMap = useSelector((state) =>
        getBuildingsMap(state, getBuildingsMapParams.current)
    );

    const onMessage = useCallback(() => {
        console.error("onMessage");
        setLoading(false);
    }, []);

    const [fullParametersCalc] = useLocalStorage(
        "fullParametersCalc",
        false,
        false
    );

    useEffect(() => {
        if (!group || !isMobile() || fullParametersCalc) return;

        setLoading(true);
        const events = [];
        const animals = [];
        for (let AnmID of uniq([...group.AnmIDs, ...group.Rmvd])) {
            const animal = animalsDB.getAnimalById(AnmID, {
                joinEvents: false,
                findDeleted: true,
            });
            animals.push(animal);
            const animalEvents = eventsDB.getAllEvents4Animal(AnmID);
            events.push(...animalEvents);
        }
        parametersDB.sendMessageToWorker(
            "CALCULATE_GROUP",
            {
                group,
                events,
                animals,
                utilResults: getUtilResults(),
                buildingsMap: Array.from(buildingsMap),
            },
            onMessage
        );
    }, [group, onMessage, fullParametersCalc]); // eslint-disable-line react-hooks/exhaustive-deps

    return loading;
}
