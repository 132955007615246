import {getLocationPathByPlcmntID} from "./RaportsUtils";
import moment from "moment";
import {isBoolean} from "lodash";

/**
 * Metoda sortująca wartości jako liczby
 * @param a             obiekt 1
 * @param b             obiekt 2
 * @param header        header
 * @param sortType      typ sortowania
 * @param value1        wartość 1
 * @param value2        wartość 2
 * @return {number}
 */

export function sortAsNumber(a, b, header, sortType, value1, value2) {
    let val1Number = +value1, val2Number = +value2;
    if (!isNaN(val1Number) && !isNaN(val2Number)) {
        return val1Number - val2Number;
    }
    if (sortType === "asc" && isNaN(val1Number)) return 1;
    if (sortType === "desc" && isNaN(val2Number)) return 1;
    return -1;
}

export function sortDateStrings(a, b, header, sortType, value1, value2, dateFormat) {
    let val1 = dateFormat ? moment(value1, dateFormat) : moment(value1), val2 = dateFormat ? moment(value2, dateFormat) : moment(value2);
    if (val1.isValid() && val2.isValid()) {
        return val1.toDate().getTime() - val2.toDate().getTime();
    }
    if (sortType === "asc" && !val1.isValid()) return 1;
    if (sortType === "desc" && !val2.isValid()) return 1;
}
export function datesRangeSort(a, b, header, sortType, value1, value2, dateFormat) { //Sortowanie objektów z startDate oraz endDate
    let val1 = dateFormat ? moment(value1?.startDate, dateFormat) : moment(value1?.startDate), val2 = dateFormat ? moment(value2?.startDate, dateFormat) : moment(value2?.startDate);
    if (val1.isValid() && val2.isValid()) {
        let rest = val1.toDate().getTime() - val2.toDate().getTime()
        if(rest === 0){
            const endDateA = dateFormat ? moment(value1?.endDate, dateFormat) : moment(value1?.endDate)
            const endDateB = dateFormat ? moment(value2?.endDate, dateFormat) : moment(value2?.endDate)
            return endDateA.toDate().getTime() - endDateB.toDate().getTime()
        }
        else
        return val1.toDate().getTime() - val2.toDate().getTime();
    }
    if (sortType === "asc" && !val1.isValid()) return 1;
    if (sortType === "desc" && !val2.isValid()) return 1;
}
/**
 * Funkcja sortująca wartości jako lokalizacje uwzględniając w nazwie również liczby.
 * @param a
 * @param b
 * @param header
 * @param sortType
 * @param value1
 * @param value2
 * @returns {number}
 */

export function sortAsLocation(a, b, header, sortType, value1, value2) {
    let firstLocationName = null;
    let secondLocationName = null;

    if (value1) firstLocationName = getLocationPathByPlcmntID(Array.isArray(value1[0].PlcmntID) ? value1[0].PlcmntID : value1);
    if (value2) secondLocationName = getLocationPathByPlcmntID(Array.isArray(value2[0].PlcmntID) ? value2[0].PlcmntID : value2);

    if (firstLocationName && secondLocationName) {
        return firstLocationName.localeCompare(secondLocationName, undefined, {numeric: true, sensitivity: 'variant'});
    }
    if (sortType === "asc" && !firstLocationName) return 1;
    if (sortType === "desc" && !secondLocationName) return 1;
    return -1;
}

/**
 * Funkcja sortująca wartości jako stringi uwzględniająca również liczby.
 * @param a
 * @param b
 * @param header
 * @param sortType
 * @param value1
 * @param value2
 * @returns {number}
 */

export function sortAsString(a, b, header, sortType, value1, value2) {
    if (value1 && value2 && typeof value1 === "string" && typeof value2 === "string") {
        return value1.localeCompare(value2, undefined, {numeric: true, sensitivity: 'base'});
    }
    if (sortType === "asc" && !value1) return 1;
    if (sortType === "desc" && !value2) return 1;
    return -1;
}

export function sortAsBoolean(a, b, header, sortType, value1, value2) {
    if (isBoolean(value1) && isBoolean(value2)) {
        return value1 === value2 ? 0 : value1 ? -1 : 1;
    }
    if (sortType === "asc" && !isBoolean(value1)) return 1;
    if (sortType === "desc" && !isBoolean(value2)) return 1;
    return -1;
}
