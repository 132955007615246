import {
    editFallNotificationFailure,
    editFallNotificationSuccess,
    editSellOrFall
} from "../../../api/events/EditSellOrFall";
import {EventTypes} from "../../../constans/eventTypes";
import {editSaleDataNotificationFailure, editSaleDataNotificationSuccess} from "../../../api/sale/editSaleData";
import salesDB from "../../../database/salesDB";
import {handleResponseToSubmissionError} from "../../../utils/response/ResponseTranslator";

export function submit(values, dispatch, props) {
    const {group} = props;
    const {event, _Comment} = values;
    return editSellOrFall({
        EvID: event.EvID,
        NewAnmCnt: +event.AnmCnt,
        AnmGrp: group.AnmGrp,
        Weight: +event.EvData.Weight,
        Reason: event.EvData.Reasn,
        EvTime: event.EvTime,
        Comment: _Comment
    }).then((res) => {
        if (event.EvCode === EventTypes.SELL) {
            editSaleDataNotificationSuccess(res);
        } else {
            editFallNotificationSuccess(res);
        }
        props.handleHide();
    }).catch((e) => {
        if (event.EvCode === EventTypes.SELL) {
            editSaleDataNotificationFailure(e);
        } else {
            editFallNotificationFailure(e);
        }
        handleResponseToSubmissionError(e);
    })
}

export function validate(values, props) {
    const {t} = props;
    const errors = {event: {EvData: {}}};
    if (values.event) {
        if (!values.event.AnmCnt) {
            errors.event.AnmCnt = t("required");
        } else if (values.event.AnmCnt <= 0) {
            errors.event.AnmCnt = t("errors.lessVal", {count: 1});
        } else if (values.animal.AnmCnt + props.event.AnmCnt < values.event.AnmCnt) {
            errors.event.AnmCnt = t("errors.greaterThan", {number: values.animal.AnmCnt + props.event.AnmCnt});
        }
        if (values.event.EvCode === EventTypes.FALL && !values.event.EvData.Reasn) {
            errors.event.EvData.Reasn = t("required");
        }
        if (values.event.EvData.SaleID) {
            let sale = salesDB.getSaleByID(values.event.EvData.SaleID);
            if (sale && props.event.AnmCnt + sale.AnmCnt < values.event.AnmCnt) {
                errors.event.AnmCnt = t("errors.greaterThan", {number: props.event.AnmCnt + sale.AnmCnt});
            }
        }
        if (values.event.EvData.Weight <= 0) {
            errors.event.EvData.Weight = t("errors.noLessOrEq0");
        }
    }
    return errors;
}
