import { get, isArray } from "lodash";
import { NRFTypes } from "../constans/devices";
import DevTypes from "@wesstron/utils/Api/constants/devTypes";

export function hasAntennaStatus(device) {
    return get(device, "Settings.Antenna.Type") === 2
}

export function hasTemperatureSensor(device) {
    return !!get(device, "Settings.Temperature.Sensor", false);
}

export function hasWater(device) {
    return !!get(device, "Settings.Water.Enabled", false);
}

export function hasFlowMeter(device) {
    return !!get(device, "Settings.Water.FlowMeter", false);
}

export function isIndividualTemperature(device) {
    return !!get(device, "Settings.Temperature.Individual", false);
}

export function isNutriPro(device) {
    return device.Settings?.Type === NRFTypes.NUTRI_PRO;
}

export function isNutriProV2(device) {
    return device.Settings?.Type === NRFTypes.NUTRI_PRO_V2;
}

export function isRFID(device) {
    return device.Settings?.Type === NRFTypes.G5;
}

export function isIPSUM(device) {
    return device.Settings?.Type === NRFTypes.IPSUM;
}

export function isRegularFeeding(device) {
    if (device.DevType === DevTypes.DISPENSER) return true;
    return (device.Settings?.Type ?? NRFTypes.F3_F5) === NRFTypes.F3_F5;
}

export function isInFeedingTab(device) {
    return isRegularFeeding(device) || isRFID(device);
}

export function getLinkedAnimalIDs(device) {
    const animalIds = [];
    if (isArray(device.PlcmntID)) {
        for (let { AnmIDs } of device.PlcmntID) {
            if (isArray(AnmIDs)) {
                animalIds.push(...AnmIDs)
            }
        }
    }
    return [...new Set(animalIds)];
}

export function hasTrapSensor(device) {
    return !!get(device, "Settings.TrapSensor");
}