import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import animalsDB from "../../../database/animalsDB";
import groupsDB from "../../../database/groupsDB";
import {
    getRemovedEvents,
    getSelectedAnimalForDocuments,
    makeGetAnimalsInGroup,
} from "../../../selectors/animalDocumentsSelectors";
import { makeGetBuildingsMap } from "../../../selectors/buildingsSelector";
import { getFarmID } from "../../../selectors/farmSelector";
import { getEmployeeList, getServiceList } from "../../../selectors/userSelector";
import { isMobile } from "../../../utils/MobileUtils";
import { checkIfUserHasPrivilegedAccess } from "../../../utils/NewRolesUtils";
import { employeesValueFormatter } from "../../../utils/RaportsUtils";
import { sortDateStrings } from "../../../utils/SortUtils";
import { formatLocationName } from "../../../utils/global-formatters/formatLocationName";
import TableGrid from "../../basics/table-grid/TableGrid";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import AnimalReferenceDocumentModalGrid from "../../grids/animal-reference-document/AnimalReferenceDocumentModalGrid";
import CollapsableContainer from "../containers/CollapsableContainer";

const makeMapStateToProps = () => {
    const getBuildingsMap = makeGetBuildingsMap();
    const params = { showDeleted: true };
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    return (state, props) => {
        return {
            transfers: getRemovedEvents(state),
            buildingsMap: getBuildingsMap(state, params),
            animals: getAnimalsInGroup(state, props),
            employees: getEmployeeList(state),
            service: getServiceList(state),
            farm: getFarmID(state),
            selectedItem: getSelectedAnimalForDocuments(state),
        };
    };
};

class RemovedCounter extends React.Component {
    constructor(props) {
        super(props);
        this.isMobile = isMobile();
    }

    dateValueFormatter = (value) => {
        return value ? moment(value).startOf("day").format("DD.MM.YY") : "";
    };

    locationValueFormatter = (value) => {
        const { buildingsMap } = this.props;
        return formatLocationName(value, { buildingsMap });
    };

    renderNewGroupID(event, field) {
        const {
            location: { search },
        } = this.props;
        if (search.includes(event.EvData[field])) return null;
        let group = groupsDB.findGroupByID(event.EvData[field], {
            showDeleted: true,
        });
        if (!group) return null;
        return (
            <span className="ms-2">
                (
                <AnimalReferenceDocumentModalGrid object={group}>
                    {group.GrNo1}
                </AnimalReferenceDocumentModalGrid>
                )
            </span>
        );
    }

    employeesValueFormatter = (value) => {
        const { employees, service } = this.props;
        return employeesValueFormatter(value, employees, service);
    };
    removedReasonFormatter = (value) => {
        const { t } = this.props;
        return t(`eventTypes.${value}`)
    };

    animalFormatter = (AnmID) => {
        const { animals } = this.props;
        const animal =
            animals.find((a) => a.AnmID === AnmID) ||
            animalsDB.getAnimalById(AnmID, {
                joinEvents: false,
                findDeleted: true,
            });
        if (animal) {
            return (
                <AnimalReferenceDocumentModalGrid
                    value={animal.AnmNo1}
                    object={animal}
                />
            );
        }
        return "";
    };


    isIndividualAnimal = (animal) =>
        animal.hasOwnProperty("RFID") || animal.Tagged;

    hasIndividualAnimals = () => {
        const { transfers, animals } = this.props;
        const _animals = animals.filter(
            (animal) =>
                this.isIndividualAnimal(animal) &&
                transfers.some((e) => e.AnmID === animal.AnmID)
        );
        return _animals.length > 0;
    };

    isDefaultExpanded = () => {
        const { transfers = [] } = this.props;
        return transfers.length > 0;
    };

    render() {
        const { t, transfers } =
            this.props;
        const headers = [
            {
                name: t("animalNumber"),
                field: "AnmID",
                valueFormatter: this.animalFormatter,
            },
            {
                name: t("date"),
                field: "EvTime",
                valueFormatter: this.dateValueFormatter,
                customSort: sortDateStrings,
                _mobileDate: true,
            },
            {
                name: t("reason"),
                field: "Reason",
                valueFormatter: this.removedReasonFormatter,
                colWidth: 2,
            },
            {
                name: t("eventGrid.operator"),
                field: "OperID",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
            },
            
        ].filter((o) => !!o);
        return (
            <>
                <CollapsableContainer.Card
                    id="cy-removed-card"
                    header={t("animalDocuments.removedCounter")}
                    defaultExpanded={this.isDefaultExpanded()}>
                    <TableGrid
                        showPagination={!this.mobile}
                        paginationItems={10}
                        scrollOnPageChange={false}
                        data={transfers}
                        headers={headers}
                        mobileRow={<DefaultMobileRow />}
                        rowClassName={this.rowClassNameFormatter}
                    />
                </CollapsableContainer.Card>
            </>
        );
    }
}

export default compose(
    withTranslation(),
    connect(makeMapStateToProps),
    withRouter
)(RemovedCounter);
