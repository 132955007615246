export function setGlobalLoading(state, text = null, translate =false) {
    return function (dispatch) {
        dispatch({
            type: "SET_GLOBAL_LOADING",
            payload: !!state,
            meta: {
                text,
                translate
            }
        })
    }
}

export function setGlobalLoadingText(text = null, translate = false) {
    return function (dispatch) {
        dispatch({
            type: "SET_GLOBAL_LOADING_TEXT",
            payload: {text, translate}
        })
    }
}
