import dictionariesDB from "../database/dictionariesDB";
import { invokeApig } from "../libs/awsLib";
import Paths from "../api/paths";

let promises = [];

export function getAllDictionaries() {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_DICTIONARIES",
            payload: dictionariesDB.getAllDictionaries()
        });
    }
}

export function listDictionariesDynamoDB(clientID, forceFetch = false) {
    return function (dispatch) {
        dispatch({
            type: "LIST_DICTIONARY", payload: invokeApig({
                ...Paths.listDictionaries({ clientID: clientID }),
                queryParams: { DtaModTime: dictionariesDB.getModificationTime(clientID).DtaModTime },
                forceFetch
            })
        }).then(async res => {
            let l = {};
            Object.assign(l, res);
            if (res.value.length > 0) {
                let tmp = [];
                Object.assign(tmp, res.value);
                await tmp.forEach(item => promises.push(item));
                dictionariesDB.insertIntoDictionaries(promises);
                promises.length = 0;
            }
            // dispatch(getAllDictionaries());
        }).catch(err => {
            // dispatch(getAllDictionaries());
            console.error(err);
        });
    }
}

export function updateControlList(list) {
    return function (dispatch) {
        dispatch({
            type: "UPDATE_CONTROL_LIST",
            payload: list
        })
    }
}

export function starControlList(WordID, LocalUserID) {
    return function (dispatch) {
        dispatch({
            type: "STAR_CONTROL_LIST",
            payload: { LocalUserID },
            meta: { WordID }
        })
    }
}