import React from "react";
import PropTypes from "prop-types";
import {findIndex, isFunction} from "lodash";
import Button from "../../button/Button";
import memoizeOne from "memoize-one";
import MenuItem from "../../menu/MenuItem";
import Menu from "../../menu/Menu";
import {Col, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";

class TableMobileHeaders extends React.Component {

    state = {
        event: null
    }

    onClick = () => {
        const {onSortChange, header, isSortable} = this.props;
        if (isSortable && !header.notSortable) {
            onSortChange(header);
        }
    };

    onMenuOpen = (event) => {
        this.setState({
            event
        })
    }

    isDisabledSort = (header) => !(header._mobileHide || header.notSortable || !!header.headerComponent || !!header.disabledSort || !header.name)

    checkIfHasEnabledSorting = memoizeOne((headers, isSortable) => {
        return isSortable && headers.some((item) => this.isDisabledSort(item))
    })

    getHeaderSortedItem = (header) => {
        const {sortArray} = this.props;
        const {name} = header;
        const itemIndex = findIndex(sortArray, (s) => s.name === name);
        return {
            sortItem: sortArray[itemIndex],
            sortIndex: itemIndex + 1
        }
    };

    render() {
        const {selectedAll, headers, onSelectAll, isSortable, onSortChange, t, sortArray} = this.props;
        const {event} = this.state;
        const showSelectAll = isFunction(onSelectAll);
        const showSorting = this.checkIfHasEnabledSorting(headers, isSortable);
        return (
            <div className={"table-grid-mobile-headers"}>
                <Row>
                    {
                        showSelectAll &&
                        <Col xs={showSorting ? 6 : 12}>
                            <Button
                                type={"button"}
                                className={"w-100"}
                                onClick={() => onSelectAll(!selectedAll)}
                                icon={<i className={`fa-fw ${selectedAll ? "far fa-square" : "fas fa-check-square"}`}/>}
                            >
                                {selectedAll ? t("deviceRows.feeding.dispenserChamber.unselect") : t("deviceRows.feeding.dispenserChamber.select")}
                            </Button>

                        </Col>

                    }
                    {
                        showSorting &&
                        <Col xs={showSelectAll ? 6 : 12}>
                            <Button
                                className={"w-100"}
                                type={"button"}
                                onClick={this.onMenuOpen}
                            >
                                {
                                    `${t("farms.tabs.inventoryTab.sortBy")} ${sortArray.length ? `(${sortArray.length})` : ""}`
                                }
                            </Button>
                        </Col>
                    }
                </Row>
                {
                    showSorting &&
                    <Menu closeCallback={this.onMenuOpen} event={event}>
                        {
                            headers.map((header, index) => {
                                const isDisabledSort = this.isDisabledSort(header);
                                if (isDisabledSort) {
                                    const {sortItem, sortIndex} = this.getHeaderSortedItem(header);
                                    return <MenuItem
                                        selected={isSortable && sortIndex !== 0 && sortItem.name === header.name && sortItem.sortType !== "off"}
                                        icon={isSortable && sortIndex !== 0 && sortItem.name === header.name && sortItem.sortType !== "off" ? sortItem.sortType === "asc" ?
                                            <i className="fas fa-sort-amount-up"/> :
                                            <i className="fas fa-sort-amount-down"/> : null} key={index}
                                        onClick={() => onSortChange(header)}>
                                        {header.name}
                                    </MenuItem>
                                }
                                return null;
                            })
                        }
                    </Menu>
                }
            </div>
        );
    }

}

TableMobileHeaders.propTypes = {
    headers: PropTypes.array.isRequired,
    onSelectAll: PropTypes.func
};

TableMobileHeaders.defaultProps = {};

export default withTranslation()(TableMobileHeaders);
