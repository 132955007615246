import cunits from "convert-units";


class Converter {
    constructor(value) {
        this._value = value;
    }

    from(from = "") {
        this._from = from.split("/");
        if (this._from.length !== 2) {
            throw Error(`[Converter] "from" expected value with single "/" but got ${this._from.length}`);
        }
        return this;
    }

    to(to = "") {
        this._to = to.split("/");
        if (this._to.length !== 2) {
            throw Error(`[Converter] "to" expected value with single "/" but got ${this._to.length}`);
        }
        return this.convert();
    }


    convert() {
        if (!this._from || !this._to) {
            throw Error("[Converter] use \"from\" and \"to\" before using!");
        }
        let upperValue = cunits(this._value).from(this._from[0]).to(this._to[0]);
        let bottomValue = cunits(1).from(this._from[1]).to(this._to[1]);
        return upperValue / bottomValue;
    }

}

export const convert = (value) => {
    return new Converter(value);
}

export default convert;
