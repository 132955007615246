import { getUserApiData } from "../../utils/UserUtils";
import { invokeApig } from "../../libs/awsLib";
import Paths from "../paths";
import { myID } from "../../libs/generateID";
import store from "../../store/store";
import { notify } from "reapop";
import i18n from "../../i18n";
import { createErrorNotification, createSuccessNotification } from "../../utils/ResponseUtils";

/**
 * @typedef SeparationToMommyData
 * @type {object}
 * @property AnmID {string}     ID zwierzęcia
 * @property EvTime {number}    Czas wykonania zdarzenia
 * @property Piglets {number}   Liczba prosiąt zabranych
 * @property GrID {string}      ID grupy, do której należało zwierzę
 * @property Comment {string}   Komentarz
 * @property Weight {number}    Waga zabranych prosiąt
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego odsadzenia do mamki
 * @param data {[SeparationToMommyData]}    Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createSeparationToMommy(data, { FarmID, ClientID, LocalUserID } = {}) {
    const { _FarmID, _LocalUserID, _ClientID } = getUserApiData({ FarmID, ClientID, LocalUserID });
    return invokeApig({
        ...Paths.createSeparationToMommy({ farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID }),
        body: data
    })
}

/**
 * Metoda wysyła zapytanie o stworzenie nowego odsadzenia do mamki z uwzględnieniem TYLKO wytatuowanych prosiąt
 * @param data
 * @param FarmID
 * @param ClientID
 * @param LocalUserID
 * @returns {Promise<string|undefined|any>}
 */
export function createSeparationToMommyGilts(data, { FarmID, ClientID, LocalUserID } = {}) {
    const { _FarmID, _LocalUserID, _ClientID } = getUserApiData({ FarmID, ClientID, LocalUserID });
    return invokeApig({
        ...Paths.createSeparationToMommyGilts({ farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID }),
        body: data
    });
}

export function createSeparationToMommyNotificationSuccess(response, { uniqueId = myID() } = {}) {
    if (createSuccessNotification(response, "apiNotifications.createMommyTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createMommyTitle"),
        message: i18n.t("apiNotifications.createMommySuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createSeparationToMommyNotificationFailure(response, { uniqueId = myID() } = {}) {
    if (createErrorNotification(response, "apiNotifications.createMommyTitle", { uniqueId })) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createMommyTitle"),
        message: i18n.t("apiNotifications.createMommyFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
