import { getUnit } from "../utils/UnitUtils";
import { UnitTypes } from "./unitTypes";

export const ResultGridUnitTypes = {
    PIECES: "pcs",
    PERCENT: "%",
    DAYS: "days",
    AVG_DAYS: "planTypes.avgDays",
    AVG: "avgShort",
    WEIGHT: getUnit("weight", UnitTypes.MEDIUM),
    BIG_WEIGHT: getUnit("weight", UnitTypes.BIG),
    CURRENCY: "CURRENCY"
};

export const ResultsGridUnitTypesV2 = {
    PIECES: "pcs",
    PERCENT: "%",
    DAYS: "days",
    AVG_DAYS: "planTypes.avgDays",
    AVG: "avgShort",
    WEIGHT: "WEIGHT_MEDIUM",
    BIG_WEIGHT: "WEIGHT_BIG",
    CURRENCY: "CURRENCY"
};