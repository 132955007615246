import {useSelector} from "react-redux";
import useReferenceLineCreator from "../../../hooks/useReferenceLineCreator";
import { getGroupBirthTime } from "../../../selectors/animalDocumentsSelectors";
import { useRef } from "react";
export default function useReferenceLines(domain, yAxisId) {

    const props = useRef({ getDeletedAnimals: true })
    const birthTime = useSelector((state) => getGroupBirthTime(state,props.current));

    return useReferenceLineCreator(birthTime, domain, yAxisId);

}