import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {isMobile as isMobileFunc} from "../../../utils/MobileUtils";
import {get, isEqual} from "lodash";
import {filterOptions} from "./utils";
import Input from "../input/Input";
import ReactDOM from "react-dom";
import Tooltip from "../tooltip/Tooltip";
import OptionListMobile from "./OptionListMobile";
import OptionListDesktop from "./OptionListDesktop";
import {catchify} from "../../../utils/Utils";
import {isString} from "lodash";
import "./_select.scss";
import {isCypress} from "../../../utils/CypressUtils";

let debug = () => {
};

if (isCypress()) {
    debug = (text, ...args) => {
        if (isString(text)) return console.log(`[SELECT_V2] ${text}`, ...args)
        return console.log("[SELECT_V2] ", text, ...args);

    }
}

const Select = ({
                    disabled,
                    initialSearch,
                    mobile,
                    onFocus,
                    onSearchChange,
                    onChange,
                    disableFilter,
                    options: _options,
                    filteredOptions,
                    customRenderer,
                    error,
                    warning,
                    showIconOnErrorOnWarning,
                    value,
                    id,
                    stopPropagationOnEnter,
                    autofocus,
                    autocomplete,
                    renderInPortal,
                    clearButton,
                    placeholder,
                    insertFirstOnNoSelected,
                    onBlur,
                    callOnChangeOnSingleOption

                }) => {

    const [inputBBox, setInputBBox] = useState({});

    const selectContainerRef = useRef();
    const selectRef = useRef();
    const caretRef = useRef();

    const [filterText, setFilterText] = useState(initialSearch || "");
    const [hovered, setHovered] = useState(null);
    const [isOpened, setOpened] = useState(false);

    // gets input HTML element
    const getInputNode = useCallback(() => {
        if (selectRef.current) {
            return selectRef.current.getElementsByClassName("fetura-input")[0].getElementsByTagName("input")[0];
        }
        return null;
    }, [])

    // isMobile local variable:
    // most of the time:
    // default isMobile() from utils is used
    // rarely:
    // user specified value is used only if mobile has a direct match `false`|`true`
    const isMobile = useMemo(() => {
        if ([false, true].includes(mobile)) return mobile;
        return isMobileFunc();
    }, [mobile]);

    const handleCaretClick = useCallback((e) => {
        debug("onCaretClick isOpened=%s", isOpened)
        if (isOpened) {
            setOpened(false);
            e.preventDefault();
            e.stopPropagation();
        } else {
            catchify(() => {
                const input = getInputNode();
                if (input) input.focus();
            })();
        }
    }, [isOpened, getInputNode]);

    const handleInputFocus = useCallback((e) => {
        debug("onFocusInput");
        setOpened(true);
        if (onFocus) onFocus(e); // callback outside the component
    }, [onFocus])

    const handleSearchChange = useCallback((value) => {
        setFilterText(value ?? "");
        if (onSearchChange) onSearchChange(value); // callback outside the component
    }, [onSearchChange, setFilterText]);

    const handleSelectChange = useCallback((option) => {
        debug("onChange %s", JSON.stringify(option ?? null));
        if (option) {
            setOpened(false);
            setHovered(null);
            onChange(option.value);
        } else {
            onChange(null);
        }
    }, [onChange, setOpened, setHovered]);

    // options after external filter
    const transformedOptions = useMemo(() => {
        // default behaviour for 99% of our app
        if (!filteredOptions) {
            return _options;
        }
        if (filteredOptions.some((o) => isEqual(o.value, value))) {
            return filteredOptions;
        } else {
            const option = _options.find((o) => isEqual(o.value, value));
            // merge filtered options with currently selected option
            // so the user won't be left with empty input
            if (option) {
                return [option, ...filteredOptions];
            } else {
                return filteredOptions;
            }
        }
    }, [filteredOptions, _options, value]);

    // after text filter (internal)
    const options = useMemo(() => {
        return disableFilter ? transformedOptions : filterOptions(filterText, transformedOptions);
    }, [filterText, transformedOptions, disableFilter]);

    // gets the container in which the select is being drawn
    const getContainer = useCallback((forceRoot = false) => {
        const defaultValue = document.getElementById("root") ?? document.createElement("div");
        if (!selectRef.current || forceRoot) return defaultValue;
        // draw a menu inside a modal to prevent modal from closing when user clicks on an item
        const findModal = (element) => {
            if (element.classList.contains("modal")) return element;
            return element?.parentElement ? findModal(element.parentElement) : defaultValue
        }
        return findModal(selectRef.current);
    }, [selectRef]);

    // updates state with a current select BoundingBox
    const updateInputBBox = useCallback(() => {
        debug("updateInputBBox");
        const styles = {};
        if (selectRef.current) {
            styles.width = selectRef.current.clientWidth;
            styles.height = selectRef.current.clientHeight;
            const inputRect = selectRef.current.getBoundingClientRect();
            const selectOptionsHeight = selectContainerRef?.current?.clientHeight ?? 0;
            const container = getContainer();
            const left = inputRect.left;
            const top = inputRect.top;
            const scrollY = container?.classList?.contains("modal") ? container.scrollTop : window.scrollY;
            styles.left = left;
            if (top + inputRect.height + selectOptionsHeight < document.documentElement.clientHeight) {
                styles.top = top + inputRect.height + scrollY;
            } else {
                styles.top = top + scrollY - selectOptionsHeight;
            }
        }
        if (!isEqual(styles, inputBBox)) {
            setInputBBox(styles)
        }
    }, [inputBBox, getContainer]);

    useEffect(() => {
        if (
            callOnChangeOnSingleOption &&
            !disabled &&
            !value &&
            options.length === 1
        ) {
            onChange(options[0].value);
        }
    }, []); // eslint-disable-line

    // update input absolute position when the user scrolls
    useEffect(() => {
        if (!isOpened || isMobile) return () => {
            debug("NO scroll listener NEEDED");
        };
        const container = getContainer();
        debug("add scroll listener");
        container.addEventListener("scroll", updateInputBBox);
        return () => {
            debug("remove scroll listener");
            container.removeEventListener("scroll", updateInputBBox);
        }
    }, [isOpened, getContainer, isMobile, updateInputBBox]);

    // update input bbox state when its about to be drawn
    useLayoutEffect(() => {
        debug("useLayoutEffect");
        updateInputBBox();
    }, [updateInputBBox, isOpened]);

    const currentPlaceholder = useMemo(() => {
        let selectedOption = options.find((item) => isEqual(item.value, value));
        if (selectedOption) {
            if (customRenderer) {
                return customRenderer(selectedOption);
            }
            return get(
                typeof selectedOption.value === "object"
                    ? selectedOption.value
                    : selectedOption,
                selectedOption.name,
                selectedOption.name
            );
        }
        return placeholder || "";
    }, [options, value, placeholder, customRenderer]);

    const handleInputBlur = useCallback((e) => {
        debug("onBlurInput");
        if (
            selectContainerRef.current &&
            e.relatedTarget !== caretRef.current &&
            !selectContainerRef.current.contains(e.relatedTarget)
        ) {
            setOpened(false);
        }
        onBlur(undefined, e);
    }, [onBlur]);

    const handleClearClick = useCallback(() => {
        setOpened(false);
        handleSelectChange(null);
    }, [handleSelectChange]);

    // arrow navigation + enter submit
    const handleKeyDown = useCallback((e) => {
        if (!options.length) return false;
        if (!isOpened) return false;
        switch (e.which) {
            case 38:
            case 40: {
                // 38 up arrow, 40 down arrow
                const moveX = e.which - 39; // hacky way to get direction
                let nextHovered = hovered ?? (moveX > 0 ? -1 : 0);
                nextHovered += moveX;
                nextHovered = Math.max(0, Math.min(options.length - 1, nextHovered));
                setHovered(nextHovered);
                const children = selectContainerRef?.current?.children;
                if (!children) break;
                const item = children[nextHovered];
                if (!item) break;
                item.scrollIntoView({
                    block: "nearest",
                });
                break;
            }
            case 13: {
                // nikt tego `stopPropagationOnEnter` nie przekazywał, więc z automatu sprawdzam modale
                // przydatne, gdy select jest renderowany w modalu i chcemy enterem wybrać opcje
                debug("stopPropagationOnEnter=%s", stopPropagationOnEnter);
                if (stopPropagationOnEnter ?? getContainer().classList.contains("modal")) {
                    debug("prevented default 'Enter' handler!");
                    e.stopPropagation();
                    e.preventDefault();
                }
                handleSelectChange(
                    options[hovered] ||
                    (filterText
                        ? options[0]
                        : insertFirstOnNoSelected
                            ? options[0]
                            : null)
                );
                // wtf is this?
                // psuło nawigowanie tabem, gdy mieliśmy wiele selectów (wracało nam na pierwszy z listy)
                // więc zakomentowałem; nie zauważyłem, żeby coś się wywalilo
                // const input = getInputNode();
                // if (input) input.blur();
                break;
            }
            default:
                break;
        }
        return false;
    }, [options, filterText, hovered, insertFirstOnNoSelected, stopPropagationOnEnter, handleSelectChange, getContainer, isOpened])

    return (
        <div
            className={isMobile ? "fetura-select mobile" : "fetura-select"}
            ref={selectRef}>
            <Input
                id={id}
                type="search"
                value={filterText}
                onChange={handleSearchChange}
                disabled={disabled}
                placeholder={!customRenderer && currentPlaceholder}
                readOnly={isMobile}
                error={error}
                warning={warning}
                onClick={isMobile || disabled ? null : handleInputFocus}
                showIconOnErrorOnWarning={showIconOnErrorOnWarning}
                onFocus={isMobile || disabled ? null : handleInputFocus}
                onBlur={handleInputBlur}
                autofocus={autofocus}
                onKeyDown={handleKeyDown}
                autocomplete={autocomplete}
            />
            {
                customRenderer && !filterText && (
                    <div className="fetura-select-custom-placeholder">
                        {currentPlaceholder}
                    </div>
                )}
            <div className="fetura-select-icons">
                <i
                    className={
                        isOpened
                            ? "fas fa-caret-up pointer"
                            : "fas fa-caret-down pointer"
                    }
                    onClick={isMobile ? null : handleCaretClick}
                    tabIndex={-1}
                    ref={caretRef}
                />
            </div>
            {
                isMobile &&
                <OptionListMobile onChange={handleSelectChange} disabled={disabled} options={options}
                                  value={value}/>
            }
            {
                !isMobile &&
                <>
                    {
                        renderInPortal ?
                            ReactDOM.createPortal(
                                <OptionListDesktop
                                    ref={selectContainerRef}
                                    inputBBox={inputBBox}
                                    value={value}
                                    options={options}
                                    customRenderer={customRenderer}
                                    hovered={hovered}
                                    menuOpened={isOpened}
                                    renderInPortal={true}
                                    setFilterText={setFilterText}
                                    onHoverChange={setHovered}
                                    onChange={handleSelectChange}
                                />,
                                getContainer()
                            )
                            :
                            <OptionListDesktop
                                ref={selectContainerRef}
                                inputBBox={inputBBox}
                                value={value}
                                options={options}
                                customRenderer={customRenderer}
                                hovered={hovered}
                                menuOpened={isOpened}
                                renderInPortal={false}
                                setFilterText={setFilterText}
                                onHoverChange={setHovered}
                                onChange={handleSelectChange}
                            />
                    }

                </>
            }
            {
                !!clearButton && !disabled && (
                    <div className="fetura-select-icons clear-button ">
                        <i
                            className={`fas fa-times pointer`}
                            onClick={
                                !disabled ? handleClearClick : undefined
                            }
                            tabIndex={-1}
                        />
                    </div>
                )}
            {
                showIconOnErrorOnWarning && (!!error || !!warning) && (
                    <Tooltip
                        tooltipContent={error || warning}
                        type={error ? "error" : "warning"}>
                        <div
                            className={`input-icon ${error ? "error" : "warning"
                            }`}
                        />
                    </Tooltip>
                )}
        </div>
    );

}

Select.defaultProps = {
    onChange: () => {
    },
    onSearchChange: () => {
    },
    placeholder: "",
    clearButton: true,
    onBlur: () => {
    },
    renderInPortal: true,
    insertFirstOnNoSelected: true,
    mobile: null,
    stopPropagationOnEnter: true,
    disableFilter: false,
    autocomplete: "off",
    callOnChangeOnSingleOption: true
};

export default Select;