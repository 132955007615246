import React, {Component} from 'react';
import NotificationsSystem, {dismissNotification, setUpNotifications, wyboTheme} from 'reapop';
import {connect} from "react-redux";
import "./_notification-theme.scss";
import ReactDOM from "react-dom";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        notifications: state.notifications
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dismiss: (id) => {
            dispatch(dismissNotification(id))
        }
    }
}

export class CustomNotificationsSystem extends Component {

    componentDidMount() {
        setUpNotifications({
            defaultProps: {
                position: "top-right"
            }
        })
    }

    handleShow = () => {
        const {location: {search, pathname, state}, history} = this.props;
        const params = new URLSearchParams(search);
        params.set("notificationsHistory", "show");
        history.replace({
            pathname: pathname,
            search: params.toString(),
            state
        });
    }

    render() {
        const {notifications, dismiss, t} = this.props;
        const element = document.getElementsByClassName("reapop__container--top-right")?.[0] || document.getElementsByClassName("reapop__container--top-center")?.[0];
        return (
            <>
                {
                    element && notifications.length > 1 && ReactDOM.createPortal(
                        <div onClick={this.handleShow} className={"more-notifications"}>
                            <i className="fas fa-history"/> {t("notificationsCenter.historyOfNotifications")}
                        </div>, element
                    )
                }
                <NotificationsSystem theme={wyboTheme} notifications={notifications}
                                     dismissNotification={dismiss}/>
            </>

        )
    }
}

export default compose(
    withTranslation(),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(CustomNotificationsSystem);
