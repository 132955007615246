import React, {Component} from 'react';
import {connect} from 'react-redux';
import NotificationItem from "./NotificationItem";
import {Col} from "react-bootstrap";
import Card from "../../components/basics/card/Card";
import NotFound from "../../components/NotFound";

function mapStateToProps(state) {
    return {
        notifications: state.notificationHistory.notifications
    };
}

class NotificationView extends Component {
    render() {
        const {notifications} = this.props;
        return (
            <Col className={"notify-view"} xs={12} sm={12} md={12} lg={6} xl={5}>
                <Card>
                    {
                        !notifications.length &&
                            <NotFound/>
                    }
                    {
                        notifications.map((n) =>
                            <NotificationItem
                                {...n}
                                key={`history_${n.id}`}
                            />)
                    }
                </Card>
            </Col>
        );
    }
}

export default connect(
    mapStateToProps,
)(NotificationView);
