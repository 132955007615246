import { getUserApiData } from "../../utils/UserUtils";
import { invokeApig } from "../../libs/awsLib";
import Paths from "../paths";
import { myID } from "../../libs/generateID";
import store from "../../store/store";
import { notify } from "reapop";
import i18n from "../../i18n";
import { createErrorNotification, createSuccessNotification } from "../../utils/ResponseUtils";

/**
 * @typedef FallData
 * @type {object}
 * @property AnmID {string}                                 ID zwierzęcia
 * @property amount {number}                                Ilość zwierząt (dla warchlaków i tuczników, dla całej reszty 1)
 * @property from {string}                                  miejsce, z którego zrobiono upadek (do użytku przy tablicy PlcmntID, inaczej przyjmuje to główne)
 * @property EvTime {number}                                czas wykonania zdarzenia
 * @property GrID {string}                                  id grupy
 * @property devices {[{DevID: string, GwID: string}]}      tablica z urządzeniami, które są na miejscu zwierzęcia
 * @property clearFeeding {boolean}                         informacja, czy ma wczyścić karmienie
 * @property Comment {string}                               komentarz
 * @property Weight {number}                                waga zwierzęcia
 * @property Reason {string}                                powód upadku
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego upadku
 * @param data {[FallData]}                 Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createFall(data, { FarmID, ClientID, LocalUserID } = {}) {
    const { _FarmID, _LocalUserID, _ClientID } = getUserApiData({ FarmID, ClientID, LocalUserID });
    return invokeApig({
        ...Paths.createFall({ farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID }),
        body: data
    })
}

export function createFallNotificationSuccess(response, { uniqueId = myID() } = {}) {
    if (createSuccessNotification(response, "apiNotifications.createFallTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createFallTitle"),
        message: i18n.t("apiNotifications.createFallSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createFallNotificationFailure(response, { uniqueId = myID() } = {}) {
    if (createErrorNotification(response, "apiNotifications.createFallTitle", { uniqueId })) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createFallTitle"),
        message: i18n.t("apiNotifications.createFallFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
