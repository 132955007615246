import React from "react";
import PropTypes from "prop-types";
import TableItem from "./TableItem";
import {myID} from "../../../libs/generateID";
import {get, isFunction} from "lodash";
import Checkbox from "../checkbox/Checkbox";
import Radio from "../radio/Radio";

class TableRow extends React.Component {
    constructor(props) {
        super(props);
        const {isFullWidthRow, object, fullWidthRow} = props;
        if (isFullWidthRow && isFullWidthRow(object) && !fullWidthRow) {
            throw new Error("Wykryto pełny wiersz bez komponentu fullWidthRow");
        }
    }

    getClassName = () => {
        const {
            isSelectable,
            isClickable,
            selected,
            className,
            object,
            index,
            stripped,
        } = this.props;
        const isRowSelectable = this.isRowSelectable();
        let cName = "table-row";

        if (isSelectable && isRowSelectable) cName += " selectable";
        if (selected) cName += " selected";
        if (isClickable && isRowSelectable) cName += " pointer";
        if (className) {
            if (typeof className === "function") {
                let name = className(object, {index});
                if (name) {
                    cName += ` ${name}`;
                }
            } else cName += ` ${className}`;
        }
        if (stripped && !(object?.index % 2)) {
            cName += " even";
        }
        return cName;
    };

    onRowClick = (e) => {
        e.preventDefault();
        const {object, index, onClick} = this.props;
        if (onClick) onClick(object, index - 1, e);
    };

    onSelectableRowClick = (e) => {
        e.preventDefault();
        const {object, index, onSelectedRowClick} = this.props;
        const isRowSelectable = this.isRowSelectable();
        if (isRowSelectable) {
            onSelectedRowClick(object, index - 1);
        }
    };

    renderFullWidthRow = () => {
        const {fullWidthRow} = this.props;
        if (isFunction(fullWidthRow)) {
            return fullWidthRow(this.props);
        } else {
            return React.cloneElement(
                fullWidthRow,
                {...this.props},
                fullWidthRow.props.children
            );
        }
    };

    onExpand = () => {
        let currentTableRow =
            this.current &&
            this.current
                .getElementsByClassName("table-row")[0]
                .getElementsByTagName("i").length > 0
                ? this.current
                      .getElementsByClassName("table-row")[0]
                      .getElementsByTagName("i")[0]
                : undefined;
        let currentExpand =
            this.current &&
            this.current.getElementsByClassName("isExpand-content").length > 0
                ? this.current.getElementsByClassName("isExpand-content")[0]
                : undefined;
        if (currentExpand) {
            currentExpand.style.display === "flex"
                ? (currentExpand.style.display = "none")
                : (currentExpand.style.display = "flex");
        }
        if (currentTableRow && currentExpand) {
            currentExpand.style.display === "flex"
                ? (currentTableRow.style.transform = "rotate(180deg)")
                : (currentTableRow.style.transform = "rotate(0deg)");
        }
    };

    createExpandContent = (component, object) => {
        return React.cloneElement(component, object);
    };

    isRowSelectable = () => {
        const {isRowSelectable, object} = this.props;
        if (isFunction(isRowSelectable)) return isRowSelectable(object);
        return true;
    };

    render() {
        const {
            headers,
            object,
            shouldIndex,
            index,
            isSelectable,
            selected,
            page,
            paginationItems,
            isPinned,
            isFullWidthRow,
            isClickable,
            singleRowSelect,
            mobile,
            fullWidthBefore,
            isExpanded,
            component,
            rowID,
        } = this.props;
        if (isFullWidthRow && isFullWidthRow(object))
            return (
                <div className="table-row full-width">
                    {this.renderFullWidthRow()}
                </div>
            );
        const isRowSelectable = this.isRowSelectable();
        return (
            <div
                className="table-row-box"
                ref={(ref) => {
                    this.current = ref;
                }}>
                <div
                    className={this.getClassName()}
                    id={
                        rowID
                            ? typeof rowID === "string"
                                ? get(object, rowID, null)
                                : rowID(object)
                            : null
                    }
                    onClick={isExpanded ? this.onExpand : undefined}>
                    {isSelectable && (
                        <>
                            {isPinned && (
                                <div className="table-item checkbox" />
                            )}
                            {singleRowSelect ? (
                                <div
                                    onClick={this.onSelectableRowClick}
                                    className="table-item checkbox">
                                    <Radio
                                        label=""
                                        id={myID()}
                                        checked={selected}
                                        readOnly
                                    />
                                </div>
                            ) : (
                                !isPinned && (
                                    <div
                                        onClick={this.onSelectableRowClick}
                                        className="table-item checkbox">
                                        <Checkbox
                                            label=""
                                            id={myID()}
                                            checked={selected}
                                            readOnly
                                            disabled={!isRowSelectable}
                                        />
                                    </div>
                                )
                            )}
                        </>
                    )}
                    {shouldIndex && (
                        <>
                            {isPinned && <div className="table-item index" />}
                            {!isPinned && (
                                <div className="table-item index">
                                    {index - fullWidthBefore}
                                </div>
                            )}
                        </>
                    )}
                    {headers.map((header, i) => (
                        <TableItem
                            isClickable={isClickable}
                            onRowClick={this.onRowClick}
                            mobile={mobile}
                            header={header}
                            object={object}
                            key={i}
                            index={index - 1}
                            page={page}
                            paginationItems={paginationItems}
                            isPinned={isPinned}
                        />
                    ))}
                </div>
                {isExpanded && (
                    <div className="isExpand-content">
                        {this.createExpandContent(component, object)}
                    </div>
                )}
            </div>
        );
    }
}

export default React.memo(TableRow);

TableRow.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.node.isRequired,
            field: PropTypes.string,
            valueFormatter: PropTypes.func,
            component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
        })
    ).isRequired,
    object: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
    shouldIndex: PropTypes.bool,
    index: PropTypes.number,
    isSelectable: PropTypes.bool,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    page: PropTypes.number,
    paginationItems: PropTypes.number,
    isPinned: PropTypes.bool,
    isFullWidthRow: PropTypes.func,
    fullWidthRow: PropTypes.node,
    isClickable: PropTypes.bool,
    singleRowSelect: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    fullWidthBefore: PropTypes.number,
    isExpanded: PropTypes.bool,
    component: PropTypes.node,
};

TableRow.defaultProps = {
    isExpanded: false,
    shouldIndex: false,
    isSelectable: false,
    selected: false,
    onClick: () => {},
};
