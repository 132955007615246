import lokiDB from "./lokiDB";
import { getModificationTime, insertInto, setModificationTime } from "../utils/LokiUtils";
import eventsDB from "./eventsDB";
import _ from "lodash";
import { AnimalTypes } from "../constans/animalTypes";
import { Level } from "../constans/levelTypes";
import { getFarm } from "../selectors/farmSelector";
import store from "../store/store";
import { convertBuildingsToOldObject } from "../utils/dbutils/convertBuildings";

class Animals {

    constructor() {
        this.getAllAnimalsByPlcmntID = _.memoize(this.getAllAnimalsByPlcmntID);
        this.getAllAnimalsForLocation = _.memoize(this.getAllAnimalsForLocation, (loc, crLevel) => {
            return (loc.FarmID || loc.BgID || loc.SID || loc.CID || loc.BID) + crLevel;
        });
        this.getAnimalLocationsByPlcmntID = _.memoize(this.getAnimalLocationsByPlcmntID, (...args) => JSON.stringify(args));
    }

    getModificationTime(id) {
        return getModificationTime("animals", "FarmID", id);
    }

    clearCache() {
        this.getAllAnimalsByPlcmntID.cache.clear();
        this.getAllAnimalsForLocation.cache.clear();
    }

    /**
     * Funkcja dodajaca rekordy do bazy danych w tabeli animals
     * dodaje jesli w bazie nie istnieje aktualnie AnmID
     * update jesli w bazie bylo wczesniej zwierze o takim samym AnmID
     *
     * Następnie update robiony jest na dacie modyfikacji w tabeli cahcedTablesParams
     * z konkretnym FarmID jesli wczesniej byl, w przeciwnym wypadku tworzony jest nowy rekord
     *
     * @param values tablica zwierzat
     */
    insertIntoAnimals(values) {
        values.sort((e1, e2) => e1.DtaModTime - e2.DtaModTime);
        insertInto(values, lokiDB.animals, "AnmID");
        setModificationTime("animals", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
        this.clearCache();
    }

    /**
     * Funkcja, która pobiera zwierzęta i dodaję dla nich eventy
     * @param object        obiekt, który wyszukuje w lokim
     * @param joinEvents    czy ma dołączyć eventy dla zwierząt
     * @param anmIDs
     * @return {*}          lista zwierząt z eventami dla każdego z nich
     */
    _findAnimals(object = {}, { joinEvents = true } = {}) {
        let anms = lokiDB.animals.find(object);
        return anms.map(animal => {
            let events = joinEvents ? eventsDB.getAllEvents4Animal(animal.AnmID) : [];
            return { ...animal, events };
        });
    }

    /**
     * Funkcja wyszukuje jedno zwierzę i wstawia do niego eventy
     * @param object                    obiekt wyszukiwania
     * @param joinEvents
     * @return {{[p: string]: *}}       zwierzę z eventami
     */
    _findOneAnimal(object, { joinEvents = true } = {}) {
        let animal = lokiDB.animals.findOne(object);
        if (animal) {
            let events = joinEvents ? eventsDB.getAllEvents4Animal(animal.AnmID) : [];
            return { ...animal, events };
        }
    }

    /**
     * Pobranie z lokijs wszystkich żywych zwierzat dla konkretnego FarmID
     * @param farmID - FarmID
     * @param type - typ zwierzęcia (0 - maciora, 1 - prosie, 2 - warchlak, 3 - tucznik, 4 - knur, 5 - loszka remontowa
     * @param showDead - czy ma znalezc martwe zwierzeta
     * @param joinEvents
     * @param showDeleted
     * @param anmIDs
     * @param checkAnmIDs
     * @returns {Array} tablica z zwierzetami lub bload "not found"
     */
    getAllAnimals(farmID, type, showDead = false, joinEvents = true, showDeleted = false, anmIDs = {}, checkAnmIDs = false) {
        try {
            let findObj = {
                FarmID: farmID,
                //DtaDelTime: {$type: 'undefined'}
            };
            if (!showDeleted) {
                findObj = {
                    ...findObj,
                    DtaDelTime: { $type: 'undefined' }
                };
            }
            if (!showDead) {
                findObj = {
                    ...findObj,
                    DtaDthTime: { $type: 'undefined' },
                    DtaJoinTime: { $type: 'undefined' }
                };
            }
            if (type !== undefined) {
                findObj = {
                    ...findObj,
                    AnimalKind: type
                };
            }
            if (checkAnmIDs) {
                findObj = {
                    ...findObj,
                    AnmID: { $in: Object.keys(anmIDs) }
                };
            }
            return this._findAnimals(findObj, { joinEvents });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Funkcja zwracająca z lokiego wszystkie typy loch tj. loszka remontowa lub maciora
     * @param farmID - FarmID
     * @param showDead - czy ma znalezc martwe zwierzeta
     * @param joinEvents - czy ma dolaczyc eventy do poszczegolnego zwierzecia
     * @returns {Array} - tablica z zwierzetami lub bload "not found"
     */
    getAllSows(farmID, showDead = false, joinEvents = true) {
        try {
            let findObj = {
                FarmID: farmID,
                AnimalKind: { $in: [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW] }
            };
            if (!showDead) {
                findObj = {
                    ...findObj,
                    DtaDthTime: { $type: 'undefined' },
                    DtaJoinTime: { $type: 'undefined' },
                    DtaDelTime: { $type: 'undefined' }
                };
            }
            return this._findAnimals(findObj, { joinEvents });
        } catch (e) {
            console.error(e);
            return [];
        }
    }
    /**
     * Pobiera zwierzę dla podanego ID
     * @param id            ID zwierzęcia
     * @param joinEvents
     * @param findDeleted
     * @return {Object}     Obiekt zwierzęcia
     */
    getAnimalById(id, { joinEvents = true, findDeleted = false } = {}) {
        try {
            if (typeof id === "object") return null;
            let params = { AnmID: id };
            if (!findDeleted) {
                params.DtaDelTime = { $type: 'undefined' };
            }
            return this._findOneAnimal(params, { joinEvents });
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    /**
     * Pobranie z lokijs zwierze dla konkretnego RFID
     * @param rfid - RFID
     * @param FarmID - FarmID
     * @param findDead - czy ma znajdowac martwe zwierzeta
     * @returns {{}} tablica z zwierzetami lub bload "not found"
     */
    getAnimalByRfid(rfid, FarmID, { findDead = false } = {}) {
        try {
            if (findDead) {
                return this._findOneAnimal({
                    RFID: rfid,
                    FarmID
                });
            }
            return this._findOneAnimal({
                RFID: rfid,
                FarmID,
                DtaDthTime: findDead ? undefined : { $type: 'undefined' },
                DtaDelTime: { $type: 'undefined' }
            });
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    /**
     * Pobranie z lokijs listy zwierząt dla konkretnego RFID
     * @param rfid - RFID
     * @param FarmID - FarmID
     * @returns {{}} tablica z zwierzetami lub bload "not found"
     */
    getAnimalsByRfid(rfid, FarmID) {
        try {
            return this._findAnimals({ RFID: rfid, FarmID, DtaDelTime: { $type: 'undefined' } });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllAnimalsByPlcmntID(PlcmntID, { joinEvents = true } = {}) {
        try {
            let anms = this._findAnimals({
                DtaDthTime: { $type: 'undefined' },
                DtaJoinTime: { $type: 'undefined' },
                DtaDelTime: { $type: 'undefined' },
                $or: [
                    {
                        PlcmntID: PlcmntID
                    },
                    {
                        PlcmntID: { $type: "array" }
                    }
                ]
            }, { joinEvents });
            return anms.filter(item => {
                if (Array.isArray(item.PlcmntID)) {
                    return !!item.PlcmntID.find(plcmnt => plcmnt.PlcmntID === PlcmntID);
                } else {
                    return item.PlcmntID === PlcmntID;
                }
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllAnimalsForLocation(loc, crLevel, { showDead = false, joinEvents = true } = {}) {
        let result = [];
        if (crLevel === Level.FARM) {
            let resBuildings = convertBuildingsToOldObject(lokiDB.buildings.find({ 'FarmID': loc.FarmID }));
            result = [loc.FarmID];
            resBuildings.map(bulding => {
                let locID = this.scanLocalizationIds(bulding, Level.BUILDING);
                result = result.concat(locID);
            });
        } else {
            let r = this.scanLocalizationIds(loc, crLevel);
            result = result.concat(r);
        }
        // wyszukiwanie zwierząt, które mają stary zapis PlcmntID (string)
        const deadParams = showDead ? {} : { DtaDthTime: { $finite: false } };
        let nonArrayPlcmnts = this._findAnimals({
            PlcmntID: { '$in': result },
            ...deadParams,
            DtaJoinTime: { $finite: false },
            DtaDelTime: { $type: 'undefined' }
        }, { joinEvents });
        // wyszukiwanie zwierząt, które mają nowy zapis PlcmntID (tablica)
        let arrayPlcmnts = this._findAnimals({
            PlcmntID: { $type: "array" },
            ...deadParams,
            DtaJoinTime: { $finite: false },
            DtaDelTime: { $type: 'undefined' }
        }, { joinEvents });
        // przefiltrowanie zwierząt z tablicą
        let filtered = arrayPlcmnts.filter(animal => !!animal.PlcmntID.find(plcmntID => result.includes(plcmntID.PlcmntID)));
        return [...nonArrayPlcmnts, ...filtered];
    }

    getAllAnimalsForPlcmntIDs(FarmID, PlcmntIDs, { showDead = false, joinEvents = false } = {}) {
        const params = showDead ? {} : { DtaDthTime: { $finite: false } };
        const animalsWithStringPlcmnts = this._findAnimals({
            PlcmntID: { '$in': PlcmntIDs },
            ...params,
            DtaJoinTime: { $finite: false }
        }, { joinEvents });
        let animalsWithArrayPlcmnts = this._findAnimals({
            PlcmntID: { $type: "array" },
            ...params,
            DtaJoinTime: { $finite: false }
        }, { joinEvents }).filter(animal => !!animal.PlcmntID.find(plcmntID => PlcmntIDs.includes(plcmntID.PlcmntID)));
        // przefiltrowanie zwierząt z tablicą
        return [...animalsWithStringPlcmnts, ...animalsWithArrayPlcmnts];
    }

    getAnimalCount(loc, crLevel) {
        let animals = this.getAllAnimalsForLocation(loc, crLevel, { joinEvents: false });
        let countUnits = 0;
        let renovationSowsCount = 0;
        let sowsCount = 0;
        let boarsCount = 0;
        let porkerCount = 0;
        let pigletCount = 0;
        let pigCount = 0;
        for (let anm of animals) {
            countUnits += anm.AnmCnt;
            switch (anm.AnimalKind) {
                case AnimalTypes.RENOVATION_SOW:
                    renovationSowsCount += 1;
                    break;
                case AnimalTypes.SOW:
                    sowsCount += 1;
                    pigCount += anm.AnmCnt - 1;
                    break;
                case AnimalTypes.PIGLET:
                    pigletCount += anm.AnmCnt;
                    break;
                case AnimalTypes.PORKER:
                    porkerCount += anm.AnmCnt;
                    break;
                case AnimalTypes.BOAR:
                    boarsCount += anm.AnmCnt;
                    break;
                default:
                    break;
            }
        }
        return {
            countUnits: countUnits,
            renovationSowsCount: renovationSowsCount,
            sowsCount: sowsCount,
            boarsCount: boarsCount,
            porkerCount: porkerCount,
            pigletCount: pigletCount,
            pigCount: pigCount
        };
    }

    getAllAnimalsForLocationType(loc, crLevel, type) {
        let anms = this.getAllAnimalsForLocation(loc, crLevel);
        return anms.filter((animal) => animal.AnimalKind === type);
    }

    /**
     * Funkcja dodajaca rekordy do bazy danych w tabeli animals
     * dodaje jesli w bazie nie istnieje aktualnie AnmID
     * update jesli w bazie bylo wczesniej zwierze o takim samym AnmID
     *
     * Nie aktualizowana jest data ostatniej modyfikacji w celu zapobiegniecia utracie danych,
     * które zostały dodane w między czasie przez inne osoby
     *
     * @param values tablica zwierzat
     */
    insertIntoAnimalsWithoutUpdateModTime(values) {
        //console.log("valuse animals without update : ",values);
        values.map(item => {
            let data = lokiDB.animals.find({ AnmID: item.AnmID });
            if (data.length > 0) {
                for (let k in item) {
                    if (item.hasOwnProperty(k)) {
                        data[0][k] = item[k];
                    }
                }
                lokiDB.animals.update(data[0]);
            } else {
                lokiDB.animals.insert(item);
            }
        });
        try {
            lokiDB.db.saveDatabase();
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * scanLocalizationIds generuje tablicę id-ów miejsc zawartych w odpowiednim fragmencie drzewa,
     *   w parametrach należy podać miejsce i poziom tego miejsca, tą tablicę należy podać jako parametr do zapytania
     *   z operatorem $in (czyli wyszukującego wszystkie zwierzęta z id miejsca pochodzącym z tego zbioru)
     *
     *   curLevel 2 - chamber   1 - sector  0 - building
     *   */
    scanLocalizationIds(place, curLevel, resultIds = []) {
        let idKeys = ['BgID', 'SID', 'CID', 'BID'];
        let tabKeys = ['', 'Sectors', 'Chambers', 'Boxes'];

        if (place !== undefined) {
            resultIds.push(place[idKeys[curLevel]]);
            curLevel++;
            if (place[tabKeys[curLevel]] !== undefined) {
                place[tabKeys[curLevel]].forEach(element => {
                    if (curLevel < 4) this.scanLocalizationIds(element, curLevel, resultIds);

                });
            }
        }
        return resultIds;
    }

    /**
     * Funckja sprawdzajaca czy dany numer zwierzęcia istnieje
     * @param AnmNo1 {string | number}  numer zwierzęcia
     * @param FarmID {string}           ID farmy na której znajduje się zwierzę
     * @param excludedAnimal {string}   ID zwierzecia, ktore ma byc wykluczone z wyszukiwania (potrzebne do edycji)
     * @returns {boolean}
     */
    checkIfAnimalExistOnFarm(AnmNo1, FarmID, excludedAnimal = "") {
        let res = lokiDB.animals.find({
            $or: [{ AnmNo1: +AnmNo1 }, { AnmNo1: AnmNo1 + "" }],
            FarmID,
            DtaDthTime: { $type: 'undefined' },
            DtaDelTime: { $type: 'undefined' }
        });
        if (excludedAnimal) {
            res = res.filter(item => item.AnmID !== excludedAnimal);
        }
        return res.length > 0;
    }

    /**
     * @param AnmNo2 {string | number}  alternatywny numer zwierzecia
     * @param FarmID {string}   ID fermy na której znajduje się zwierzę
     * @param animalKind {number}   typ zwierzęcia
     * @returns {object}
     */
    getAnimalByAnmNo2(AnmNo2, FarmID, animalKind = undefined) {
        if (!(AnmNo2 || FarmID)) return null;
        let findObj = {
            AnmNo2: AnmNo2,
            FarmID: FarmID,
            DtaDthTime: { $type: 'undefined' },
            DtaJoinTime: { $type: 'undefined' },
            DtaDelTime: { $type: 'undefined' }
        };
        if (animalKind) {
            findObj = {
                ...findObj,
                AnimalKind: animalKind
            };
        }
        return this._findOneAnimal(findObj);
    }

    getAnimalByAnmNo1(AnmNo1, FarmID, animalKind = undefined) {
        if (!(AnmNo1 && FarmID)) return null;
        let findObj = {
            AnmNo1: AnmNo1,
            FarmID: FarmID,
            DtaDthTime: { $type: 'undefined' },
            DtaJoinTime: { $type: 'undefined' },
            DtaDelTime: { $type: 'undefined' }
        };
        if (animalKind !== undefined) {
            findObj = {
                ...findObj,
                AnimalKind: animalKind
            };
        }
        return this._findOneAnimal(findObj);
    }

    getAnimalsByAnmNo1(AnmNo1, FarmID, showDead = false) {
        if (!(AnmNo1 && FarmID)) return [];
        let findObj = {
            AnmNo1: AnmNo1,
            FarmID: FarmID,
            DtaDelTime: { $type: 'undefined' }
        };
        if (!showDead) {
            findObj = {
                ...findObj,
                DtaDthTime: { $finite: false },
                DtaJoinTime: { $finite: false }
            };
        }
        return this._findAnimals(findObj);
    }

    getAllAnimalsWithoutFarmID() {
        try {
            return this._findAnimals({ DtaJoinTime: { $type: 'undefined' }, DtaDelTime: { $type: 'undefined' } });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * @deprecated używać raczej stora do szukania a nie lokiego
     * @param plcmntID
     * @param nameDeep
     * @return {{id: *}[]}
     */
    getAnimalLocationsByPlcmntID(plcmntID = [], { nameDeep } = {}) {
        if (!_.isArray(plcmntID)) {
            plcmntID = [{ PlcmntID: plcmntID }];
        }
        return plcmntID.map(plcmnt => {
            const locationID = plcmnt.PlcmntID;
            const location = { id: locationID };
            let name = [];
            let results = getFarm(store.getState(), locationID);
            if (results) {
                location.name = results.FarmName;
                location.location = results;
                location.level = Level.FARM;
                return location;
            }
            results = convertBuildingsToOldObject(lokiDB.buildings.find());
            results.forEach(building => {
                if (locationID === building.BgID) {
                    location.level = Level.BUILDING;
                    location.location = building;
                    name = [building.BName];

                }
                (building.Sectors || []).forEach(sector => {
                    if (locationID === sector.SID) {
                        location.level = Level.SECTOR;
                        location.sectorType = sector.SType;
                        location.location = sector;
                        name = [building.BName, sector.SName];
                    }
                    (sector.Chambers || []).forEach(chamber => {
                        if (locationID === chamber.CID) {
                            location.level = Level.CHAMBER;
                            location.location = chamber;
                            location.sector = sector;
                            location.sectorType = sector.SType;
                            name = [building.BName, sector.SName, chamber.CName];
                            // console.log("KOMORA CORRECT ID")
                        }

                        (chamber.Boxes || []).forEach(box => {
                            if (locationID === box.BID) {
                                location.level = Level.BOX;
                                location.chamber = chamber;
                                location.sectorType = sector.SType;
                                location.location = box;
                                name = [building.BName, sector.SName, chamber.CName, box.BoxesName];
                            }
                        });
                    });
                });
            });
            if (name.length) {
                const start = Math.max(nameDeep ? name.length - nameDeep : 0, 0);
                const stop = nameDeep ? start + nameDeep : undefined;
                location.name = name.slice(start, stop).join(" - ");
            }
            return location;
        });
    }

    /**
     * Funkcja pobierająca wszystkie zwierzęta, niezależnie od farmy (żywe i martwe)
     */
    getAllAnimalWithoutFarmID() {
        try {
            return this._findAnimals({ DtaDelTime: { $type: 'undefined' } });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * funkcja zwracajaca wszystkie zwierzeta z data wprowadzenia zawierajaca sie pomiedzy dwoma datami
     * @param farmID
     * @param startTime
     * @param endTime
     * @returns {Array|*}
     */
    getAllAnimalsInsertedBetweenDates(farmID, startTime, endTime) {
        try {
            return this._findAnimals({
                FarmID: farmID,
                DtaInTime: { $between: [+startTime, +endTime] },
                DtaJoinTime: { $type: 'undefined' },
                DtaDelTime: { $type: 'undefined' }
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllAnimalsInsertedToDate(farmID, date, AnimalKind, showDead = false) {
        //pobranie zwierzat od poczatku roku do ostatniego dnia miesiaca, np 31.01.2018 23:59:59, ktore zyly w tym okresie
        try {
            return this._findAnimals({
                FarmID: farmID, DtaInTime: { $lte: +date },
                $or: [
                    {
                        DtaDthTime: {
                            $type: "undefined"
                        }
                    },
                    {
                        DtaDthTime: {
                            $gt: showDead ? undefined : +date
                        }
                    }
                ],
                DtaJoinTime: { $type: 'undefined' },
                AnimalKind: AnimalKind,
                DtaDelTime: { $type: 'undefined' },
                AnmCnt: { $gt: 0 }
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllAnimalsBornToDate(farmID, date, AnimalKind, showDead = false) {
        try {
            return this._findAnimals({
                FarmID: farmID, DtaBrthTime: { $lte: +date },
                $or: [
                    {
                        DtaDthTime: {
                            $type: "undefined"
                        }
                    },
                    {
                        DtaDthTime: {
                            $gt: showDead ? undefined : +date
                        }
                    }
                ],
                DtaJoinTime: { $type: 'undefined' },
                AnimalKind: AnimalKind,
                DtaDelTime: { $type: 'undefined' }
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllSingleAnimals(farmID) {
        try {
            return this._findAnimals({
                FarmID: farmID,
                AnmCnt: 1,
                DtaDthTime: { $type: "undefined" },
                DtaJoinTime: { $type: 'undefined' },
                DtaDelTime: { $type: 'undefined' }
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Metoda sprawdza czy nie ma już podanego takiego numeru RFID (sprawdza tylko zywe zwierzeta)
     * @param RFID {string}              numer RFID
     * @param FarmID {string}            ID fermy
     * @param excludeAnmID {string}      ID, które nie jest brane pod uwagę przy sprawdzaniu (do edycji)
     */
    checkIfHaveRFID(RFID, FarmID, excludeAnmID = "") {
        try {
            let animalsWithRFID = lokiDB.animals.find({
                FarmID,
                RFID,
                DtaDthTime: { $type: "undefined" },
                DtaDelTime: { $type: 'undefined' }
            });
            if (excludeAnmID) {
                animalsWithRFID = animalsWithRFID.filter(item => item.AnmID !== excludeAnmID);
            }
            return animalsWithRFID.length > 0;
        } catch (e) {
            return true;
        }
    }

    /**
     * Metoda zwraca zwierzęta, które nie mają przypisanego typu
     * @param FarmID
     * @return {Array|*}
     */
    getAutoCreatedAnimals(FarmID) {
        try {
            return this._findAnimals({
                FarmID,
                DtaDthTime: { $type: "undefined" },
                AnimalKind: { $type: "undefined" },
                DtaDelTime: { $type: 'undefined' }
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getAllAnimalsWithRFID(RFID, FarmID) {
        try {
            return this._findAnimals({
                FarmID,
                RFID,
                DtaDelTime: { $type: 'undefined' }
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    doesFarmHasSomeAnimal(FarmID) {
        return this._findOneAnimal({ FarmID }, { joinEvents: false });
    }

    findAnimalByTattooNumber(TattooNumber, FarmID) {
        return this._findOneAnimal({
            "Genetics.TattooNumber": TattooNumber,
            FarmID,
            DtaDthTime: { $type: "undefined" },
            DtaDelTime: { $type: 'undefined' }
        });
    }

    removeTemporaryAnimals(AnmID) {
        console.log("Removing temporary animal", AnmID);
        lokiDB.animals.chain().find({ AnmID, isTemporary: true }).remove();
    }

    getSingleAnimals(FarmID, animalKinds, { joinEvents = false } = {}) {
        return this._findAnimals({
            FarmID,
            DtaDthTime: { $type: "undefined" },
            AnimalKind: { $in: animalKinds },
            DtaDelTime: { $type: 'undefined' },
            $or: [
                { RFID: { $type: "string" } },
                { Tagged: true }
            ]
        }, { joinEvents });
    }

    getAllAnimalsByAnmNo1(AnmNo1, FarmID, { joinEvents = false } = {}) {
        return this._findAnimals({
            AnmNo1,
            FarmID
        }, { joinEvents });
    }
}

const animalsDB = new Animals();
export default animalsDB;
