import {isNil} from "lodash";
import React from "react";


const getClassName = (status) => {
    switch (status) {
        case "removed":
            return "background-error-light";
        case "added":
            return "background-success-light";
        case "changed":
            return "background-info-light";
        default:
            return "";
    }
};

const getStatus = (oldIsNil, newIsNil) => {
    if (!oldIsNil && !newIsNil) return "changed";
    if (!newIsNil) return "added";
    if (!oldIsNil) return "removed";
    return ""
};

export default ({value, className}) => {
    if (!value) return null;
    return (
        <>
            {
                value.map(({oldValue, newValue, name}) => {
                    let status = getStatus(isNil(oldValue), isNil(newValue));
                    return (
                        <div className={className}>
                            <small>{name}</small>
                            <div className={getClassName(status)}>
                                {
                                    status === "removed" &&
                                    <>
                                        <small><i className={"fas fa-fw fa-square-minus"}/></small>
                                    </>
                                }
                                {oldValue}
                                {
                                    status === "changed" &&
                                    <>
                                        <small><i className={"fas fa-fw fa-arrow-right"}/></small>
                                    </>
                                }
                                {
                                    status === "added" &&
                                    <>
                                        <small><i className={"fas fa-fw fa-square-plus"}/></small>
                                    </>
                                }
                                {newValue}
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}


