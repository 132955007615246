import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Fade } from 'react-bootstrap';
import { SQL_COLUMNS, SQL_KEYWORDS } from '../../../../constans/sqlKeywords';
import Input from "../../../basics/input/Input";
import "./_sql-input.scss";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import { uniq } from 'lodash';

function SQLAbsoluteListItem({ item, onClick: onClickProps, index, deleteItem, hovered }) {

    const onClick = useCallback(() => {
        onClickProps(item);
    }, [onClickProps, item]);

    const onDelete = useCallback((e) => {
        e.stopPropagation();
        deleteItem(index);
    }, [deleteItem, index]);

    return (
        <div className={`sql-input-history-item ${hovered ? " hovered" : ""}`} onClick={onClick}>
            <div>{item}</div>
            {
                deleteItem &&
                <div onClick={onDelete}><i className="fa-solid fa-xmark" /></div>
            }
        </div>
    )
}

function SQLAbsoluteList({ items, onDelete, show, onClick, setShow }) {

    const container = useRef();

    const [hovered, setHovered] = useState(0);

    useEffect(() => {
        setHovered(0);
    }, [items.length, show]);

    const onKeyUp = useCallback(e => {
        if (show) {
            if (e.keyCode === 38) {
                setHovered(prev => {
                    if (prev === 0) return items.length - 1;
                    return prev - 1;
                });
            } else if (e.keyCode === 40) {
                setHovered(prev => {
                    if (items.length - 1 === prev) return 0;
                    return prev + 1
                });
            } else if (e.keyCode === 13) {
                onClick(items[hovered]);
                setShow(false);
            }
        }
    }, [show, items, onClick, hovered, setShow]);

    useEffect(() => {
        document.addEventListener("keyup", onKeyUp);

        return () => {
            document.removeEventListener("keyup", onKeyUp);
        }
    }, [onKeyUp]);

    useEffect(() => {
        if (container.current) {
            container.current.children[hovered].scrollIntoView({ block: "nearest" });
        }
    }, [hovered]);

    return (
        <Fade in={show} mountOnEnter unmountOnExit>
            <div className="sql-input-history" ref={container}>
                {items.map((value, index) => <SQLAbsoluteListItem item={value} key={value} onClick={onClick} index={index} deleteItem={onDelete} hovered={index === hovered} />)}
            </div>
        </Fade>
    )
}

function SQLHints({ caretPosition, value, show, setSQL, setShow }) {

    const stringToSpace = useMemo(() => {
        let tmp = value.slice(0, caretPosition).split("").reverse().join("");
        const indexOfSpace = tmp.indexOf(" ");
        if (indexOfSpace === -1) {
            return tmp.split("").reverse().join("").toLowerCase();
        }
        return tmp.slice(0, indexOfSpace).split("").reverse().join("").toLowerCase();
    }, [value, caretPosition]);

    const possibleHints = useMemo(() => {
        return [...SQL_COLUMNS, ...SQL_KEYWORDS].filter(item => {
            const tmp = item.toLowerCase();
            return tmp.startsWith(stringToSpace) && tmp !== stringToSpace;
        });
    }, [stringToSpace]);

    useEffect(() => {
        if (possibleHints.length === 0 && show) {
            setShow(false);
        }
    }, [setShow, possibleHints, show])

    const onClickItem = useCallback((item) => {
        setSQL(prev => {
            return prev.slice(0, caretPosition - stringToSpace.length) + item + prev.slice(caretPosition);
        });
        document.getElementById("sql-input-container").firstChild.focus();
    }, [caretPosition, stringToSpace, setSQL]);

    return (
        <SQLAbsoluteList items={possibleHints} show={show && possibleHints.length > 0 && value.length > 0} onClick={onClickItem} setShow={setShow} />
    )
}

export default forwardRef(function SQLInput({ value, onChange, placeholder }, ref) {

    const [savedValues, setSavedValues] = useLocalStorage("mapSQL", [], true);

    const [caretPosition, setCaretPosition] = useState(0);
    const [isFocused, setIsFocused] = useState(false);
    const [showHints, setShowHints] = useState(false);

    const onKeyUp = useCallback(e => {
        if (e.keyCode === 32 && e.ctrlKey) { // CTRL + SPACE
            setShowHints(true);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keyup", onKeyUp);

        return () => {
            document.removeEventListener("keyup", onKeyUp);
        }
    }, [onKeyUp]);

    const onCaretPositionChange = useCallback((e) => {
        setTimeout(() => {
            setCaretPosition(e.target.selectionStart);
        }, 0)
        if (e.keyCode === 38 || e.keyCode === 40) {
            e.preventDefault();
        }
    }, []);

    const onFocus = useCallback((e) => {
        setIsFocused(true);
    }, []);

    const onBlur = useCallback((e) => {
        setIsFocused(false);
        setShowHints(false);
    }, []);

    const deleteItem = useCallback(index => {
        const newSavedValues = [...savedValues];
        newSavedValues.splice(index, 1);
        setSavedValues(newSavedValues);
    }, [savedValues, setSavedValues]);

    const addSavedValue = useCallback((value) => {
        const newSavedValues = uniq([value, ...savedValues]);
        setSavedValues(newSavedValues);
    }, [savedValues, setSavedValues])

    useImperativeHandle(ref, () => ({
        addSavedValue
    }), [addSavedValue]);

    return (
        <div id="sql-input-container">
            <Input type="text" value={value} onChange={onChange} className="mb-0" onFocus={onFocus} onBlur={onBlur} onKeyDown={onCaretPositionChange} onClick={onCaretPositionChange} placeholder={placeholder} />
            <SQLHints caretPosition={caretPosition} value={value} setSQL={onChange} show={showHints} setShow={setShowHints} />
            <SQLAbsoluteList items={savedValues} show={isFocused && value.length === 0} onDelete={deleteItem} onClick={onChange} setShow={setIsFocused} />
        </div>
    )
})