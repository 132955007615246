import {VARIABLE_STATUS, VARIABLE_WRITE_STATUS} from "../utils/monitor/VariableStatus";
import {Milliseconds} from "../constans/milliseconds";

const makeSubmittingCloneWithCleanUp = (state) => {
    const newSubmitting = {...state.writeMemoryStatus};
    const now = Date.now();
    for (let key in newSubmitting) {
        if (newSubmitting.hasOwnProperty(key)) {
            if ((now - newSubmitting[key].timestamp) > Milliseconds.MINUTE) {
                delete newSubmitting[key];
            }
        }
    }
    return newSubmitting;
}


const makeInitialValue = () => ({
    readMemoryStatus: {},
    writeMemoryStatus: {},
    memoryData: {},
    watchedVariables: {},
    extended: {},
    selectedId: null,
    variables: [], // list of the variables
    queue: null, // queue for mqtt communication
    addToQueue: null, // function which adds task to the queue
    DevID: null, // selected device which we will be sending/reading data from
    watchMode: true,
    initialized: false,
    initializing: false
})

const initialValue = makeInitialValue();

export const MONITOR_REDUCER_ACTIONS = {
    INITIALIZE: "MONITOR_INITIALIZE",
    INITIALIZE_PENDING: "MONITOR_INITIALIZE_PENDING",
    INITIALIZE_REJECTED: "MONITOR_INITIALIZE_REJECTED",
    INITIALIZE_FULFILLED: "MONITOR_INITIALIZE_FULFILLED",
    DESTROY: "MONITOR_DESTROY",
    READ_RAM: "MONITOR_READ_RAM",
    READ_RAM_PENDING: "MONITOR_READ_RAM_PENDING",
    READ_RAM_FULFILLED: "MONITOR_READ_RAM_FULFILLED",
    READ_RAM_REJECTED: "MONITOR_READ_RAM_REJECTED",
    WRITE_RAM: "MONITOR_WRITE_RAM",
    WRITE_RAM_PENDING: "MONITOR_WRITE_RAM_PENDING",
    WRITE_RAM_FULFILLED: "MONITOR_WRITE_RAM_FULFILLED",
    WRITE_RAM_REJECTED: "MONITOR_WRITE_RAM_REJECTED",
    INVALIDATE_BY_ID: "MONITOR_INVALIDATE_BY_ID",
    INVALIDATE_ALL: "MONITOR_INVALIDATE_ALL",
    WATCHLIST_SHOW_TOGGLE: "MONITOR_WATCHLIST_SHOW_TOGGLE",
    WATCHLIST_TOGGLE: "MONITOR_WATCHLIST_TOGGLE",
    WATCHLIST_CLEAR: "MONITOR_WATCHLIST_CLEAR",
    SET_EXTENDED: "MONITOR_SET_EXTENDED",
    SET_SELECTED: "MONITOR_SET_SELECTED",
}

const setReadMemoryStatus = (state, variableId, status) => {
    return {
        ...state.readMemoryStatus,
        [variableId]: status
    };
}

const setWriteMemoryStatus = (state, variableId, status) => {
    return {
        ...makeSubmittingCloneWithCleanUp(state),
        [variableId]: {status, timestamp: Date.now()}
    }
}

const setMemoryData = (state, variableId, {buffer, addr, size}) => {
    return {
        ...state.memoryData,
        [variableId]: {buffer, addr, size}
    }
}

export default function monitorReducer(state = initialValue, action) {
    switch (action.type) {
        case MONITOR_REDUCER_ACTIONS.INITIALIZE_PENDING: {
            const {queue, addToQueue, DevID} = action.meta;
            return {
                ...makeInitialValue(),
                queue,
                addToQueue,
                DevID: DevID,
                initialized: true,
                initializing: true
            };
        }
        case MONITOR_REDUCER_ACTIONS.INITIALIZE_FULFILLED: {
            const {variables} = action.payload;
            return {
                ...state,
                variables,
                initializing: false
            };
        }
        case MONITOR_REDUCER_ACTIONS.READ_RAM_PENDING: {
            const {variableId} = action.meta;
            return {
                ...state,
                readMemoryStatus: setReadMemoryStatus(state, variableId, VARIABLE_STATUS.PENDING)
            }
        }
        case MONITOR_REDUCER_ACTIONS.READ_RAM_FULFILLED: {
            const {variableId, addr, size} = action.meta;
            const {readRAM} = action.payload;
            return {
                ...state,
                readMemoryStatus: setReadMemoryStatus(state, variableId, VARIABLE_STATUS.FULFILLED),
                memoryData: setMemoryData(state, variableId, {
                    buffer: Buffer.from(readRAM).buffer,
                    addr,
                    size
                })
            }
        }
        case MONITOR_REDUCER_ACTIONS.READ_RAM_REJECTED: {
            const {variableId} = action.meta;
            return {
                ...state,
                readMemoryStatus: setReadMemoryStatus(state, variableId, VARIABLE_STATUS.REJECTED)

            }
        }
        case MONITOR_REDUCER_ACTIONS.WRITE_RAM_PENDING: {
            const {localId} = action.meta;
            return {
                ...state,
                writeMemoryStatus: setWriteMemoryStatus(state, localId, VARIABLE_WRITE_STATUS.SUBMITTING)
            };
        }
        case MONITOR_REDUCER_ACTIONS.WRITE_RAM_REJECTED: {
            const {localId} = action.meta;
            return {
                ...state,
                writeMemoryStatus: setWriteMemoryStatus(state, localId, VARIABLE_WRITE_STATUS.ERROR)
            };

        }
        case MONITOR_REDUCER_ACTIONS.WRITE_RAM_FULFILLED: {
            const {variableId, localId} = action.meta;
            return {
                ...state,
                writeMemoryStatus: setWriteMemoryStatus(state, localId, VARIABLE_WRITE_STATUS.SUBMITTED),
                readMemoryStatus: setReadMemoryStatus(state, variableId, VARIABLE_STATUS.INVALIDATED),
            };
        }
        case MONITOR_REDUCER_ACTIONS.INVALIDATE_BY_ID: {
            const {variableId} = action.payload;
            return {
                ...state,
                readMemoryStatus: setReadMemoryStatus(state, variableId, VARIABLE_STATUS.INVALIDATED)
            };
        }
        case MONITOR_REDUCER_ACTIONS.INVALIDATE_ALL: {
            const readStatusEntries = Object.entries(state.readMemoryStatus);
            const readMemoryStatus = {...state.readMemoryStatus};
            let isInvalidated = false;
            readStatusEntries.forEach(([k, v]) => {
                // invalidate only variables that are marked as fulfilled or rejected
                if ([VARIABLE_STATUS.FULFILLED, VARIABLE_STATUS.REJECTED].includes(v)) {
                    readMemoryStatus[k] = VARIABLE_STATUS.INVALIDATED;
                    isInvalidated = true;
                }
            })
            return isInvalidated ? {
                ...state,
                readMemoryStatus
            } : state;
        }
        case MONITOR_REDUCER_ACTIONS.WATCHLIST_TOGGLE: {
            const {variableId} = action.payload;
            const list = {...state.watchedVariables}
            if (list[variableId]) delete list[variableId];
            else list[variableId] = true;
            return {
                ...state,
                watchedVariables: list
            }
        }
        case MONITOR_REDUCER_ACTIONS.WATCHLIST_SHOW_TOGGLE: {
            return {
                ...state,
                watchMode: !state.watchMode
            }
        }
        case MONITOR_REDUCER_ACTIONS.SET_EXTENDED: {
            return {
                ...state,
                extended: {
                    ...state.extended,
                    [action.payload.localId]: !!action.payload.value
                }
            }
        }
        case MONITOR_REDUCER_ACTIONS.SET_SELECTED: {
            return {
                ...state,
                selectedId: action.payload.localId
            }
        }
        case MONITOR_REDUCER_ACTIONS.INITIALIZE_REJECTED:
        case MONITOR_REDUCER_ACTIONS.DESTROY:
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return makeInitialValue();
        }
        default:
            return state
    }
}

