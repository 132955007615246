import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "../../../../basics/button/Button";

class Icon extends Component {

    state = {
        submitting: false
    }

    onClick = () => {
        const {submit} = this.props;
        this.setState({
            submitting: true
        }, () => {
            submit().finally(() => {
                this.setState({
                    submitting: false
                })
            })
        });
    }

    render() {
        const {icon} = this.props;
        const {submitting} = this.state;
        return (
            <Button title={icon.description} className={"m-0"} onClick={this.onClick}
                    buttonStyle={"bordered"}
                    icon={<i className={icon.iconName}/>}
                    type={"button"} isLoading={submitting}>
                {icon.name}
            </Button>
        );
    }
}

Icon.propTypes = {
    icon: {
        iconName: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    },
    submit: PropTypes.func.isRequired
};

export default Icon;
