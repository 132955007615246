import {withTranslation} from "react-i18next";
import SummaryTemplate from "./SummaryTemplate";
import React from "react";
import {parseFeed} from "../parsers";
import {makeExtractKeys} from "../../../../utils/FarmMapUtils";

const getKeys = makeExtractKeys();

function FeedSummary({devices, data, t}) {
    const result = [];
    const keys = getKeys(devices);
    for (let key of keys) {
        const {text, isLive} = parseFeed(data[key]);
        result.push(<SummaryTemplate icon={"fas fa-wheat"} header={t("forageDispensed")} alerts={data[key]?.Alerts}
                                     warnings={data[key]?.Warnings}
                                     isWarning={!isLive}
                                     content={text}/>)
    }
    return result;
}

export default withTranslation()(FeedSummary);