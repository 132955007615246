import lokiDB from "./lokiDB"

class FormData {

    insertFormData(formName, data, FarmID) {
        console.log(formName, data);
        lokiDB.formData.insert({ formName, data, time: new Date().getTime(), FarmID });
        lokiDB.saveDB();
    }

    getSavedData(formName, FarmID) {
        let data = lokiDB.formData.findOne({ formName, FarmID });
        if (!data) return null;
        lokiDB.formData.chain().find({ formName }).remove();
        lokiDB.saveDB();
        return data;
    }

    getDrafts(formNames, FarmID) {
        let drafts = [];
        for (let formName of formNames) {
            let data = lokiDB.formData.find({ formName: { $eq: formName }, FarmID });
            drafts.push(...data);
        }
        return drafts;
    }

}

const formDataDB = new FormData();
export default formDataDB;
