import {ReactLocalStorage} from "../utils/ReactLocalStorage";
import Paths from "../api/paths";
import {invokeApig} from "../libs/awsLib";
import moment from "moment";
import {getFarmID} from "../selectors/farmSelector";


const getNotifications = (FarmID, {LastEvaluatedKey, signal, Limit = 100} = {}) => invokeApig({
    ...Paths.listNotifications({farmID: FarmID}),
    queryParams: {
        DtaModTime: +moment().endOf("day"),
        newAPI: true, ...!!LastEvaluatedKey && {...LastEvaluatedKey},
        Limit
    },
    forceFetch: true,
    signal
})

export const getLatestNotifications = (FarmID, {signal} = {}) => {
    return (dispatch) => {
        const readTime = ReactLocalStorage.getPrivate(`notificationReadTime_${FarmID}`, 0);
        dispatch({
            type: "GET_LATEST_NOTIFICATIONS",
            payload: getNotifications(FarmID, {signal, Limit: 1}),
            meta: {readTime}
        })
    }
}

export const setFilter = (filter = "") => {
    return (dispatch) => {
        dispatch({
            type: "SET_NOTIFICATION_FILTER",
            payload: filter
        })
    }
}

export const setShow = (key, value) => {
    return (dispatch) => {
        dispatch({
            type: "SET_NOTIFICATION_SHOW",
            payload: {key, value: !!value}
        })
    }
}

export const clear = () => {
    return (dispatch) => {
        dispatch({
            type: "CLEAR_NOTIFICATIONS",
            payload: true
        })
    }
}

export const listNotifications = (FarmID, ListNewer = false) => {
    return (dispatch, getState) => {
        const state = getState();
        const LastEvaluatedKey = !ListNewer ? state?.notificationCenter?.lastEvaluatedKey ?? null : null;
        dispatch({
            type: "LIST_NOTIFICATIONS",
            payload: getNotifications(FarmID, {LastEvaluatedKey}),
            meta: {
                FarmID,
                ListNewer
            }
        })

    }
}

export const listOlder = (FarmID) => listNotifications(FarmID, false);

export const listNewer = (FarmID) => listNotifications(FarmID, true);

export const setReadTime = (time, farmId) => {
    return (dispatch, getState) => {
        const FarmID = farmId ?? getFarmID(getState());
        const currentTime = Math.max(ReactLocalStorage.getPrivate(`notificationReadTime_${FarmID}`, 0), time);
        ReactLocalStorage.setPrivate(`notificationReadTime_${FarmID}`, currentTime);
        dispatch({
            type: "SET_NOTIFICATIONS_READ_TIME",
            payload: time
        })
    }
}