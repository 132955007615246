import React, {Component} from 'react';
import {Fade} from "react-bootstrap";

class FileVideoShow extends Component {

    state = {
        show: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.url !== prevProps.url) {
            this.setState({
                show: false
            })
        }
    }

    onLoadedData = () => {
        this.props.onLoadFile();
        this.setState({
            show: true
        })
    }

    render() {
        const {url} = this.props;
        const {show} = this.state;
        if (!url) return null;
        return (
            <Fade in={show}>
                <video src={url} onLoadedData={this.onLoadedData} autoPlay controls/>
            </Fade>
        );
    }
}

export default FileVideoShow;
