import React, { Component } from "react";
import BasicInfoBox from "./BasicInfoBox";
import { AnimalTypes } from "../../constans/animalTypes";
import SowCard from "./cards/SowCard";
import BoarCard from "./cards/BoarCard";
import BasicInfoGroupBox from "./BasicInfoGroupBox";
import GroupCard from "./cards/GroupCard";
import { connect } from "react-redux";
import RenovationSowCard from "./cards/RenovationSowCard";
import InfoBox from "../basics/info-box/InfoBox";
import { EventTypes } from "../../constans/eventTypes";
import { get } from "lodash";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import withRoles from "../withRoles";
import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import PorkerPigletCard from "./cards/PorkerPigletCard";

function mapStateToProps(state) {
    return {
        selectedItem: state.animalDocuments.selectedItem,
        selectionReasons: state.dictionary.selectionReason,
        lang: state.language.lang.lang,
    };
}

class DocumentBody extends Component {
    getSelectionInfoBox = () => {
        const {
            events,
            selectionReasons: { WData },
            t,
        } = this.props;
        const sensitiveEvents = events.filter((e) =>
            [EventTypes.SELL, EventTypes.FALL].includes(e.EvCode)
        );
        const selection = events.find((e) => e.EvCode === EventTypes.SELECTION);
        if (selection && sensitiveEvents.length === 0) {
            let reason = WData.find(
                (item) => item.ID === selection.EvData.Reasn
            );
            return (
                <InfoBox boxColor="warning">
                    <i className="fas fa-clipboard-check ms-2 me-2" />
                    {t("animalDocuments.hasBeenSelected", {
                        reason: get(reason, "Value", ""),
                    })}
                </InfoBox>
            );
        }
        return null;
    };

    render() {
        const { selectedItem, events, mobile, loading } = this.props;
        if (!selectedItem) return null;
        return (
            <>
                {!loading && selectedItem?.AnmID && (
                    <>
                        {this.getSelectionInfoBox()}
                        <BasicInfoBox
                            showEdit={true}
                            events={events}
                            animal={selectedItem}
                        />
                        {selectedItem.AnimalKind !== undefined && (
                            <>
                                {selectedItem.AnimalKind ===
                                    AnimalTypes.RENOVATION_SOW && (
                                        <RenovationSowCard />
                                    )}
                                {selectedItem.AnimalKind ===
                                    AnimalTypes.SOW && (
                                        <SowCard mobile={mobile} />
                                    )}
                                {selectedItem.AnimalKind ===
                                    AnimalTypes.BOAR && <BoarCard />}
                                {[
                                    AnimalTypes.PIGLET,
                                    AnimalTypes.PORKER,
                                ].includes(selectedItem.AnimalKind) && (
                                        <PorkerPigletCard />
                                    )}
                            </>
                        )}
                    </>
                )}
                {!loading && selectedItem?.AnmGrp && (
                    <>
                        <BasicInfoGroupBox />
                        <GroupCard />
                    </>
                )}
            </>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRoles({ roles: [RoleTypes.BREEDING], showComponent: true })
)(DocumentBody);
