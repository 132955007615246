import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {Field} from "redux-form";
import ReduxLabeledInput from "../basics/input/labeled-input/ReduxLabeledInput";
import InfoBox from "../basics/info-box/InfoBox";
import {isString} from "lodash";

export default ({name, minimal = false, edit = true, group = false}) => {

    const {t} = useTranslation();

    const validateName = useCallback((value) => name !== value ? t("errors.validAnimalNumber") : null, [t, name]);

    const validateComment = useCallback((value) => {
        if (!isString(value)) return t("required")
        if (value.trim().length <= 3) return t("required");
        return null;
    }, [t]);

    const animalNameField = (
        <Field label={group ? t("groupNumber") : t("animalNumber")} component={ReduxLabeledInput} type={"text"}
               id={"_Name"}
               required
               validate={validateName}
               name={"_Name"}/>
    );

    const commentField = (
        <Field label={edit ? t("modificationReason") : t("removalReason")} component={ReduxLabeledInput}
               type={minimal ? "text" : "textarea"}
               id={"_Comment"}
               required
               validate={validateComment}
               name={"_Comment"}/>
    )

    // use this when your container is tiny and cant handle InfoBox
    if (minimal) {
        return (
            <>
                <fieldset className="fieldset mb-2">
                    <legend>{t("confirmModification.inOrderToUnlockActionEnter")}</legend>
                    {animalNameField}
                    {commentField}
                </fieldset>
            </>
        )
    }
    return (
        <>
            <fieldset className="fieldset mb-2">
                <legend>{t("confirmModification.verificationNeeded")}</legend>
                <InfoBox boxColor={"info"} outlined>
                    {t("confirmModification.inOrderToUnlockActionEnter")}
                </InfoBox>
                {animalNameField}
                {commentField}
            </fieldset>
        </>
    )
}