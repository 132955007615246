import StaticListParamsHandler from "./StaticListParamsHandler";
import {isRestored} from "./utils";

const handler = StaticListParamsHandler(({params, translationManager, changes}) => {
    if (isRestored(changes)) {
        // jeśli został usunięty `DtaDelTime` to zmien tłumaczenie
        return translationManager.translate(`animalModList.restoreAnimal`, params);
    }
    return translationManager.translate(`animalModList.updateAnimal`, params);
});

handler.RegExp = new RegExp(`^2014$`, "g");

export default handler;