import {getRotatedData} from "../utils";
import SVGPaths from "../../components/SVGPaths";
import React from "react";
import Material from "./Material";

const NutriProV2 = (props, orientation) => {
    const {id, down, right, className} = props;
    const height = 120;
    const width = 60;

    const {angle, viewBox, toAbsolute} = getRotatedData(orientation, down, right, width, height);
    return (
        <pattern className={className} id={id} x="0" y="0" width="100%" height="100%"
                 viewBox={viewBox}
                 patternUnits="objectBoundingBox">
            <SVGPaths.NutriProV2Outline className={Material.STEEL_LIGHT} translate={toAbsolute(0, 0)}
                                        rotate={[angle, 0, 0]}/>
            <SVGPaths.NutriProV2Trough className={Material.STEEL} translate={toAbsolute(0, 0)}
                                       rotate={[angle, 0, 0]}/>
            <SVGPaths.Dispenser className={Material.DISPENSER} translate={toAbsolute(`${width / 2}-50%`, 20)}
                                rotate={[[90], [angle, 0, 0]]}/>
            <SVGPaths.Dispenser className={Material.DISPENSER} translate={toAbsolute(`${width / 2}-50%`, 80)}
                                rotate={[[-90], [angle, 0, 0]]}/>

        </pattern>
    )
}

export default NutriProV2;