const initialValue = {
    video: null
};

export default function loadingReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_PIP_VIDEO":
            return {...state, video: action.payload};
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
