import store from "../store/store";

export function pushMessageToServiceWorker(
    locale = undefined,
    lang = undefined,
    sub = undefined,
    isService = undefined,
    user = null
) {
    if (
        navigator &&
        "serviceWorker" in navigator &&
        navigator.serviceWorker.controller
    ) {
        let state = store.getState();
        navigator.serviceWorker.controller.postMessage(
            JSON.stringify({
                locale: locale || state.language.locale,
                lang: lang || state.language.lang,
                sub: sub || state.user.attributes.sub,
                isService,
                user: user || state.user.user,
            })
        );
    }
}

export function pushRefreshTokenAndDeviceKeyToServiceWorker(
    refreshToken,
    deviceKey,
    accessToken
) {
    if (
        navigator &&
        "serviceWorker" in navigator &&
        navigator.serviceWorker.controller
    ) {
        navigator.serviceWorker.controller.postMessage(
            JSON.stringify({
                refreshToken,
                deviceKey,
                accessToken,
                type: "REFRESH_TOKEN",
            })
        );
    }
}
