import RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import { setSingleRelay } from "../../../../../IOT/device-functions/RelayFunctions";
import { changeMapShadowDelta } from "../../../../../actions/mapShadowActions";
import store from "../../../../../store/store";
import { getAlias, getDeviceGroup, getFlatDevices } from "../../../../../utils/DevicesUtils";
import { groupBy } from "lodash";
import { executeSequentially } from "../../../../../utils/Utils";
import { notify } from "reapop";

export const turnLights = ({ t, devices, roleUtils }, turnOn = true) => {
    const allLights = getFlatDevices(devices.filter((device) => getDeviceGroup(device) === "lights"), false)
        .filter(({ index, device }) => roleUtils.hasRole({
            roles: [RoleTypes.DEVICE_CONFIG],
            device: device,
            index: index
        }))
    if (allLights.length === 0) return null;
    const dispatch = store.dispatch;
    return [{
        key: `light-turn-${turnOn ? "on" : "off"}`,
        label: turnOn ? t("actions.turnOnImperative") : t("actions.turnOffImperative"),
        show: true,
        icon: turnOn ? "fas fa-fw fa-lightbulb-on color-yellow" : "fas fa-fw fa-lightbulb",
        submit: () => {
            // group light outputs by parent device
            const groupedLights = Object.values(groupBy(allLights, ({ device }) => device.DevID));
            // we may update different devices single at once
            return Promise.allSettled(groupedLights.map((lights) => {
                // every output on single device should be updated sequentially to make sure everything saves on dynamodb
                return executeSequentially(lights.map(({ device, index }) => {
                    return () => {
                        const key = `${device.DevID}_${index}`;
                        return new Promise((resolve, reject) => {
                            setSingleRelay(device, { relayIndex: index, state: !!turnOn }, {
                                onFailure: reject,
                                onSuccess: resolve,
                                showNotification: false
                            })
                        }).then(() => {
                            dispatch(changeMapShadowDelta({
                                [key]: {
                                    State: !!turnOn
                                }
                            }))
                        }).catch(() => {
                            dispatch(notify({
                                title: t("IOT.setSingleRelay"),
                                message: t("IOT.setSingleRelayFailed", { name: getAlias(device, index) }),
                                dismissible: true,
                                dismissAfter: 3000,
                                status: "error"
                            }))
                        })
                    }
                }));
            }));
        }
    }];
}
