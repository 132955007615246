import {isString, isObject, isArray} from "lodash";

export const isVirtualPlacement = (placementString) => {
    if (!isString(placementString)) return false;
    return placementString.startsWith("RM#");
}

export const removeVirtualPlacement = (placementString) => placementString.replace("RM#", "");

const extractFromObject = (placementObj) => isString(placementObj?.PlcmntID) ? placementObj?.PlcmntID : null;
const extractFromArray = (placementArray) => isString(placementArray?.[0]) ? placementArray?.[0] : isObject(placementArray[0]) ? extractFromObject(placementArray[0]) : null;

export const normalizePlacement = (placement, {revertVirtualPlacements = true} = {}) => {
    let placementString = null;
    if (isArray(placement)) {
        placementString = extractFromArray(placement);
    } else if (isObject(placement)) {
        placementString = extractFromObject(placement)
    } else if (isString(placement)) {
        placementString = placement;
    }
    if (revertVirtualPlacements && isVirtualPlacement(placementString)) {
        return removeVirtualPlacement(placementString);
    }
    return placementString;
}