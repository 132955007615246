import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getDeviceData } from "../../../actions/aggregatedActions";
import useDeviceLocations from "./useDeviceLocations";

/**
 * automatyczne pobieranie danych potrzebych do chartów z api
 * @param ref {Element} - div z chartem
 * @param type {string}
 * @param start {number}
 * @param end {number}
 * @return {{data: unknown, show, locations: function(*): * extends ((...args: any) => infer R) ? R : any, loading: boolean, error: unknown}}
 */
const useAggregatedLoader = (type, start, end) => {

    const fetchedData = useRef(false);

    const locations = useDeviceLocations(type);

    const dispatch = useDispatch();

    useEffect(() => {
        fetchedData.current = false;
    }, [start, end])

    useEffect(() => {
        if (locations.length > 0 && !fetchedData.current) {
            dispatch(getDeviceData({
                Start: start,
                End: end,
                Placements: locations,
                Type: type
            }))
        }
        // setError(null);
        // getDeviceData({
        //     Start: start,
        //     End: end,
        //     Placements: locations,
        //     Type: type
        // }).then(({ result }) => setData(result)).catch(error => {
        //     setError(error || "Unknown Error");
        //     setData(null);
        // }).finally(() => {
        //     setLoading(false);
        // });
    }, [start, end, dispatch, locations]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useAggregatedLoader;