import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import memoizeOne from "memoize-one";
import {getEventsFromRow} from "../../../utils/AnimalDocumentsUtils";
import {findRowFromCycle} from "../../grids/animal-documents/mini-components/EditEventPopupValidatorUtils";
import {checkIfCanRemoveEventFromCycle, getPigBalance} from "../../../utils/EventUtils";
import ConfirmModificationField from "../../custom-fields/ConfirmModificationField";
import {cloneDeep} from "lodash";
import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess
} from "../../../api/cycles/editSowCycle";

export const ModalName = 'sow-card-remove-event-modal';

class SowCardRemoveEventModal extends Component {

    constructor(props) {
        super(props);
        const {handleHide, selectedEvent} = this.props;
        if (!selectedEvent) {
            handleHide();
        }
    }

    getData = memoizeOne((t, cycleTable, selectedEvent, EvID, animal) => {
        let disableSubmit = false;
        let message = t("animalDocuments.deleteEventMessage", {AnmNo1: animal?.AnmNo1});
        if (selectedEvent) {
            const cycleEvents = getEventsFromRow(findRowFromCycle(cycleTable, selectedEvent)).filter((e) => e.EvID !== EvID);
            const balance = getPigBalance(cycleEvents);
            if (balance < 0) {
                message = t("animalDocuments.deleteEventMesssageBalanceError", {balance});
                disableSubmit = true;
            } else if (!checkIfCanRemoveEventFromCycle(cycleTable, selectedEvent)) {
                message = t("animalDocuments.deleteEventMessageFutureEventsError");
                disableSubmit = true;
            }
        }
        return {
            message,
            disableSubmit
        }
    })

    render() {
        const {
            show,
            handleHide,
            t,
            handleSubmit,
            submitting,
            selectedEvent,
            cycleTable = [],
            EvID,
            animal,
            invalid
        } = this.props;
        const {message, disableSubmit} = this.getData(t, cycleTable, selectedEvent, EvID, animal)

        return (
            <Modal onHide={handleHide} show={selectedEvent ? show : false} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("animalDocuments.deleteEventTitle")}
                        onCloseClick={handleHide}/>
                    <ModalBody>
                        <p>{message}</p>
                        <ConfirmModificationField name={animal?.AnmNo1} edit={false}/>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        hasButtonDisabled={invalid || disableSubmit}
                        confirmText={t("yes")}
                        closeText={t("cancel")}
                        onCloseClick={handleHide}
                        submitting={submitting}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }
}

SowCardRemoveEventModal.propTypes = {
    animalEvents: PropTypes.array.isRequired,
    EvID: PropTypes.string.isRequired,
    animal: PropTypes.object.isRequired,
    cycleTable: PropTypes.array.isRequired
};

const submit = (values, dispatch, props) => {
    console.log(values, props);
    const eventCopy = cloneDeep(props.selectedEvent);
    if (values._Comment) {
        eventCopy._Comment = values._Comment;
    }
    const data = {AnmID: props.animal.AnmID, eventsToUpdate: [], eventsToDelete: [eventCopy]};
    return editSowCycle(data, {FarmID: eventCopy.FarmID}).then((res) => {
        editSowCycleNotificationSuccess(res);
        props.handleHide();
    }).catch((e) => {
        editSowCycleNotificationFailure(e);
    });
}

function makeMapStateToProps() {
    const getSelectedEvent = memoizeOne((animalEvents, EvID) => {
        return (animalEvents ?? []).find((e) => e.EvID === EvID);
    });
    return (state, props) => ({
        selectedEvent: getSelectedEvent(props.animalEvents, props.EvID)
    })
}

export default compose(
    connectModal({name: ModalName}),
    connect(makeMapStateToProps),
    reduxForm({
        form: ModalName,
        onSubmit: submit
    }),
    withTranslation(),
)(SowCardRemoveEventModal)
