import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import i18n from "../../i18n";
import {
    createErrorNotification,
    createSuccessNotification,
} from "../../utils/ResponseUtils";

export function createTagging(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({
        FarmID,
        ClientID,
        LocalUserID,
    });
    return invokeApig({
        ...Paths.createTagging({
            farmID: _FarmID,
            clientID: _ClientID,
            localUserID: _LocalUserID,
        }),
        body: data,
    });
}

export function createTaggingNotificationSuccess(
    response,
    {uniqueId = myID()} = {}
) {
    if (createSuccessNotification(response, "tagging")) return;
    store.dispatch(
        notify({
            id: uniqueId,
            title: i18n.t("tagging"),
            message: i18n.t("apiNotifications.createTaggingSuccess"),
            status: "success",
            dismissible: true,
            dismissAfter: 5000,
        })
    );
}

export function createTaggingNotificationFailure(
    response,
    {uniqueId = myID()} = {}
) {
    if (createErrorNotification(response, "tagging", {uniqueId})) return;
    store.dispatch(
        notify({
            id: uniqueId,
            title: i18n.t("tagging"),
            message: i18n.t("apiNotifications.createTaggingFailure"),
            status: "error",
            dismissible: true,
            // buttons: checkLogsButton(),
            dismissAfter: 15000,
        })
    );
}
