import lokiDB from "./lokiDB";

class FiltersDB {

    saveFilter(name, filter) {
        lokiDB.filters.chain().find({ name }).remove();
        lokiDB.filters.insert({ name, filter });
        lokiDB.saveDB();
    }

    getFilter(name) {
        return lokiDB.filters.findOne({ name })?.filter || null;
    }

}

const filtersDB = new FiltersDB();
export default filtersDB;
