import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

/**
 * Pobiera tłumaczenia zapisane na S3
 * @param languages {Array<string>}     tablica języków do pobrania
 * @param LocalUserID {string}
 * @param forceFetch {boolean}
 * @return {Promise<any|undefined>}
 */
export function getTranslations(languages, {LocalUserID = "", forceFetch = false} = {}) {
    const {_LocalUserID} = getUserApiData({LocalUserID});
    return invokeApig({
        ...Paths.getTranslations({localUserID: _LocalUserID}),
        queryParams: {
            languages: languages
        },
        forceFetch
    })
}
