import {invokeApig} from "../../libs/awsLib";
import {getUserApiData} from "../../utils/UserUtils";
import {notify} from "reapop";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import Paths from "../paths";
import i18next from "i18next";
import {createErrorNotification, createSuccessNotification} from "../../utils/ResponseUtils";

/**
 * @link https://app.nuclino.com/Wesstron/Fetura/Przeniesienie-4a5e56a0-1b84-493b-80a4-7e35f4538d71
 * @param data {{
 *     AnmID: string,
 *     transferTo: string,
 *     amount: string|null|undefined,
 *     from: string|null|undefined,
 *     newAnmNo1: string|null|undefined,
 *     stand: boolean|null|undefined,
 *     animalInStand: object|null|undefined,
 *     DevIDsNewPlcmnt: {
 *         DevID: string,
 *         GwID: string
 *     }[]|null|undefined,
 *     DevIDsOldPlcmnt: {
 *         DevID: string,
 *         GwID: string
 *     }[]|null|undefined,
 *     GrID: string|null|undefined,
 *     EvTime: number|null|undefined,
 *     Comment: string|null|undefined,
 *     clearFeeding: boolean|null|undefined,
 *     feeding: {
 *         curveNumber: number,
 *         doseCorrection: number,
 *         curveStart: number,
 *         punishment: number,
 *         stage: number
 *     }|null|undefined,
 *
 * }[]}
 * @param FarmID {string|null}
 * @param ClientID {string|null}
 * @param LocalUserID {string|null}
 * @return {Promise<*|undefined>}
 */
export function createTransfer(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createTransfer({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createTransferNotificationSuccess(response, {uniqueId = myID()} = {}) {
    if (createSuccessNotification(response, "transfer")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18next.t("transfer"),
        message: i18next.t("transferSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createTransferNotificationFailure(response, {uniqueId = myID()} = {}) {
    if (createErrorNotification(response, "transfer", {uniqueId})) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18next.t("transfer"),
        message: i18next.t("transferFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
