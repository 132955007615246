import React, {useMemo, useRef} from "react";
import {useSelector} from "react-redux";
import {
    makeGetAnimalsInGroup,
    makeGetWeightingEvents
} from "../../../selectors/animalDocumentsSelectors";
import {localMomentToUTC} from "../../../utils/DateTimeUtils";
import useAnimalGroupInfo from "../ideal-charts/useAnimalGroupInfo";
import useDomain from "../ideal-charts/useDomain";
import WeightingChart from "./WeightingChart";
import useReferenceLines from "../ideal-charts/useReferenceLines";

const WeightingGroupChart = () => {

    const groupInfo = useAnimalGroupInfo();
    const props = useRef({getDeletedAnimals: true});

    const getAnimalsInGroup = useMemo(() => makeGetAnimalsInGroup(), []);
    const getWeightingEvents = useMemo(() => makeGetWeightingEvents(), []);

    const weightingEvents = useSelector(state => getWeightingEvents(state, props.current));

    const animalsInGroup = useSelector(state => getAnimalsInGroup(state, props.current));

    const domain = useDomain();

    const referenceLines = useReferenceLines(domain, "lineAxis");

    const weightDict = useMemo(() => {
        console.log("weightDictweightDictweightDict");
        const dictionary = {};
        for (const event of weightingEvents) {
            const utcTIME = +localMomentToUTC(event?.EvTime).startOf("day");
            if (!dictionary[utcTIME]) dictionary[utcTIME] = [];
            const animal = animalsInGroup.find((a) => a.AnmID === event.AnmID);
            dictionary[utcTIME].push({
                [event.EvCode]: event?.EvData?.Weight || 0,
                weight: event?.EvData?.Weight || 0,
                Files: event?.EvData?.Files || [],
                AnmCnt: event?.EvData?.AnmCnt || event?.AnmCnt || 1,
                AnmID: event?.AnmID,
                PlcmntID: animal?.DelPlcmntID || animal?.PlcmntID
            });
        }
        return dictionary;
    }, [weightingEvents, animalsInGroup]);

    return (
        <WeightingChart weightDict={weightDict} chartDomain={domain} referenceLines={referenceLines} {...groupInfo} />
    )
}

export default React.memo(WeightingGroupChart);