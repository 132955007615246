import {set} from "lodash";

const initialValue = {
    persistentState: {}
};

export default function deviceRowsReducer(state = initialValue, action) {
    switch (action.type) {
        case "CHANGE_PERSISTENT_STATE": {
            const {value, type, key, id} = action.payload;
            const newState = state.persistentState[type] || {};
            if (!newState[id]) newState[id] = {};
            set(newState[id], key, value);
            return {...state, persistentState: {...state.persistentState, [type]: newState}};
        }
        case "DESTROY_PERSISTENT_STATE": {
            const {type} = action.payload;
            const newState = {...state.persistentState};
            delete newState[type];
            return {...state, persistentState: newState}
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
