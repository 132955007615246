import React from "react";
import {compose} from "redux";
import {connectModal} from "redux-modal";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {withTranslation} from "react-i18next";

export const ModalName = "info-parameter-result-modal";

class InfoParameterResultModal extends React.Component {

    getName() {
        const {translatedName} = this.props;
        return translatedName;
    }

    getParameterType() {
        const {name} = this.props;
        if (!name) return "";
        return name.split(".")[1];
    }

    getDescriptionByType() {
        const {t} = this.props;
        return t(`resultParameterDescription.${this.getParameterType()}`);
    }

    render() {
        const {handleHide, show, t, description} = this.props;
        const name = this.getName();
        const _description = description || this.getDescriptionByType();
        return (
            <Modal onHide={handleHide} show={show} size={"xl"}>
                <ModalHeader title={name} onCloseClick={handleHide}/>
                <ModalBody>
                    <strong>{t("parameterRenderer.parameterInfoModalTooltipContent")}</strong>
                    <br/>
                    {_description}
                </ModalBody>
                <ModalFooter onCloseClick={handleHide}/>
            </Modal>
        );
    }

}

export default compose(
    withTranslation(),
    connectModal({name: ModalName})
)(InfoParameterResultModal);
