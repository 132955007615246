import queryString from "query-string";
import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { compose } from "redux";
import ChunkLoadErrorCatcher from "../components/chunk-load-error-catcher/ChunkLoadErrorCatcher";
////////////////
import FileShow from "../components/files/file-show/FileShow";

const InventoryTutorial = lazy(() =>
    import("./tutorial-views/inventory-tutorial/InventoryTutorial")
);
const TechnologyGroupsTutorial = lazy(() =>
    import(
        "./tutorial-views/technology-groups-tutorial/TechnologyGroupsTutorial"
        )
);
// const ResultsTutorial = lazy(() =>
//     import("./tutorial-views/results-tutorial/ResultsTutorial")
// );
const MapTutorial = lazy(() =>
    import("./tutorial-views/map-tutorial/MapTutorial")
);
const ControlListTutorial = lazy(() =>
    import("./tutorial-views/control-lists-tutorial/ControlListTutorial")
);
const FilesTutorial = lazy(() =>
    import("./tutorial-views/files-tutorial/FilesTutorial")
);
const EventChooserTutorial = lazy(() =>
    import("./tutorial-views/events-tutorial/EventChooserTutorial")
);
const ReportsTutorial = lazy(() =>
    import("./tutorial-views/reports-tutorial/ReportsTutorial")
);
const LogTutorial = lazy(() =>
    import("../components/logs-view-components/log-tutorial/LogTutorial")
);
const UsersTutorial = lazy(() =>
    import("./tutorial-views/users-tutorial/UsersTutorial")
);
const TranslatorsTutorial = lazy(() =>
    import("./tutorial-views/translators-tutorial/TranslatorsTutorial")
);
const ServiceTutorial = lazy(() =>
    import("./tutorial-views/service-tutorial/ServiceTutorial")
);
const SupportTutorial = lazy(() =>
    import("./tutorial-views/support-tutorial/SupportTutorial")
);
const TranslateTutorial = lazy(() =>
    import("./tutorial-views/translate-tutorial/TranslateTutorial")
);
const RoutinesTutorial = lazy(() =>
    import("./tutorial-views/routines-tutorial/RoutinesTutorial")
);
const AutomatedTasksTutorial = lazy(() =>
    import("./tutorial-views/automated-tasks-tutorial/AutomatedTasksTutorial")
);
const NewAutomatedDetailsTasksTutorial = lazy(() => import("./tutorial-views/new-automated-tasks-tutorial/NewAutomatedTasksTutorial"));
const CustomTasksTutorial = lazy(() =>
    import("./tutorial-views/custom-tasks-tutorial/CustomTasksTutorial")
);
const BreedingTutorial = lazy(() =>
    import("./tutorial-views/breeding-tutorial/BreedingTutorial")
);
const BreedingSettingsTutorial = lazy(() =>
    import(
        "./tutorial-views/breeding-settings-tutorial/BreedingSettingsTutorial"
        )
);
const SettlementTutorial = lazy(() =>
    import("./tutorial-views/settlement-tutorial/SettlementTutorial")
);
const GraftingProgramTutorial = lazy(() =>
    import("./tutorial-views/grafting-program-tutorial/GraftingProgramTutorial")
);
const AutomatedActionsTutorial = lazy(() =>
    import(
        "./tutorial-views/automated-actions-tutorial/AutomatedActionsTutorial"
        )
);
const SignUpTutorial = lazy(() =>
    import("./tutorial-views/sign-up-tutorial/SignUpTutorial")
);
const MapEditorTutorial = lazy(() =>
    import("./tutorial-views/map-editor-tutorial/MapEditorTutorial")
);
const NotificationTutorial = lazy(() =>
    import("./tutorial-views/notification-tutorial/NotificationTutorial")
);
const MedicinesTutorial = lazy(() =>
    import("./tutorial-views/medicines-tutorial/MedicinesTutorial")
);
const DictionaryTutorial = lazy(() =>
    import("./tutorial-views/dictionary-tutorial/DictionaryTutorial")
);
const SellTutorial = lazy(() =>
    import("./tutorial-views/sell-tutorial/SellTutorial")
);
const GeneralTutorial = lazy(() =>
    import("./tutorial-views/general-tutorial/GeneralTutorial")
);
//////////
const AnimalTutorial = lazy(() =>
    import("../components/animal-documents/AnimalDocumentTutorial")
);
const ClimateTutorial = lazy(() =>
    import("./tutorial-views/climate-tutorial/ClimateTutorial")
);
const SiloTutorial = lazy(() =>
    import("./tutorial-views/silo-tutorial/SiloTutorial")
);
const WaterTutorial = lazy(() =>
    import("./tutorial-views/water-tutorial/WaterTutorial")
);
const ElectricityTutorial = lazy(() =>
    import("./tutorial-views/electricity-tutorial/ElectricityTutorial")
);
const ChainFeedingTutorial = lazy(() =>
    import("./tutorial-views/chain-feeding-tutorial/ChainFeedingTutorial")
);
const IpsumTutorial = lazy(() =>
    import("./tutorial-views/ipsum-tutorial/IpsumTutorial")
);
const FeedingTutorial = lazy(() =>
    import("../components/devices-rows/dispenser/tutorial/FeedingTutorial")
);
const NutriProTutorial = lazy(() =>
    import("./tutorial-views/nutri-pro-tutorial/NutriProTutorial")
);
const CageTutorial = lazy(() =>
    import("./tutorial-views/cage-tutorial/CageTutorial")
);
const ResultsTutorial = lazy(() =>
    import("./tutorial-views/results-tutorial/ResultsTutorial")
);
function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

class TutorialRoutes extends React.Component {
    render() {
        const {
            location: {search},
            farm,
        } = this.props;
        const searchParams = queryString.parse(search);
        if (searchParams?.documentsAnimal) {
            return (
                <Suspense fallback={<div/>}>
                    <AnimalTutorial/>
                </Suspense>
            );
        } else {
            return (
                <ChunkLoadErrorCatcher>
                    <Suspense fallback={<div/>}>
                        <Switch>
                            {
////////////////////////////////////////////////
                            }
                            <Route
                                exact={false}
                                path={`/farm/${farm}/inventory`}
                                component={InventoryTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/technologyGroups`}
                                component={TechnologyGroupsTutorial}
                            />
                            {/*<Route*/}
                            {/*    exact={false}*/}
                            {/*    path={`/farm/${farm}/results`}*/}
                            {/*    component={ResultsTutorial}*/}
                            {/*/>*/}
                            <Route
                                exact={false}
                                path={`/farm/${farm}/map`}
                                component={MapTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/controlLists`}
                                component={ControlListTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/files`}
                                component={FilesTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/eventChooser`}
                                component={EventChooserTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/reports`}
                                component={ReportsTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/logs`}
                                component={LogTutorial}
                            />
                            <Route
                                exact={true}
                                path={`/users/manageUsers`}
                                component={UsersTutorial}
                            />
                            <Route
                                exact={true}
                                path={`/users/manageTranslators`}
                                component={TranslatorsTutorial}
                            />
                            <Route
                                exact={true}
                                path={`/users/manageServiceAccounts`}
                                component={ServiceTutorial}
                            />
                            <Route
                                exact={true}
                                path={`/users/manageSupport`}
                                component={SupportTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/translate`}
                                component={TranslateTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/settings/routines`}
                                component={RoutinesTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/settings/tasks/technologyGroup`}
                                component={NewAutomatedDetailsTasksTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/settings/tasks/automated`}
                                component={AutomatedTasksTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/settings/tasks/custom`}
                                component={CustomTasksTutorial}
                            />
                            <Route
                                exact={true}
                                path={`/farm/${farm}/settings/breeding`}
                                component={BreedingTutorial}
                            />
                            <Route
                                exact={true}
                                path={`/farm/${farm}/settings/breeding/cycle`}
                                component={BreedingSettingsTutorial}
                            />
                            <Route
                                exact={true}
                                path={`/farm/${farm}/settings/breeding/settlements`}
                                component={SettlementTutorial}
                            />
                            <Route
                                exact={true}
                                path={`/farm/${farm}/settings/breeding/vaccinationPrograms`}
                                component={GraftingProgramTutorial}
                            />
                            <Route
                                exact={true}
                                path={`/farm/${farm}/settings/breeding/automatedActions`}
                                component={AutomatedActionsTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/settings/preferences/notifications`}
                                component={NotificationTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/settings/general/medicines`}
                                component={MedicinesTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/settings/general/dictionary`}
                                component={DictionaryTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/settings/buildings/map`}
                                component={MapEditorTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/sell`}
                                component={SellTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/general`}
                                component={GeneralTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/signup`}
                                component={SignUpTutorial}
                            />
                            {
//////////////////////////////////////////
                            }
                            <Route
                                exact={false}
                                path={`/farm/${farm}/nutriPRO`}
                                component={NutriProTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/climate`}
                                component={ClimateTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/silo`}
                                component={SiloTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/water`}
                                component={WaterTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/electricity`}
                                component={ElectricityTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/chainFeeding`}
                                component={ChainFeedingTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/cage`}
                                component={CageTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/results`}
                                component={ResultsTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/feeding`}
                                component={FeedingTutorial}
                            />
                            <Route
                                exact={false}
                                path={`/farm/${farm}/ipsum`}
                                component={IpsumTutorial}
                            />
                        </Switch>
                        {
////////////////////////////////////////////
                        }
                        <FileShow/>
                        {
//////////////////////////////////////
                        }
                    </Suspense>
                </ChunkLoadErrorCatcher>
            );
        }
    }
}

export default compose(withRouter, connect(mapStateToProps))(TutorialRoutes);
