import store from "../store/store";
import moment from "moment";
import { UnitTypes } from "../constans/unitTypes";
import { get } from "lodash";
import { AnimalTypes } from "../constans/animalTypes";
import { colorsNonInvasive } from "./ColorUtils";
import { AnimalWeightOptions } from "../constans/weightOptions";
import { SectorType } from "../constans/sectorTypes";

//system - units
export function getUnitSystem() {
    let defaultValue = "metric";
    try {
        return store.getState().settings.general.SetData.Settings.System.UnitSystem || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

// dozownizki
export function getFeedingUnit() {
    let defaultValue = UnitTypes.MEDIUM;
    try {
        return get(store.getState(), "settings.general.SetData.Settings.System.FeedingUnit", defaultValue);
    } catch (e) {
        return defaultValue;
    }
}

// zwierzeta
export function getAnimalUnit() {
    let defaultValue = UnitTypes.MEDIUM;
    try {
        return get(store.getState(), "settings.general.SetData.Settings.System.AnimalUnit", defaultValue);
    } catch (e) {
        return defaultValue;
    }
}


// waga najazdowa
export function getVehicleWeightUnit() {
    return UnitTypes.MEDIUM;
}

// panele wagowe
export function getScaleUnit() {
    let defaultValue = UnitTypes.BIG;
    try {
        return get(store.getState(), "settings.general.SetData.Settings.System.ScaleUnit", defaultValue);
    } catch (e) {
        return defaultValue;
    }
}

// stacje selekecyjne
export function getCageUnit() {
    let defaultValue = UnitTypes.MEDIUM;
    try {
        return get(store.getState(), "settings.general.SetData.Settings.System.CageUnit", defaultValue);
    } catch (e) {
        return defaultValue;
    }
}

// liczniki wody
export function getWaterFlowUnit() {
    let defaultValue = UnitTypes.MEDIUM;
    try {
        return get(store.getState(), "settings.general.SetData.Settings.System.WaterFlowUnit", defaultValue);
    } catch (e) {
        return defaultValue;
    }
}

// ###############################################################################################
export function getTechnologyGroupWeeks(general) {
    let defaultValue = 1;
    try {
        return (general ?? store.getState().settings.general).SetData.Settings.Cycle.NumberOfWeeksInCycle || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeFromInseminationToPregnancy(state) {
    let defaultValue = 28;
    try {
        if (state) return state.TimeFromInseminationToPregnancy || defaultValue;
        return store.getState().settings.general.SetData.Settings.Cycle.TimeFromInseminationToPregnancy || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getDaysForRepeatedInsemination(state) {
    let defaultValue = 5;
    try {
        if (state) return state.TimeBetweenInseminations || defaultValue;
        return store.getState().settings.general.SetData.Settings.Cycle.TimeBetweenInseminations || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeFromInseminationToPartuition(state) {
    let defaultValue = 114;
    try {
        if (state) return state.TimeFromInseminationToPartuition || defaultValue;
        return store.getState().settings.general.SetData.Settings.Cycle.TimeFromInseminationToPartuition || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getMaxDelayForBirth(state) {
    let defaultValue = 5;
    try {
        if (state) return state.MaxDelayForBirth || defaultValue;
        return store.getState().settings.general.SetData.Settings.Cycle.MaxPartuitionDelay || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnMatingRoom() {
    let defaultValue = 15;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnMatingRoom || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnSowsRoom() {
    let defaultValue = 100;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnSowsRoom || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnBirthRoom(state) {
    let defaultValue = 28;
    try {
        if (state) return state.TimeOnBirthRoom || defaultValue;
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnBirthRoom || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnPorkHouse() {
    let defaultValue = 115;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnPorkHouse || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnPigletHouse() {
    let defaultValue = 49;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnPigletHouse || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnBirthRoomMommy(state) {
    let defaultValue = 35;
    try {
        if (state) return state.TimeOnBirthRoomMommy || defaultValue;
        return store.getState().settings.general.SetData.Settings.Cycle.TimeOnBirthRoomMommy || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeToAddPorkerToGroup() {
    let defaultValue = 35;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeToAddPorkerToGroup || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeToAddPigletToGroup() {
    let defaultValue = 35;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TimeToAddPigletToGroup || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getFirstCycleIndex(state) {
    let defaultValue = 0;
    try {
        if (state) return state.FirstCycleIndex || defaultValue;
        return store.getState().settings.general.SetData.Settings.Cycle.FirstCycleIndex || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getPassageAccuracy() {
    let defaultValue = 0.5;
    try {
        return store.getState().settings.general.SetData.Settings.Cage.Passage || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getOffline() {
    let defaultValue = false;
    try {
        return store.getState().settings.general.SetData.Settings.Devices.Offline || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getMinAgeForFirstMating() {
    const defaultValue = 240;//dni
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.FirstMatingMinAge || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getMinWeightForFirstMating() {
    const defaultValue = 140 * 1000;//g
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.FirstMatingMinWeight || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getPreparationPeriodForMating() {
    const defaultValue = 3;//tyg
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.MatingPreparationPeriod || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 * funkcja zwracająca rozmiar grupy technologicznej (ilosc kojcow na porodowce)
 * @returns {number}
 */
export function getTechnologyGroupSize() {
    const defaultValue = 200;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TechnologyGroupSize || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getValidationSettings(internalState) {
    const defaultValue = {};
    if (internalState && internalState.validations) return internalState.validations || defaultValue;
    try {
        return store.getState().settings.general.SetData.Settings.Validations || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getFirstTechnologyGroupStart(general) {
    // pierwszy poniedzialek roku
    const defaultValue = moment.utc().startOf("year").isoWeekday(1);
    if (defaultValue.year() !== moment().year()) {
        defaultValue.isoWeekday(8);
    }
    try {
        return (general ?? store.getState().settings.general).SetData.Settings.Cycle.FirstTechnologyGroup || defaultValue.toDate().getTime();
    } catch (e) {
        return defaultValue.toDate().getTime();
    }

}

export function getTechnologyGroupColors() {
    const defaultValue = Object.keys(colorsNonInvasive);
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TechnologyGroupColors || store.getState().settings.general.SetData.Settings.Cycle.Colors || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTechnologyGroupColorsForSettings() {
    const defaultValue = Object.keys(colorsNonInvasive);
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TechnologyGroupColors || store.getState().settings.general.SetData.Settings.Cycle.Colors || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 *
 * @param type {"WST"|"NRF"}
 * @return {number}
 */
export function getFeedingDefaultInseminationCurveNumber(type = "WST") {
    const defaultValue = 0;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding[type].DefaultInseminationCurveNumber || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 *
 * @param type {"WST"|"NRF"}
 * @return {number}
 */
export function getFeedingDefaultParturitionCurveNumber(type = "WST") {
    const defaultValue = 0;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding[type].DefaultParturitionCurveNumber || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 *
 * @param type {"WST"|"NRF"}
 * @return {number}
 */
export function getFeedingDefaultInseminationCurveDay(type = "WST") {
    const defaultValue = 1;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding[type].DefaultInseminationCurveDay || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 *
 * @param type {"WST"|"NRF"}
 * @return {number}
 */
export function getFeedingDefaultParturitionCurveDay(type = "WST") {
    const defaultValue = 1;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding[type].DefaultParturitionCurveDay || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getDefaultCurvesForSectors() {
    const defaultValue = {};
    try {
        return store.getState().settings.general.SetData.Settings.Feeding.DefaultCurves || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getDefaultCurve(sector) {
    const defaultValue = null;
    try {
        return store.getState().settings.general.SetData.Settings.Feeding.DefaultCurves[sector] || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getMedicineDelay() {
    const defaultValue = 0;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.MedicineDelay || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getShouldNotifyFirstMilk() {
    const defaultValue = false;
    try {
        return store.getState().settings.general.SetData.Settings.FirstMilk.Notify || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getNotifyAfterFirstMilk() {
    const defaultValue = 1000 * 60 * 30;
    try {
        return store.getState().settings.general.SetData.Settings.FirstMilk.NotifyAfter || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getDiodeColourFirstMilk() {
    const defaultValue = "#e65100";
    try {
        return store.getState().settings.general.SetData.Settings.FirstMilk.DiodeColour || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getDiodeTimeFirstMilk() {
    const defaultValue = 1000 * 60 * 30;
    try {
        return store.getState().settings.general.SetData.Settings.FirstMilk.DiodeTime || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeForFirstMilk() {
    const defaultValue = 1000 * 60 * 60 * 24;
    try {
        return store.getState().settings.general.SetData.Settings.FirstMilk.Time || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getMedicinesWhenParturition() {
    const defaultValue = [];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.MedicinesWhenParturition || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getMaximumIntervalBetweenSeparations(state) {
    const defaultValue = 14;
    try {
        if (state) return state.MaximumIntervalBetweenSeparations || defaultValue;
        return store.getState().settings.general.SetData.Settings.Cycle.MaximumIntervalBetweenSeparations || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getIdleDays() {
    const defaultValue = 5;
    try {
        return store.getState().settings.general.SetData.Settings.IdleDays || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 * @deprecated
 * @returns {boolean}
 */
export function getManageSubgroups() {
    // ustalone z Danielem, żeby zawsze zwracało false
    return false;
    // const defaultValue = false;
    // try {
    //     return store.getState().settings.general.SetData.Settings.Cycle.ManageSubGroups || defaultValue;
    // } catch (e) {
    //     return defaultValue;
    // }
}

export function getParturitionIndexRangeValues() {
    const defaultValue = ["auto", "auto"];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.ParturitionIndexRange || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getHealthyPigletsPerLitterRangeValues() {
    const defaultValue = ["auto", "auto"];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.HealthyPigletsPerLitterRange || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getSeparatedPigletsPerLitterRangeValues() {
    const defaultValue = ["auto", "auto"];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.SeparatedPigletsPerLitterRange || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAverageParturitionWeightRangeValues() {
    const defaultValue = ["auto", "auto"];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.AverageParturitionWeightRange || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAverageSeparationWeightRangeValues() {
    const defaultValue = ["auto", "auto"];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.AverageSeparationWeightRange || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAverageIdleDaysRangeValues() {
    const defaultValue = ["auto", "auto"];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.AverageIdleDaysRange || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAverageLactationDaysValues() {
    const defaultValue = ["auto", "auto"];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.AverageLactationDaysRange || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 *
 * @return {array}
 */
export function getFeedingPunishmentSettings() {
    const defaultValue = [
        {
            Time: 1,
            Trigger: 1,
            Percentage: 95
        },
        {
            Time: 1,
            Trigger: 2,
            Percentage: 90

        },
        {
            Time: 2,
            Trigger: 2,
            Percentage: 85

        }
    ];
    try {
        return store.getState().settings.general.SetData.Settings.Feeding.Punishment || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getFeedingLevels() {
    const defaultValue = {
        FeedingMinimumLevel: .1,
        FeedingMaximumLevel: .75
    };
    try {
        return store.getState().settings.general.SetData.Settings.FeedingLevels || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getSowWeights() {
    const defaultValue = [150000];
    try {
        return store.getState().settings.general.SetData.Settings.Weights.Sow.Weights || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getRenovationSowWeights() {
    const defaultValue = [150000];
    try {
        return store.getState().settings.general.SetData.Settings.Weights.RenovationSow.Weights || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getBoarWeights() {
    const defaultValue = [150000];
    try {
        return store.getState().settings.general.SetData.Settings.Weights.Boar.Weights || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getOtherWeights(generalSettings) {
    const defaultValue = [2800, 4300, 6000, 8000, 10500, 13800, 17500, 21500, 25500, 30000, 35000, 41000, 47000, 53000, 59000, 65000, 71000, 77000, 83000, 89000, 95000, 100000, 106000, 112000, 118000, 124000, 130000, 136000, 142000, 148000, 154000, 160000, 166000];
    try {
        if (generalSettings) return generalSettings.SetData.Settings.Weights.Other.Weights || defaultValue;
        return store.getState().settings.general.SetData.Settings.Weights.Other.Weights || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTargetWaterLevel(generalSettings) {
    const defaultValue = [
        1000,
        1000,
        1000,
        1000,
        1050,
        1400,
        1750,
        2150,
        2550,
        3000,
        3500,
        4100,
        4700,
        5300,
        5900,
        6500,
        7100,
        7700,
        8300,
        8900,
        9500,
        10000,
        10000,
        10000,
        10000,
        10000,
        10000,
        10000,
        10000,
        10000,
        10000,
        10000,
        10000
    ];
    try {
        if (generalSettings) return generalSettings.SetData.Settings.WaterTarget.WeeklyTarget || defaultValue;
        return store.getState().settings.general.SetData.Settings.WaterTarget.WeeklyTarget || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}


export function getTargetForageLevel(generalSettings) {
    const defaultValue = [
        1000, 1000, 1000, 1000, 1000, 1500, 1500,
        1500, 1500, 1500, 1500, 2000, 2000, 2400,
        2400, 2800, 2800, 2800, 2800, 2800, 2800,
        2800, 2800, 2800, 2800, 2800, 2800, 2800,
        2800, 2800, 2800, 2800, 2800
    ];
    try {
        if (generalSettings) return generalSettings.SetData.Settings.ForageTarget.WeeklyTarget || defaultValue;
        return store.getState().settings.general.SetData.Settings.ForageTarget.WeeklyTarget || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTargetWaterLevelOffset(generalSettings) {
    const defaultValue = 5;
    try {
        if (generalSettings) return generalSettings.SetData.Settings.WaterTarget.Offset || defaultValue;
        return store.getState().settings.general.SetData.Settings.WaterTarget.Offset || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTargetForageLevelOffset(generalSettings) {
    const defaultValue = 5;
    try {
        if (generalSettings) return generalSettings.SetData.Settings.ForageTarget.Offset || defaultValue;
        return store.getState().settings.general.SetData.Settings.ForageTarget.Offset || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getPorkerWeek() {
    const defaultValue = 10;
    try {
        return store.getState().settings.general.SetData.Settings.Weights.Other.PorkerWeek || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAllowJoiningGroups() {
    const defaultValue = false;
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.AllowJoiningGroups || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getShowGiltsOnParturition() {
    const defaultValue = true;
    try {
        const state = store.getState();
        return get(state, "settings.general.SetData.Settings.Cycle.ShowGiltsOnParturition", defaultValue);
    } catch (e) {
        return defaultValue;
    }
}

export function getCastrationDays() {
    const defaultValue = [5, 8];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.CastrationDays || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTailsDays() {
    const defaultValue = [5, 8];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TailsDays || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getTeethDays() {
    const defaultValue = [5, 8];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.TeethDays || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getWeightingDays() {
    const defaultValue = [5, 8];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.WeightingDays || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getPigletMortality() {
    const defaultValue = [0, 10];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.PigletMortality || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getWeanerMortality() {
    const defaultValue = [0, 2];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.WeanerMortality || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getPorkerMortality() {
    const defaultValue = [0, 2];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.PorkerMortality || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAverageGrowthOfPiglets() {
    const defaultValue = [0, 1000];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.AverageGrowthOfPiglets || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAverageGrowthOfPorkers() {
    const defaultValue = [0, 1000];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.AverageGrowthOfPorkers || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getFatteningDaysForPiglet() {
    const defaultValue = [0, 70];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.FatteningDaysForPiglet || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getFatteningDaysForPorker() {
    const defaultValue = [0, 90];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.FatteningDaysForPorker || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAnimalWeightOptionForSeparation(state) {
    const defaultValue = AnimalWeightOptions.SINGLE;
    try {
        return (state || store.getState())?.settings?.general?.SetData?.Settings?.Cycle?.AnimalWeightOptionForSeparation || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}
export function getAnimalWeightOptionForParturition(state) {
    const defaultValue = AnimalWeightOptions.SINGLE;
    try {
        return (state || store.getState())?.settings?.general?.SetData?.Settings?.Cycle?.AnimalWeightOptionForParturition || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAnimalWeightOptionForSale(state) {
    const defaultValue = AnimalWeightOptions.SINGLE;
    try {
        return (state || store.getState())?.settings?.general?.SetData?.Settings?.Cycle?.AnimalWeightOptionForSale || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getAnimalWeightsTable(animal) {
    switch (animal.AnimalKind) {
        case AnimalTypes.SOW:
            return getSowWeights();
        case AnimalTypes.RENOVATION_SOW:
            return getRenovationSowWeights();
        case AnimalTypes.BOAR:
            return getBoarWeights();
        default:
            return getOtherWeights();
    }
}

export function getIPSUMGlobalSettings(state) {
    const defaultValue = { GainDays: 7, WeightDeviation: 7500, MaxMSE: 400 };
    try {
        return (state || store.getState())?.settings?.general?.SetData?.Settings?.Feeding?.IPSUM || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function setPlan(plan, year, data) {
    let p = plan.SetData.Plans.find(item => item.Year === year);
    if (p) {
        p.Data = data;
    } else {
        plan.SetData.Plans.push({
            Year: year,
            Data: data
        });
    }
    plan.DtaModTime = +new Date();
}

export function getAverageWeightForPiglet() {
    const defaultValue = [20000, 50000];
    try {
        const state = store.getState();
        return get(state, "settings.general.SetData.Settings.Cycle.AverageWeightForPiglet", defaultValue);
    } catch (e) {
        return defaultValue;
    }
}

export function getAverageSellWeightForPorker() {
    const defaultValue = [80000, 180000];
    try {
        const state = store.getState();
        return get(state, "settings.general.SetData.Settings.Cycle.AverageSellWeightForPorker", defaultValue);
    } catch (e) {
        return defaultValue;
    }
}

export function getFCRRange() {
    const defaultValue = [2, 4];
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.FCR || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function getResultsEventStartDate() {
    const defaultValue = moment.utc("01-01-2015").startOf("day");
    try {
        return store.getState().settings.general.SetData.Settings.Cycle.ResultsEventStartDate || defaultValue.toDate().getTime();
    } catch (e) {
        return defaultValue.toDate().getTime();
    }
}

export function getCalculateResultsAfterSowCycles(internalState) {
    const defaultValue = true;
    try {
        if (internalState) return internalState.CalculateResultsAfterSowCycles || defaultValue;
        const state = store.getState();
        return get(state, "settings.general.SetData.Settings.Cycle.CalculateResultsAfterSowCycles", defaultValue);
    } catch (e) {
        return defaultValue;
    }
}

export function getTimeOnSectorByType(type) {
    switch (type) {
        case SectorType.MATING: return getTimeOnMatingRoom();
        case SectorType.SOWS: return getTimeOnSowsRoom();
        case SectorType.DELIVERY: return getTimeOnBirthRoomMommy();
        case SectorType.PIGLET_HOUSE: return getTimeOnPigletHouse();
        case SectorType.PORK_HOUSE: return getTimeOnPorkHouse();
        default: return null;
    }
}

