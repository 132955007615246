import browserslist from "browserslist";
import supportedBrowsers from "../browserslist/supportedBrowsers";

// jezeli zmieniasz tu, to zmien tez w .browserslistrc
const QUERY = "last 5 versions or last 2 Safari major versions or last 2 iOS major versions and not dead and not op_mini all";
// const QUERY = "Firefox 116";
// const QUERY = "Chrome 112";

export function checkBrowser() {
    return supportedBrowsers.test(navigator.userAgent);
}

export function getSupportedBrowsers() {
    // const browsers = browserslist("last 5 versions or last 2 Safari major versions or last 2 iOS major versions and not dead and not op_mini all");
    const browsers = browserslist(QUERY);
    const supportedBrowsers = {};
    for (let browser of browsers) {
        const [browserName, version] = browser.split(" ");
        if (!supportedBrowsers[browserName]) {
            supportedBrowsers[browserName] = [];
        }
        supportedBrowsers[browserName].push(version);
    }

    return supportedBrowsers;
}

export function translateBrowserName(browserName) {
    switch (browserName) {
        case "and_chr": return "Chrome for Android";
        case "and_ff": return "Firefox for Android";
        case "and_qq": return "QQ Browser";
        case "and_uc": return "UC Browser for Android";
        case "android": return "Android Browser";
        case "chrome": return "Chrome";
        case "edge": return "Microsoft Edge";
        case "firefox": return "Firefox";
        case "ios_saf": return "Safari for iOS";
        case "kaios": return "KaiOS Browser";
        case "op_mob": return "Opera Mobile";
        case "opera": return "Opera";
        case "safari": return "Safari";
        case "samsung": return "Samsung Internet";
        default:
            return browserName;
    }
}