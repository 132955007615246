const initialValue = {
    fetching: {},
    data: {},
    fetchingDetails: {},
    details: {}
};


export default function ipsumReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_IPSUM_DATA_PENDING": {
            const fetching = { ...state.fetching };
            for (let plcmntID of action.meta.PlcmntIDs) {
                fetching[plcmntID] = true;
            }
            return { ...state, fetching }
        }
        case "GET_IPSUM_DATA_FULFILLED": {
            const fetching = { ...state.fetching };
            const data = { ...state.data };
            for (let plcmntID of action.meta.PlcmntIDs) {
                fetching[plcmntID] = false;
                data[plcmntID] = action.payload.CAnsw[plcmntID];
            }
            return { ...state, fetching, data }
        }
        case "GET_IPSUM_DATA_REJECTED": {
            const fetching = { ...state.fetching };
            for (let plcmntID of action.meta.PlcmntIDs) {
                fetching[plcmntID] = false;
            }
            return { ...state, fetching }
        }
        case "GET_IPSUM_DETAILS_PENDING":
            return { ...state, fetchingDetails: { ...state.fetchingDetails, [action.meta.PlcmntID]: true } }
        case "GET_IPSUM_DETAILS_FULFILLED":
            return { ...state, fetchingDetails: { ...state.fetchingDetails, [action.meta.PlcmntID]: false }, details: { ...state.details, [action.meta.PlcmntID]: action.payload.CAnsw } }
        case "GET_IPSUM_DETAILS_REJECTED":
            return { ...state, fetchingDetails: { ...state.fetchingDetails, [action.meta.PlcmntID]: false } }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
