/**
 *
 -1 "Ferma",
 0 "Budynek",
 1 "Sektor",
 2 "Komora"
 3 "Stanowisko"
 * @type {{BUILDING: number, FARM: number, SECTOR: number, CHAMBER: number, BOX: number}}
 */
export const Level = {
    FARM: -1,
    BUILDING: 0,
    SECTOR: 1,
    CHAMBER: 2,
    BOX: 3
};