import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {roleSelector} from "../../../../selectors/roleSelector";
import DiodeControl from "./control-panel/DiodeControl";
import LightControl from "./control-panel/LightControl";
import {flatten} from "lodash";
import Icon from "./control-panel/Icon";
import {getMapData} from "../../../../selectors/farmMapSelector";
import ButtonGroup from "../../../basics/button/button-group/ButtonGroup";
import VehicleWeightTareControl from "./control-panel/VehicleWeightTareControl";

const makeMapStateToProps = () => {
    return (state) => ({
        roleUtils: roleSelector(state),
        data: getMapData(state)
    })
}

class DeviceControlPanel extends Component {


    getOptions = () => {
        const {enableSpecificControls} = this.props;
        let controls = [];
        const key2Ctrl = {
            DIODE: DiodeControl,
            LIGHT: LightControl,
            VEHICLE_WEIGHT: VehicleWeightTareControl
        }
        if (enableSpecificControls) {
            for (const key in enableSpecificControls) {
                if (key2Ctrl.hasOwnProperty(key)) {
                    controls.push(key2Ctrl[key]);
                }
            }
        } else {
            controls = Object.values(key2Ctrl);
        }
        return flatten(controls.map((c) => {
            return c(this.props, "map");
        })).filter((o) => o && o.show);

    };


    render() {
        const options = this.getOptions();
        if (options.length === 0) return null;
        const content = options.map((props, i) => (
            <Icon {...props}/>
        ));
        if (options.length === 1) {
            return (
                <div>
                    {content}
                </div>
            )
        }
        return (
            <div>
                <ButtonGroup>
                    {content}
                </ButtonGroup>
            </div>
        );
    }
}

export default compose(
    connect(makeMapStateToProps),
    withTranslation()
)(DeviceControlPanel);
