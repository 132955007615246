import { useEffect, useRef, useState } from 'react';

export function useOnScreen(ref, root, rootMargin) {
    const [isOnScreen, setIsOnScreen] = useState(false);
    const observerRef = useRef(null);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting), { root, rootMargin });
    }, [root, rootMargin]);

    useEffect(() => {
        if (ref.current) {
            observerRef.current.observe(ref.current);
        }

        return () => {
            observerRef.current.disconnect();
        };
    }, [ref]);

    return isOnScreen;
}

