/**
 *       1"Krycie",
 2"Lochy prośne",
 3"Loszki remontowe",
 4"Porodówka",
 5"Baby room",
 6"Tuczarnia",
 7"Warchlakarnia",
 8"Knurownik"
 1"Mating",
 2"Sows",
 3"Renovation sows",
 4"Delivery",
 5"Baby room",
 6"Pork house",
 7"Piglet house",
 8"Boar house"
 * @type {{MATING: Krycie_1, SOWS: Lochy_prośne_2, RENOVATION_SOWS: Loszki_remontowe_3, DELIVERY: Porodówka_4, BABY_ROOM: Baby_room_5, PORK_HOUSE: Tuczarnia_6, PIGLET_HOUSE: Warchlakarnia_7, BOAR_HOUSE: Knurownik_8}}
 */
export const SectorType = {
    MATING: 1,
    SOWS: 2,
    RENOVATION_SOWS: 3,
    DELIVERY: 4,
    BABY_ROOM: 5,
    PORK_HOUSE: 6,
    PIGLET_HOUSE: 7,
    BOAR_HOUSE: 8
};