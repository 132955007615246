import React, { Component } from "react";
import { connect } from "react-redux";
import "./_filter.scss";
import Button from "../../button/Button";
import Input from "../../input/Input";
import FilterLevel from "./filter-level/FilterLevel";
import { debounce, get, isEmpty, isEqual, set, unset } from "lodash";
import PropTypes from "prop-types";
import FilterRow from "./filter-level/FilterRow";
import FilterOr from "./filter-level/FilterOr";
import XLSX from "../../../../beans/XLSX/XLSX";
import { withTranslation } from "react-i18next";
import GridColumnManageModal, {
    ModalName as GridColumnManageModalName
} from "../../../modals-new/grid-column-manage-modal/GridColumnManageModal";
import { bindActionCreators, compose } from "redux";
import { show } from "redux-modal";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ show }, dispatch)
    };
}

export class Filter extends Component {

    defaultFilter = { $and: [{}] };

    constructor(props) {
        super(props);
        const filter = this.props.getFilter();
        let showFilter = false;
        if (!isEqual(filter.filters, this.defaultFilter)) showFilter = true;
        this.state = {
            showFilter,
            filter: filter.filters || this.defaultFilter,
            quickFilter: ""
        };
        this.quickFilter = debounce(this.props.onQuickFilterChange, 300);
    }

    componentDidMount() {
        const { name } = this.props;
        const { filter } = this.state;
        if (name && !isEqual(filter, this.defaultFilter)) {
            this.onApplyFilterClick();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.onQuickFilterChange !== this.props.onQuickFilterChange) { // w momencie, gdy zmienia sie referencja poinno zmienic debounce
            this.quickFilter = debounce(this.props.onQuickFilterChange, 300);
        }
    }

    onFilterButtonClick = () => {
        this.setState(state => ({
            showFilter: !state.showFilter,
            quickFilter: ""
        }));
        if (this.state.quickFilter !== "") {
            this.props.onQuickFilterChange("");
        }
    };

    onTypeChange = (type, path) => {
        let { filter } = this.state;
        let value = get(filter, path);
        let split = path.split(".");
        let pathWithoutLast = split.slice(0, -1).join(".");
        let lastKey = split[split.length - 1];
        let level = get(filter, pathWithoutLast, filter);
        delete level[lastKey];
        level[type] = value;
        if (pathWithoutLast) {
            set(filter, pathWithoutLast, level);
        } else {
            filter = level;
        }
        this.setState({
            filter
        });
    };

    onChangeFilters = (value, path) => {
        let filter = JSON.parse(JSON.stringify(this.state.filter));
        set(filter, path, value);
        this.setState({
            filter
        });
    };

    isNewGroup(value) {
        let keys = Object.keys(value);
        return keys.includes("$and");
    }

    clearParent(filter, path) {
        let parent = get(filter, path, filter);
        let keys = Object.keys(parent);
        if (keys.length === 0) {
            unset(filter, path);
        } else {
            let key = keys[0];
            parent[key] = parent[key].filter(item => item);
            if (parent[key].length === 1) {
                if (path) {
                    set(filter, path, parent[key][0]);
                } else {
                    filter = parent[key][0];
                }
            }
        }
        let parentPath = path.split(".").slice(0, -1).join("");
        if (path) {
            filter = this.clearParent(filter, parentPath);
        }
        return filter;
    }

    onRemove = (path, index) => {
        let filter = JSON.parse(JSON.stringify(this.state.filter));
        let pathed = get(filter, path, []);
        pathed.splice(index, 1);
        if (pathed.length === 0) {
            unset(filter, path);
            let parentPath = path.split(".").slice(0, -1).join(".");
            filter = this.clearParent(filter, parentPath);
        }
        if (isEmpty(filter)) {
            filter = {
                $and: [{}]
            };
        }
        this.setState({
            filter
        }, () => {
            const { onFilterChange } = this.props;
            onFilterChange(this.state.filter);
        });
    };

    onOrClick = path => {
        let { filter } = this.state;
        let split = path.split(".");
        let pathWithoutLast = split.slice(0, -1).join(".");
        let level = get(filter, pathWithoutLast, filter);
        let or = { $or: [level, { $and: [{}] }] };
        if (pathWithoutLast) {
            set(filter, pathWithoutLast, or);
        } else {
            filter = or;
        }
        this.setState({
            filter
        });
    };

    renderFilters(filter = this.state.filter, path = "") {
        let component;
        const { mobile } = this.props;
        for (let key in filter) {
            let value = filter[key];
            path += path ? "." + key : key;
            if (key === "$or") {
                component = (
                    <FilterOr mobile={mobile} value={value} path={path} onFilterChange={this.onChangeFilters}>
                        {
                            value.map((val, index) => this.renderFilters(val, path + `[${index}]`))
                        }
                    </FilterOr>
                );
            } else {
                component = (
                    <FilterLevel mobile={mobile} value={{ [key]: value }} onChangeFilters={this.onChangeFilters}
                        path={path}
                        onTypeChange={this.onTypeChange} onOrClick={this.onOrClick}>
                        {
                            value.map((val, index) => {
                                if (this.isNewGroup(val)) return this.renderFilters(val, path + `[${index}]`);
                                return <FilterRow index={index} path={path} onRemove={this.onRemove}
                                    headers={this.props.headers} mobile={mobile} value={val}
                                    onFilterChange={this.onChangeFilters} farm={this.props.farm}
                                />;
                            })
                        }
                    </FilterLevel>
                );
            }
        }
        return component;
    }

    onApplyFilterClick = () => {
        const { onFilterChange } = this.props;
        onFilterChange(this.state.filter);
    };

    onQuickFilterChange = value => {
        this.setState({
            quickFilter: value
        });
        this.quickFilter(value);
    };

    clearQuickFilter() {
        this.setState({
            quickFilter: ""
        });
    }

    onSaveToExcelClick = () => {
        const { headers, sortedData, excelFileName } = this.props;
        let d = XLSX.formatData(headers, sortedData);
        let xlsx = new XLSX(d, {
            headers,
            addDefaultDate: false
        });
        xlsx.save(excelFileName.replace(/[ :]+/g, ''));
    };

    onClearClick = () => {
        let filter = {
            $and: [{}]
        };
        this.setState({
            filter
        });
        this.props.onFilterChange(filter);
    };

    onGridColumnManageModalClick = () => {
        const { show, headers, onHeadersToDisplayChange, headersToDisplay = [], maxColumns } = this.props;
        show(GridColumnManageModalName, {
            headers,
            headersToDisplay,
            onHeadersToDisplayChange,
            maxColumns
        });
    };

    render() {
        const { t, saveToExcel, mobile, ableToHideColumns, showFilter: _showFilter } = this.props;
        if (!_showFilter && !saveToExcel) return null;
        if (!_showFilter && saveToExcel) return (
            <div className="table-grid-filter">
                <div className="table-grid-filter-buttons">
                    <div className="table-grid-filter-buttons-main">
                    </div>
                    <div>
                        <Button type="button" title={t("filterGrid.tooltip.saveToExel")} buttonStyle={"round"}
                            buttonColor={"success"}
                            icon={<i className="fas fa-fw fa-file-excel" />}
                            onClick={this.onSaveToExcelClick} />
                    </div>
                </div>
            </div>
        );
        const { showFilter, quickFilter } = this.state;
        return (
            <div className="table-grid-filter">
                <div className="table-grid-filter-buttons">
                    <div className="table-grid-filter-buttons-main">
                        <Button type='button' title={t("filterGrid.tooltip.advancedFilter")} buttonStyle={"round"}
                            icon={<i className="fas fa-filter" />}
                            onClick={this.onFilterButtonClick} />
                        <Input title={t("filterGrid.tooltip.searchBar")} type={"text"}
                            placeholder={t("filterGrid.filter")} onChange={this.onQuickFilterChange}
                            value={quickFilter} disabled={showFilter} />
                    </div>
                    <div>
                        {
                            ableToHideColumns &&
                            <Button type='button' title={t("filterGrid.tooltip.columnsManage")} buttonStyle={"round"}
                                buttonColor={"success"}
                                icon={<i className="fas fa-fw fa-cog" />}
                                onClick={this.onGridColumnManageModalClick} />
                        }
                        {
                            saveToExcel &&
                            <Button type='button' title={t("filterGrid.tooltip.saveToExel")} buttonStyle={"round"}
                                buttonColor={"success"}
                                icon={<i className="fas fa-fw fa-file-excel" />}
                                onClick={this.onSaveToExcelClick} />
                        }
                    </div>
                </div>
                {
                    showFilter &&
                    <div className={"table-grid-filter-container"}>
                        <div className="table-grid-filter-advanced">
                            {this.renderFilters()}
                            <div className="table-grid-filter-advanced-apply">
                                <Button type='button' icon={<i className="fas fa-eraser" />} onClick={this.onClearClick}>
                                    {mobile ? null : t("clear")}
                                </Button>
                                <Button type='button' buttonColor={"success"} icon={<i className="fas fa-check" />}
                                    onClick={this.onApplyFilterClick}>
                                    {mobile ? null : t("filterGrid.filter")}
                                </Button>
                            </div>
                        </div>
                    </div>
                }
                {
                    ableToHideColumns &&
                    <GridColumnManageModal />
                }
            </div>
        );
    }

}

Filter.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    onQuickFilterChange: PropTypes.func.isRequired
};

export default compose(
    withTranslation(null, { withRef: true }),
    connect(
        mapStateToProps,
        mapDispatchToProps,
        null,
        { forwardRef: true }
    )
)(Filter);
