import React from "react";
import SmallElement from "./SmallElement";

const SmallElementRenderer = ({ data, onBeforeRender, rotate }) => {
    return (
        <svg>
            {data.map((element) => (
                <SmallElement key={element.id} element={element} onBeforeRender={onBeforeRender} rotate={rotate} />
            ))}
        </svg>
    )
}

export default React.memo(SmallElementRenderer);