import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Button from "../../basics/button/Button";
import {Field, reduxForm} from "redux-form";
import EditTreatmentModalSelectMedicines from "./EditTreatmentModalSelectMedicines";
import EditTreatmentModalDateChooser from "./EditTreatmentModalDateChooser";

class EditTreatmentModalDataForm extends Component {

    render() {
        const {t, handleSubmit} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Field name="medicines" component={EditTreatmentModalSelectMedicines}/>
                <Field name="dates" component={EditTreatmentModalDateChooser}/>
                <Button buttonColor="primary">{t("add")}</Button>
            </form>
        );
    }
}

function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (values.medicines?.length === 0) {
        errors.medicines = t("required");
    }
    if (values.dates?.length === 0) {
        errors.dates = t("required");
    }

    return errors;
}

function onSubmitSuccess(result, dispatch, props) {
    props.reset();
}

export default compose(
    withTranslation(),
    reduxForm({
        form: "editTreatmentDataForm",
        validate,
        onSubmitSuccess,
        initialValues: {
            medicines: [],
            dates: []
        }
    })
)(EditTreatmentModalDataForm);
