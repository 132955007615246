import {useEffect, useMemo, useState} from "react";

/**
 * Hook zwracający pozostały czas od wskazanej daty
 * @param targetDate {number}                     Data, do której odlicza
 * @returns {[number,number,number,number]}     [Dni, Godziny, Minuty, Sekundy]
 */
export default function useCountdown(targetDate) {

    const [countdown, setCountdown] = useState(targetDate - +new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(targetDate - +new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [targetDate])

    return useMemo(() => {
        const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
        const hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countdown % (1000 * 60)) / 1000);
        return [days, hours, minutes, seconds];
    }, [countdown]);
}
