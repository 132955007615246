import React from "react";
import "./_view-container-modal.scss"
import PropTypes from "prop-types";
import Header from "../header/Header";
import ViewContainer from "../view-container/ViewContainer";
import {CSSTransition} from "react-transition-group";
import ReactDOM from "react-dom";
import {bodyLockScroll, bodyUnlockScroll, ScrollLock} from "../../../utils/DOMUtils";
import LoadingComponent from "../../loading/LoadingComponent";
import {isMobile} from "../../../utils/MobileUtils";
import {bindActionCreators, compose} from "redux";
import {hide, show as _showConfirmModal} from "redux-modal";
import {connect} from "react-redux";
import {ModalName as ConfirmModalName} from "../../modals-new/confirm-modal/ConfirmModal";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import Button from "../button/Button";
import {Prompt, withRouter} from "react-router-dom";

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({showConfirmModal: _showConfirmModal, hide}, dispatch)
    }
}

class ViewContainerModal extends React.Component {

    state = {
        show: false
    };

    isBusy = false;

    componentDidMount() {
        this.setState({
            show: !!this.props.show
        })
        // jesli nie ma kontenera to czekamy az bedzie
        if (!document.getElementById("center-content")) {
            this.timer = setInterval(() => {
                if (document.getElementById("center-content")) {
                    clearInterval(this.timer);
                }
                this.forceUpdate();

            }, 200)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const globalShow = !!this.props.show, prevShow = !!prevProps.show;
        if (!this.isBusy && (prevShow !== globalShow)) {
            this.setState({
                show: globalShow
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        if (this.state.show) this.handleExit();
    }

    onHide = (nextLocation) => {
        const {justBack, onHide} = this.props;
        if (justBack) onHide && onHide();
        else {
            const state = {
                show: false
            };
            if (nextLocation.pathname) state["pathname"] = nextLocation.pathname;
            this.isBusy = true;
            this.setState(state);
        }
    };

    showConfirmModal = nextLocation => {
        const {beforeHide, showConfirmModal, t, notSavedText} = this.props;
        showConfirmModal(ConfirmModalName, {
            title: t("newSettings.translate.edit.notSaved"),
            text: notSavedText,
            confirmText: t("save"),
            closeText: t("cancel"),
            optionalButtons: <Button buttonColor={"error"}
                                     onClick={() => this.hideModals(nextLocation)}>{t("doNotSave")}</Button>,
            onConfirmed: () => {
                beforeHide();
                this.hideModals(nextLocation);
            }
        });
        return false;
    };

    hideModals = (nextLocation) => {
        this.onHide(nextLocation);
        this.props.hide(ConfirmModalName);
    };

    handleExited = () => {
        const {onHide, history} = this.props;
        const {pathname} = this.state;
        this.isBusy = false;
        if (pathname) history.push(pathname);
        onHide && onHide();
    };

    handleEnter = () => {
        this.isBusy = true;
    }

    handleEntered = () => {
        bodyLockScroll(ScrollLock.MODAL);
        this.isBusy = false;
    };

    handleExit = () => {
        const modals = document.getElementsByClassName("view-container-modal");
        if (modals.length <= 1) bodyUnlockScroll(ScrollLock.MODAL);
    };

    render() {
        const {
            children,
            className: propsClassName,
            name,
            loading,
            onHide,
            addPaddingForButtons,
            shouldShowConfirmModal,
            addHeight
        } = this.props;
        const {show} = this.state;
        const hasContainer = document.getElementById("center-content");
        const className = [`view-container-modal ${isMobile() ? "mobile" : ""}`, propsClassName].filter(o => !!o).join(" ");
        const showContainerModal = hasContainer ? !!show : false;
        // don't show prompt if it's already closed
        const showPromptBeforeExit = shouldShowConfirmModal ? showContainerModal : false;
        return ReactDOM.createPortal((
            <CSSTransition in={showContainerModal} timeout={300}
                           classNames="modal-in" mountOnEnter appear unmountOnExit onEnter={this.handleEnter}
                           onEntered={this.handleEntered} onExit={this.handleExit} onExited={this.handleExited}>
                <div className={className}>
                    <Header text={
                        <>
                            {
                                !!onHide &&
                                <span>
                                    <i className={`fas fa-fw fa-arrow-${show ? "left" : "right"} pointer`}
                                       onClick={showPromptBeforeExit ? this.showConfirmModal : this.onHide}/>
                                </span>
                            }
                            {name}
                            {
                                showPromptBeforeExit &&
                                <Prompt when={true} message={this.showConfirmModal}/>
                            }
                        </>
                    }/>
                    <ViewContainer addPaddingForButtons={addPaddingForButtons} addHeight={addHeight}>
                        <LoadingComponent type={"container"} isLoading={loading} bgInherit={true}/>
                        {children}
                    </ViewContainer>
                </div>
            </CSSTransition>

        ), document.getElementById("center-content") || document.createElement("div"));
    }

}

ViewContainerModal.propTypes = {
    show: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onHide: PropTypes.func,
    addPaddingForButtons: PropTypes.bool,
    className: PropTypes.string,
    justBack: PropTypes.bool
};

ViewContainerModal.defaultProps = {
    show: false,
    justBack: false
};

export default compose(
    connect(null, mapDispatchToProps),
    withTranslation(NameSpaces.TRANSLATION),
    withRouter
)(ViewContainerModal);
