import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export function getFiles(path, {FarmID, ClientID, LocalUserID, forceFetch = false} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.getFiles({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {
            Folder: path
        },
        forceFetch
    })
}
