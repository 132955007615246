import React from "react";
import {useTranslation} from "react-i18next";

export default function ShowMoreLinkComponent({opened, onClick}) {

    const {t} = useTranslation();

    return (
        <a onClick={onClick} className={"pointer opacity-75 mt-1 d-block text-end"}>
            <span>{t(`grid.${opened ? "less" : "more"}`)}</span>
            <i className={`fa-solid fa-fw ms-1 ${!opened ? "fa-chevron-right" : "fa-chevron-down"}`}/>
        </a>
    )
}