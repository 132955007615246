import animalsDB from "../database/animalsDB";
import moment from "moment";
import {
    differenceBy,
    findIndex,
    get,
    isEmpty,
    isEqual,
    isNil,
    last,
    memoize,
    uniqBy,
    values,
} from "lodash";
import React from "react";
import SelectEditor from "../components/basics/table-input/editors/SelectEditor";
import store from "../store/store";
import Select from "../components/basics/select/Select";
import InputFilter from "../components/basics/table-input/filters/InputFilter";
import formDataDB from "../database/formDataDB";
import * as AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import * as EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import InputEditor from "../components/basics/table-input/editors/InputEditor";
import i18n from "../i18n";
import {
    getAliveAnimalsFromMommyGilt,
    getAliveAnimalsFromTattoo,
    getDetailedLocationDaysForGroup,
    getLocationDaysForGroup,
    getTattooEventsForAnimal,
} from "./EventUtils";
import {
    checkIfUserHasPrivilegedAccess,
    checkIfUserIsService,
    getClientPackageForAnimalType,
    hasMinPackageLevel,
} from "./NewRolesUtils";
import {ROW_HEIGHT} from "../constans/tableInput";
import {getAnimalTypeTranslation} from "./RaportsUtils";
import {SectorType} from "../constans/sectorTypes";
import groupsDB from "../database/groupsDB";
import TechnologyGroupSelect from "../components/technology-group/select/TechnologyGroupSelect";
import TableCellAnimalAmount from "../components/basics/table-input/cell/TableCellAnimalAmount";
import {LicPackageKeys, LicPackageLevel} from "../constans/roles";
import {formatLocationName} from "./global-formatters/formatLocationName";
import TableCellGroupAnimal from "../components/basics/table-input/cell/TableCellGroupAnimal";
import {
    getAnimalUnit,
    getManageSubgroups,
    getValidationSettings,
} from "./SettingsUtils";
import TableCellAnimalLocation from "../components/basics/table-input/cell/TableCellAnimalLocation";
import UserTypes from "@wesstron/utils/Api/constants/userTypes";
import {ValidationDefaultValues} from "../views/new-settings-view/validations/validation-values/ValidationDefaultValues";
import eventsDB from "../database/eventsDB";
import breedingCountersDB from "../database/breedingCountersDB";
import {preEvents} from "./AnimalDocumentsUtils";
import ColorItem from "../components/color-item/ColorItem";
import {BoarTypes} from "../constans/boarTypes";
import {AnimalWeightOptions} from "../constans/weightOptions";
import {
    getArithmeticWeight,
    getBaseForWeight,
    getBirthDateFromAnimalGroup,
    isIndividualAnimal,
} from "./AnimalsUtils";
import {convertWeightUnitTo} from "./UnitUtils";
import {reverseString} from "./TextUtils";
import {setBirthDate} from "./GroupUtils";
import {sortAsString} from "./SortUtils";
import {localDateTimeFormatter} from "./DateTimeUtils";

/*******************************
 GENERAL
 *******************************/

export function isEmptyRow(row, ignoreFields = ["index", "rowIdx"]) {
    if (isEmpty(row)) return true;
    for (let key in row) {
        if (!ignoreFields.includes(key) && !isNil(row[key]) && row[key] !== "")
            return false;
    }
    return true;
}

export function calculateGridRowsByHeight() {
    try {
        let grid = document.getElementsByClassName("fetura-table-input")[0];
        let header = document.getElementsByClassName("rdg-header-row")[0];
        // let filter = document.getElementsByClassName("rdg-filter-row")[0];
        const length = Math.floor(
            (grid.clientHeight - header.clientHeight) / ROW_HEIGHT
        );
        if (length > 0) return length;
    } catch (e) {
        console.error(e);
    }
    return 10;
}

export function getBasicValues() {
    return new Array(calculateGridRowsByHeight())
        .fill(1)
        .map((_, index) => ({index: index + 1, rowIdx: index}));
}

export function getInitialValues(formName, FarmID, dateFields = ["date"]) {
    const savedData = formDataDB.getSavedData(formName, FarmID);
    if (savedData) {
        const newValue = savedData.data.data
            .map((row) => {
                let obj = {...row};
                for (const field of dateFields) {
                    if (row[field]) {
                        obj[field] = moment(row[field]);
                    }
                }
                // czyszczenie wiersza jezeli usunieto zwierze
                if (obj.animal) {
                    if (obj.animal.AnmGrp) {
                        const group = groupsDB.getGroupByAnmGrp(
                            obj.animal.AnmGrp,
                            FarmID
                        );
                        if (group.DtaDltTime) {
                            obj = {removedGroup: true};
                        }
                    } else if (obj.animal.isGroupChild && obj.animal.AnmIDs) {
                        const newAnmIDs = obj.animal.AnmIDs.filter((id) => {
                            const animal = animalsDB.getAnimalById(id, {
                                joinEvents: false,
                                findDeleted: true,
                            });
                            return (
                                animal &&
                                !animal.DtaDthTime &&
                                !animal.DtaDelTime
                            );
                        });
                        if (newAnmIDs.length === 0) obj = {};
                    } else if (obj.animal && !obj.animal.isGroupChild) {
                        const newAnimal = animalsDB.getAnimalById(
                            obj.animal.AnmID,
                            {
                                joinEvents: false,
                                findDeleted: true,
                            }
                        );
                        if (
                            !newAnimal ||
                            newAnimal.DtaDthTime ||
                            newAnimal.DtaDelTime
                        ) {
                            obj = {};
                        }
                    }
                }
                return obj;
            })
            .filter((item) => !isEmpty(item));
        for (let i = 0; i < newValue.length; i++) {
            const row = newValue[i];
            if (row.animal?.AnmGrp) {
                if (!newValue[i + 1] || !newValue[i + 1].animal?.isGroupChild)
                    newValue[i] = {};
            }
            if (row.removedGroup) {
                newValue[i] = {};
                for (let j = i + 1; j < newValue.length; j++) {
                    if (!newValue[j].animal?.isGroupChild) break;
                    newValue[j] = {};
                }
            }
        }
        return newValue.filter((item) => !isEmpty(item));
    } else {
        return getBasicValues();
    }
}

export function getSows(
    farm,
    selectedAnimals = [],
    showDead = false,
    joinEvents = false,
    showDeleted = false,
    sowIDs = {},
    checkAnmIDs = false
) {
    let sows = animalsDB.getAllAnimals(
        farm,
        AnimalTypes.SOW,
        showDead,
        joinEvents,
        showDeleted,
        sowIDs,
        checkAnmIDs
    );
    sows = differenceBy(sows, selectedAnimals, "AnmID");
    return sows;
}

export function getSowsForTattoo(
    farm,
    selectedAnimals = [],
    showDead = false,
    joinEvents = false,
    showDeleted = false,
    sowIDs = {}
) {
    return getSows(
        farm,
        selectedAnimals,
        showDead,
        joinEvents,
        showDeleted,
        sowIDs,
        true
    ).map((sow) => ({
        ...sow,
        hasGilts: true,
    }));
}

export function getSowsForRenovationSows(
    farm,
    selectedAnimals = [],
    sowIDs = {}
) {
    const sows = animalsDB
        .getAllAnimals(farm, AnimalTypes.SOW, false, false, false, sowIDs, true)
        .map((animal) => ({...animal, hasTattooedPiglets: true}));
    const renovationsSows = animalsDB.getAllAnimals(
        farm,
        AnimalTypes.RENOVATION_SOW,
        false,
        false,
        false
    );
    let animals = [...sows, ...renovationsSows];
    animals = differenceBy(animals, selectedAnimals, "AnmID");
    return animals;
}

export function getAnimalKindBySectorType(sectorType) {
    switch (sectorType) {
        case SectorType.SOWS:
        case SectorType.DELIVERY:
            return [AnimalTypes.SOW];
        case SectorType.RENOVATION_SOWS:
        case SectorType.MATING:
            return [
                AnimalTypes.SOW,
                AnimalTypes.RENOVATION_SOW,
                AnimalTypes.PIGLET,
                AnimalTypes.PORKER,
            ];
        case SectorType.BABY_ROOM:
        case SectorType.PIGLET_HOUSE:
            return [AnimalTypes.PIGLET];
        case SectorType.PORK_HOUSE:
            return [AnimalTypes.PIGLET, AnimalTypes.PORKER];
        case SectorType.BOAR_HOUSE:
            return [AnimalTypes.BOAR];
        default:
            return [];
    }
}

export function getAnimalKindForStandingBySectorType(sectorType) {
    // na stanowiskach nie moze byc warchlakow ani prosiakow wiec trzeba odfiltrowac
    // wedlug Daniela i Adriana loszka moze byc wszedzie wiec wrzucam loszke remontowa -> https://redmine.wesstron.local/issues/8725
    const animalTypes = getAnimalKindBySectorType(sectorType).filter(
        (animalKind) =>
            ![AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(animalKind)
    );
    if (!animalTypes.includes(AnimalTypes.RENOVATION_SOW)) {
        animalTypes.push(AnimalTypes.RENOVATION_SOW);
    }
    if (!animalTypes.includes(AnimalTypes.SOW)) {
        animalTypes.push(AnimalTypes.SOW);
    }
    return animalTypes;
}

export function getAnimalsBySector(
    farm,
    location,
    selectedAnimals = [],
    displayGroups,
    eventsMap
) {
    // wykomentowane na prosbe Daniela!
    // let allowedAnimalKinds = getAnimalKindBySectorType(location.SType);
    // if (location.isIndividualFeeding) {
    //     allowedAnimalKinds = allowedAnimalKinds.filter(item => ![AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(item));
    // }
    switch (location.SType) {
        case SectorType.SOWS:
        case SectorType.DELIVERY:
        case SectorType.BOAR_HOUSE:
        case SectorType.MATING:
            return getAnimals(
                farm,
                selectedAnimals,
                undefined,
                eventsMap
            ) /*.filter(item => allowedAnimalKinds.includes(item.AnimalKind))*/;
        case SectorType.RENOVATION_SOWS:
        case SectorType.BABY_ROOM:
        case SectorType.PIGLET_HOUSE:
        case SectorType.PORK_HOUSE:
            return getAnimalsAndGroups(
                farm,
                selectedAnimals,
                displayGroups,
                eventsMap
            );
        default:
            return getAnimals(farm, selectedAnimals, undefined, eventsMap);
    }
}

export function getAnimals(
    farm,
    selectedAnimals = [],
    eventChooserFilter,
    eventsMap
) {
    let chooserFilter =
        typeof eventChooserFilter === "number"
            ? eventChooserFilter
            : store?.getState().events?.eventChooserFilter;
    let allowedSubGroups = getManageSubgroups();
    // jesli wczesniej wybralismy filtr, i mamy go w storze to bez sensu jest brac wszystkie
    let animals = animalsDB.getAllAnimals(farm, chooserFilter, false, false);
    const animalsFromGroups = [];
    const groupItems = [];
    if ([AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(chooserFilter)) {
        if (!allowedSubGroups) {
            const groups = groupsDB.getAllGroups(farm);
            for (const group of groups) {
                const animalsInGroups = animals.filter(({AnmID}) =>
                    group.AnmIDs.includes(AnmID)
                );
                const anmIDs = animalsInGroups.map(({AnmID}) => AnmID);
                animalsFromGroups.push(...anmIDs);
                const groupEvents = [],
                    removedEvents = [];
                for (const anmID of group.AnmIDs) {
                    groupEvents.push(...get(eventsMap, anmID, []));
                }
                for (const anmID of group.Rmvd) {
                    removedEvents.push(...get(eventsMap, anmID, []));
                }
                const groupItem = getGroupItem(
                    group,
                    animalsInGroups,
                    groupEvents,
                    removedEvents
                );
                groupItems.push(groupItem);
            }
        }
    }
    animals = animals.filter((item) => {
        if (![AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(item.AnimalKind))
            return true;
        return !animalsFromGroups.includes(item.AnmID);
    });
    animals = differenceBy(animals, selectedAnimals, "AnmID");
    return [...animals, ...groupItems];
}

export function getAnimalsAndGroups(
    farm,
    selected = [],
    displayGroups = true,
    eventsMap
) {
    const chooserFilter = store?.getState().events?.eventChooserFilter;
    const allowedSubGroups = getManageSubgroups();
    // jesli wczesniej wybralismy filtr, i mamy go w storze to bez sensu jest brac wszystkie
    const isRenovationSow = chooserFilter === AnimalTypes.RENOVATION_SOW;
    let animals = animalsDB.getAllAnimals(farm, chooserFilter, false, false);
    if (!displayGroups) return animals;
    const groups = allowedSubGroups ? [] : groupsDB.getAllGroups(farm);
    const groupItems = [],
        animalsFromGroups = [];
    if (!isRenovationSow) {
        //Jeżeli jest loszką to chcemy wyświetlać zarówno grupy z pojedyńczymi jak i pojedyńcze #10124
        for (const group of groups) {
            const groupEvents = [],
                removedEvents = [];
            const animalsInGroup = animals.filter(({AnmID}) =>
                group.AnmIDs.includes(AnmID)
            );
            const anmIDs = animalsInGroup.map(({AnmID}) => AnmID);
            animalsFromGroups.push(...anmIDs);
            for (const anmID of group.AnmIDs) {
                groupEvents.push(...get(eventsMap, anmID, []));
            }
            for (const anmID of group.Rmvd) {
                removedEvents.push(...get(eventsMap, anmID, []));
            }
            const groupItem = getGroupItem(
                group,
                animalsInGroup,
                groupEvents,
                removedEvents
            );
            groupItems.push(groupItem);
        }
    }
    animals = animals.filter((item) => !animalsFromGroups.includes(item.AnmID));
    animals = differenceBy(animals, selected, (o) => o.AnmID || o.AnmGrp);
    return [...animals, ...groupItems];
}

export function getSingleAnimals(farm, selectedAnimals = []) {
    let animals = getAnimals(farm, selectedAnimals);
    return animals.filter((item) => item.AnmCnt === 1);
}

export function getIndividualPorkersAndPiglets(
    farm,
    selectedAnimals = [],
    chooserFilter = store?.getState().events?.eventChooserFilter
) {
    let pigletsAndPorkers = animalsDB.getSingleAnimals(farm, [
        AnimalTypes.PIGLET,
        AnimalTypes.PORKER,
    ]);
    if (!isNil(chooserFilter)) {
        pigletsAndPorkers = pigletsAndPorkers.filter(
            (anm) => anm.AnimalKind === chooserFilter
        );
    }
    pigletsAndPorkers = differenceBy(
        pigletsAndPorkers,
        selectedAnimals,
        "AnmID"
    );
    return pigletsAndPorkers;
}

export function getSowsAndRenovationSows(farm, selectedAnimals = []) {
    let chooserFilter = store?.getState().events?.eventChooserFilter;
    let animals = [
        ...animalsDB.getAllAnimals(farm, AnimalTypes.SOW, false, false),
        ...animalsDB.getAllAnimals(
            farm,
            AnimalTypes.RENOVATION_SOW,
            false,
            false
        ),
    ];
    // nie powinno byc takiej sytuacji ze ktos wejdzie na widok gdzie wolana jest ta funkcja z innym typem zwierzecia
    if (
        !isNil(chooserFilter) &&
        [AnimalTypes.SOW, AnimalTypes.RENOVATION_SOW].includes(chooserFilter)
    ) {
        animals = animals.filter((anm) => anm.AnimalKind === chooserFilter);
    }
    animals = differenceBy(animals, selectedAnimals, "AnmID");
    return animals;
}

export function getAnimalsWithoutGroups(
    farm,
    selectedAnimals = [],
    animalKind
) {
    let animals = [
        ...animalsDB.getAllAnimals(farm, AnimalTypes.SOW, false, false),
        ...animalsDB.getAllAnimals(
            farm,
            AnimalTypes.RENOVATION_SOW,
            false,
            false
        ),
        ...animalsDB.getAllAnimals(farm, AnimalTypes.BOAR, false, false),
    ];
    animals = differenceBy(animals, selectedAnimals, "AnmID");
    if (typeof animalKind === "number") {
        animals = animals.filter((anm) => anm.AnimalKind === animalKind);
    }
    return animals;
}

function hasLocationErrorDataAfterDateChange(
    locationData = [],
    locationId = ""
) {
    for (let i = 0; i < locationData.length; i++) {
        const locationRow = locationData[i];
        const locationHistoryDetails = locationRow[`location_${locationId}`];
        if (!locationHistoryDetails || !locationRow.date) continue;
        if (
            typeof locationHistoryDetails.amount === "number" &&
            locationHistoryDetails.amount < 0
        ) {
            return true;
        }
    }
    return false;
}

function getDetailedDataForAnimal(
    group,
    groupEvents,
    removedEvents,
    row,
    code
) {
    const rowData = row.location || row.animal;
    const childrenEvents = [...groupEvents, ...removedEvents];
    const locationID = rowData.PlcmntID;
    const eventToValidate = {
        AnmID: "temporary",
        EvData: {
            AnmCnt: +row.amount,
            PlcmntID: locationID,
            OldGroupID: rowData.AnmGrp || rowData.GroupID,
            SrcID: locationID,
        },
        EvCode: code,
        EvTime: +row.date.clone().startOf("day"),
    };
    childrenEvents.push(eventToValidate);
    const locationDaysForGroup = getLocationDaysForGroup(
        group,
        childrenEvents,
        group.GroupBirthTime
    );
    const {detailedData} =
        getDetailedLocationDaysForGroup(locationDaysForGroup);
    return hasLocationErrorDataAfterDateChange(detailedData, locationID);
}

function getGroupItem(group, animalsInGroup, groupEvents, removedEvents) {
    setBirthDate(group, getBirthDateFromAnimalGroup(animalsInGroup));
    const item = {...group};
    if (groupEvents.length || removedEvents.length) {
        item.getDetailedDataForAnimal = (row, code) =>
            getDetailedDataForAnimal(
                group,
                groupEvents,
                removedEvents,
                row,
                code
            );
    }
    return item;
}

export function getGroups(farm, selectedAnimals = [], animalKind, eventsMap) {
    const allowedSubGroups = getManageSubgroups();
    if (!allowedSubGroups) {
        const data = [];
        const groups = differenceBy(
            groupsDB.getAllGroups(farm),
            selectedAnimals,
            "AnmGrp"
        );
        for (const group of groups) {
            const animalsInGroup = [];
            const groupEvents = [];
            const removedEvents = [];
            for (const anmID of group.AnmIDs) {
                const animal = animalsDB.getAnimalById(anmID, {
                    joinEvents: false,
                });
                groupEvents.push(...get(eventsMap, anmID, []));
                if (animal) {
                    if (
                        typeof animalKind === "number" &&
                        animal.AnimalKind !== animalKind
                    )
                        continue;
                    animalsInGroup.push(animal);
                }
            }
            for (const anmID of group.Rmvd) {
                removedEvents.push(...get(eventsMap, anmID, []));
            }
            if (animalsInGroup.length) {
                const groupItem = getGroupItem(
                    group,
                    animalsInGroup,
                    groupEvents,
                    removedEvents
                );
                data.push(groupItem);
            }
        }
        return data;
    }
    // jesli wczesniej wybralismy filtr, i mamy go w storze to bez sensu jest brac wszystkie
    let animals = [];
    if (typeof animalKind === "number") {
        animals = animalsDB.getAllAnimals(farm, animalKind, false, false);
    } else {
        animals = [
            ...animalsDB.getAllAnimals(farm, AnimalTypes.PORKER, false, false),
            ...animalsDB.getAllAnimals(farm, AnimalTypes.PIGLET, false, false),
        ];
    }
    animals = differenceBy(animals, selectedAnimals, "AnmID");
    return animals;
}

export function getPlcmntsWithGroups(
    farm,
    animalKind = store?.getState().events?.eventChooserFilter,
    selectedPlcmnts = [],
    eventsMap
) {
    const animals = animalsDB.getAllAnimals(farm, animalKind, false, false);
    const plcmnts = {};
    for (const animal of animals) {
        if (animal.AnmCnt === 0) continue;
        const group = groupsDB.getGroupWithAnimal(animal.AnmID, farm)[0];
        if (group) {
            const animalsInGroup = animals.filter(({AnmID}) =>
                group.AnmIDs.includes(AnmID)
            );
            const groupEvents = [];
            const removedEvents = [];
            for (const anmID of group.AnmIDs) {
                groupEvents.push(...get(eventsMap, anmID, []));
            }
            for (const anmID of group.Rmvd) {
                removedEvents.push(...get(eventsMap, anmID, []));
            }
            const groupItem = getGroupItem(
                group,
                animalsInGroup,
                groupEvents,
                removedEvents
            );
            const plcmntList = Array.isArray(animal.PlcmntID)
                ? animal.PlcmntID
                : [
                      {
                          PlcmntID: animal.PlcmntID,
                          AnmCnt: animal.AnmCnt,
                      },
                  ];
            for (const row of plcmntList) {
                if (selectedPlcmnts.includes(row.PlcmntID)) continue;
                if (!plcmnts[row.PlcmntID]) {
                    plcmnts[row.PlcmntID] = {
                        plcmntID: row.PlcmntID,
                        children: [],
                    };
                }
                if (isIndividualAnimal(animal)) {
                    plcmnts[row.PlcmntID].children.push({
                        AnmID: animal.AnmID,
                        AnmNo1: animal.AnmNo1,
                        GrNo1: groupItem.GrNo1,
                        DtaInTime: animal.DtaInTime,
                        AnmCnt: 1,
                        AnmGrp: groupItem.AnmGrp,
                        RFID: animal.RFID,
                        Tagged: animal.Tagged || false,
                        DtaBrthTime: animal.DtaBrthTime,
                        AnimalKind: animal.AnimalKind,
                        GroupBirth: groupItem.GroupBirthTime,
                        IndividualAnimal: true,
                        PlcmntID: row.PlcmntID,
                    });
                } else {
                    const index = findIndex(
                        plcmnts[row.PlcmntID].children,
                        (o) => o.AnmGrp === groupItem.AnmGrp && o.AnmIDs
                    );
                    if (index === -1) {
                        plcmnts[row.PlcmntID].children.push({
                            AnmIDs: [animal.AnmID],
                            AnmGrp: groupItem.AnmGrp,
                            AnmCnt: row.AnmCnt,
                            AnmNo1: groupItem.GrNo1,
                            DtaInTime: animal.DtaInTime,
                            DtaBrthTime: animal.DtaBrthTime,
                            AnimalKind: animal.AnimalKind,
                            GroupBirth: groupItem.GroupBirthTime,
                            IndividualAnimal: false,
                            getDetailedDataForAnimal:
                                groupItem.getDetailedDataForAnimal, // for now only for subgroups
                            PlcmntID: row.PlcmntID,
                        });
                    } else {
                        plcmnts[row.PlcmntID].children[index].AnmIDs.push(
                            animal.AnmID
                        );
                        plcmnts[row.PlcmntID].children[index].AnmCnt +=
                            row.AnmCnt;
                    }
                }
            }
        }
    }
    return Object.values(plcmnts);
}

export function getRaceCrossing(sowRace, boarRace, crossBreeding) {
    return get(crossBreeding, "WData", []).find(
        (crossing) =>
            crossing.BoarRace === boarRace && crossing.SowRace === sowRace
    )?.Value;
}

function getGiltAnimalNumber(breedingCounter, anmCnt, farm, loop = 0) {
    const name = getFormatterCounterName(
        breedingCounter,
        false,
        breedingCounter.ActCnt + anmCnt + loop
    );
    if (animalsDB.checkIfAnimalExistOnFarm(name, farm)) {
        return getGiltAnimalNumber(breedingCounter, anmCnt, farm, loop + 1);
    }
    return {name, loop};
}

export function getAnimalNumberFromCounter(
    counter = "",
    startPattern = "",
    endPattern = "",
    asNumber = true
) {
    const startAnmNo1Index = counter.match(new RegExp(startPattern));
    const anmNo1 = reverseString(
        counter.substring(startAnmNo1Index?.[0]?.length || 0)
    );
    const endAnmNo1Index = counter.match(new RegExp(endPattern));
    const finalAnmNo1 = reverseString(
        anmNo1.substring(endAnmNo1Index?.[0]?.length || 0)
    );
    return asNumber ? +finalAnmNo1 : finalAnmNo1;
}

function getGiltsForAnimal(
    sow,
    amount,
    farm,
    breedingCounter,
    giltRace,
    {boarID, parturitionTime}
) {
    const payload = [];
    let counter = 0;
    for (let anmCnt = 0; anmCnt < amount; anmCnt++) {
        const {name, loop} = getGiltAnimalNumber(
            breedingCounter,
            anmCnt,
            farm,
            counter
        );
        counter = loop;
        payload.push({
            AnmNo1: name,
            giltRace,
            sowID: sow.AnmID,
            boarID,
            parturitionTime,
        });
    }
    return payload;
}

export function getSowsWithTattooedPiglets(
    farm,
    selectedAnimals = [],
    sowIDs = {}
) {
    return getSows(
        farm,
        selectedAnimals,
        false,
        false,
        false,
        sowIDs,
        true
    ).map((sow) => ({
        ...sow,
        hasTattooedPiglets: true,
    }));
}

export function getAnimalOptions(array, licPackages) {
    console.log(array);
    array.sort((a, b) =>
        (a.AnmNo1 || a.GrNo1 || "").localeCompare(
            b.AnmNo1 || b.GrNo1,
            undefined,
            {
                numeric: true,
                sensitivity: "base",
            }
        )
    );
    const duplicatedAnimals = array
        .map((a) => a.AnmNo1 || a.GrNo1)
        .filter((anm, index, all) => all.indexOf(anm) !== index);
    return array
        .filter((item) => {
            if (item.AnmGrp) return true;
            if (licPackages) {
                const clientPackage = getClientPackageForAnimalType(
                    item.AnimalKind
                );
                return hasMinPackageLevel(
                    licPackages,
                    Object.keys(clientPackage),
                    Object.values(clientPackage)
                );
            }
            return true;
        })
        .map((item) => {
            let name = duplicatedAnimals.includes(item.AnmNo1 || item.GrNo1)
                ? `${item.AnmNo1 || item.GrNo1} [${
                      item.GrNo1
                          ? "Grupa"
                          : getAnimalTypeTranslation(item.AnimalKind)
                  }]`
                : item.AnmNo1 || item.GrNo1;
            if (item.DtaDthTime) {
                name += ` ✝ (${moment(item.DtaDthTime).format("L")})`;
            }
            return {
                name,
                value: item,
            };
        });
}

export function getPlcmntOptions(array, buildingsMap, ignorePlcmntID = null) {
    let temp = array.map((item) => {
        let name = formatLocationName(item.plcmntID, {
            buildingsMap,
            nameDeep: 2,
        });
        return {
            name,
            value: {...item, name},
        };
    });
    if (ignorePlcmntID) {
        temp = temp.filter((item) => item.value.plcmntID !== ignorePlcmntID);
    }
    return temp;
}

/*******************************
 FORMATTERS
 *******************************/

export const dateFormatter = ({column: {key}, row}) => {
    if (row[key]) {
        return moment(row[key]).format("L");
    }
    return null;
};

export const dateTimeFormatter = ({column: {key}, row}) => {
    if (row[key]) {
        return localDateTimeFormatter(row[key]);
    }
    return null;
};

export const getFormatterCounterName = (
    counter,
    actFromCounter = true,
    actCnt
) => {
    let name = "";
    if (counter.StartPattern) name += counter.StartPattern;
    name += (actFromCounter ? counter.ActCnt : actCnt)
        .toString()
        .padStart(counter.MaxCnt.toString().length, "0");
    if (counter.EndPattern) name += counter.EndPattern;
    return name;
};

export const animalFormatter = (props, error, warning) => {
    const {
        column: {key},
        row,
    } = props;
    if (row[key]) {
        if (row[key].AnmGrp) return row[key].GrNo1;
        if (row[key].isGroupChild) return <TableCellGroupAnimal {...props} />;
        if (row[key].AnmNo1)
            return (
                <TableCellAnimalLocation
                    {...props}
                    error={error}
                    warning={warning}
                />
            );
        let name = row[key].GrNo1;
        if (row[key].DtaDthTime)
            name += ` ✝ (${moment(row[key].DtaDthTime).format("L")})`;
        return name;
    }
    return null;
};

export const animalWithAmountFormatter = (props, error) => {
    const {
        column: {key},
        row,
    } = props;
    let animal = row[key];
    if (animal) {
        if (animal.isGroupChild) return <TableCellGroupAnimal {...props} />;
        if (animal.AnmGrp) return animal.GrNo1;
        if (animal.plcmntID || animal.sowID) return animal.name;
        if (animal.AnmCnt === 1)
            return <TableCellAnimalLocation {...props} error={error} />;
        return <TableCellAnimalAmount animal={animal} />;
    }
    return null;
};

export const raceFormatter = (option) => {
    let name = option.name;
    if (option.code) name += ` (${option.code})`;
    return (
        <div className="d-flex justify-items-center align-items-center">
            {option.color && (
                <ColorItem className={"me-2"} value={option.color} />
            )}
            <span>{name}</span>
        </div>
    );
};

export const operatorFormatter = ({column: {key}, row}) => {
    const {
        user: {
            employees,
            user: {LocalUserID},
        },
    } = store.getState();
    if (row[key] === LocalUserID) return i18n.t("me");
    let employee = employees.find((item) => item.LocalUserID === row[key]);
    if (employee) {
        if (!employee.firstname && !employee.surname) return employee.userName;
        return `${employee.firstname || ""} ${employee.surname || ""}`;
    }
    return null;
};

export const boarTypeFormatter = ({column: {key}, row}) => {
    const options = getBoarOptions();
    const type = options.find((o) => o.value === row[key]);
    if (type) return type.name;
    return null;
};

export const animalTypeFormatter = ({column: {key}, row}) => {
    const options = getAnimalTypeOptions();
    const type = options.find((o) => o.value === row[key]);
    if (type) return type.name;
    return null;
};

export const animalTypeWithPigletFormatter = ({column: {key}, row}) => {
    const options = getAnimalTypeWithPigletOptions();
    const type = options.find((o) => o.value === row[key]);
    if (type) return type.name;
    return null;
};

/*******************************
 FIELDS
 *******************************/

export function getOperators() {
    const {
        user: {
            employees,
            user: {LocalUserID},
        },
    } = store.getState();
    const emps = employees.filter(
        ({userName, userType}) =>
            !userName.includes("erased") && userType !== UserTypes.TV
    );
    return uniqBy(
        [
            {
                value: LocalUserID,
                name: i18n.t("me"),
            },
            ...emps.map((employee) => ({
                value: employee.LocalUserID,
                name: `${employee.firstname || ""} ${employee.surname || ""}`,
            })),
        ],
        "value"
    );
}

export function getAnimalTypeOptions() {
    return [
        {
            name: i18n.t("animalKind.5"),
            value: AnimalTypes.RENOVATION_SOW,
        },
        {
            name: i18n.t("animalKind.2"),
            value: AnimalTypes.PIGLET,
        },
        {
            name: i18n.t("animalKind.3"),
            value: AnimalTypes.PORKER,
        },
        {
            name: i18n.t("animalKind.4"),
            value: AnimalTypes.BOAR,
        },
    ];
}

export function getAnimalTypeWithPigletOptions() {
    return [
        {
            name: i18n.t("animalKind.5"),
            value: AnimalTypes.RENOVATION_SOW,
        },
        {
            name: i18n.t("animalKind.2"),
            value: AnimalTypes.PIGLET,
        },
        {
            name: i18n.t("animalKind.3"),
            value: AnimalTypes.PORKER,
        },
        {
            name: i18n.t("animalKind.4"),
            value: AnimalTypes.BOAR,
        },
        {
            name: i18n.t("animalKind.1"),
            value: AnimalTypes.PIG,
        },
    ];
}

export function getBoarOptions() {
    return [
        {
            name: i18n.t("boarTypes.stationary"),
            value: BoarTypes.STATIONARY,
        },
        {
            name: i18n.t("boarTypes.farm"),
            value: BoarTypes.FARM,
        },
        {
            name: i18n.t("boarTypes.sperm"),
            value: BoarTypes.SPERM,
        },
    ];
}

export function getRacesOptions(races) {
    return races.map((race) => ({
        name: race.Value,
        value: race.ID,
        code: race.Code,
        color: race.Color,
    }));
}

export const customFilterRenderer = ({
    value,
    onChange,
    onFocus,
    filterOptions = [],
    filterPlaceholder = "",
}) => {
    return (
        <Select
            value={value}
            options={filterOptions}
            onChange={onChange}
            placeholder={filterPlaceholder}
            onFocus={onFocus}
            callOnChangeOnSingleOption={false}
        />
    );
};

export const operatorFilterRenderer = ({value, onChange, onFocus}) => {
    return (
        <Select
            value={value}
            options={getOperators()}
            onChange={onChange}
            placeholder={i18n.t("operator")}
            onFocus={onFocus}
            callOnChangeOnSingleOption={false}
        />
    );
};

export const animalTypeFilterRenderer = ({value, onChange, onFocus}) => {
    return (
        <Select
            value={value}
            options={getAnimalTypeOptions()}
            onChange={onChange}
            placeholder={i18n.t("animalType")}
            onFocus={onFocus}
            callOnChangeOnSingleOption={false}
        />
    );
};

export const animalTypeWithPigletFilterRenderer = ({
    value,
    onChange,
    onFocus,
}) => {
    return (
        <Select
            value={value}
            options={getAnimalTypeWithPigletOptions()}
            onChange={onChange}
            placeholder={i18n.t("animalType")}
            onFocus={onFocus}
            callOnChangeOnSingleOption={false}
        />
    );
};

export const weightFilterRenderer = ({
    value,
    onChange,
    onFocus,
    filterPlaceholder = "",
}) => {
    return (
        <InputFilter
            value={value}
            onChange={onChange}
            type={"number"}
            onFocus={onFocus}
            placeholder={filterPlaceholder}
        />
    );
};

export const locationFilterRenderer = ({
    value,
    onChange,
    onFocus,
    filterOptions = [],
}) => {
    return (
        <Select
            value={value}
            options={filterOptions}
            onChange={onChange}
            placeholder={i18n.t("location")}
            onFocus={onFocus}
            callOnChangeOnSingleOption={false}
        />
    );
};

export const boarTypeFilterRenderer = ({value, onChange, onFocus}) => {
    return (
        <Select
            value={value}
            options={getBoarOptions()}
            onChange={onChange}
            placeholder={i18n.t("type")}
            onFocus={onFocus}
            callOnChangeOnSingleOption={false}
        />
    );
};

export const commentFilterRenderer = ({value, onChange, onFocus}) => {
    return (
        <InputFilter
            type={"text"}
            onChange={onChange}
            value={value}
            placeholder={i18n.t("comment")}
            onFocus={onFocus}
        />
    );
};

export const dateRenderer = ({value, onChange, onFocus}) => {
    return (
        <InputFilter
            value={value}
            onChange={onChange}
            type={"date"}
            onFocus={onFocus}
        />
    );
};

export const dateTimeRenderer = ({value, onChange, onFocus}) => {
    return (
        <InputFilter
            value={value}
            onChange={onChange}
            type={"datetime-local"}
            onFocus={onFocus}
        />
    );
};

const technologyGroupSelect = ({value, onChange, onFocus}) => {
    let state = store.getState();
    let chooserFilter = state.events?.eventChooserFilter;
    let isAnimalGroup =
        chooserFilter === AnimalTypes.PIGLET ||
        chooserFilter === AnimalTypes.PORKER;
    if (isAnimalGroup) {
        let groups = [];
        groups = groupsDB
            .getAllGroups(state.location.farm)
            .filter((group) => group.AnmIDs.length > 0)
            .map((group) => ({
                value: group,
                name: group.GrNo1,
            }));
        return (
            <Select
                value={value}
                options={groups}
                onChange={onChange}
                onFocus={onFocus}
                callOnChangeOnSingleOption={false}
                placeholder={
                    isAnimalGroup
                        ? i18n.t("groupNumber")
                        : i18n.t("technologyGroup")
                }
            />
        );
    } else {
        return (
            <TechnologyGroupSelect
                value={value}
                onChange={onChange}
                onFocus={onFocus}
            />
        );
    }
};

export const operatorField = () => {
    return {
        name: i18n.t("operator"),
        key: "operator",
        editor: (props) => <SelectEditor {...props} options={getOperators()} />,
        formatter: operatorFormatter,
        filterRenderer: operatorFilterRenderer,
    };
};

export const animalTypeField = () => {
    return {
        name: i18n.t("animalType"),
        key: "AnimalType",
        editor: (props) => (
            <SelectEditor {...props} options={getAnimalTypeOptions()} />
        ),
        formatter: animalTypeFormatter,
        filterRenderer: animalTypeFilterRenderer,
    };
};

export const animalTypeWithPigletField = () => {
    return {
        name: i18n.t("animalType"),
        key: "AnimalType",
        editor: (props) => (
            <SelectEditor
                {...props}
                options={getAnimalTypeWithPigletOptions()}
            />
        ),
        formatter: animalTypeWithPigletFormatter,
        filterRenderer: animalTypeWithPigletFilterRenderer,
    };
};

export const boarTypeField = () => {
    return {
        name: i18n.t("type"),
        key: "BoarType",
        editor: (props) => (
            <SelectEditor {...props} options={getBoarOptions()} />
        ),
        formatter: boarTypeFormatter,
        filterRenderer: boarTypeFilterRenderer,
        width: 200,
    };
};

export const commentField = () => {
    return {
        name: i18n.t("comment"),
        key: "comment",
        filterRenderer: commentFilterRenderer,
        editor: (props) => <InputEditor type={"text"} {...props} />,
    };
};

export const dateField = (onChange) => {
    return {
        name: i18n.t("date"),
        key: "date",
        editor: (props) => (
            <InputEditor onChange={onChange} {...props} type={"date"} />
        ),
        formatter: dateFormatter,
        filterRenderer: dateRenderer,
        importType: "date",
    };
};

export const dateTimeField = (onChange) => {
    return {
        name: i18n.t("date"),
        key: "date",
        editor: (props) => (
            <InputEditor
                onChange={onChange}
                {...props}
                type={"datetime-local"}
            />
        ),
        formatter: dateTimeFormatter,
        filterRenderer: dateTimeRenderer,
        importType: "date",
    };
};
function getChildrenForTattooedSowSeparation(
    animal,
    {t, sowsWithTattooedAnimals}
) {
    const children = [];
    const tattooEvents = getTattooEventsForAnimal(
        sowsWithTattooedAnimals[animal.AnmID]
    );
    if (!animal?.AnmNo1 || animal?.AnmNo1 === "-")
        return {children: [], error: t("tattoo.noSowAnmNo1")};
    const cycles =
        preEvents(sowsWithTattooedAnimals[animal.AnmID], null)?.cycleTable ||
        [];
    const lastCycle = last(cycles);
    const inseminations = get(lastCycle, `[${EventTypes.INSEMINATION}]`);
    const insemination = inseminations[0];
    if (!insemination) {
        return {
            children: [],
            error: i18n.t("events.inseminationEvent.noInsemination"),
        };
    }
    const boar = animalsDB.getAnimalById(insemination.EvData.BoarID, {
        joinEvents: false,
        findDeleted: true,
    });
    if (!boar?.AnmNo2 || boar?.AnmNo2 === "-")
        return {children: [], error: i18n.t("tattoo.noBoarAnmNo2")};

    for (const tattoo of tattooEvents) {
        const animalsFromTattoo =
            tattoo.EvCode === EventTypes.TATTOO
                ? getAliveAnimalsFromTattoo(animal, tattoo)
                : getAliveAnimalsFromMommyGilt(animal, tattoo);
        children.push(...animalsFromTattoo);
    }
    return {
        children: children.sort((a, b) =>
            sortAsString(
                undefined,
                undefined,
                undefined,
                "asc",
                a.AnmNo1,
                b.AnmNo1
            )
        ),
        error: "",
    };
}
function getChildrenForTattooedSow(animal, {sowsWithTattooedAnimals}) {
    const children = [];
    console.log(sowsWithTattooedAnimals);
    const tattooEvents = getTattooEventsForAnimal(
        sowsWithTattooedAnimals[animal.AnmID]
    );
    for (const tattoo of tattooEvents) {
        const animalsFromTattoo =
            tattoo.EvCode === EventTypes.TATTOO
                ? getAliveAnimalsFromTattoo(animal, tattoo)
                : getAliveAnimalsFromMommyGilt(animal, tattoo);
        children.push(...animalsFromTattoo);
    }
    return children.sort((a, b) =>
        sortAsString(undefined, undefined, undefined, "asc", a.AnmNo1, b.AnmNo1)
    );
}
function getChildrenForSowWithGilts(
    animal,
    {t, crossBreeding, farm, sowsWithGilts, validations, races} = {}
) {
    const {tattooGiltsWithOneBoar} = validations;
    const cycles =
        preEvents(sowsWithGilts[animal.AnmID], null)?.cycleTable || [];
    const lastCycle = last(cycles);
    const lastParturition = get(lastCycle, `[${EventTypes.PARTURITION}][0]`);
    if (!animal?.Race) return {children: [], error: t("tattoo.noGiltBreeding")};
    if (!animal?.AnmNo1 || animal?.AnmNo1 === "-")
        return {children: [], error: t("tattoo.noSowAnmNo1")};
    const inseminations = get(lastCycle, `[${EventTypes.INSEMINATION}]`);
    if (tattooGiltsWithOneBoar && inseminations.length > 1)
        return {children: [], error: t("tattoo.multipleBoars")};
    const insemination = inseminations[0];
    const submitted = (lastCycle?.[EventTypes.TATTOO] || []).reduce(
        (prev, curr) => prev + (curr.EvData.TattooedAnimals?.length || 0),
        0
    );
    const lastParturitionGilts = get(lastParturition, "EvData.GiltsCnt", 0);
    const boar = animalsDB.getAnimalById(insemination.EvData.BoarID, {
        joinEvents: false,
        findDeleted: true,
    });

    if (!boar || !boar.Race)
        return {children: [], error: t("tattoo.noBoarBreeding")};
    if (!boar?.AnmNo2 || boar?.AnmNo2 === "-")
        return {children: [], error: t("tattoo.noBoarAnmNo2")};
    const giltRace = getRaceCrossing(animal.Race, boar.Race, crossBreeding);
    const breedingCounter = breedingCountersDB.getBreedingCountersByRace(
        farm,
        giltRace
    )[0];
    if (!giltRace) return {children: [], error: t("tattoo.noCrossing")};
    if (!breedingCounter) {
        const race = races?.WData?.find((race) => race.ID === giltRace)?.Value;
        return {
            children: [],
            error: t("tattoo.noBreedingCounter", {race: race}),
        };
    }
    const separatedAmount = get(lastCycle, EventTypes.SEPARATION, []).reduce(
        (prev, curr) => prev + curr.EvData.PiCnt,
        0
    );
    if (separatedAmount === lastParturition?.EvData?.HealthyCnt) {
        return {
            children: [],
            error: t("tattoo.allSeparated"),
        };
    }
    return {
        children: getGiltsForAnimal(
            animal,
            Math.min(
                lastParturition?.EvData?.HealthyCnt -
                    separatedAmount -
                    submitted, // w przypadku, gdy znaleziono odsadzenie i odsadzono czesc loszek
                lastParturitionGilts - submitted // nie odsadzono zadnych loszek
            ),
            farm,
            breedingCounter,
            giltRace,
            {
                boarID: boar.AnmID,
                parturitionTime: lastParturition.EvTime,
            }
        ),
        error: "",
    };
}

export function getPlacementsForGroup(group, animalKind) {
    const plcmnts = {};
    for (const AnmID of group.AnmIDs) {
        const animal = animalsDB.getAnimalById(AnmID, {joinEvents: false});
        if (!animal || animal.DtaDthTime) continue;
        if (typeof animalKind === "number" && animalKind !== animal.AnimalKind)
            continue;
        for (const plcmnt of Array.isArray(animal.PlcmntID)
            ? animal.PlcmntID
            : [animal]) {
            const showIndividualNumber = isIndividualAnimal(animal);
            const plcmntKey = showIndividualNumber
                ? `${plcmnt.PlcmntID}_${animal.RFID || animal.AnmID}`
                : plcmnt.PlcmntID;
            if (!plcmnts[plcmntKey]) {
                const childItem = {
                    id: plcmnt.PlcmntID,
                    AnmCnt: 0,
                    AnmIDs: new Set(),
                    AnmNo1: showIndividualNumber ? animal.AnmNo1 : null,
                    RFID: animal.RFID,
                    Tagged: animal.Tagged || false,
                    DtaBrthTime: animal.DtaBrthTime,
                    DtaInTime: animal.DtaInTime,
                    AnimalKind: animal.AnimalKind,
                    GroupID: group.AnmGrp,
                    GroupBirth: group.GroupBirthTime,
                };
                if (
                    !showIndividualNumber &&
                    group.hasOwnProperty("getDetailedDataForAnimal")
                ) {
                    childItem.getDetailedDataForAnimal =
                        group.getDetailedDataForAnimal;
                }
                plcmnts[plcmntKey] = childItem;
            }
            plcmnts[plcmntKey].AnmCnt += plcmnt.AnmCnt;
            plcmnts[plcmntKey].AnmIDs.add(animal.AnmID);
        }
    }
    return Object.values(plcmnts);
}

export function onAnimalChange(
    data,
    old,
    filters,
    onChange,
    animalKind,
    additionalProps
) {
    const {animal} = data;
    if (animal) {
        console.log(animal);
        if (data.children) {
            data.removeChildren = data.children.length;
        }
        if (animal.AnmGrp) {
            data.children = getPlacementsForGroup(animal, animalKind);
        } else if (additionalProps?.validateSow) {
            const childrenObject = getChildrenForTattooedSowSeparation(
                animal,
                additionalProps
            );
            data.children = childrenObject?.children || [];
            data.childrenError = childrenObject?.error;
        } else if (animal.hasTattooedPiglets) {
            data.children = getChildrenForTattooedSow(animal, additionalProps);
        } else if (animal.hasGilts) {
            const childrenObject = {
                ...getChildrenForSowWithGilts(animal, additionalProps),
            };
            data.children = childrenObject?.children || [];
            data.childrenError = childrenObject?.error;
        } else {
            delete data.children;
        }
    } else {
        if (data.children) {
            data.removeChildren = data.children.length;
        }
        delete data.children;
    }
    if (onChange) onChange(data, old, filters);
    return data;
}

export const isAnimalEditable = (row, editable, childInput = false) => {
    if (childInput) return true;
    if (row.animal?.isGroupChild) return false;
    if (editable) return editable(row);
    return true;
};

export const isAnimalSpanned = ({row, type}, colSpan) => {
    if (type === "ROW") {
        if (
            row.animal?.AnmGrp ||
            row.animal?.hasTattooedPiglets ||
            row.animal?.hasGilts
        ) {
            return colSpan;
        }
    }
    return undefined;
};

export function animalField(
    sows,
    onChange,
    {
        showTechnologyGroupSelect = true,
        key = "animal",
        headerName = i18n.t("animalNumber"),
        forceHeaderName = false,
        showAmount = false,
        childInput = false,
        licPackages = undefined,
        colSpan = undefined,
        additionalProps = {},
        customFilterRenderer: {
            renderer = undefined,
            filterPlaceholder,
            filterOptions,
        } = {},
        clearRowOnRemove = true,
    } = {},
    editable = null
) {
    let chooserFilter = store?.getState().events?.eventChooserFilter;
    let shouldShowTechnologyGroupSelect =
        showTechnologyGroupSelect &&
        hasMinPackageLevel(
            licPackages,
            [LicPackageKeys.SOW],
            [LicPackageLevel.BASIC]
        );
    return {
        name: forceHeaderName
            ? headerName
            : [AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(chooserFilter)
            ? getManageSubgroups()
                ? i18n.t("subgroupNumber")
                : i18n.t("groupNumber")
            : headerName,
        key,
        editor: (props) => {
            const {
                column: {key},
                row,
            } = props;
            const isGroupChild = get(row, `[${key}].isGroupChild`, false);
            if (isGroupChild && childInput)
                return (
                    <InputEditor type={"text"} {...props} childKey={"AnmNo1"} />
                );
            return <SelectEditor {...props} options={sows} />;
        },
        formatter: showAmount ? animalWithAmountFormatter : animalFormatter,
        filterRenderer: shouldShowTechnologyGroupSelect
            ? technologyGroupSelect
            : renderer,
        filterPlaceholder,
        filterOptions,
        disableAutoInsert: true,
        disableDragAndDrop: true,
        clearRowOnRemove,
        onChange: (data, old, filters) =>
            onAnimalChange(data, old, filters, onChange, null, additionalProps),
        editable: (row) => isAnimalEditable(row, editable, childInput),
        colSpan: colSpan ? (args) => isAnimalSpanned(args, colSpan) : undefined,
    };
}

const onLocationChange = (data) => {
    if (data.location) {
        if (data.children) {
            data.removeChildren = data.children.length;
        }
        if (data.location.children.length > 0) {
            data.children = data.location.children;
        }
    } else {
        if (data.children) {
            data.removeChildren = data.children.length;
        }
        delete data.children;
    }
    return data;
};

const isLocationSpanned = ({row, type}, colSpan) => {
    if (type === "ROW") {
        if (!row.location || row.location.children) {
            return colSpan;
        }
    }
    return undefined;
};

const isLocationEditable = (row) => !row.location?.isGroupChild;

export function locationField(locations, colSpan, name = i18n.t("location")) {
    return {
        name,
        key: "location",
        placeholder: i18n.t("events.placeholder.location"),
        editor: (props) => <SelectEditor {...props} options={locations} />,
        formatter: animalWithAmountFormatter,
        onChange: onLocationChange,
        colSpan: (data) => isLocationSpanned(data, colSpan),
        editable: isLocationEditable,
    };
}

/*******************************
 INSERTS
 *******************************/
export function technologyGroupInsert(
    group,
    value,
    {
        field = "animal",
        onRowChange = () => {},
        addPiglets = false,
        date = moment(),
        weight = [],
        animalWeightOption = AnimalWeightOptions.SINGLE,
    } = {}
) {
    if (group) {
        console.log(group);
        let animals = [];
        if (group.AnmIDs) {
            animals = group.AnmIDs.map((AnmID) =>
                animalsDB.getAnimalById(AnmID, {joinEvents: false})
            ).filter((item) => item && !item.DtaDthTime);
        } else {
            animals = group.animals.slice();
        }
        const expandValueObject = (animal) => {
            if (!animal) return {};
            const item = {
                [field]: animal,
                ...onRowChange(animal),
            };
            if (addPiglets) {
                const piglets = eventsDB.getPigBalanceInSpecificCycle(
                    animal,
                    date
                );
                const arithmeticWeight = getArithmeticWeight(weight);
                const base = getBaseForWeight(piglets, {animalWeightOption});
                item.piglets = piglets;
                item.weight = convertWeightUnitTo(
                    Math.round(arithmeticWeight * base),
                    {
                        unit: getAnimalUnit(),
                        rawValue: true,
                    }
                );
            }
            return item;
        };
        animals.sort((a, b) => a.AnmNo1 && a.AnmNo1.localeCompare(b.AnmNo1));
        value = value.map((row) => {
            const animal = animals.shift();
            return {
                index: row.index,
                rowIdx: row.rowIdx,
                ...expandValueObject(animal),
            };
        });
        for (let animal of animals) {
            const item = {
                index: value.length + 1,
                rowIdx: value.length,
                ...expandValueObject(animal),
            };
            value.push(item);
        }
    } else {
        value = value.map((row) => {
            return {index: row.index, rowIdx: row.rowIdx};
        });
    }
    return value;
}

function canChangeField(row, field, oldFilter) {
    if (isNil(row[field])) return true;
    if (moment.isMoment(row[field])) {
        return row[field].isSame(oldFilter, "day");
    } else {
        return isEqual(row[field], oldFilter);
    }
}

export function insertData(
    data,
    field,
    value,
    oldFilter,
    predicate = () => true,
    onChangeColumn = null
) {
    if (!isNil(data)) {
        value = value.map((row) => {
            if (
                row.animal?.AnmGrp ||
                row.location?.children ||
                row.sowWithGilts?.children
            )
                return row;
            if (!values(row).every(isEmpty)) {
                let obj = {
                    ...row,
                    [field]: canChangeField(row, field, oldFilter)
                        ? predicate(row, value, data)
                            ? data
                            : null
                        : row[field],
                };
                if (onChangeColumn) obj = onChangeColumn(obj, row);
                return obj;
            }
            return row;
        });
    } else {
        value = value.map((row) => {
            let obj = {
                ...row,
                [field]: isNil(oldFilter)
                    ? null
                    : isEqual(row[field], oldFilter)
                    ? null
                    : row[field],
            };
            if (onChangeColumn) obj = onChangeColumn(obj, row);
            return obj;
        });
    }
    return value;
}

export function insertOperator(
    filters,
    value,
    oldFilter,
    predicate = () => true
) {
    if (filters.hasOwnProperty("operator")) {
        const {operator} = filters;
        return insertData(operator, "operator", value, oldFilter, predicate);
    }
    return value;
}

export function insertComment(
    filters,
    value,
    oldFilter,
    predicate = () => true
) {
    if (filters.hasOwnProperty("comment")) {
        const {comment} = filters;
        return insertData(comment, "comment", value, oldFilter, predicate);
    }
    return value;
}

export function insertFilters(filters, value, oldFilters) {
    if (filters.hasOwnProperty("animal")) {
        const {animal: group} = filters;
        const {animal: oldGroup} = oldFilters;
        if (!isEqual(group, oldGroup)) {
            value = technologyGroupInsert(group, value);
        }
    }
    if (filters.hasOwnProperty("date")) {
        const {date} = filters;
        value = insertData(date, "date", value, oldFilters.date);
    }
    value = insertOperator(filters, value, oldFilters.operator);
    value = insertComment(filters, value, oldFilters.comment);
    return value;
}

export function warningGrid(data, customValidation) {
    if (data) {
        return data.map((row) => {
            return {...customValidation(row)};
        });
    }
}

/*******************************
 CHILDREN HANDLERS
 *******************************/

export function handleGroupChildren(children, filters, buildingsMap) {
    return children.map((child) => {
        const location = formatLocationName(child.id, {buildingsMap});
        const obj = {
            animal: {
                isGroupChild: true,
                AnmNo1:
                    child.Tagged ||
                    child.RFID ||
                    child.AnimalKind === AnimalTypes.RENOVATION_SOW
                        ? `${location} - ${child.AnmNo1 || child.RFID}`
                        : location,
                AnmIDs: [...child.AnmIDs],
                AnmCnt: child.AnmCnt,
                PlcmntID: child.id,
                RFID: child.RFID,
                Tagged: child.Tagged,
                DtaBrthTime: child.DtaBrthTime,
                AnimalKind: child.AnimalKind,
                GroupID: child.GroupID,
                GroupBirth: child.GroupBirth,
                DtaInTime: child.DtaInTime,
            },
        };
        if (typeof child.weight === "number") {
            obj.weight = child.weight;
        }
        if (child.hasOwnProperty("getDetailedDataForAnimal")) {
            obj.animal.getDetailedDataForAnimal =
                child.getDetailedDataForAnimal;
        }
        for (let key in filters) {
            if (key === "animal") continue;
            obj[key] = filters[key];
        }
        return obj;
    });
}

export function handleTattooedAnimalsChildren(children, filters) {
    return children.map((child) => ({
        animal: {
            ...child,
            isGroupChild: true,
        },
        AnimalType: AnimalTypes.PIG,
        sowWithGilts: true,
    }));
}

/*******************************
 VALIDATE
 *******************************/

export function validateGrid(
    data,
    customValidation,
    ignoreFields = ["index", "rowIdx"],
    allowDuplicatedAnimals = false,
    ignoreAnimal = false,
    childrenKey = "animal.AnmGrp",
    allowInFuture = false,
    validateOperator = true,
    validateComment = true,
    validateDate = true
) {
    let hasAnyErrors = false;
    let validationSettings = getValidationSettings();
    let hasPrivilegedAccess = checkIfUserHasPrivilegedAccess();
    let isService = checkIfUserIsService();
    let errors = data.map((row) => {
        let rowErrors = {};
        if (get(row, childrenKey)) return rowErrors;
        if (!isEmptyRow(row, ignoreFields) && !row.ignoreValidation) {
            if (!ignoreAnimal) {
                if (!row.animal) {
                    rowErrors.animal = i18n.t("required");
                } else if (
                    !allowDuplicatedAnimals &&
                    !row.animal.isGroupChild
                ) {
                    if (
                        data.filter(
                            (item) =>
                                item.animal &&
                                item.animal.AnmID === row.animal.AnmID
                        ).length > 1
                    ) {
                        rowErrors.animal = i18n.t("errors.duplicate");
                    }
                }
            }
            if (hasPrivilegedAccess && validateOperator) {
                if (!row.operator) {
                    rowErrors.operator = i18n.t("required");
                }
            }
            if (
                validateComment &&
                get(
                    validationSettings,
                    "requiredComments",
                    ValidationDefaultValues.requiredComments
                ) &&
                !row.comment
            ) {
                rowErrors.comment = i18n.t("required");
            }
            if (validateDate) {
                if (!row.date) {
                    rowErrors.date = i18n.t("required");
                } else if (
                    !allowInFuture &&
                    !isService &&
                    row.date.isAfter(new Date(), "days")
                ) {
                    rowErrors.date = i18n.t("errors.doNotInsertEventInFuture");
                } else if (
                    row.animal?.DtaBrthTime &&
                    moment(row.animal.DtaBrthTime).isAfter(row.date, "day")
                ) {
                    rowErrors.date = i18n.t("errors.eventBeforeBirth");
                }
            }
            // na razie ta walidacje jest useless bo - https://projekty.fetura.com.pl/issues/5052
            // else if (row.animal?.DtaInTime && moment(row.animal.DtaInTime).isAfter(row.date, "day")) {
            //     rowErrors.date = i18n.t("errors.animalInsertionAfter", {insertionDate: moment(row.animal.DtaInTime).format("DD.MM.YY")});
            // }
            rowErrors = {
                ...rowErrors,
                ...customValidation(row),
            };
        }
        if (!isEmpty(rowErrors)) {
            hasAnyErrors = true;
        }
        return rowErrors;
    });
    if (hasAnyErrors) return errors;
    return undefined;
}

export const shouldRemoveFilter = memoize(
    (animals, values) => {
        for (let row of values) {
            if (row.animal) {
                let hasAnimal = findIndex(
                    animals,
                    (o) => o.AnmID === row.animal.AnmID
                );
                if (hasAnimal >= 0) {
                    animals.splice(hasAnimal, 1);
                } else {
                    return false;
                }
            }
        }
        return animals.length <= 0;
    },
    (animals, values) => JSON.stringify(animals) + JSON.stringify(values)
);

export const checkIfHaveOnlyTechnologyGroup = memoize(
    (group, values) => {
        console.log(group);
        let animals = [];
        if (group.AnmIDs) {
            animals = group.AnmIDs.map((AnmID) =>
                animalsDB.getAnimalById(AnmID, {joinEvents: false})
            ).filter((item) => item && !item.DtaDthTime);
        } else {
            animals = group.animals.slice();
        }
        return shouldRemoveFilter(animals, values);
    },
    (group, values) =>
        group.AnmIDs
            ? group.GrID + JSON.stringify(values)
            : group.name + JSON.stringify(values)
);
