import PropTypes from "prop-types";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {showFile} from "../../../actions/filesActions";
import {getFileObject as getFileObjectAPI} from "../../../api/files/getFileObject";
import animalsDB from "../../../database/animalsDB";
import {
    getFallList, getMostRecentEventIDs,
    getSelectedAnimalForDocuments,
    makeGetAnimalsInGroup,
} from "../../../selectors/animalDocumentsSelectors";
import {getFallReasons} from "../../../selectors/dictionarySelectors";
import {getSales} from "../../../selectors/salesSelector";
import {getEmployeeList, getServiceList} from "../../../selectors/userSelector";
import {dateFormatter} from "../../../utils/AnimalDocumentsUtils";
import {formatAnimalName, weightFormatter} from "../../../utils/AnimalsUtils";
import {isMobile} from "../../../utils/MobileUtils";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";
import {employeesValueFormatter} from "../../../utils/RaportsUtils";
import {getManageSubgroups} from "../../../utils/SettingsUtils";
import {sortDateStrings} from "../../../utils/SortUtils";
import {formatLocationName} from "../../../utils/global-formatters/formatLocationName";
import TableGrid from "../../basics/table-grid/TableGrid";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import {
    ModalName as GroupCardRemoveEventModalName
} from "../../modals-new/group-card-remove-event-modal/GroupCardRemoveEventModal";
import {ModalName as EditSellOrFallModalName} from "../../modals-new/edit-sell-or-fall-modal/EditSellOrFallModal";
import CollapsableContainer from "../containers/CollapsableContainer";
import AnimalReferenceDocumentModalGrid from "../../grids/animal-reference-document/AnimalReferenceDocumentModalGrid";
import * as EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {removeRenderer} from "../TableComponents";

function makeMapStateToProps() {
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    return (state) => ({
        animals: getAnimalsInGroup(state),
        employees: getEmployeeList(state),
        service: getServiceList(state),
        fallReasons: getFallReasons(state),
        sales: getSales(state),
        farm: state.location.farm,
        group: getSelectedAnimalForDocuments(state),
        fallEvents: getFallList(state),
        mostRecentEvents: getMostRecentEventIDs(state)([EventTypes.FALL, EventTypes.SELL])
    });
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch),
    };
}

class FallGrid extends React.Component {
    constructor(props) {
        super(props);
        this.showOperator = checkIfUserHasPrivilegedAccess();
        this.mobile = isMobile();
        this.canManageSubgroups = getManageSubgroups();
        this.renderRemove = removeRenderer.bind(this);
        this.state = {
            imageLoadingIndex: null,
        };
    }

    employeesValueFormatter = (value) => {
        const {employees, service} = this.props;
        return employeesValueFormatter(value, employees, service);
    };

    animalFormatter = (AnmID) => {
        const {animals, isGroup} = this.props;
        const animal =
            animals.find((a) => a.AnmID === AnmID) ||
            animalsDB.getAnimalById(AnmID, {
                joinEvents: false,
                findDeleted: true,
            });
        if (!isGroup) return animal?.AnmNo1 || "";
        if (animal.RFID || animal.Tagged || this.canManageSubgroups) {
            return (
                <AnimalReferenceDocumentModalGrid
                    value={animal.AnmNo1}
                    object={animal}
                />
            );
        }
        return "";
    };

    fallReasonFormatter = (value) => {
        const {fallReasons} = this.props;
        const reason = fallReasons.WData.find(({ID}) => ID === value);
        return reason?.Value;
    };

    getAnimalById = (animalId) => this.props.animals.find((a) => a.AnmID === animalId) ||
        animalsDB.getAnimalById(animalId, {joinEvents: false, findDeleted: true})

    onRemoveClick(props) {
        const {t, group, isGroup} = this.props;
        const animal = this.getAnimalById(props.value.AnmID);
        this.props.show(GroupCardRemoveEventModalName, {
            title: t("removeFall"),
            text: t("removeFallText", {number: formatAnimalName(animal)}),
            name: isGroup ? group.GrNo1 : animal.AnmNo1,
            AnmID: props.value.AnmID,
            AnmCnt: props.value.AnmCnt,
            EvID: props.value.EvID,
            AnmGrp: isGroup ? group.AnmGrp : undefined,
            isGroup: !!isGroup
        });
    }

    onEditClick(props) {
        const {group} = this.props;
        this.props.show(EditSellOrFallModalName, {event: props.value, group});
    }

    locationValueFormatter = (plcmntID) => formatLocationName(plcmntID);

    isIndividualAnimal = (animal) =>
        animal.hasOwnProperty("RFID") || animal.Tagged;

    hasIndividualAnimals = (events) => {
        const {animals} = this.props;
        const _animals = animals.filter(
            (animal) =>
                this.isIndividualAnimal(animal) &&
                events.some((e) => e.AnmID === animal.AnmID)
        );
        return _animals.length > 0;
    };

    onShowImageFileClick = (props) => {
        const file = props?.object?.Files?.[0];
        const {imageLoadingIndex} = this.state;
        if (!file || typeof imageLoadingIndex === "number") return;
        const {farm, dispatch} = this.props;
        this.setState(
            {
                imageLoadingIndex: props.index,
            },
            () => {
                getFileObjectAPI(file.FileID, {FarmID: farm})
                    .then((res) => {
                        dispatch(showFile(res.file));
                    })
                    .finally(() => {
                        this.setState({imageLoadingIndex: null});
                    });
            }
        );
    };

    getImageClassIcon(props) {
        const {imageLoadingIndex} = this.state;
        const file = props?.object?.Files?.[0];
        if (!file) return null;
        if (imageLoadingIndex === props?.index)
            return "fas fa-spin fa-fw fa-circle-notch fa-spin";
        return "fas fa-camera pointer";
    }

    renderEdit = (props) => <i className="fas fa-edit pointer" onClick={() => this.onEditClick(props)}/>;

    render() {
        const {t, isGroup, fallEvents} = this.props;
        const hasIndividualAnimals = this.hasIndividualAnimals(fallEvents);
        const headers = [
            isGroup && (hasIndividualAnimals || this.canManageSubgroups) && {
                name: t("animalNumber"),
                field: "AnmID",
                valueFormatter: this.animalFormatter,
            },
            isGroup && {
                name: t("location"),
                field: "EvData.PlcmntID",
                valueFormatter: this.locationValueFormatter,
            },
            {
                name: t("date"),
                field: "EvTime",
                valueFormatter: dateFormatter,
                customSort: sortDateStrings,
                _mobileDate: true,
            },
            {
                name: t("amount"),
                field: "EvData.AnmCnt",
                _mobileHide: true,
            },
            {
                name: t("avgPieceWeight"),
                field: "EvData.Weight",
                valueFormatter: weightFormatter,
                disableValueFormatterSort: true,
            },
            {
                name: t("reason"),
                field: "EvData.Reasn",
                _mobileHide: true,
                valueFormatter: this.fallReasonFormatter,
            },
            {
                name: t("eventGrid.operator"),
                field: "EvData.OperID",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: this.showOperator,
                colWidth: 2,
            },
            {
                name: t("comment"),
                field: "Comment",
            },
            {
                name: "",
                component: (props) => (
                    <i
                        className={this.getImageClassIcon(props)}
                        onClick={() => this.onShowImageFileClick(props)}
                    />
                ),
                headerClassName: "index",
                itemClassName: "index",
                notSortable: true,
            },
            {
                name: "",
                component: this.renderEdit,
                headerClassName: "index",
                itemClassName: "index",
                notSortable: true,
            },
            {
                name: "",
                component: this.renderRemove,
                headerClassName: "index",
                itemClassName: "index",
                notSortable: true,
            },
        ].filter((o) => !!o);
        return (
            <>
                <CollapsableContainer.Card
                    id="cy-fall-card"
                    header={t("menu.fall")}
                    defaultExpanded={fallEvents.length > 0}>
                    <TableGrid
                        data={fallEvents}
                        headers={headers}
                        mobileRow={<DefaultMobileRow/>}
                        showPagination={!this.mobile}
                        paginationItems={10}
                        scrollOnPageChange={false}
                    />
                </CollapsableContainer.Card>
            </>
        );
    }
}

FallGrid.defaultProps = {
    isGroup: false,
};

FallGrid.propTypes = {
    isGroup: PropTypes.bool,
};

export default compose(
    withTranslation(),
    connect(makeMapStateToProps, mapDispatchToProps)
)(FallGrid);
