const initialValue = {
    rightSideBarContent: 0,
    leftSideBarContent: 1,
    area: {
        currentView: ""
    },
    farm: "",
    building: "",
    activeFarm: "",
    navMenuElementShow: "",
    activeFun: ""
};

export default function locationReducer(state = initialValue, action) {
    switch (action.type) {
        case "CHANGE_ACTIVE_FUNCTION": {
            return {
                ...state,
                activeFun: action.payload
            }
        }
        case "CHANGE_FARM": {
            return {
                ...state,
                farm: action.payload,
                sector: "",
                building: "",
            }
        }
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "CHANGE_AREA_FARM": {
            return {
                ...state,
                area: {
                    ...state.area,
                    currentView: "farm",
                    farm: action.payload
                },
            }
        }
        case "CHANGE_AREA_FARM_NEW": {
            return {
                ...state,
                area: {
                    ...state.area,
                    currentView: "farm",
                    farm: action.payload
                },
                active: action.payload.FarmID
            }
        }
        case "CHANGE_BUILDING": {
            return {
                ...state,
                building: action.payload,
                sector: ""
            }
        }
        case "CHANGE_AREA_BUILDING": {
            let active;
            if (action.payload) {
                active = action.payload.BgID;
                // console.log('change area building', action.payload);
            }
            return {
                ...state,
                area: {
                    ...state.area,
                    currentView: "building",
                    building: action.payload
                },
                active: active
            }
        }
        case "CHANGE_SECTOR": {
            return {
                ...state,
                sector: action.payload
            }
        }
        case "CHANGE_AREA_SECTOR": {
            let active;
            if (action.payload) {
                // console.log('ppppppppppp', action.payload)
                active = action.payload.SID
            }
            return {
                ...state,
                area: {
                    ...state.area,
                    currentView: "sector",
                    sector: action.payload
                },
                active: active
            }
        }
        case "CHANGE_CHAMBER": {
            return {
                ...state,
                chamber: action.payload
            }
        }
        case "CHANGE_AREA_CHAMBER": {
            return {
                ...state,
                area: {
                    ...state.area,
                    currentView: "chamber",
                    chamber: action.payload.chamber,
                    SType: action.payload.SType
                },
                active: action.payload.chamber.CID
            }
        }
        case "SET_SETTINGS_VIEW": {
            return {
                ...state,
                area: {
                    ...state.area,
                    currentView: action.payload
                }
            }
        }
        case "SET_DEFAULT_VALUES_ON_AREA": {
            return {
                ...state,
                active: "",
                area: {
                    currentView: ""
                }
            }
        }
        case "CHANGE_RIGHT_SIDEBAR_CONTENT": {
            return {
                ...state,
                rightSideBarContent: action.payload
            }
        }
        case "CHANGE_LEFT_SIDEBAR_CONTENT": {
            return {
                ...state,
                leftSideBarContent: action.payload
            }
        }
        case "UPDATE_BUILDING_FULFILLED":
        case "DELETE_SECTOR_FULFILLED": {
            return {
                ...state,
                area: {
                    ...state.area,
                    building: action.payload
                }
            }
        }
        case "FETCH_BUILDINGS_BY_FARM_ID_FULFILLED": {
            return {
                ...state,
                area: {
                    ...state.area,
                    //building: action.payload
                }
            }
        }
        case "CHANGE_SHOWING_FARM": {
            return {
                ...state,
                activeFarm: action.payload
            }
        }
        case "CHANGE_DEVICE": {
            return {
                ...state,
                area: {
                    ...state.area,
                    device: action.payload
                }
            }
        }
        case "SHOW_HIDE_NAV_MENU_ELEMENT":
            // var tmpMap = new Map(state.navMenuElementShow);
            // var isShow = tmpMap.get(action.payload);
            // //console.log("act id",action.payload)
            // tmpMap.set(action.payload, !isShow)
            let tmpMap = action.payload;
            return {...state, navMenuElementShow: tmpMap};
        default:
            return state
    }
}
