export const initialValue = {
    showChangePasswordModal: false,
    showEditAccountModal: false,
    currentEditUser: null,
    showConfirmMediumModal: false,
    mediumType: ""
};

export default function currentAccountReducer(state = initialValue, action) {
    switch (action.type) {
        case "SHOW_PASSWORD_MODAL":
            return {...state, showChangePasswordModal: true};
        case "HIDE_PASSWORD_MODAL":
            return {...state, showChangePasswordModal: false};
        case "SHOW_EDIT_ACCOUNT_MODAL":
            return {...state, showEditAccountModal: true, currentEditUser: action.payload};
        case "HIDE_EDIT_ACCOUNT_MODAL":
            return {...state, showEditAccountModal: false, currentEditUser: null};
        case "SHOW_CONFIRM_MEDIUM_MODAL":
            return {...state, showConfirmMediumModal: true, mediumType: action.payload};
        case "HIDE_CONFIRM_MEDIUM_MODAL":
            return {...state, showConfirmMediumModal: false, mediumType: ""};
        default:
            return state;
    }
}
