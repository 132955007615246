import React, {Component} from 'react';
import VideoPlayer from "./VideoPlayer";
import "./_video-player-picture-in-picture.scss";
import Button from "../button/Button";
import {connect} from "react-redux";
import {getVideoForPiP} from "../../../selectors/pipSelector";
import {isEqual} from "lodash";
import {onElementPainted} from "../../../utils/DOMUtils";
import {setVideoFoPiP} from "../../../actions/pipActions";

function mapStateToProps(state) {
    return {
        video: getVideoForPiP(state)
    }
}

class VideoPlayerPictureInPicture extends Component {

    state = {
        expanded: true
    }

    pos1 = 0;
    pos2 = 0;
    pos3 = 0;
    pos4 = 0;
    container = React.createRef();
    videoPlayer = React.createRef();

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(this.props.video, prevProps.video) && this.props.video) {
            // nowe video
            const {video} = this.props;
            onElementPainted(() => {
                console.log(this.videoPlayer.current);
                this.videoPlayer.current.setVideoTime(video.videoElement.currentTime);
                if (video.playOnStart) {
                    this.videoPlayer.current.onPlayClick();
                }
            })
        }
    }

    onMouseDown = e => {
        e.preventDefault();
        this.pos3 = e.clientX;
        this.pos4 = e.clientY;
        document.addEventListener("mousemove", this.moveElement);
        document.addEventListener("mouseup", this.onMouseUp);
    }

    onMouseUp = e => {
        document.removeEventListener("mousemove", this.moveElement);
        document.removeEventListener("mouseup", this.onMouseUp);
    }

    moveElement = e => {
        e.preventDefault();
        this.pos1 = this.pos3 - e.clientX;
        this.pos2 = this.pos4 - e.clientY;
        this.pos3 = e.clientX;
        this.pos4 = e.clientY;
        this.container.current.style.top = (this.container.current.offsetTop - this.pos2) + "px";
        this.container.current.style.left = (this.container.current.offsetLeft - this.pos1) + "px";
    }

    onExpandClick = () => {
        this.setState(state => ({
            expanded: !state.expanded
        }));
    }

    onCloseClick = () => {
        const {video: {videoElement}} = this.props;
        let isVideoInDOM = document.body.contains(videoElement);
        if (isVideoInDOM) {
            // synchronizacja czasu, poniewaz element jest nadal wyswietlany
            videoElement.currentTime = this.videoPlayer.current.video.current.currentTime;
            if (this.videoPlayer.current.state.playing) {
                videoElement.play();
            }
        }
        this.props.dispatch(setVideoFoPiP(null));
    }

    render() {
        const {video} = this.props;
        if (!video) return null;
        const {expanded} = this.state;
        const {sources, subtitles, onVideoPause, onVideoPlay} = video;
        return (
            <div className={`fetura-picture-in-picture${expanded ? " expanded" : ""}`} ref={this.container}
                 onMouseDown={this.onMouseDown}>
                <VideoPlayer sources={sources} showPiP={false}
                             customButtons={[
                                 <Button key={1} buttonStyle={"text"}
                                         icon={expanded ? <i className="fas fa-compress-alt"/> :
                                             <i className="far fa-expand-alt"/>}
                                         buttonColor={"white"} onClick={this.onExpandClick}/>
                             ]} ref={this.videoPlayer} autoPlay={false} subtitles={subtitles} showFullScreen={false}
                             onVideoPause={onVideoPause} onVideoPlay={onVideoPlay}/>
                <div className="fetura-picture-in-picture-close" onClick={this.onCloseClick}>
                    <i className="fas fa-times error"/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(VideoPlayerPictureInPicture);
