import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import ViewContainerModal from "../../components/basics/view-containter-modal/ViewContainerModal";
import NotificationView from "./NotificationView";
import {withTranslation} from "react-i18next";
import memoizeOne from "memoize-one";

function mapStateToProps(state) {
    return {
        hasNotifications: !!state.notificationHistory.notifications.length
    };
}

class NotificationUrlWrapper extends Component {

    handleHide = () => {
        const {location: {search, pathname, state}, history} = this.props;
        const params = new URLSearchParams(search);
        params.delete("notificationsHistory");
        history.replace({
            pathname: pathname,
            search: params.toString(),
            state
        });
    }

    addClassList = memoizeOne(status => {
        for (const container of [...document.getElementsByClassName("reapop__container")]) {
            container.classList[status ? "add" : "remove"]("d-none");
        }
    })

    componentWillUnmount() {
        this.addClassList(false);
    }

    render() {
        const {location: {search}, t} = this.props;
        const show = new URLSearchParams(search).has("notificationsHistory");
        this.addClassList(show);
        return (
            <>
                <ViewContainerModal onHide={this.handleHide} loading={false}
                                    name={t("notificationsCenter.notificationsHeader")}
                                    show={show} addPaddingForButtons>
                    <NotificationView/>
                </ViewContainerModal>
            </>
        );
    }
}

export default compose(
    withTranslation(),
    withRouter,
    connect(
        mapStateToProps,
    )
)(NotificationUrlWrapper);
