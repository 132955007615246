export default {
    FLOOR: "floor",
    DOOR: "door",
    STEEL: "steel",
    STEEL_DARK: "steel-dark",
    STEEL_LIGHT: "steel-light",
    TROUGH: "trough",
    PIG: "animal",
    DISPENSER: "device",
    PLASTIC_GREEN: "plastic",
}