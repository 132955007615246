import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

/**
 * Pobiera języki dostępne dla danego usera
 * @return {Promise<any|undefined>}
 */
export function getLanguages() {
    const {_LocalUserID} = getUserApiData();
    return invokeApig({
        ...Paths.getLanguages({localUserID: _LocalUserID})
    })
}
