import lokiDB from "./lokiDB";
import {cloneDeep, get, isNil, memoize} from "lodash";
import {getModificationTime, insertInto, setModificationTime} from "../utils/LokiUtils";
import animalsDB from "./animalsDB";
import {getFarm} from "../selectors/farmSelector";
import store from "../store/store";
import {convertBuildingsToOldObject} from "../utils/dbutils/convertBuildings";
import {getLocationName} from "../utils/BuildingUtils";

class Buildings {

    /**********************************
     ***         BUILDINGS          ***
     **********************************/

    constructor() {
        this.getTreeByLocationID = memoize(this.getTreeByLocationID);
        this.getLocationFallthrough = memoize(this.getLocationFallthrough);
    }

    getModificationTime(farmID) {
        return getModificationTime("buildings", "FarmID", farmID);
    }

    clearCache() {
        animalsDB.getAnimalLocationsByPlcmntID.cache.clear();
        animalsDB.getAllAnimalsForLocation.cache.clear();
        this.getTreeByLocationID.cache.clear();
        this.getLocationFallthrough.cache.clear();
    }

    //insert into animals
    insertIntoBuildings(values) {
        insertInto(values, lokiDB.buildings, "BgID");
        setModificationTime("buildings", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
        this.clearCache();
    }

    /**
     * get all buildings with farmID
     * @param farmID
     * @deprecated use store my mates!
     * @returns {*}
     */
    getAllBuildingsForFarm(farmID) {
        try {
            return convertBuildingsToOldObject(lokiDB.buildings.find({
                FarmID: farmID,
                DtaDelTime: {$type: 'undefined'}
            }));
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * uzywac tylko do wrzucania budynkow do stora
     * @param farmID
     * @return {*[]|*}
     */
    getBuildingsForStore(farmID) {
        try {
            return lokiDB.buildings.find({FarmID: farmID});
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    /**
     * Funkcja wyszukujaca stanowisko po jego RFID
     * @param RFID - RFID stanowiska
     * @deprecated use getManageBuildingsList and use find by level and rfid
     * @return box - stanowisko
     */
    getBoxBIDByRfid(RFID) {
        let results = lokiDB.buildings.find();
        let box = undefined;
        results.filter(building => {
            building.Sectors.filter(sector => {
                sector.Chambers.filter(chamber => {
                    if ((!box || box.length < 1) && chamber.Boxes !== undefined) {
                        box = chamber.Boxes.filter(box => (box.RFID === RFID))[0];
                    }
                });
            });
        });
        return box;
    }

    /**
     * Metoda pobiera lokalizacje po RFID
     * @param RFID {string}     numer RFID lokalizacji
     * @deprecated use getManageBuildingsList and use find by rfid
     * @return {*}              lokalizacja
     */
    getLocationByRFID(RFID, farmID, addSType = false) {
        return this.getLocationByProperty("RFID", RFID, farmID, addSType);
    }

    getLocationByIdAndFarmID(id, farmID, addSType = false) {
        return this.getLocationByProperty("id", id, farmID, addSType);
    }

    getLocationByNameAndFarmID(name, farmID, addSType = false) {
        return this.getLocationByProperty("name", name, farmID, addSType);
    }

    getLocationByProperty(propertyName, propertyValue, farmID, addSType = false) {
        let results = convertBuildingsToOldObject(lokiDB.buildings.find({
            DtaDelTime: {$type: 'undefined'},
            FarmID: farmID
        }));
        const isEqualByProperty = (item, idKey, ...parent) => {
            switch (propertyName) {
                case "id": {
                    return get(item, idKey) === propertyValue;
                }
                case "name": {
                    const name = parent.length ? [...parent, item].map(getLocationName).join(" - ") : getLocationName(item);
                    return name === item;
                }
                default: {
                    return get(item, propertyName) === propertyValue;
                }
            }
        }
        for (let building of results) {
            if (isEqualByProperty(building, "BgID")) return building;
            for (let sector of building.Sectors) {
                if (isEqualByProperty(sector, "SID", building)) return sector;
                for (let chamber of sector.Chambers) {
                    if (isEqualByProperty(chamber, "CID", building, sector)) {
                        return {
                            ...chamber,
                            ...(addSType && {additionalInformation: {SType: sector.SType, ParentName: sector.SName}})
                        };
                    }
                    if (chamber.Boxes) {
                        for (let box of chamber.Boxes) {
                            if (isEqualByProperty(box, "BID", building, sector, chamber)) {
                                return {
                                    ...box,
                                    ...(addSType && {
                                        additionalInformation: {
                                            SType: sector.SType,
                                            ParentName: chamber.CName
                                        }
                                    })
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Metoda pobiera wszystkie komory dla danego typu sektora z lokacją w stringu
     * @deprecated use getManageBuildingsList and filter by sectorType and level
     * @param SType
     * @param FarmID
     */
    getAllChambersForSTypeWithLocation(SType, FarmID) {
        try {
            let data = convertBuildingsToOldObject(lokiDB.buildings.find({
                FarmID: FarmID,
                DtaDelTime: {$type: 'undefined'}
            }));
            console.log(data);
            let Sectors = [];
            data.map(b => {
                b.Sectors.map(sector => {
                    sector.location = b.BName;
                    Sectors.push(sector);
                })
            });
            Sectors = Sectors.filter(sec => parseInt(sec.SType) === SType);
            let Chambers = [];
            Sectors.map(s => {
                s.Chambers.map(chamber => {
                    chamber.location = s.location + " - " + s.SName;
                    Chambers.push(chamber);
                })
            });
            return Chambers;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    //todo: zastąpić selectorem jak będzie czas
    getAllChambers(FarmID) {
        try {
            let data = convertBuildingsToOldObject(lokiDB.buildings.find({
                FarmID: FarmID,
                DtaDelTime: {$type: 'undefined'}
            }));
            let chambers = [];
            for (let building of data) {
                for (let sector of building.Sectors) {
                    chambers.push(...sector.Chambers);
                }
            }
            return chambers;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    //todo: zastąpić selectorem jak będzie czas
    getTreeByLocationID(id) {
        let state = store.getState();
        let farm = getFarm(state, id);
        let res = state.farms.farms;
        if (farm) {
            return {farm}
        }
        let bs = convertBuildingsToOldObject(lokiDB.buildings.find());
        for (let i = 0; i < bs.length; i++) {
            if (bs[i].BgID === id) {
                return {
                    building: cloneDeep(bs[i]),
                    farm: res.filter(f => f.FarmID === bs[i].FarmID)[0]
                }
            }
            for (let j = 0; j < bs[i].Sectors.length; j++) {
                if (bs[i].Sectors[j].SID === id) {
                    return {
                        sector: cloneDeep(bs[i].Sectors[j]),
                        sectorIndex: j,
                        building: cloneDeep(bs[i]),
                        farm: res.filter(f => f.FarmID === bs[i].FarmID)[0],
                    }
                }
                for (let k = 0; k < bs[i].Sectors[j].Chambers.length; k++) {
                    if (bs[i].Sectors[j].Chambers[k].CID === id) {
                        return {
                            chamber: cloneDeep(bs[i].Sectors[j].Chambers[k]),
                            chamberIndex: k,
                            sector: cloneDeep(bs[i].Sectors[j]),
                            sectorIndex: j,
                            building: cloneDeep(bs[i]),
                            farm: res.filter(f => f.FarmID === bs[i].FarmID)[0]
                        }
                    }
                    if (bs[i].Sectors[j].Chambers[k].Boxes) {
                        for (let l = 0; l < bs[i].Sectors[j].Chambers[k].Boxes.length; l++) {
                            if (bs[i].Sectors[j].Chambers[k].Boxes[l].BID === id) {
                                return {
                                    box: cloneDeep(bs[i].Sectors[j].Chambers[k].Boxes[l]),
                                    boxIndex: l,
                                    chamber: cloneDeep(bs[i].Sectors[j].Chambers[k]),
                                    chamberIndex: k,
                                    sector: cloneDeep(bs[i].Sectors[j]),
                                    sectorIndex: j,
                                    building: cloneDeep(bs[i]),
                                    farm: res.filter(f => f.FarmID === bs[i].FarmID)[0]
                                }
                            }
                        }
                    }
                }
            }
        }
        return {};
    }

    //todo: zastąpić selectorem jak będzie czas
    getAllSectorsForFarm(FarmID) {
        let bs = convertBuildingsToOldObject(lokiDB.buildings.find({FarmID, DtaDelTime: {$type: 'undefined'}}));
        let sectors = [];
        bs.map(building => {
            sectors = [...sectors, ...building.Sectors];
        });
        return sectors;
    }

    //todo: zastąpić selectorem jak będzie czas
    getLocationByID(id, FarmID = null) {
        let farm = getFarm(store.getState(), id);
        if (farm) return farm;
        const findParams = {};
        if (FarmID) {
            findParams.FarmID = FarmID;
        }
        let build = lokiDB.buildings.findOne({...findParams, BgID: id});
        if (build) return isNil(build.DtaDelTime) ? convertBuildingsToOldObject([build])[0] : undefined;
        let builds = convertBuildingsToOldObject(lokiDB.buildings.find(findParams));
        for (let building of builds) {
            let sector = building.Sectors.find(item => item.SID === id);
            if (sector) return sector;
            for (let sec of building.Sectors) {
                let chamber = sec.Chambers.find(item => item.CID === id);
                if (chamber) return chamber;
                for (let ch of sec.Chambers) {
                    if (ch.Boxes) {
                        let box = ch.Boxes.find(item => item.BID === id);
                        if (box) return box;
                    }
                }
            }
        }
    }

    //todo: zastąpić selectorem jak będzie czas
    getObjectForTreeSelect(FarmID, shouldShowBoxes = true) {
        let builds = convertBuildingsToOldObject(lokiDB.buildings.find({FarmID}));
        builds = builds.filter(item => !item.DtaDelTime);
        return builds.map(building => ({
            name: "BName",
            object: building,
            key: "BgID",
            children: building.Sectors.map(sector => ({
                name: "SName",
                object: sector,
                parent: [{name: "BName", object: building, key: "BgID"}],
                key: "SID",
                children: sector.Chambers.map(chamber => ({
                    name: "CName",
                    object: chamber,
                    parent: [{name: "BName", object: building, key: "BgID"}, {
                        name: "SName",
                        object: sector,
                        key: "SID"
                    }],
                    key: "CID",
                    children: chamber.Boxes && shouldShowBoxes ? chamber.Boxes.map(box => ({
                        name: "BoxesName",
                        object: box,
                        parent: [{name: "BName", object: building, key: "BgID"}, {
                            name: "SName",
                            object: sector,
                            key: "SID"
                        }, {name: "CName", object: chamber, key: "CID"}],
                        key: "BID"
                    })).sort((a, b) => (a.object.BoxesName + "").localeCompare((b.object.BoxesName + ""), [], {numeric: true})) : null
                })).sort((a, b) => a.object.CName.localeCompare(b.object.CName, [], {numeric: true}))
            })).sort((a, b) => a.object.SName.localeCompare(b.object.SName, [], {numeric: true}))
        })).sort((a, b) => a.object.BName.localeCompare(b.object.BName, [], {numeric: true}))
    }

    //todo: zastąpić selectorem jak będzie czas
    getSectorTypeByChamberID(chamberID) {
        let builds = convertBuildingsToOldObject(lokiDB.buildings.find().filter(item => !item.DtaDelTime));
        let sectorType;
        for (let building of builds) {
            const sectors = get(building, "Sectors", []);
            for (let sector of sectors) {
                sectorType = get(sector, "Chambers", []).find(chamber => chamber.CID === chamberID) ? sector.SType : sectorType;
                if (!isNil(sectorType)) {
                    break;
                }
            }
            if (!isNil(sectorType)) {
                break;
            }
        }
        return sectorType;
    }

    //todo: podmienic na cos innego
    getLocationFallthrough(FarmID) {
        let map = {};
        let data = convertBuildingsToOldObject(lokiDB.buildings.find({
            FarmID: FarmID,
            DtaDelTime: {$type: 'undefined'}
        }));
        for (let building of data) {
            map[building.BgID] = [building.BgID];
            for (let sector of building.Sectors || []) {
                map[building.BgID].push(sector.SID);
                map[sector.SID] = [sector.SID];
                for (let chamber of sector.Chambers || []) {
                    map[building.BgID].push(chamber.CID);
                    map[sector.SID].push(chamber.CID);
                    map[chamber.CID] = [chamber.CID];
                    for (let box of chamber.Boxes || []) {
                        map[building.BgID].push(box.BID);
                        map[sector.SID].push(box.BID);
                        map[chamber.CID].push(box.BID);
                        map[box.BID] = [box.BID];
                    }
                }
            }
        }
        return map;
    }

}

const buildingsDB = new Buildings();
export default buildingsDB;
