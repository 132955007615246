import moment from "moment";
import {get} from "lodash";

const _DateFormat = "DD.MM.YY";

export const getEventDate = (data, {UTC = false, inDays = false, fromDay = new Date()} = {}) => {
    const eventDate = get(data, "event.EvTime", null);
    if (inDays) {
        if (UTC) return Math.abs(moment.utc(eventDate).endOf("day").diff(moment.utc(fromDay).startOf("day"), "days"));
        return Math.abs(moment(eventDate).endOf("day").diff(moment(fromDay).startOf("day"), "days"));
    }
    if (UTC) return moment.utc(eventDate).format(_DateFormat);
    return moment(eventDate).format(_DateFormat);
};

export const getExpectedDate = (isInterrupted, rowIndex, expectedDates, eventCode, inDays, fromDay) => {
    if (!isInterrupted && typeof rowIndex === "number") {
        const event = get(expectedDates, `[${rowIndex}][${eventCode}]`);
        if (typeof event?.EvTime === "number") {
            return getEventDate({event}, {inDays, fromDay});
        }
    }
    return "";
};