import React from "react";
import PropTypes from "prop-types";
import "./_printable-table-grid.scss";
import { get, isFunction } from "lodash";

export default class PrintableTableGrid extends React.Component {

    renderItem(data, header, index, isBottom = false) {
        const { field, filterColumn, valueFormatter, component } = header;
        let value = data;
        if (field || filterColumn) {
            value = get(data, field || filterColumn, "");
        }
        if (isBottom) {
            if (typeof value === "object") return null; // czasem nie ma wszystkich pól i value jest obiektem, a react nie wyrenderuje tego
            return <strong>{value}</strong>;
        }
        if (valueFormatter) {
            value = valueFormatter(value);
        }
        if (!component) {
            const tooltipContent = get(value, "props.tooltipContent", "")
            return (
                <>
                    {value}
                    {tooltipContent}
                </>
            )
        }
        if (isFunction(component)) {
            return component({ index, value, page: 0, paginationItems: 0 }, this.props);
        } else {
            return React.cloneElement(component, { index, value, page: 0, paginationItems: 0 })
        }
    }

    getHeaders() {
        const { headers, getHeaders } = this.props;
        return isFunction(getHeaders) ? getHeaders() : headers;
    }

    createPinnedRows = (data = []) => {
        const { shouldIndex } = this.props;
        const headers = this.getHeaders();
        return <tbody className="bottom-pinned-container">
            {
                data.map((d, i) =>
                    <tr>
                        {
                            shouldIndex && <td />
                        }
                        {
                            headers.map((header, j) =>
                                <td key={`${i}.${j}.bottom`}>
                                    {this.renderItem(d, header, i, true)}
                                </td>
                            )
                        }
                    </tr>
                )
            }
        </tbody>;
    };

    render() {
        const { data, shouldIndex, bottomPinnedRows, getData } = this.props;
        const printableData = isFunction(getData) ? getData() : data;
        const headers = this.getHeaders();
        return (
            <>
                <table className="printable-fetura-grid">
                    <thead>
                        <tr>
                            {
                                shouldIndex && <th>#</th>
                            }
                            {
                                headers.map((header, index) =>
                                    <th key={index}
                                        className={header.headerClassName && header.headerClassName.includes("empty") ? "empty" : null}>
                                        {header.name}
                                    </th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            printableData.map((d, i) =>
                                <tr>
                                    {
                                        shouldIndex && <td>{i + 1}</td>
                                    }
                                    {
                                        headers.map((header, j) =>
                                            <td key={`${i}.${j}`}>
                                                {this.renderItem(d, header, i)}
                                            </td>
                                        )
                                    }
                                </tr>
                            )
                        }
                    </tbody>
                    {
                        bottomPinnedRows &&
                            isFunction(bottomPinnedRows) ? this.createPinnedRows(bottomPinnedRows(printableData)) : this.createPinnedRows(bottomPinnedRows)
                    }
                </table>
            </>
        );
    }

}

PrintableTableGrid.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.node.isRequired,
        field: PropTypes.string,
        valueFormatter: PropTypes.func,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    })).isRequired,
    data: PropTypes.array.isRequired,
    bottomPinnedRows: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
    shouldIndex: PropTypes.bool
};
