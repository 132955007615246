import { getUserApiData } from "../../utils/UserUtils";
import { invokeApig } from "../../libs/awsLib";
import Paths from "../paths";
import { myID } from "../../libs/generateID";
import store from "../../store/store";
import { notify } from "reapop";
import i18n from "../../i18n";
import { createErrorNotification, createSuccessNotification } from "../../utils/ResponseUtils";

/**
 * @typedef HeatData
 * @type {object}
 * @property AnmID {string}         ID zwierzecia
 * @property EvTime {number}        czas wykonania zdarzeniia (możliwe, że przejdzie do tablicy animals)
 * @property Comment {string}       komentarz
 * @property GrID {string}          id grupy
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego upadku prosiat
 * @param data {[HeatData]}          Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createHeat(data, { FarmID, ClientID, LocalUserID } = {}) {
    const { _FarmID, _LocalUserID, _ClientID } = getUserApiData({ FarmID, ClientID, LocalUserID });
    return invokeApig({
        ...Paths.createHeat({ farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID }),
        body: data
    })
}

export function createHeatNotificationSuccess(response, { uniqueId = myID() } = {}) {
    if (createSuccessNotification(response, "apiNotifications.createHeatTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createHeatTitle"),
        message: i18n.t("apiNotifications.createHeatSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createHeatNotificationFailure(response, { uniqueId = myID() } = {}) {
    if (createErrorNotification(response, "apiNotifications.createHeatTitle", { uniqueId })) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createHeatTitle"),
        message: i18n.t("apiNotifications.createHeatFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
