export const SQL_KEYWORDS = [
    "ABSOLUTE",
    "ACTION",
    "ADD",
    "AGGR",
    "ALL",
    "ALTER",
    "AND",
    "ANTI",
    "ANY",
    "APPLY",
    "ARRAY",
    "AS",
    "ASSERT",
    "ASC",
    "ATTACH",
    "AUTOINCREMENT",
    "AUTO_INCREMENT",
    "AVG",
    "BEGIN",
    "BETWEEN",
    "BREAK",
    "BY",
    "CALL",
    "CASE",
    "CAST",
    "CHECK",
    "CLASS",
    "CLOSE",
    "COLLATE",
    "COLUMN",
    "COLUMNS",
    "COMMIT",
    "CONSTRAINT",
    "CONTENT",
    "CONTINUE",
    "CONVERT",
    "CORRESPONDING",
    "COUNT",
    "CREATE",
    "CROSS",
    "CUBE",
    "CURRENT_TIMESTAMP",
    "CURSOR",
    "DATABASE",
    "DECLARE",
    "DEFAULT",
    "DELETE",
    "DELETED",
    "DESC",
    "DETACH",
    "DISTINCT",
    "DOUBLEPRECISION",
    "DROP",
    "ECHO",
    "EDGE",
    "END",
    "ENUM",
    "ELSE",
    "EXCEPT",
    "EXISTS",
    "EXPLAIN",
    "FALSE",
    "FETCH",
    "FIRST",
    "FOREIGN",
    "FROM",
    "GO",
    "GRAPH",
    "GROUP",
    "GROUPING",
    "HAVING",
    "HELP",
    "IF",
    "IDENTITY",
    "IS",
    "IN",
    "INDEX",
    "INNER",
    "INSERT",
    "INSERTED",
    "INTERSECT",
    "INTO",
    "JOIN",
    "KEY",
    "LAST",
    "LET",
    "LEFT",
    "LIKE",
    "LIMIT",
    "LOOP",
    "MATCHED",
    "MATRIX",
    "MAX",
    "MERGE",
    "MIN",
    "MINUS",
    "MODIFY",
    "NATURAL",
    "NEXT",
    "NEW",
    "NOCASE",
    "NO",
    "NOT",
    "NULL",
    "OFF",
    "ON",
    "ONLY",
    "OFFSET",
    "OPEN",
    "OPTION",
    "OR",
    "ORDER",
    "OUTER",
    "OVER",
    "PATH",
    "PARTITION",
    "PERCENT",
    "PLAN",
    "PRIMARY",
    "PRINT",
    "PRIOR",
    "QUERY",
    "READ",
    "RECORDSET",
    "REDUCE",
    "REFERENCES",
    "RELATIVE",
    "REPLACE",
    "REMOVE",
    "RENAME",
    "REQUIRE",
    "RESTORE",
    "RETURN",
    "RETURNS",
    "RIGHT",
    "ROLLBACK",
    "ROLLUP",
    "ROW",
    "SCHEMA(S)?",
    "SEARCH",
    "SELECT",
    "SEMI",
    "SET",
    "SETS",
    "SHOW",
    "SOME",
    "SOURCE",
    "STRATEGY",
    "STORE",
    "SUM",
    "TABLE",
    "TABLES",
    "TARGET",
    "TEMP",
    "TEMPORARY",
    "TEXTSTRING",
    "THEN",
    "TIMEOUT",
    "TO",
    "TOP",
    "TRAN",
    "TRANSACTION",
    "TRIGGER",
    "TRUE",
    "TRUNCATE",
    "UNION",
    "UNIQUE",
    "UPDATE",
    "USE",
    "USING",
    "VALUE",
    "VERTEX",
    "VIEW",
    "WHEN",
    "WHERE",
    "WHILE",
    "WITH",
    "WORK"
]

export const SQL_COLUMNS = [
    "act_cycle",
    "AnmID",
    "AnmNo1",
    "cycle",
    "dataEv",
    "date_end",
    "date_introduction",
    "EvID",
    "group_nr",
    "key",
    "location",
    "user",
    "value"
]