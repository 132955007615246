import {debounce} from "lodash";

export const ScrollFixDuration = 500;

export const removeClass = debounce(() => {
    document.body.classList.remove("no-events")
}, ScrollFixDuration)

export const scrollHandler = () => {
    document.body.classList.add("no-events")
    removeClass();
}
