import {connectModal} from "redux-modal";
import {Modal} from "react-bootstrap";
import {Form, Formik, useField} from "formik";
import {useCallback, useMemo} from "react";
import ModalHeader from "../ModalHeader";
import {useTranslation} from "react-i18next";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import FormikLabeledInput from "../../basics/input/FormikLabeledInput";
import moment from "moment";
import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess
} from "../../../api/cycles/editSowCycle";
import {useSelector} from "react-redux";
import {getComments} from "../../../selectors/dictionarySelectors";
import LabeledSelect from "../../basics/select/labeled-select/LabeledSelect";
import {getAnimalEvents} from "../../../selectors/animalDocumentsSelectors";

export const ModalName = "edit-comment-modal";

function CommentsSelect() {

    const {t} = useTranslation();

    const comments = useSelector(getComments);

    const [field, meta, handlers] = useField("id");

    const options = useMemo(() => {
        return (comments?.WData || []).map(item => ({value: item.ID, name: item.Value}));
    }, [comments]);

    const onChange = useCallback((value) => {
        handlers.setValue(value);
    }, [handlers]);

    return (
        <LabeledSelect options={options} label={t("printSelectedAnimalsModal.comments")} value={field.value}
                       onChange={onChange} error={meta.error}/>
    );
}

function EditCommentModalForm({handleSubmit, handleHide, isSubmitting}) {

    const {t} = useTranslation();

    return (
        <Form className={ModalName}>
            <ModalHeader title={t("editComment")} onCloseClick={handleHide}/>
            <ModalBody>
                <FormikLabeledInput name="EvTime" label={t("date")} type="date" id="EvTime"/>
                <CommentsSelect/>
            </ModalBody>
            <ModalFooter
                hasConfirmButton
                confirmText={t("save")}
                onCloseClick={handleHide}
                onConfirmed={handleSubmit}
                submitting={isSubmitting}
            />
        </Form>
    )
}

function EditCommentModal({show, handleHide, comment}) {

    const animalEvents = useSelector(getAnimalEvents);

    const onSubmit = useCallback((values) => {
        const oldComment = animalEvents.find(({EvID}) => EvID === values.EvID);
        const newComment = {
            ...oldComment,
            EvTime: +moment(values.EvTime),
            EvData: {...oldComment.EvData, Comments: [values.id]}
        };
        return editSowCycle({AnmID: newComment.AnmID, eventsToUpdate: [newComment], eventsToDelete: []}).then((res) => {
            editSowCycleNotificationSuccess(res);
            handleHide();
        }).catch((err) => {
            editSowCycleNotificationFailure(err);
        });
    }, [handleHide, animalEvents]);

    return (
        <Modal onHide={handleHide} show={show} size={"lg"}>
            <Formik initialValues={{
                ...comment,
                EvTime: moment(comment?.date).format(moment.HTML5_FMT.DATE)
            }} onSubmit={onSubmit}>
                {
                    (props) => <EditCommentModalForm {...props} handleHide={handleHide}/>
                }
            </Formik>
        </Modal>
    )
}

export default connectModal({name: ModalName})(EditCommentModal);