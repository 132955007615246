class Scale {
    constructor(options) {
        this.options = options || [
            0.01,
            0.015,
            0.02,
            0.025,
            0.03,
            0.04,
            0.05,
            0.1,
            0.15,
            0.2,
            0.25,
            0.3,
            0.35,
            0.4,
            0.5,
            0.7,
            0.9,
            1,
            1.1,
            1.25,
            1.5,
            2,
            2.5,
            3,
            5,
            7.5,
            10,
            12.5,
            15,
            20,
            25,
            30,
            35,
            40,
            45,
            50
        ];
        this.currentScale = 0;
    }

    setCurrent(_current) {
        this.currentScale = _current;
        return this.fix();
    }

    get scale() {
        return this.currentScale;
    }

    getCurrent() {
        return this.fix().scale;
    }

    fix() {
        this.currentScale = Math.min(Math.max(this.currentScale, this.options[0]), this.options[this.options.length - 1]);
        return this;
    }

    prev() {
        for (let i = this.options.length - 1; i >= 0; i--) {
            const scale = this.options[i];
            if (scale < this.currentScale) {
                this.currentScale = scale;
                return this;
            }

        }
        this.currentScale = this.options[0];
        return this;
    }

    next() {
        for (let scale of this.options) {
            if (scale > this.currentScale) {
                this.currentScale = scale;
                return this;
            }
        }
        this.currentScale = this.options[this.options.length - 1];
        return this;
    }

    /**
     * tl;dr naprawia crashe apki na webkit
     * @return {Scale}
     */
    makeSafe() {
        const newOptions = []
        for (let number of this.options) {
            const fixed = Math.round((number + Number.EPSILON) * 100) / 100;
            if (fixed >= 0.1) {
                newOptions.push(fixed);
            }
        }
        this.options = newOptions;
        return this;
    }
}

export default Scale;