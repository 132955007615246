import React from "react";
import PropTypes from "prop-types";
import {Field} from "redux-form";
import _ from "lodash"
import {myID} from "../../../../libs/generateID";

export default class ReduxTableItem extends React.Component {

    constructor(props) {
        super(props);
        this.id = myID();
    }


    getDontShowInputValue() {
        const {header: {dontShowInput}, header, value, fields, index} = this.props;
        if (dontShowInput === undefined || typeof dontShowInput === "boolean") return dontShowInput;
        if (typeof dontShowInput === "function") {
            return dontShowInput({header, value, fields, index});
        }
    }

    getOptions() {
        const {header: {options}, header, value, fields, index} = this.props;
        if (!options || Array.isArray(options)) return options;
        if (typeof options === "function") return options({header, value, fields, index});
        return [];
    }

    getDisabled() {
        const {header: {disabled}, header, value, fields, index} = this.props;
        if (typeof disabled === "function") return disabled({header, value, fields, index});
        return disabled;
    }

    render() {
        const {header: {component, type, fieldToGet, unit, normalize, onChange, minField, valueFormatter, fieldProps, colWidth, parse, hasNotValue}, field, header, value, fields, index} = this.props;
        let minValue;
        if (minField) {
            let fieldValue = fields.get(index);
            if (fieldValue) {
                minValue = fieldValue[minField];
            }
        }
        let cName = "table-item";
        cName += colWidth ? ` col-width-${colWidth}` : "";
        return (
            <div className={cName}>
                {
                    !this.getDontShowInputValue() &&
                    <Field
                        disabled={this.getDisabled()}
                        {...(fieldProps || {})}
                        index={index}
                        name={`${field}.${header.field}`}
                        component={component}
                        type={type}
                        unit={unit}
                        showIconOnErrorOnWarning
                        normalize={normalize}
                        onChange={onChange}
                        min={minValue}
                        options={this.getOptions()}
                        id={this.id}
                        parse={parse}
                    />
                }
                {
                    this.getDontShowInputValue() &&
                    (valueFormatter ? valueFormatter(value) : !hasNotValue ? _.get(value, fieldToGet, typeof value === "object" ? "" : value) :
                        <i className={"fas fa-times error"}/>)
                }
            </div>
        );
    }

}

ReduxTableItem.propTypes = {
    header: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]),
};