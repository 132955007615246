import {
    getAnimalUnit,
    getAverageIdleDaysRangeValues,
    getAverageLactationDaysValues,
    getAverageParturitionWeightRangeValues,
    getAverageSeparationWeightRangeValues,
    getCalculateResultsAfterSowCycles,
    getDaysForRepeatedInsemination,
    getFirstCycleIndex,
    getFirstTechnologyGroupStart,
    getHealthyPigletsPerLitterRangeValues,
    getIdleDays,
    getManageSubgroups,
    getMaxDelayForBirth,
    getMaximumIntervalBetweenSeparations,
    getParturitionIndexRangeValues,
    getPigletMortality,
    getResultsEventStartDate,
    getSeparatedPigletsPerLitterRangeValues,
    getTechnologyGroupWeeks,
    getTimeFromInseminationToPartuition,
    getTimeFromInseminationToPregnancy,
    getTimeOnBirthRoom,
    getTimeOnBirthRoomMommy,
    getTimeOnMatingRoom,
    getTimeOnSowsRoom,
    getValidationSettings
} from "../SettingsUtils";
import { LicPackageKeys, LicPackageLevel } from "../../constans/roles";
import { ResultCategory } from "../../constans/resultsTypes";
import { ResultGridUnitTypes } from "../../constans/resultGridUnitTypes";
import { ResultSummaryTypes } from "../../constans/resultSummaryType";

/**
 * funkcja zwracająca obiekt potrzebny niektórym funkcjom na wynikach
 * @returns {{technologyGroupWeeks: *, maxDelayForBirth: *, technologyGroupStart: (*|undefined), timeFromInseminationToParturition: *, animalUnit: any}}
 */
export function getUtilResults() {
    return {
        AnimalUnit: getAnimalUnit(),
        MaxDelayForBirth: getMaxDelayForBirth(),
        TimeFromInseminationToParturition: getTimeFromInseminationToPartuition(),
        TimeFromInseminationToPregnancy: getTimeFromInseminationToPregnancy(),
        TechnologyGroupWeeks: getTechnologyGroupWeeks(),
        TechnologyGroupStart: getFirstTechnologyGroupStart(),
        FirstCycleIndex: getFirstCycleIndex(),
        TimeOnBirthRoom: getTimeOnBirthRoom(),
        TimeOnBirthRoomMommy: getTimeOnBirthRoomMommy(),
        TimeBetweenInseminations: getDaysForRepeatedInsemination(),
        TimeOnMating: getTimeOnMatingRoom(),
        TimeOnSows: getTimeOnSowsRoom(),
        MaximumIntervalBetweenSeparations: getMaximumIntervalBetweenSeparations(),
        ParturitionIndexRange: getParturitionIndexRangeValues(),
        HealthyPigletsPerLitterRange: getHealthyPigletsPerLitterRangeValues(),
        SeparatedPigletsPerLitterRange: getSeparatedPigletsPerLitterRangeValues(),
        AverageParturitionWeightRange: getAverageParturitionWeightRangeValues(),
        AverageSeparationWeightRange: getAverageSeparationWeightRangeValues(),
        AverageIdleDaysRange: getAverageIdleDaysRangeValues(),
        AverageLactationDaysRange: getAverageLactationDaysValues(),
        ManageSubgroups: getManageSubgroups(),
        PigletsMortalityRange: getPigletMortality(),
        ResultsEventStartDate: getResultsEventStartDate(),
        CalculateResultsAfterSowCycles: getCalculateResultsAfterSowCycles(),
        IdleDays: getIdleDays(),
        validations: getValidationSettings(),
    };
}

export function getResultsParameters(displayMode = "", all = false, parameterName = "") {
    let result = [];
    const isGroup = displayMode === "groups";
    const reproductionParameters = [
        { name: "planTypes.reproduction", isFullWidth: true, category: ResultCategory.REPRODUCTION, hideFor: ["groups"] },
        {
            name: "planTypes.inseminatedRenovationSows",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            hideFor: ["groups"]
        },
        { name: "planTypes.inseminatedSows", unit: ResultGridUnitTypes.PIECES, summaryType: ResultSummaryTypes.SUM, hideFor: ["groups"] },
        {
            name: "planTypes.inseminatedAllSows",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            mobile: true,
            hideFor: ["groups"]
        },
        { name: "planTypes.allUsgs", unit: ResultGridUnitTypes.PIECES, summaryType: ResultSummaryTypes.SUM, hideFor: ["groups"] },
        {
            name: "planTypes.parturitionSowsUntilFiveWeek",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.parturitionSowsUntilEightWeek",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.repetitionsForRenovationSows",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            hideFor: ["groups"]
            // hideFor: ["technologyGroups"]
        },
        {
            name: "planTypes.repetitionsForSows",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            hideFor: ["groups"]
            // hideFor: ["technologyGroups"]
        },
        {
            name: "planTypes.numberOfRepetitions",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            hideFor: ["groups"]
            // hideFor: ["technologyGroups"]
        },
        {
            name: "planTypes.avgBrakeFromInsToRep",
            unit: ResultGridUnitTypes.AVG_DAYS,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            hideFor: ["groups"]
            // hideFor: ["technologyGroups"]
        },
        {
            name: "planTypes.sepSowsThatHaveBeenPregnantWithinSevenDaysInFirstCycle",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.sepSowsThatHaveBeenPregnantAboveSevenDaysInFirstCycle",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.sepSowsThatHaveBeenPregnantWithinSevenDaysInSecondCycle",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.sepSowsThatHaveBeenPregnantAboveSevenDaysInSecondCycle",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.sepSowsThatHaveBeenPregnantWithinSevenDaysInThirdCycle",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.sepSowsThatHaveBeenPregnantAboveSevenDaysInThirdCycle",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.percentageOfSepSowsThatHaveBeenPregnantWithinSevenDaysInFirstCycle",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.percentageOfSepSowsThatHaveBeenPregnantAboveSevenDaysInFirstCycle",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.percentageOfSepSowsThatHaveBeenPregnantWithinSevenDaysInSecondCycle",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.percentageOfSepSowsThatHaveBeenPregnantAboveSevenDaysInSecondCycle",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.percentageOfSepSowsThatHaveBeenPregnantWithinSevenDaysInThirdCycle",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.percentageOfSepSowsThatHaveBeenPregnantAboveSevenDaysInThirdCycle",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.avgBrakeFromSepToIns",
            unit: ResultGridUnitTypes.AVG_DAYS,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.retention",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.averageLactationDays",
            unit: ResultGridUnitTypes.AVG_DAYS,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["days", "weeks", "technologyGroups", "groups"]
        },
    ];
    const parturitionParameters = [
        {
            name: "planTypes.parturitions", isFullWidth: true, category: ResultCategory.PARTURITION,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.expectedParturitions",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.NONE,
            hideFor: ["groups"]
        },
        { name: "planTypes.parturitionedSows", unit: ResultGridUnitTypes.PIECES, summaryType: ResultSummaryTypes.SUM, hideFor: ["groups"] },
        {
            name: "planTypes.parturitionDiffDays",
            unit: ResultGridUnitTypes.AVG_DAYS,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.generalResult",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["days", "weeks", "technologyGroups", "groups"]
        },
        {
            name: "planTypes.parturitionIndex",
            unit: ResultGridUnitTypes.AVG,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["days", "weeks", "technologyGroups", "groups"]
        },
        {
            name: "planTypes.parturitionEffectiveness",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["days", "weeks", "groups"],
            mobile: true
        },
        {
            name: "planTypes.percentageOfUsedStandings",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.inseminationEffectiveness",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            hideFor: ["days", "weeks", "groups"],
            mobile: true
        },
        {
            name: "planTypes.allBornPiglets", unit: ResultGridUnitTypes.PIECES, summaryType: ResultSummaryTypes.SUM,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.allBornPigletsPerSow", unit: ResultGridUnitTypes.PIECES, summaryType: ResultSummaryTypes.AVG,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.bornHealthyPiglets",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            mobile: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.bornHealthyPigletsPerSow",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            mobile: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.bornWeakPiglets",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.bornWeakPigletsPerSow",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.percentageOfWeakPigletsBornRelativeToHealthy",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.bornMummyPiglets",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.bornMummyPigletsPerSow",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.bornDeadPiglets",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.bornDeadPigletsPerSow",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.averageWeightOfPiglet",
            unit: ResultGridUnitTypes.WEIGHT,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.averageBornPigletsWeight",
            unit: ResultGridUnitTypes.WEIGHT,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["groups"]
        }
    ];
    const separationParameters = [
        { name: "separation", isFullWidth: true, category: ResultCategory.SEPARATION, hideFor: ["groups"] },
        { name: "planTypes.separatedSows", unit: ResultGridUnitTypes.PIECES, summaryType: ResultSummaryTypes.SUM, hideFor: ["groups"] },
        {
            name: "planTypes.amountOfSeparatedPiglets",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.amountOfSeparatedPigletsPerSow",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.weightOfPitcherWeight",
            unit: ResultGridUnitTypes.WEIGHT,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.litterWeight", unit: ResultGridUnitTypes.WEIGHT, summaryType: ResultSummaryTypes.SUM,
            hideFor: ["groups"]
        },
        {
            name: 'planTypes.averageWeightOfSeparatedPiglet',
            unit: ResultGridUnitTypes.WEIGHT,
            summaryType: ResultSummaryTypes.AVG,
            isFloat: true,
            hideFor: ["groups"]
        },
        {
            name: "planTypes.pigletsMortality",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            hideFor: ["groups"],
            mobile: true
        }
    ];
    const additionalParameters = [
        {
            name: "additional",
            isFullWidth: true,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC]
            // hideFor: ["groups"]
        }, {
            name: "planTypes.amountOfSeparatedPigletsToBabyroom",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC]
            // hideFor: ["groups"]
        }];
    const generalParameters = [
        ...reproductionParameters.map((item) => ({ ...item, category: ResultCategory.REPRODUCTION })),
        ...parturitionParameters.map((item) => ({ ...item, category: ResultCategory.PARTURITION })),
        ...separationParameters.map((item) => ({ ...item, category: ResultCategory.SEPARATION })),

    ].map((item) => ({ ...item, packageKeys: [LicPackageKeys.SOW], packageLevels: [LicPackageLevel.BASIC] }));
    const inventoryParameters = [
        {
            name: "inventory",
            isFullWidth: true,
            category: ResultCategory.INVENTORY,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups"]
        },
        {
            name: "planTypes.insertedRenovationSows",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.renovationSowsAmount",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.renovationSowsUntil26Week",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.renovationSowsAbove26Week",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.numberOfRenovationSowsSold",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            mobile: true,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.numberOfFallenRenovationSows",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.insertedSows",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.amountSows",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.parturitionedSowsAmount",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.numberOfSowsSold",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            mobile: true,
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.numberOfFallenSows",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.pigletsAmount",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.numberOfFallenPigs",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["groups"]
        },
        {
            name: "planTypes.insertedWeaners",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups"]
        },
        {
            name: "planTypes.numberOfPiglets",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC, LicPackageLevel.BASIC],
            hideFor: ["technologyGroups"],
            disableReportInGroups: true
        },
        {
            name: "planTypes.numberOfFallenPiglets",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC, LicPackageLevel.BASIC],
            mobile: true,
            hideFor: ["technologyGroups"]
        },
        {
            name: "planTypes.reclassifiedPiglets",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC, LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.weanerMortality",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC, LicPackageLevel.BASIC],
            hideFor: ["technologyGroups"],
            mobile: true,
            disableReportInGroups: true
        },
        {
            name: "planTypes.numberOfPigletsSold",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC, LicPackageLevel.BASIC],
            mobile: true,
            hideFor: ["technologyGroups"]
        },
        {
            name: "planTypes.numberOfBoars",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.AVG,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.numberOfFallenBoars",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.numberOfBoarsSold",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.SOW],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups", "groups"]
        },
        {
            name: "planTypes.insertedPorkers",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups"],
        },
        {
            name: "planTypes.numberOfPorkers",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups"],
            disableReportInGroups: true,
        },
        {
            name: "planTypes.numberOfFallenPorkers",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            revertClass: true,
            packageKeys: [LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC],
            mobile: true,
            hideFor: ["technologyGroups"]
        },
        {
            name: "planTypes.porkerMortality",
            unit: ResultGridUnitTypes.PERCENT,
            summaryType: ResultSummaryTypes.AVG,
            revertClass: true,
            isFloat: true,
            packageKeys: [LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC],
            hideFor: ["technologyGroups"],
            mobile: true,
            disableReportInGroups: true
        },
        {
            name: "planTypes.numberOfPorkersSold",
            unit: ResultGridUnitTypes.PIECES,
            summaryType: ResultSummaryTypes.SUM,
            packageKeys: [LicPackageKeys.PORKER],
            packageLevels: [LicPackageLevel.BASIC],
            mobile: true,
            hideFor: ["technologyGroups"]
        }
    ].map((item) => ({ ...item, category: ResultCategory.INVENTORY }));

    result.push(...generalParameters, ...inventoryParameters,);
    if (isGroup) {
        result.push(
            {
                name: "planTypes.fatteningPeriod",
                unit: ResultGridUnitTypes.DAYS,
                summaryType: ResultSummaryTypes.AVG,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC]
            },
            {
                name: "planTypes.averageGain",
                unit: ResultGridUnitTypes.WEIGHT,
                summaryType: ResultSummaryTypes.AVG,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC]
            },
            {
                name: "planTypes.averageInsertionWeight",
                unit: ResultGridUnitTypes.WEIGHT,
                summaryType: ResultSummaryTypes.AVG,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC],
                disableReportInGroups: true
            },
            {
                name: "planTypes.averageSellWeight",
                unit: ResultGridUnitTypes.WEIGHT,
                summaryType: ResultSummaryTypes.AVG,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC],
                disableReportInGroups: true
            },
            {
                name: "planTypes.sellWeight",
                unit: ResultGridUnitTypes.BIG_WEIGHT,
                summaryType: ResultSummaryTypes.SUM,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC]
            },
            {
                name: "planTypes.medicineCost",
                unit: ResultGridUnitTypes.CURRENCY,
                summaryType: ResultSummaryTypes.SUM,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC]
            },
            {
                name: "planTypes.fixedCost",
                unit: ResultGridUnitTypes.CURRENCY,
                summaryType: ResultSummaryTypes.SUM,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC],
                disableReportInGroups: true
            },
            {
                name: "planTypes.buyCost",
                unit: ResultGridUnitTypes.CURRENCY,
                summaryType: ResultSummaryTypes.SUM,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC],
                disableReportInGroups: true
            },
            {
                name: "planTypes.averagePigletInsertionWeight",
                unit: ResultGridUnitTypes.WEIGHT,
                summaryType: ResultSummaryTypes.SUM,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC],
                disableReportInGroups: true
            },
            {
                name: "planTypes.pigletRearingPeriod",
                unit: ResultGridUnitTypes.DAYS,
                summaryType: ResultSummaryTypes.AVG,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC],
                disableReportInGroups: true
            },
            {
                name: "planTypes.averagePigletSellWeight",
                unit: ResultGridUnitTypes.WEIGHT,
                summaryType: ResultSummaryTypes.AVG,
                packageKeys: [LicPackageKeys.SOW, LicPackageKeys.PORKER],
                packageLevels: [LicPackageLevel.BASIC],
                disableReportInGroups: true
            }
        );
    }
    else {
        result.push(...additionalParameters.map((item) => ({ ...item, category: ResultCategory.SEPARATION })));
    }

    if (all) return result;
    return result.filter((o) => (parameterName ? o.name === `planTypes.${parameterName}` : true) && (!o.hasOwnProperty("hideFor") || !o.hideFor.includes(displayMode)));
}
