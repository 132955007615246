import lokiDB from "./lokiDB";
import { getModificationTime, insertInto, setModificationTime } from "../utils/LokiUtils";

class SalesDB {

    getModificationTime() {
        return getModificationTime("sales");
    }

    insertSales(values) {
        insertInto(values, lokiDB.sales, "SaleID");
        setModificationTime("sales", values[0].DtaModTime);
    }

    getSales(FarmID) {
        return lokiDB.sales.find({
            FarmID,
            DtaDelTime: { $type: 'undefined' }
        });
    }

    getSaleByID(SaleID) {
        return lokiDB.sales.findOne({
            SaleID,
            DtaDelTime: { $type: 'undefined' }
        });
    }

    getAllSales(FarmID) {
        return lokiDB.sales.find({
            FarmID
        });
    }
}

const salesDB = new SalesDB();
export default salesDB;
