import {uniqBy, pick} from "lodash";

const initialValue = {
    notifications: [],
    fetched: false,
    fetching: false,
    lastEvaluatedKey: null,
    readTime: 0,
    newestTime: 0,
    filter: "",
    show: {error: true, warning: true, info: true},
    fetchingOrder: "older"
};

export default function notificationReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_NOTIFICATION_FILTER": {
            return {
                ...state,
                filter: action.payload
            }
        }
        case "SET_NOTIFICATION_SHOW": {
            return {
                ...state,
                show: {
                    ...state.show,
                    [action.payload.key]: action.payload.value
                }
            }
        }
        case "GET_LATEST_NOTIFICATIONS_FULFILLED": {
            const newestItem = action.payload?.items[0];
            const readTime = action.meta.readTime;
            return {
                ...state,
                newestTime: Math.max(newestItem?.DtaModTime ?? state.newestTime, state.newestTime),
                readTime
            }
        }
        case "LIST_NOTIFICATIONS_PENDING": {
            return {
                ...state,
                fetching: true,
                fetchingOrder: action.meta?.ListNewer ? "newer" : "older"
            }
        }

        case "SET_NOTIFICATIONS_READ_TIME": {
            return {
                ...state,
                readTime: action.payload
            }
        }
        case "LIST_NOTIFICATIONS_FULFILLED": {
            const {items, LastEvaluatedKey} = action.payload;
            const {ListNewer} = action.meta ?? {};
            const mergedList = uniqBy((ListNewer && !items.some(({NID}) => state.notifications.some((n) => NID === n.NID))) ? items : [...items, ...state.notifications], (o) => o.NID);
            mergedList.sort((o1, o2) => o2.DtaCrtTime - o1.DtaCrtTime);
            return {
                ...state,
                lastEvaluatedKey: ListNewer ? state.lastEvaluatedKey : LastEvaluatedKey,
                notifications: mergedList,
                fetched: true,
                fetching: false,
                error: false,
                newestTime: Math.max(mergedList[0]?.DtaModTime ?? state.newestTime, state.newestTime)
            }
        }
        case "LIST_NOTIFICATIONS_REJECTED": {
            return {
                ...state,
                fetched: false,
                fetching: false,
                error: true
            }
        }
        case "CLEAR_NOTIFICATIONS": {
            return {
                ...state,
                ...pick(initialValue, ["notifications", "fetched", "fetching", "lastEvaluatedKey", "filter", "show"])
            }
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
