import React, { Component } from "react";
import { connect } from "react-redux";
import ViewContainerModal from "../basics/view-containter-modal/ViewContainerModal";
import { isMobile } from "../../utils/MobileUtils";
import DocumentBody from "./DocumentBody";
import EditAnimalBasicsModal from "../modals-new/edit-animal-basics/EditAnimalBasicsModal";
import ButtonGroup from "../basics/button/button-group/ButtonGroup";
import Button from "../basics/button/Button";
import { getAllDictionaries } from "../../actions/dictionaryActions";
import { AnimalTypes } from "../../constans/animalTypes";
import { withTranslation } from "react-i18next";
import { get, startCase, toLower, isEqual } from "lodash";
import { compose } from "redux";
import { resetSelectedItem } from "../../actions/animalDocumentsAction";
import { handleAnimalInventoryRowClick } from "../../utils/URLUtils";
import { withRouter } from "react-router-dom";
import EditGraftingModal from "../modals-new/edit-grafting-modal/EditGraftingModal";
import EditTreatmentModal from "../modals-new/edit-treatment-modal/EditTreatmentModal";
import EditGroupBasicsModal from "../modals-new/edit-group-basics-modal/EditGroupBasicsModal";
import EditSubgroupDataModal from "../modals-new/edit-subgroup-data-modal/EditSubgroupDataModal";
import { getManageSubgroups } from "../../utils/SettingsUtils";
import "./_document.scss";
import { makeGetInventorySorted } from "../../selectors/inventorySelector";
import EditInsertionsModal from "../modals-new/edit-insertions-modal/EditInsertionsModal";
import GroupCardRemoveEventModal from "../modals-new/group-card-remove-event-modal/GroupCardRemoveEventModal";
import { getAnimalEvents } from "../../selectors/animalDocumentsSelectors";
import PrintGeneratorAnimalModal from "../modals-new/print-generator-animal/PrintGeneratorAnimalModal";

function makeMapStateToProps() {
    const getInventorySorted = makeGetInventorySorted();
    return state => ({
        selectedItem: state.animalDocuments.selectedItem,
        events: getAnimalEvents(state),
        loadingDocuments: state.animalDocuments.fetchingEvent,
        data: getInventorySorted(state),
    });
}

class Document extends Component {
    constructor(props) {
        super(props);
        const { dispatch } = this.props;
        dispatch(getAllDictionaries());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedItem } = this.props;
        if (!isEqual(prevProps.selectedItem, selectedItem)) {
            const manageSubgroups = getManageSubgroups();
            if (!manageSubgroups) {
                const currentSelectedAnimalKind = get(
                    selectedItem,
                    "AnimalKind",
                    null
                );
                if (
                    [AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(
                        currentSelectedAnimalKind
                    ) &&
                    !(selectedItem.RFID || selectedItem.Tagged)
                ) {
                    this.handleHide();
                }
            }
        }
    }

    handleHide = () => {
        const { dispatch, location: { state: { goBack = false } = {} } = {} } =
            this.props;
        if (!this.hasInventoryPath()) {
            dispatch(resetSelectedItem());
        }
        handleAnimalInventoryRowClick(null, {}, {}, goBack);
    };

    getItemsMap = () => {
        const { selectedItem, data } = this.props;
        const ids = data.inventory.map((item) => ({
            id: item.id,
            isGroup: !!item.isGroup,
            isTechnoGroup: !!item.isTechnoGroup,
        }));
        return {
            ids,
            currentIndex: ids.findIndex(
                (o) =>
                    selectedItem &&
                    [
                        selectedItem.AnmID,
                        selectedItem.AnmGrp,
                        selectedItem.TGID,
                    ].includes(o.id)
            ),
        };
    };

    handlePrev = (updatePig = false) => {
        const { ids, currentIndex } = this.getItemsMap();
        if (~currentIndex && currentIndex - 1 >= 0 && !!ids[currentIndex - 1]) {
            if (updatePig) {
                handleAnimalInventoryRowClick(ids[currentIndex - 1]);
            }
            return true;
        }
        return false;
    };

    handleNext = (updatePig = false) => {
        const { ids, currentIndex } = this.getItemsMap();
        if (
            ~currentIndex &&
            currentIndex + 1 <= ids.length &&
            !!ids[currentIndex + 1]
        ) {
            if (updatePig) {
                handleAnimalInventoryRowClick(ids[currentIndex + 1]);
            }
            return true;
        }
        return false;
    };

    getName = () => {
        const { selectedItem, t } = this.props;
        if (selectedItem) {
            switch (selectedItem.AnimalKind) {
                case AnimalTypes.SOW:
                    return (
                        t("animalDocuments._sow") + " " + selectedItem.AnmNo1
                    );
                case AnimalTypes.BOAR:
                    return t("boar") + " " + selectedItem.AnmNo1;
                case AnimalTypes.PIGLET:
                    return (
                        t("animalDocuments._piglet") + " " + selectedItem.AnmNo1
                    );
                case AnimalTypes.RENOVATION_SOW:
                    return (
                        t("animalDocuments.renovation_sow") +
                        " " +
                        selectedItem.AnmNo1
                    );
                case AnimalTypes.PORKER:
                    return (
                        t("animalDocuments._porker") + " " + selectedItem.AnmNo1
                    );
                default:
                    return `${startCase(toLower(t("group")))} ${selectedItem.GrNo1
                        }`;
            }
        } else {
            return "-";
        }
    };

    hasInventoryPath = () => {
        const {
            location: { pathname },
        } = this.props;
        return pathname.includes("inventory");
    };

    render() {
        const {
            selectedItem,
            events,
            loadingDocuments,
            location: { state: { goBack = false } = {} } = {},
        } = this.props;
        const mobile = isMobile();
        const loading = loadingDocuments;
        const canUseNext = this.handleNext(false);
        const canUsePrev = this.handlePrev(false);
        const showButtons = this.hasInventoryPath();
        return (
            <>
                <ViewContainerModal
                    onHide={this.handleHide}
                    loading={!!loading || !selectedItem}
                    name={this.getName()}
                    show={!!selectedItem}
                    addPaddingForButtons
                    addHeight
                    justBack={goBack}>
                    <DocumentBody
                        selectedItem={selectedItem}
                        events={events}
                        mobile={mobile}
                    />
                </ViewContainerModal>
                <PrintGeneratorAnimalModal />
                <EditAnimalBasicsModal />
                <EditGroupBasicsModal />
                <EditInsertionsModal />
                <EditGraftingModal />
                <EditTreatmentModal />
                <EditSubgroupDataModal />
                <GroupCardRemoveEventModal />
                {showButtons && (
                    <ButtonGroup
                        show={
                            !!selectedItem &&
                            !!this.handleNext &&
                            !!this.handlePrev
                        }
                        fixed
                        renderInPortal={true}
                        className="document-button-group">
                        <Button
                            type={"button"}
                            onClick={() => this.handlePrev(true)}
                            buttonColor={"info"}
                            buttonStyle={"round"}
                            icon={<i className="fas fa-arrow-left" />}
                            disabled={loading || !canUsePrev}
                        />
                        <Button
                            type={"button"}
                            onClick={() => this.handleNext(true)}
                            buttonColor={"info"}
                            buttonStyle={"round"}
                            icon={<i className="fas fa-arrow-right" />}
                            disabled={loading || !canUseNext}
                        />
                    </ButtonGroup>
                )}
            </>
        );
    }
}

export default compose(
    withRouter,
    withTranslation(),
    connect(makeMapStateToProps)
)(Document);
