import {useEffect, useMemo} from "react";
import {debounce} from "lodash";

export default function useDebounce(callback, delay = 500) {
    const debouncedCallback = useMemo(() => {
        console.log("[useDebounce] Creating debounced callback");
        return debounce(callback, delay)
    }, [callback, delay]);

    useEffect(() => {
        return () => {
            console.log("[useDebounce] Canceling debounce");
            debouncedCallback.cancel();
        }
    }, [debouncedCallback]);

    return debouncedCallback;
}
