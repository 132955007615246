import React, {Component} from 'react';
import Button from "../../../basics/button/Button";
import {Col} from "react-bootstrap";

class ControlButton extends Component {

    handleSubmit = () => {
        const {submit, setLoading, setIdle, id, isLoading} = this.props;
        if (!isLoading) {
            setLoading(id);
            submit().then(setIdle);
        }
    }

    render() {
        const {label, icon, isLoading, id, loadingKey, mobile} = this.props;
        return (
            <Col xs={6} sm={6} md={4} lg={4} xl={3}>
                <Button
                    isLoading={(id === loadingKey) && isLoading}
                    icon={icon}
                    text={label}
                    className={"w-100 m-0"}
                    type={"button"}
                    buttonStyle={mobile ? "bordered" : ""}
                    buttonColor={"menu-color"}
                    onClick={this.handleSubmit}
                />
            </Col>
        );
    }
}

export default ControlButton;