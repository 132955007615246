import "./_message-box.scss";

const MessageBox = ({message, direction = "right"}) => {
    return (
        <div className="msgbox">
            <div className={`msgbox__arrow msgbox__arrow--${direction}`}/>
            <div className="msgbox__body">
                <p className={"msgbox__paragraph"}>{message}</p>
            </div>
        </div>
    )
}

export default MessageBox;