import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Tooltip from "../../../../components/basics/tooltip/Tooltip";

class RepeatedInseminationControlListDiffDays extends Component {
    render() {
        const {value, object: {errorDiff} = {}, t} = this.props;
        return (
            <div>
                {
                    errorDiff &&
                    <Tooltip tooltipContent={t("cantCalculateNoData")} type={"error"}>
                        <i className="fas fa-exclamation-circle error"/>
                    </Tooltip>
                }
                {!errorDiff && value}
            </div>
        );
    }
}

export default compose(
    withTranslation()
)(RepeatedInseminationControlListDiffDays);
