import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import i18n from "../../i18n";
import {createErrorNotification, createSuccessNotification} from "../../utils/ResponseUtils";

export function setFeedingEfficiency({
                                         DevIDs = [],
                                         SiloIDs = [],
                                         PlcmntIDs = [],
                                         Efficiency = 1000,
                                         AllDevices = false
                                     }, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.setFeedingEfficiency({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: {
            DevIDs,
            SiloIDs,
            PlcmntIDs,
            Efficiency,
            AllDevices
        }
    })
}

export function setFeedingEfficiencyNotificationSuccess(response, {uniqueId = myID()} = {}) {
    if (createSuccessNotification(response, "IOT.setFeedingEfficiencyTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("IOT.setFeedingEfficiencyTitle"),
        message: i18n.t("success"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function setFeedingEfficiencyNotificationFailure(response, {uniqueId = myID()} = {}) {
    if (createErrorNotification(response, "IOT.setFeedingEfficiencyTitle", {uniqueId})) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("IOT.setFeedingEfficiencyTitle"),
        message: i18n.t("IOT.setFeedingEfficiencyFailureGeneral"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}
