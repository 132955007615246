import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {getFilesForShower, getFileToShow, isShowFromLastFiles} from "../../../selectors/filesSelectors";
import {Fade} from "react-bootstrap";
import "./_file-show.scss";
import {findIndex, isEqual} from "lodash";
import {bodyLockScroll, bodyUnlockScroll, ScrollLock} from "../../../utils/DOMUtils";
import FileImageShow from "./file-showers/FileImageShow";
import {getFile} from "../../../api/files/getFile";
import LoadingComponent from "../../loading/LoadingComponent";
import {showFile} from "../../../actions/filesActions";
import FileVideoShow from "./file-showers/FileVideoShow";
import Button from "../../basics/button/Button";
import FilePdfShow from "./file-showers/FilePDFShow";
import Printable from "../../basics/printable/Printable";
import ButtonGroup from '../../basics/button/button-group/ButtonGroup';

function mapStateToProps(state) {
    return {
        file: getFileToShow(state),
        files: getFilesForShower(state),
        openedFromLastFiles: isShowFromLastFiles(state)
    };
}

class FileShow extends Component {

    state = {
        loading: false,
        url: null,
        error: false,
        printing: false,
        zoom: 1.0
    }
    addedListener = false;

    async getFileURL() {
        const {file: {url}} = this.props;
        this.setState({
            loading: true
        });
        let result = await url();
        this.setState({
            url: result
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(this.props.file, prevProps.file)) {
            if (this.props.file) {
                bodyLockScroll(ScrollLock.FILES);
                if (this.props.file?.PreviewMode) {
                    if (typeof this.props.file.url === "function") {
                        this.getFileURL();
                    } else {
                        this.setState({
                            url: this.props.file.url
                        })
                    }
                } else {
                    this.getFile();
                }
                // this.props.file?.PreviewMode ? this.setState({url: typeof this.props.file.url === "function" ? this.getFileURL() : this.props.file.url}) : ;
                if (!this.addedListener) {
                    window.addEventListener("keyup", this.onKeyUp);
                    this.addedListener = true;
                }
            } else {
                bodyUnlockScroll(ScrollLock.FILES);
                window.removeEventListener("keyup", this.onKeyUp);
                this.addedListener = false;
            }
            if (!this.props.file?.PreviewMode) {
                this.setState({
                    url: null
                })
            }
        }
    }

    onKeyUp = e => {
        switch (e.keyCode) {
            case 27:
                return this.closeShower(); // escape
            case 37:
                return this.onClickPrevious(); // strzalka w lewo
            case 39:
                return this.onClickNext(); // strzalka w prawo
            default:
                return;
        }
    }

    getFile() {
        const {file} = this.props;
        this.setState({
            loading: true,
            zoom:1.0
        });
        getFile(file.FileID, {FarmID: file.FarmID}).then(res => {
            this.setState({
                url: res
            })
        }).catch(e => {
            this.setState({
                loading: false,
                error: true
            })
        })
    }

    getComponent() {
        const {file} = this.props;
        if (!file) return "div";
        const {Type} = file;
        if (Type.includes("image")) return FileImageShow;
        if (Type.includes("video")) return FileVideoShow;
        if (Type.includes("pdf")) return FilePdfShow;
        return "div";
    }

    closeShower = () => {
        this.props.dispatch(showFile(null));
        this.setState({
            url: null

        })
    }

    onLoadFile = () => {
        this.setState({
            loading: false,
            zoom:1.0,
        })
    }

    findFileByIndex(index, reversed) {
        const {openedFromLastFiles} = this.props;
        let files = this.props.files.slice(0);
        if (openedFromLastFiles) {
            // filtrowanie do poziomu aktualnie wyswietlonych plikow
            let container = document.getElementById("top-list-files");
            let items = container.querySelectorAll(`.file-item`);
            files = files.filter(item => {
                let el = [...items].find(element => element.id === item.FileID);
                return el.style.display !== "none";
            })
        }
        if (reversed) {
            files.reverse();
            index = files.length - index + 1;
        }
        files = files.slice(index);
        return files[0];
    }

    findFile(index, reversed = false) {
        const {files} = this.props;
        let file = this.findFileByIndex(index, reversed);
        if (file) return file;
        return this.findFileByIndex(reversed ? files.length + 1 : 0, reversed);
    }

    onClickPrevious = () => {
        const {files, file, openedFromLastFiles} = this.props;
        if (file.onClickPrevious) {
            file.onClickPrevious();
            return;
        }
        let index = findIndex(files, o => o.FileID === file.FileID) + 1;
        let foundFile = this.findFile(index, true);
        this.props.dispatch(showFile(foundFile || null, openedFromLastFiles));
    }

    onClickNext = () => {
        const {files, file, openedFromLastFiles} = this.props;
        if (file.onClickNext) {
            file.onClickNext();
            return;
        }
        let index = findIndex(files, o => o.FileID === file.FileID) + 1;
        let foundFile = this.findFile(index);
        this.props.dispatch(showFile(foundFile || null, openedFromLastFiles));
    }

    canShowButtons() {
        const {file} = this.props;
        if (!file) return false;
        if (file.MultiMode) return true;
        if (file.IssueID) return false;
        return !file.PreviewMode;
    }
    canShowZoomButtons() {
        const {file} = this.props;
        if (!file) return false;
        const {Type} = file;
        const zoomTypes = ['pdf','image/jpeg','image/png','image/jpg','image/webp']
        return zoomTypes.includes(Type);
    }
    isPDF() {
        const {file} = this.props;
        if (!file) return false;
        const {Type} = file;
        return Type.includes("pdf");
    }

    onPrintClick = () => {
        if (this.isPDF()) {
            window.open(this.state.url);
            return;
        }
        this.setState({
            printing: true
        })
    }

    onPrintEnd = () => {
        this.setState({
            printing: false
        })
    }

    onDownloadClick = async () => {
        // TODO obsługa aktualnie tylko na sprzedaży
        const {file: {FileName, url, Extension}} = this.props;
        let downloadURL = await url(`${FileName}.${Extension}`);
        let link = document.createElement("a");
        link.setAttribute("download", FileName);
        link.setAttribute("href", downloadURL);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
    decreaseZoom = () => {
        const {zoom} = this.state;
        this.setState({
            zoom: zoom - 0.3
        })
    }
    increaseZoom = () => {
        const {zoom} = this.state;
        this.setState({
            zoom: zoom + 0.3
        })
    }
    render() {
        const {file} = this.props;
        const {url, loading, printing, zoom} = this.state;
        return (
            <Fade in={!!file} mountOnEnter unmountOnExit>
                <div className="file-show">
                    <div className="file-show-backdrop" onClick={this.closeShower} data-testid={"file-show-backdrop"} />
                    <h3 className="file-show-title">
                        {file?.FileName}&nbsp;
                        {file?.showControls && (
                            <div className="file-show-controls">
                                <Button buttonStyle={"text"} onClick={this.onPrintClick}>
                                    <i className="fas fa-print" />
                                </Button>
                                <Button buttonStyle={"text"} onClick={this.onDownloadClick}>
                                    <i className="fas fa-download" />
                                </Button>
                            </div>
                        )}
                        <Button buttonStyle={"text"} className="file-show-close" onClick={this.closeShower}>
                            <i className="fas fa-times" />
                        </Button>
                    </h3>
                    <div className={`file-show-container${this.isPDF() ? " pdf" : ""}`}>
                        <Printable onPrintEnd={this.onPrintEnd} printing={printing} onlyChildren>
                            {React.createElement(this.getComponent(), {
                                file,
                                url,
                                onLoadFile: this.onLoadFile,
                                zoom: zoom,
                            })}
                        </Printable>
                    </div>
                    <LoadingComponent isLoading={loading} bgInherit />
                    {this.canShowButtons() && (
                        <>
                            <Button
                                buttonStyle={"text"}
                                className="file-show-button previous"
                                onClick={this.onClickPrevious}>
                                <i className="fas fa-chevron-left" />
                            </Button>
                            <Button buttonStyle={"text"} className="file-show-button next" onClick={this.onClickNext}>
                                <i className="fas fa-chevron-right" />
                            </Button>
                        </>
                    )}
                    {this.canShowZoomButtons() && (
                        <ButtonGroup renderInPortal={false} fixed>
                            <Button
                                buttonColor={"info"}
                                icon={<i class="fa-solid fa-magnifying-glass-minus" />}
                                buttonStyle={"round"}
                                onClick={this.decreaseZoom}
                                disabled={zoom <= 1.0}
                            />
                            <Button
                                buttonColor={"info"}
                                icon={<i class="fa-solid fa-magnifying-glass-plus" />}
                                buttonStyle={"round"}
                                onClick={this.increaseZoom}
                            />
                        </ButtonGroup>
                    )}
                </div>
            </Fade>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(FileShow);
