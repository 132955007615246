import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_video-player.scss";
import Button from "../button/Button";
import {connect} from "react-redux";
import {setVideoFoPiP} from "../../../actions/pipActions";
import VideoPlayerProgress from "./VideoPlayerProgress";
import {getVideoForPiP} from "../../../selectors/pipSelector";
import {isMobile} from "../../../utils/MobileUtils";
import {isEqual} from "lodash";
import LoadingComponent from "../../loading/LoadingComponent";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        video: getVideoForPiP(state),
        locale: state.language.locale
    }
}

class VideoPlayer extends Component {

    state = {
        playing: this.props.autoPlay,
        replay: false,
        inFullscreen: false,
        inPiP: false
    }
    video = React.createRef();
    container = React.createRef();
    duration = React.createRef();

    componentDidMount() {
        this.video.current.addEventListener("play", this.onVideoPlay);
        this.video.current.addEventListener("pause", this.onVideoPause);
        this.video.current.addEventListener("ended", this.onVideoEnd);
        this.video.current.addEventListener("timeupdate", this.onTimeUpdate);
        this.video.current.addEventListener("durationchange", this.onVideoDurationChanged);
        document.addEventListener("fullscreenchange", this.onFullScreenChange);
        this.setDuration();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.video === null && prevProps.video !== null) {
            this.setState({
                inPiP: false
            })
        }
        if (!isEqual(this.props.sources, prevProps.sources)) {
            this.video.current?.load();
            this.setState({
                replay: false,
                playing: false
            })
        }
        // if (!isEqual(this.props.subtitles, prevProps.subtitles)) {
        //     let tracks = this.video.current?.querySelectorAll("track");
        //     console.log(tracks);
        //     for (let track of tracks) {
        //         this.video.current.removeChild(track);
        //     }
        // }
    }

    componentWillUnmount() {
        this.video.current.removeEventListener("play", this.onVideoPlay);
        this.video.current.removeEventListener("pause", this.onVideoPause);
        this.video.current.removeEventListener("ended", this.onVideoEnd);
        this.video.current.removeEventListener("timeupdate", this.onTimeUpdate);
        this.video.current.removeEventListener("durationchange", this.onVideoDurationChanged);
        document.removeEventListener("fullscreenchange", this.onFullScreenChange);
    }

    onVideoPlay = () => {
        const {onVideoPlay} = this.props;
        this.setState({
            playing: true,
            replay: false
        })
        onVideoPlay && onVideoPlay();
    }

    onVideoPause = () => {
        const {onVideoPause} = this.props;
        this.setState({
            playing: false
        })
        onVideoPause && onVideoPause();
    }

    onVideoEnd = () => {
        this.setState({
            replay: true
        });
        this.getProgressBar().style.width = "100%";
    }

    onVideoDurationChanged = () => {
        this.setDuration();
        let progress = this.container.current.querySelector(".progress-bar");
        progress.style.width = "0";
    }

    getProgressBar() {
        return this.container.current.querySelector(".progress-bar");
    }

    onTimeUpdate = () => {
        let progress = Math.floor((100 / this.video.current.duration) * this.video.current.currentTime);
        this.getProgressBar().style.width = progress + "%";
        this.setDuration();
    }

    onFullScreenChange = () => {
        this.setState({
            inFullscreen: !!document.fullscreenElement
        })
    }

    onPlayClick = () => {
        this.video.current.play();
    }

    onPauseClick = () => {
        this.video.current.pause();
    }

    onFullscreenClick = async () => {
        try {
            await this.container.current.requestFullscreen();
        } catch (e) {
            console.error(e);
        }
    }

    onCompressClick = async () => {
        try {
            await document.exitFullscreen();
        } catch (e) {
            console.error(e);
        }
    }

    getPlayIcon() {
        const {playing, replay} = this.state;
        if (replay) return <i className="fas fa-redo"/>
        if (playing) return <i className="fas fa-pause"/>
        return <i className="fas fa-play"/>
    }

    getClassName() {
        const {inFullscreen} = this.state;
        let className = "fetura-video-player";
        if (inFullscreen) className += " fullscreen";
        return className;
    }

    getFullscreenIcon() {
        const {inFullscreen} = this.state;
        if (inFullscreen) return <i className="fas fa-compress"/>;
        return <i className="fas fa-expand"/>;
    }

    onPiPClick = () => {
        const {sources, subtitles, onVideoPause, onVideoPlay} = this.props;
        const {playing} = this.state;
        this.onPauseClick();
        this.props.dispatch(setVideoFoPiP({
            sources,
            playOnStart: playing,
            videoElement: this.video.current,
            subtitles,
            onVideoPause,
            onVideoPlay
        }));
        this.setState({
            inPiP: true
        })
    }

    setVideoTime(time) {
        this.video.current.currentTime = time;
    }

    onProgressClick = e => {
        let offsetX = e.nativeEvent.offsetX;
        let offsetWidth = e.target.offsetWidth;
        let target = e.target;
        if (!target.classList.contains("progress")) {
            offsetWidth = target.parentElement.offsetWidth;
        }
        let percent = offsetX / offsetWidth;
        this.video.current.currentTime = percent * this.video.current.duration;
        if (target.classList.contains("progress")) {
            target = this.getProgressBar();
        }
        target.style.width = Math.round(percent * 100) + "%";
        if (this.state.replay) {
            this.setState({
                replay: false
            });
        }
    }

    formatTime(time) {
        if (isNaN(time)) return "--:--";
        let seconds = Math.floor(time) % 60;
        let minutes = Math.floor(time / 60);
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }

    setDuration() {
        this.duration.current.innerHTML = `${this.formatTime(this.video.current.currentTime)} / ${this.formatTime(this.video.current.duration)}`;
    }

    getDefaultTrack() {
        const {subtitles, locale} = this.props;
        let track = subtitles.find(item => item.language === locale);
        if (track) return track;
        let englishTrack = subtitles.find(item => item.language === "en");
        if (englishTrack) return englishTrack;
        return subtitles[0];
    }

    render() {
        const {sources, autoPlay, showPiP, customButtons, loading, t, showFullScreen} = this.props;
        const {playing, inFullscreen, inPiP} = this.state;
        let defaultTrack = this.getDefaultTrack();
        return (
            <div className={this.getClassName()} ref={this.container}>
                <LoadingComponent isLoading={loading}/>
                <video autoPlay={autoPlay} ref={this.video} onClick={playing ? this.onPauseClick : this.onPlayClick}
                       crossOrigin="anonymous">
                    {
                        sources.map((source, index) => (
                            <source src={source.src} type={source.type} key={source.src}/>
                        ))
                    }
                    {
                        defaultTrack &&
                        <track default kind="captions" srcLang={defaultTrack.language} src={defaultTrack.url} key={defaultTrack.url}/>
                    }
                </video>
                <div className="fetura-video-player-controls">
                    <div className="fetura-video-player-controls-progress">
                        <VideoPlayerProgress onProgressClick={this.onProgressClick}/>
                    </div>
                    <div className="fetura-video-player-controls-buttons">
                        <div className="fetura-video-player-controls-buttons-first">
                            <Button buttonStyle={"text"} icon={this.getPlayIcon()}
                                    buttonColor={"white"} onClick={playing ? this.onPauseClick : this.onPlayClick}/>
                            <div className="fetura-video-player-controls-buttons-first-duration" ref={this.duration}/>
                        </div>
                        <div className="fetura-video-player-controls-buttons-last">
                            {
                                showPiP && !isMobile() &&
                                <Button buttonStyle={"text"} icon={<i className="far fa-rectangle-landscape"/>}
                                        buttonColor={"white"} onClick={this.onPiPClick}/>
                            }
                            {customButtons}
                            {
                                showFullScreen &&
                                <Button buttonStyle={"text"} icon={this.getFullscreenIcon()} buttonColor={"white"}
                                        onClick={inFullscreen ? this.onCompressClick : this.onFullscreenClick}/>
                            }
                        </div>
                    </div>
                </div>
                {
                    inPiP &&
                    <div className="fetura-video-player-pip">
                        {t("pipRunning")}
                    </div>
                }
            </div>
        );
    }
}

VideoPlayer.propTypes = {
    sources: PropTypes.arrayOf(PropTypes.shape({
        src: PropTypes.node.isRequired,
        type: PropTypes.string,
    })).isRequired,
    subtitles: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        language: PropTypes.string,
    })),
    autoPlay: PropTypes.bool,
    showPiP: PropTypes.bool,
    customButtons: PropTypes.arrayOf(PropTypes.node),
    loading: PropTypes.bool,
    showFullScreen: PropTypes.bool,
    onVideoPlay: PropTypes.func,
    onVideoPause: PropTypes.func,
};

VideoPlayer.defaultProps = {
    autoPlay: true,
    showPiP: true,
    customButtons: [],
    subtitles: [],
    loading: false,
    showFullScreen: true
}

export default compose(
    withTranslation(null, {withRef: true}),
    connect(mapStateToProps, null, null, {forwardRef: true})
)(VideoPlayer);
