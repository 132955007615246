/**
 * Typy eventów
 * @constant {object}
 */
export const EventTypes = {
    TRANSFER: "T",
    INSEMINATION: "I",
    PARTURITION: "P",
    NO_PREGNANCY: "N",
    FALL: "F",
    GRAFTING: "G",
    SEPARATION: "S",
    SELL: "L",
    USG: "U",
    SEPARATION_TO_MOMMY: "M",
    CHANGE_CURVE: "C",
    JOIN: "J",
    SELECTION: "X",
    MOMMY: "W",
    FALL_PIGLETS: "V",
    CONDITION: "D",
    INSERTION: "IN",
    TREATMENT: "TR",
    WEIGHTING: "WE",
    ACTIVE_NIPPLES: "AN",
    RECLASSIFY: "R",
    PIGLETS_TREATMENT: "PT",
    HEAT: "H",
    SOW_CYCLES: "B",
    CASTRATION: "CA",
    TO_INSEMINATION: "TI",
    TO_REPEAT: "TT",
    PARTURITION_START: "PS",
    PARTURITION_END: "PE",
    PIGLET_RECLASSIFY: "PR",
    PARTURITION_STATE: "ST",
    FIRST_MILK: "FM"
};

export const EventCategory = {
    OVERALL: "O",
    GRAFTING: "G",
    PROCREATION: "PR",
    PARTURITION: "P",
    FALL: "F",
    SELL: "S",
    PIGLETS: "PI",
    GILTS: "GILTS"
};

export const USG_STATE = {
    NEGATIVE: 0,
    POSITIVE: 1,
    REPEAT: 2,
    REPEAT_NEGATIVE: 3,
    REPEAT_POSITIVE:4,
    MISSING: null
}

export const PARTURITION_DIFFICULTY = {
    LOW: 0,
    MEDIUM: 1,
    HARD: 2
}
