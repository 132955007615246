export const changeMapShadowDelta = (newShadow) => {
    return (dispatch) => {
        dispatch({
            type: "CHANGE_MAP_SHADOW_DELTA",
            payload: newShadow
        })
    }
}

export const changeMapShadow = (newShadow) => {
    return (dispatch) => {
        dispatch({
            type: "CHANGE_MAP_SHADOW",
            payload: newShadow
        })
    }
}
