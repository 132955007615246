import React, {Component} from "react";
import PropTypes from 'prop-types';
import {submit} from 'redux-form'
import Button from "../basics/button/Button";
import "./_modal.scss";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {compose} from "redux";

class ModalFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmButtonClicked: false,
            confirming: false
        }
    }

    onClick = (e) => {
        e.preventDefault();
        const {formName, onConfirmed, dispatch} = this.props;
        this.setState({confirmButtonClicked: true});
        if (formName) {
            dispatch(submit(formName))
        }
        if (onConfirmed) {
            const onConfirmedCalled = onConfirmed();
            // we set local confirming flag as true to mimic redux-form behaviour
            if (onConfirmedCalled instanceof Promise) {
                this.setState({
                    confirming: true
                }, () => {
                    onConfirmedCalled.finally(() => {
                        this.setState({
                            confirming: false
                        })
                    })
                })
            } else {
                // we only set confirming flag with onConfirmed returning promise
                // use 'forceLoadingOnClicked' prop if you wish to set button as loading when clicked with regular function
                this.setState({
                    confirming: false
                })
            }
        }
    };

    handleOnEnter = (e) => {
        const {hasButtonDisabled} = this.props
        if (e.key === 'Enter' && !e.Handled && !e.shiftKey && !hasButtonDisabled) {
            //e.preventDefault();
            this.onClick(e);
        }
        e.Handled = true;
    };

    componentDidMount() {
        if (!this.props.disableEnterSubmit) {
            window.addEventListener('keydown', this.handleOnEnter)
        }
    }

    componentWillUnmount() {
        if (!this.props.disableEnterSubmit) {
            window.removeEventListener('keydown', this.handleOnEnter)
        }
    }

    render() {
        const {
            hasConfirmButton,
            confirmText,
            confirmIcon,
            hasCloseButton,
            closeText,
            optionalButtons,
            onCloseClick,
            submitting,
            forceLoadingOnClick,
            t,
            hasButtonDisabled,
            confirmButtonColor,
            confirmButtonClassName,
            closeIcon
        } = this.props;
        const {confirmButtonClicked, confirming} = this.state;
        return (
            <div className="modal-footer">
                {
                    hasCloseButton &&
                    <Button
                        type={"button"}
                        icon={closeIcon}
                        onClick={() => {
                            onCloseClick();
                        }}
                    >
                        {closeText ? closeText : t("close")}
                    </Button>

                }
                {optionalButtons}
                {
                    hasConfirmButton &&
                    <Button
                        buttonColor={confirmButtonColor ? confirmButtonColor : "primary"}
                        icon={confirmIcon}
                        className={confirmButtonClassName}
                        isLoading={submitting || (confirmButtonClicked && forceLoadingOnClick) || confirming}
                        onClick={this.onClick}
                        disabled={hasButtonDisabled || submitting}
                    >
                        {confirmText ? confirmText : 'Ok'}
                    </Button>
                }
            </div>
        );
    }
}

ModalFooter.propTypes = {
    hasConfirmButton: PropTypes.bool,
    confirmText: PropTypes.string,
    confirmIcon: PropTypes.node,
    hasCloseButton: PropTypes.bool,
    closeText: PropTypes.string,
    closeIcon: PropTypes.node,
    optionalButtons: PropTypes.node,
    onCloseClick: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    formName: PropTypes.string,
    forceLoadingOnClicked: PropTypes.bool,
    onConfirmed: PropTypes.func,
    hasButtonDisabled: PropTypes.bool,
    disableEnterSubmit: PropTypes.bool,
    confirmButtonClassName: PropTypes.string
};

ModalFooter.defaultProps = {
    hasCloseButton: true,
    hasConfirmButton: false,
    forceLoadingOnClicked: false,
    hasButtonDisabled: false,
};


export default compose(
    withTranslation(),
    connect()
)(ModalFooter);
