import React from "react";
import CycleRenderer from "./CycleRenderer";
import PropTypes from "prop-types";
import {get, isEmpty} from "lodash";

class ActiveNipplesRenderer extends React.Component {

    state = {
        hoveredID: "",
    };

    onMouseEnter = (event) => {
        const cell = get(event, "currentTarget");
        if (cell) {
            const EvID = cell.id.split(" ")[0];
            this.setState({
                hoveredID: EvID
            })
        }
    };

    onMouseLeave = () => {
        this.setState({
            hoveredID: ""
        })
    };

    render() {
        const {
            cycle: {activeNipples},
            tooltipContent,
            cycleRenderClass,
            deletionMode,
            onDeleteClick,
            onDoubleEventClick,
            isInterrupted
        } = this.props;
        const {hoveredID} = this.state;
        let lastActiveNipples = activeNipples[activeNipples.length - 1];
        let operators = [];
        if (!isEmpty(activeNipples)) operators = activeNipples[0].operators;
        if (!lastActiveNipples) {
            return (
                <CycleRenderer isInterrupted={isInterrupted} comment={cycleRenderClass && tooltipContent}
                               deletionMode={deletionMode} className="print-last"/>
            )
        }
        return (
            <CycleRenderer comment={cycleRenderClass && tooltipContent} className="print-last"
                           isAdvanced={false} operators={operators} deletionMode={deletionMode}>
                <div className={`cell-wrapper ${hoveredID === lastActiveNipples.event.EvID ? "error" : "success"}`}
                     id={`${lastActiveNipples.event.EvID} EvData.Nipples`} onDoubleClick={onDoubleEventClick}
                     onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                     onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                     onClick={deletionMode ? onDeleteClick : undefined}
                >
                    {get(lastActiveNipples, "event.EvData.Nipples")}
                </div>
            </CycleRenderer>
        );
    }

}

ActiveNipplesRenderer.propTypes = {
    cycle: PropTypes.shape({
        nipples: PropTypes.array.isRequired
    }).isRequired
};

export default ActiveNipplesRenderer;
