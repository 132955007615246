import {parse, stringify} from "path-ast";
import keys from "path-ast/lib/keys";
import {cloneDeep, find, findLast, isNumber} from "lodash";
import constants from "path-ast/lib/constants";
import Collision from "./Collision";
import {polygonHull} from "d3-polygon";
import {
    getAngleBetween3Points,
    getAngleBetweenTwoPoints,
    getDistanceBetweenTwoPoints,
    getVectorCoordinatesByLengthAndAngle
} from "./utils";
import {
    LEFT_BOTTOM,
    LEFT_MIDDLE,
    LEFT_TOP,
    MIDDLE_BOTTOM,
    MIDDLE_MIDDLE,
    MIDDLE_TOP,
    RIGHT_BOTTOM,
    RIGHT_MIDDLE,
    RIGHT_TOP
} from "./Align";
import {isBetween, fixFloatingPrecision} from "../../../utils/MathUtils";
import {doIntersect, getLargestRectInsidePoly} from "../../../utils/FarmMapUtils";
import {cloneFast} from "../../../utils/Utils";

export default class PathParser {


    static mergePaths(paths) {
        const points = [];
        for (const d of paths) {
            const vertices = new PathParser(d).getVertices();
            vertices.forEach(({x, y}) => {
                points.push([x, y]);
            })
        }
        return polygonHull(points);
    }


    getSVGTextName() {
        return "path"
    }

    static getMemoKey(params) {
        return typeof params === "string" ? params : params.d;
    }

    constructor(params) {
        const parsed = parse(typeof params === "string" ? params : params.d);
        Object.assign(this, parsed);
        this.orientation = typeof params === "string" ? null : params.orientation || null
    }


    static createFromRect(x, y, width, height) {
        return new PathParser(`M${x} ${y} L${x} ${y + height} L${x + width} ${y + height} L${x + width} ${y} Z`)
    }

    static createFromPoints(points) {
        let path = "";
        for (let i = 0; i < points.length; i++) {
            const isFirst = i === 0;
            const isLast = i === (points.length - 1);
            const prefix = isFirst ? "M" : "L";
            const suffix = isLast ? " Z" : " ";
            path += `${prefix}${points[i].x} ${points[i].y}${suffix}`;
        }
        return new PathParser(path)
    }

    toString() {
        return stringify(this);
    }

    getParams() {
        return {d: this.toString(), orientation: this.orientation || null}
    }

    toggleOrientation() {
        if (this.orientation === null) return this;
        switch (this.orientation) {
            case "horizontal": {
                this.orientation = "horizontal-ml";
                return this;
            }
            case "horizontal-ml": {
                this.orientation = "vertical";
                return this;
            }
            case "vertical": {
                this.orientation = "vertical-ml";
                return this;
            }
            default:
                this.orientation = "horizontal";
                return this;
        }
    }

    getRect() {
        let minY = null, minX = null, maxY = null, maxX = null;
        const max = (value, currentMax) => currentMax === null ? value : Math.max(value, currentMax);
        const min = (value, currentMin) => currentMin === null ? value : Math.min(value, currentMin);
        this.commands.forEach((command) => {
            if (typeof command.params.x !== "undefined") {
                maxX = max(command.params.x, maxX);
                minX = min(command.params.x, minX);
            }
            if (typeof command.params.y !== "undefined") {
                maxY = max(command.params.y, maxY);
                minY = min(command.params.y, minY);
            }
        });
        return {
            minX,
            maxY,
            maxX,
            minY,
            x: minX,
            y: minY,
            width: Math.abs(maxX - minX),
            height: Math.abs(maxY - minY)
        }
    }


    getRectPoints() {
        const rect = this.getRect();
        return [
            {x: rect.minX, y: rect.minY, position: "topLeft"},
            {x: rect.maxX, y: rect.minY, position: "topRight"},
            {x: rect.maxX, y: rect.maxY, position: "bottomRight"},
            {x: rect.minX, y: rect.maxY, position: "bottomLeft"},
        ]
    }

    getMiddleCrossPoints() {
        const center = this.getCenter();
        const rect = this.getRect();
        return [
            {x: center.x, y: rect.minY, position: "top"},
            {x: center.x, y: rect.maxY, position: "bottom"},
            {x: rect.minX, y: center.y, position: "left"},
            {x: rect.maxX, y: center.y, position: "right"},
        ]
    }

    getCompassRosePoints() {
        return [...this.getRectPoints(), ...this.getMiddleCrossPoints()];
    }

    isClockwise() {
        const vertices = this.getVertices();
        if (vertices.length < 3) return false;
        let end = vertices.length - 1;
        let sum = vertices[end].x * vertices[0].y - vertices[0].x * vertices[end].y;
        for (let i = 0; i < end; ++i) {
            const n = i + 1;
            sum += vertices[i].x * vertices[n].y - vertices[n].x * vertices[i].y;
        }
        return sum > 0;
    }

    getEdges() {
        const lines = [];
        const vertexes = this.getVertices();
        let isClockwise = this.isClockwise();
        for (let i = 0; i < vertexes.length; i++) {
            const before = vertexes.slice(i - 1)[0];
            const after = vertexes[i];
            let x1, x2, y1, y2;
            if (isClockwise) {
                x1 = before.x;
                x2 = after.x;
                y1 = before.y;
                y2 = after.y;
            } else {
                x1 = after.x;
                x2 = before.x;
                y1 = after.y;
                y2 = before.y;
            }
            lines.push({
                x1,
                x2,
                y1,
                y2,
                distance: getDistanceBetweenTwoPoints(x1, y1, x2, y2),
                angle: getAngleBetweenTwoPoints(x1, y1, x2, y2),
                indexes: [before.index, after.index]
            });
        }
        return lines;
    }

    _scaleN(scaleX = null, scaleY = null, cx, cy) {

        let center = this.getCenter()
        cx = typeof cx !== 'undefined' ? cx : center.x;
        cy = typeof cy !== 'undefined' ? cy : center.y;

        this.commands = this.commands.map((command) => {
            keys[command.type].forEach((key) => {
                const param = command.params[key];
                if (scaleX !== null && key.match(constants.X_REGEX)) {
                    command.params[key] = cx + (param - cx) * scaleX
                }
                if (scaleY !== null && key.match(constants.Y_REGEX)) {
                    command.params[key] = cy + (param - cy) * scaleY
                }
            })
            return command;
        })
        return this;
    }

    scaleX(n, cx, cy) {
        return this._scaleN(n, null, cx, cy);
    }

    scaleY(n, cx, cy) {
        return this._scaleN(null, n, cx, cy);
    }

    scaleXY(nX, nY, cx, cy) {
        return this._scaleN(nX, nY, cx, cy);
    }

    addPoint(index, x, y) {
        let type = "L";
        if (this.commands[index].type === "M") {
            this.commands[index].type = "L";
            type = "M";
        }
        this.commands.splice(index, 0, {
            type,
            params: {x, y}
        });
        return this;
    }

    useDecimals() {
        const newCommands = [];
        for (let cmd of this.commands) {
            const command = cloneDeep(cmd);
            if (command.params) {
                for (let key in command.params) {
                    if (isNumber(command.params[key])) {
                        command.params[key] = Math.round(command.params[key])
                    }
                }
            }
            newCommands.push(command);
        }
        this.commands = newCommands;
        return this;
    }

    removePoint(index) {
        if (this.commands.length <= 4) {
            console.warn("Not enough vertices to remove one");
            return this;
        }
        let type = "L";
        if (this.commands[index].type === "M") {
            type = "M";
        }
        this.commands = this.commands.filter((o, i) => i !== index);
        if (type === "M") {
            this.commands[index].type = type;
        }
        return this;

    }

    getVertices() {
        const vertices = [];
        this.commands.forEach((command, index) => {
            if (typeof command.params.x !== "undefined" && typeof command.params.y !== "undefined") {
                vertices.push({
                    x: command.params.x,
                    y: command.params.y,
                    index: index
                })
            }
        });
        return vertices;
    }

    getPoly() {
        return this.getVertices();
    }

    collidesWith(object) {
        return Collision.isCollision(this, object);
    }

    getArea() {
        const vertices = this.getVertices()
        let total = 0;

        for (let i = 0, l = vertices.length; i < l; i++) {
            let addX = vertices[i].x;
            let addY = vertices[i === vertices.length - 1 ? 0 : i + 1].y;
            let subX = vertices[i === vertices.length - 1 ? 0 : i + 1].x;
            let subY = vertices[i].y;

            total += (addX * addY * 0.5);
            total -= (subX * subY * 0.5);
        }
        return Math.abs(total);
    }

    isRect() {
        const vertices = this.getVertices();
        if (vertices.length === 4) {
            const rect = this.getRect();
            const points = [
                {
                    x: rect.x,
                    y: rect.y
                },
                {
                    x: rect.maxX,
                    y: rect.maxY
                },
                {
                    x: rect.maxX,
                    y: rect.y
                }, {
                    x: rect.x,
                    y: rect.maxY
                }
            ];
            for (let p of vertices) {
                const index = points.findIndex(({x, y}) => p.x === x && p.y === y);
                if (index === -1) return false;
                points.splice(index, 1);
            }
            return !points.length
        }
        return false;
    }

    isRotatedRect() {
        const vertices = this.getVertices();
        if (vertices.length === 4) {
            for (let i = 0; i < 4; ++i) {
                const start = vertices[i];
                const mid = vertices[(i + 1) % 4];
                const end = vertices[(i + 2) % 4];
                const angle = getAngleBetween3Points(mid, start, end);
                const absoluteAngle = Math.abs(angle % 180);
                if (absoluteAngle > 90.005 || absoluteAngle < 89.005) return false;
            }
            return true;
        }
        return false;
    }

    getRotate = () => {
        if (this.isRotatedRect()) {
            const vertices = this.getVertices();
            const {minX, minY, maxX, maxY} = this.getRect();
            let initialAngle = 0;
            const isTop = vertices[0].y === minY;
            const isLeft = vertices[0].x === minX;
            const isBottom = vertices[0].y === maxY;
            const isRight = vertices[0].x === maxX;
            if (isTop && !isRight) {
                initialAngle = 0;
            } else if (isRight && !isBottom) {
                initialAngle = 90;
            } else if (isBottom && !isLeft) {
                initialAngle = 180;
            } else if (isLeft && !isTop) {
                initialAngle = 270;
            }
            return initialAngle;
        }
        return 0;
    }

    getRotatedRectParams() {
        const isRotated = this.isRotatedRect();
        if (isRotated) {
            const isClockwise = this.isClockwise();
            const vertices = this.getVertices();
            const center = this.getCenter();
            const {minX, minY} = this.getRect();
            let angle;
            const isRect = this.isRect();
            const initialAngle = this.getRotate();
            if (isRect) {
                angle = (initialAngle % 180);
            } else {
                const left = (isClockwise ? findLast(vertices, ({x}) => x === minX) : find(vertices, ({x}) => x === minX));
                const top = (isClockwise ? findLast(vertices, ({y}) => y === minY) : find(vertices, ({y}) => y === minY));
                const topLeftAngle = fixFloatingPrecision(getAngleBetweenTwoPoints(left.x, left.y, top.x, top.y), 3);
                angle = 90 - (initialAngle % 180) + topLeftAngle;

            }
            const path = new PathParser(this.toString());
            path.rotate(-angle);
            const rect = path.getRect();
            const angle2 = fixFloatingPrecision(getAngleBetween3Points(center, rect, vertices[0]), 3);
            return {
                x1: rect.x,
                x2: rect.maxX,
                y1: rect.y,
                y2: rect.maxY,
                width: rect.width,
                height: rect.height,
                angle: angle2
            }
        }
        return null;

    }


    getInsideRectParams() {
        const rect = this.getRect();
        const rotateParams = this.getRotatedRectParams();
        if (this.isRect()) {
            return {
                angle: rotateParams ? rotateParams.angle : 0,
                rect: {
                    x1: rect.x,
                    x2: rect.maxX,
                    y1: rect.y,
                    y2: rect.maxY,
                    width: rect.width,
                    height: rect.height
                },
                _rotatedRect: rotateParams,
            }
        }
        return {
            angle: rotateParams ? rotateParams.angle : 0,
            _rotatedRect: rotateParams,
            rect: getLargestRectInsidePoly("area-canvas", this.toString())
        }
    }

    getTextPosition() {
        const rect = this.getRect();
        if (this.isRect()) {
            return {
                x1: rect.x,
                x2: rect.maxX,
                y1: rect.y,
                y2: rect.maxY,
                width: rect.width,
                height: rect.height
            }
        }
        const hasCanvas = !!document.getElementById("area-canvas");
        const result = {
            x1: rect.x,
            x2: rect.x,
            y1: rect.y,
            y2: rect.y,
            width: 0,
            height: 0
        }
        if (!hasCanvas) return result;
        return getLargestRectInsidePoly("area-canvas", this.toString());

    }

    getRelativeVertices() {
        const vertices = this.getVertices();
        return vertices.map(o => ({
            x: o.x - vertices[0].x,
            y: o.y - vertices[0].y,
            index: o.index
        }))
    }

    transformCanvasResize(oldCanvasW, oldCanvasH, newCanvasW, newCanvasH, align = LEFT_TOP) {
        const diffX = newCanvasW - oldCanvasW;
        const diffY = newCanvasH - oldCanvasH;

        let moveX = 0;
        let moveY = 0;
        switch (align) {
            case RIGHT_BOTTOM:
            case RIGHT_MIDDLE:
            case RIGHT_TOP:
                moveX = diffX;
                break;
            case MIDDLE_BOTTOM:
            case MIDDLE_MIDDLE:
            case MIDDLE_TOP:
                moveX = diffY / 2;
                break;
            default:
                break;
        }
        switch (align) {
            case RIGHT_BOTTOM:
            case LEFT_BOTTOM:
            case MIDDLE_BOTTOM:
                moveY = diffY;
                break;
            case LEFT_MIDDLE:
            case MIDDLE_MIDDLE:
            case RIGHT_MIDDLE:
                moveY = diffY / 2;
                break;
            default:
                break;
        }
        if (moveY === moveX && moveX === 0) {
            return this;
        } else {
            this.translate(moveX, moveY);
            return this;
        }
    }

    paste(obj) {
        if (obj instanceof PathParser) {
            const o1 = obj.getCenter();
            const o2 = this.getCenter();
            const moveX = o2.x - o1.x, moveY = o2.y - o1.y;
            this.commands = cloneDeep(obj.commands);
            this.translate(moveX, moveY)
            return this;
        }
        return this;
    }

    reflectX(cx) {
        this.reflectX(cx);
        return this;
    }

    reflectY(cy) {
        this.reflectY(cy);
        return this;
    }

    getPath2D() {
        return new Path2D(this.toString())
    }

    isVisibleInViewport(viewPortX1, viewPortX2, viewPortY1, viewPortY2) {
        const rect = this.getRect();
        const isHorizontalBetween = isBetween(rect.minX, viewPortX1, viewPortX2) || isBetween(rect.maxX, viewPortX1, viewPortX2);
        if (!isHorizontalBetween) return false;
        return isBetween(rect.maxY, viewPortY1, viewPortY2) || isBetween(rect.minY, viewPortY1, viewPortY2);
    }

    // path modification by specifying with and height (only for rect)
    getCustomSetter() {
        // this should only work for rect-like paths
        if (this.isRect() || this.isRotatedRect()) {
            const params = {
                width: 0,
                height: 0
            }
            params.width = getDistanceBetweenTwoPoints(this.commands[0].params.x, this.commands[0].params.y, this.commands[1].params.x, this.commands[1].params.y);
            params.height = getDistanceBetweenTwoPoints(this.commands[1].params.x, this.commands[1].params.y, this.commands[2].params.x, this.commands[2].params.y);
            return params;
        }
        return null;
    }

    setUsingCustomSetter({width, height} = {}) {
        if (width && height) {
            if (this.isRect() || this.isRotatedRect()) {
                // robimy kopie bo w petli modyfikujemy path i musimy miec dostep do pierwotnych katow
                const cmdCopy = cloneFast(this.commands);
                for (let i = 0; i < 4; i++) {
                    const length = i % 2 === 0 ? width : height;
                    // korzystamy z twierdzenia cos i sinosow zeby z dlugosci wektora i kata obliczyc przesuniecie w X i Y
                    const angle = getAngleBetweenTwoPoints(cmdCopy[i].params.x, cmdCopy[i].params.y, cmdCopy[i + 1].params.x, cmdCopy[i + 1].params.y);
                    const {x, y} = getVectorCoordinatesByLengthAndAngle(length, angle);
                    this.commands[i + 1].params.x = fixFloatingPrecision(this.commands[i].params.x + x);
                    this.commands[i + 1].params.y = fixFloatingPrecision(this.commands[i].params.y + y);

                }
                this.commands[0].params.x = fixFloatingPrecision(this.commands[0].params.x);
                this.commands[0].params.y = fixFloatingPrecision(this.commands[0].params.y);
            }
        }
        return this;
    }

    /**
     * this aligns "d" of rotated rect to X axis, and returns the transform to revert the operation
     * it is needed to make pattern align to the "d"-rect
     * @param returnAsObj
     * @return {{transform: string, d}|{d}}
     */
    makeRotatedRectGreatAgain(returnAsObj = false) {
        if (this.isRotatedRect()) {
            const newPath = new PathParser(this.toString());
            const params = newPath.getRotatedRectParams()
            newPath.rotate(-params.angle);
            const center = newPath.getCenter();
            return {
                d: newPath.toString(),
                transform: `rotate(${params.angle},${center.x},${center.y})`,
                ...returnAsObj && {rotation: {angle: params.angle, x: center.x, y: center.y}}
            }
        }
        return {
            d: this.toString()
        }
    }

    isSelfIntersecting() {
        const vertices = this.getVertices();
        const tested = {};
        for (let i = 0; i < vertices.length; i++) {
            // makes a line from two points, last line is made from first and last point
            const line1 = i ? [vertices[i - 1], vertices[i]] : [vertices[i], vertices[vertices.length - 1]];
            for (let j = 0; j < vertices.length; j++) {
                // skip same line
                if (i === j) continue;
                // get another line
                const line2 = j ? [vertices[j - 1], vertices[j]] : [vertices[j], vertices[vertices.length - 1]];
                // make unique key containing index of line
                const key = i > j ? `${i}_${j}` : `${j}_${i}`;
                // already checked
                if (tested[key]) continue;
                tested[key] = true;
                // check if lines intersect
                const intersects = doIntersect(...line1, ...line2);
                if (intersects) {
                    const value = !line1.some((p1) => line2.some((p2) => p1.x === p2.x && p1.y === p2.y));
                    if (value) return true;
                }
            }
        }
        return false;
    }

}
