import apiDB from "../database/apiDB";

const excludes = ["eventsForAnimals"];

export function getSyncAPI(left = null) {
    return function (dispatch) {
        dispatch({
            type: "GET_SYNC_DATA",
            payload: apiDB.getData(left)
        })
    }
}

export function addSyncAPI(path, method, headers, query, body) {
    return function (dispatch) {
        delete headers.authorization;
        delete headers.Authorization;
        for (let exclude of excludes) {
            if (path.includes(exclude)) return;
        }
        apiDB.insert(path, method, headers, query, body);
        dispatch(getSyncAPI());
    }
}