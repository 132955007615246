import { combineReducers } from "redux";
import error from "./errorReducer";
import user from "./userReducer";
import farms from "./farmsReducer";
import language from "./localizationReducer";
import location from "./locationReducer";
import settings from "./settingsReducer";
import view from "./viewPropertiesReducer";
import loading from "./loadingReducer";
import farmDevices from "./devicesReducer";
import grid from "./gridReducer";
import shadows from "./shadowReducer";
import { reducer as reduxFormReducer } from "redux-form";
import { reducer as modal } from "redux-modal";
import { reducer as notificationsReducer } from "reapop";
import mqtt from "./mqttReducer";
import iotAlarms from "./iotAlarmsReducer";
import terminal from "./terminalReducer";
import time from "./timeReducer";
import notificationCenter from "./notificationReducer";
import aggregatedData from "./aggregatedReducer";
import feeding from "./feedingReducer";
import maintenance from "./maintenanceReducer";
import mainTab from "./mainTabReducer";
import sideBar from "./sideBarReducer";
import devicesDebug from "./devicesDebugReducer";
import online from "./onlineReducer";
import help from "./helpReducer";
import deviceRows from "./deviceRowsReducer";
import notificationHistory from "./notificationHistoryReducer";
import ipsum from "./ipsumReducer";
import settlement from "./settlementReducer";
////////////////
import animals from "./animalsReducer";
import animalDocuments from "./animalDocumentsReducer";
import events from "./eventsReducer";
import dictionary from "./dictionaryReducer";
import technologyGroup from "./technologyGroupReducer";
import raports from "./raportsReducer";
import braintree from "./braintreeReducer";
import sns from "./snsReducer";
import documents from "./documentsReducer";
import workers from "./workersReducer";
import dataLoader from "./dataLoaderReducer";
import logs from "./logsReducer";
import lastUsed from "./lastUsedReducer";
import commandsHistory from "./commandsHistoryReducer";
import inventory from "./inventoryReducer";
import eventCounter from "./eventCountersReducer";
import task from "./taskReducer";
import groups from "./groupReducer";
import changelog from "./changelogReducer";
import files from "./filesReducer";
import pip from "./pipReducer";
import managePC from "./managePCReducer";
import sales from "./salesReducer";
import preferences from "./preferencesReducer";
import economy from "./economyReducer";
import breedingCounters from "./breedingCounterReducer";
import editFarmMap from "./editFarmMapReducer";
import registration from "./registrationReducer";
import workingCopy from "./workingCopyReducer";
import currentAccount from "./currentAccountReducer";
import farmMapData from "./mapShadowReducer";
import pcb from "./pcbReducer";
import rfidHistory from "./rfidHistoryReducer";
import sync from "./syncReducer";
import deviceScan from "./deviceScanReducer";
import dataMonitor from "./monitorReducer";
import projects from "./projectsReducer";
import rfid from "./rfidReducer";
import animalParameters from "./animalParametersReducer";
//////////

export default combineReducers({
////////////////////
    animals,
    animalDocuments,
    events,
    dictionary,
    technologyGroup,
    raports,
    braintree,
    sns,
    documents,
    workers,
    dataLoader,
    logs,
    lastUsed,
    commandsHistory,
    inventory,
    eventCounter,
    task,
    groups,
    changelog,
    files,
    pip,
    managePC,
    sales,
    preferences,
    economy,
    breedingCounters,
    editFarmMap,
    registration,
    workingCopy,
    currentAccount,
    farmMapData,
    pcb,
    rfidHistory,
    sync,
    deviceScan,
    dataMonitor,
    projects,
    rfid,
    animalParameters,
//////////////
    settlement,
    error,
    user,
    farms,
    language,
    modal,
    location,
    view,
    notifications: notificationsReducer(),
    settings,
    loading,
    farmDevices,
    grid,
    shadows,
    mqtt,
    terminal,
    form: reduxFormReducer, // mounted under "form"
    time,
    notificationHistory,
    notificationCenter,
    aggregatedData,
    feeding,
    iotAlarms,
    maintenance,
    mainTab,
    devicesDebug,
    sideBar,
    online,
    help,
    deviceRows,
    ipsum
});
