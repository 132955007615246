import { withTranslation } from "react-i18next";
import React from "react";
import Button from "../../basics/button/Button";
import ButtonGroup from "../../basics/button/button-group/ButtonGroup";
import Menu from "../../basics/menu/Menu";
import MenuItem from "../../basics/menu/MenuItem";
import memoizeOne from "memoize-one";
import { checkIfUserIsService } from "../../../utils/NewRolesUtils";

class TypeSelect extends React.Component {

    isService = checkIfUserIsService();

    state = {
        event: null
    }

    setEvent = (e) => {
        this.setState({
            event: e
        })
    }

    options = memoizeOne((t) => [
        {
            value: "chambers",
            name: t("chambers"),
            icon: "fas fa-warehouse"
        },
        {
            value: "climates",
            name: t("climates"),
            icon: "fas fa-thermometer-three-quarters"
        },
        {
            value: "water",
            name: t("water"),
            icon: "fas fa-faucet"
        },
        {
            value: "electric",
            name: t("electricity"),
            icon: "fas fa-bolt"
        },
        {
            value: "lights",
            name: t("newSettings.buildings.farmMap.groups.lights"),
            icon: "fas fa-lightbulb"
        },
        {
            value: "animals",
            name: t("newSettings.buildings.farmMap.groups.animals"),
            icon: "fas fa-filter"
        }
    ].filter((o) => o));

    renderButton = ({ name, value, icon }) => {
        const { mapType, onSelect } = this.props;
        return (
            <Button title={name} buttonColor={"menu-color"} icon={<i className={`fa-fw ${icon}`} />}
                selected={mapType === value}
                onClick={() => onSelect(value)} />
        )
    }


    renderMenuItem = ({ name, value, icon }) => {
        const { mapType, onSelect } = this.props;
        return (
            <MenuItem icon={<i className={`fa-fw ${icon}`} />} selected={mapType === value}
                onClick={() => onSelect(value)}>{name}</MenuItem>
        )
    }

    render() {
        const { mobile, mapType, t } = this.props;
        const { event } = this.state;
        if (mobile) {
            const icon = this.options(t).find(({ value }) => mapType === value)?.icon || "fas fa-warehouse";
            return (
                <>
                    <Menu event={event}>
                        {this.options(t).map(this.renderMenuItem)}
                    </Menu>
                    <Button buttonStyle={"round"} icon={<i className={`fa-fw ${icon}`} />} buttonColor={"menu-color"}
                        onClick={this.setEvent} />
                </>
            )
        }
        return (
            <ButtonGroup>
                {this.options(t).map(this.renderButton)}
            </ButtonGroup>
        )
    }

}

export default withTranslation()(TypeSelect);
