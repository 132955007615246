import { PIG_COLOR } from "../components/farm-map-components/patterns/utils";

export const SOW_IDLE_DAYS = "SELECT AnmID FROM ParamsTable WHERE key=\"idleDays\" AND actCycle=true AND [value] >= ? AND isPresent = true";
export const SOW_AFTER_EXPECTED_FARROWING = "SELECT AnmID FROM ParamsTable WHERE key=\"expectedFarrowing\" AND actCycle=true AND [value] <= -? AND isPresent = true";
export const SOW_BEFORE_EXPECTED_FARROWING = "SELECT AnmID FROM ParamsTable WHERE key=\"expectedFarrowing\" AND actCycle=true AND [value] <= ? AND isPresent = true";
export const SOW_REPETITIONS = "SELECT AnmID FROM(SELECT AnmID, MAX(CASE WHEN key = 'repetition' THEN [value] ELSE NULL END) AS repetition, MAX(CASE WHEN key = 'bornPiglet' THEN [value] ELSE NULL END) AS bornPiglet, MIN(CASE WHEN key = 'usgResult' THEN [value] ELSE NULL END) AS usgResult FROM ParamsTable WHERE actCycle=true AND isPresent = true GROUP BY group_nr, AnmID, AnmNo1, actCycle) as S0 WHERE usgResult IS NULL AND bornPiglet IS NULL AND repetition>= 2";
export const SOW_BEFORE_DELAYED_AFTER_PARTURITION = "SELECT * FROM(SELECT AnmID, SUM(CASE WHEN key = 'weanedPerCycleFarrow' THEN [value] ELSE NULL END) AS weanedPerCycleFarrow,SUM(CASE WHEN key = 'expectedFarrowing' THEN [value] ELSE NULL END) AS expectedFarrowing, SUM(CASE WHEN key = 'shiftFarrowDay' THEN [value] ELSE NULL END) AS shiftFarrowDay FROM ParamsTable WHERE actCycle=true AND isPresent = true GROUP BY group_nr, AnmID, AnmNo1, actCycle) as S0 WHERE (expectedFarrowing <= 7 OR shiftFarrowDay IS NOT NULL) AND weanedPerCycleFarrow IS NULL";
export const SOW_EXPECTED_TRANSFER = "SELECT AnmID FROM ParamsTable WHERE key = 'nextTransfer' AND isPresent = true AND [value] < 0";
export const SOW_WITHOUT_PIGLETS = "SELECT AnmID FROM (SELECT AnmID, [value] as days FROM ParamsTable WHERE key = 'zeroPigletsDays' AND actCycle = true AND isPresent = true) as s1 INNER JOIN (SELECT AnmID FROM ParamsTable WHERE key = 'currentSector' AND actCycle = true AND isPresent = true AND [value] = 4) as s2 ON s1.AnmID = s2.AnmID";
export const SOW_WRONG_CYCLE = "WITH main as (SELECT cycle, AnmID, COUNT(CASE WHEN key = 'liveBornPiglet' THEN AnmID END) > 0 as hadParturition, COUNT(CASE WHEN key = 'weanedPiglet' THEN AnmID END) > 0 as hadWeaning, COUNT(CASE WHEN key = 'boar' THEN AnmID END) > 0 as hadInsemination FROM ParamsTable WHERE isPresent = true GROUP BY AnmID, cycle) SELECT AnmID FROM (SELECT s1.AnmID, s1.hadInsemination, s2.hadParturition, s2.hadWeaning FROM (SELECT * FROM main) as s1 INNER JOIN (SELECT * FROM main) as s2 ON CONCAT(s1.AnmID, '_', s1.cycle - 1) = CONCAT(s2.AnmID, '_', s2.cycle)) WHERE hadInsemination = true AND hadParturition = true AND hadWeaning = false";
export const SOW_WITH_PIGLETS = "SELECT * FROM (SELECT SUM(CASE WHEN key IN ('liveBornPiglet', 'fostering') THEN [value] ELSE -[value] END) as [value], AnmID FROM ParamsTable WHERE actCycle = true AND key IN ('liveBornPiglet', 'deadPiglet', 'weanedPiglet', 'fostering') GROUP BY AnmID) WHERE [value] > 0";

export const SOW_IDLE_DAYS_ANIMALS = "SELECT AnmID, [value] FROM ParamsTable WHERE key=\"idleDays\" AND actCycle=true AND [value] >= ? AND isPresent = true AND AnmID IN @(?) ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC";
export const SOW_AFTER_EXPECTED_FARROWING_ANIMALS = "SELECT AnmID, ABS([value]) as [value] FROM ParamsTable WHERE key=\"expectedFarrowing\" AND actCycle=true AND [value] <= -? AND isPresent = true AND AnmID IN @(?) ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC";
export const SOW_BEFORE_EXPECTED_FARROWING_ANIMALS = "SELECT AnmID, [value] FROM ParamsTable WHERE key=\"expectedFarrowing\" AND [value] <= ? AND actCycle=true AND isPresent = true AND AnmID IN @(?) ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC";
export const SOW_REPETITIONS_ANIMALS = "SELECT * FROM(SELECT AnmID, MAX(CASE WHEN key = 'repetition' THEN [value] ELSE NULL END) AS repetition, MAX(CASE WHEN key = 'bornPiglet' THEN [value] ELSE NULL END) AS bornPiglet, MIN(CASE WHEN key = 'usgResult' THEN [value] ELSE NULL END) AS usgResult FROM ParamsTable WHERE actCycle=true AND isPresent = true AND AnmID IN @(?) GROUP BY group_nr, AnmID, AnmNo1, actCycle ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC) as S0 WHERE usgResult IS NULL AND bornPiglet IS NULL AND repetition>= 2";
export const SOW_BEFORE_DELAYED_AFTER_PARTURITION_ANIMALS = "SELECT * FROM(SELECT AnmID, SUM(CASE WHEN key = 'weanedPerCycleFarrow' THEN [value] ELSE NULL END) AS weanedPerCycleFarrow,SUM(CASE WHEN key = 'expectedFarrowing' THEN [value] ELSE NULL END) AS expectedFarrowing, SUM(CASE WHEN key = 'shiftFarrowDay' THEN [value] ELSE NULL END) AS shiftFarrowDay FROM ParamsTable WHERE actCycle=true AND isPresent = true AND AnmID IN @(?) GROUP BY group_nr, AnmID, AnmNo1, actCycle ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC) as S0 WHERE (expectedFarrowing <= 7 OR shiftFarrowDay IS NOT NULL) AND weanedPerCycleFarrow IS NULL";
export const SOW_EXPECTED_TRANSFER_ANIMALS = "SELECT AnmID, ABS([value]) as days FROM ParamsTable WHERE key = 'nextTransfer' AND isPresent = true AND [value] < 0 AND AnmID IN @(?) ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC";
export const SOW_WITHOUT_PIGLETS_ANIMALS = "SELECT * FROM (SELECT AnmID, [value] as days FROM ParamsTable WHERE key = 'zeroPigletsDays' AND actCycle = true AND isPresent = true AND AnmID IN @(?)) as s1 INNER JOIN (SELECT AnmID FROM ParamsTable WHERE key = 'currentSector' AND actCycle = true AND isPresent = true AND [value] = 4) as s2 ON s1.AnmID = s2.AnmID ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC";
export const SOW_WRONG_CYCLE_ANIMALS = "WITH main as (SELECT cycle, AnmID, LAST(AnmNo1), COUNT(CASE WHEN key = 'liveBornPiglet' THEN AnmID END) > 0 as hadParturition, COUNT(CASE WHEN key = 'weanedPiglet' THEN AnmID END) > 0 as hadWeaning, COUNT(CASE WHEN key = 'boar' THEN AnmID END) > 0 as hadInsemination FROM ParamsTable WHERE isPresent = true AND AnmID IN @(?) GROUP BY AnmID, cycle) SELECT AnmID FROM (SELECT s1.AnmID, s1.hadInsemination, s2.hadParturition, s2.hadWeaning FROM (SELECT * FROM main) as s1 INNER JOIN (SELECT * FROM main) as s2 ON CONCAT(s1.AnmID, '_', s1.cycle - 1) = CONCAT(s2.AnmID, '_', s2.cycle)) WHERE hadInsemination = true AND hadParturition = true AND hadWeaning = false ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC";
export const SOW_WITH_PIGLETS_ANIMALS = "SELECT * FROM (SELECT SUM(CASE WHEN key IN ('liveBornPiglet', 'fostering') THEN [value] ELSE -[value] END) as [value], AnmID FROM ParamsTable WHERE actCycle = true AND AnmID IN @(?) AND key IN ('liveBornPiglet', 'deadPiglet', 'weanedPiglet', 'fostering') GROUP BY AnmID) WHERE [value] > 0 ORDER BY AnmNo1::NUMBER ASC, AnmNo1 ASC";

export const mapSQL = { // lista zapytan dla mapy
    SOW_IDLE_DAYS,
    SOW_AFTER_EXPECTED_FARROWING,
    SOW_BEFORE_EXPECTED_FARROWING,
    SOW_REPETITIONS,
    SOW_BEFORE_DELAYED_AFTER_PARTURITION,
    SOW_EXPECTED_TRANSFER,
    SOW_WITHOUT_PIGLETS,
    SOW_WRONG_CYCLE,
    SOW_WITH_PIGLETS
};

export const mapSQLColors = {
    SOW_IDLE_DAYS: PIG_COLOR.ERROR,
    SOW_REPETITIONS: ({ value }) => value === 2 ? PIG_COLOR.WARN : PIG_COLOR.ERROR,
    SOW_BEFORE_DELAYED_AFTER_PARTURITION: ({ expectedFarrowing, shiftFarrowDay }) => shiftFarrowDay !== null ? PIG_COLOR.INFO : expectedFarrowing >= 0 ? PIG_COLOR.SUCCESS : PIG_COLOR.ERROR,
    SOW_EXPECTED_TRANSFER: PIG_COLOR.ERROR,
    SOW_WITHOUT_PIGLETS: PIG_COLOR.ERROR,
    SOW_WRONG_CYCLE: PIG_COLOR.ERROR
};

export const animalListMapSQL = { // lista zapytan dla komponentow wyswietlajacych zapytania na mapie, gdzie nazwa jest identyczna, aby je powiazac w latwy sposob (odwracanie obiektow)
    SOW_IDLE_DAYS: SOW_IDLE_DAYS_ANIMALS,
    SOW_AFTER_EXPECTED_FARROWING: SOW_AFTER_EXPECTED_FARROWING_ANIMALS,
    SOW_BEFORE_EXPECTED_FARROWING: SOW_BEFORE_EXPECTED_FARROWING_ANIMALS,
    SOW_REPETITIONS: SOW_REPETITIONS_ANIMALS,
    SOW_BEFORE_DELAYED_AFTER_PARTURITION: SOW_BEFORE_DELAYED_AFTER_PARTURITION_ANIMALS,
    SOW_EXPECTED_TRANSFER: SOW_EXPECTED_TRANSFER_ANIMALS,
    SOW_WITHOUT_PIGLETS: SOW_WITHOUT_PIGLETS_ANIMALS,
    SOW_WRONG_CYCLE: SOW_WRONG_CYCLE_ANIMALS,
    SOW_WITH_PIGLETS: SOW_WITH_PIGLETS_ANIMALS
};

export const animalListMapFields = {
    SOW_IDLE_DAYS: [{ translationKey: "sterilleDays", field: "value" }],
    SOW_AFTER_EXPECTED_FARROWING: [{ translationKey: "daysAfterExpectedParturition", field: "value" }],
    SOW_BEFORE_EXPECTED_FARROWING: [{ translationKey: "daysBeforeParturition", field: "value" }],
    SOW_REPETITIONS: [{ translationKey: "grid.repetition", field: "repetition" }],
    SOW_BEFORE_DELAYED_AFTER_PARTURITION: [{ translationKey: "expectedParturitionIn", field: "expectedFarrowing" }, { translationKey: "expectedRealParturitionDelay", field: "shiftFarrowDay" }],
    SOW_EXPECTED_TRANSFER: [{ translationKey: "delayWithDaysUnit", field: "days" }],
    SOW_WITHOUT_PIGLETS: [{ translationKey: "daysWithoutPiglets", field: "days" }],
    SOW_WRONG_CYCLE: [],
    SOW_WITH_PIGLETS: [{ translationKey: "pigletsAmount", field: "value" }],
};