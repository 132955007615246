import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    getAnimalEvents,
    getCommentList,
    getSelectedAnimalForDocuments
} from "../../../selectors/animalDocumentsSelectors";
import TableGrid from "../../basics/table-grid/TableGrid";
import moment from "moment";
import { AnimalTypes } from "../../../constans/animalTypes";
import { withTranslation } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import CollapsableContainer from "../containers/CollapsableContainer";
import { show } from "redux-modal";
import { ModalName as ConfirmModalName } from "../../modals-new/confirm-modal/ConfirmModal";
import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess
} from "../../../api/cycles/editSowCycle";
import EditCommentModal, { ModalName as EditCommentModalName } from "../../modals-new/edit-comment-modal/EditCommentModal";

function mapStateToProps(state) {
    return {
        comments: getCommentList(state),
        animal: getSelectedAnimalForDocuments(state),
        animalEvents: getAnimalEvents(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ show }, dispatch)
    }
}

class AnimalDocumentsComments extends Component {

    dateValueFormatter = value => moment(value).format("L");

    onRemoveClick = ({ object }) => {
        const { t, show, animal: { AnmNo1, AnmID }, animalEvents } = this.props;
        const comment = animalEvents.find((e) => e.EvID === object.EvID);
        show(ConfirmModalName, {
            title: t("removeEventForAnimalTitle", { animal: AnmNo1 }),
            text: t("removeEventForAnimalText", { animal: AnmNo1 }),
            confirmText: t("yes"),
            size: "lg",
            onConfirmed: (props) => {
                return editSowCycle({ AnmID, eventsToUpdate: [], eventsToDelete: [comment] }).then((res) => {
                    props.handleHide();
                    editSowCycleNotificationSuccess(res);
                }).catch((err) => {
                    editSowCycleNotificationFailure(err);
                });
            }
        });
    };

    onEditCommentClick = ({ object }) => {
        const { show } = this.props;
        show(EditCommentModalName, { comment: object });
    };

    render() {
        const { comments, animal, t } = this.props;
        const headers = animal?.AnimalKind === AnimalTypes.SOW ? [{
            name: t("printSelectedAnimalsModal.cycle"),
            field: "cycle"
        }] : [];
        headers.push(
            { name: t("printSelectedAnimalsModal.comments"), field: "comment" },
            { name: t("dateOfAddition"), field: "date", valueFormatter: this.dateValueFormatter },
            {
                name: "",
                component: (props) => <i className="fas fa-trash pointer" onClick={() => this.onRemoveClick(props)} />,
                headerClassName: "index",
                itemClassName: "index",
                notSortable: true,
            },
            {
                name: "",
                component: (props) => <i className="fas fa-edit pointer" onClick={() => this.onEditCommentClick(props)} />,
                headerClassName: "index",
                itemClassName: "index",
                notSortable: true,
            }
        );
        return (
            <>
                <CollapsableContainer.Card
                    id="cy-comments-card"
                    header={t("printSelectedAnimalsModal.comments")}
                    defaultExpanded={comments.length > 0}
                >
                    <TableGrid data={comments} headers={headers} shouldIndex />
                    <EditCommentModal />
                </CollapsableContainer.Card>
            </>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(AnimalDocumentsComments);
