import React, {useCallback} from "react";
import MenuItem from "../../../basics/menu/MenuItem";
import Menu from "../../../basics/menu/Menu";
import {show} from "redux-modal";
import {ModalName as ConfirmModalName} from "../../../modals-new/confirm-modal/ConfirmModal";
import {
    removeFile,
    removeFileNotificationFailure,
    removeFileNotificationSuccess,
} from "../../../../api/files/removeFile";
import {removeTaskUploadStatus} from "../../../../actions/taskActions";
import {downloadFile} from "../../../../api/files/downloadFile";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

export default function DesktopCalendarTaskDrawerFileMenu({
    menu,
    fileObject,
    FarmID,
}) {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const onRemoveClick = useCallback(() => {
        dispatch(
            show(ConfirmModalName, {
                title: t("removeFile", {name: fileObject.FileName}),
                text: t("removeFileText", {name: fileObject.FileName}),
                confirmText: t("yes"),
                onConfirmed: (props) => {
                    return removeFile(fileObject.FileID, {FarmID})
                        .then((res) => {
                            props.handleHide();
                            dispatch(
                                removeTaskUploadStatus(
                                    fileObject.FileID,
                                    fileObject.IssueID
                                )
                            );
                            removeFileNotificationSuccess(res);
                        })
                        .catch((e) => {
                            removeFileNotificationFailure(e);
                        });
                },
            })
        );
    }, [dispatch, fileObject, t, FarmID]);

    const onDownloadClick = useCallback(() => {
        downloadFile(fileObject);
    }, [fileObject]);

    return (
        <Menu event={menu}>
            <MenuItem
                icon={<i className="fa-solid fa-download fa-fw" />}
                onClick={onDownloadClick}>
                {t("newReports.headers.download")}
            </MenuItem>
            <MenuItem
                icon={<i className="fa-solid fa-trash fa-fw" />}
                onClick={onRemoveClick}>
                {t("delete")}
            </MenuItem>
        </Menu>
    );
}
