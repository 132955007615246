const initialValue = {
    activeNipples: 0,
    fall: 0,
    fallPiglets: 0,
    grafting: 0,
    graftingPiglets: 0,
    heat: 0,
    insemination: 0,
    mommyAdd: 0,
    mommyGet: 0,
    noPregnancy: 0,
    parturition: 0,
    selection: 0,
    sell: 0,
    separationToPiglethouse: 0,
    transfer: 0,
    usg: 0,
    weighting: 0,
    castration: 0,
    toInsemination: 0,
    toRepeat: 0,
    reclassify: 0,
    insertion: 0,
    treatment: 0,
    parturitionState: 0,
    comments: 0,
    tagging: 0,
    isMommy: 0,
    drafts: {},
};

export default function eventCounterReducer(state = initialValue, action) {
    switch (action.type) {
        case "ADD_ACTIVE_NIPPLES": {
            return {
                ...state,
                activeNipples: state.activeNipples + action.payload,
            };
        }
        case "ADD_FALL": {
            return {...state, fall: state.fall + action.payload};
        }
        case "ADD_FALL_PIGLETS": {
            return {
                ...state,
                graftingPiglets: state.graftingPiglets + action.payload,
            };
        }
        case "ADD_GRAFTING": {
            return {...state, grafting: state.grafting + action.payload};
        }
        case "ADD_GRAFTING_PIGLETS": {
            return {
                ...state,
                graftingPiglets: state.graftingPiglets + action.payload,
            };
        }
        case "ADD_HEAT": {
            return {...state, heat: state.heat + action.payload};
        }
        case "ADD_INSEMINATION": {
            return {
                ...state,
                insemination: state.insemination + action.payload,
            };
        }
        case "ADD_MOMMY_ADD": {
            return {...state, mommyAdd: state.mommyAdd + action.payload};
        }
        case "ADD_MOMMY_GET": {
            return {...state, mommyGet: state.mommyGet + action.payload};
        }
        case "ADD_NO_PREGNANCY": {
            return {...state, noPregnancy: state.noPregnancy + action.payload};
        }
        case "ADD_PARTURITION": {
            return {...state, parturition: state.parturition + action.payload};
        }
        case "ADD_SELECTION": {
            return {...state, selection: state.selection + action.payload};
        }
        case "ADD_SELL": {
            return {...state, sell: state.sell + action.payload};
        }
        case "ADD_SEPARATION_TO_PIGLETHOUSE": {
            return {
                ...state,
                separationToPiglethouse:
                    state.separationToPiglethouse + action.payload,
            };
        }
        case "ADD_TRANSFER": {
            return {...state, transfer: state.transfer + action.payload};
        }
        case "ADD_USG": {
            return {...state, usg: state.usg + action.payload};
        }
        case "ADD_WEIGHTING": {
            return {...state, weighting: state.weighting + action.payload};
        }
        case "ADD_CASTRATION": {
            return {...state, castration: state.castration + action.payload};
        }
        case "ADD_TO_INSEMINATION": {
            return {
                ...state,
                toInsemination: state.toInsemination + action.payload,
            };
        }
        case "ADD_RECLASSIFY": {
            return {...state, reclassify: state.reclassify + action.payload};
        }
        case "ADD_INSERTION": {
            return {...state, insertion: state.insertion + action.payload};
        }
        case "ADD_TREATMENT": {
            return {...state, treatment: state.treatment + action.payload};
        }
        case "ADD_PARTURITION_STATE": {
            return {
                ...state,
                parturitionState: state.parturitionState + action.payload,
            };
        }
        case "ADD_COMMENTS": {
            return {...state, comments: state.comments + action.payload};
        }
        case "ADD_TAGGING": {
            return {...state, tagging: state.tagging + action.payload};
        }
        case "ADD_IS_MOMMY": {
            return {...state, isMommy: state.isMommy + action.payload};
        }
        case "ADD_FOSTERING_DRAFT": {
            return {
                ...state,
                drafts: {...state.drafts, fostering: action.payload},
            };
        }
        case "REMOVE_DRAFT": {
            return {
                ...state,
                drafts: {...state.drafts, [action.payload]: undefined},
            };
        }
        case "USER_LOGOUT":
        case "CHANGE_FARM":
            return initialValue;
        default:
            return state;
    }
}
