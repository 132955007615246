import { getUserApiData } from "../../utils/UserUtils";
import { invokeApig } from "../../libs/awsLib";
import Paths from "../paths";
import { myID } from "../../libs/generateID";
import store from "../../store/store";
import { notify } from "reapop";
import i18n from "../../i18n";
import { createErrorNotification, createSuccessNotification } from "../../utils/ResponseUtils";

/**
 * @typedef NoPregnancyData
 * @type {object}
 * @property AnmID {string}     ID zwierzęcia
 * @property EvTime {number}    Czas wykonania zdarzenia
 * @property Reason {string}    Id powodu
 * @property GrID {string}      ID grupy, do której należało zwierzę
 * @property comment {string}   Komentarz
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego braku ciazy
 * @param data {[NoPregnancyData]}         Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createNoPregnancy(data, { FarmID, ClientID, LocalUserID } = {}) {
    const { _FarmID, _LocalUserID, _ClientID } = getUserApiData({ FarmID, ClientID, LocalUserID });
    return invokeApig({
        ...Paths.createNoPregnancy({ farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID }),
        body: data
    })
}

export function createNoPregnancyNotificationSuccess(response, { uniqueId = myID() } = {}) {
    if (createSuccessNotification(response, "apiNotifications.createNoPregnancyTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createNoPregnancyTitle"),
        message: i18n.t("apiNotifications.createNoPregnancySuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createNoPregnancyNotificationFailure(response, { uniqueId = myID() } = {}) {
    if (createErrorNotification(response, "apiNotifications.createNoPregnancyTitle", { uniqueId })) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createNoPregnancyTitle"),
        message: i18n.t("apiNotifications.createNoPregnancyFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
