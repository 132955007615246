import React, {Component} from "react";
import {isMobile} from "../../utils/MobileUtils";
import {setBackgroundColorInMeta} from "../../utils/ColorUtils";
import {ReactLocalStorage} from "../../utils/ReactLocalStorage";
import {connect} from "react-redux";
import {compose} from "redux";
import windowSize from "react-window-size";

function mapStateToProps(state) {
    return {
        user: state.user.user
    }
}

class ThemeProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: null,
            mobile: null
        };
        this.userChanged = false;
        this.sizeChanged = false;
    }

    componentWillUnmount() {
        document.removeEventListener("localStorageChange", this.onLocalStorageChange);
    }

    componentDidMount() {
        this.getTheme();
        document.addEventListener("localStorageChange", this.onLocalStorageChange);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {windowWidth, windowHeight, user} = this.props;
        this.userChanged = prevProps.user !== user;
        this.sizeChanged = (prevProps.windowHeight !== windowHeight) || (prevProps.windowWidth !== windowWidth);
        if (this.userChanged || this.sizeChanged) {
            this.getTheme();
        }
    }

    onLocalStorageChange = (e) => {
        if (e.key.includes(this.props.user.LocalUserID)) {
            this.getTheme();
        }
    }

    getLockScrollElement() {
        for (const el of document.body.classList.values()) {
            if (typeof el === "string" && el.startsWith("lock-scroll")) return ` ${el}`;
        }
        return "";
    }

    mediaEventListener = e => {
        const mobile = isMobile() ? " mobile" : "";
        const helper = document.body.classList.contains("helper") ? " helper" : "";
        const newColorScheme = e.matches ? "dark" : "normal";
        try {
            document.body.className = `theme theme-${newColorScheme}${mobile}${helper}${this.getLockScrollElement()}`;
        } catch (e) {
            console.error(e);
        }
    }

    getTheme = () => {
        try {
            const mobile = isMobile() ? " mobile" : "";
            const helper = document.body.classList.contains("helper") ? " helper" : "";
            let name = ReactLocalStorage.getPrivate("theme", "normal");
            if (name !== this.state.name || !!mobile !== this.state.mobile) {
                console.log(window.matchMedia('(prefers-color-scheme: dark)'));
                let toSet = name === "auto" ? window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "normal" : name;
                this.setState({name: name || "normal", mobile: !!mobile});
                try {
                    document.body.className = `theme theme-${toSet}${mobile}${helper}${this.getLockScrollElement()}`;
                } catch (e) {
                    console.error(e);
                }
                setBackgroundColorInMeta();
                if (name === "auto") {
                    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.mediaEventListener);
                } else {
                    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.mediaEventListener)
                }
            }
        } catch (error) {
            console.warn(error);
        }
    }

    render() {
        const {children} = this.props;
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
}


export default compose(
    connect(mapStateToProps),
    windowSize,
)(ThemeProvider);
