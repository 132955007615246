import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {showFile} from "../../../../../actions/filesActions";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import {compose} from "redux";

class HelperImage extends Component {

    onDoubleClick = () => {
        const {src, t} = this.props;
        this.props.dispatch(showFile({
            url: src,
            FileName: t("preview"),
            Type: "image",
            PreviewMode: true
        }, +new Date()));
    };

    render() {
        const {size, type, src, alt} = this.props;
        const className = ["fetura-helper-image pointer", size, type].join(" ");
        return (
            <div className={className} onDoubleClick={this.onDoubleClick}>
                <img className="w-100" alt={alt} src={src}/>
            </div>
        );
    }
}

HelperImage.propTypes = {
    type: PropTypes.oneOf(["bottom", "left"]),
    size: PropTypes.oneOf(["match-font", "full-width"]),
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};

HelperImage.defaultProps = {
    type: "bottom",
    size: "full-width"
}

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect()
)(HelperImage);
