export const GRID_INVENTORY_EMPTY = -1;
export const GRID_INVENTORY_AMNT = 0;
export const GRID_INVENTORY_PLCMNT = 1;

export const GRID_INVENTORY_TYPE = {
    hash: 0,
    number: 1,
    type: 2,
    localization: 3,
    weight: 4,
    numberOfAnimalsInGroup: 5,
    dateOfEntry: 6,
    dateOfCollapse: 7,
    price: 8,
    race: 9,
    vendor: 10,
};

export const DICTIONARY_TYPE = {
    forageType: "P",
    clients: "C",
    fallReasons: "F",
    noPreganancy: "N",
    graftingReason: "G",
    race: "R",
    medicine: "MD",
    default: "D",
    feedingCurves: "U",
    selectionReason: "S",
    employees: "E",
    suppliers: "L",
    incidentAnimal: "IA",
    incidentPlace: "IP",
    weighting: "W",
    controlLists: "CL",
    graftingProgram: "GP",
    forageIngredient: "FI",
    taskCategory: "TC",
    files: "FS",
    automatedAction: "AA",
    comments: "CM",
    crossBreeding: "CB",
    sellers: "SE",
};
