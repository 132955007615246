
export const InventoryColumnCodes = {
    NAME: 1,
    LOCATION: 2,
    HERD_NUMBER: 3,
    BREED: 4,
    BREED_CODE: 5,
    TATTOO:6,
    AGE:7,
};

export const InventoryMockValues = {
    [InventoryColumnCodes.NAME]: {
        name: "designation",
    },
    [InventoryColumnCodes.LOCATION]: {
        name: "grid.columnTypeName.3",
    },
    [InventoryColumnCodes.HERD_NUMBER]: {
        name: "herdNumber",
    },
    [InventoryColumnCodes.BREED]: {
        name: "race",
    },
    [InventoryColumnCodes.BREED_CODE]: {
        name: "raceNumberWithCode",
    },
    [InventoryColumnCodes.TATTOO]: {
        name: "tattooNumber",
    },
    [InventoryColumnCodes.AGE]: {
        name: "newSettings.breeding.graftingProgram.animalAge",
    },
};

export const DefaultInventoryValues = [1,2,7]

export const InventoryColumns = [
    {
        name: InventoryMockValues[InventoryColumnCodes.NAME].name,
        value: InventoryColumnCodes.NAME,
    },
    {
        name: InventoryMockValues[InventoryColumnCodes.LOCATION].name,
        value: InventoryColumnCodes.LOCATION,
    },
    {
        name: InventoryMockValues[InventoryColumnCodes.HERD_NUMBER].name,
        value: InventoryColumnCodes.HERD_NUMBER,
    },
    {
        name: InventoryMockValues[InventoryColumnCodes.BREED].name,
        value: InventoryColumnCodes.BREED,
    },
    {
        name: InventoryMockValues[InventoryColumnCodes.BREED_CODE].name,
        value: InventoryColumnCodes.BREED_CODE,
    },
    {
        name: InventoryMockValues[InventoryColumnCodes.TATTOO].name,
        value: InventoryColumnCodes.TATTOO,
    },
    {
        name: InventoryMockValues[InventoryColumnCodes.AGE].name,
        value: InventoryColumnCodes.AGE,
    },
];
