import Alert from "./Alert";
import i18next from "i18next";

export class AlertList {
    constructor() {
        this.list = {};
    }

    addAlert(code, deviceType, deviceId) {
        const alert = this.list[code] || new Alert(code);
        alert.add(deviceType, deviceId);
        this.list[code] = alert;
        return this;
    }

    isEmpty() {
        return Object.keys(this.list).length === 0
    }

    each(cb) {
        this.asList().forEach(cb);
    }

    mapEach(cb) {
        return this.asList().map(cb);
    }

    asList() {
        return Object.values(this.list);
    }
    hasError(code){
        return !!this.list[code]
    }

    groupByType() {
        const list = this.asList();
        const groupped = {};
        for (const alert of list) {
            const types = alert.getTypes();
            for (let type of types) {
                if (!groupped[type]) groupped[type] = [];
                groupped[type].push(alert);
            }
        }
        return Object.entries(groupped).map(([type, alerts]) => {
            return {
                key: type,
                name: i18next.t(`farms.tabs.mapTab.types.${type}`),
                alerts
            }
        })
    }
}

