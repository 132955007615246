import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import { lightLED } from "../../../../../IOT/device-functions/DispenserNRFFunctions";
import { changeMapShadowDelta } from "../../../../../actions/mapShadowActions";
import { changeShadowState } from "../../../../../actions/shadowsActions";
import { Milliseconds } from "../../../../../constans/milliseconds";
import store from "../../../../../store/store";
import { diodeColors, hexStringToNumeric } from "../../../../../utils/ColorUtils";

export default ({ t, devices, roleUtils, placementId, data }, updateShadowOn = "map") => {
    const hasFeedingWriteInLocation = roleUtils.hasRoleByPlacementID({
        roles: [RoleTypes.DEVICE_FEEDING_WRITE],
        placementId
    });
    const allowedDevices = devices.filter(({ device }) => device.DevType === DevTypes.DISPENSER_NRF);
    console.log(allowedDevices, devices, roleUtils, placementId, data);
    const FIND_LED_COLOUR = hexStringToNumeric(diodeColors[9]);
    let result = {
        key: "diode-control-panel",
        icon: {
            iconName: "fas fa-map-marker",
            name: t("settings.find"),
            description: t("settings.findByTurningOnLED")
        },
        show: allowedDevices.length > 0 && hasFeedingWriteInLocation
    }
    const lightLEDPayload = {
        colour: FIND_LED_COLOUR,
        time: 10 * Milliseconds.MINUTE,
        blink: 4
    };
    const isFindLedColourOnEveryDevice = allowedDevices.every((device) => {
        const key = device.device.DevID;
        return data[key]?.LED?.Colour === FIND_LED_COLOUR;
    });
    if (isFindLedColourOnEveryDevice) {
        lightLEDPayload.colour = 0;
        lightLEDPayload.time = 0;
        lightLEDPayload.blink = 0;
        result = {
            ...result,
            icon: {
                ...result.icon,
                name: t("settings.findTextDone"),
                iconName: "fas fa-check",
            },
            __alreadyFound__: true // i use it to change behaviour in parent component
        }
    }
    const { dispatch } = store;
    return {
        ...result,
        submit: () => {
            return new Promise((resolve, reject) => {
                lightLED(allowedDevices.map(({ device }) => device), lightLEDPayload, {
                    onSuccess: resolve,
                    onFailure: reject
                })
            }).then(() => {
                switch (updateShadowOn) {
                    case "map": {
                        const map = {};
                        for (const { device } of allowedDevices) {
                            map[device.DevID] = {
                                LED: lightLEDPayload.time ? {
                                    Colour: lightLEDPayload.colour,
                                    Blink: lightLEDPayload.blink,
                                    Time: lightLEDPayload.time
                                } : null
                            }
                        }
                        dispatch(changeMapShadowDelta(map));
                        break;
                    }
                    case "device": {
                        const map = new Map();
                        for (const { device } of allowedDevices)
                            map.set(device.DevID, {
                                diode: {
                                    setTime: Date.now(),
                                    ...lightLEDPayload
                                }
                            })
                        dispatch(changeShadowState(map, true));
                        break;
                    }
                    default:
                        break;
                }
            })
        }
    }
}
