import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import {connect} from "react-redux";
import {getCastrationData} from "../../../selectors/animalDocumentsSelectors";
import {Row} from "react-bootstrap";
import CastrationTaskItem from "./CastrationTaskItem";
import CollapsableContainer from "../containers/CollapsableContainer";

function mapStateToProps(state, props) {
    return {
        castrationData: getCastrationData(state, {cycleNumber: props.cycle})
    }
}

class CastrationTasksDone extends React.Component {

    hasSomeCastrationTaskDone() {
        const {castrationData = {}} = this.props;
        return Object.values(castrationData).some((task) => task.done);
    }

    render() {
        const {castrationData, t} = this.props;
        const castrationItems = [
            {
                key: "Castrate",
                name: t("castration"),
                icon: "fa-scalpel"
            },
            {
                key: "Tails",
                name: t("tails"),
                icon: "fa-cut"
            },
            {
                key: "Teeth",
                name: t("teeth"),
                icon: "fa-tooth"
            },
            {
                key: "Weighting",
                name: t("weighting"),
                icon: "fa-balance-scale-right"
            }
        ];
        return (
            <>
                <CollapsableContainer.Fieldset
                    id="cy-castration-card"
                    header={t("pigletsTreatment")}
                    defaultExpanded={this.hasSomeCastrationTaskDone()}
                >
                    <Row className={`castration-tasks`}>
                        {
                            castrationItems.map((item) =>
                                <CastrationTaskItem {...item} value={castrationData[item.key]}/>
                            )
                        }
                    </Row>
                </CollapsableContainer.Fieldset>
            </>
        );
    }

}

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect(mapStateToProps)
)(CastrationTasksDone);
