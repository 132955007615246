import Sheet from "./Sheet";
import {utils} from "xlsx"

export default class WorkBook {

    constructor() {
        Object.assign(this, utils.book_new());
        this.Props = {};
    }

    setTitle(title) {
        this.Props.Title = title;
    }

    setSubject(subject) {
        this.Props.Subject = subject;
    }

    setAuthor(author) {
        this.Props.Author = author;
    }

    setManager(manager) {
        this.Props.Manager = manager;
    }

    setCompany(company) {
        this.Props.Company = company;
    }

    setCategory(category) {
        this.Props.Category = category;
    }

    setKeywords(keywords) {
        this.Props.Keywords = keywords;
    }

    setComments(comments) {
        this.Props.Comments = comments;
    }

    setLastAuthor(lastAuthor) {
        this.Props.LastAuthor = lastAuthor;
    }

    setCreatedDate(createdDate) {
        this.Props.CreatedDate = createdDate;
    }

    setProps(props) {
        this.Props = props;
    }

    addSheet(name, data) {
        let sheet = new Sheet(data);
        utils.book_append_sheet(this, sheet, name);
        return sheet;
    }

}