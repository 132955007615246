import {getProjects as getProjectsAPI} from "../api/projects/getProjects";

export function getProjects() {
    return function (dispatch) {
        dispatch({
            type: "GET_PROJECTS",
            payload: getProjectsAPI()
        })
    }
}
