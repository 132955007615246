import {useField} from "formik";
import {useCallback} from "react";
import LabeledInput from "./labeled-input/LabeledInput";

export default function FormikLabeledInput({name, id, type, label}) {

    const [field, meta, helpers] = useField(name);

    const onChange = useCallback((value) => {
        helpers.setValue(type === "number" ? +value : value);
    }, [helpers, type]);

    const onFocus = useCallback((e) => {
        e.target.select();
    }, []);

    return (
        <LabeledInput label={label} type={type} {...field} name={name} {...meta} onChange={onChange} id={id}
                      onFocus={onFocus}/>
    )
}