import React from "react";
import Card from "../../components/basics/card/Card";
import Button from "../../components/basics/button/Button";
import Chrome1 from "../../resources/images/accept-cert/chrome-1.png"
import Chrome2 from "../../resources/images/accept-cert/chrome-2.png"
import Firefox1 from "../../resources/images/accept-cert/firefox-1.png"
import Firefox2 from "../../resources/images/accept-cert/firefox-2.png"
import ChromeMobile1 from "../../resources/images/accept-cert/chrome-mob-1.png";
import ChromeMobile2 from "../../resources/images/accept-cert/chrome-mob-2.png";
import FirefoxMob1 from "../../resources/images/accept-cert/firefox-mob-1.png"
import FirefoxMob2 from "../../resources/images/accept-cert/firefox-mob-2.png"
import SafariMob1 from "../../resources/images/accept-cert/safari-mob-1.png"
import SafariMob2 from "../../resources/images/accept-cert/safari-mob-2.png"
import {withRouter} from "react-router-dom";
import "./_accept-cert.scss";
import {detect} from "detect-browser";
import {Col, Row} from "react-bootstrap";
import {isMobile} from "../../utils/MobileUtils";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import {LOCAL_MQTT_ADDRESS} from "../../IOT/NewIOT";

export class AcceptCertView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            interval: null
        }
    }

    checkIfClosedWindow(win) {
        let interval = setInterval(() => {
            if (win.closed) {
                if (this.props.user.ClientID) {
                    this.props.history.goBack();
                } else {
                    this.props.history.push("/login");
                }
            }
        }, 100);
        this.setState({
            interval
        })
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    onButtonClick = () => {
        let newWindow = window.open(`https://${LOCAL_MQTT_ADDRESS}:9001`);
        this.checkIfClosedWindow(newWindow);
    };

    getScreenshots() {
        const browser = detect();
        switch (browser.name) {
            default:
            case "chrome":
                if (isMobile()) {
                    return (
                        <>
                            <img src={ChromeMobile1} alt="chrome1"/>
                            <img src={ChromeMobile2} alt="chrome2"/>
                        </>
                    )
                }
                return (
                    <>
                        <img src={Chrome1} alt="chrome1"/>
                        <img src={Chrome2} alt="chrome2"/>
                    </>
                );
            case "firefox":
                if (isMobile()) {
                    return (
                        <>
                            <img src={FirefoxMob1} alt="firefox1"/>
                            <img src={FirefoxMob2} alt="firefox2"/>
                        </>
                    )
                }
                return (
                    <>
                        <img src={Firefox1} alt="firefox1"/>
                        <img src={Firefox2} alt="firefox2"/>
                    </>
                );
            case "ios":
                return (
                    <>
                        <img src={SafariMob1} alt="safari1"/>
                        <img src={SafariMob2} alt="safari1"/>
                    </>
                )
        }
    }

    render() {
        const {t} = this.props;
        return (
            <div className="accept-cert">
                <Card>
                    <h2>{t("acceptCert.title")}</h2>
                    <ol>
                        <li>
                            <div>
                                {t("acceptCert.first")}
                            </div>
                            <Button onClick={this.onButtonClick}>{t("clickHere")}</Button>
                        </li>
                        <li>
                            {t("acceptCert.second")}
                        </li>
                    </ol>
                    <Row className="justify-content-center">
                        <Col md={6}>
                            {
                                this.getScreenshots()
                            }
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }

}

AcceptCertView = withRouter(AcceptCertView);
AcceptCertView = connect(state => ({
    user: state.user.user
}))(AcceptCertView);

export default withTranslation(NameSpaces.TRANSLATION)(AcceptCertView);
