import { getIOTAttributes } from "../../utils/IOTUtils";
import NewIOT from "../NewIOT";
import { RelayCommandTypes } from "../../constans/mqttMessages";
import i18next from "../../i18n";

export function setSingleRelay(dev, { relayIndex = 0, state = true } = {}, {
    onSuccess, onFailure,
    showNotification = true
} = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, RelayCommandTypes.SET_SINGLE_RELAY, {
            relayIndex,
            state
        }, showNotification ? {
            loading: {
                title: i18next.t("IOT.setSingleRelay"),
                message: i18next.t("IOT.setSingleRelayText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSingleRelayDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSingleRelayFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    } else if (onFailure) {
        onFailure(new Error('No MQTT params'));
    }
}