import Api from "../localization/ApiLanguage";
import {getTranslations} from "../api/translations/GetTranslations";
import {getLanguages as getLanguageList} from "../api/translations/getLanguages";
import {get} from "lodash";

export function fetchLanguage(lang) {

    return async function (dispatch) {
        dispatch({type: 'SET_LANGUAGE', payload: lang});
    }
}

export function getLanguages() {
    return {
        type: "GET_LANGUAGES", payload: Api.getLanguage()
    }
}

export function getTranslation(language, addLoading = true, forceFetch = false) {
    return function (dispatch, getState = "") {
        let body = [];
        let shouldInitialize = false;
        if (Array.isArray(language) && !language[0] && !language[1]) {
            shouldInitialize = true;
            const userLangs = get(getState(), "user.user.Langs", {Src: [], Dst: []});
            const source = userLangs.Src[0] === "*" ? "pl" : userLangs.Src[0];
            const destination = userLangs.Dst[0] === "*" ? "en" : userLangs.Dst[0];
            body.push(source, destination);
        } else {
            body = language;
        }
        dispatch({
            type: "GET_TRANSLATION",
            payload: getTranslations(body, {forceFetch}),
            meta: {addLoading, shouldInitialize, body}
        })
    }
}

export function getAllTranslationLanguages() {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_TRANSLATION_LANGUAGES",
            payload: getLanguageList()
        })
    }
}

export function addLanguage(language) {
    return function (dispatch) {
        dispatch({
            type: "ADD_LANGUAGE",
            payload: language
        })
    }
}

export function changeTranslation(locale, translation) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_TRANSLATION",
            payload: translation,
            meta: locale
        })
    }
}

export function changeHideAcceptedStatement(hideAccepted) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_HIDE_ACCEPTED_STATEMENT",
            payload: hideAccepted
        })
    }
}

export function changeSourceLanguage(language) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_SOURCE_LANGUAGE",
            payload: language
        })
    }
}

export function changeDestinationLanguage(language) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_DESTINATION_LANGUAGE",
            payload: language
        })
    }
}

export function changePluralsSource(plurals) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_PLURALS_SOURCE",
            payload: plurals
        })
    }
}

export function changePluralsDestination(plurals) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_PLURALS_DESTINATION",
            payload: plurals
        })
    }
}

export function deleteSegmentIndex(index) {
    return function (dispatch) {
        dispatch({
            type: "SET_DELETING_INDEX",
            payload: index
        })
    }
}

export function resetDeleteSegmentIndex(index) {
    return function (dispatch) {
        dispatch({
            type: "RESET_DELETING_INDEX",
            payload: index
        })
    }
}

export function updateSegment(segment) {
    return function (dispatch) {
        dispatch({
            type: "SET_UPDATING_SEGMENT",
            payload: segment
        })
    }
}

export function exchangeLanguages(source, destination, pluralsSource, pluralsDestination) {
    return function (dispatch) {
        dispatch({
            type: "EXCHANGE_LANGUAGES",
            payload: {source, destination, pluralsSource, pluralsDestination}
        })
    }
}
