import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_placeholder.scss";

class Placeholder extends Component {

    getLength = () => {
        const {length, type} = this.props;
        switch (type) {
            case "standing":
                return 17;
            case "feeding-simple":
                return 25;
            case "chart":
                return 9;
            case "settings-main-view":
                return 3;
            case "table":
                return 1 + (10 * 5)
            default:
                return length;
        }
    }

    render() {
        const {type} = this.props;
        const length = this.getLength();
        return (
            <div className={`fetura-placeholder type-${type}`}>
                {new Array(length).fill(0).map((o, i) => (
                    <div key={i} className={`ph-${i}`}>&nbsp;</div>
                ))}
            </div>
        );
    }
}


Placeholder.propTypes = {
    length: PropTypes.number,
    type: PropTypes.oneOf(["text", "standing", "feeding-simple", "chart", "settings-main-view", "table"])
    //todo: dodac zmienny background i color
};

Placeholder.defaultProps = {
    length: 2,
    type: "text"
}

export default Placeholder;
