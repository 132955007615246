import {convertLengthToBaseUnit, convertVolumeUnitTo, getUnit, convertLengthUnitTo} from "../UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";

export const UnitType = UnitTypes.BIG;

export const getLengthUnit = () => getUnit("length", UnitType);

export const toBaseUnit = (value) => convertLengthToBaseUnit(value, {fromUnit: UnitType});

export const toBigUnit = (value) => convertLengthUnitTo(value, {
    showUnit: false,
    rawValue: true,
    unit: UnitType,
    fixed: 3
})

export const volumeFormatter = (value, {showUnit = true, raw = false}={}) => {
    return convertVolumeUnitTo(value, {
        showUnit,
        unit: UnitType,
        fixed: 2,
        rawValue: !!raw
    });
}

export const getVolumeUnit = () => {
    return getUnit("volume", UnitType);
}
