import { getUserApiData } from "../../utils/UserUtils";
import { invokeApig } from "../../libs/awsLib";
import Paths from "../paths";
import { myID } from "../../libs/generateID";
import store from "../../store/store";
import { notify } from "reapop";
import i18n from "../../i18n";
import { createErrorNotification, createSuccessNotification } from "../../utils/ResponseUtils";

/**
 * @typedef USGData
 * @type {object}
 * @property AnmID {string}     ID zwierzęcia
 * @property EvTime {number}    Czas wykonania zdarzenia
 * @property Pregnant {bool}    Status badania USG
 * @property GrID {string}      ID grupy, do której należało zwierzę
 * @property comment {string}   Komentarz
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego badania USG
 * @param data {[USGData]}          Dane badania USG
 * @param FarmID {string}           ID fermy
 * @param ClientID {string}         ID klienta
 * @param LocalUserID {string}      ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createUSG(data, { FarmID, ClientID, LocalUserID } = {}) {
    const { _FarmID, _LocalUserID, _ClientID } = getUserApiData({ FarmID, ClientID, LocalUserID });
    return invokeApig({
        ...Paths.createUSG({ farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID }),
        body: data
    })
}

export function createUSGNotificationSuccess(response, { uniqueId = myID() } = {}) {
    if (createSuccessNotification(response, "apiNotifications.createUSGTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createUSGTitle"),
        message: i18n.t("apiNotifications.createUSGSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createUSGNotificationFailure(response, { uniqueId = myID() } = {}) {
    if (createErrorNotification(response, "apiNotifications.createUSGTitle", { uniqueId })) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createUSGTitle"),
        message: i18n.t("apiNotifications.createUSGFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
