import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export async function getFile(fileID, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    let res = await invokeApig({
        ...Paths.getFile({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {
            FileID: fileID
        }
    });
    return res.ResourceUrl;
}
