import React from "react";
import "./_farm-map-control-container.scss";

const FarmMapControlContainer = ({children, mobile}) => {
    return (
        <div
            className={mobile ? "fetura-farm-map__ctrl-container fetura-farm-map__ctrl-container--mobile" : "fetura-farm-map__ctrl-container"}>
            {children}
        </div>
    )
}

export default React.memo(FarmMapControlContainer);