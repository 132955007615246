export const initialValue = {
    countries: [],
    loadingCountries: false,
    selectedRegistrationOwnerAccount: null,
    selectedRegistrationOwnerAccountLoading: false
};

export default function registrationReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_COUNTRIES_DATA": {
            return {...state, loadingCountries: true};
        }
        case "SET_COUNTRIES_DATA": {
            return {...state, countries: action.payload.slice(0), loadingCountries: false};
        }
        case "RESET_COUNTRIES_DATA": {
            return {...state, countries: []};
        }
        case "SET_REGISTRATION_OWNER_ACCOUNT_PENDING": {
            return {...state, selectedRegistrationOwnerAccount: null, selectedRegistrationOwnerAccountLoading: true};
        }
        case "SET_REGISTRATION_OWNER_ACCOUNT_FULFILLED": {
            return {...state, selectedRegistrationOwnerAccount: action.payload, selectedRegistrationOwnerAccountLoading: false};
        }
        case "SET_REGISTRATION_OWNER_ACCOUNT_REJECTED": {
            return {...state, selectedRegistrationOwnerAccount: null, selectedRegistrationOwnerAccountLoading: false};
        }
        case "RESET_REGISTRATION_ACCOUNT": {
            return {...state, selectedRegistrationOwnerAccount: null}
        }
        default:
            return state;
    }
}
