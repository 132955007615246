export const LicPackageLevel = {
    NO_ACCESS: null,
    BASIC: "BASIC",
    EXTENDED: "EXTENDED"
};

export const LicPackageKeys = {
    ADMINISTRATION: "administration",
    ALARM: "alarms",
    CAGE: "cages",
    CHAIN: "chains",
    CLIMATE: "climates",
    COUNTER: "counters",
    DISPENSER_5G: "dispensers5G",
    DISPENSER: "dispensers",
    PORKER: "porkers",
    SMALL_CAGE: "smallCages",
    SILO: "siloses",
    SOW: "sows",
    FILES: "files",
    OCR: "ocr",
    FARM_MAP: "farmMap"
};
