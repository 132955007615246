export const logActionForCypress = (action) => {
    if (!!localStorage.getItem("isCypress")) {
        console.log(JSON.stringify(action, null, 2))
    }
}

export const isCypress = () => {
    return !!localStorage.getItem("isCypress")
}

export const isCypressOffline = () => {
    return !!localStorage.getItem("isCypressOffline")
}