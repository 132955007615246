import moment from "moment";

export function isUsingFakeData() {
    if (document.location.href.split("/")[3] === "CypressFarm1") {
        console.log("Fake!!!!!!!!!");
        return true;
    }
    return process.env.REACT_APP_STAGE === "demo";
}

export const getFakeExpeditions = () => {
    const date = moment.utc().startOf("day");
    return [{
        startDate: +date.clone().subtract(2, "day"),
        endDate: +date.clone().subtract(1, "day")
    }]
}
