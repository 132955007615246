import React, {Component} from "react";
import {connect} from "react-redux"
import TableGrid from "../../../components/basics/table-grid/TableGrid";
import {getLocationPathByPlcmntID, getPinnedRowSum} from "../../../utils/RaportsUtils";
import {FilterTypes} from "../../../constans/filter";
import {sortAsString} from "../../../utils/SortUtils";
import {withTranslation} from "react-i18next";
import ReportsContainer from "../ReportsContainer";
import AnimalReferenceDocumentModalGrid
    from "../../../components/grids/animal-reference-document/AnimalReferenceDocumentModalGrid";
import {reportType} from "../../../constans/reports";
import {isNil} from "lodash";
import Tooltip from "../../../components/basics/tooltip/Tooltip";
import {compose} from "redux";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import withRoles from "../../../components/withRoles";
import {LicPackageKeys, LicPackageLevel} from "../../../constans/roles";
import localReportDataDB from "../../../database/localReportDataDB";
import {getFirstIndexOfCycle} from "../../../selectors/settingsSelector";

export const IdleDaysHeaderComponent = ({tooltipContent, name}) => (
    <div>
        {name}&nbsp;
        <Tooltip tooltipContent={tooltipContent}>
            <i className="fas fa-info-circle"/>
        </Tooltip>
    </div>
);

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        reports: state.raports.reports,
        firstCycleIndex: getFirstIndexOfCycle(state)
    }
}

export class IdleDaysReport extends Component {

    state = {
        data: [],
        startDate: null,
        endDate: null
    };

    constructor(props) {
        super(props);

        const {match: {params: {id}}} = this.props;
        let report = this.props.reports.find(item => item.FeturaQuery.FeturaQTime === +id);
        if (report) {
            const data = localReportDataDB.getData(+id);
            const {FeturaQuery: {QueryParams: {startDate, endDate}}} = report;
            this.state = {
                data,
                startDate,
                endDate
            }
        }
    }

    rowClassName = object => {
        if (object.idleDays < 0) return "error";
        if (object.warning) return "warning";
    };

    avgFormatter(value) {
        return isNil(value) || isNaN(value) ? "-" : Math.round(value * 10) / 10
    }

    cycleFormatter = (number) => number ? number : '-';

    render() {
        const {t, name, firstCycleIndex} = this.props;
        const {data, startDate, endDate} = this.state;
        const numberOfColumns = Math.max(...data.map(a => a.cycle).filter(o => !!o));
        const headers = [
            {
                name: t("newReports.idle.pigNo"),
                field: "AnmNo1",
                filterType: FilterTypes.STRING,
                customSort: sortAsString,
                component: props => <AnimalReferenceDocumentModalGrid {...props}/>
            }
        ];
        for (let i = firstCycleIndex; i <= numberOfColumns; i++) {
            headers.push({
                name: t('newReports.idle.cycle', {cycle: i}),
                field: `idleDays${i}`,
                valueFormatter: this.cycleFormatter,
                excelFormatter: this.cycleFormatter,
                filterType: FilterTypes.NUMBER,
                headerComponent: <IdleDaysHeaderComponent name={t('newReports.idle.cycle', {cycle: i})}
                                                          tooltipContent={t("newReports.idle.cycleColumnInfo")}/>
            })
        }
        headers.push(...[
            {
                name: t('avg'),
                field: "avg",
                valueFormatter: this.cycleFormatter,
                excelFormatter: this.cycleFormatter,
                filterType: FilterTypes.NUMBER,
            },
            {
                name: t('newReports.idle.currentCycle'),
                field: "cycle",
                filterType: FilterTypes.NUMBER,
            },
            {
                name: t("location"),
                field: "location",
                valueFormatter: (value) => getLocationPathByPlcmntID(value),
                excelFormatter: (value) => getLocationPathByPlcmntID(value),
                filterType: FilterTypes.LOCATION,
                disableValueFormatterFilter: true
            }
        ]);
        return (
            <ReportsContainer name={name}>
                <TableGrid data={data} headers={headers}
                           bottomPinnedRows={(data) => {
                               const outputBottomPinnedRow = {};
                               Array.from({length: numberOfColumns}, (_, i) => i + 1)
                                   .forEach(number => {
                                       outputBottomPinnedRow[`idleDays${number}`] = this.avgFormatter((getPinnedRowSum(data, `idleDays${number}`) / data.filter(row => !!row[`idleDays${number}`]).length).toFixed(2));
                                   });
                               const sum = getPinnedRowSum(data, `avg`);
                               const rowLength = data.filter(row => !!row[`avg`]).length;
                               const value = +(sum / rowLength).toFixed(2);
                               return data.length ? [
                                   {
                                       ...outputBottomPinnedRow,
                                       [`avg`]: (sum > 0 && rowLength > 0) ? value : "-",
                                       AnmNo1: t("avg"),
                                   }
                               ] : []
                           }}
                           rowClassName={this.rowClassName}
                           saveToExcel={true}
                           isSortable={true}
                           shouldIndex={true}
                           showFilter={true}
                           name={reportType.IDLE}
                           excelFileName={name}
                           initialSortColumn={headers[0].name}
                           startDate={startDate}
                           endDate={endDate}
                           paginationItems={50}
                           showPagination
                />
            </ReportsContainer>
        )
    }
}

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect(mapStateToProps),
    withRoles({
        showComponent: true,
        showServiceWarning: true,
        clientPackage: {[LicPackageKeys.SOW]: LicPackageLevel.BASIC}
    })
)(IdleDaysReport);
