import NewIOT from "../IOT/NewIOT";
import {getUploadStatus, getProgramDetails as getProgramDetailsMQTT} from "../IOT/device-functions/GatewayFunctions";

export function setDebugGateway(gateway, getShadow = true) {
    return function (dispatch) {
        dispatch({
            type: "SET_DEBUG_GATEWAY",
            payload: gateway
        });
        if (gateway && getShadow) {
            NewIOT.startSendingDeviceState(gateway);
        }
    }
}

export function getUploadDeviceStatus(gateway) {
    return function (dispatch) {
        dispatch({
            type: "GET_UPLOAD_DEVICE_STATUS",
            payload: getUploadStatus(gateway),
            meta: {
                DevID: gateway.DevID
            }
        })
    }
}

export function getProgramDetails(gateway, DevIDs) {
    return function (dispatch) {
        dispatch({
            type: "GET_PROGRAM_DETAILS",
            payload: getProgramDetailsMQTT(gateway, DevIDs),
            meta: {GwID: gateway}
        })
    }
}

export function getUploadDeviceStatusDelta(CAnsw, GwID) {
    return function (dispatch) {
        dispatch({
            type: "GET_UPLOAD_DEVICE_STATUS_DELTA",
            payload: CAnsw,
            meta: {
                GwID
            }
        })
    }
}
