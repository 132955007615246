import i18n from "../i18n";

export const initialValue = {
    lang: {},
    langDynamic: [],
    supportedLanguages: [],
    locale: "pl",
    fetching: false,
    fetched: false,
    error: null,
    farmTreeDisplay: false,
    translations: {
        content: {},
        acceptance: {}
    },
    availableTranslationLanguages: [],
    loading: {
        translationLanguages: false,
        translations: false
    },
    hideAccepted: true,
    source: "",
    destination: "",
    pluralsSource: [],
    pluralsDestination: [],
    deletingIndexes: [],
    updatingSegments: [],
    acceptedSegments: []
};

export default function localizationReducer(state = initialValue, action) {

    switch (action.type) {
        case "FETCH_LANGUAGE": {
            return {...state, fetching: true}
        }
        case "FETCH_LANGUAGE_REJECTED": {
            return {...state, fetching: false, error: action.payload}
        }
        case "FETCH_LANGUAGE_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                lang: action.payload,
            }
        }
        case "SET_LANGUAGE": {
            return {
                ...state,
                locale: action.payload,
                lang: {...state.lang, lang: action.payload}
            }
        }
        case "GET_LANGUAGE_CONTENT_FULFILLED": {
            return {...state, lang: action.payload,}
        }
        case "GET_LANGUAGE_CONTENT_DYNAMIC": {
            return {...state, langDynamic: action.payload,}
        }
        case "GET_LANGUAGES": {
            return {...state, supportedLanguages: action.payload,}
        }
        case "GET_TRANSLATION_PENDING": {
            return {...state, loading: {...state.loading, translations: action.meta.addLoading}}
        }
        case "GET_TRANSLATION_REJECTED": {
            return {...state, loading: {...state.loading, translations: false}, updatingSegments: []}
        }
        case "GET_TRANSLATION_FULFILLED": {
            const stateObject = {
                ...state,
                translations: {
                    content: {
                        ...state.translations.content,
                        ...action.payload.content
                    },
                    acceptance: {
                        ...state.translations.acceptance,
                        ...action.payload.acceptance
                    }
                },
                loading: {...state.loading, translations: false},
                updatingSegments: []
            };
            const {shouldInitialize, body} = action.meta;
            if (shouldInitialize) {
                stateObject.source = body[0];
                stateObject.destination = body[1];
                stateObject.pluralsSource = i18n.services.pluralResolver.getRule(body[0])?.numbers || [];
                stateObject.pluralsDestination = i18n.services.pluralResolver.getRule(body[1])?.numbers || [];
            }
            return stateObject;
        }
        case "GET_ALL_TRANSLATION_LANGUAGES_PENDING": {
            return {...state, loading: {...state.loading, translationLanguages: true}}
        }
        case "GET_ALL_TRANSLATION_LANGUAGES_REJECTED": {
            return {...state, loading: {...state.loading, translationLanguages: false}}
        }
        case "GET_ALL_TRANSLATION_LANGUAGES_FULFILLED": {
            return {
                ...state,
                availableTranslationLanguages: action.payload.supported,
                loading: {...state.loading, translationLanguages: false},
            }
        }
        case "ADD_LANGUAGE": {
            const availableTranslationLanguages = state.availableTranslationLanguages.slice(0);
            availableTranslationLanguages.push(action.payload);
            return {...state, availableTranslationLanguages}
        }
        case "CHANGE_TRANSLATION": {
            return {
                ...state,
                translations: {
                    ...state.translations,
                    [action.meta]: action.payload
                }
            }
        }
        case "CHANGE_HIDE_ACCEPTED_STATEMENT": {
            return {
                ...state,
                hideAccepted: action.payload
            }
        }
        case "CHANGE_SOURCE_LANGUAGE": {
            return {
                ...state,
                source: action.payload
            }
        }
        case "CHANGE_DESTINATION_LANGUAGE": {
            return {
                ...state,
                destination: action.payload
            }
        }
        case "CHANGE_PLURALS_SOURCE": {
            const _pluralsSource = action.payload.slice(0);
            return {
                ...state,
                pluralsSource: _pluralsSource
            }
        }
        case "CHANGE_PLURALS_DESTINATION": {
            const _pluralsDestination = action.payload.slice(0);
            return {
                ...state,
                pluralsDestination: _pluralsDestination
            }
        }
        case "SET_DELETING_INDEX": {
            const {deletingIndexes} = state;
            const _deletingIndexes = deletingIndexes.slice(0);
            _deletingIndexes.push(action.payload);
            return {
                ...state,
                deletingIndexes: _deletingIndexes
            }
        }
        case "RESET_DELETING_INDEX": {
            const {deletingIndexes} = state;
            const _deletingIndexes = deletingIndexes.filter((index) => index !== action.payload);
            return {
                ...state,
                deletingIndexes: _deletingIndexes
            }
        }
        case "SET_UPDATING_SEGMENT": {
            const {updatingSegments} = state;
            const _updatingSegments = updatingSegments.slice(0);
            _updatingSegments.push(action.payload);
            return {
                ...state,
                updatingSegments: _updatingSegments
            }
        }
        case "EXCHANGE_LANGUAGES": {
            const {source, destination, pluralsSource, pluralsDestination} = action.payload;
            return {
                ...state,
                source,
                destination,
                pluralsSource: pluralsSource.slice(0),
                pluralsDestination: pluralsDestination.slice(0)
            }
        }
        default:
            return state
    }
}
