import React, {
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {Col, Collapse, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import fetchData from "../../../api/fetch-suspense-wrapper/FetchSuspenseWrapper";
import {SowResultsTypes} from "../../../constans/resultsTypes";
import {
    sowResultSelects,
    sowResultsAutoRange,
    sowResultsInfo,
} from "../../../constans/sowCardSelects";
import useQuery from "../../../hooks/useQuery";
import useQueryCaller from "../../../hooks/useQueryCaller";
import {getAnimalParametersFirstFetching} from "../../../selectors/animalParametersSelector";
import {getPercent} from "../../../utils/GaugeUtils";
import {isMobile} from "../../../utils/MobileUtils";
import {
    getAnimalUnit,
    getAverageIdleDaysRangeValues,
    getAverageLactationDaysValues,
    getAverageParturitionWeightRangeValues,
    getAverageSeparationWeightRangeValues,
    getHealthyPigletsPerLitterRangeValues,
    getParturitionIndexRangeValues,
    getPigletMortality,
    getSeparatedPigletsPerLitterRangeValues,
} from "../../../utils/SettingsUtils";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import Gauge from "../../basics/gauge/Gauge";
import TableGrid from "../../basics/table-grid/TableGrid";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import LoadingComponent from "../../loading/LoadingComponent";
import CollapsableContainer from "../containers/CollapsableContainer";
import "./_sow-results.scss";
import {useCalculateAnimalParams} from "../../../hooks/useCalculateAnimalParams";

function createResource(caller, AnmID, sqlKey) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            caller(sowResultSelects[sqlKey], [AnmID, AnmID])
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    });
}

function GaugeItemRenderer({
    label,
    min,
    max,
    isSelected,
    reverse,
    valueFormatter,
    sideToSide,
    type,
    resource,
    onClick: onClickProps,
}) {
    const data = resource.read();

    const percent = useMemo(() => {
        return getPercent(data, min, max, reverse);
    }, [data, min, max, reverse]);

    const onClick = useCallback(() => {
        onClickProps((prev) => (prev !== type ? type : null));
    }, [onClickProps, type]);

    return (
        <Col xs={6} xl={3} className="d-flex justify-content-center">
            <Gauge
                label={label}
                percent={percent}
                min={min}
                max={max}
                onClick={onClick}
                showLegend
                isSelected={isSelected}
                value={data}
                valueFormatter={valueFormatter}
                sideToSide={sideToSide}
            />
        </Col>
    );
}

function GaugeItem({
    sqlKey,
    queryCaller,
    AnmID,
    label,
    min,
    max,
    sideToSide,
    reverse,
    valueFormatter,
    onClick,
    type,
    selected,
}) {
    const resource = useMemo(() => {
        return fetchData(createResource(queryCaller, AnmID, sqlKey));
    }, [sqlKey, AnmID]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Suspense fallback={<div />}>
            <GaugeItemRenderer
                resource={resource}
                label={label}
                min={min}
                max={max}
                sideToSide={sideToSide}
                reverse={reverse}
                valueFormatter={valueFormatter}
                onClick={onClick}
                type={type}
                isSelected={type === selected}
            />
        </Suspense>
    );
}

function ParturitionIndexItem({queryCaller, AnmID, onClick, selected}) {
    const {t} = useTranslation();

    const [{min, max}, setMinMax] = useState({min: 0, max: 0});

    useEffect(() => {
        const range = getParturitionIndexRangeValues();
        if (range[0] !== "auto" && range[1] !== "auto") {
            setMinMax({min: range[0], max: range[1]});
            return;
        }

        async function fetchData() {
            try {
                const max = await queryCaller(
                    sowResultsAutoRange.animalIndexMax
                );
                setMinMax({min: 0, max});
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [queryCaller]);

    return (
        <GaugeItem
            label={t("newReports.ranking.parturitionIndex")}
            min={min}
            max={max}
            queryCaller={queryCaller}
            AnmID={AnmID}
            sqlKey={"animalIndex"}
            onClick={onClick}
            type={SowResultsTypes.PARTURITION_INDEX}
            selected={selected}
        />
    );
}

function HealthyPigletsPerLitterItem({queryCaller, AnmID, onClick, selected}) {
    const {t} = useTranslation();

    const [{min, max}, setMinMax] = useState({min: 0, max: 0});

    useEffect(() => {
        const range = getHealthyPigletsPerLitterRangeValues();
        if (range[0] !== "auto" && range[1] !== "auto") {
            setMinMax({min: range[0], max: range[1]});
            return;
        }

        async function fetchData() {
            try {
                const max = await queryCaller(
                    sowResultsAutoRange.averageLiveBornMax
                );
                setMinMax({min: 0, max});
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [queryCaller]);

    return (
        <GaugeItem
            label={t("sowResults.healthyPigletsPerLitter")}
            min={min}
            max={max}
            queryCaller={queryCaller}
            AnmID={AnmID}
            sqlKey={"averageLiveBorn"}
            onClick={onClick}
            type={SowResultsTypes.HEALTHY_PIGLETS_PER_LITTER}
            selected={selected}
        />
    );
}

function AverageParturitionWeightItem({
    queryCaller,
    AnmID,
    valueFormatter,
    onClick,
    selected,
}) {
    const {t} = useTranslation();

    const [{min, max}, setMinMax] = useState({min: 0, max: 0});

    useEffect(() => {
        const range = getAverageParturitionWeightRangeValues();
        if (range[0] !== "auto" && range[1] !== "auto") {
            setMinMax({min: range[0], max: range[1]});
            return;
        }

        async function fetchData() {
            try {
                const max = await queryCaller(
                    sowResultsAutoRange.averageBornWeightMax
                );
                setMinMax({min: 0, max});
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [queryCaller]);

    return (
        <GaugeItem
            label={t("sowResults.avgParturitionWeight")}
            min={min}
            max={max}
            queryCaller={queryCaller}
            AnmID={AnmID}
            sqlKey={"averageBornWeight"}
            valueFormatter={valueFormatter}
            onClick={onClick}
            type={SowResultsTypes.AVG_PARTURITION_WEIGHT}
            selected={selected}
        />
    );
}

function SeparatedPigletPerLitterItem({queryCaller, AnmID, onClick, selected}) {
    const {t} = useTranslation();

    const [{min, max}, setMinMax] = useState({min: 0, max: 0});

    useEffect(() => {
        const range = getSeparatedPigletsPerLitterRangeValues();
        if (range[0] !== "auto" && range[1] !== "auto") {
            setMinMax({min: range[0], max: range[1]});
            return;
        }

        async function fetchData() {
            try {
                const max = await queryCaller(
                    sowResultsAutoRange.averageWeanedPigletsMax
                );
                setMinMax({min: 0, max});
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [queryCaller]);

    return (
        <GaugeItem
            label={t("sowResults.separatedPigletsPerLitter")}
            min={min}
            max={max}
            queryCaller={queryCaller}
            AnmID={AnmID}
            sqlKey={"averageWeanedPiglets"}
            onClick={onClick}
            type={SowResultsTypes.SEPARATED_PIGLETS_PER_LITTER}
            selected={selected}
        />
    );
}

function AverageSeparatedWeightItem({
    queryCaller,
    AnmID,
    valueFormatter,
    onClick,
    selected,
}) {
    const {t} = useTranslation();

    const [{min, max}, setMinMax] = useState({min: 0, max: 0});

    useEffect(() => {
        const range = getAverageSeparationWeightRangeValues();
        if (range[0] !== "auto" && range[1] !== "auto") {
            setMinMax({min: range[0], max: range[1]});
            return;
        }

        async function fetchData() {
            try {
                const max = await queryCaller(
                    sowResultsAutoRange.averageWeanedWeightMax
                );
                setMinMax({min: 0, max});
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [queryCaller]);

    return (
        <GaugeItem
            label={t("sowResults.avgSeparationWeight")}
            min={min}
            max={max}
            queryCaller={queryCaller}
            AnmID={AnmID}
            sqlKey={"averageWeanedWeight"}
            valueFormatter={valueFormatter}
            onClick={onClick}
            type={SowResultsTypes.AVG_SEPARATION_WEIGHT}
            selected={selected}
        />
    );
}

function IdleDaysItem({queryCaller, AnmID, onClick, selected}) {
    const {t} = useTranslation();

    const [{min, max}, setMinMax] = useState({min: 0, max: 0});

    useEffect(() => {
        const range = getAverageIdleDaysRangeValues();
        if (range[0] !== "auto" && range[1] !== "auto") {
            setMinMax({min: range[0], max: range[1]});
            return;
        }

        async function fetchData() {
            try {
                const max = await queryCaller(
                    sowResultsAutoRange.averageIdleDaysMax
                );
                setMinMax({min: 0, max});
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [queryCaller]);

    return (
        <GaugeItem
            label={t("sowResults.avgIdleDays")}
            min={min}
            max={max}
            queryCaller={queryCaller}
            AnmID={AnmID}
            sqlKey={"averageIdleDays"}
            onClick={onClick}
            type={SowResultsTypes.AVG_IDLE_DAYS}
            selected={selected}
        />
    );
}

function AverageLactationDaysItem({queryCaller, AnmID, onClick, selected}) {
    const {t} = useTranslation();

    const [{min, max}, setMinMax] = useState({min: 0, max: 0});

    useEffect(() => {
        const range = getAverageLactationDaysValues();
        if (range[0] !== "auto" && range[1] !== "auto") {
            setMinMax({min: range[0], max: range[1]});
            return;
        }

        async function fetchData() {
            try {
                const max = await queryCaller(
                    sowResultsAutoRange.averageLactationDaysMax
                );
                setMinMax({min: 0, max});
            } catch (e) {
                console.error(e);
            }
        }
        fetchData();
    }, [queryCaller]);

    return (
        <GaugeItem
            label={t("sowResults.avgLactationDays")}
            min={min}
            max={max}
            queryCaller={queryCaller}
            AnmID={AnmID}
            sqlKey={"averageLactationDays"}
            sideToSide
            onClick={onClick}
            type={SowResultsTypes.AVG_LACTATION_DAYS}
            selected={selected}
        />
    );
}

function PigletsMortalityItem({
    queryCaller,
    AnmID,
    valueFormatter,
    onClick,
    selected,
}) {
    const {t} = useTranslation();

    const {min, max} = useMemo(() => {
        const range = getPigletMortality();
        return {min: range[1], max: range[0]};
    }, []);

    return (
        <GaugeItem
            label={t("planTypes.pigletsMortality")}
            min={min}
            max={max}
            queryCaller={queryCaller}
            AnmID={AnmID}
            sqlKey={"pigletMortality"}
            valueFormatter={valueFormatter}
            onClick={onClick}
            type={SowResultsTypes.PIGLETS_MORTALITY}
            selected={selected}
        />
    );
}

function IndexInfo({queryCaller, AnmID}) {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const mobile = useRef(isMobile());

    const headers = useMemo(() => {
        return [
            {name: t("group"), field: "group"},
            {name: t("printSelectedAnimalsModal.cycle"), field: "cycle"},
            {name: t("sowResults.productiveDays"), field: "productive"},
            {name: t("gestationDays"), field: "gestation"},
            {name: t("sowResults.lactationDays"), field: "lactation"},
            {name: t("idle"), field: "idle"},
        ];
    }, [t]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await queryCaller(sowResultsInfo.indexInfo, [
                    AnmID,
                ]);
                setData(res);
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }

        fetchData();
    }, [AnmID, queryCaller]);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loading} />
            <TableGrid
                headers={headers}
                data={data}
                showPagination={!mobile.current}
                paginationItems={10}
                scrollOnPageChange={false}
                mobileRow={<DefaultMobileRow />}
            />
        </div>
    );
}

function BornPerLitterInfo({queryCaller, AnmID}) {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const mobile = useRef(isMobile());

    const headers = useMemo(() => {
        return [
            {name: t("group"), field: "group"},
            {name: t("printSelectedAnimalsModal.cycle"), field: "cycle"},
            {name: t("alive"), field: "born"},
            {name: t("dead"), field: "dead"},
            {name: t("mummies"), field: "mummy"},
            {name: t("weakPiglets"), field: "weak"},
            {name: t("gilts"), field: "gilts"},
        ];
    }, [t]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await queryCaller(sowResultsInfo.liveBornInfo, [
                    AnmID,
                ]);
                setData(res);
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }

        fetchData();
    }, [AnmID, queryCaller]);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loading} />
            <TableGrid
                headers={headers}
                data={data}
                showPagination={!mobile.current}
                paginationItems={10}
                scrollOnPageChange={false}
                mobileRow={<DefaultMobileRow />}
            />
        </div>
    );
}

function BornWeightInfo({queryCaller, AnmID, weightFormatter}) {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const mobile = useRef(isMobile());

    const headers = useMemo(() => {
        return [
            {name: t("group"), field: "group"},
            {name: t("printSelectedAnimalsModal.cycle"), field: "cycle"},
            {
                name: t("sowResults.avgParturitionWeight"),
                field: "weight",
                valueFormatter: weightFormatter,
            },
            {name: t("alive"), field: "born"},
        ];
    }, [weightFormatter, t]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await queryCaller(sowResultsInfo.bornWeightInfo, [
                    AnmID,
                ]);
                setData(res);
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }

        fetchData();
    }, [AnmID, queryCaller]);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loading} />
            <TableGrid
                headers={headers}
                data={data}
                showPagination={!mobile.current}
                paginationItems={10}
                scrollOnPageChange={false}
                mobileRow={<DefaultMobileRow />}
            />
        </div>
    );
}

function SeparatePerLitterInfo({queryCaller, AnmID}) {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const mobile = useRef(isMobile());

    const headers = useMemo(() => {
        return [
            {name: t("group"), field: "group"},
            {name: t("printSelectedAnimalsModal.cycle"), field: "cycle"},
            {
                name: t("newReports.rankingReport.separatedPiglets"),
                field: "weaned",
            },
            {name: t("alive"), field: "born"},
            {name: t("newReports.rankingReport.fallsAmount"), field: "fall"},
            {name: t("separationToMommy"), field: "fostering"},
        ];
    }, [t]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await queryCaller(
                    sowResultsInfo.weanedPigletsInfo,
                    [AnmID]
                );
                setData(res);
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }

        fetchData();
    }, [AnmID, queryCaller]);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loading} />
            <TableGrid
                headers={headers}
                data={data}
                showPagination={!mobile.current}
                paginationItems={10}
                scrollOnPageChange={false}
                mobileRow={<DefaultMobileRow />}
            />
        </div>
    );
}

function SeparatedWeightInfo({queryCaller, AnmID, weightFormatter}) {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const mobile = useRef(isMobile());

    const headers = useMemo(() => {
        return [
            {name: t("group"), field: "group"},
            {name: t("printSelectedAnimalsModal.cycle"), field: "cycle"},
            {
                name: t("sowResults.avgSeparationWeight"),
                field: "weight",
                valueFormatter: weightFormatter,
            },
            {
                name: t("newReports.rankingReport.separatedPiglets"),
                field: "weaned",
            },
            {name: t("activeNipples"), field: "active"},
        ];
    }, [weightFormatter, t]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await queryCaller(sowResultsInfo.weanedWeightInfo, [
                    AnmID,
                ]);
                setData(res);
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }

        fetchData();
    }, [AnmID, queryCaller]);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loading} />
            <TableGrid
                headers={headers}
                data={data}
                showPagination={!mobile.current}
                paginationItems={10}
                scrollOnPageChange={false}
                mobileRow={<DefaultMobileRow />}
            />
        </div>
    );
}

function IdleDaysInfo({queryCaller, AnmID}) {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const mobile = useRef(isMobile());

    const headers = useMemo(() => {
        return [
            {name: t("group"), field: "group"},
            {name: t("printSelectedAnimalsModal.cycle"), field: "cycle"},
            {name: t("idle"), field: "idle"},
        ];
    }, [t]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await queryCaller(sowResultsInfo.idleDaysInfo, [
                    AnmID,
                ]);
                setData(res);
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }

        fetchData();
    }, [AnmID, queryCaller]);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loading} />
            <TableGrid
                headers={headers}
                data={data}
                showPagination={!mobile.current}
                paginationItems={10}
                scrollOnPageChange={false}
                mobileRow={<DefaultMobileRow />}
            />
        </div>
    );
}

function LactationDaysInfo({queryCaller, AnmID}) {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const mobile = useRef(isMobile());

    const headers = useMemo(() => {
        return [
            {name: t("group"), field: "group"},
            {name: t("printSelectedAnimalsModal.cycle"), field: "cycle"},
            {name: t("sowResults.lactationDays"), field: "lactation"},
            {name: t("daysAsMommy"), field: "mommy"},
        ];
    }, [t]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await queryCaller(
                    sowResultsInfo.lactationDaysInfo,
                    [AnmID]
                );
                setData(res);
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }

        fetchData();
    }, [AnmID, queryCaller]);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loading} />
            <TableGrid
                headers={headers}
                data={data}
                showPagination={!mobile.current}
                paginationItems={10}
                scrollOnPageChange={false}
                mobileRow={<DefaultMobileRow />}
            />
        </div>
    );
}

function PigletMortalityInfo({queryCaller, AnmID}) {
    const {t} = useTranslation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const mobile = useRef(isMobile());

    const headers = useMemo(() => {
        return [
            {name: t("group"), field: "group"},
            {name: t("printSelectedAnimalsModal.cycle"), field: "cycle"},
            {name: t("alive"), field: "born"},
            {name: t("fallPiglets"), field: "dead"},
        ];
    }, [t]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await queryCaller(
                    sowResultsInfo.pigletMortalityInfo,
                    [AnmID]
                );
                setData(res);
            } catch (e) {
                console.error(e);
            }
            setLoading(false);
        }

        fetchData();
    }, [AnmID, queryCaller]);

    return (
        <div className="position-relative">
            <LoadingComponent isLoading={loading} />
            <TableGrid
                headers={headers}
                data={data}
                showPagination={!mobile.current}
                paginationItems={10}
                scrollOnPageChange={false}
                mobileRow={<DefaultMobileRow />}
            />
        </div>
    );
}

function SowResultsAdditionalInfo({type, queryCaller, AnmID, weightFormatter}) {
    return (
        <div className="mt-4">
            {type === SowResultsTypes.PARTURITION_INDEX && (
                <IndexInfo queryCaller={queryCaller} AnmID={AnmID} />
            )}
            {type === SowResultsTypes.HEALTHY_PIGLETS_PER_LITTER && (
                <BornPerLitterInfo queryCaller={queryCaller} AnmID={AnmID} />
            )}
            {type === SowResultsTypes.AVG_PARTURITION_WEIGHT && (
                <BornWeightInfo
                    queryCaller={queryCaller}
                    AnmID={AnmID}
                    weightFormatter={weightFormatter}
                />
            )}
            {type === SowResultsTypes.SEPARATED_PIGLETS_PER_LITTER && (
                <SeparatePerLitterInfo
                    queryCaller={queryCaller}
                    AnmID={AnmID}
                />
            )}
            {type === SowResultsTypes.AVG_SEPARATION_WEIGHT && (
                <SeparatedWeightInfo
                    queryCaller={queryCaller}
                    AnmID={AnmID}
                    weightFormatter={weightFormatter}
                />
            )}
            {type === SowResultsTypes.AVG_IDLE_DAYS && (
                <IdleDaysInfo queryCaller={queryCaller} AnmID={AnmID} />
            )}
            {type === SowResultsTypes.AVG_LACTATION_DAYS && (
                <LactationDaysInfo queryCaller={queryCaller} AnmID={AnmID} />
            )}
            {type === SowResultsTypes.PIGLETS_MORTALITY && (
                <PigletMortalityInfo queryCaller={queryCaller} AnmID={AnmID} />
            )}
        </div>
    );
}

export default function SowResults() {
    const {t} = useTranslation();

    const [type, setType] = useState(null);

    const firstFetchingParams = useSelector(getAnimalParametersFirstFetching);

    const animalUnit = useRef(getAnimalUnit());

    const queryCaller = useQueryCaller();

    const query = useQuery();

    const AnmID = useMemo(() => {
        return query.get("documentsAnimal");
    }, [query]);

    const fetchingAnimalParams = useCalculateAnimalParams(AnmID);

    const weightFormatter = useCallback((value) => {
        return convertWeightUnitTo(value, {
            unit: animalUnit.current,
            showUnit: true,
            fixed: 1,
        });
    }, []);

    const percentFormatter = useCallback((value) => {
        if (value === null) return "-";
        return `${value.toFixed(2)}%`;
    }, []);

    return (
        <CollapsableContainer.Card
            className="sow-results"
            header={t("results.results")}
            defaultExpanded={true}>
            {(firstFetchingParams || fetchingAnimalParams) && (
                <LoadingComponent isLoading />
            )}
            {!firstFetchingParams && !fetchingAnimalParams && (
                <Row>
                    <ParturitionIndexItem
                        AnmID={AnmID}
                        queryCaller={queryCaller}
                        onClick={setType}
                        selected={type}
                    />
                    <HealthyPigletsPerLitterItem
                        queryCaller={queryCaller}
                        AnmID={AnmID}
                        onClick={setType}
                        selected={type}
                    />
                    <AverageParturitionWeightItem
                        queryCaller={queryCaller}
                        AnmID={AnmID}
                        valueFormatter={weightFormatter}
                        onClick={setType}
                        selected={type}
                    />
                    <SeparatedPigletPerLitterItem
                        queryCaller={queryCaller}
                        AnmID={AnmID}
                        onClick={setType}
                        selected={type}
                    />
                    <AverageSeparatedWeightItem
                        queryCaller={queryCaller}
                        AnmID={AnmID}
                        valueFormatter={weightFormatter}
                        onClick={setType}
                        selected={type}
                    />
                    <IdleDaysItem
                        queryCaller={queryCaller}
                        AnmID={AnmID}
                        onClick={setType}
                        selected={type}
                    />
                    <AverageLactationDaysItem
                        queryCaller={queryCaller}
                        AnmID={AnmID}
                        onClick={setType}
                        selected={type}
                    />
                    <PigletsMortalityItem
                        queryCaller={queryCaller}
                        AnmID={AnmID}
                        valueFormatter={percentFormatter}
                        onClick={setType}
                        selected={type}
                    />
                </Row>
            )}
            <Collapse in={type !== null} mountOnEnter unmountOnExit>
                <div>
                    <SowResultsAdditionalInfo
                        type={type}
                        queryCaller={queryCaller}
                        AnmID={AnmID}
                        weightFormatter={weightFormatter}
                    />
                </div>
            </Collapse>
        </CollapsableContainer.Card>
    );
}
