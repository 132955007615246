import EventTypes from '@wesstron/utils/Api/constants/eventTypes';
import moment from 'moment';
import React, { useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {utcDateFormatter} from '../../../utils/DateTimeUtils'
import Chart from "../../basics/chart/Chart";
import CompareWeightsTooltip from "../../charts/CompareWeightsTooltip";
import CollapsableContainer from '../containers/CollapsableContainer';
import {calculateAutoDomainForP2Chart} from '../ideal-charts/charts/utils';

const emptyArray = [];

function FatMeasurementChart({fatDict, chartDomain = emptyArray, referenceLines}) {

    const forwardRef = useRef(null);

    const {t} = useTranslation();

    const dataSet = useMemo(() => {
        const ds = [];
        const [chartStart, chartEnd] = chartDomain;
        const diff = Math.abs(moment.utc(chartStart).diff(moment.utc(chartEnd), "day"));
        for (let i = 0; i <= diff; i++) {
            const dayUTC = moment.utc(chartStart).add(i, "day");
            // getting events by utc day from dict
            console.log(fatDict,+dayUTC,'testtt')
            const eventsForCurrentDay = fatDict?.[+dayUTC] || null;
            if (Array.isArray(eventsForCurrentDay)) {
                for (const event of eventsForCurrentDay) {
                    ds.push({
                        ...event,
                        time: +dayUTC,
                    });
                }
            }
        }
        return ds;
    }, [chartDomain, fatDict]);

    const chartDomainY = useMemo(() => {
        return calculateAutoDomainForP2Chart(dataSet);
    }, [dataSet]);


    const dataDef = useMemo(() => {
        return [
            {
                dataKey: EventTypes.FAT_MEASUREMENT,
                color: "orange",
                chartType: "Scatter",
                opacity: 1,
                unit: 'mm',
                name: t("fatThickness"),
                yAxisId: "scatterAxis",
            }
        ];
    }, [t]);

    return (
        <CollapsableContainer.Card id="cy-fatmeasurement-card" header={t("fatMeasurement")} defaultExpanded={dataSet.length}
                                   forwardRef={forwardRef}>
            <div className={"h-0 mh-15rem"}>
                <Chart
                    data={dataSet}
                    dataDef={dataDef}
                    syncId={"idealChart"}
                    isAnimationActive={false}
                    tooltipContent={<CompareWeightsTooltip/>}
                    syncMethod={"value"}
                    Yaxis={{
                        name: `${t("fatThickness")} [mm]`,
                        yAxisId: "lineAxis",
                        type: "number",
                        domain: chartDomainY,
                    }}
                    thirdYaxis={{yAxisId: "scatterAxis", type: "number", domain: chartDomainY}}
                    Xaxis={{
                        name: "",
                        domain: chartDomain,
                        dataKey: "time",
                        formatter: utcDateFormatter,
                        type: "number"
                    }}
                    type={"Composed"}
                    referenceLines={referenceLines}
                />
                {
                    referenceLines && referenceLines.length === 0 &&
                    <div className="chart-template-no-data">
                        <h5>{t("chartDisplayNoBirthTime")}</h5>
                        <div>{t("chartDisplayNoBirthTimeText")}</div>
                    </div>
                }
            </div>
        </CollapsableContainer.Card>
    )
}

export default React.memo(FatMeasurementChart);