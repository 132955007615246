import React, {useCallback, useMemo, useState} from "react";
import {Milliseconds} from "../../../constans/milliseconds";
import moment from "moment";
import MessageBox from "../../basics/message-box/MessageBox";
import {groupBy} from "lodash";
import {makeEnhancedComparer} from "../../../utils/TextUtils";
import "./_changes-timeline.scss";
import Button from "../../basics/button/Button";
import ChangeList from "./ChangeList";
import NotFound from "../../NotFound";
import {useTranslation} from "react-i18next";

export default ({data, isGroup}) => {

    const changeListByDay = useMemo(() => {
        const groupByTimestamp = groupBy(data, (o) => +moment(o.timestamp).startOf("day"));
        return Object.entries(groupByTimestamp).sort(makeEnhancedComparer("[0]", {
            numeric: true,
            ascending: false
        })).map((r) => ([
            +r[0],
            r[1]
        ]));
    }, [data]);

    if (!data) return null;

    if (data.length === 0) {
        return (
            <p>
                <NotFound/>
            </p>
        )
    }

    return (
        <div className={"timeline_container"}>
            {
                changeListByDay.map(([timestamp, items], index) => (
                    <DayCard
                        isGroup={isGroup}
                        timestamp={timestamp}
                        key={timestamp}
                        data={items}
                        nextDay={changeListByDay[index + 1]?.[0]}
                        prevDay={changeListByDay[index - 1]?.[0]}
                    />
                ))
            }
        </div>
    )

}

const DayCard = ({timestamp, nextDay, isGroup, data}) => {
    const lineLength = useMemo(() => {
        if (!nextDay) return 0;
        return Math.floor(Math.abs((timestamp - nextDay)) / Milliseconds.DAY) * 15;
    }, [nextDay, timestamp]);

    const changeListByMinute = useMemo(() => {
        const groupByTimestamp = groupBy(data, (o) => +moment(o.timestamp).startOf("minute"));
        return Object.entries(groupByTimestamp).sort(makeEnhancedComparer("[0]", {
            numeric: true,
            ascending: false
        })).map((r) => ([
            +r[0],
            r[1]
        ]));
    }, [data]);

    return (
        <>
            <h4>{moment(timestamp).format("L")}</h4>
            {
                changeListByMinute.map(([timestamp, items]) => (
                    <MinuteCard isGroup={isGroup} timestamp={timestamp} key={timestamp} data={items}/>
                ))
            }
            {
                !!lineLength &&
                <div className={"timeline_line"} style={{height: lineLength}}/>
            }
        </>
    )
}

const MAX_ITEMS = 2;

const MinuteCard = ({data, timestamp, isGroup}) => {

    const [expanded, setExpanded] = useState(data.length <= MAX_ITEMS);

    const onClick = useCallback(() => {
        setExpanded(true);
    }, [setExpanded]);

    const shouldShow = useCallback((index) => {
        if (expanded) return true;
        return index <= MAX_ITEMS; // ostatni item będzie robił za przycisk do pokazania szczegółów
    }, [expanded]);

    return (
        <>
            <fieldset className={"fieldset w-100 mb-3"}>
                <legend>{moment(timestamp).format("HH:mm")}</legend>
                {
                    data.map((item, index) => (
                        shouldShow(index) ?
                            <AnimalModificationCard
                                isGroup={isGroup}
                                showExpand={expanded ? null : MAX_ITEMS === index ? onClick : null}
                                key={index} item={item} nextItem={data[index + 1]}/>
                            : null
                    ))
                }
            </fieldset>
        </>
    )
}


const AnimalModificationCard = ({item, nextItem, showExpand, isGroup}) => {
    const {title, invokedBy, comment, subgroupName} = item;

    const {t} = useTranslation();

    return (
        <div className={showExpand ? "timeline_card timeline_card--hidden" : "timeline_card"}>
            <div className={"title"}>{title}</div>
            {
                isGroup &&
                <div>
                    <i className={"fas fa-pig"}/> {subgroupName}
                </div>
            }
            <div className={"mb-3"}>
                <ChangeList className={"changed-item"} value={item.changes}/>
            </div>
            <div className={"d-flex"}>
                <div className={"me-3"}>
                    {invokedBy.name}
                </div>
                {
                    !!comment &&
                    <MessageBox direction={"left"} message={comment}/>
                }
            </div>
            {
                !!showExpand &&
                <Button className={"floating-button"} type={"button"} buttonStyle={"text"}
                        icon={<i className={"fas fa-arrow-down"}/>} text={t("animalDocuments.expand")}
                        onClick={showExpand}/>
            }
            {
                !!nextItem &&
                <hr/>
            }
        </div>

    )
}