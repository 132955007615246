export function collectErrorCodes(obj, stack) {
    let collective = [];
    for (const property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (typeof obj[property] === "object") {
                collectErrorCodes(obj[property], stack + '.' + property);
            } else {
                if (property === 'code') {
                    collective = [...collective, obj[property]]
                }
            }
        }
    }
    return collective
}


export function hasActiveSubscription(farm = {}) {
    const {LicenseExpirationDate = 0} = farm;
    return +new Date() < LicenseExpirationDate;
}