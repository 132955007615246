import React from "react";
import PropTypes from 'prop-types';
import Button from "../basics/button/Button";
import {isFunction} from "lodash";

export default class ModalHeader extends React.Component {

    onClose = () => {
        const {onCloseClick} = this.props;
        if (isFunction(onCloseClick)) {
            onCloseClick();
        }
    }

    render() {
        const {loading, title, hasCloseButton = true} = this.props;
        return (
            <div className="modal-header">
                <h4>
                    <span>
                        {title}
                        {
                            !!loading &&
                            <i className={"fas fa-circle-notch fa-spin ms-1"}/>
                        }
                    </span>
                </h4>
                {
                    !!hasCloseButton &&
                    <Button className={"modal-exit"} onClick={this.onClose} buttonStyle={"text"}
                            icon={<i className={"fas fa-times"}/>} type="button"/>

                }

            </div>
        );
    }
}

ModalHeader.defaultProps = {
    hasCloseButton: true
}
ModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func,
    loading: PropTypes.bool
};
