import {myID} from "../libs/generateID";

const initialValue = {
    workers: new Map()
};

export default function workersReducer(state = initialValue, action) {
    switch (action.type) {
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "ADD_WORKER": {
            let workers = new Map(state.workers);
            // jezeli juz ustanieje taki worker to terminowanie
            let name = `${action.meta}_${myID()}`;
            if (workers.has(name)) workers.get(name).terminate();
            workers.set(name, action.payload);
            return {
                ...state, workers
            }
        }
        case "REMOVE_WORKER": {
            let workers = new Map(state.workers);
            workers.delete(action.payload);
            return {
                ...state, workers
            }
        }
        case "TERMINATE_WORKER": {
            let workers = new Map(state.workers);
            for (let key of workers.keys()) {
                if (key.startsWith(action.payload)) {
                    workers.get(key).terminate();
                    workers.delete(key);
                }
            }
            return {
                ...state, workers
            }
        }
        case "SET_RESULTS_GRID_DATA_FULFILLED": {
            let workers = new Map(state.workers);
            workers.delete("resultGrid");
            return {
                ...state, workers
            }
        }
        default:
            return state
    }
}
