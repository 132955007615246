// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

import i18next from "../i18n";
import { isEmpty } from "lodash";
import { notify } from "reapop";
import { getSyncAPI } from "../actions/syncActions";
import lokiDB from "../database/lokiDB";
import store from "../store/store";
import { askForPermisstionToReceiveNotifications } from "../utils/FirebaseUtils";
import { checkIfUserIsService } from "../utils/NewRolesUtils";
import { getUserToken } from "./awsLib";
import {isCypress} from "../utils/CypressUtils";

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
    if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        window.addEventListener("load", () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, config);

                // Add some additional logging to localhost, pointing developers to the
                // service worker/PWA documentation.
                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        "This web app is being served cache-first by a service " +
                        "worker. To learn more, visit https://bit.ly/CRA-PWA"
                    );
                });
            } else {
                // Is not localhost. Just register service worker
                registerValidSW(swUrl, config);
            }
            navigator.serviceWorker.ready.then(() => {
                navigator.serviceWorker.addEventListener(
                    "message",
                    async (event) => {
                        console.log("Sync results in app", event);
                        if (event.data.code === "FETCH_SYNC_API") {
                            if (!store) return;
                            const state = store.getState();
                            const user = state.user.user;
                            if (!user) return;
                            const sub = state.user.attributes.sub;
                            const key = checkIfUserIsService(user.Roles)
                                ? `${sub}_${user.ClientID}`
                                : sub;
                            console.log(
                                "%cReloading database after syncing fetches " +
                                key,
                                "background-color: blue, color: white"
                            );
                            await lokiDB.loadDatabase(key, true);
                            store.dispatch(getSyncAPI());
                            document.dispatchEvent(new Event("dataLoader"));
                        }
                        if (event.data.code === "GET_ACCESS_TOKEN") {
                            const token = await getUserToken();
                            console.log("Got access token", token);
                            navigator.serviceWorker.controller.postMessage({
                                type: "ACCESS_TOKEN",
                                token,
                            });
                        }
                    }
                );
            });
        });
    }
}

function showRefreshNotification(worker) {
    if (!isCypress()) {
        store.dispatch(
            notify({
                title: i18next.t("newAppVersion"),
                message: i18next.t("newAppVersionInfo"),
                status: "info",
                dismissible: true,
                dismissAfter: 0,
                buttons: [
                    {
                        name: i18next.t("newSettings.feeding.forages.feedingForageItem.update"),
                        onClick: () => {
                            worker.postMessage({ type: "SKIP_WAITING" });
                        }
                    }
                ]
            })
        );
    }
}

function registerValidSW(swUrl, config) {
    i18next.setDefaultNamespace("translation");
    if (!isCypress()) { // wylaczenie przeladowywania strony na cypressie
        navigator.serviceWorker?.addEventListener('controllerchange', () => {
            console.log("reload controllerchange")
            window.location.reload();
        });
    }
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            console.log(registration.waiting, registration, !!registration.waiting);
            if (registration.waiting) {
                setTimeout(() => {
                    showRefreshNotification(registration.waiting);
                }, 5000)
            }
            registration.onupdatefound = () => {
                // const waitingWorker = registration.waiting;
                // if (waitingWorker) {
                //     waitingWorker.postMessage({type: "SKIP_WAITING"});
                // }
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = async () => {
                    if (installingWorker.state === "installed") {
                        // installingWorker.postMessage({ type: "SKIP_WAITING" });
                        const user = store.getState().user.user;
                        try {
                            if (!isEmpty(user)) {
                                await askForPermisstionToReceiveNotifications(user);
                            }
                        } catch (e) {
                            console.error("register error", e);
                        }
                        if (navigator.serviceWorker.controller) {
                            showRefreshNotification(installingWorker);
                            // navigator.serviceWorker.controller.postMessage({
                            //     type: "SKIP_WAITING",
                            // });
                            // At this point, the updated precached content has been fetched,
                            // but the previous service worker will still serve the older
                            // content until all client tabs are closed.
                            console.log(
                                "New content is available and will be used when all " +
                                "tabs for this page are closed. See https://bit.ly/CRA-PWA."
                            );
                            // Execute callback
                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log("Content is cached for offline use.");

                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        })
        .catch((error) => {
            console.error("Error during service worker registration:", error);
        });
}

function checkValidServiceWorker(swUrl, config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { "Service-Worker": "script" },
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get("content-type");
            if (
                response.status === 404 ||
                (contentType != null &&
                    contentType.indexOf("javascript") === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log(
                "No internet connection found. App is running in offline mode."
            );
        });
}

export function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.unregister();
        });
    }
}
