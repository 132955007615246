import React, {memo, useCallback, useMemo, useRef} from "react";
import useIsOverflowingWidth from "../../../hooks/useIsOverflowingWidth";

const CustomLegend = (props) => {
    const {payload, onClick, mobile} = props;

    const position = useRef({
        left: 0,
        x: 0,
    });

    const mouseDownTime = useRef(0);

    const container = useRef();

    const mouseMoveHandler = useCallback((e) => {
        // get move by subtracting client pos an element pos
        const moveX = e.clientX - position.current.x;

        // set scroll
        container.current.scrollLeft = position.current.left - moveX;

        // set grabbing style
        container.current.style.setProperty("cursor", "grabbing");
        container.current.style.setProperty("userSelect", "none");
    }, [position]);

    const mouseUpHandler = useCallback(() => {
        // remove event listeners which will be no longer used
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);

        // set default style
        container.current.style.removeProperty("cursor");
        container.current.style.removeProperty("user-select");
    }, [mouseMoveHandler]);

    const mouseDownHandler = useCallback((e) => {
        mouseDownTime.current = Date.now();
        // set values of current scroll and position
        position.current.left = container.current.scrollLeft;
        position.current.x = e.clientX;

        // add event listeners
        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
    }, [mouseUpHandler, mouseMoveHandler, position]);


    // check if legend is overflowing the parent element
    const isOverflown = useIsOverflowingWidth(container);

    // move legend using grab only on desktop if the label exceeds its" parent element
    const enablePointerControls = !mobile && isOverflown;

    const handlers = useMemo(() => {
        return enablePointerControls ? {
            onMouseDown: mouseDownHandler
        } : {}
    }, [enablePointerControls, mouseDownHandler]);

    const onLegendItemClick = useCallback((...args) => {
        if (enablePointerControls) {
            // this fixes clicking on an item by mistake when grabbing the legend
            const now = Date.now();
            // when mouse click lasts less than 250 ms
            // do the legend item click
            if ((now - mouseDownTime.current) < 250) {
                onClick(...args);
            }
        } else {
            // proceed as usual
            onClick(...args);
        }

    }, [onClick, enablePointerControls]);


    return (
        <div {...handlers} className={"recharts-custom-legend"}
             ref={container}>
            {
                payload.map((entry, index) => (
                    entry.hideLegend ? null : <LegendItem mobile={mobile} key={index} entry={entry} onClick={onLegendItemClick}/>
                ))
            }
        </div>
    );
}

const LegendItem = memo(({entry, onClick}) => {

    const onLegendItemClick = useCallback(() => {
        onClick(entry);
    }, [entry, onClick]);

    const style = useMemo(() => ({
        color: entry.color
    }), [entry.color]);

    return (
        <div className={"recharts-custom-legend-item"} onClick={onLegendItemClick}>
            <i className={"fa fa-fw fa-circle me-1"} style={style}/> {entry.value}
        </div>
    )
})

export default CustomLegend;