export const ValidationDefaultValues = {
    // ogolne
    requiredComments: false,
    // wprowadzenie
    boarOnlyOnBoarChamber: true,
    maxPigletInsertionTimeDifference: 35,
    maxPorkerInsertionTimeDifference: 35,
    // inseminacja
    inseminationOnMating: true,
    blockDifferentSpermInCycle: true,
    maxCycleInseminations: 0,
    // usg
    usgWithoutInsemination: true,
    daysFromInseminationToUSG: true,
    checkDaysFromInseminationToUSG: false,
    // porod
    parturitionOnDelivery: true,
    parturitionWithoutInsemination: true,
    parturitionAfterParturition: true,
    daysFromInseminationToParturition: true,
    maxHealthyCnt: 50,
    maxWeakCnt: 50,
    maxMummyCnt: 50,
    maxDeadCnt: 50,
    maxPigletsWithSow: 50,
    averagePigletWeightForParturition: [1000, 4000],
    // odsad
    separationOnDelivery: true,
    separationWithoutParturition: true,
    separationWithMorePigletsThanAnimalHas: true,
    averagePigletWeightForSeparation: [5000, 10000],
    partialSeparationReport: true,
    maxPigletSeparationTimeDifference: 35,
    // przeniesienie
    transferAnimalWithPiglets: true,
    maxPigletTransferTimeDifference: 35,
    maxPorkerTransferTimeDifference: 35,
    // brak ciazy
    noPregnancyWithoutInsemination: true,
    // upadek
    requireToSubmitEstimatedWeight: true,
    // tatuowanie
    tattooGiltsWithOneBoar: false,
    // sprzedaz
    averagePigletWeightForSale: [20000, 40000],
    averagePorkerWeightForSale: [100000, 120000],
    // usuwanie
    removeAnimalWithEvents: true,
    // taski
    markTaskAsCompleted: false,
    markTaskAsCompletedAfterDate: false,
    // brak na widoku
    parturitionWithPositiveBalance: true,
    maxIntervalBetweenSeparations: 14,
};