import {useCallback, useEffect, useState} from "react";

export function useIsTabVisible() {

    const [isVisible, setVisible] = useState(true);

    const checkIfTabIsVisible = useCallback(() => {
        setVisible(document.visibilityState === "visible");
    }, []);

    useEffect(() => {
        document.addEventListener("visibilitychange", checkIfTabIsVisible);
        return () => {
            document.removeEventListener("visibilitychange", checkIfTabIsVisible);
        };
    }, [checkIfTabIsVisible]);

    return isVisible;
}