import {myID} from "../libs/generateID";
import store from "../store/store";
import {notify} from "reapop";
import i18n from "../i18n";
import animalsDB from "../database/animalsDB";
import groupsDB from "../database/groupsDB";

function translateErrorCode({code, data}) {
    switch (code) {
        case 1:
            return {message: "apiNotifications.ajvValidationError"};
        case 2:
            return {
                message: "apiNotifications.duplicatedAnimal",
                messageData: data,
            };
        case 3:
            return {
                message: "apiNotifications.corruptedRFID",
                messageData: data,
            };
        case 4:
            return {message: "apiNotifications.licenseError"};
        case 5: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.animalNotFound",
                messageData: {AnmNo1: animal?.AnmNo1},
            };
        }
        case 6: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.deadAnimal",
                messageData: {AnmNo1: animal?.AnmNo1},
            };
        }
        case 7: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.exceededSowBalance",
                messageData: {AnmNo1: animal?.AnmNo1},
            };
        }
        case 8: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.transferWithSowBalance",
                messageData: {AnmNo1: animal?.AnmNo1},
            };
        }
        case 9: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.restrictedSector",
                messageData: {AnmNo1: animal?.AnmNo1},
            };
        }
        case 10: {
            return {message: "apiNotifications.userNotAuthorized"};
        }
        case 11: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.exceededAnimalAmount",
                messageData: {
                    AnmNo1: animal?.AnmNo1,
                    amount: data.amount,
                    max: data.max,
                },
            };
        }
        case 12: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.animalHasRFID",
                messageData: {AnmNo1: animal?.AnmNo1},
            };
        }
        case 13: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.sowHasNoPiglets",
                messageData: {AnmNo1: animal?.AnmNo1},
            };
        }
        case 14: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.wrongAnimalType",
                messageData: {AnmNo1: animal?.AnmNo1},
            };
        }
        case 15: {
            const group = groupsDB.findGroupByID(data?.groupID);
            return {
                message: "apiNotifications.wrongAnimalBalance",
                messageData: {AnmGrp: group?.GrNo1},
            };
        }
        case 16: {
            return {
                message: "apiNotifications.blockingEvent",
                messageData: {count: data?.eventIds.length ?? 0},
            };
        }
        case 17: {
            return {message: "apiNotifications.blockingGroup"};
        }
        case 18: {
            const animal = animalsDB.getAnimalById(data.AnmID);
            return {
                message: "apiNotifications.rfidNotChanged",
                messageData: {animal: animal.AnmNo1},
            };
        }
        case 19: {
            return {
                message: "apiNotifications.duplicatedRFID",
                messageData: {RFID: data.RFID},
            };
        }
        default: {
            // jezeli wejdzie w ten warunek to dodaj case z obsluga
            console.warn("Error code not found");
            return {message: "apiNotifications.notFound"};
        }
    }
}

function dispatchErrorNotification(title, message, uniqueId) {
    store.dispatch(
        notify({
            id: uniqueId,
            title: title,
            message: message,
            status: "error",
            dismissible: true,
            // buttons: checkLogsButton(),
            dismissAfter: 15000,
        })
    );
}

export function createErrorNotification(
    response,
    titleKey,
    {uniqueId = myID()} = {}
) {
    if (Array.isArray(response)) {
        let notificationText = [];
        let notificationCounter = 0;
        let additionalNotificationCounter = 0;

        for (let i = 0; i < response.length; i++) {
            if (response[i] === null) continue;
            const translateData = translateErrorCode(response[i]);
            if (notificationCounter < 3) {
                console.log(translateData);
                let text = i18n.t(
                    translateData.message,
                    translateData.messageData
                );
                if (notificationCounter === 0) text = text.toLowerCase();
                notificationText.push(text);
                notificationCounter++;
            } else {
                additionalNotificationCounter++;
            }
        }
        if (additionalNotificationCounter > 0) {
            notificationText.push(
                i18n.t("XmoreParams", {number: additionalNotificationCounter})
            );
        }

        dispatchErrorNotification(
            i18n.t(titleKey),
            notificationText.join(", "),
            uniqueId
        );
        return true;
    } else {
        if (response?.code === undefined) return false;
        const translateData = translateErrorCode(response);
        dispatchErrorNotification(
            i18n.t(titleKey),
            i18n.t(translateData.message, translateData.messageData),
            uniqueId
        );
        return true;
    }
}

export function createSuccessNotification(response, titleKey) {
    if (response.feturaExecConfig) {
        // powinno byc sprawdzane po kodzie HTTP ale invokeAPig tego nie zwraca
        store.dispatch(
            notify({
                id: response.feturaExecConfig.execID,
                title: i18n.t(titleKey),
                message: i18n.t("apiNotifications.asyncRun"),
                status: "loading",
                dismissible: true,
                dismissAfter: 0,
            })
        );
        return true;
    }
    return false;
}

export const exportForTesting = {
    translateErrorCode,
    dispatchErrorNotification,
};
