import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Modal} from "react-bootstrap";
import {connectModal} from "redux-modal";
import {Field, FormSection, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import animalsDB from "../../../database/animalsDB";
import {submit, validate} from "./EditSellOrFallModalSubmit";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {getDictionary} from "../../../selectors/dictionarySelectors";
import {EventTypes} from "../../../constans/eventTypes";
import {convertWeightToBaseUnit, convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {getAnimalUnit} from "../../../utils/SettingsUtils";
import ConfirmModificationField from "../../custom-fields/ConfirmModificationField";
import {cloneDeep, isNil} from "lodash";
import {isAnimalRemovedOrSoldOrFallen} from "../../../utils/AnimalsUtils";
import moment from "moment";
import {getClosestEventsByTime} from "../../../utils/EventUtils";
import {isBetween} from "../../../utils/MathUtils";
import InfoBox from "../../basics/info-box/InfoBox";
import {
    getAnimalCountForLocationAndTimestampFunc
} from "../../../selectors/animalDocumentsSelectors";

function mapStateToProps(state) {
    return {
        fallReasons: getDictionary(state, "fallReasons"),
        getAnimalCountForLocationAndTimestamp: getAnimalCountForLocationAndTimestampFunc(state)
    };
}

export const ModalName = "edit-sell-or-fall";

class EditSellOrFallModal extends Component {

    unit = getUnit("weight", getAnimalUnit());

    constructor(props) {
        super(props);
        const {event, initialize} = this.props;
        let animal = animalsDB.getAnimalById(event.AnmID, {findDeleted: true});
        this.state = {animal};
        initialize({event: cloneDeep(event), animal});
    }

    validateEventTime = (value) => {
        const {t, event, getAnimalCountForLocationAndTimestamp} = this.props;
        if (isNil(value)) return t("required");
        const type = "animalCount";
        // PIERWSZY SPOSÓB:
        // tryb Adrianowy, który sprawdza ilość zwierząt w danym miejscu
        if (type === "animalCount") {

            // pobierz ilość zwierząt w danym dniu i miejscu
            let animalCount = getAnimalCountForLocationAndTimestamp(event.EvData.PlcmntID, +moment(value).startOf("day"), true);

            // jeśli nowa data jest później niż "stara" to te świnie co były w upadku trzeba dodać do ilości świń
            if (moment(value).isAfter(event.EvTime, "day")) {
                animalCount += event.EvData.AnmCnt ?? event.AnmCnt;
            }

            // jeśli nie ma zwierząt to poinformuj o tym użytkownika
            if (animalCount <= 0) {
                return t("rfidApp.noAnimalsInCurrentLocation")
            }
            // final words:
            // może się, popsuć, gdy w miejscu grupy będzie jakaś inna podgrupa, ale liczę, że api wtedy odrzuci
            return;
        }

        // DRUGI SPOSÓB:
        // sprawdź otoczenie wybranego zdarzenia modyfikujące ilość świń lub miejsce
        // małpiarski sposób, ale mamy pewność, że nic nie napsujemy
        const {animal: {events, DtaInTime, DtaBrthTime, DtaCrtTime}} = this.state;

        // pobierze starzy oraz nowszy event dla wybranych typów zdarzeń
        const {prev, next} = getClosestEventsByTime(events, event.EvTime, {
            excludeEventIds: [
                event.EvID
            ],
            eventCodes: [
                EventTypes.FALL,
                EventTypes.SELL,
                EventTypes.INSERTION,
                EventTypes.TRANSFER
            ]
        });
        // min czas albo ten wyszukany event, albo jakikolwiek czas z początku świni na fermie
        const minTime = +moment(prev ? prev.EvTime : (DtaInTime ?? DtaBrthTime ?? DtaCrtTime)).startOf("day");
        // kolejne zdarzenie albo dzisiaj
        const maxTime = +moment(next ? next.EvTime : new Date().getTime()).endOf("day");
        const isOutOfBounds = !isBetween(value, minTime, maxTime);
        if (isOutOfBounds) {
            return t("errors.mustBeInRange", {start: moment(minTime).format("L"), end: moment(maxTime).format("L")})
        }
    }

    render() {
        const {show, handleHide, submitting, t, fallReasons, event, group, invalid, error} = this.props;
        const {animal} = this.state;
        let showAnimalCountField = (!animal?.RFID && !animal?.Tagged);
        if (isAnimalRemovedOrSoldOrFallen(animal)) showAnimalCountField = false;
        return (
            <Modal show={show} size={"lg"}>
                <ModalHeader
                    title={event.EvCode === EventTypes.FALL ? t("editFall") : t("apiNotifications.editSaleData")}
                    onCloseClick={handleHide}/>
                <ModalBody>
                    {
                        !!error &&
                        <InfoBox outlined={true} boxColor={"error"}>
                            {error}
                        </InfoBox>
                    }
                    <FormSection name={"event"}>
                        {
                            isNil(animal?.DtaDelTime) &&
                            <Field
                                name={"EvTime"}
                                component={ReduxLabeledInput}
                                label={t("eventDate")}
                                type={"date"}
                                validate={this.validateEventTime}
                                parse={(value) => value ? moment(value).toDate().getTime() : null}
                                format={(value) => value ? moment(value).format(moment.HTML5_FMT.DATE) : null}
                            />
                        }
                        {
                            showAnimalCountField &&
                            <Field
                                name={"AnmCnt"}
                                component={ReduxLabeledInput}
                                label={t("grid.animalsAmount")}
                                type={"number"}
                            />
                        }
                        {
                            event.EvCode === EventTypes.FALL &&
                            <Field
                                name={"EvData.Reasn"}
                                component={ReduxLabeledSelect}
                                label={t("reason")}
                                options={fallReasons.map(reason => ({
                                    name: reason.Value,
                                    value: reason.ID
                                }))}
                            />
                        }
                        <Field
                            name={"EvData.Weight"}
                            component={ReduxLabeledInput}
                            label={t("avgPieceWeight")}
                            type={"number"}
                            unit={this.unit}
                            parse={value => value ? convertWeightToBaseUnit(+value, {fromUnit: UnitTypes.MEDIUM}) : null}
                            format={value => value ? convertWeightUnitTo(value, {
                                unit: UnitTypes.MEDIUM,
                                rawValue: true,
                                showUnit: false
                            }) : null}
                        />
                    </FormSection>
                    <ConfirmModificationField group={!!group} name={group ? group.GrNo1 : animal.AnmNo1}/>
                </ModalBody>
                <ModalFooter hasConfirmButton confirmText={t("save")}
                             onCloseClick={handleHide}
                             hasButtonDisabled={invalid}
                             formName={ModalName} submitting={submitting}/>
            </Modal>
        )
            ;
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    connectModal({name: ModalName}),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(EditSellOrFallModal);
