import {get, set} from "lodash";
import {calculateSiloVolume} from "./DevicesUtils";

export function getVolume(device) {
    const {Settings: {Diameter, CylinderHeight, TaperHeight, TaperDiameter} = {}} = device;
    if (Diameter && CylinderHeight && TaperHeight && TaperDiameter) {
        return calculateSiloVolume(Diameter, CylinderHeight, CylinderHeight, TaperDiameter);
    }
    return 0;
}

export function getForageCalibration(device) {
    return get(device, "Settings.ForageCalibration", 0);
}

export function getCapacity(device) {
    const volume = getVolume(device);
    const calibration = getForageCalibration(device);
    return volume * calibration;
}

export function getMinimumVolumeAlarm(device) {
    return get(device, "Settings.Alarms.MinVolume", 0)
}

export function setMinimumVolumeAlarm(device, _minValue = 0) {
    set(device, "Settings.Alarms.MinVolume", _minValue);
    return this;
}

export function getDiameter(device) {
    return get(device, "Settings.Diameter", 0);
}
