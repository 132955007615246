import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export function getJobExecutionStatus(jobId, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.getJobExecutionStatus({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {jobId}
    })
}
