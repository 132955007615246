import {handleAnimalInventoryRowClick} from "../../../../utils/URLUtils";
import {isFiniteNumber} from "../../../../utils/MathUtils";
import React from "react";
import {withTranslation} from "react-i18next";
import Chip from "../../../basics/chip/Chip";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import {compose} from "redux";
import {connect} from "react-redux";
import {animalFormatterSelector} from "../../../../selectors/animalSelector";
import {getActiveGroups, getManageSubgroups} from "../../../../selectors/groupSelector";
import memoizeOne from "memoize-one";
import {hasAccessByRoles} from "../../../../selectors/roleSelector";
import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";


const DrawChip = ({t, id, name, AnimalKind, isGroup, hasAccessToBreeding}) => {
    return (
        <Chip onClick={hasAccessToBreeding ? () => handleAnimalInventoryRowClick({id, isGroup}) : undefined}>
            <div><i className={"fas fa-pig"}/> {name}</div>
            <div>{AnimalKind.map((a) => t(`animalKind.${a}`)).join("/")}</div>
        </Chip>
    )
}

function AnimalsSummary({data: {items, counters}, t, hasAccessToBreeding}) {
    if (items.length !== 0) {
        const objects = Object.entries(counters).filter(([key, value]) => value > 0);
        if (!objects.length) return null;
        objects.sort((o1, o2) => +o1[1] - +o2[1]);
        return (
            <div className={"map-drawer-inventory"} aria-label={t("inventory")}>
                {objects.map(([key, count]) => `${t(`animalKind.${key}`)}: ${t("pcs", {count})}`).join(", ")}
                <div className={"mt-2"}>
                    {items.map(({id, name, isGroup, AnimalKind}, i) =>
                        <DrawChip t={t} key={`${i}_${id}`} id={id} isGroup={isGroup} AnimalKind={AnimalKind}
                                  name={name} hasAccessToBreeding={hasAccessToBreeding}/>
                    )}
                </div>
            </div>
        )
    }
    return null;
}

const transformData = memoizeOne((animals, groups, manageSubgroups, animalFormatter) => {
    const items = [];
    const counters = {};
    let usedAnimals = {};
    // hide porkers and piglets if not in group lol
    for (let {AnmIDs, GrNo1, AnmGrp} of groups) {
        const animalsInGroup = animals.filter(({AnmID}) => AnmIDs.includes(AnmID));
        const animalKinds = {};
        for (let {AnimalKind, AnmCnt, AnmID} of animalsInGroup) {
            usedAnimals[AnmID] = true;
            animalKinds[AnimalKind] = true;
            if (!isFiniteNumber(+AnmCnt) || (+AnmCnt < 1)) continue; // jesli nie ma anmcnt albo jest mniejszy od 1 to ie traktujemy powaznie takiego rekordu
            if (isFiniteNumber(+AnimalKind)) {
                if (!counters[AnimalKind]) counters[AnimalKind] = 0;
                counters[AnimalKind] += +AnmCnt;
            }
        }
        if (animalsInGroup.length) {
            items.push({
                isGroup: true,
                AnimalKind: Object.keys(animalKinds),
                id: AnmGrp,
                name: GrNo1
            });
        }
    }
    for (let animal of animals) {
        const {AnmID, AnimalKind, AnmCnt} = animal;
        // if (!manageSubgroups && [AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(AnimalKind)) continue;
        if (usedAnimals[AnmID]) continue;
        items.push({
            AnimalKind: [AnimalKind],
            name: animalFormatter(animal),
            id: AnmID
        });
        if (!isFiniteNumber(+AnmCnt) || (+AnmCnt < 1)) continue; // jesli nie ma anmcnt albo jest mniejszy od 1 to ie traktujemy powaznie takiego rekordu
        if (isFiniteNumber(+AnimalKind)) {
            if (!counters[AnimalKind]) counters[AnimalKind] = 0;
            counters[AnimalKind] += +AnmCnt;
        }
    }
    return {counters, items};
})

export default compose(
    connect((state, ownProps) => {
        const result = {
            formatter: animalFormatterSelector(state),
            manageSubgroups: getManageSubgroups(state),
            groups: getActiveGroups(state),
            hasAccessToBreeding: hasAccessByRoles(state, {roles: [RoleTypes.BREEDING]})
        }
        return {
            ...result,
            data: transformData(ownProps.animals, result.groups, result.manageSubgroups, result.formatter)
        }
    }),
    withTranslation(NameSpaces.TRANSLATION),
)(AnimalsSummary);
