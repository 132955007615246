import React, {Component} from 'react';
import "./_table-cell-animal-amount.scss";

class TableCellAnimalAmount extends Component {
    render() {
        const {animal} = this.props;
        return (
            <div className="table-cell-animal-amount">
                <div>{animal.AnmNo1}</div>
                <div>{animal.AnmCnt}</div>
            </div>
        );
    }
}

export default TableCellAnimalAmount;
