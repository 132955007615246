export const firebaseConfig = {
    apiKey: "AIzaSyC3MdMEF0T689-dCFBubO9gHFTK7GQzV5M",
    authDomain: "feturasns.firebaseapp.com",
    databaseURL: "https://feturasns.firebaseio.com",
    projectId: "feturasns",
    storageBucket: "feturasns.appspot.com",
    messagingSenderId: "1008420419627",
    appId: "1:1008420419627:android:1685a2929049113f"
}

