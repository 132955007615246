import { detect } from "detect-browser";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component, Suspense } from "react";
import { Container } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { hide, show } from "redux-modal";
import { fetchLanguage, getLanguages } from "../actions/localizationActions";
import ChunkLoadErrorCatcher from "../components/chunk-load-error-catcher/ChunkLoadErrorCatcher";
import CookieAcceptance from "../components/cookie-acceptance/CookieAcceptance";
import LoadingLanguageSwapper from "../components/loading-language-swapper/LoadingLanguageSwapper";
import LoadingComponent from "../components/loading/LoadingComponent";
import ConfirmModal from "../components/modals-new/confirm-modal/ConfirmModal";
import "../resources/scss/_style.scss";
import { changeLanguage } from "../utils/LangUtils";
import { ReactLocalStorage } from "../utils/ReactLocalStorage";
import { pushRefreshTokenAndDeviceKeyToServiceWorker } from "../utils/ServiceWorkerUtils";
import { logout } from "../utils/UserUtils";
import { getLanguageModule } from "../utils/Utils";
import MainRoute from "../views/main-route/MainRoute";
import GlobalLoading from "../components/loading/GlobalLoading";
////////////////
import { listDocumentsDynamoDB } from "../actions/documentsActions";
import { checkMaintenanceStatus } from "../actions/serviceActions";
import VideoPlayerPictureInPicture from "../components/basics/video-player/VideoPlayerPictureInPicture";
import GoogleAnalytics from "../components/google-analytics/GoogleAnalytics";
import DelayedActionModal from "../components/modals-new/delayed-action/DelayedActionModal";
import InfoParameterResultModal from "../components/modals-new/info-parameter-result-modal/InfoParameterResultModal";
import TechnicalBreakComponent from "../components/technical-break-component/TechnicalBreakComponent";
import i18n from "../i18n";
//////////

export function mapStateToProps(state) {
    return {
        isAuthenticating: state.user.isAuthenticating,
        isAuthenticated: state.user.isAuthenticated,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ show, hide }, dispatch),
    };
}

class App extends Component {
////////////////////
    constructor(props) {
        super(props);
        props.dispatch(checkMaintenanceStatus());
        props.dispatch(listDocumentsDynamoDB());
        i18n.setDefaultNamespace("translation");
    }
//////////////

    setUserLang = () => {
        let userLang;
        try {
            userLang = window.navigator.language || "en";
        } catch (e) {
            userLang = "en";
        }
        if (userLang.includes("-")) {
            userLang = userLang.split("-")[0];
        }
        if (!["en", "pl", "es", "ru"].includes(userLang)) {
            userLang = "en";
        }
        changeLanguage(userLang);
    };

    componentDidMount() {
        const { dispatch, i18n } = this.props;

        dispatch(getLanguages());
        // if (location.pathname === "/confirm") {
        //     let queryParams = queryString.parse(location.search);
        //     history.push(
        //         `/confirm/${queryParams.code}/${queryParams.username}`
        //     );
        // }
        // if (isAuthenticated) {
        //     history.push(path);
        // }
        let browser = detect();
        window.addEventListener("storage", (e) => {
            if (browser.name !== "safari") {
                // na safari nie dziala ten mechanizm, poniewaz safari inaczej sie zachowuje z wartosciami kluczy, kiedy sa puste
                if (
                    e &&
                    !isEmpty(e.key) &&
                    e.key.includes("LastAuthUser") &&
                    e.oldValue === null
                ) {
                    // tu sie safari wysypuje poniewaz po zalogowaniu oldValue === null, a na chrome jest inaczej
                    window.location.reload();
                }
                if (
                    e &&
                    !isEmpty(e.key) &&
                    e.key.includes("LastAuthUser") &&
                    e.oldValue !== null &&
                    e.newValue !== e.oldValue
                ) {
                    logout();
                }
            }
            if (
                e &&
                !isEmpty(e.key) &&
                e.key.includes("accessToken") &&
                e.newValue
            ) {
                pushRefreshTokenAndDeviceKeyToServiceWorker(
                    null,
                    null,
                    e.newValue
                );
            }
            if (
                e &&
                !isEmpty(e.key) &&
                e.key.includes("refreshToken") &&
                e.newValue
            ) {
                pushRefreshTokenAndDeviceKeyToServiceWorker(e.newValue);
            }
            if (
                e &&
                !isEmpty(e.key) &&
                e.key.includes("deviceKey") &&
                e.newValue
            ) {
                pushRefreshTokenAndDeviceKeyToServiceWorker(null, e.newValue);
            }
        });
        let currentUser = null;
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (key.includes("LastAuthUser")) {
                currentUser = localStorage.getItem(key);
                break;
            }
        }
        if (currentUser) {
            let token = null,
                deviceKey = null,
                accessToken = null;
            for (let i = 0; i < localStorage.length; i++) {
                let key = localStorage.key(i);
                if (key.includes(`${currentUser}.refreshToken`)) {
                    token = localStorage.getItem(key);
                }
                if (key.includes(`${currentUser}.deviceKey`)) {
                    deviceKey = localStorage.getItem(key);
                }
                if (key.includes(`${currentUser}.accessToken`)) {
                    accessToken = localStorage.getItem(key);
                }
            }
            if (token && deviceKey) {
                pushRefreshTokenAndDeviceKeyToServiceWorker(token, deviceKey, accessToken);
            }
        }

        i18n.on("languageChanged", async (language) => {
            ReactLocalStorage.setPrivate("language", language);
            let lang = getLanguageModule(language);
            await import(`moment/locale/${lang}`);
            moment.locale(lang);
            dispatch(fetchLanguage(language));
        });
        this.setUserLang();
    }

    render() {
        const { isAuthenticating, isAuthenticated } = this.props;
        console.log("test2");
        return (
            <ChunkLoadErrorCatcher>
                <Suspense
                    fallback={
                        <LoadingComponent
                            isLoading={true}
                            text={<LoadingLanguageSwapper />}
                        />
                    }>
                    <div className={"App"}>
                        {
////////////////////////////////////////////
                        }
                        {
                            !isAuthenticating && isAuthenticated && (
                                <TechnicalBreakComponent />
                            )
                        }
                        {
//////////////////////////////////////
                        }
                        <Container fluid={true} id="app-container">
                            <GlobalLoading />
                            <MainRoute />
                            {
////////////////////////////////////////////////
                            }
                            <VideoPlayerPictureInPicture />
                            {
//////////////////////////////////////////
                            }
                        </Container>
                    </div>
                    <CookieAcceptance />
                    <ConfirmModal />
                    {
////////////////////////////////////////
                    }
                    <DelayedActionModal
                        counter={10}
                        action={logout}
                        title={"modals.logoutModal.title"}
                        text={"modals.logoutModal.text"}
                        confirmButtonText={"logout"}
                    />
                    <InfoParameterResultModal />
                    <GoogleAnalytics />
                    {
//////////////////////////////////
                    }
                </Suspense>
            </ChunkLoadErrorCatcher>
        );
    }
}

export default compose(
    withTranslation(),
    // withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(App);
