import {useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {getTranslationEventKey} from "../../../../utils/RaportsUtils";
import {Collapse} from "react-bootstrap";

export default function FatteningAnimalsItem({items = [], value}) {

    const {t} = useTranslation();

    const [show, showClick] = useState(false);

    const onShowClick = useCallback(() => {
        showClick((prevState) => !prevState);
    }, []);

    const {details} = useMemo(() => {
        const details = (
            <div>
                <small>
                    {
                        items.map(({EvCode, Type, Amount}) => (
                            <>
                                <strong
                                    className={`opacity-75 color-${Type}`}>{t(getTranslationEventKey(EvCode))} ({Amount})</strong>
                                <br/>
                            </>
                        ))
                    }
                </small>
            </div>
        )
        return {details};
    }, [t, items]);

    const hasSomeItems = items.length;

    const cName = hasSomeItems ? "flex-column pointer" : "";

    return (
        <div className={cName} onClick={hasSomeItems ? onShowClick : undefined}>
            <div>
                {value}
                {
                    hasSomeItems ? <i className={`fas fa-chevron-${show ? "down" : "right"} ms-1`}/> : null
                }
            </div>
            <Collapse in={show}>
                {details}
            </Collapse>
        </div>
    )
}