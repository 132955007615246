import Paths from "../paths";
import {invokeApig} from "../../libs/awsLib";
import {getUserApiData} from "../../utils/UserUtils";

export function getTechnologyGroupTasks(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    const {GroupStartTime} = data;
    return invokeApig({
        ...Paths.getTechnologyGroupTasks({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {GroupStartTime}
    });
}