import {useCallback, useEffect, useRef, useState} from "react";

export default function useIsOverflowingWidth(ref) {

    const [isOverflow, setIsOverflow] = useState(false);

    const checkOverflowing = useCallback(() => {
        if (ref?.current) {
            const isOverflowing = ref.current.scrollWidth > ref.current.clientWidth;
            setIsOverflow(isOverflowing);
        }
    }, [ref]);

    const observer = useRef(new ResizeObserver(checkOverflowing))

    useEffect(() => {
        if (!ref?.current) return;
        checkOverflowing();
        const tmp = observer.current;
        tmp.observe(ref.current);

        return () => {
            tmp.disconnect();
        }
    }, [ref, checkOverflowing]);

    return isOverflow;
}
