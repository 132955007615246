import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import {getBasicEconomy as getBasicEconomySelector} from "../selectors/economySelectors";
import economyDB from "../database/economyDB";

export function getBasicEconomy() {
    return function (dispatch, getState) {
        let state = getState();
        let currentEconomy = getBasicEconomySelector(state);
        if (!currentEconomy) {
            dispatch({
                type: "GET_BASIC_ECONOMY",
                payload: invokeApig({
                    ...Paths.getBasicEconomy(),
                    queryParams: {}
                })
            })
        }
    }
}

export function getEconomy(FarmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_ECONOMY",
            payload: economyDB.getEconomy(FarmID)
        })
    }
}
