import {convertTemperatureUnitTo} from "../UnitUtils";
import {UnitTypes} from "../../constans/unitTypes";

const __defaultOptions__ = {};

export const temperatureFormatter = (value, options = __defaultOptions__) => convertTemperatureUnitTo(value, {
    showUnit: true,
    fixed: 1,
    ...options,
    unit: UnitTypes.SMALL,

});

export const temperatureOffsetFormatter = (value, options = __defaultOptions__) => convertTemperatureUnitTo(value, {
    showUnit: true,
    fixed: 1,
    ...options,
    unit: UnitTypes.SMALL,
    isOffset: true
});