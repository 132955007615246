import {removeDuplicateRFIDs} from "../utils/ShadowUtils";
import { logActionForCypress } from "../utils/CypressUtils";
const initialValue = {
    shadows: new Map(),
    animals: new Map(),
    loading: {},
    upload: {
        data: {},
        loading: {},
        details: {}
    }
};

export default function shadowReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_SHADOW_LOADING": {
            let newLoading = Object.assign({}, state.loading);
            const {DevID, status} = action.payload;
            const handle = (devId) => status === null ? (delete newLoading[devId]) : (newLoading[devId] = !!status)
            if (Array.isArray(DevID)) {
                DevID.forEach(d => {
                    handle(d);
                })
            } else {
                handle(DevID);
            }
            return {
                ...state,
                loading: newLoading
            }
        }
        case "CHANGE_SHADOWS_DELTA": {
            logActionForCypress(action)
            let map = new Map(state.shadows);
            for (let key of [...action.payload.keys()]) {
                //jesli ma caly obiekt to wrzuca jesli nie to nie zeby nie bylo niepelnych danych
                if (map.has(key)) {
                    const currentValue = map.get(key);
                    const newValue = action.payload.get(key);
                    const mergedMetadata = {...currentValue.metadata, ...newValue.metadata}
                    map.set(key, {
                        ...currentValue,
                        ...newValue,
                        metadata: mergedMetadata
                    });
                }
            }
            return {
                ...state,
                shadows: map
            };
        }

        case "CHANGE_SHADOWS": {
            logActionForCypress(action)
            let merged = new Map([...state.shadows, ...action.payload]);
            // mozliwe ze bedzie trzeba do tego wrocic - bug gdy mamy customowe onSuccess i onFailure
            // let newLoading = {...state.loading};
            // [...action.payload.entries()].forEach(([key]) => {
            //     newLoading[key] = false
            // })
            return {
                ...state,
                shadows: merged
                // loading: newLoading
            };
        }

        case "CHANGE_SHADOWS_ANIMAL": {
            let merged = new Map([...state.animals, ...action.payload]);
            for (let key of action.payload.keys()) {
                merged = removeDuplicateRFIDs(merged, key);
            }
            return {
                ...state,
                animals: merged
            };
        }

        case "CHANGE_SHADOWS_ANIMAL_DELTA": {
            let map = new Map(state.animals);
            for (let key of [...action.payload.keys()]) {
                //jesli ma caly obiekt to wrzuca jesli nie to nie zeby nie bylo niepelnych danych
                if (map.has(key)) {
                    let RFIDs = action.payload.get(key).map(delta => {
                        return delta.RFID
                    });
                    let oldWithUpdated = map.get(key).filter(v => (!RFIDs.includes(v.RFID)));
                    // let value = [...map.get(key), ...action.payload.get(key)];
                    let value = [...oldWithUpdated, ...action.payload.get(key)];
                    map.set(key, value);
                }
                map = removeDuplicateRFIDs(map, key);
            }
            return {
                ...state,
                animals: map
            };
        }
        case "REMOVE_RFIDS_FROM_ANIMAL_SHADOW": {
            let map = new Map(state.animals);
            let RFIDs = action.payload;
            for (let [locID, rfs] of map.entries()) {
                map.set(locID, rfs.filter(o => !RFIDs.includes(o.RFID)))
            }
            return {
                ...state,
                animals: map
            }

        }
        case "GET_UPLOAD_DEVICE_STATUS_PENDING": {
            return {...state, upload: {...state.upload, loading: {...state.upload.loading, [action.meta.DevID]: true}}}
        }
        case "GET_UPLOAD_DEVICE_STATUS_FULFILLED": {
            return {
                ...state, upload: {
                    ...state.upload, loading: {...state.upload.loading, [action.meta.DevID]: false},
                    data: {...state.upload.data, [action.meta.DevID]: action.payload.CAnsw}
                }
            }
        }
        case "GET_UPLOAD_DEVICE_STATUS_REJECTED": {
            return {
                ...state,
                upload: {
                    ...state.upload,
                    loading: {...state.upload.loading, [action.meta.DevID]: false}
                }
            }
        }
        case "GET_PROGRAM_DETAILS_FULFILLED": {
            return {
                ...state,
                upload: {
                    ...state.upload,
                    details: {
                        ...state.upload.details,
                        [action.meta.GwID]: action.payload.CAnsw
                    }
                }
            }
        }
        case "GET_UPLOAD_DEVICE_STATUS_DELTA": {
            if (!state.upload.data[action.meta.GwID]) return state;
            return {
                ...state,
                upload: {
                    ...state.upload,
                    data: {
                        ...state.upload.data,
                        [action.meta.GwID]: {
                            ...state.upload.data[action.meta.GwID],
                            ...action.payload[action.meta.GwID]
                        }
                    }
                }
            }
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
