import React from "react";
import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import i18n from "../i18n";
import {connect} from "react-redux";
import {checkIfUserIsMasterService} from "../utils/NewRolesUtils";
import {makeGetManageBuildingsList} from "../selectors/buildingsSelector";
import InfoBox from "./basics/info-box/InfoBox";
import NoRoleComponent from "./NoRoleComponent";
import {displayComponent} from "../utils/WithRolesUtils";
import {userIsService} from "../utils/NewRolesUtils2";

const getManageBuildingsList = makeGetManageBuildingsList();

function makeMapStateToProps() {
    return function mapStateToProps(state) {
        return {
            farm: state.location.farm,
            user: state.user.user,
            _buildingsMap: getManageBuildingsList(state)
        }
    }
}

/**
 * HOC do wrapowania komponentu (tak jak reduxForm albo connect) do sprawdzania czy użytkownik
 * ma potrzebne role do wyświetlenia komponentu
 * @param props {object|function}               obiekt z danymi, moze byc funkcja, ktora zwraca obiekt
 *                                              (przydatne przy wyciaganiu DevID, poniewaz parametrem sa propsy komponentu)
 *
 * @property props.roles {array}                lista ról minimalnych dla widoku
 *
 * @property props.devID {string}               DevID urządzenia dla roli
 *
 * @property props.showComponent {boolean}      określenie czy ma pokazać komponent, jeżeli ktoś nie ma
 *                                              uprawnień do wejścia
 *
 * @property props.clientPackage                obiekt z minimalnymi parametrami wejścia na widok, każdy klucz
 *                                              przyjmuje wartość "BASIC" lub "EXTENDED"
 *
 * @property props.clientPackage.counters       klucz odpowiedzialny za liczniki
 * @property props.clientPackage.siloses        klucz odpowiedzialny za silosy
 * @property props.clientPackage.dispensers     klucz odpowiedzialny za dozowniki
 * @property props.clientPackage.climate        klucz odpowiedzialny za klimaty
 * @property props.clientPackage.cages          klucz odpowiedzialny za klatki selekcyjne
 * @property props.clientPackage.smallCages     klucz odpowiedzialny za małą klatkę warchlaków
 * @property props.clientPackage.alarms         klucz odpowiedzialny za alarmy
 * @property props.clientPackage.chains         klucz odpowiedzialny za paszociągi
 * @property props.clientPackage.managment      klucz odpowiedzialny za zarządzanie
 * @property props.clientPackage.farm           klucz odpowiedzialny za część hodowlaną
 * @return {function(*): WithRoles}             komponent
 */
const withRoles = props => WrappedComponent => {

    class WithRoles extends React.Component {

        constructor(props) {
            super(props);
            const {user, farm} = this.props;
            this.isService = userIsService(user, farm);
            this.isMasterService = checkIfUserIsMasterService();
            this.p = this.getProps();
            this.state = {
                show: false,
                showMasterView: false
            }
        }

        componentDidMount() {
            this.setState({
                show: this.checkIfCanBeDisplayed(),
                showMasterView: this.checkIfCanShowMasterComponent()
            })
        }

        // pobranie propsow
        getProps() {
            if (typeof props === "function") return props(this.props);
            return props;
        }

        checkIfCanShowMasterComponent() {
            const {roles = []} = this.p;
            let isMasterServiceRequired = roles.includes(RoleTypes.MASTER_SERVICE);
            return !(isMasterServiceRequired && !this.isMasterService);
        }

        checkIfCanBeDisplayed() {
            const {roles = [], clientPackage, devPlcmnts, showServiceWarning, hideFor} = this.p;
            const {farm, user, _buildingsMap} = this.props;
            return displayComponent(roles, clientPackage, devPlcmnts, farm, user, _buildingsMap, this.isService, showServiceWarning, hideFor);
        }

        render() {
            const {show, showMasterView} = this.state;
            const {showComponent, showInfo, showServiceWarning} = this.p;
            if (!showMasterView) return null;
            if (!show && !this.isService) {
                if (showComponent) return <NoRoleComponent/>;
                if (showInfo) return <InfoBox boxColor="warning">{i18n.t("withRoles.noAccessWarningMessage")}</InfoBox>;
                return null;
            }
            return (
                <>
                    {
                        (showServiceWarning && this.isService && !show) &&
                        <InfoBox boxColor="warning"
                                 className="max-height-2rem">{i18n.t("withRoles.serviceViewMessage")}</InfoBox>
                    }
                    <WrappedComponent {...this.props}/>
                </>
            );
        }
    }

    WithRoles.displayName = `WithRoles(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

    return connect(
        makeMapStateToProps
    )(WithRoles);

};

export default withRoles;

