import {useMemo} from "react";
import settingsDB from "../database/settingsDB";
import {useSelector} from "react-redux";

export default function useFarmMapInfo() {

    const farm = useSelector(state => state.location.farm);

    const farmMap = useMemo(() => settingsDB.getFarmMap(farm), [farm]);

    return {
        hasFarmMap: !!farmMap,
        hasManyLevels: farmMap && Object.keys(farmMap.SetData.Levels).filter(o => o !== "bg").length > 1
    }
};
