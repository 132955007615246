import React from "react";
import CycleRenderer from "./CycleRenderer";
import PropTypes from "prop-types";
import {get, isEqual, isNil, sumBy} from "lodash";
import {getEventDate} from "./utils";
import moment from "moment";
import {withTranslation} from "react-i18next";

class FallPigletsRenderer extends React.Component {

    state = {
        hoveredID: "",
    };

    onMouseEnter = (event) => {
        const cell = get(event, "currentTarget");
        if (cell) {
            const EvID = cell.id.split(" ")[0];
            this.setState({
                hoveredID: EvID
            })
        }
    };

    onMouseLeave = () => {
        this.setState({
            hoveredID: ""
        })
    };

    getPigletsAge(fall) {
        const {cycle: {parturitions}, t} = this.props;
        const parturitionTime = parturitions[0]?.event?.EvTime;
        const fallTime = fall?.event?.EvTime;
        if (parturitions[0]?.event) {
            return t("Xdays", {count: !isNil(fall?.event?.Age) ? fall.event.Age : moment(fallTime).startOf("day").diff(moment(parturitionTime).startOf("day"), "days")});
        }
        return "";
    };

    getTattooData(fall) {
        const tattooData = get(fall, "event.EvData.TattooData", {});
        if (Object.keys(tattooData).length === 0) return undefined;
        return tattooData;
    };

    getPigletsContent(fall, tattooData) {
        if (tattooData) {
            return tattooData.AnmNo1 || "";
        }
        return get(fall, "event.EvData.Piglets");
    };

    getTitle(tattooData) {
        const {deletionMode, t} = this.props;
        if (tattooData && !deletionMode) return t("errors.noEditTattooPiglet");
        return "";
    };

    getClassName(evID, tattooData) {
        const {hoveredID} = this.state;
        const {deletionMode} = this.props;
        let className = "cell-wrapper";
        if (tattooData && !deletionMode) {
            className += " cursor-not-allowed";
        } else {
            if (hoveredID === evID) className += " error";
            else className += " success";
        }
        return className;
    };

    render() {
        const {
            cycle: {falls, key},
            expanded: {rows},
            isAdvanced,
            tooltipContent,
            cycleRenderClass,
            onDoubleEventClick,
            deletionMode,
            onDeleteClick,
            printing,
            isInterrupted
        } = this.props;
        const rowValue = rows.find(o => o === key);
        const lastFall = falls[falls.length - 1];
        if (!lastFall) {
            return (
                <>
                    {
                        isAdvanced &&
                        <CycleRenderer isInterrupted={isInterrupted}/>
                    }
                    <CycleRenderer comment={cycleRenderClass && tooltipContent} isInterrupted={isInterrupted}
                                   deletionMode={deletionMode}/>
                    <CycleRenderer isInterrupted={isInterrupted}/>
                </>
            );
        }
        const operators = falls[0].operators;
        const lastFallTattooData = this.getTattooData(lastFall);
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer isAdvanced={isAdvanced}>
                        {
                            isAdvanced && (isEqual(key, rowValue) || printing) ?
                                falls.map((fall, index) => {
                                    const {event: {EvID}} = fall;
                                    const tattooData = this.getTattooData(fall);
                                    return (
                                        <div className={this.getClassName(EvID, tattooData)} id={`${EvID} EvTime`}
                                             key={index} onDoubleClick={!tattooData ? onDoubleEventClick : undefined}
                                             title={this.getTitle(tattooData)}
                                             onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                             onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                             onClick={deletionMode ? onDeleteClick : undefined}
                                        >
                                            {getEventDate(fall)}
                                        </div>
                                    )
                                }) :
                                <div className={this.getClassName(lastFall.event.EvID, lastFallTattooData)}
                                     id={`${lastFall.event.EvID} EvTime`}
                                     onDoubleClick={!lastFallTattooData ? onDoubleEventClick : undefined}
                                     onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                     onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                     onClick={deletionMode ? onDeleteClick : undefined}
                                >
                                    {getEventDate(lastFall)}
                                </div>
                        }
                    </CycleRenderer>
                }
                <CycleRenderer comment={cycleRenderClass && tooltipContent}
                               isAdvanced={isAdvanced} operators={operators} deletionMode={deletionMode}>
                    {
                        isAdvanced && (isEqual(key, rowValue) || printing) ?
                            falls.map((fall, index) => {
                                const {event: {EvID}} = fall;
                                const tattooData = this.getTattooData(fall);
                                return (
                                    <div key={index} className={this.getClassName(EvID, tattooData)}
                                         id={`${EvID} EvData.Piglets`}
                                         onDoubleClick={!tattooData ? onDoubleEventClick : undefined}
                                         onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                         onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                         onClick={deletionMode ? onDeleteClick : undefined}
                                    >
                                        {this.getPigletsContent(fall, tattooData)}
                                    </div>
                                )
                            }) :
                            <div className={this.getClassName(lastFall.event.EvID, lastFallTattooData)}
                                 id={`${lastFall.event.EvID} EvData.Piglets`}
                                 onDoubleClick={!lastFallTattooData ? onDoubleEventClick : undefined}
                                 onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                 onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                 onClick={deletionMode ? onDeleteClick : undefined}
                            >
                                {
                                    sumBy(falls, "event.EvData.Piglets")
                                }
                            </div>
                    }
                </CycleRenderer>
                <CycleRenderer isAdvanced={isAdvanced}>
                    {
                        isAdvanced && (isEqual(key, rowValue) || printing) ?
                            falls.map((fall, index) => (
                                <div className={`cell-wrapper pointer-events-none`} key={index}>
                                    {this.getPigletsAge(fall)}
                                </div>
                            )) :
                            <div className={`cell-wrapper pointer-events-none`}>
                                {this.getPigletsAge(lastFall)}
                            </div>
                    }
                </CycleRenderer>
            </>
        );
    }
}

FallPigletsRenderer.propTypes = {
    cycle: PropTypes.shape({
        falls: PropTypes.array.isRequired
    }).isRequired
};

export default withTranslation()(FallPigletsRenderer);
