import {connectModal} from "redux-modal";
import {Modal} from "react-bootstrap";
import {Form, Formik} from "formik";
import {useCallback} from "react";
import ModalHeader from "../ModalHeader";
import {useTranslation} from "react-i18next";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import FormikLabeledInput from "../../basics/input/FormikLabeledInput";
import moment from "moment";
import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess
} from "../../../api/cycles/editSowCycle";

export const ModalName = "edit-heat-modal";

function EditHeatModalForm({handleSubmit, handleHide, isSubmitting}) {

    const {t} = useTranslation();

    return (
        <Form className={ModalName}>
            <ModalHeader title={t("editHeat")} onCloseClick={handleHide}/>
            <ModalBody>
                <FormikLabeledInput name="EvTime" label={t("date")} type="date" id="EvTime"/>
                <FormikLabeledInput name="Comment" label={t("comment")} type="text" id="Comment"/>
            </ModalBody>
            <ModalFooter
                hasConfirmButton
                confirmText={t("save")}
                onCloseClick={handleHide}
                onConfirmed={handleSubmit}
                submitting={isSubmitting}
            />
        </Form>
    )
}

function EditHeatModal({show, handleHide, heat}) {

    const onSubmit = useCallback((values) => {
        return editSowCycle({
            AnmID: values.AnmID,
            eventsToUpdate: [{...values, EvTime: +moment(values.EvTime)}],
            eventsToDelete: []
        }).then((res) => {
            editSowCycleNotificationSuccess(res);
            handleHide();
        }).catch((err) => {
            editSowCycleNotificationFailure(err);
        });
    }, [handleHide]);

    return (
        <Modal onHide={handleHide} show={show} size={"lg"}>
            <Formik initialValues={{
                ...heat,
                EvTime: moment(heat?.EvTime).format(moment.HTML5_FMT.DATE)
            }} onSubmit={onSubmit}>
                {
                    (props) => <EditHeatModalForm {...props} handleHide={handleHide}/>
                }
            </Formik>
        </Modal>
    )
}

export default connectModal({name: ModalName})(EditHeatModal);