import { getUserApiData } from "../../utils/UserUtils";
import { invokeApig } from "../../libs/awsLib";
import Paths from "../paths";
import { myID } from "../../libs/generateID";
import store from "../../store/store";
import { notify } from "reapop";
import i18n from "../../i18n";
import { createErrorNotification, createSuccessNotification } from "../../utils/ResponseUtils";

/**
 * @typedef PigletsTreatmentData
 * @type {object}
 * @property AnmID {string}             ID zwierzecia
 * @property Medicine {string}          ID leku
 * @property EvTime {number}            czas wykonania zdarzeniia (możliwe, że przejdzie do tablicy animals)
 * @property Comment {string}           komentarz
 * @property GrID {string}              id grupy
 * @property Weight {number}            waga upadłych prosiąt
 * @property TreatmentType {number}     Typ leczenia
 * @property AnmCnt {number}            Ilość zwierząt
 * @property PiCnt {number}             Liczba prosiąt
 * @property ForageAmount {number}      Ilość paszy podanej
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego upadku prosiat
 * @param data {[PigletsTreatmentData]}     Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createPigletsTreatment(data, { FarmID, ClientID, LocalUserID } = {}) {
    const { _FarmID, _LocalUserID, _ClientID } = getUserApiData({ FarmID, ClientID, LocalUserID });
    return invokeApig({
        ...Paths.createPigletsTreatment({ farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID }),
        body: data
    })
}

export function createPigletsTreatmentNotificationSuccess(response, { uniqueId = myID() } = {}) {
    if (createSuccessNotification(response, "apiNotifications.createPigletsTreatmentTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createPigletsTreatmentTitle"),
        message: i18n.t("apiNotifications.createPigletsTreatmentSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createPigletsTreatmentNotificationFailure(response, { uniqueId = myID() } = {}) {
    if (createErrorNotification(response, "apiNotifications.createPigletsTreatmentTitle", { uniqueId })) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createPigletsTreatmentTitle"),
        message: i18n.t("apiNotifications.createPigletsTreatmentFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
