import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getTechnologyGroupByTime } from "../../../utils/TechnologyGroupUtils";
import ALink from "../../basics/alink/ALink";

export function GroupClickItem({ TechnoGroupName, EvData: { InseminationTime = null } = {} }) {

    const { t } = useTranslation();

    const history = useHistory();

    const text = `${t("technologyGroup")} ${TechnoGroupName}`;

    const onTechnoGroupClick = useCallback(() => {
        const techno = getTechnologyGroupByTime(InseminationTime);
        history.push({ search: `?technologyGroup=${+techno.start}` });
    }, [InseminationTime, history]);

    return <ALink uppercase={false} onClick={onTechnoGroupClick}>{text}</ALink>;
}