import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {SowCardViewTypes} from "../cards/SowCard";
import Card from "../../basics/card/Card";
import TextSwitch from "../../basics/text-switch/TextSwitch";
import Button from "../../basics/button/Button";

export default function SowCardTypeChanger({viewType, deletionMode, print, onChange}) {

    const {t} = useTranslation();

    const options = [
        {name: <i className="fas fa-list"/>, value: SowCardViewTypes.LIST},
        {name: <i className="fas fa-table"/>, value: SowCardViewTypes.CYCLES}
    ];

    const onPrintClick = useCallback(() => {
        print();
    }, [print]);

    return (
        <Card>
            <p>{t("sowCard.viewCardType")}</p>
            <TextSwitch onChange={onChange} value={viewType} options={options}/>
            <Button className="m-0 mt-2 w-100" icon={<i className="far fa-print"/>}
                    disabled={deletionMode || viewType === SowCardViewTypes.LIST}
                    id="print-button" buttonColor="success" onClick={onPrintClick}>
                {t("grid.print1")}
            </Button>
        </Card>
    )
}
