import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {getMapData, makeGetAlertsData} from "../../../selectors/farmMapSelector";
import {pick} from "lodash";
import {getTextParametersStanding} from "./Text";
import {numericToHexString} from "../../../utils/ColorUtils";

const SmallElement = ({element, onBeforeRender, rotate}) => {

    const mapData = useSelector(getMapData);

    const getAlerts = useMemo(() => {
        const func = makeGetAlertsData();
        return (state) => func(state, element.id, element.type);
    }, [element]);

    const alerts = useSelector(getAlerts)

    const devices = useMemo(() => {
        const _devices = element.devices.slice(0);
        for (let d of _devices) {
            switch (d?.Index?.length || 0) {
                case 0: {
                    d.shadow = mapData[d.device.DevID];
                    break;
                }
                case 1: {
                    d.shadow = mapData[`${d.device.DevID}_${d.Index[0]}`];
                    break;
                }
                default: {
                    d.shadow = [];
                    d.Index.forEach(index => {
                        d.shadow.push(mapData[`${d.device.DevID}_${index}`]);
                    })
                    break;

                }
            }
        }
        return _devices;
    }, [element.devices, mapData]);

    const animals = useMemo(() => {
        const _animals = element.animals.slice(0);
        for (let a of _animals) {
            if (a.AnmID) {
                a.shadow = mapData[a.AnmID];
            }
        }
        return _animals;
    }, [element.animals, mapData]);

    const props = useMemo(() => {
        const TypesWhichShouldApplyAngle = ["standings", "groups", "animals"];
        const {
            fill,
            disabled,
            show,
            override = {},
            rotateObject = 0,
            className,
            text,
            _progress,
            led
        }
            = onBeforeRender({
            object: element,
            devices,
            animals,
            alerts
        });
        const angle = override.angle || TypesWhichShouldApplyAngle.includes(element.type) && element._angle && element._angle.angle ? element._angle.angle : 0;
        const transform = {
            angle: override.angle || angle,
            originX: override.transformOriginX || (angle ? element._angle.x : 0),
            originY: override.transformOriginY || (angle ? element._angle.y : 0)
        }
        const elementProps = {
            ...pick(element, ["fill", "id", "cx", "cy", "r", "d"]),
            fill,
            disabled
        }
        if (rotateObject) {
            elementProps.transform = `rotate(${rotateObject} ${(element.rect.x2 + element.rect.x1) / 2} ${(element.rect.y2 + element.rect.y1) / 2})`;
        }
        const groupProps = {
            transform: `rotate(${transform.angle} ${transform.originX} ${transform.originY})`,
            className: `map-wrapper ${className}`,
        }


        const result = {
            show,
            key: element.id,
            Group: {
                props: groupProps
            },
            Node: {
                tag: element.nodeName,
                props: elementProps
            },
            ForeignObject: {
                show: false,
                props: {},
                text
            },
            Progress: {
                show: false
            },
            Circle: {
                show: false
            }
        };
        if (text) {
            const textProps = getTextParametersStanding(text, element.rect, TypesWhichShouldApplyAngle.includes(element.type) ? transform.angle + rotate : rotate);
            result.ForeignObject.show = true;
            result.ForeignObject.props = textProps;
            result.ForeignObject.text = text;
            if (_progress) {
                result.Progress.show = true;
                result.Progress.props = {
                    x: element.rect.x1 + element.rect.width / 2 - 2,
                    y: element.rect.y2 - 2,
                    width: 4,
                    height: 1,
                    className: `feed-state ${_progress}`,
                    style: textProps.style
                }
            }
            if (led) {
                result.Circle.show = true;
                result.Circle.props = {
                    cx: element.rect.x1 + 3,
                    cy: element.rect.y1 + 3,
                    r: 2,
                    className: led.Blink ? "led-diode blink" : "led-diode",
                    style: {fill: numericToHexString(led.Colour)}
                }
            }
        }

        return result;
    }, [onBeforeRender, element, alerts, devices, animals, rotate]);

    if (!props.show) return null;

    return (
        <g {...props.Group.props}>
            <NodeRenderer props={props.Node.props} tag={props.Node.tag}/>
            {
                props.ForeignObject.show &&
                <foreignObject {...props.ForeignObject.props}>
                    <div className={"text"}>{props.ForeignObject.text}</div>
                </foreignObject>
            }
            {
                props.Progress.show &&
                <rect {...props.Progress.props}/>
            }
            {
                props.Circle.show &&
                <circle {...props.Circle.props}/>
            }
        </g>
    )

}

const _NodeRenderer = ({tag, props}) => {
    return React.createElement(tag, props);
}
const NodeRenderer = React.memo(_NodeRenderer);

export default React.memo(SmallElement);