import Paths from "../paths";
import {invokeApig} from "../../libs/awsLib";
import {notify} from "reapop";
import i18n from "../../i18n";
import {getUserApiData} from "../../utils/UserUtils";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {createErrorNotification, createSuccessNotification} from "../../utils/ResponseUtils";

export function removeProject({FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.removeProject({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID})
    });
}

export function removeProjectNotificationSuccess(response, {uniqueId = myID()} = {}) {
    if (createSuccessNotification(response, "apiNotifications.removeProjectTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.removeProjectTitle"),
        message: i18n.t("apiNotifications.removeProjectSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function removeProjectNotificationFailure(response, {uniqueId = myID()} = {}) {
    if (createErrorNotification(response, "apiNotifications.removeProjectTitle", {uniqueId})) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.removeProjectTitle"),
        message: i18n.t("apiNotifications.removeProjectFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}
