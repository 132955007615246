import React, {Component} from "react";
import {connect} from "react-redux";
import "./_technicalBreakComponent.scss";
import {withTranslation} from "react-i18next";
import {isMobile} from "../../utils/MobileUtils";
import moment from "moment";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import {compose} from "redux";

function mapStateToProps(state) {
    return {
        futureMaintenance: state.maintenance.futureMaintenance
    }
}

class TechnicalBreakComponent extends Component {

    getClassName() {
        let className = "technical-break-banner";
        if (isMobile()) className += " mobile";
        return className;
    }

    render() {
        const {t, futureMaintenance} = this.props;
        if (!futureMaintenance) return null;
        const DateStart = moment(futureMaintenance.DtaStartTime).format("DD-MM-YYYY (HH:mm)");
        const DateEnd = moment(futureMaintenance.DtaEndTime).format("DD-MM-YYYY (HH:mm)");
        return (
            <>
                <div className={this.getClassName()}>
                    {t("technicalBreakComponent.technicalBreak", {
                        from: DateStart,
                        to: DateEnd
                    })}
                </div>
            </>
        );
    }
}

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect(mapStateToProps)
)(TechnicalBreakComponent);
