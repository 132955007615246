import { isEmpty } from "lodash";
import { MultiTabDetection } from "multi-tab-detection";
import React, { Component, lazy, Suspense } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { compose } from "redux";
import ChunkLoadErrorCatcher from "../../components/chunk-load-error-catcher/ChunkLoadErrorCatcher";
import LoadingComponent from "../../components/loading/LoadingComponent";
import CustomNotificationsSystem from "../../components/notifications-system/CustomNotificationsSystem";
import TutorialRoutes from "../../tutorials/TutorialRoutes";
import { createVirtualizedTask } from "../../utils/DOMUtils";
import { checkIfUserIsService } from "../../utils/NewRolesUtils";
import NotificationURLWrapper from "../notification-view/NotificationURLWrapper";
import TabDuplicatedView from "../tab-duplicated-view/TabDuplicatedView";
////////////////
import queryString from "query-string";
import { changeSelectedItem } from "../../actions/animalDocumentsAction";
import { checkMaintenanceStatus } from "../../actions/serviceActions";
import AnimalDocument from "../../components/animal-documents/data-wrapper/AnimalDocument";
import { Level } from "../../constans/levelTypes";
import animalsDB from "../../database/animalsDB";
import buildingsDB from "../../database/buildingsDB";
import groupsDB from "../../database/groupsDB";
import { checkBrowser } from "../../utils/BrowserUtils";
import AcceptCertView from "../accept-cert-view/AcceptCertView";
import MaintenanceView from "../maintenance-view/MaintenanceView";
import {envIsLocalOrDevelop} from "../../utils/Utils";
//////////

const MainFarmRoute = lazy(() => import("./routes/MainFarmRoute"));
const Login = lazy(() => import("../Login"));
const LogoutView = lazy(() => import("../logout-view/LogoutView"));
const FarmSettings = lazy(() => import("../farm-settings-view/FarmSettings"));
////////////////
const TOSView = lazy(() => import("../documents-view/TOSView"));
const ConfirmUserView = lazy(() =>
    import("../confirm-user-view/ConfirmUserView")
);
const PricingView = lazy(() => import("../documents-view/PricingView"));
const PrivacyPolicyView = lazy(() =>
    import("../documents-view/PrivacyPolicyView")
);
const FeturaSignUpView = lazy(() =>
    import("../fetura-sign-up-view/FeturaSignUpView")
);
const GDPRView = lazy(() => import("../documents-view/GDPRView"));
const CheckBrowser = lazy(() => import("../../components/check-browser/CheckBrowser"));
/////////
////////////////////////////////////
//////////////////////////////////////////////////////
//
//////////

function makeMapStateToProps() {
    return (state) => ({
        isAuthenticating: state.user.isAuthenticating,
        isAuthenticated: state.user.isAuthenticated,
        maintenance: state.maintenance,
        user: state.user.user,
        farm: state.location.farm,
    });
}

class MainRoute extends Component {
    multiTabDetection = new MultiTabDetection();
    maxTabs = 1;
    state = {
        isDuplicatedTab: false,
////////////////////////
        userHasGoodBrowser: checkBrowser()
//////////////////
    };

    componentDidMount() {
        this.multiTabDetection.NewTabDetectedEvent.subscribe({
            next: (number) => {
                this.setDuplicatedTabs(number);
            },
        });
        this.multiTabDetection.ExistingTabDetectedEvent.subscribe({
            next: () => {
                this.setDuplicatedTabs(
                    this.multiTabDetection.NumberOfTabsOpened + 1
                );
            },
        });
        this.multiTabDetection.ClosedTabDetectedEvent.subscribe({
            next: (number) => {
                this.setDuplicatedTabs(number);
            },
        });
    }

////////////////////
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { isAuthenticated, dispatch } = this.props;
        if (prevProps.isAuthenticated !== isAuthenticated) {
            if (isAuthenticated) {
                dispatch(checkMaintenanceStatus());
            }
            this.setDuplicatedTabs(this.multiTabDetection.NumberOfTabsOpened);
        }
    }
//////////////

    hasAccessToken(arr) {
        return (
            Array.isArray(arr) &&
            ((arr[0] === "CognitoIdentityServiceProvider" &&
                arr[arr.length - 1] === "accessToken") ||
                arr[arr.length - 1] === "token")
        );
    }

    setDuplicatedTabs(number) {
        createVirtualizedTask(() => {
            const { isAuthenticating, user } = this.props;
            const isLoggedIn = Object.keys(localStorage)
                .map((item) => item.split("."))
                .some((items) => this.hasAccessToken(items));
            if (isLoggedIn && isEmpty(user)) return false;
            this.maxTabs = checkIfUserIsService() ? Infinity : 1;
            this.setState({
                isDuplicatedTab: !isAuthenticating && number > this.maxTabs,
            });
            return true;
        });
    }

////////////////////
    showAnimalDocument() {
        const eligableSearchNames = [
            "documentsGroup",
            "documentsAnimal",
            "documentsRFID",
            "documentsTechnoGroup",
        ];
        const {
            history: { location } = {},
            selectedItem,
            dispatch,
            farm,
        } = this.props;
        if (location && location.search) {
            const shouldShow = !!eligableSearchNames.find((name) =>
                location.search.includes(name)
            );
            if (
                shouldShow &&
                !selectedItem &&
                location.pathname !== `/farm/${farm}/inventory`
            ) {
                let queryParams =
                    queryString.parse(location.search || "") || {};
                if (
                    queryParams.documentsAnimal ||
                    queryParams.documentsGroup ||
                    queryParams.documentsRFID
                ) {
                    if (queryParams.documentsAnimal) {
                        const animal = animalsDB.getAnimalById(
                            queryParams.documentsAnimal,
                            { findDeleted: checkIfUserIsService() }
                        );
                        if (animal?.AnmID)
                            dispatch(changeSelectedItem(animal));
                    }
                    if (queryParams.documentsGroup) {
                        const group = groupsDB.findGroupByID(
                            queryParams.documentsGroup,
                            { showDeleted: true }
                        );
                        if (group?.AnmGrp)
                            dispatch(changeSelectedItem(group));
                    }
                    if (queryParams.documentsRFID) {
                        let animals = animalsDB
                            .getAnimalsByRfid(
                                queryParams.documentsRFID,
                                this.props.farm
                            )
                            .filter((a) => a?.AnmID);
                        if (animals.length === 0) {
                            const stand = buildingsDB.getBoxBIDByRfid(
                                queryParams.documentsRFID
                            );
                            if (stand)
                                animals = animalsDB
                                    .getAllAnimalsForLocation(
                                        stand,
                                        Level.BOX,
                                        { joinEvents: false }
                                    )
                                    .filter((a) => a?.AnmID);
                        }
                        if (animals.length === 1) {
                            dispatch(changeSelectedItem(animals[0]));
                        }
                    }
                }
            }
            return shouldShow;
        }
        // warunek psuje wyswietlanie informacji o lochach na widoku karmienia
        // po wykomentowaniu wydaje sie ze dziala: testowalem: inwentarz, karte lochy z linku oraz karmienie
        // if (location && !location.search && !!selectedItem) {
        //     if (get(location, "pathname", "").includes("/mobile")) {
        //         return false;
        //     } else {
        //         dispatch(resetSelectedItem());
        //     }
        // }
        return false;
    }
//////////////

    render() {
        const {
            isAuthenticated,
////////////////////////////
            maintenance,
//////////////////////
            isAuthenticating,
        } = this.props;
        const { isDuplicatedTab, userHasGoodBrowser } = this.state;
////////////////////////
        if (maintenance.inMaintenance) return <MaintenanceView />;
        if (!userHasGoodBrowser && !envIsLocalOrDevelop()) {
            return <Suspense fallback={<LoadingComponent isLoading={true}/>}><CheckBrowser/></Suspense>;
        }
//////////////////
        if (isDuplicatedTab) {
            return <TabDuplicatedView />;
        }
        return (
            <ChunkLoadErrorCatcher>
                <Suspense fallback={<LoadingComponent isLoading={true} />}>
                    {!isAuthenticated && (
                        <Switch>
                            {
////////////////////////////////////////////////
                            }
                            <Route
                                path="/tos"
                                render={(props) => <TOSView {...props} />}
                            />
                            <Route
                                path="/privacypolicy"
                                render={(props) => (
                                    <PrivacyPolicyView {...props} />
                                )}
                            />
                            <Route
                                path="/pricing"
                                render={(props) => <PricingView {...props} />}
                            />
                            <Route
                                path="/gdpr"
                                render={(props) => <GDPRView {...props} />}
                            />
                            <Route
                                path="/signup"
                                render={(props) => (
                                    <FeturaSignUpView {...props} />
                                )}
                            />
                            <Route
                                path="/confirm"
                                render={(props) => (
                                    <ConfirmUserView {...props} />
                                )}
                            />
                            <Route
                                path="/acceptCert"
                                render={(props) => (
                                    <AcceptCertView {...props} />
                                )}
                            />
                            {
//////////////////////////////////////////
                            }
                            <Route render={(props) => <Login {...props} />} />
                        </Switch>
                    )}
                    {isAuthenticated && !isAuthenticating && (
                        <>
                            <Switch>
                                <Route
                                    path="/farm/:FarmID"
                                    render={(props) => (
                                        <MainFarmRoute {...props} />
                                    )}
                                />
                                <Route
                                    path="/logout"
                                    render={(props) => (
                                        <LogoutView {...props} />
                                    )}
                                />
                                {
///////////////////////////////////////////////////
/////////////////////////////////
//////////////////////////////////////
////////////////////////////////////////////////
////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//////////////////////////////////////
//////////////////////////////////
/////////////////////////////////
//////////////////////////////////////////////
                                }
                                <Route
                                    render={(props) => (
                                        <FarmSettings {...props} />
                                    )}
                                />
                            </Switch>
                            <NotificationURLWrapper />
                        </>
                    )}
                    <TutorialRoutes />
                    <CustomNotificationsSystem />
                    {
////////////////////////////////////////
                    }
                    <AnimalDocument />
                    {
//////////////////////////////////
                    }
                </Suspense>
            </ChunkLoadErrorCatcher>
        );
    }
}

export default compose(
    withTranslation(),
    connect(makeMapStateToProps),
    withRouter
)(MainRoute);
