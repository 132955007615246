import {getPreferences as getPreferencesAPI} from "../api/preferences/getPreferences";
import {ReactLocalStorage} from "../utils/ReactLocalStorage";

export function getPreferences({showLoading = true, selectedLocalUserID = "", forceFetch = false} = {}) {
    return function (dispatch, state) {
        dispatch({
            type: "GET_PREFERENCES",
            payload: getPreferencesAPI({selectedLocalUserID, forceFetch}),
            meta: {showLoading}
        })
    }
}

export function clearPreferences() {
    return function (dispatch) {
        dispatch({
            type: "RESET_PREFERENCES"
        })
    }
}

export function selectNotificationPreference(item) {
    return function (dispatch, state) {
        dispatch({
            type: "SET_SELECTED_PREFERENCE_NOTIFICATION",
            payload: item
        })
    }
}

export function resetSelectedNotificationPreference(item) {
    return function (dispatch, state) {
        dispatch({
            type: "RESET_SELECTED_NOTIFICATION_PREFERENCE"
        })
    }
}

export function changeTabsPreferences(value = new Map()) {
    return (dispatch) => {
        dispatch({
            type: "CHANGE_TABS_PREFERENCES",
            payload: value
        });
        ReactLocalStorage.setPrivate("tabsPreferences", JSON.stringify(Array.from(value)));
    }
}
