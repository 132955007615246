import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connectModal } from "redux-modal";
import ModalHeader from "../ModalHeader";
import { connect } from "react-redux";
import ModalFooter from "../ModalFooter";
import ModalBody from "../ModalBody";
import { compose } from "redux";

export const ModalName = 'confirm-modal';

function mapStateToProps(store) {
    return {
        confirmLoading: store.view.confirmLoading
    };
}

/**
 * Komponent od modala
 * @param props
 * @returns {XML}
 * @constructor
 */
export class ConfirmModal extends Component {

    static propTypes = {
        text: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        confirmText: PropTypes.string.isRequired,
        handleHide: PropTypes.func.isRequired,
        onConfirmed: PropTypes.func,
        size: PropTypes.string,
        closeText: PropTypes.string,
        disableSubmit: PropTypes.bool
    };

    confirmedActionClick = () => {
        const { onConfirmed } = this.props;
        return onConfirmed(this.props);
    };

    emptyFunction = () => {
        console.log("no function")
    }

    handleHide = () => {
        const { customHandleHide, handleHide } = this.props;
        if (customHandleHide) {
            customHandleHide();
        }
        handleHide();
    }

    render() {
        const {
            show,
            text,
            confirmText,
            children,
            onConfirmed,
            title,
            size,
            closeText,
            optionalButtons,
            confirmButtonColor,
            disableSubmit,
            removeCloseButton,
            confirmLoading,
            disableEnterSubmit,
            confirmButtonClassName
        } = this.props; //title
        return (
            <Modal onHide={removeCloseButton ? this.emptyFunction : this.handleHide} show={show} size={size}>
                <ModalHeader title={title} hasCloseButton={!removeCloseButton} onCloseClick={this.handleHide} />
                <ModalBody className={ModalName}>
                    {text && <div className={"mb-1"}>{text}</div>}
                    {children}
                </ModalBody>
                <ModalFooter hasConfirmButton={!!onConfirmed} confirmButtonColor={confirmButtonColor}
                    confirmText={confirmText} onCloseClick={this.handleHide} forceLoadingOnClicked
                    hasButtonDisabled={disableSubmit || confirmLoading} closeText={closeText} confirmButtonClassName={confirmButtonClassName}
                    onConfirmed={this.confirmedActionClick} hasCloseButton={!removeCloseButton} disableEnterSubmit={disableEnterSubmit}
                    optionalButtons={optionalButtons} submitting={confirmLoading} />
            </Modal>
        );
    }
}

export default compose(
    connect(mapStateToProps),
    connectModal({ name: ModalName })
)(ConfirmModal);
