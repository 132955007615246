import {isNumber} from "lodash";
import moment from "moment";
import React, {useCallback, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {UnitTypes} from "../../../../constans/unitTypes";
import {makeGetDeviceData} from "../../../../selectors/aggregatedDataSelector";
import {convertTemperatureUnitTo, getUnit} from "../../../../utils/UnitUtils";
import useDeviceLocations from "../useDeviceLocations";
import useDomain from "../useDomain";
import ChartTemplate from "./ChartTemplate";
import {skipData} from "./utils";
import {utcMomentToLocal} from "../../../../utils/DateTimeUtils";
import useOpenDetailsModal from "../useOpenDetailsModal";

const DATA_TYPE = "temperature";

const Chart = ({groupInfo}) => {
    const ref = useRef(null);

    const getDeviceData = useMemo(makeGetDeviceData, []);
    const locations = useDeviceLocations(DATA_TYPE, false);

    const {
        data,
        error,
        loading
    } = useSelector(state => getDeviceData(state, groupInfo.start, groupInfo.end, locations, DATA_TYPE));

    const show = !!locations.length;

    const temperatureFormatter = useCallback((value) => convertTemperatureUnitTo(value, {
        showUnit: false,
        unit: UnitTypes.SMALL,
        rawValue: true,
        fixed: 2
    }), []);

    const domainX = useDomain();

    const dataSet = useMemo(() => {
        if (!data) return [];
        const fmt = temperatureFormatter;
        const ds = [];
        const diff = Math.abs(moment(domainX[0]).diff(moment(domainX[1]), "day"));
        for (let i = 0; i < diff; i++) {
            const dayUTC = moment.utc(domainX[0]).add(i, "day");
            const dayLOCAL = utcMomentToLocal(dayUTC);
            const deviceData = data[+dayUTC];
            const {value, details, estimated} = (() => {
                const details = [];
                const totalTemperature = [null, null];
                const totalRequested = [null, null];
                for (const locationId of locations) {
                    const hasData = deviceData ? !!deviceData[locationId] : false;
                    const {MinTemp, MaxTemp, Alert, RequestedTemp} = hasData ? deviceData[locationId] : {};
                    const animals = groupInfo.getNumberByTimeAndLocation(+dayLOCAL, locationId);
                    if (skipData(animals)) continue;
                    const locationData = {
                        locationId,
                        value: isNumber(MinTemp) && isNumber(MaxTemp) ? [fmt(MinTemp), fmt(MaxTemp)] : null,
                        error: Alert ? true : null,
                        estimated: RequestedTemp ? RequestedTemp.map(fmt) : null
                    }
                    if (locationData.value !== null) {
                        if (totalTemperature[0] === null) {
                            totalTemperature[0] = locationData.value[0];
                            totalTemperature[1] = locationData.value[1];
                        }
                        totalTemperature[0] = Math.min(totalTemperature[0], locationData.value[0]);
                        totalTemperature[1] = Math.max(totalTemperature[1], locationData.value[1]);
                    }
                    if (locationData.estimated !== null) {
                        if (totalRequested[0] === null) {
                            totalRequested[0] = locationData.estimated[0];
                            totalRequested[1] = locationData.estimated[1];
                        }
                        totalRequested[0] = Math.min(totalRequested[0], locationData.estimated[0]);
                        totalRequested[1] = Math.max(totalRequested[1], locationData.estimated[1]);
                    }
                    details.push(locationData);
                }
                return {
                    value: totalTemperature[0] === null ? null : totalTemperature,
                    details,
                    estimated: totalRequested[0] === null ? null : totalRequested
                };
            })();
            ds.push({
                time: +dayUTC,
                value,
                details,
                error: details.some(({error}) => error !== null) ? value || 0 : null,
                estimated
            })
        }
        return ds;

    }, [data, temperatureFormatter, locations, domainX, groupInfo]);

    const {t} = useTranslation();

    const valueFormatter = useCallback(value => {
        const isObject = (typeof value === 'object' && !Array.isArray(value))
        if (value === null || value === undefined || Math.abs(value) === Infinity || isObject) return null;
        if (Array.isArray(value)) {
            return value.map(item => {
                if (item === null || item === undefined || Math.abs(item) === Infinity) return null;
                return item
            }).join(" - ")
        }
        return value
    }, []);


    const dataDef = useMemo(() => {
        const unit = getUnit("temperature", UnitTypes.SMALL)
        return [
            {
                dataKey: "value",
                color: "green",
                type: "linear",
                opacity: 1,
                strokeOpacity: 0,
                unit,
                name: t("idealCharts.temperatureRange"),
                _role: "read",
                formatter: valueFormatter
            },
            {
                dataKey: "estimated",
                color: "blue",
                type: "stepAfter",
                opacity: 0.2,
                strokeWidth: 1,
                strokeOpacity: 1,
                unit,
                name: t("requestedTemperature"),
                _role: "range",
                formatter: valueFormatter
            },

            {
                color: "red",
                opacity: 1,
                dataKey: "error",
                chartType: "Scatter",
                unit,
                name: t("idealCharts.problems"),
                formatter: valueFormatter
            }
        ];
    }, [t, valueFormatter]);

    const onClick = useOpenDetailsModal("climate", locations, groupInfo);

    return (
        <ChartTemplate
            show={show}
            domain={["dataMin-1", "dataMax+1"]}
            data={data} error={error}
            YaxisName={t("temperature")}
            name={t("idealCharts.climateHeader")} loading={loading} id={"cy-climate-chart"}
            dataDef={dataDef}
            dataSet={dataSet}
            saveAsExcell={t("idealCharts.climateHeader")}
            defaultExpanded={true} forwardRef={ref} onClick={onClick}/>
    )
}

const ClimateChart = ({groupInfo}) => {
    const hasClimate = useDeviceLocations(DATA_TYPE, true);
    return hasClimate ? <Chart groupInfo={groupInfo}/> : null;
}

export default React.memo(ClimateChart);