import lokiDB from "./lokiDB";
import { getModificationTime, insertInto, setModificationTime } from "../utils/LokiUtils";

class BreedingCountersDB {

    collectionName = "breedingCounters";

    getModificationTime() {
        return getModificationTime(this.collectionName);
    }

    insertBreedingCounters(values) {
        insertInto(values, lokiDB.breedingCounters, "CntID");
        setModificationTime(this.collectionName, values[0].DtaModTime);
    }

    getBreedingCounters(FarmID) {
        return lokiDB.breedingCounters.find({
            FarmID,
            DtaDelTime: { $type: "undefined" }
        });
    }

    getBreedingCountersByRace(FarmID, Race) {
        return lokiDB.breedingCounters.find({
            FarmID,
            Race,
            DtaDelTime: { $type: "undefined" }
        });
    }
}

const breedingCountersDB = new BreedingCountersDB();
export default breedingCountersDB;