import { useSelector } from "react-redux";
import { getLocationDays } from "../../../selectors/animalDocumentsSelectors";
import { getDevices } from "../../../selectors/deviceSelector";
import memoizeOne from "memoize-one";
import { isStringifyEqual } from "../../../utils/Utils";
import { useMemo } from "react";
import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import { getDeviceGroup, hasEnableAnalyze, hasPlcmntID } from "../../../utils/DevicesUtils";

/**
 * w zależności od przekazanej flagi `returnAsBoolean` zwracamy tutaj:
 * a) liste lokalizacji w których znajdują się urządzenia o danym typie danych
 * b) wartość mówiąca czy znajduja się urządzania o danym typie danych
 * @param type {string} - typ danych
 * @param returnAsBoolean {boolean} - czy ma zwócic wszystkie lokalizację, czy tylko flage informującą czy są dane urządzenia powiązane z typem danych
 * @return {boolean|ReturnType<function(*): any>}
 */
const useDeviceLocations = (type, returnAsBoolean = false) => {
    const animalNumberPerLocation = useSelector(getLocationDays);
    const devices = useSelector(getDevices);

    const memoLocations = useMemo(() => memoizeOne((locations) => locations, isStringifyEqual), []);

    const allowedLocations = useMemo(() => {
        const allowedDevices = devices.filter((d) => {
            // jeśli analiza danych jest wyłączona wyjdź
            if (!hasEnableAnalyze(d)) return false;
            switch (type) {
                case "water":
                    if (![DevTypes.WATER_FLOW_METER, DevTypes.WIRELESS_WATER_FLOW_METER].includes(d.DevType)) return false;
                    break;
                case "temperature":
                    if (getDeviceGroup(d) !== "climates") return false;
                    break;
                case "forage":
                    if (![DevTypes.SCALE].includes(d.DevType)) return false;
                    break;
                default:
                    return false;
            }
            return true;
        });
        // lista lokalizacji związana z aktualną grupą warchlaków
        const locations = Object.keys(animalNumberPerLocation);
        const allowedLocations = [];
        for (let locationId of locations) {
            if (allowedDevices.some((d) => hasPlcmntID(d, locationId))) {
                allowedLocations.push(locationId);
                if (returnAsBoolean) {
                    break;
                }
            }
        }
        return allowedLocations.sort();
    }, [devices, type, animalNumberPerLocation, returnAsBoolean]);

    return returnAsBoolean ? allowedLocations.length > 0 : memoLocations(allowedLocations);

}

export default useDeviceLocations;