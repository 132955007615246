import React, { useCallback, useMemo } from "react";
import CollapsableContainer from "../containers/CollapsableContainer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getSeparationToMommyGiltsList } from "../../../selectors/animalDocumentsSelectors";
import TableGrid from "../../basics/table-grid/TableGrid";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import { isMobile } from "../../../utils/MobileUtils";
import moment from "moment";
import AnimalReferenceDocumentModalGrid from "../../grids/animal-reference-document/AnimalReferenceDocumentModalGrid";

export default function SeparationToMommyGiltsGrid({ cycle }) {

    const { t } = useTranslation();

    const mommyList = useSelector(getSeparationToMommyGiltsList);

    const mobile = useMemo(() => isMobile(), []);

    const data = useMemo(() => {
        return mommyList.filter((row) => row.cycle === cycle);
    }, [cycle, mommyList]);

    const dateFormatter = useCallback((time) => {
        return time ? moment(time).format("L") : null;
    }, []);

    const headers = useMemo(() => {
        return [
            {
                name: t("animalNumber"),
                field: "AnmNo1"
            },
            {
                name: t("RFID"),
                field: "RFID"
            },
            {
                name: t("activeNipples"),
                field: "Nipples"
            },
            {
                name: t("grid.dateSeparation"),
                field: "SeparationTime",
                valueFormatter: dateFormatter
            },
            {
                name: t("grid.mommyTime"),
                field: "MommyTime",
                valueFormatter: dateFormatter
            },
            {
                name: t("grid.newMommyEvent"),
                field: "AnmID",
                component: <AnimalReferenceDocumentModalGrid />
            },
            {
                name: t("grid.reclassifyTime"),
                field: "ReclassifyTime",
                valueFormatter: dateFormatter
            },
            {
                name: t("animalDocuments.deathDate"),
                field: "DtaDthTime",
                valueFormatter: dateFormatter
            },
            {
                name: t("animalDocuments.deletionDate"),
                field: "DtaDelTime",
                valueFormatter: dateFormatter
            }
        ]
    }, [t, dateFormatter]);

    return (
        <CollapsableContainer.Fieldset id="cy-separation-to-mommy-gilts-card" header={t("separationGiltsToMommy")} defaultExpanded={data.length > 0}>
            <TableGrid data={data} headers={headers} mobileRow={<DefaultMobileRow />} paginationItems={10}
                showPagination={!mobile} scrollOnPageChange={false} />
        </CollapsableContainer.Fieldset>
    )
}