
export const MAX_NOTIFICATIONS = 200;
const initialValue = {
    notifications: [],
    size: 0
};

export default function notificationHistoryReducer(state = initialValue, action) {
    switch (action.type) {
        case "reapop/upsertNotification": {
            const time = +new Date();
            const notification = {...action.payload, time};
            const newNotifications = [...state.notifications];
            const index = newNotifications.findIndex(({id}) => id === notification.id);
            let newSize = state.size;
            if (index === -1) {
                newNotifications.push({
                    time,
                    id: notification.id,
                    history: [notification]
                })
            } else {
                newNotifications[index].history.push(notification);
                newSize++;
            }
            newNotifications.sort((o1, o2) => o2.time - o1.time);
            return {...state, notifications: newNotifications.slice(0, MAX_NOTIFICATIONS), size: newSize}
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
