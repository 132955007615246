import {
    createInsemination,
    createInseminationNotificationFailure,
    createInseminationNotificationSuccess
} from "../../../api/events/Insemination";
import moment from "moment";
import {
    createSowCycles,
    createSowCyclesNotificationFailure,
    createSowCyclesNotificationSuccess
} from "../../../api/events/SowCycles";
import {checkIfUserIsService} from "../../../utils/NewRolesUtils";

export function submit(values, dispatch, props) {
    const {animal, user, handleHide} = props;
    const {EvTime, Boar} = values;
    const cycles = +values.Cycles;
    const isInsemination = cycles === 0;
    if (isInsemination) {
        return createInsemination([{
            AnmID: animal.AnmID,
            EvTime: +moment.utc(EvTime),
            Comment: "",
            boarID: Boar.AnmID,
            Operator: user.LocalUserID
        }]).then((res) => {
            createInseminationNotificationSuccess(res);
            handleHide();
        }).catch((e) => {
            console.error(e);
            createInseminationNotificationFailure(e);
        });
    } else {
        return createSowCycles({
            AnmID: animal.AnmID,
            EvTime: +moment.utc(EvTime),
            Cycles: cycles
        }).then((res) => {
            createSowCyclesNotificationSuccess(res);
            handleHide();
        }).catch((e) => {
            console.error(e);
            createSowCyclesNotificationFailure(e);
        });
    }
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;
    if (!values.Cycles) errors.Cycles = t("required");
    if (values.Cycles) {
        const cycles = +values.Cycles;
        if (cycles < 0) errors.Cycles = t("errors.noLess", {number: 0});
        if (cycles > 15) errors.Cycles = t("errors.greaterThan", {number: 15});
    }
    if (!values.EvTime) errors.EvTime = t("required");
    if (!checkIfUserIsService() && moment(values.EvTime).isAfter(new Date(), "days")) {
        errors.EvTime = t("errors.doNotInsertEventInFuture");
    }
    if (!values.Boar) errors.Boar = t("required");
    return errors;
}
