import i18n from "../i18n"
import memoizeOne from "memoize-one";

export const getLogNames = memoizeOne((t) => {
    return _shortNameMemoizedByLang(i18n.language);
});

const _shortNameMemoizedByLang = memoizeOne((lang) => {
    const data = i18n.getDataByLanguage(lang);
    const keys = Object.keys(data?.translation || {});
    const result = [];
    for (let key of keys) {
        if (/logsView\.shortNames\.[0-9]+$/.test(key)) {
            const code = parseInt((key).replace("logsView.shortNames.", ""));
            result[code] = data.translation[key];
        }
    }
    return result;
});