import React from "react";

export default function () {
    return (
        <pattern id="ue-meadow" x="0" y="0" width="128" height="128" viewBox="0 0 128 128"
                 patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="128" height="128"/>
        </pattern>
    )
}
