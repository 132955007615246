import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import i18n from "../../i18n";
import {createErrorNotification, createSuccessNotification} from "../../utils/ResponseUtils";

/**
 * @typedef InseminationData
 * @type {object}
 * @property AnmID {string}     ID zwierzęcia
 * @property EvTime {number}    Czas wykonania zdarzenia
 * @property boarID {string}    Id knura
 * @property GrID {string}      ID grupy, do której należało zwierzę
 * @property comment {string}   Komentarz
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowej inseminacji
 * @param data {[InseminationData]}         Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createInsemination(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createInsemination({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createToInsemination(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createToInsemination({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createToRepeatInsemination(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createToRepeatInsemination({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createInseminationNotificationSuccess(response, {uniqueId = myID()} = {}) {
    if (createSuccessNotification(response, "apiNotifications.createInseminationTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createInseminationTitle"),
        message: i18n.t("apiNotifications.createInseminationSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createInseminationNotificationFailure(response, {uniqueId = myID()} = {}) {
    if (createErrorNotification(response, "apiNotifications.createInseminationTitle", {uniqueId})) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createInseminationTitle"),
        message: i18n.t("apiNotifications.createInseminationFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
