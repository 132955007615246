import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isNil} from 'lodash';

class BasicInfoBoxItem extends Component {

    getClassName() {
        const {className} = this.props;
        let name = "info-row";
        if (className) name += ` ${className}`;
        return name;
    }

    render() {
        const {name, children, valueID} = this.props;
        const className = this.getClassName();
        return (
            <div className={className}>
                <div className={"info-column header"}>{name}</div>
                <div className="info-column" id={valueID}>
                    {isNil(children) ? "-" : children}
                </div>
            </div>
        );
    }
}

BasicInfoBoxItem.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    valueID: PropTypes.string,
};

export default BasicInfoBoxItem;
