import {catchify} from "../../../utils/Utils";
import {isNil} from "lodash";
import {myID} from "../../../libs/generateID";

export default class Animation {

    DEBUG = false;

    constructor(setState) {
        this.setState = setState;
        this.timerRef = null;
        this.animations = {};
        this.runId = null;
    }

    add(name, animationKeys) {
        this.animations[name] = animationKeys;
    }

    async run(name, dstCamera) {
        this.stop();
        this.DEBUG && console.log(name, "run");
        const runId = myID();
        this.runId = runId;
        const animation = this.animations[name];
        for (const keyframe of animation) {
            if (runId !== this.runId) break;
            await this.step(name, keyframe, dstCamera);
        }
    }

    async step(name, animationKey, dstCamera) {
        const [func, delay = 0] = animationKey;
        return new Promise((resolve) => {
            this.timerRef = setTimeout(() => {
                catchify(() => {
                    this.setState((state) => {
                        const result = func(state.camera, dstCamera);
                        const {animate, x, y, zoom} = result;
                        this.DEBUG && console.log(name, result);
                        return {
                            camera: {
                                ...state.camera,
                                ...!isNil(x) && {x},
                                ...!isNil(zoom) && {zoom},
                                ...!isNil(y) && {y}
                            },
                            ...(animate === "smooth") && {animate: true, animateType: "search"},
                            ...(animate === "instant") && {animate: true, animateType: "click"},
                            ...(animate === "none") && {animate: false, animateType: "click"}
                        };
                    });
                })();
                resolve(name);
            }, delay)
        })

    }

    destroy() {
        clearTimeout(this.timerRef);
    }

    stop() {
        this.runId = null;
        clearTimeout(this.timerRef);
    }

}