import lokiDB from "./lokiDB"
import { reportStatus } from "../constans/reports";
import { getModificationTime, insertInto, setModificationTime } from "../utils/LokiUtils";
import { isOnFarm } from "../utils/RaportsUtils";

class ReportsDB {

    getModificationTime() {
        return getModificationTime("reports");
    }

    insertAthenaReports(values) {
        console.log(values);
        insertInto(values, lokiDB.reports, "LocalQTime");
        setModificationTime("reports", values[0].DtaModTime);
    }

    insertNewReport(report) {
        lokiDB.reports.insert(report);
    }

    /**
     * Zwraca listę raportów dla fermy
     * @param farmID
     * @return {[{}}]}
     */
    getAllReports(farmID) {
        return lokiDB.reports.find({ DtaDelTime: { $type: 'undefined' } })
            .filter(item => isOnFarm(item, farmID))
            .sort((a, b) => b.FeturaQuery.FeturaQTime - a.FeturaQuery.FeturaQTime);
    }

    updateReportStatus(id, status = reportStatus.READY) {
        let reps = lokiDB.reports.find();
        let report = JSON.parse(JSON.stringify(reps.find(item => item.FeturaQuery.FeturaQTime === id)));
        report.QueryExecution.Status.State = status;
        lokiDB.reports.update(report);
    }

    removeReport(report) {
        lokiDB.reports.chain().find({ $loki: report.$loki }).remove();
    }

    /**
     * Tylko do testów
     */
    clear() {
        lokiDB.reports.chain().find().remove();
    }
}

const reportsDB = new ReportsDB();
export default reportsDB;
