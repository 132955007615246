import lokiDB from "../database/lokiDB";

/**
 * Dodaje listę do kolekcji
 * @param list              lista obiektów
 * @param collection        nazwa kolekcji
 * @param idName            nazwa klucza, który identyfikuje obiekty, np. AnmID
 * @param additionalKey     dodatkowy klucz główny, np. AggTi
 */
export const insertInto = (list, collection, idName, additionalKey = undefined) => {
    console.log("INSERT", list.length, collection, idName, additionalKey);
    const toInsert = [];
    const handleUpsert = (newItem, oldItem) => {
        if (oldItem) {
            const updatedObj = {
                $loki: oldItem.$loki,
                ...newItem
            };
            delete updatedObj.isTemporary;
            collection.update(updatedObj);
        } else {
            toInsert.push(newItem);
        }
    }
    // this is more effective when updating small chunk of items
    // 1. list every new item
    // 2. check if new item is already on lokijs (direct lokijs call)
    // 3. run update or add to insert list
    if (list.length <= 75) {
        for (const newItem of list) {
            const query = {
                [idName]: newItem[idName]
            };
            if (additionalKey && newItem[additionalKey]) query[additionalKey] = newItem[additionalKey];
            const oldItem = collection.findOne(query);
            handleUpsert(newItem, oldItem);
        }
    } else {
        // this is more effective when updating large chunks of items
        // 1. get all items from collection
        // 2. make a key map from old items
        // 3. check if new item is already on lokijs (using key-map object)
        // 4. run update or add to insert list
        let objects = collection.find();
        let tmp = {};
        for (let oldItem of objects) {
            let key = oldItem[idName];
            if (additionalKey) key += oldItem[additionalKey];
            tmp[key] = oldItem;
        }
        for (let newItem of list) {
            let key = newItem[idName];
            if (additionalKey) key += newItem[additionalKey];
            let oldItem = tmp[key];
            handleUpsert(newItem, oldItem);

        }
    }
    if (toInsert.length > 0) {
        collection.insert(toInsert);
    }
};

/**
 * Funkcja pobierająca czas ostatniej edycji
 * @param collection            kolekcja
 * @param addtionalKey          dodatkowy klucz, np. FarmID dla kolekcji animals
 * @param additinalKeyValue     wartosc dodatkowego klucza
 * @param mainKeyName           nazwa głównego klucza, np. DtaModTime
 */
export const getModificationTime = (collection, addtionalKey = undefined, additinalKeyValue = undefined, mainKeyName = "DtaModTime") => {
    let findObject = {
        table: collection
    };
    if (addtionalKey) {
        findObject[addtionalKey] = additinalKeyValue;
    }
    const tmp = lokiDB.cachedTablesParams.findOne(findObject) || { [mainKeyName]: "0" };
    console.log(`%cGetting modification time for ${collection}: ${tmp[mainKeyName]}`, "color: green");
    return tmp;
};

/**
 * Funckja ustawia czas ostatniej edycji
 * @param collection            kolekcja
 * @param modificationTime      czas ostatniej edycji
 * @param addtionalKey          dodatkowy klucz, np. FarmID dla kolekcji animals
 * @param additinalKeyValue     wartosc dodatkowego klucza
 * @param mainKeyName           nazwa głównego klucza, np. DtaModTime
 */
export const setModificationTime = (collection, modificationTime, addtionalKey = undefined, additinalKeyValue = undefined, mainKeyName = "DtaModTime") => {
    console.log(`%cSetting modification time for ${collection}: ${modificationTime}`, "color: red");
    let cached = getModificationTime(collection, addtionalKey, additinalKeyValue, mainKeyName);
    if (cached[mainKeyName] === "0") {
        cached[mainKeyName] = modificationTime;
        cached.table = collection;
        if (addtionalKey) {
            cached[addtionalKey] = additinalKeyValue;
        }
        if (cached.$loki) {
            lokiDB.cachedTablesParams.update(cached);
        } else {
            lokiDB.cachedTablesParams.insert(cached);
        }
    } else {
        cached[mainKeyName] = modificationTime;
        lokiDB.cachedTablesParams.update(cached);
    }
};
