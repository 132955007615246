import {parse, stringify} from "path-ast";
import {isString, omit, memoize, isArray, get} from "lodash";
import {mathEval} from "../../../utils/Utils";
import PathParser from "../../svg-editor/utils/PathParser";

const SVGPaths = {
    Pig: {
        width: 48,
        height: 130,
        d: ["M27.193,0.418C28.268,1.301 27.821,3.25 28.512,4.432C31.688,9.857 31.348,10.316 36.557,21.489C36.822,22.058 45.601,19.753 47.241,23.182C48.606,26.037 39.416,27.346 39.347,29.323C38.221,61.549 60.948,112.176 25.142,125.235C23.368,125.882 23.173,128.802 21.422,129.507C20.498,129.879 19.806,129.651 20.054,128.687C20.407,127.317 23.727,125.977 22.521,125.235C11.507,118.464 7.183,115.085 6.118,106.972C2.687,80.844 4.518,42.194 9.753,28C10.182,26.838 0.972,27.673 1.383,24.702C1.986,20.336 12.775,20.866 12.797,20.728C14.34,10.969 17.429,9.378 20.323,3.987C20.842,3.018 21.019,1.095 21.817,0.32C23.589,0.19 25.384,0.245 27.193,0.418Z"]
    },
    Standing: {
        width: 90,
        height: 90,
        d: ["M77.017,0L77,187.469L76.986,187.469L77,187.52L63,191.272L63,191.282L27,191.282L27,187.282L62.435,187.282L73,184.451L73,115.546L63.735,115.546L57.3,109.11L57.316,109.094L57.3,109.094L57.3,58.094L57.339,58.094L63.735,51.697L73,51.697L73,28.79L62.848,28.79L62.848,38.82L56.452,45.217L56.452,45.256L33.452,45.256L33.452,45.24L33.435,45.256L27,38.82L27,28.79L17,28.79L17,51.697L26.223,51.697L32.619,58.094L32.658,58.094L32.658,109.094L32.642,109.094L32.658,109.11L26.223,115.546L17,115.546L17,183.282L26,183.282L26,187.282L0,187.282L0,183.282L13,183.282L13.017,0L77.017,0ZM17,55.697L17,111.546L24.566,111.546L28.658,107.453L28.658,59.79L24.566,55.697L17,55.697ZM73,111.546L73,55.697L65.392,55.697L61.3,59.79L61.3,107.453L65.392,111.546L73,111.546ZM58.848,28.79L31,28.79L31,36.398L35.092,40.49L54.756,40.49L58.848,36.398L58.848,28.79ZM17.017,4L17.017,24.79L73.017,24.79L73.017,4L17.017,4Z"]
    },
    StandingFloor: {
        width: 200,
        height: 240,
        d: [PathParser.createFromRect(0, 0, 200, 240).toString()]
    },
    StandingTrough: {
        width: 56,
        height: 20.8,
        d: [PathParser.createFromRect(0, 0, 56, 20.8).toString()]
    },
    StandingSmall: {
        width: 70,
        height: 205,
        d: ["M70,0L70,170L69.999,170.226C69.878,189.439 54.242,205 35,205C15.683,205 0,189.317 0,170L0,0L70,0ZM67,28L3,28L3,170C3,186.813 19.592,202 35,202C51.32,202 67,188.046 67,170L67,28ZM67,3L3,3L3,25L67,25L67,3Z"]
    },
    GroupDispenser: {
        width: 70,
        height: 205,
        d: ["M70,235L56,235L56,231L67,231L67,31L55,31L55,240L15,240L15,31L3,31L3,231L14,231L14,235L0,235L0,0L70,-0L70,235ZM52,216L18,216L18,237L52,237L52,216ZM18,153L18,213L52,213L52,153L18,153ZM52,80L18,80L18,150L52,150L52,80ZM52,56L18,56L18,77L52,77L52,56ZM18,31L18,53L52,53L52,31L18,31ZM67,28L67,3L3,3L3,27.991L67,28Z"]
    },
    StandingSmallFloor: {
        width: 70,
        height: 240,
        d: [PathParser.createFromRect(0, 0, 70, 240).toString()]
    },
    StandingSmallTrough: {
        width: 64,
        height: 22,
        d: [PathParser.createFromRect(0, 0, 64, 22).toString()]
    },
    GroupTrough: {
        width: 64,
        height: 25,
        d: [PathParser.createFromRect(0, 0, 64, 25).toString()]
    },
    Dispenser: {
        width: 34,
        height: 21,
        d: ["M15,14.993C15.082,14.998 15.164,15 15.246,15L29.246,15C31.73,15 33.746,12.984 33.746,10.5C33.746,8.016 31.73,6 29.246,6L15.246,6C15.164,6 15.082,6.002 15,6.007L15,5C15,4.448 14.552,4 14,4L1,4C0.448,4 0,4.448 0,5L0,16C0,16.552 0.448,17 1,17L14,17C14.552,17 15,16.552 15,16L15,14.993Z"]
        // d: ["M18.986,15.251C17.286,18.426 13.937,20.587 10.087,20.587C4.52,20.587 0,16.067 0,10.5C0,4.933 4.52,0.413 10.087,0.413C13.937,0.413 17.286,2.574 18.986,5.749L31.371,5.749C32.682,5.749 33.746,6.813 33.746,8.125L33.746,12.875C33.746,14.187 32.682,15.251 31.371,15.251L18.986,15.251Z"]
    },
    CageTwoWayFloor: {
        width: 200,
        height: 200,
        d: [PathParser.createFromRect(0, 0, 200, 200).toString()]
    },
    CageTwoWayOutline: {
        width: 200,
        height: 200,
        d: ["M77.273,174.199L75.25,174.199L75.25,196.898L79.25,196.898L79.25,176.199L81.273,176.199L81.273,75.727L83.273,75.727L83.273,56L83.207,56L83.273,55.887L46.488,34.649L44.488,38.113L79.273,58.196L79.273,72.472L77.273,72.472L77.273,174.199ZM121.289,176.199L121.289,196.898L125.289,196.898L125.289,174.199L123.273,174.199L123.273,72.472L120.488,72.472L120.488,58.196L155.273,38.113L153.273,34.649L116.488,55.887L116.554,56L116.488,56L116.488,75.727L119.273,75.727L119.273,176.199L121.289,176.199ZM100.416,22.725L66.136,2.934L64.136,6.398L98.696,26.351L102.136,26.351L136.696,6.398L134.696,2.934L100.416,22.725Z"]
    },
    CageTwoWayForeground: {
        width: 200,
        height: 200,
        d: ["M119.289,173.199L119.289,163.199L81.25,163.199L81.25,173.199L119.289,173.199ZM114,122.25C114,121.008 112.992,120 111.75,120L88.25,120C87.008,120 86,121.008 86,122.25L86,126.75C86,127.992 87.008,129 88.25,129L111.75,129C112.992,129 114,127.992 114,126.75L114,122.25ZM119.289,85.199L119.289,75.199L81.25,75.199L81.25,85.199L119.289,85.199ZM107,21.5C107,19.568 105.432,18 103.5,18L96.5,18C94.568,18 93,19.568 93,21.5L93,28.5C93,30.432 94.568,32 96.5,32L103.5,32C105.432,32 107,30.432 107,28.5L107,21.5Z"]
    },
    CageDoorSmall: {
        width: 3,
        height: 18,
        d: [PathParser.createFromRect(0, 0, 3, 18).toString()]
    },
    CageDoorBig: {
        width: 3,
        height: 30,
        d: [PathParser.createFromRect(0, 0, 3, 30).toString()]
    },
    IpsumFloor: {
        width: 50,
        height: 200,
        d: ["M41.75,60L7.75,60L7.75,75L4.75,75L4.75,175L44.75,175L44.75,75L41.75,75L41.75,60Z"]
    },
    IpsumTrough: {
        width: 50,
        height: 200,
        d: ["M7.75,60L41.75,60L41.75,26.01L7.75,26L7.75,60Z"]
    },
    IpsumForeground: {
        width: 50,
        height: 200,
        d: ["M44.039,173.199L44.039,163.199L6,163.199L6,173.199L44.039,173.199ZM38.75,122.25C38.75,121.008 37.742,120 36.5,120L13,120C11.758,120 10.75,121.008 10.75,122.25L10.75,126.75C10.75,127.992 11.758,129 13,129L36.5,129C37.742,129 38.75,127.992 38.75,126.75L38.75,122.25ZM44.039,85.199L44.039,75.199L6,75.199L6,85.199L44.039,85.199Z"]
    },
    IpsumOutline: {
        width: 50,
        height: 200,
        d: ["M45.25,22L4.05,22L4.023,72.472L2.023,72.472L2.023,174.199L0,174.199L0,196.898L4,196.898L4,176.199L6.023,176.199L6.023,75.727L8.023,75.727L8.023,56L7.957,56L7.995,26L41.238,26L41.238,75.727L44.023,75.727L44.023,176.199L46.039,176.199L46.039,196.898L50.039,196.898L50.039,174.199L48.023,174.199L48.023,72.472L45.238,72.472L45.25,22Z"]
    },
    NutriProV2Outline: {
        width: 60,
        height: 120,
        d: [PathParser.createFromRect(0, 0, 60, 120).toString()]
    },
    NutriProV2Trough: {
        width: 52,
        height: 112,
        d: [PathParser.createFromRect(4, 4, 52, 112).toString()]
    },
}

const withManyOperations = (args, operation) => {
    if (args.every((a) => isArray((a)))) {
        for (let a of args) {
            operation(a);
        }
    } else {
        operation(args);
    }
}
const getPathWithTransform = memoize(({key, translate = [], rotate = [], scale = []} = {}) => {
    const def = get(SVGPaths, key);
    const paths = def.d.map(d => parse(d));
    if (translate.length === 2) {
        paths.forEach(d => {
            withManyOperations(translate, (arg) => {
                const t = [];
                t[0] = evaluateTransform(def.width, arg[0]);
                t[1] = evaluateTransform(def.height, arg[1]);
                d.translate(...t);
            })
        })
    }
    if (rotate.length > 0) {
        paths.forEach(d => {
            withManyOperations(rotate, (arg) => {
                d.rotate(...arg);
            })
        })
    }
    if (scale.length > 0) {
        paths.forEach(d => {
            withManyOperations(scale, (arg) => {
                d.scale(...arg);
            });
        })
    }
    return paths.map(d => stringify(d));
}, ({
        key,
        rotate = [],
        translate = [],
        scale = []
    }) => `${key}_${JSON.stringify(rotate)}_${JSON.stringify(translate)}_${JSON.stringify(scale)}`)


const evaluateTransform = (size, maybeAString) => {
    if (!isString(maybeAString)) return maybeAString;
    let strTmp = maybeAString;
    let match;
    do {
        match = strTmp.match(/\d+(\.\d+)?%/);
        if (match) {
            strTmp = strTmp.replace(match[0], size * parseFloat(match[0].replace(",", ".")) / 100)
        }
    } while (match && match[0]);
    return mathEval(strTmp);

}

const prepareProps = (props) => omit(props, ["translate", "rotate", "scale"]);

const pathRenderer = (key) => (props) => <>
    {
        getPathWithTransform({
            key: key,
            translate: props.translate || [],
            rotate: props.rotate || [],
            scale: props.scale || []
        }).map((d) => (
            <path d={d} {...prepareProps(props)}/>
        ))
    }
</>

// todo: do a proper nesting so its easier to find for other people
export default {
    //dozownik
    Dispenser: pathRenderer("Dispenser"),
    // path od świni używana w wiekszości stanowisk
    Pig: pathRenderer("Pig"),
    // zwykłe stanowiska
    Standing: pathRenderer("Standing"),
    StandingFloor: pathRenderer("StandingFloor"),
    StandingTrough: pathRenderer("StandingTrough"),
    // wąskie stanowiska
    StandingSmallFloor: pathRenderer("StandingSmallFloor"),
    StandingSmall: pathRenderer("StandingSmall"),
    StandingSmallTrough: pathRenderer("StandingSmallTrough"),
    GroupDispenser: pathRenderer("GroupDispenser"),
    GroupTrough: pathRenderer("GroupTrough"),
    CageTwoWayFloor: pathRenderer("CageTwoWayFloor"),
    CageTwoWayForeground: pathRenderer("CageTwoWayForeground"),
    CageTwoWayOutline: pathRenderer("CageTwoWayOutline"),
    CageDoorSmall: pathRenderer("CageDoorSmall"),
    CageDoorBig: pathRenderer("CageDoorBig"),
    IpsumFloor: pathRenderer("IpsumFloor"),
    IpsumTrough: pathRenderer("IpsumTrough"),
    IpsumForeground: pathRenderer("IpsumForeground"),
    IpsumOutline: pathRenderer("IpsumOutline"),
    NutriProV2Trough: pathRenderer("NutriProV2Trough"),
    NutriProV2Outline: pathRenderer("NutriProV2Outline"),
};