import _ from "lodash";

const initialValue = {
    commandsHistory: [],
    fetching: false,
    fetched: false,
    lastEvaluatedKey: undefined
};

export default function commandsHistoryReducer(state = initialValue, action) {

    switch (action.type) {
        case "LIST_COMMANDS_HISTORY_PENDING":{
            return {...state, ...initialValue, lastEvaluatedKey: state.lastEvaluatedKey, commandsHistory: state.commandsHistory, fetching: true}
        }
        case "LIST_COMMANDS_HISTORY_REJECTED":{
            return {...state, ...initialValue, lastEvaluatedKey: state.lastEvaluatedKey, commandsHistory: state.commandsHistory}
        }
        case "LIST_COMMANDS_HISTORY_FULFILLED":{
            let actionOldestTime = _.get(action.payload.LastEvaluatedKey,  "RTime", 0);
            let currentOldestTime = _.get(state.lastEvaluatedKey,  "RTime");
            const currentNewestTime = _.get(state.commandsHistory[0], "RTime", 0);
            const dataTooNew = actionOldestTime > currentNewestTime;
            let lastEvaluatedKey =(_.isNil(currentOldestTime) || dataTooNew || (actionOldestTime <= currentOldestTime))  ? action.payload.LastEvaluatedKey : state.lastEvaluatedKey;
            return {...state, commandsHistory: _.uniqBy([...(dataTooNew ? [] : state.commandsHistory), ...action.payload.items], o=>o.MsgID), lastEvaluatedKey: lastEvaluatedKey, fetched: true, fetching: false}
        }
        case "CHANGE_FARM":
        case "CLEAR_COMMANDS_HISTORY":
        case "USER_LOGOUT_FULFILLED":
            return initialValue;
        default:
            return state
    }
}
