export const FilterTypes = {
    LOCATION: "location",
    NUMBER: "number",
    STRING: "string",
    DATE: "date",
    CUSTOM: "custom",
    DEV_ADDRESS: "address",
    USG_RESULT: "usg",
    RESULT_STATUS: "resultStatus"
};

export const LocationFilterTypes = {
    EVERYTHING_BELOW: "everythingBelow",
    ONLY_LOCATION: "onlyLocation"
};
