import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { getRemToPx, isOverlapping } from "../../../utils/DOMUtils";

// class Label extends Component {

//     // constructor(props) {
//     //     super(props);
//     //     this.label = React.createRef();
//     // }

//     // moves label position if it does not fit inside chart
//     // fixPosition = memoizeOne((x) => {
//     //     const { container } = this.props;
//     //     let newX;
//     //     try {
//     //         // get chart size (rect where data is drawn)
//     //         const bbox = container.current.getElementsByClassName("recharts-surface")[0].getElementsByTagName("defs")[0].firstElementChild.getBBox();
//     //         // get label offset
//     //         const offset = this.label.current.getBoundingClientRect().width / 2;
//     //         newX = Math.round(Math.min(Math.max(x, bbox.x + offset), bbox.x + bbox.width - offset));
//     //     } catch (err) {
//     //         console.warn(err);
//     //         newX = x;
//     //     }
//     //     // move label to new position
//     //     if (this.label.current && isFiniteNumber(newX)) {
//     //         this.label.current.setAttribute("x", newX);
//     //     }

//     // })

//     // componentDidMount() {
//     //     const { viewBox: { x } } = this.props;
//     //     this.fixPosition(x);
//     // }

//     // componentDidUpdate(prevProps, prevState, snapshot) {
//     //     const { viewBox: { x } } = this.props;
//     //     this.fixPosition(x);
//     // }

//     getFontSize = memoizeOne((fontSizeRatio) => Math.round(getRemToPx(1) * (fontSizeRatio || 1)));

//     render() {
//         const { label, viewBox: { x, y }, color, fontSizeRatio, } = this.props;
//         return (
//             <text className={"reference-line-text"} stroke={color}
//                 fontSize={this.getFontSize(fontSizeRatio)} x={x} y={y}
//                 textAnchor="end" transform={`rotate(-90, ${x + 4}, ${y})`}>
//                 {label}
//             </text>
//         );
//     }
// }

function VerticalLabel({ label, viewBox: { x, y }, color, fontSize }) {
    return (
        <text className={"reference-line-text"} stroke={color}
            fontSize={fontSize} x={x} y={y}
            textAnchor="end" transform={`rotate(-90, ${x + 4}, ${y})`}>
            {label}
        </text>
    );
}

function AutoLabelPosition({ label, viewBox, color, fontSize }) {

    const text = useRef();
    const [overlapping, setOverlapping] = useState(false);

    const observerCallback = useCallback(() => {
        if (!text.current?.parentElement) return;
        // mozna przerzucic do charta jezeli bedzie mialo duzy wplyw na performance, aczkolwiek debounce powinien swoje zrobic
        const otherLines = [...text.current.parentElement.parentElement.querySelectorAll(".recharts-reference-line")];
        if (otherLines.length === 1) return;
        let biggestLine = otherLines[0]; // find line with biggest rect from all lines, that line is checked if overlapping any others
        let biggestLineRect = biggestLine.getBoundingClientRect();
        for (let i = 1; i < otherLines.length; i++) {
            const lineRect = otherLines[i].getBoundingClientRect();
            if (lineRect.width > biggestLineRect.width) {
                biggestLine = otherLines[i];
                biggestLineRect = lineRect;
            }
        }
        let overlappingAny = false;
        const indexOfLine = otherLines.indexOf(biggestLine);
        for (let line of otherLines.slice(indexOfLine - 1, indexOfLine + 2)) { // check only 2 closest lines to biggest
            if (line !== biggestLine) {
                const lineRect = line.getBoundingClientRect();
                const overlapping = isOverlapping(biggestLineRect, lineRect);
                if (overlapping) {
                    overlappingAny = true;
                    break;
                }
            }
        }
        setOverlapping(overlappingAny);
    }, []);

    useLayoutEffect(() => {
        observerCallback();
    }, []); // eslint-disable-line

    const debouncedObserverCallback = useDebounce(observerCallback, 100);

    const observer = useRef(new MutationObserver(debouncedObserverCallback));

    useEffect(() => {
        const tmp = text.current;
        const observerTmp = observer.current;
        if (tmp) {
            observerTmp.observe(tmp, { attributes: true });
        }
        return () => {
            observerTmp.disconnect();
        }
    }, []);

    // komponent renderuje 2 razy to samo (jezeli pokrywa sie z innym labelem), aby znac szerokosc komponentu caly czas
    return (
        <>
            {
                overlapping && <VerticalLabel color={color} fontSize={fontSize} label={label} viewBox={viewBox} />
            }
            <text
                className={"reference-line-text"}
                stroke={color}
                fontSize={fontSize} x={viewBox.x + 1} y={viewBox.y + 10}
                textAnchor="middle"
                ref={text} style={overlapping ? { opacity: 0 } : {}}>
                {label}
            </text>
        </>
    );
}

export default function Label({ label, viewBox, color, fontSizeRatio, textPosition }) {

    const fontSize = useMemo(() => {
        return Math.round(getRemToPx(1) * (fontSizeRatio || 1));
    }, [fontSizeRatio])

    if (textPosition === "AUTO") return <AutoLabelPosition color={color} fontSize={fontSize} label={label} viewBox={viewBox} />;
    return (
        <VerticalLabel color={color} fontSize={fontSize} label={label} viewBox={viewBox} />
    );
}