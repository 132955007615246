import {Level} from "../constans/levelTypes";
import {get, isFinite} from "lodash";
import {createSelector} from "reselect";
import {convertBuildingsToOldObject} from "../utils/dbutils/convertBuildings";
import memoizeOne from "memoize-one";
import {isFiniteNumber} from "../utils/MathUtils";
import {createKeyToIndexDictionary} from "../utils/Utils";

const getShowDeleted = (state, props) => !!props && props.showDeleted

const getBuildingsRaw = (state) =>
    state.farms.buildings

const getFarms = (state) =>
    state.farms.farms

const getClosestParams = (state, {id, level} = {}) => {
    return {id, level};
}

const getPlacementID = (state, {PlcmntID}) => PlcmntID

const convertBuildingsToOldObjectMemoized = memoizeOne(convertBuildingsToOldObject);

export const getBuildings = createSelector([getBuildingsRaw, getShowDeleted], (buildings, showDeleted) => {
    return showDeleted ? buildings : convertBuildingsToOldObjectMemoized(buildings);
})


export const makeGetBuildingsMap = () => createSelector([getBuildings, getFarms], (_buildings, _farms) => {
    const map = new Map();
    console.time("getBuildingsMap");
    console.log("getBuildingsMap selector called");
    (_farms || []).forEach(farm => {
        map.set(farm.FarmID, {
            level: Level.FARM,
            parentId: farm.FarmID,
            name: [farm.FarmName],
            id: farm.FarmID
        })
    });
    (_buildings || []).forEach(building => {
        map.set(building.BgID, {
            level: Level.BUILDING,
            parentId: building.FarmID,
            name: [building.BName],
            id: building.BgID,
            isDeleted: isFinite(building.DtaDelTime)
        })
        get(building, "Sectors", []).forEach(sector => {
            const SType = isFiniteNumber(sector.SType) ? sector.SType : +sector.SType;
            map.set(sector.SID, {
                level: Level.SECTOR,
                parentId: building.BgID,
                name: [building.BName, sector.SName],
                id: sector.SID,
                SType,
                isDeleted: isFinite(building.DtaDelTime) || isFinite(sector.DtaDelTime)
            })
            get(sector, "Chambers", []).forEach(chamber => {
                map.set(chamber.CID, {
                    level: Level.CHAMBER,
                    parentId: sector.SID,
                    name: [building.BName, sector.SName, chamber.CName],
                    id: chamber.CID,
                    SType,
                    isDeleted: isFinite(building.DtaDelTime) || isFinite(sector.DtaDelTime) || isFinite(chamber.DtaDelTime),
                    isIndividualFeeding: !!chamber.IndividualFeeding
                })
                get(chamber, "Boxes", []).forEach(box => {
                    map.set(box.BID, {
                        level: Level.BOX,
                        parentId: chamber.CID,
                        name: [building.BName, sector.SName, chamber.CName, box.BoxesName],
                        id: box.BID,
                        SType,
                        isDeleted: isFinite(building.DtaDelTime) || isFinite(sector.DtaDelTime) || isFinite(chamber.DtaDelTime) || isFinite(box.DtaDelTime)
                    })
                })
            })
        })
    });
    console.timeEnd("getBuildingsMap");
    return map;
});

export const getBuildingsMap = makeGetBuildingsMap();


export const makeGetClosestPlacementByID = () => {
    return createSelector([getBuildingsMap, getClosestParams], (_buildingsMap, _params) => {
        if (!_params.id || !_params.level) return null;
        const {id, level} = _params;
        const getPlacement = (plcmntId) => {
            const placement = _buildingsMap.get(plcmntId);
            if (!placement) return null;
            if (placement.level === level) return placement;
            else {
                if (plcmntId === placement.parentId) return null;
                return getPlacement(placement.parentId)
            }
        }
        return getPlacement(id);
    })
}

const _getManageBuildingsList = memoizeOne((_buildings) => {
    const flat = [];
    console.log("getFlatBuildings selector called")
    _buildings.forEach(building => {
        const isBuildingDeleted = isFinite(building.DtaDelTime);
        flat.push({
            level: Level.BUILDING,
            parentId: building.FarmID,
            name: building.BName,
            id: building.BgID,
            isDeleted: isBuildingDeleted,
            rfid: !isBuildingDeleted ? building.RFID : null
        })
        get(building, "Sectors", []).forEach(sector => {
            const sectorType = isFiniteNumber(sector.SType) ? sector.SType : +sector.SType;
            const isSectorDeleted = building.isDeleted || isFinite(sector.DtaDelTime);
            flat.push({
                level: Level.SECTOR,
                parentId: building.BgID,
                name: sector.SName,
                id: sector.SID,
                sectorType,
                isDeleted: isSectorDeleted,
                rfid: !isSectorDeleted ? sector.RFID : null
            })
            get(sector, "Chambers", []).forEach(chamber => {
                const isChamberDeleted = sector.isDeleted || isFinite(chamber.DtaDelTime);
                flat.push({
                    level: Level.CHAMBER,
                    parentId: sector.SID,
                    name: chamber.CName,
                    id: chamber.CID,
                    sectorType,
                    individualFeeding: !!chamber.IndividualFeeding,
                    standsInRow: chamber.StandsInRow || 1,
                    standsOrder: chamber.StandsOrder || 0,
                    letter: chamber.Letter || 0,
                    number: chamber.Number || 0,
                    chamberSize: chamber.IndividualFeeding ? chamber.Boxes.length : chamber.CSize,
                    isDeleted: isChamberDeleted,
                    rfid: !isChamberDeleted ? chamber.RFID : null,
                    hasBoxes: !!chamber.Boxes
                })
                get(chamber, "Boxes", []).forEach(box => {
                    flat.push({
                        level: Level.BOX,
                        parentId: chamber.CID,
                        name: box.BoxesName,
                        id: box.BID,
                        rfid: box.RFID || null,
                        sectorType,
                        isDeleted: chamber.isDeleted || isFinite(box.DtaDelTime)
                    })
                })
            })
        })
    });
    return flat;
});

export const getManageBuildingsList = createSelector([getBuildings], _getManageBuildingsList);


export const getManageBuildingsListHelper = createSelector([getManageBuildingsList], (buildings) => {
    const idIndex = createKeyToIndexDictionary(buildings, "id");
    return {
        buildings,
        getById: (id) => buildings[idIndex[id ?? -1]] ?? null
    }
})

export const makeGetManageBuildingsList = () => {
    return createSelector([getBuildings], _getManageBuildingsList);
}

export const getChildrenPlcmntIDsByPlcmntID = createSelector([getPlacementID, getManageBuildingsList], (_plcmntId, _list) => {
    const PlcmntIDs = [_plcmntId];
    const setPlcmntIDs = (parentId) => {
        _list.forEach(item => {
            if (item.parentId === parentId) {
                PlcmntIDs.push(item.id);
                setPlcmntIDs(item.id);
            }
        })
    }
    setPlcmntIDs(_plcmntId);
    console.log(PlcmntIDs, _plcmntId, _list, "L:D");
    return [...new Set(PlcmntIDs)];
})

export const getAllChambers = createSelector(getBuildings, buildings => {
    let chambers = [];
    for (let building of buildings) {
        for (let sector of building.Sectors || []) {
            chambers.push(...(sector.Chambers || []));
        }
    }
    return chambers;
})

const getLocationIDFromRouter = (state, props) => props.match.params.locationID;

export const getLocationFromRouter = createSelector(getBuildingsMap, getLocationIDFromRouter, (buildings, locationID) => {
    return buildings.get(locationID) || null;
})
