import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./_helper-icon.scss";

class HelperIcon extends Component {

    getClassName = () => {
        const {className = "", background, type, color, size} = this.props;
        const _bg = (className.includes("background-") || background === "none") ? "" : `background-${background}`;
        const _type = className.includes("type-") ? "" : type;
        const _color = (className.includes("color-") || !color) ? "" : `color-${color}`;
        const _size = (className.includes("size-") || !size) ? "" : `size-${size}`;
        return ["fetura-helper-icon", className, _bg, _type, _color, _size].filter(o => !!o).join(" ");
    }

    render() {
        const className = this.getClassName();
        return (
            <i className={className}/>
        );
    }
}

HelperIcon.propTypes = {
    background: PropTypes.oneOf(["none", "disabled", "error", "warning", "success", "info"]),
    color: PropTypes.oneOf(["disabled", "error", "warning", "success", "info"]),
    size: PropTypes.oneOf(["s", "m", "l"]),
    className: PropTypes.string,
    type: PropTypes.oneOf(["round", "rect"])
};

HelperIcon.defaultProps = {
    background: "none",
    type: "type-round"
}

export default HelperIcon;
