import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import i18n from "../../i18n";

export function editAnimalGroups(groups, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.updateGroup({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: groups
    });
}

export function updateAnimalGroupsNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("success"),
        message: i18n.t("popNotifications.technoGroupUpdated"),
        status: "success",
        dismissible: true,
        dismissAfter: 5000,
    }))
}


export function updateAnimalGroupsNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("popNotifications.updateErr"),
        message: i18n.t("popNotifications.technoGroupUpdatedFailure"),
        status: "error",
        dismissible: true,
        dismissAfter: 15000,
    }))
}