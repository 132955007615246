import React from "react";
import {parseCage} from "../parsers";
import DeviceDefaultContent from "./DeviceDefaultContent";
import {useTranslation} from "react-i18next";


const CageContent = (props) => {
    const {t} = useTranslation();
    return <DeviceDefaultContent {...props} parseDataFn={parseCage}
                                 name={t("modals.cageWeightConfiguration.separationCage")}
                                 paramLabel={t("averageWeightAnimal")}/>
}


export default React.memo(CageContent);