import React, {useMemo} from "react";
import {CustomEntityTypes, isStandaloneEntity} from "../../../utils/FarmMapUtils";
import CageTwoWay from "./devices/CageTwoWay";
import Ipsum from "./devices/Ipsum";
import NutriProV2 from "./devices/NutriProV2";
import NutriPro from "./devices/NutriPro";
import {uniqBy} from "lodash";
import {validateClassName} from "./utils";

const {CAGE_2WAY, CAGE_3WAY, IPSUM, NUTRI_PRO, NUTRI_PRO_V2} = CustomEntityTypes;

const getStates = (entityType) => {
    switch (entityType) {
        case IPSUM:
        case NUTRI_PRO:
        case NUTRI_PRO_V2:
            return [["error", "warning", ""]];
        case CAGE_2WAY:
            return [["error", "warning", ""], [null, "enter-0", "enter-1"], ["mid-0", "mid-1"], ["exit-left", "exit-right"]];
        case CAGE_3WAY:
            return [["error", "warning", ""], [null, "enter-0", "enter-1"], ["mid-0", "mid-1"], ["exit-left", "exit-right", "exit-middle"]];
        default:
            return [];
    }
}

const getHorizontalStates = (entityType) => {
    const standalone = isStandaloneEntity(entityType);
    return standalone ? getStates(entityType) : [["left"/*, "right"*/], ...getStates(entityType)];
}

const getVerticalStates = (entityType) => {
    const standalone = isStandaloneEntity(entityType);
    return standalone ? getStates(entityType) : [["up"/*, "right"*/], ...getStates(entityType)];
}

const getDefArray = (name, state = []) => {
    const all = state.length;
    const states = [];
    const combinations = (current = []) => {
        const len = current.length;
        if (len === all) {
            const nullIdx = current.findIndex((o) => o === null);
            const cur = nullIdx !== -1 ? current.slice(0, nullIdx) : current;
            const id = [name, ...cur].filter(o => !!o).join("-");
            const className = ["def-device", ...cur.filter(validateClassName)].join(" ");
            const props = {className};
            for (let c of cur) {
                if (c) {
                    props[c] = true;
                }
            }
            states.push({
                id,
                props
            })
        } else {
            for (let i = 0; i < (state[len]?.length || 0); i++) {
                combinations([...current, state[len][i]]);
            }
        }
    }
    combinations([]);
    return uniqBy(states, ({id}) => id);
};

const getComponent = (entityName) => {
    switch (entityName) {
        case CAGE_2WAY:
            return (props) => CageTwoWay(props, props._type);
        case IPSUM:
            return (props) => Ipsum(props, props._type);
        case NUTRI_PRO_V2:
            return (props) => NutriProV2(props, props._type);
        case NUTRI_PRO:
            return (props) => NutriPro(props, props._type);
        default:
            return () => null
    }
};

function Devices() {

    const data = useMemo(() => [CAGE_2WAY, CAGE_3WAY, IPSUM, NUTRI_PRO, NUTRI_PRO_V2].map((entityType) => {
        const standalone = isStandaloneEntity(entityType);
        return {
            horizontal: standalone ? [] : getDefArray(`ue-${entityType}`, getHorizontalStates(entityType)),
            vertical: getDefArray(`ue-${entityType}`, getVerticalStates(entityType)),
            Component: getComponent(entityType)
        };
    }), [])
    return (
        <>
            {
                data.map(({Component, horizontal, vertical}, j) => (
                    <>
                        {horizontal.map(({id, props}, i) => (
                            <Component key={`device_${j}-h-${i}`} id={id} {...props} _type={"horizontal"}/>
                        ))}
                        {vertical.map(({id, props}, i) => (
                            <Component key={`device_${j}-v-${i}`} id={id} {...props} _type={"vertical"}/>
                        ))}
                    </>
                ))
            }
        </>
    )

}

export default React.memo(Devices);
