import {detect} from "detect-browser";

export function isMobile() {
    try {
        const browser = detect();
        if (browser.os.startsWith("Android")) return true;
        if (browser.os.startsWith("iPad")) return true;
        if (browser.os.startsWith("iPhone")) return true;
        if (browser.os.startsWith("iOS")) return true;
        if (/Macintosh/.test(navigator.userAgent) && 'ontouchend' in document) return true;
    } catch (e) {
        console.error("isMobile error", e.name, e.message);
    }
    return false;

}

/**
 * returns true if we are dealing with ios-based device
 * if you want to detect Safari use Browser.Safari() from our basics components collection!
 * @deprecated - temporary function - will get deleted as soon as ios native select is bug free
 * @return {boolean}
 */
export function isIOS() {
    try {
        const browser = detect();
        if (browser.os.startsWith("iPad")) return true;
        if (browser.os.startsWith("iPhone")) return true;
        if (browser.os.startsWith("iOS")) return true;
    } catch (e) {
        console.error("isIOS error", e.name, e.message);
    }
    return false;
}