import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {getUserApiData} from "../../utils/UserUtils";

export function getAllUsers({FarmID, ClientID, LocalUserID} = {}, signal) {
    console.log("getAllUsers")
    const {_LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.getAllUsers({clientID: _ClientID, localUserID: _LocalUserID}),
        signal
    })
}
