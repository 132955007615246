import int24 from "int24";
import {memoize} from "lodash";

export const Type = {
    U8: "U8",
    U16: "U16",
    U24: "U24",
    U32: "U32",
    U64: "U64",
    I8: "I8",
    I16: "I16",
    I24: "I24",
    I32: "I32",
    I64: "I64",
    F32: "F32"
};

/**
 * returns octet length
 * @type {any}
 */
export const sizeof = memoize((type) => {
    if (!Type[type]) return 0;
    return +(Type[type].match(/\d+$/)[0]) / 8;
})

export const makeParser = (littleEndian = false) => {
    const parsers = {};
    const formatters = {};

    const utils = {
        makeBuf: (t) => Buffer.alloc(sizeof(t)),
        dataViewToArray: (dataView) => {
            const array = [];
            for (let i = 0; i < dataView.byteLength; i++) {
                array[i] = dataView.getUint8(i);
            }
            return array;
        }
    };
    /*
      formatters - buffer to value
     */
    // unsigned integers
    formatters[Type.U8] = (dataView) => dataView.getUint8(0, littleEndian);
    formatters[Type.U16] = (dataView) => dataView.getUint16(0, littleEndian);
    formatters[Type.U24] = (dataView) => int24[`readUInt24${littleEndian ? "LE" : "BE"}`](utils.dataViewToArray(dataView), 0);
    formatters[Type.U32] = (dataView) => dataView.getUint32(0, littleEndian);
    formatters[Type.U64] = (dataView) => dataView.getBigUint64(0, littleEndian);
    // signed integers
    formatters[Type.I8] = (dataView) => dataView.getInt8(0, littleEndian);
    formatters[Type.I16] = (dataView) => dataView.getInt16(0, littleEndian);
    formatters[Type.I24] = (dataView) => int24[`readInt24${littleEndian ? "LE" : "BE"}`](utils.dataViewToArray(dataView), 0);
    formatters[Type.I32] = (dataView) => dataView.getInt32(0, littleEndian);
    formatters[Type.I64] = (dataView) => dataView.getBigInt64(0, littleEndian);
    // floating point number
    formatters[Type.F32] = (dataView) => dataView.getFloat32(0, littleEndian);

    /*
      parsers - value to buffer
     */
    // unsigned integers
    parsers[Type.U8] = (value) => {
        const buf = utils.makeBuf(Type.U8);
        buf.writeUInt8(value);
        return buf;
    };
    parsers[Type.U16] = (value) => {
        const buf = utils.makeBuf(Type.U16);
        littleEndian ? buf.writeUInt16LE(value) : buf.writeUInt16BE(value);
        return buf;
    };
    parsers[Type.U24] = (value) => {
        const buf = utils.makeBuf(Type.U24);
        littleEndian ? int24.writeInt24LE(buf, 0, value) : int24.writeInt24BE(buf, 0, value);
        return buf;
    };
    parsers[Type.U32] = (value) => {
        const buf = utils.makeBuf(Type.U32);
        littleEndian ? buf.writeUInt32LE(value) : buf.writeUInt32BE(value);
        return buf;
    };
    parsers[Type.U64] = (value) => {
        const buf = utils.makeBuf(Type.U64);
        littleEndian ? buf.writeBigUInt64LE(BigInt(value)) : buf.writeBigUInt64BE(BigInt(value));
        return buf;
    };
    // signed integers
    parsers[Type.I8] = (value) => {
        const buf = utils.makeBuf(Type.I8);
        buf.writeInt8(value);
        return buf;
    };
    parsers[Type.I16] = (value) => {
        const buf = utils.makeBuf(Type.I16);
        littleEndian ? buf.writeInt16LE(value) : buf.writeInt16BE(value);
        return buf;
    };
    parsers[Type.I24] = (value) => {
        const buf = utils.makeBuf(Type.I24);
        littleEndian ? int24.writeInt24LE(buf, 0, value) : int24.writeInt24BE(buf, 0, value);
        return buf;
    };
    parsers[Type.I32] = (value) => {
        const buf = utils.makeBuf(Type.I32);
        littleEndian ? buf.writeInt32LE(value) : buf.writeInt32BE(value);
        return buf;
    };
    parsers[Type.I64] = (value) => {
        const buf = utils.makeBuf(Type.I64);
        littleEndian ? buf.writeBigInt64LE(BigInt(value)) : buf.writeBigInt64BE(BigInt(value));
        return buf;
    };
    // floating point number
    parsers[Type.F32] = (value) => {
        const buf = utils.makeBuf(Type.F32);
        // cannot use Buffers' `writeFloat32LE` as it always returns empty buffer in browser
        // dataView's `setFloat32` comes in handy
        const dv = new DataView(buf.buffer);
        dv.setFloat32(0, value, littleEndian)
        return buf;
    }

    /*
     UTILS
     */

    /**
     * change hex string to buffer
     * @param hexString {string} hex string i.e: "0xA2" or "A2"
     * @param size {number} optional size of buffer (0 - auto size)
     * @return {Uint8Array}
     */
    utils.hexString2Buf = (hexString, size = 0) => {
        if (typeof hexString !== "string") throw new Error("not a string");
        if (!(/^(0x)?[0-9a-f]+$/i).test(hexString)) {
            throw new Error("not a valid hex");
        }
        hexString = hexString.replace("0x", "");
        const hexBuf = Buffer.from(hexString, "hex");
        const source = new Uint8Array(hexBuf.buffer);
        if (size === 0) return source;
        const buf = Buffer.alloc(size);
        const destination = new Uint8Array(buf.buffer);
        const isZero = hexString.split("").every((o) => o === "0");
        if (isZero) return destination;
        destination.set(source, 0);
        return destination;
    }

    /**
     * change buffer to hex string
     * @param dataView
     * @param use0xPrefix {boolean}
     * @return {string} - hex string with 0x prefix i.e: "0xA2"
     */
    utils.buf2HexString = (dataView, use0xPrefix = true) => {
        const hex = new Array(dataView.byteLength).fill(0).map((o, i) => {
            return (dataView.getUint8(i).toString(16).toUpperCase()).padStart(2, "0");
        }).join("");
        return use0xPrefix ? `0x${hex}` : hex;
    }

    utils.arr2HexString = (array, use0xPrefix = true) => {
        const hex = array.map((o) => {
            return (o.toString(16).toUpperCase()).padStart(2, "0");
        }).join("");
        return use0xPrefix ? `0x${hex}` : hex;
    }

    return {
        parsers,
        formatters,
        utils,
        hex: {
            toBuffer: (type, hexString) => {
                return utils.hexString2Buf(hexString, sizeof(type))
            },
            toString: (type, dataView) => {
                return utils.buf2HexString(dataView);
            }
        },
        toBuffer: (type, value) => parsers[type](value),
        toValue: (type, dataView) => {
            try {
                return formatters[type](dataView)
            } catch (er) {
                console.log(er);
                return null;
            }
        }
    }

}