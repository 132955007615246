import React, {Component} from 'react';
import "./_video-player-progress.scss";
import PropTypes from 'prop-types';

class VideoPlayerProgress extends Component {

    progress = React.createRef();
    shadow = React.createRef();
    tooltip = React.createRef();

    onMouseEnter = e => {
        document.addEventListener("mousemove", this.onMouseMove)
    }

    onMouseLeave = e => {
        document.removeEventListener("mousemove", this.onMouseMove);
    }

    getVideo() {
        return this.progress.current.parentElement.parentElement.parentElement.parentElement.firstChild;
    }

    setTooltipText(percent) {
        let video = this.getVideo();
        let timePercent = Math.floor(video.duration * (percent / 100));
        let seconds = timePercent % 60;
        let minutes = Math.floor(timePercent / 60);
        this.tooltip.current.firstChild.innerHTML = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }

    setTooltipPosition(percent) {
        let tooltipWidth = this.tooltip.current.clientWidth / 2;
        let progressWidth = this.progress.current.clientWidth;
        let tooltipWidthPercent = (tooltipWidth / progressWidth) * 100;
        if (tooltipWidthPercent + percent > 100) {
            this.tooltip.current.style.left = `calc(100% - ${tooltipWidth * 2}px)`;
        } else if (percent - tooltipWidthPercent < 0) {
            this.tooltip.current.style.left = `0px`;
        } else {
            this.tooltip.current.style.left = `calc(${Math.round(percent)}% - ${tooltipWidth}px)`;
        }
    }

    onMouseMove = e => {
        let percent = (e.offsetX / this.progress.current.offsetWidth) * 100;
        this.shadow.current.style.width = Math.round(percent) + "%";
        this.setTooltipPosition(percent);
        this.setTooltipText(percent);
    }

    render() {
        const {onProgressClick} = this.props;
        return (
            <div className="video-player-progress" onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                <div className="progress" onClick={onProgressClick} ref={this.progress}>
                    <div className="progress-bar"/>
                    <div className="video-player-progress-shadow" ref={this.shadow}/>
                    <div className="fade show tooltip bs-tooltip-top" ref={this.tooltip}>
                        <div className="tooltip-inner"/>
                    </div>
                </div>
            </div>
        );
    }
}

VideoPlayerProgress.propTypes = {
    onProgressClick: PropTypes.func.isRequired,
};

export default VideoPlayerProgress;
