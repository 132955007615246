import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {parseIpsum} from "../parsers";
import DeviceDefaultContent from "./DeviceDefaultContent";
import {createKeysAndLabels} from "./utils";


const IpsumContent = (props) => {
    const {t} = useTranslation();
    const transformErrors = useCallback((state) => {
        if(state.errors.hasError(5803)){
            return {...state, isErrorInChildren: true}
        }
        return state;
    }, []);
    
    const {keys, labels} = useMemo(() => {
        return createKeysAndLabels(
            {
                key: "text",
                label: t("averageAnimalWeight")
            },
            {
                key: "textDispenserStatus",
                label: t("dispenserSensor")
            },
            {
                key: "textFeederStatus",
                label: t("chainFeederSensor")
            }
        )
    }, [t])
    return <DeviceDefaultContent {...props} parseDataFn={parseIpsum} name={t("ipsum")}
                                 paramKeys={keys}
                                 paramLabel={labels} transformErrors={transformErrors}/>
}


export default React.memo(IpsumContent);