import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import UserTypes from "@wesstron/utils/Api/constants/userTypes";

export function getServiceAccounts({LocalUserID} = {}) {
    const {_LocalUserID} = getUserApiData({LocalUserID});
    return invokeApig({
        ...Paths.getServiceAccounts({localUserID: _LocalUserID}),
    })
}

export function getSupportAccounts({LocalUserID} = {}) {
    const {_LocalUserID} = getUserApiData({LocalUserID});
    return invokeApig({
        ...Paths.getServiceAccounts({localUserID: _LocalUserID}),
        queryParams: {UserType: UserTypes.SUPPORT}
    })
}

export function getServiceClientsMap({LocalUserID, ServiceLocalUserID} = {}) {
    const {_LocalUserID} = getUserApiData({LocalUserID});
    return invokeApig({
        ...Paths.getServiceClientsMap({localUserID: _LocalUserID}),
        queryParams: {ServiceLocalUserID}
    });
}
