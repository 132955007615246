import { createStructuredSelector } from "reselect";

export const getSideBarExpanded = (state) => state.sideBar.expanded;

const isGlobalLoading = state => state.view.globalLoading;

const getGlobalLoadingText = state => state.view.globalLoadingText;

const getGlobalLoadingTextTranslate = state => state.view.globalLoadingTextTranslate;

export const getGlobalLoadingTextData = createStructuredSelector({ text: getGlobalLoadingText, translate: getGlobalLoadingTextTranslate, globalLoading: isGlobalLoading })