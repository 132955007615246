import React, {Component} from "react";
import "./_tab-duplicated.scss";
import Card from "../../components/basics/card/Card";
import {Col, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";

export class TabDuplicatedView extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className="duplicated-tab col-12">
                <Row bsPrefix={"row h-100 justify-content-center align-items-center w-100 mx-auto"}>
                    <Col lg={6}>
                        <Card>
                            <h4 className="align-items-center">
                                <i className="fas fa-fw fa-exclamation-triangle"/>{" "}{t("tabDuplicatedView.title")}
                            </h4>
                            <p>
                                {t("tabDuplicatedView.messageLong1")}{" "}
                                {" "}
                                {t("tabDuplicatedView.messageLong2")}
                            </p>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withTranslation(NameSpaces.TRANSLATION)(TabDuplicatedView);
