import React from "react";
import PropTypes from "prop-types";
import LabeledSelect from "./LabeledSelect";
import {get, isNil, isObject} from "lodash";
import memoizeOne from "memoize-one";

export const SortKey = {ADDRESS: 'DevAdr'};

export default class ReduxLabeledSelect extends React.PureComponent {

    onChange = (value) => {
        this.props.input.onChange(value);
    };

    onBlur = (value) => {
        this.props.input.onBlur(value);
    };


    makeSortOptions = () => {
        return memoizeOne((options) => {
            const {sortKey = "name"} = this.props;
            return [...options].sort((a, b) => {
                const value1 = sortKey !== "name" ? get(a.value, sortKey) : get(a, sortKey);
                const value2 = sortKey !== "name" ? get(b.value, sortKey) : get(b, sortKey);
                const check = (val) => (isNil(val) ? 0 : isObject(val) ? JSON.stringify(val) : val).toString();
                return check(value1).localeCompare(check(value2), undefined, {numeric: true, sensitivity: 'base'});
            });
        });
    }

    sortValues = this.makeSortOptions();

    sortFilteredValues = this.makeSortOptions();

    render() {
        const {
            mobile, label, options, id, input: {value}, meta: {touched, error, warning}, required,
            disabled, clearButton, disableSort, onSearchChange, initialSearch, autofocus, placeholder, autocomplete,
            disableFilter, callOnChangeOnSingleOption, filteredOptions, customRenderer, initDelay
        } = this.props;
        return (
            <LabeledSelect
                required={required}
                label={label}
                options={disableSort ? options : this.sortValues(options)}
                filteredOptions={(!filteredOptions || disableSort) ? filteredOptions : this.sortFilteredValues(filteredOptions)}
                onChange={this.onChange}
                warning={warning ? warning : ""}
                onSearchChange={onSearchChange}
                placeholder={placeholder}
                initialSearch={initialSearch}
                autofocus={autofocus}
                id={id}
                initDelay={initDelay}
                mobile={mobile}
                value={value}
                error={touched && error ? error : ""}
                disabled={disabled}
                onBlur={this.onBlur} clearButton={clearButton}
                autocomplete={autocomplete}
                disableFilter={disableFilter}
                callOnChangeOnSingleOption={callOnChangeOnSingleOption}
                customRenderer={customRenderer}
            />
        )
    }

}

ReduxLabeledSelect.propTypes = {
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string, // nazwa pola z obiektu
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired
    })).isRequired,
    id: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    clearButton: PropTypes.bool,
    disableSort: PropTypes.bool,
    onSearchChange: PropTypes.func,
    mobile: PropTypes.bool,
    callOnChangeOnSingleOption: PropTypes.bool,
};
