import React from "react";
import FeedSummary from "./FeedSummary";
import TempSummary from "./TempSummary";
import WaterSummary from "./WaterSummary";
import ElectricSummary from "./ElectricSummary";
import AnimalsSummary from "./AnimalsSummary";
import { compose } from "redux";
import memoizeOne from "memoize-one";
import { isString } from "lodash";
import { getBuildingsMap } from "../../../../selectors/buildingsSelector";
import { getActiveAnimals } from "../../../../selectors/animalSelector";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getMapData, makeGetAlertsData } from "../../../../selectors/farmMapSelector";
import ErrorList from "./ErrorList";
import WarningList from "./WarningList";
import CageSummary from "./CageSummary";
import { Level } from "../../../../constans/levelTypes";
import { isFiniteNumber } from "../../../../utils/MathUtils";
import DeviceControlPanel from "../device-controls/DeviceControlPanel";
import { getDeviceGroup, getFlatDevices, hasPlcmntID } from "../../../../utils/DevicesUtils";
import FilterAnimalDrawerContent from "../animal-controls/FilterAnimalDrawerContent";

const getName = (t, { name, level } = {}) => {
    let value = name.pop();
    switch (level) {
        case Level.BUILDING:
            return `${t("newSettings.buildings.building")} ${value}`;
        case Level.CHAMBER:
            return `${t("grid.chamber")} ${value}`;
        case Level.SECTOR:
            return `${t("newSettings.buildings.sector")} ${value}`;
        case Level.BOX:
            return isFiniteNumber(value) ? `${t("standing")} ${value}` : value;
        default:
            return value;
    }
}

function LocationContent({
    climates = [],
    dispensers = [],
    electricMeters = [],
    waterMeters = [],
    data,
    animals,
    t,
    alerts: { errors, isErrorInChildren, warnings, isWarningInChildren },
    location,
    expanded,
    toggleExpand,
    cages = [],
    lights = [],
    mapType
}) {
    if (!location) return null;
    const name = location.name.slice(0);
    const classNames = ["map-drawer"];
    if (expanded) classNames.push("expanded");
    const devices = [...dispensers, ...climates, ...waterMeters, ...electricMeters, ...cages, ...lights];
    return (
        <div className={classNames.join(" ")}>
            <div className={"toggle-expand"} aria-label={expanded ? t("collapse") : t("animalDocuments.expand")}
                onClick={toggleExpand} />
            <div className={"map-drawer-content"}>
                <div className={"map-drawer-header"}>
                    <div>{getName(t, { name, level: location.level })}</div>
                    <div>{t("farms.tabs.mapTab.inName", { name: name.join(" - ") })}</div>
                </div>
                {
                    mapType === "animals" &&
                    <FilterAnimalDrawerContent animals={animals}/>
                }
                {
                    devices.length > 0 &&
                    <DeviceControlPanel placementId={location.id} devices={devices} />
                }
                <ErrorList t={t} errors={errors} isErrorInChildren={isErrorInChildren} />
                <WarningList t={t} warnings={warnings} isWarningInChildren={isWarningInChildren} />
                <div className={"map-drawer-params"}
                    aria-label={t("shadowDescription.CL_SK3.ambientConditions._title")}>
                    <FeedSummary devices={dispensers} data={data} locationId={location.id} />
                    <TempSummary devices={climates} data={data} />
                    <WaterSummary devices={waterMeters} data={data} />
                    <ElectricSummary devices={electricMeters} data={data} />
                    <CageSummary devices={cages} data={data} />
                </div>
                <AnimalsSummary animals={animals} />
            </div>

        </div>
    );
}

const flattenDevices = memoizeOne((devices) => getFlatDevices(devices, false));

const getDevices = memoizeOne((devices, locationId) => {
    const flat = flattenDevices(devices);
    const result = {};
    for (const { device, index } of flat) {
        const key = getDeviceGroup(device);
        if (key !== "device") {
            if (hasPlcmntID(device, locationId, index)) {
                if (!result[key]) result[key] = [];
                result[key].push({ index, device });
            }
        }
    }
    return result;
});

const getAnimals = memoizeOne((animals, locationIds) => animals.filter((o) => isString(o.PlcmntID) && locationIds.includes(o.PlcmntID)));

const extractAnimalIDs = memoizeOne((locationId, buildingsMap) => {
    if (!locationId) return [];
    const ids = {};
    const getChildren = (id) => {
        ids[id] = true;
        const children = [...buildingsMap.values()].filter(({ parentId }) => parentId === id);
        if (children.length) {
            for (let child of children) {
                if (!ids[child.id]) {
                    getChildren(child.id);
                }
            }
        }
    };
    getChildren(locationId);
    return Object.keys(ids);
})


const makeMapStateToProps = () => {
    return (state, props) => {
        const buildingsMap = getBuildingsMap(state);
        const getAlertsData = makeGetAlertsData();
        return {
            buildingsMap,
            animals: getAnimals(getActiveAnimals(state), extractAnimalIDs(props.selectedId, buildingsMap)),
            data: getMapData(state),
            alerts: getAlertsData(state, props.selectedId, "locations"),
            ...getDevices(state.farmDevices.devices, props.selectedId)
        };
    };
}

export default compose(
    withTranslation(),
    connect(makeMapStateToProps)
)(LocationContent)
