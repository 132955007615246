const initialValue = {
    protocol: {
        loading: false,
        data: []
    }
};

export default function workingCopyReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_WORKING_COPY_PROTOCOL_PENDING": {
            return {...state, protocol: {...state.protocol, loading: true}};
        }
        case "GET_WORKING_COPY_PROTOCOL_FULFILLED": {
            return {...state, protocol: {...state.protocol, loading: false, data: action.payload}};
        }
        case "GET_WORKING_COPY_PROTOCOL_REJECTED": {
            return {...state, protocol: {...state.protocol, loading: false}};
        }
        case "CLEAR_WORKING_COPIES_PROTOCOL": {
            return {...state, protocol: {...state.protocol, data: []}}
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
