import React from "react";
import {withRouter} from "react-router-dom";
import {scrollToTop} from "../../utils/DOMUtils";

export class ScrollToTop extends React.Component {

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            scrollToTop({behaviour: ''});
        }
    }

    render() {
        return this.props.children;
    }

}

export default withRouter(ScrollToTop);