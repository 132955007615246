import React from 'react';
import Element from "./Element";


const ElementRenderer = ({data, mobile, rotate, onBeforeRender, showSmalls}) => {
    return (
        <svg>
            <g>
                {
                    data.map(o => <Element key={o.id}
                                           element={o}
                                           mobile={mobile}
                                           rotate={rotate}
                                           showSmalls={showSmalls}
                                           onBeforeRender={onBeforeRender}/>)
                }
            </g>
        </svg>
    );
}

export default React.memo(ElementRenderer);
