import lokiDB from "./lokiDB"
import { getModificationTime } from "../utils/LokiUtils";
import Backup from "../beans/Backup";

class BackupDB {

    getModificationTime() {
        return getModificationTime("backup");
    }

    save(_backup) {
        lokiDB.backup.chain().find({ Type: _backup.Type }).remove();
        lokiDB.backup.insert({ ..._backup });
        lokiDB.saveDB();
    }

    remove(_backup) {
        lokiDB.backup.chain().find({ Type: _backup.Type }).remove();
        lokiDB.saveDB();
    }

    getByFarmAndName(FarmID, Type) {
        const bp = lokiDB.backup.findOne({
            FarmID,
            Type,
            Used: false,
            Version: {
                '$gte': Backup.VERSION[Type]
            }
        })
        if (!bp) return null;
        return new Backup(bp);
    }
}

const backupDB = new BackupDB();
export default backupDB;
