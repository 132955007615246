import React from "react";
import "../../../../components/grids/animal-documents/_heat-list.scss";
import Tooltip from "../../../../components/basics/tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { isFunction } from "lodash";

export function HeatsComponent({ value, size, onClick }) {

    const getSquareClassNameProperties = ({ heats, color }) => {
        let className = "color-square";
        let style = { borderColor: color };
        if (size) className += ` ${size}`;
        if (heats.length === 0) className += " empty";
        else {
            if (onClick) className += " pointer";
            style.background = color;
        }
        return { className, style };
    };

    const { t } = useTranslation();

    return (
        <div className="heats">
            {
                value.map((item, index) => (
                    <Tooltip tooltipContent={t("grid.weekYearNumber", item)} key={`square_${index}`}>
                        <div id={`square_${index}`} {...getSquareClassNameProperties(item)}
                            onClick={(isFunction(onClick) && item?.heats?.length > 0) ? onClick : null} />
                    </Tooltip>
                ))
            }
        </div>
    )
}