export const initialValue = {
    data: [],
    selectData: [],
    loading: false,
    firstLoading: false,
    saveDate: null,
    selected: null,
    loadingSelect: false
};

export default function technologyGroupReducer(state = initialValue, action) {
    switch (action.type) {
        case "PREPARE_TECHNOLOGY_GROUP_GRID_PENDING":
            return {...state, loading: true};
        case "PREPARE_TECHNOLOGY_GROUP_GRID_REJECTED":
            return {...state, loading: false, data: []};
        case "PREPARE_TECHNOLOGY_GROUP_GRID_FULFILLED":
            return {
                ...state,
                loading: false,
                data: action.payload,
                firstLoading: false,
                saveDate: new Date(),
                selected: state.selected ? action.payload.find(item => item.min === state.selected.min && item.max === state.selected.max) || null : null
            };
        case "SAVED_TECHNOLOGY_GROUPS":
            return {...state, data: action.payload, saveDate: new Date(action.meta)};
        case "START_TECHNOLOGY_GROUP_GRID_LOADING":
            return {...state, firstLoading: true};
        case "SET_SELECTED_TECHNOLOGY_GROUP":
            return {...state, selected: action.payload};
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
