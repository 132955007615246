const initialValue = {
    inMaintenance: false,
    currentMaintenance: undefined,
    futureMaintenance: undefined,
    futureMaintenances: [],
    deletingMaintenances: []
};
export default function maintenanceReducer(state = initialValue, action) {
    switch (action.type) {
        case "MAINTENANCE_STATUS_FULFILLED":
            return {
                ...state,
                inMaintenance: action.payload.serviceInMaintenance,
                currentMaintenance: action.payload.inMaintenance,
                futureMaintenance: action.payload.futureMaintenance,
                futureMaintenances: action.payload.futureMaintenances
            };
        case "MAINTENANCE_STATUS_REJECTED":
            return {
                ...state,
                inMaintenance: false,
                currentMaintenance: undefined,
                futureMaintenance: undefined,
                futureMaintenances: [],
                data: action.payload
            };
        case"USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "MAINTENANCE_STATUS_PENDING": {
            return state;
        }
        case "DELETE_MAINTENANCE_PENDING": {
            const _deletingMaintenances = state.deletingMaintenances.slice();
            _deletingMaintenances.push(action.meta.deletingIndex);
            return {
                ...state,
                deletingMaintenances: _deletingMaintenances
            };
        }
        case "DELETE_MAINTENANCE_FULFILLED": {
            const futureMaintenances = state.futureMaintenances ? state.futureMaintenances.filter(f => f.DtaEndTime !== action.payload.endTime && f.DtaStartTime !== action.payload.startTime) : state.futureMaintenances;
            let _deletingMaintenances = state.deletingMaintenances.slice();
            _deletingMaintenances = _deletingMaintenances.filter((index) => index !== action.meta.deletingIndex);
            return {
                ...state,
                futureMaintenances,
                deletingMaintenances: _deletingMaintenances
            };
        }
        case "DELETE_MAINTENANCE_REJECTED": {
            let _deletingMaintenances = state.deletingMaintenances.slice();
            _deletingMaintenances = _deletingMaintenances.filter((index) => index !== action.meta.deletingIndex);
            return {
                ...state,
                deletingMaintenances: _deletingMaintenances
            };
        }
        default:
            return state;
    }
}
