export const LogCodeType = {
    CREATE_EV_ACTIVE_NIPPLES: 1000,
    CREATE_EV_CONDITION: 1001,
    CREATE_EV_FALL: 1002,
    CREATE_EV_FALL_PIGGLETS: 1003,
    CREATE_EV_PARTURITION: 1004,
    CREATE_EV_PARTURITION_START: 1005,
    CREATE_EV_PARTURITION_END: 1006,
    CREATE_EV_GRAFTING: 1007,
    CREATE_EV_GRAFTING_END: 1008,
    CREATE_EV_HEAT: 1009,
    CREATE_EV_INSEMINATION: 1010,
    CREATE_EV_TO_INSEMINATION: 1011,
    CREATE_EV_TO_REPEAT_INSEMINATION: 1012,
    CREATE_EV_MOMMY: 1013,
    CREATE_EV_NO_PREGNANCY: 1014,
    CREATE_EV_RECLASSIFY: 1015,
    CREATE_EV_SELECTION: 1016,
    CREATE_EV_SELL: 1017,
    CREATE_EV_SEPARATION: 1018,
    CREATE_EV_SEPARATION_TO_MOMMY: 1019,
    CREATE_EV_TRANSFER: 1020,
    CREATE_EV_TREATMENT: 1021,
    CREATE_EV_PI_TREATMENT: 1022,
    CREATE_EV_USG: 1023,
    CREATE_EV_WEIGHTING: 1024,
    CREATE_EV_SOW_CYCLES_WITH_ANIMAL: 1025,
    CREATE_EV_CASTRATION: 1026,
    CREATE_ANIMAL: 1050,
    UPDATE_EV_ACTIVE_NIPPLES: 2000,
    UPDATE_EV_FALL_PIGLETS: 2001,
    UPDATE_EV_PARTURITION: 2002,
    UPDATE_EV_INSEMINATION: 2003,
    UPDATE_EV_MOMMY: 2004,
    UPDATE_EV_NO_PREGNANCY: 2005,
    UPDATE_EV_SEPARATION: 2006,
    UPDATE_EV_SEPARATION_TO_MOMMY: 2007,
    UPDATE_EV_USG: 2008,
    UPDATE_EV_WEIGHTING: 2009
}

export const LogTableTypes = {
    ANIMALS: "Animals",
    DEVICES: "Devices",
    USERS: "Users",
    REPORTS: "Reports",
    SETTINGS: "Settings",
    GENERAL: "General",
    EVENTS: "Events",
    DICTIONARY: "Dictionary",
    BUILDINGS: "Buildings",
    TASKS: "Tasks",
    FTI: "FarmTimeInterval",
    ANIMAL_GROUP: "AnimalsGroup",
    SALES: "Sales",
    BREEDING_COUNTERS: "BreedingCounters"
}

export const LogTableTypesShort = {
    ANIMALS: "ANLS",
    DEVICES: "DEES",
    USERS: "USRS",
    SETTINGS: "SEGS", //śmiesznie
    EVENTS: "EVTS",
    DICTIONARY: "DIRY",
    BUILDINGS: "BUGS",
    TECHNOLOGY_GROUPS: "TEPS",
    TASKS: "TAKS",
    FTI: "FAAL",
    ANIMAL_GROUP: "ANUP",
    SALES: "SAES",
    BREEDING_COUNTERS: "BRRS"
}
