import React from "react";
import "./_notification-item.scss"
import TwitterTime from "../../components/basics/ago-time/TwitterTime";
import AgoTime from "../../components/basics/ago-time/AgoTime";


const getStatusIcon = (status) => {
    switch (status) {
        case "loading":
            return <i className={"fas fa-spinner-third fa-spin color-info"}/>;
        case "error":
            return <i className={"fas fa-exclamation-circle color-error"}/>;
        case "warning":
            return <i className={"fas fa-exclamation-triangle color-warning"}/>;
        case "success":
            return <i className={"fas fa-check-circle color-success"}/>;
        case "info":
        default:
            return <i className={"fas fa-info-circle color-info"}/>;
    }
}

export default function ({history, time}) {
    const {title, message, allowHTML, status} = history[history.length - 1];
    const icon = getStatusIcon(status);
    return (
        <div className={"notify-item"}>
            <div className={"title"}>
                <div className={"icon"}>{icon}</div>
                {title}
                <span className={"time"}>
                    <AgoTime component={TwitterTime} minPeriod={30} date={time}/>
                </span>
            </div>
            {
                allowHTML && <div className={"message"} dangerouslySetInnerHTML={{__html: message}}/>
            }
            {
                !allowHTML && <div className={"message"}>{message}</div>
            }
        </div>
    )
}
