import moment from "moment";
import React, {useCallback, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import EstimatedWeight from "../../../../beans/estimated-weight/EstimatedWeight";
import {UnitTypes} from "../../../../constans/unitTypes";
import {getTargetWater} from "../../../../selectors/settingsSelector";
import {utcMomentToLocal} from "../../../../utils/DateTimeUtils";
import {isBetween, isFiniteNumber} from "../../../../utils/MathUtils";
import {convertVolumeUnitTo, getUnit} from "../../../../utils/UnitUtils";
import useDeviceLocations from "../useDeviceLocations";
import useDomain from "../useDomain";
import ChartTemplate from "./ChartTemplate";
import {ERRORS, applyOffset, calculateAutoDomain, skipData} from "./utils";
import {makeGetDeviceData} from "../../../../selectors/aggregatedDataSelector";
import useOpenDetailsModal from "../useOpenDetailsModal";
import { arrayValueFormatter } from "../../../../utils/ChartsUtils";

const DATA_TYPE = "water";

const Chart = ({groupInfo}) => {
    const getDeviceData = useMemo(makeGetDeviceData, []);
    const locations = useDeviceLocations(DATA_TYPE, false);

    const {
        data,
        error,
        loading
    } = useSelector(state => getDeviceData(state, groupInfo.start, groupInfo.end, locations, DATA_TYPE));

    const show = !!locations.length;

    const ref = useRef(null);
    const volumeFormatter = useCallback((value) => {
        if (value === undefined || value === null || isNaN(value)) return null;
        return convertVolumeUnitTo(value, {
            showUnit: false,
            unit: UnitTypes.MEDIUM,
            rawValue: true,
            fixed: 2
        })
    }, []);
    const targetWater = useSelector(getTargetWater);
    const chartDomain = useDomain();

    const dataSet = useMemo(() => {
        if (!data) return [];
        const fmt = volumeFormatter;
        const ds = [];
        const estimate = new EstimatedWeight(0, []);
        estimate.setWeightTable(targetWater.WeeklyTarget);
        estimate.setBirthDate(groupInfo.birthTime);
        const [chartStart, chartEnd] = chartDomain;
        const diff = Math.abs(moment.utc(chartStart).diff(moment.utc(chartEnd), "day"));
        for (let i = 0; i <= diff; i++) {
            const dayUTC = moment.utc(chartStart).add(i, "day");
            const dayLOCAL = utcMomentToLocal(dayUTC);
            const isGroupActive = isBetween(+dayUTC, groupInfo.start, groupInfo.end);
            const deviceData = data[+dayUTC];
            const estimated = estimate.getWeightByDate(+dayLOCAL);
            const offset = [fmt(applyOffset(estimated, -targetWater.Offset)), fmt(applyOffset(estimated, targetWater.Offset))];
            if (!isGroupActive) {
                ds.push({
                    time: +dayUTC,
                    estimated: fmt(estimated),
                    value: null,
                    details: [],
                    offset,
                    error: null
                })
                continue;
            }
            const {value, details} = (() => {
                const details = [];
                let total = 0;
                let cnt = 0;
                for (const locationId of locations) {
                    const hasData = deviceData ? !!deviceData[locationId] : false;
                    const {Usage} = hasData ? deviceData[locationId] : {};
                    const animals = groupInfo.getNumberByTimeAndLocation(+dayLOCAL, locationId);
                    if (skipData(animals)) continue;
                    const locationData = {
                        locationId,
                        value: (animals && isFiniteNumber(Usage)) ? fmt(Usage / animals) : null,
                        error: null,
                        count: animals
                    }
                    if (locationData.value === null) {
                        locationData.error = ERRORS.NO_DATA
                    } else if (!isBetween(locationData.value, offset[0], offset[1])) {
                        locationData.error = ERRORS.OUT_OF_BOUNDS
                    }
                    details.push(locationData);
                    if (isFiniteNumber(Usage)) {
                        total += Usage;
                        cnt += animals;
                    }
                }
                return {value: cnt ? fmt(total / cnt) : null, details};
            })();
            ds.push({
                time: +dayUTC,
                estimated: fmt(estimated),
                value,
                details,
                offset,
                error: details.some(({error}) => [ERRORS.OUT_OF_BOUNDS].includes(error)) ? value || 0 : null,
                amount: deviceData ? Object.keys(deviceData).map((locationId) => groupInfo.getNumberByTimeAndLocation(+dayLOCAL, locationId) ?? "NIE MA").join("/") : "NULL"
            })
        }
        return ds;

    }, [data, targetWater, groupInfo, volumeFormatter, locations, chartDomain]);

    const {t} = useTranslation();

    const dataDef = useMemo(() => {
        const unit = t("perPig", {value: getUnit("volume", UnitTypes.MEDIUM)});
        return [
            {
                dataKey: "value",
                color: "blue",
                type: "linear",
                unit,
                name: t("idealCharts.realUsage"),
                _role: "read"
            },
            {
                dataKey: "offset",
                color: "blue",
                opacity: 0.2,
                strokeDasharray: "5 5",
                strokeOpacity: 0.5,
                type: "linear",
                unit,
                name: t("idealCharts.plannedUsage"),
                hideLegend: true,
                _role: "range",
                formatter: arrayValueFormatter,
            },
            {
                color: "red",
                opacity: 1,
                dataKey: "error",
                chartType: "Scatter",
                unit,
                name: t("idealCharts.problems")
            }
        ];
    }, [t]);

    const domain = useMemo(() => {
        return calculateAutoDomain(dataSet);
    }, [dataSet]);

    const onClick = useOpenDetailsModal("water", locations, groupInfo);

    return (
        <ChartTemplate
            domain={domain}
            show={show}
            data={data} error={error}
            YaxisName={t("charts.waterHourlyChart.consumption")}
            name={t("idealCharts.waterUsage")} loading={loading} id={"cy-water-chart"}
            dataDef={dataDef}
            dataSet={dataSet}
            defaultExpanded={true}
            forwardRef={ref}
            onClick={onClick}
            saveAsExcell={t("idealCharts.waterUsage")}
        />
    )
}

const WaterChart = ({groupInfo}) => {
    const hasWater = useDeviceLocations(DATA_TYPE, true);
    return hasWater ? <Chart groupInfo={groupInfo}/> : null;
}

export default React.memo(WaterChart);