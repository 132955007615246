import React from "react";
import PropTypes from "prop-types"
import MultiValuesComponent from "../../../views/event-chooser-view/events/MultiValuesComponent.js"
import {findIndex, isEqual} from "lodash"

const SortIconComponent = ({icon, order}) => <div className="d-flex align-items-center">
    <i className={icon}/>
    {`(${order})`}
</div>;

export default class TableHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!isEqual(this.props, nextProps)) return true;
        return !isEqual(this.state, nextState);
    }

    onClick = () => {
        const {onSortChange, header, isSortable} = this.props;
        if (isSortable && !header.notSortable) {
            onSortChange(header);
        }
    };

    getClassName() {
        let className = "header-item";
        let {header: {headerClassName, colWidth, disabledSort, notSortable}, isSortable} = this.props;
        if (isSortable && !notSortable) className += " sortable";
        if (disabledSort) className += " disabled-sort";
        if (headerClassName) className += " " + headerClassName;
        if (colWidth) className += ` col-width-${colWidth}`;
        return className;
    }

    getHeaderSortedItem = () => {
        const {sortArray, header: {name}} = this.props;
        const itemIndex = findIndex(sortArray, (s) => s.name === name);
        let sortItem = null;
        let sortIndex = 0;
        if (itemIndex !== -1) {
            sortItem = sortArray[itemIndex];
            sortIndex = itemIndex + 1;
        }
        return {
            sortItem,
            sortIndex
        }
    };

    render() {
        const {
            header: {name, headerComponent, disabledSort, multiValues, animals, field, unit, type},
            isSortable,
            show
        } = this.props;
        const {sortItem, sortIndex} = this.getHeaderSortedItem();
        return (
            <div className={this.getClassName()} onClick={!disabledSort ? this.onClick : null}>
                {
                    headerComponent &&
                    React.cloneElement(headerComponent, {name: name}, headerComponent.props.children)
                }
                {
                    !headerComponent &&
                    <div className="multiValues">
                        {name}
                        {show &&
                            <div>
                                <br/>
                                {multiValues &&
                                    <MultiValuesComponent field={field} unit={unit} type={type} animals={animals}/>
                                }
                            </div>
                        }
                    </div>

                }
                {
                    isSortable && sortIndex !== 0 && sortItem.sortType !== "off" &&
                    <div className="header-sorting-icon">
                        {
                            sortItem.sortType === "asc" &&
                            <SortIconComponent icon={"fas fa-sort-amount-up"} order={sortIndex}/>
                        }
                        {
                            sortItem.sortType === "desc" &&
                            <SortIconComponent icon={"fas fa-sort-amount-down"} order={sortIndex}/>
                        }
                    </div>
                }
            </div>
        );
    }

}

TableHeader.propTypes = {
    header: PropTypes.shape({
        name: PropTypes.node.isRequired,
        field: PropTypes.string,
        valueFormatter: PropTypes.func,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
        headerComponent: PropTypes.element,
        headerClassName: PropTypes.string,
        notSortable: PropTypes.bool,
    }).isRequired,
    isSortable: PropTypes.bool,
    onSortChange: PropTypes.func,
    sortColumn: PropTypes.string,
    sortType: PropTypes.string
};

TableHeader.defaultProps = {
    isSortable: false,
    onHeaderClick: () => {
    }
};
