import {useEffect, useMemo} from "react";
import {useIsTabVisible} from "./useIsTabVisible";
import NewIOT from "../IOT/NewIOT";
import {groupDevicesByGatewayID} from "../utils/DevicesUtils";
import {flatten} from "lodash";
import {makeIsEqualArrayByItemProperty} from "../utils/Utils";
import memoizeOne from "memoize-one";

/**
 * iot utility to automatically fetch shadows
 * @param devices {array} - list of devices, (mixed types allowed, mixed gateways allowed - this function will get the job done)
 * @return {null}
 */
export default function useShadowState(devices) {

    const isTabVisible = useIsTabVisible();

    // devices get updated all the time, we dont want to send a new iot request with each update so we will check if only selected parameters have changed
    const getDevices = useMemo(() => {
        const isPartiallyEqual = makeIsEqualArrayByItemProperty("DevID", "GatewayID", "IOT");
        return memoizeOne((devices) => devices, (...args) => isPartiallyEqual(args[0][0], args[1][0]));
    }, []);

    const devicesMemoized = useMemo(() => getDevices(devices), [devices, getDevices]);

    useEffect(() => {
        const messages = [];
        if (isTabVisible) {
            const devicesGroupedByGW = groupDevicesByGatewayID(devicesMemoized);
            if (devicesGroupedByGW) {
                for (let sameGW of devicesGroupedByGW.values()) {
                    const merged = flatten(Object.values(sameGW));
                    if (merged.length) {
                        messages.push(NewIOT.startSendingDeviceState(merged, undefined, undefined, {keepAlive: true}));
                    }
                }
            }

        }
        return () => {
            if (messages.length) {
                NewIOT.removeFromInterval(messages);
            }
        }
    }, [devicesMemoized, isTabVisible]);

    return null;
}
