import React, {Component} from 'react';
import {Fade} from "react-bootstrap";

class FileImageShow extends Component {
    container = React.createRef();
    state = {
        show: false,
        startPosition: { x: 0, y: 0 },
        currentPosition: { x: 0, y: 0 },
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {zoom} = this.props;
        if (this.props.url !== prevProps.url) {
            this.setState({
                show: false,
                isDragging: false,
            });
        }
        if (zoom !== prevProps.zoom && zoom === 1) {
            this.setState({
                startPosition: {x: 0, y: 0},
                currentPosition: {x: 0, y: 0},
            });
        }
    }

    onLoadFile = () => {
        this.props.onLoadFile();
        this.setState({
            show: true
        })
    }
    handleMouseDown = (e) => {
        this.setState({
          isDragging: true,
          startPosition: { x: e.clientX, y: e.clientY },
        });
      };
    
      handleMouseMove = (e) => {
        const {isDragging, startPosition, currentPosition} = this.state;
        if (isDragging) {
          const deltaX = e.clientX - startPosition.x;
          const deltaY = e.clientY - startPosition.y;
    

          this.setState({
            currentPosition: {
              x: currentPosition.x + deltaX,
              y: currentPosition.y + deltaY,
            },
            startPosition: { x: e.clientX, y: e.clientY },
          });
        }
      };
    
      handleMouseUp = () => {
        this.setState({
          isDragging: false,
        });
      };
    render() {
        const {file: {FileName}, url, zoom} = this.props;
        const {show, currentPosition} = this.state;
        const canMove = zoom>1
        if (!url) return null;
        return (
            <Fade in={show} ref={this.container}>
                <img
                    draggable={false}
                    onMouseDown={canMove ? this.handleMouseDown : null}
                    onMouseMove={canMove ? this.handleMouseMove : null}
                    onMouseUp={canMove ? this.handleMouseUp : null}
                    style={{transform: `scale(${zoom}) translate(${currentPosition.x}px, ${currentPosition.y}px)`}}
                    src={url}
                    alt={FileName}
                    onLoad={this.onLoadFile}
                />
            </Fade>
        );
    }
}

export default FileImageShow;
