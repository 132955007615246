import {useCallback, useMemo, useRef} from "react";
import {useSelector} from "react-redux";
import {getGroupBirthTime, getGroupTimespan, getLocationDays} from "../../../selectors/animalDocumentsSelectors";
import {isFiniteNumber} from "../../../utils/MathUtils";

/**
 * hook zwraca podstawowe informacje o grupie takie jak:
 * birthTime {number} - pierwsze urodzenie w grupie (czas lokalny)
 * start - początek grupy (czas utc)
 * end - koniec grupy (czas utc)
 * animalNumberPerLocation {object} - obiekt zawierający informacje o ilości zwierząt w danym miejscu i czasie
 * getNumberByTimeAndLocation {function} - funkcja zwracajaca ilość zwierząt w danym miejscu
 * @return {{getNumberByTimeAndLocation: function(*, *): number, start: number, end: number, birthTime: *, animalNumberPerLocation: unknown}}
 */
const useAnimalGroupInfo = () => {
    const props = useRef({ getDeletedAnimals: true })
    const birthTime = useSelector((state) => getGroupBirthTime(state,props.current));
    const {start, end} = useSelector((state) => getGroupTimespan(state,props.current));
    const animalNumberPerLocation = useSelector(getLocationDays);

    const getNumberByTimeAndLocation = useCallback((timeLOCAL, locationId) => {
        const data = animalNumberPerLocation[locationId] || [];
        let number = 0;
        let currentTime = 0;
        data.forEach(({date, value}) => {
            if (isFiniteNumber(value) && (timeLOCAL >= date)) {
                if (date >= currentTime) {
                    number = value;
                    currentTime = date;
                }
            }
        });
        return Math.max(0, number);  // yep you've guessed it

    }, [animalNumberPerLocation]);

    return useMemo(() => ({
        birthTime,
        animalNumberPerLocation,
        getNumberByTimeAndLocation,
        start,
        end,
    }), [birthTime, animalNumberPerLocation, getNumberByTimeAndLocation, start, end])
}

export default useAnimalGroupInfo;