const initialValue = {
    status: true
};

export default function notificationReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_ONLINE_STATUS": {
            return {...state, status: action.payload};
        }
        default:
            return state
    }
}
