import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { roleSelector } from "../../../../selectors/roleSelector";
import { flatten } from "lodash";
import { makeGetDevicesByType } from "../../../../selectors/deviceSelector";
import { DeviceTypesUsedInMap } from "../../utils";
import memoizeOne from "memoize-one";
import TurnOffLights from "./light-controls/TurnOffLights";
import TurnOnLights from "./light-controls/TurnOnLights";
import ControlButton from "./ControlButton";
import { Col, Row } from "react-bootstrap";

const makeMapStateToProps = () => {
    let getDeviceByType = makeGetDevicesByType();
    const DevType = { DevType: DeviceTypesUsedInMap }
    return (state) => {
        return {
            roleUtils: roleSelector(state),
            devices: getDeviceByType(state, DevType)
        }
    }
}

class GlobalControlPanel extends Component {
    state = {
        isLoading: false,
        loadingKey: null
    }

    setLoading = (key) => this.setState({ isLoading: true, loadingKey: key })

    setIdle = () => this.setState({ isLoading: false, loadingKey: null })

    options = -1;

    getControls = memoizeOne((mapType) => {
        if (mapType === "lights") {
            return [TurnOffLights, TurnOnLights];
        }
        return [];
    });

    _getOptions = memoizeOne((controls, devices, roleUtils, t) => {
        return flatten(controls.map((c) => {
            return c({ devices, roleUtils, t });
        })).filter((o) => o && o.show);
    })

    getOptions = () => {
        const { devices, mapType, roleUtils, t } = this.props;
        const controls = this.getControls(mapType);
        return this._getOptions(controls, devices, roleUtils, t);
    }


    render() {
        const options = this.getOptions();
        const { changeOptionsCallback, mobile, t } = this.props;
        if (this.options !== options.length) {
            changeOptionsCallback(options.length);
        }
        this.options = options.length;
        if (options.length === 0) return null;
        const { state: { isLoading, loadingKey }, setIdle, setLoading } = this;
        const passAsProps = { isLoading, setIdle, setLoading, loadingKey, mobile, t };
        return (
            <Col xs={12}>
                <div className={"fast-action-panel"}>
                    <div className={"title"}>{t("settings.quickActions")}</div>
                    <Row>
                        {
                            options.map(({ key, submit, label, icon }) => (
                                <ControlButton {...passAsProps} key={key} id={key} submit={submit}
                                    icon={icon ? <i className={`fas fa-fw ${icon}`} /> : null}
                                    label={label} />
                            ))
                        }
                    </Row>
                </div>
            </Col>
        );
    }
}

export default compose(
    connect(makeMapStateToProps),
    withTranslation()
)(GlobalControlPanel);
