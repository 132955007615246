import memoizeOne from "memoize-one";
import parser from "./parser";
import PathParser from "./PathParser";
import CircleParser from "./CircleParser";
import Flatbush from "flatbush";

export const getDistanceBetweenTwoPoints = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(y1 - y2, 2) + Math.pow(x1 - x2, 2))
}

export const getAngleBetweenTwoPoints = (x1, y1, x2, y2) => {
    return Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);
}

export const snapToSize = (value, gridSize) => {
    const candidate = gridSize * Math.round(value / gridSize);
    return Math.abs(value - candidate) < (gridSize) ? candidate : value;
}

export const getEdgeAttributes = (scale = 1) => {
    return {
        strokeWidth: (4 / scale)
    }
};
export const getRectAttributes = (scale = 1) => {
    return {
        width: (1 / scale) * 16,
        height: (1 / scale) * 16,
    }
};

export const getCircleAttributes = (scale = 1) => {
    return {
        r: (1 / scale) * 5
    }
};

export const getFillPatternByPosition = (position) => {
    switch (position) {
        case "top":
        case "bottom":
            return "url(#arrow-1)";
        case "left":
        case "right":
            return "url(#arrow-2)";
        case "topLeft":
        case "bottomRight":
            return "url(#arrow-4)";
        case "bottomLeft":
        case "topRight":
        default:
            return "url(#arrow-3)";
    }
}

export const getTranslateByPosition = (position, scale) => {
    const halfStep = 8;
    const step = 16;
    let params;
    switch (position) {
        case "top":
            params = [-halfStep, -step]
            break;
        case "bottom":
            params = [-halfStep, 0]
            break;
        case "left":
            params = [-step, -halfStep]
            break;
        case "right":
            params = [0, -halfStep]
            break;
        case "topLeft":
            params = [-step, -step]
            break;
        case "bottomRight":
            params = [0, 0]
            break;
        case "bottomLeft":
            params = [-step, 0]
            break;
        case "topRight":
            params = [0, -step]
            break;
        default:
            params = [-step * 2, -step * 2]
            break;
    }
    params = params.map(o => `${o * (1 / scale)}px`);
    return {
        transform: `translate(${params.join(", ")})`
    }
}

export const getOriginalViewport = (scale, offsetX, offsetY, portalWidth, portalHeight) => {
    return {
        x1: -offsetX / scale,
        x2: (-offsetX + portalWidth) / scale,
        y1: -offsetY / scale,
        y2: (-offsetY + portalHeight) / scale
    }
}

export const getSelectionPosition = memoizeOne((items, defaultWidth = 0, defaultHeight = 0) => {
    let x1 = null, x2 = null, y1 = null, y2 = null;
    for (let item of items) {
        const obj = parser(item.type, item);
        if (!obj) continue;
        const {minX, maxX, minY, maxY} = obj.getRect();
        x1 = x1 === null ? minX : Math.min(minX, x1);
        y1 = y1 === null ? minY : Math.min(minY, y1);
        x2 = x2 === null ? maxX : Math.max(maxX, x2);
        y2 = y2 === null ? maxY : Math.max(maxY, y2);
    }
    return {x1: x1 || 0, x2: x2 ?? defaultWidth, y1: y1 || 0, y2: y2 ?? defaultHeight};
});

export const getNextScale = (currentScale) => {
    for (let scale of scaleOptions) {
        if (scale > currentScale)
            return scale;
    }
    return scaleOptions[scaleOptions.length - 1];
}

export const getPrevScale = (currentScale) => {
    for (let i = scaleOptions.length - 1; i >= 0; i--) {
        const scale = scaleOptions[i];
        if (scale < currentScale)
            return scale;
    }
    return scaleOptions[0];
}

// const scaleOptions = new Array(70).fill(0).map((o, i) => Math.exp((i - 25) / 10))


const scaleOptions = [
    0.01,
    0.015,
    0.02,
    0.025,
    0.03,
    0.04,
    0.05,
    0.1,
    0.15,
    0.2,
    0.25,
    0.3,
    0.35,
    0.4,
    0.5,
    0.7,
    0.9,
    1,
    1.1,
    1.25,
    1.5,
    2,
    2.5,
    3,
    5,
    7.5,
    10,
    12.5,
    15,
    20,
    25,
    30,
    35,
    40,
    45,
    50
];

export const DEFAULT_PADDING = 30;

export const getOffsetAndScaleByRenderArea = (x1, y1, x2, y2, portalWidth, portalHeight) => {
    const result = {scale: 1, offsetX: 30, offsetY: 30};
    const width = x2 - x1, height = y2 - y1;
    const scaleW = portalWidth / (width + DEFAULT_PADDING), scaleH = portalHeight / (height + DEFAULT_PADDING);
    result.scale = Math.min(Math.abs(scaleW), Math.abs(scaleH));
    result.scale = getPrevScale(result.scale);
    console.log(portalHeight, height, portalWidth, width);
    const diffX = (portalWidth - (width * result.scale)) / 2, diffY = (portalHeight - (height * result.scale)) / 2;
    result.offsetX = Math.round(-(result.scale * x1) + diffX);
    result.offsetY = Math.round(-(result.scale * y1) + diffY);
    console.log(diffX, diffY, scaleH, scaleW, result)
    return result;
}


export const getPortalRect = () => {
    return document.getElementById("view-portal").getBoundingClientRect();
}

export const getAngleBetween3Points = (mid, start, end) => {
    return 180 * (Math.atan2(end.y - mid.y, end.x - mid.x) - Math.atan2(start.y - mid.y, start.x - mid.x)) / Math.PI;
}


// zwraca kat od 0 do 359
export const normalizeAngle = (angleDEG) => {
    const baseAngle = 360;
    let normalizedAngle = 0;
    if (angleDEG < 0) {
        normalizedAngle = baseAngle - (Math.abs(angleDEG % baseAngle));
    } else {
        normalizedAngle = angleDEG % baseAngle;
    }
    return normalizedAngle;
}

export const getSlopePercentage = (angleDEG) => {
    return Math.ceil(Math.tan((normalizeAngle(angleDEG) % 180) * Math.PI / 180)) * 100;
}

export const getCenterBetweenTwoPoints = (p1, p2) => {
    return {
        x: (p1.x + p2.x) / 2,
        y: (p1.y + p2.y) / 2
    }
}

export const getVectorFromTwoPoints = (p1, p2) => {
    return {
        x: (p1.x - p2.x),
        y: (p1.y - p2.y)
    }
}

export const getNormalizedVectorFromTwoPoints = (p1, p2) => {
    const vec = getVectorFromTwoPoints(p1, p2);
    const len = Math.sqrt(Math.pow(vec.x, 2) + Math.pow(vec.y, 2));
    if (len > 0) {
        return {
            x: vec.x / len,
            y: vec.y / len
        }
    }
    return vec;
}

export const toDEG = (rad) => rad * (180 / Math.PI);

export const toRAD = (deg) => deg * (Math.PI / 180);

export const getVectorCoordinatesByLengthAndAngle = (length, angleDEG) => {
    return {
        x: length * Math.cos(toRAD(angleDEG)),
        y: length * Math.sin(toRAD(angleDEG))
    }
}

const makeFlatbushIndexForItems = (items) => {
    if (!items.length) return {
        search: () => [],
        neighbors: () => [],
    }
    let allPoints = [];
    items.forEach((item) => {
        let points = [];
        if (item.type === "path") {
            points = new PathParser(item.d).getVertices();
        }
        if (item.type === "circle") {
            points = new CircleParser(item).getVertices();
        }
        for (let p of points) {
            const tmp = {
                minX: p.x,
                minY: p.y,
                maxX: p.x,
                maxY: p.y
            }
            allPoints.push(tmp);
        }
    });
    const index = new Flatbush(allPoints.length);
    for (const p of allPoints) {
        index.add(p.minX, p.minY, p.maxX, p.maxY);
    }
    index.finish();
    return {
        search: (minX, minY, maxX, maxY) => index.search(minX, minY, maxX, maxY).map((i) => allPoints[i]),
        neighbors: (x, y, maxItems, maxDistance) => index.neighbors(x, y, maxItems, maxDistance).map((i) => allPoints[i])
    }
}

const getFlatbushIndex = memoizeOne(makeFlatbushIndexForItems);

export const getMagnetPointByPoints = (pointListToAnalyze, items) => {
    const flatbushIndex = getFlatbushIndex(items);
    let magnetPoint = null;
    pointListToAnalyze.forEach(({x, y}, index) => {
        const points = flatbushIndex.neighbors(x, y, 5, 7.5);
        for (let p of points) {
            const tmp = {
                x: p.minX,
                y: p.minY,
                index,
                _distance: getDistanceBetweenTwoPoints(x, y, p.minX, p.minY)
            }
            if (!magnetPoint || magnetPoint._distance > tmp._distance) {
                magnetPoint = tmp;
            }
        }
    })
    return magnetPoint;
}
