import React, {Component} from 'react';
import "./_table-cell-group.scss";
import TableCellAnimalAmount from "./TableCellAnimalAmount";
import {isFunction} from "lodash";

class TableCellGroupAnimal extends Component {

    onRemoveClick = () => {
        const {row, column: {onRemoveChild} = {}} = this.props;
        if (isFunction(onRemoveChild)) onRemoveChild(row);
    };

    render() {
        const {row: {isLast, sowWithGilts, animal, location} = {}, allowDeleteChild} = this.props;
        return (
            <div className={`table-cell-group${isLast ? " last" : ""}`}>
                {
                    allowDeleteChild &&
                    <div className="remove-child" onClick={this.onRemoveClick}>
                        <i className="fas fa-times success"/>
                    </div>
                }
                {
                    sowWithGilts && (sowWithGilts.AnmNo1 || animal.AnmNo1)
                }
                {
                    !sowWithGilts &&
                    <TableCellAnimalAmount animal={animal || location}/>
                }
            </div>
        );
    }
}

export default TableCellGroupAnimal;