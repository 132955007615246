import React, {useMemo} from "react";
import Button from "../../../basics/button/Button";
import ButtonGroup from "../../../basics/button/button-group/ButtonGroup";
import "./_zoom.scss";

const Zoom = ({mobile, zoom, onZoom, scaleOptions}) => {
    const nextZoom = useMemo(() => scaleOptions.setCurrent(zoom).next().currentScale, [zoom, scaleOptions]);
    const previousZoom = useMemo(() => scaleOptions.setCurrent(zoom).prev().currentScale, [zoom, scaleOptions]);

    const btnBaseProps = useMemo(() => ({
        buttonColor: "menu-color",
        type: "button",
        ...mobile && {className: "h-100"}
    }), [mobile]);

    return (
        <div className={"fetura-farm-map__zoom"}>
            <ButtonGroup className={mobile ? "m-0 h-100" : "m-0"} vertical={!mobile}>
                <Button {...btnBaseProps} onClick={() => onZoom(nextZoom)} icon={<i className={"fas fa-plus"}/>}/>
                <Button {...btnBaseProps} onClick={() => onZoom(previousZoom)} icon={<i className={"fas fa-minus"}/>}/>
            </ButtonGroup>
        </div>
    )
}


export default React.memo(Zoom);