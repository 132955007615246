import React from "react";
import {useTranslation} from "react-i18next";
import {parseWater} from "../parsers";
import DeviceDefaultContent from "./DeviceDefaultContent";


const WaterContent = (props) => {
    const {t} = useTranslation();
    return <DeviceDefaultContent {...props} parseDataFn={parseWater} name={t("water")}
                                 paramLabel={t("charts.waterHourlyChart.consumption")}/>
}


export default React.memo(WaterContent);