const initialValue = {
    messages: [],
};

export default function terminalReducer(state = initialValue, action) {
    switch (action.type) {
        case "TERMINAL_CLEAR_MESSAGES":
            return {
                ...state,
                messages: [],
            };
        case "TERMINAL_ADD_MESSAGE":
            const newMessages = [...state.messages];
            newMessages.unshift(action.payload);
            // keep the history of last messages small
            if (newMessages.length > 35) {
                newMessages.length = 35;
            }
            return {
                ...state,
                messages: newMessages
            };
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
