import React, { useMemo } from 'react'
import LoadingComponent from './LoadingComponent'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGlobalLoadingTextData } from '../../selectors/viewSelector';
import LoadingLanguageSwapper from '../loading-language-swapper/LoadingLanguageSwapper';
import { globalLoadingUserData } from '../../selectors/userSelector';

export default function GlobalLoading() {

    const { t, ready } = useTranslation();

    const { text: globalText, translate, globalLoading } = useSelector(getGlobalLoadingTextData);
    const { isAuthenticating, isChangingClientID } = useSelector(globalLoadingUserData);

    const text = useMemo(() => {
        if (!ready) return <LoadingLanguageSwapper />;
        if (globalText)
            return translate ? t(globalText) : globalText;
        if (isChangingClientID) return t("changingClient");
        if (isAuthenticating) return t("serviceAuth");
        return null;
    }, [t, ready, globalText, translate, isAuthenticating, isChangingClientID]);

    return (
        <LoadingComponent
            style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2137 }}
            isLoading={isChangingClientID || isAuthenticating || globalLoading || !ready}
            text={text} />
    )
}
