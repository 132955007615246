import { useEffect } from 'react';
import ReactGA from "react-ga4";
import { useHistory } from 'react-router-dom';

export default function GoogleAnalytics() {

    const history = useHistory();

    useEffect(() => {
        ReactGA.initialize("G-4KBX0QS2B2", {
            gaOptions: { send_page_view: false },
            gtagOptions: { send_page_view: false },
        });
        history.listen((location, action) => {
            ReactGA.send({ hitType: "pageview", page: location.pathname });
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return null
}
