import {getFiles as getFilesAPI} from "../api/files/getFiles";
import {getLastModifiedFiles as getLastModifiedFilesAPI} from "../api/files/getLastModifiedFiles";
import {getAllDirectories as getAllDirectoriesAPI} from "../api/files/getAllDirectories";
import {getFileSort} from "../selectors/filesSelectors";
import {getFilesOCR as getFilesOCRApi} from "../api/files/getFilesOCR";
import {getCreatedProtocols as getCreatedProtocolsAPI} from "../api/files/getCreatedProtocols";

export function getFiles(path, {showLoading = true, forceFetch = false} = {}) {
    return function (dispatch, state) {
        dispatch({
            type: "GET_FILES",
            payload: getFilesAPI(path, {forceFetch}),
            meta: {showLoading}
        })
    }
}

export function changeFilesPath(path) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_FILE_PATH",
            payload: path
        })
        dispatch(getFiles(path));
        dispatch(selectFile(null));
    }
}

export function selectFile(file) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_SELECTED_FILE",
            payload: file
        })
    }
}

export function startFileUpload(file, xhr) {
    return function (dispatch) {
        dispatch({
            type: "START_FILE_UPLOAD",
            payload: {file, xhr}
        })
    }
}

export function updateFileUploadProgress(file, event) {
    return function (dispatch) {
        dispatch({
            type: "UPDATE_FILE_UPLOAD_PROGRESS",
            payload: {file, event}
        })
    }
}

export function cancelFileUpload(file) {
    return function (dispatch) {
        dispatch({
            type: "CANCEL_FILE_UPLOAD",
            payload: file
        })
    }
}

export function errorFileUpload(file) {
    return function (dispatch) {
        dispatch({
            type: "ERROR_FILE_UPLOAD",
            payload: file
        })
    }
}

export function removeFileUpload(index) {
    return function (dispatch) {
        dispatch({
            type: "REMOVE_FILE_UPLOAD",
            payload: index
        })
    }
}

export function changeFilesView(view) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_FILE_VIEW",
            payload: view
        })
    }
}

export function setFileSort(column) {
    return function (dispatch, getState) {
        let state = getState();
        let currentSort = getFileSort(state);
        let type = "ASC";
        if (currentSort.column === column) {
            type = currentSort.type === "ASC" ? "DESC" : "ASC";
        }
        dispatch({
            type: "SET_FILE_SORT",
            payload: {column, type}
        })
    }
}

export function removeFileFromList(file) {
    return function (dispatch) {
        dispatch({
            type: "REMOVE_FILE_FROM_LIST",
            payload: file.FileID
        })
    }
}

export function addFileToList(file) {
    return function (dispatch) {
        dispatch({
            type: "ADD_FILE_TO_LIST",
            payload: file
        })
    }
}

export function getFolderPickerFiles(path) {
    return function (dispatch) {
        dispatch({
            type: "GET_FOLDER_PICKER_FILES",
            payload: getFilesAPI(path)
        })
    }
}

export function changeFolderPickerPath(path) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_FOLDER_PICKER_PATH",
            payload: path
        })
        dispatch(getFolderPickerFiles(path));
    }
}

export function selectFileForMenu(event, file, fromLastFiles = false) {
    return function (dispatch) {
        dispatch({
            type: "SELECT_FILE_FOR_MENU",
            payload: {event, file, fromLastFiles}
        })
    }
}

export function showFile(file, fromLastFiles = false) {
    return function (dispatch) {
        dispatch({
            type: "SHOW_FILE",
            payload: {
                file,
                fromLastFiles
            }
        })
    }
}

export function getLastModifiedFiles(forceFetch = false) {
    return function (dispatch) {
        dispatch({
            type: "GET_LAST_MODIFIED_FILES",
            payload: getLastModifiedFilesAPI({forceFetch})
        })
    }
}

export function getAllDirectories() {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_DIRECTORIES",
            payload: getAllDirectoriesAPI()
        })
    }
}

export function getFilesOCR({showLoading = true} = {}) {
    return function (dispatch) {
        dispatch({
            type: "GET_FILES_OCR",
            payload: getFilesOCRApi(),
            meta: {showLoading}
        })
    }
}

export function clearFiles() {
    return function (dispatch) {
        dispatch({
            type: "CLEAR_FILES"
        })
    }
}

export function getCreatedProtocols({showLoading = true, forceFetch = false} = {}) {
    return function (dispatch) {
        dispatch({
            type: "GET_CREATED_PROTOCOLS",
            payload: getCreatedProtocolsAPI({forceFetch}),
            meta: {showLoading}
        })
    }
}

export function restoreDirectoryPickerPath() {
    return function (dispatch) {
        dispatch({
            type: "RESTORE_DIRECTORY_PICKER_PATH"
        })
    }
}
