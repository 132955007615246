import React from "react";
import CycleRenderer from "./CycleRenderer";
import PropTypes from "prop-types";
import {get, isEqual} from "lodash";
import {getEventDate} from "./utils";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {withTranslation} from "react-i18next";

class MommySeparationsRenderer extends React.Component {

    state = {
        hoveredID: "",
    };

    isTattooedPiglet(mommy) {
        return mommy && mommy.event.EvCode === EventTypes.TATTOO;
    }

    getPigletsSummary() {
        const {cycle: {separationsMommy = []}} = this.props;
        return separationsMommy.reduce((prev, current) => prev + get(current, "event.EvData.PiCnt", 1), 0);
    }

    getPigletsContent(mommy, showAnmNo1 = false) {
        if (showAnmNo1) {
            return get(mommy, "event.EvData.AnmNo1", "-");
        }
        return get(mommy, "event.EvData.PiCnt");
    };

    getClassName(evID, mommy, isGilt = false) {
        const {hoveredID} = this.state;
        let className = "cell-wrapper";
        if (isGilt) {
            className += " cursor-not-allowed";
        } else {
            if (hoveredID === evID) className += " error";
            else className += " success";
        }
        return className;
    };

    getTitle(isGilt = false) {
        const {t} = this.props;
        if (isGilt) return t("errors.noEditTattooPiglet");
        return "";
    }

    onMouseEnter = (event) => {
        const cell = get(event, "currentTarget");
        if (cell) {
            const EvID = cell.id.split(" ")[0];
            this.setState({
                hoveredID: EvID
            })
        }
    };

    onMouseLeave = () => {
        this.setState({
            hoveredID: ""
        })
    };

    render() {
        const {
            cycle: {separationsMommy, key},
            expanded: {rows},
            isAdvanced,
            tooltipContent,
            cycleRenderClass,
            deletionMode,
            onDeleteClick,
            onDoubleEventClick,
            printing,
            isInterrupted
        } = this.props;
        let rowValue = rows.find(o => o === key);
        let firstSeparation = separationsMommy[0];
        if (!firstSeparation) {
            return (
                <>
                    {
                        isAdvanced &&
                        <CycleRenderer isInterrupted={isInterrupted}/>
                    }
                    <CycleRenderer comment={cycleRenderClass && tooltipContent} isInterrupted={isInterrupted}
                                   deletionMode={deletionMode}/>
                </>
            );
        }
        const operators = separationsMommy[0].operators;
        const isFirstTattooedPiglet = this.isTattooedPiglet(firstSeparation);
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer>
                        {
                            isAdvanced && (isEqual(key, rowValue) || printing) ?
                                separationsMommy.map((separation, index) => {
                                    const {event: {EvID}} = separation;
                                    const isTattooedPiglet = this.isTattooedPiglet(separation);
                                    return (
                                        <div className={this.getClassName(EvID, separation, isTattooedPiglet)}
                                             key={index} id={`${EvID} EvTime`} title={this.getTitle(isTattooedPiglet)}
                                             onDoubleClick={!isTattooedPiglet ? onDoubleEventClick : undefined}
                                             onMouseEnter={deletionMode && !isTattooedPiglet ? this.onMouseEnter : undefined}
                                             onMouseLeave={deletionMode && !isTattooedPiglet ? this.onMouseLeave : undefined}
                                             onClick={deletionMode && !isTattooedPiglet ? onDeleteClick : undefined}
                                        >
                                            {getEventDate(separation)}
                                        </div>
                                    )
                                }) :
                                <div
                                    className={this.getClassName(firstSeparation.event.EvID, firstSeparation, isFirstTattooedPiglet)}
                                    id={`${firstSeparation.event.EvID} EvTime`}
                                    title={this.getTitle(isFirstTattooedPiglet)}
                                    onDoubleClick={!isFirstTattooedPiglet ? onDoubleEventClick : undefined}
                                    onMouseEnter={deletionMode && !isFirstTattooedPiglet ? this.onMouseEnter : undefined}
                                    onMouseLeave={deletionMode && !isFirstTattooedPiglet ? this.onMouseLeave : undefined}
                                    onClick={deletionMode && !isFirstTattooedPiglet ? onDeleteClick : undefined}
                                >
                                    {getEventDate(firstSeparation)}
                                </div>
                        }
                    </CycleRenderer>
                }
                <CycleRenderer comment={cycleRenderClass && tooltipContent}
                               isAdvanced={isAdvanced} operators={operators} deletionMode={deletionMode}
                >
                    {
                        isAdvanced && (isEqual(key, rowValue) || printing) ?
                            separationsMommy.map((separation, index) => {
                                const {event: {EvID}} = separation;
                                const isTattooedPiglet = this.isTattooedPiglet(separation);
                                return (
                                    <div className={this.getClassName(EvID, separation, isTattooedPiglet)} key={index}
                                         id={`${EvID} EvData.PiCnt`} title={this.getTitle(isTattooedPiglet)}
                                         onDoubleClick={!isTattooedPiglet ? onDoubleEventClick : undefined}
                                         onMouseEnter={deletionMode && !isTattooedPiglet ? this.onMouseEnter : undefined}
                                         onMouseLeave={deletionMode && !isTattooedPiglet ? this.onMouseLeave : undefined}
                                         onClick={deletionMode && !isTattooedPiglet ? onDeleteClick : undefined}
                                    >
                                        {this.getPigletsContent(separation, isTattooedPiglet)}
                                    </div>
                                )
                            }) :
                            <div
                                className={this.getClassName(firstSeparation.event.EvID, firstSeparation, isFirstTattooedPiglet)}
                                id={`${firstSeparation.event.EvID} EvData.PiCnt`}
                                title={this.getTitle(isFirstTattooedPiglet)}
                                onDoubleClick={!isFirstTattooedPiglet ? onDoubleEventClick : undefined}
                                onMouseEnter={deletionMode && !isFirstTattooedPiglet ? this.onMouseEnter : undefined}
                                onMouseLeave={deletionMode && !isFirstTattooedPiglet ? this.onMouseLeave : undefined}
                                onClick={deletionMode && !isFirstTattooedPiglet ? onDeleteClick : undefined}
                            >
                                {this.getPigletsSummary()}
                            </div>
                    }
                </CycleRenderer>
            </>
        );
    }

}

MommySeparationsRenderer.propTypes = {
    cycle: PropTypes.shape({
        separationsMommy: PropTypes.array.isRequired
    }).isRequired
};

export default withTranslation()(MommySeparationsRenderer);
