import {withTranslation} from "react-i18next";
import Select from "../../basics/select/Select";
import React from "react";
import Button from "../../basics/button/Button";
import ButtonGroup from "../../basics/button/button-group/ButtonGroup";
import memoizeOne from "memoize-one";
import Menu from "../../basics/menu/Menu";
import MenuItem from "../../basics/menu/MenuItem";

class LevelSelect extends React.Component {

    state = {
        event: null
    }

    selectNextLevel = () => {
        const {level, levels, onSelect} = this.props;
        for (let option of levels) {
            if (option > level) {
                onSelect(option);
                break;
            }
        }
    }

    selectPrevLevel = () => {
        const {level, levels, onSelect} = this.props;
        for (let i = levels.length; i >= 0; i--) {
            const option = levels[i];
            if (option < level) {
                onSelect(option);
                break;
            }
        }
    }


    getOptions = memoizeOne((levels, t) => levels.map(l => ({value: l, name: t("levelNumber", {number: l})})))


    setEvent = (e) => {
        this.setState({
            event: e
        })
    }

    render() {
        const {t, levels, onSelect, level, mobile} = this.props;
        const options = this.getOptions(levels, t);
        if (mobile) {
            const {event} = this.state;
            return (
                <>
                    <>
                        <Menu event={event}>
                            {options.map(option => (
                                <MenuItem selected={option.value === level}
                                          onClick={() => onSelect(option.value)}>{option.name}</MenuItem>
                            ))}
                        </Menu>
                        <Button type={"button"}
                                buttonStyle={"round"} icon={<i className={`fa-fw fa-layer-group fas`}/>}
                                buttonColor={"menu-color"} onClick={this.setEvent}/>
                    </>
                </>
            )
        }
        return (
            <>
                <ButtonGroup>
                    <Button
                        type={"button"}
                        buttonColor={"menu-color"}
                        className={"overflow-visible"}
                        onClick={this.selectPrevLevel}
                        disabled={level === levels[0]}
                        icon={<i className={"fas fa-arrow-down"}/>}
                    />
                    <Select
                        value={level}
                        onChange={onSelect}
                        options={options}
                        clearButton={false}
                    />
                    <Button
                        type={"button"}
                        buttonColor={"menu-color"}
                        className={"overflow-visible"}
                        onClick={this.selectNextLevel}
                        disabled={level === levels[levels.length - 1]}
                        icon={<i className={"fas fa-arrow-up"}/>}
                    />
                </ButtonGroup>

            </>

        )
    }


}

export default withTranslation()(LevelSelect);