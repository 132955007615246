import React from "react";
import PropTypes from "prop-types"
import ReduxTableItem from "./ReduxTableItem";
import _ from "lodash"
import IconComponent from "../helpers/IconComponent";
import {connect} from "react-redux";


export default class ReduxTableRow extends React.Component {

    onClick = () => {
        const {fields, minLength, index} = this.props;
        if (fields.length > minLength) {
            fields.remove(index);
        } else {
            return undefined;
        }
    };

    render() {
        const {headers, field, data, shouldIndex, index, addRemoveButton, fields} = this.props;
        return (
            <div className="table-row">
                {
                    shouldIndex && <div className="table-item index">{index + 1}</div>
                }
                {
                    headers.map((header, i) =>
                        <ReduxTableItem header={header} key={i} field={field} value={_.get(data, header.field)}
                                        fields={fields} index={index}/>
                    )
                }
                {
                    addRemoveButton && <div className="table-item index"><IconComponent className={"fas fa-trash"}
                                                                                        onClick={this.onClick}/>
                    </div>
                }
            </div>
        );
    }

}

ReduxTableRow = connect(state => ({
    form: state.form
}))(ReduxTableRow);

ReduxTableRow.propTypes = {
    field: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    headers: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
    shouldIndex: PropTypes.bool,
    addRemoveButton: PropTypes.bool,
    minLength: PropTypes.number.isRequired
};