import React from "react";
import PropTypes from "prop-types";
import TableHeader from "../TableHeader";
import ReduxTableRow from "./ReduxTableRow";
import Button from "../../button/Button";
import "../_table.scss"
import InfoBox from "../../info-box/InfoBox";
import TableRow from "../TableRow";
import {isFunction} from "lodash";
import {withTranslation} from "react-i18next";

class ReduxTableGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            moreOptions: false
        };
    }

    _onClick = () => {
        const {moreOptions} = this.state;
        this.setState({
            moreOptions: !moreOptions
        })
    };

    createPinnedRows(data = []) {
        const {headers, shouldIndex, selectableRow} = this.props;
        return data.map((row, i) => {
            if (React.isValidElement(row)) {
                return (
                    <div className="pinned-row">{row}</div>
                );
            }
            return (
                <TableRow object={row} headers={headers} key={i} isPinned isSelectable={selectableRow}
                          shouldIndex={shouldIndex}/>
            )
        })
    }

    renderTableFields = () => {
        const {headers, shouldIndex, fields, addRemoveButton, minLength} = this.props;
        return fields.map((field, index) => (
                <ReduxTableRow field={field} headers={headers} index={index} key={index} data={fields.get(index)}
                               shouldIndex={shouldIndex} addRemoveButton={addRemoveButton} fields={fields}
                               minLength={minLength}/>
            )
        )
    };

    render() {
        const {topPinnedRows, bottomPinnedRows, addDefaultValue, headers, shouldIndex, fields, addRemoveButton, addAddButton, meta: {error, submitFailed}, t, moreOptionsButton} = this.props;
        const {moreOptions} = this.state;
        return (
            <>
                <div className="fetura-grid redux">
                    {moreOptionsButton &&
                    <Button className="more-options" type="button"
                            onClick={this._onClick}>{(!moreOptions ? t("reduxTableGrid.moreOptions") : t("close"))}</Button>
                    }
                    <div className="header-container">
                        {
                            shouldIndex && <div className="header-item index">#</div>
                        }
                        {
                            headers.map((header, i) => {
                                return (<TableHeader key={i} show={moreOptions} header={header}/>)
                            })
                        }
                        {
                            addRemoveButton && <div className="header-item index"/>
                        }
                    </div>
                    {
                        !!topPinnedRows &&
                        <div className="top-pinned-container">
                            {
                                isFunction(topPinnedRows) ? this.createPinnedRows(topPinnedRows(fields)) : this.createPinnedRows(topPinnedRows)
                            }
                        </div>
                    }
                    <div className="body-container">
                        {
                            this.renderTableFields()
                        }
                        {
                            fields.length === 0 &&
                            <div className="empty-list">
                                <i>{t("reduxTableGrid.noData")}</i>
                            </div>
                        }
                    </div>
                    {
                        !!bottomPinnedRows &&
                        <div className="bottom-pinned-container">
                            {
                                isFunction(bottomPinnedRows) ? this.createPinnedRows(bottomPinnedRows(fields)) : this.createPinnedRows(bottomPinnedRows)
                            }
                        </div>
                    }
                </div>
                {
                    addAddButton &&
                    <Button type="button"
                            onClick={() => fields.push(addDefaultValue)}>{this.props.t("reduxTableGrid.addRow")}</Button>
                }
                {
                    error && submitFailed &&
                    <InfoBox boxColor="error">{error}</InfoBox>
                }
            </>
        );
    }

}

ReduxTableGrid.propTypes = {
    headers: PropTypes.array.isRequired,
    shouldIndex: PropTypes.bool,
    addRemoveButton: PropTypes.bool,
    addAddButton: PropTypes.bool,
    minLength: PropTypes.number,
    addDefaultValue: PropTypes.object
};

ReduxTableGrid.defaultProps = {
    minLength: 1,
    addDefaultValue: {}
};

ReduxTableGrid = withTranslation()(ReduxTableGrid);

export default ReduxTableGrid
