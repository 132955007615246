import { read, utils } from "xlsx";
import { parse } from "csv-parse";
import store from "../store/store";
import { show } from "redux-modal";
import { ModalName as ConfirmModalName } from "../components/modals-new/confirm-modal/ConfirmModal";
import {
    removeFile as removeFileAPI,
    removeFileNotificationFailure,
    removeFileNotificationSuccess
} from "../api/files/removeFile";
import i18n from "../i18n";
import { selectFile } from "../actions/filesActions";
import { isUsingFakeData } from "./DemoUtils";
import UserTypes from "@wesstron/utils/Api/constants/userTypes";
import { removeTaskUploadStatus } from "../actions/taskActions";

export const PRIV_TYPE = {
    READ_EVERYONE_CLIENT: "READ_EVERYONE_CLIENT",
    READ_EVERYONE_FARM: "READ_EVERYONE_FARM",
    READ_MANAGER_CLIENT: "READ_MANAGER_CLIENT",
    READ_MANAGER_FARM: "READ_MANAGER_FARM",
    READ_USER_LIST: "READ_USER_LIST",
    WRITE_ONLY_OWNER: "WRITE_ONLY_OWNER",
    WRITE_EVERYONE_CLIENT: "WRITE_EVERYONE_CLIENT",
    WRITE_EVERYONE_FARM: "WRITE_EVERYONE_FARM",
    WRITE_MANAGER_CLIENT: "WRITE_MANAGER_CLIENT",
    WRITE_MANAGER_FARM: "WRITE_MANAGER_FARM",
    WRITE_USER_LIST: "WRITE_USER_LIST",
};

const cast = (value, context) => {
    if (!value) return null;
    if (context.header) return value;
    // sprawdzanie czy jest liczbą
    if (!isNaN(+value)) return +value;
    // sprawdzanie czy jest obiektem
    try {
        return JSON.parse(value);
    } catch (e) {
    }
    // sprawdzanie czy jest tablicą
    if (value.startsWith("[")) {
        value = value.replace("[", "").replace("]", "")
        return value.split(",");
    }
    // sprawdzanie czy jest datą
    let timestamp = Date.parse(value);
    if (!isUsingFakeData() && !isNaN(timestamp) && timestamp > 0 && !isNaN(+value)) { //wylaczone parsowanie dat dla demo
        return new Date(timestamp);
    }
    // zwracanie stringa
    return value;
};

export function processCSV(text) {
    return new Promise((resolve, reject) => {
        let output = [];
        try {
            const parser = parse({
                columns: true,
                cast,
                skip_empty_lines: true,
                trim: true,
                skip_lines_with_empty_values: true,
                relax_column_count: true
            });
            parser.on('readable', () => {
                let record = parser.read();
                while (record) {
                    output.push(record);
                    record = parser.read();
                }
            });
            parser.on('end', () => {
                resolve(output);
            });
            parser.write(text);
            parser.end();
        } catch (e) {
            reject(e);
        }
    })
}

function processXLSorXLSX(data) {
    let workbook = read(data, { type: "array", cellDates: true });
    let sheet = workbook.Sheets[workbook.SheetNames[0]];
    return utils.sheet_to_json(sheet);
}

function _processFile(file, extension) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        if (extension === "xls" || extension === "xlsx") {
            reader.onload = async e => {
                let data = new Uint8Array(e.target.result);
                let res = processXLSorXLSX(data);
                resolve(res);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = async e => {
                let res = await processCSV(e.target.result);
                resolve(res);
            }
            reader.readAsText(file);
        }
    })
}

export function isAllowedExtension(file, allowedExtensions) {
    let split = file.name.split(".");
    let extension = split[split.length - 1].toLowerCase();
    return !allowedExtensions.includes(extension);
}

/**
 * Funkcja przetwarzajaca plik
 * @param file {File}
 */
export function processFile(file) {
    let allowedExtensions = ["xls", "xlsx", "csv"];
    let split = file.name.split(".");
    let extension = split[split.length - 1];
    if (isAllowedExtension(file, allowedExtensions)) {
        throw new Error(`Extension .${extension} not supported`);
    }
    return _processFile(file, extension);
}

export function getFontAwesomeIconForType(Type) {
    if (!Type) return "fa-file";
    if (Type === "DIR") return "fa-folder";
    if (Type.includes("video")) return "fa-file-video";
    if (Type.includes("audio")) return "fa-file-audio";
    if (Type === "application/msword") return "fa-file-word";
    if (Type.includes("ms-excel") || Type.includes("spreadsheetml")) return "fa-file-excel";
    if (Type.includes("image")) return "fa-file-image";
    if (Type.includes("zip") || Type === "application/octet-stream") return "fa-file-archive";
    if (Type === "application/pdf") return "fa-file-pdf";
    if (Type.includes("ms-powerpoint") || Type.includes("presentationml")) return "fa-file-powerpoint";
    return "fa-file";
}

export function getFileSize(bytes) {
    let kiloBytes = bytes / 1024;
    if (kiloBytes < 1024) {
        return `${kiloBytes.toFixed(2)} KB`;
    }
    let megaBytes = kiloBytes / 1024;
    if (megaBytes < 1024) {
        return `${megaBytes.toFixed(2)} MB`;
    }
    let gigaBytes = megaBytes / 1024;
    return `${gigaBytes.toFixed(2)} GB`;
}

export function removeFile(file) {
    store.dispatch(show(ConfirmModalName, {
        title: i18n.t(file.Type === "DIR" ? "removeDirectory" : "removeFile", { name: file.FileName }),
        text: i18n.t(file.Type === "DIR" ? "removeDirectoryText" : "removeFileText", { name: file.FileName }),
        confirmText: i18n.t("yes"),
        onConfirmed: props => {
            removeFileAPI(file.FileID).then(res => {
                removeFileNotificationSuccess(res);
                store.dispatch(removeTaskUploadStatus(file.FileID, file.IssueID));
                store.dispatch(selectFile(null));
                props.handleHide();
            }).catch(e => {
                removeFileNotificationFailure(e);
            })
        }
    }))
}

export function validatePrivWrite(File, ClientID, FarmID, User) {
    // sprawdzenie jakie uprawniania sa przypisane do pliku
    if (!File.PrivWrite) {
        return false;
    }
    // jesli Owner/Serwis ma dostep do wszystkiego
    if ([UserTypes.OWNER, UserTypes.SERVICE].includes(User.UserType)) {
        return true;
    }
    // this condition gives rise to an error in the form that if the owner of the file has his rights withdrawn from him by the owner of the farm, he will still be able to manage it
    // if (File.Owner === User.LocalUserID) {
    //     return true;
    // }
    // jesli inny typ konta to sprawdzamy
    switch (File.PrivWrite) {
        case PRIV_TYPE.WRITE_EVERYONE_CLIENT:
            return ClientID === File.ClientID;
        case PRIV_TYPE.WRITE_EVERYONE_FARM:
            return FarmID === File.FarmID;
        case PRIV_TYPE.WRITE_MANAGER_CLIENT:
        case PRIV_TYPE.WRITE_MANAGER_FARM:
            return [UserTypes.MANAGER, UserTypes.OWNER, UserTypes.SERVICE].includes(User.UserType);
        case PRIV_TYPE.WRITE_USER_LIST:
            return !!File.PrivWriteList.includes(User.LocalUserID);
        case PRIV_TYPE.WRITE_ONLY_OWNER:
            return [UserTypes.OWNER, UserTypes.SERVICE].includes(User.UserType);
        default:
            // domyslnie jesli nie ma uprawnien to wlasciciel pliku powinien miec zawsze
            return File.Owner === User.LocalUserID;
    }
}
