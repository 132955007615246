export const initialValue = {
    fetching: true,
    firstFetching: true,
    data: "",
    map: {
        sql: "",
        params: [],
        data: [],
    },
};

export default function animalsReducer(state = initialValue, action) {
    switch (action.type) {
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "CALCULATE_ANIMAL_PARAMETERS_PENDING":
            return {...state, fetching: true};
        case "CALCULATE_ANIMAL_PARAMETERS_FULFILLED":
            return {
                ...state,
                fetching: false,
                firstFetching: action.meta.disableLoading
                    ? false
                    : state.firstFetching,
                data: action.payload,
            };
        case "CALCULATE_ANIMAL_PARAMETERS_REJECTED":
            return {...state, fetching: false, firstFetching: false};
        case "SET_MAP_SQL":
            return {...state, map: {...state.map, ...action.payload}};
        case "SKIP_LOADING":
            return {...state, firstFetching: false, fetching: false};
        default:
            return state;
    }
}
