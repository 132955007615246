export const UniqueEntitiesLevel = 11;

export const UniqueEntities = {
    DIRT: "ue-dirt",
    FOREST: "ue-forest",
    MEADOW: "ue-meadow",
    GRASSLAND: "ue-grassland",
    WATER: "ue-water",
    CONCRETE: "ue-concrete",
    ASPHALT: "ue-asphalt",
    BUILDING: "ue-building"
}
