import FatteningHeader from "../components/FatteningHeader";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getLocationDays} from "../../../../selectors/animalDocumentsSelectors";
import {useMemo} from "react";
import {formatLocationName} from "../../../../utils/global-formatters/formatLocationName";
import FatteningAnimalsItem from "../components/FatteningAnimalsItem";

export default function FatteningAnimalsAmountTable() {

    const {t} = useTranslation();

    const data = useSelector(getLocationDays);

    const {headers, rows} = useMemo(() => {
        const headers = [];
        const rows = [];
        for (const locationID in data) {
            rows.push(
                <div className="header span-row-2">
                    {formatLocationName(locationID)}
                </div>
            );
            for (const {date, value, items = []} of data[locationID]) {
                if (!headers.includes(date)) headers.push(date);
                rows.push(
                    <FatteningAnimalsItem items={items} value={value}/>
                );
            }
        }
        return {headers, rows};
    }, [data]);

    const columns = headers.length + 1;

    return (
        <div className="h-100 grid-wrapper" style={{"grid-template-columns": `repeat(${columns}, 1fr)`}}>
            <FatteningHeader>
                {`${t("grid.columnTypeName.3")} / ${t("date")}`}
            </FatteningHeader>
            {headers.map((d) => <FatteningHeader>{moment(d).format(moment.HTML5_FMT.DATE)}</FatteningHeader>)}
            {rows}
        </div>
    )
}