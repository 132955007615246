import React from 'react';
import { Col, Row } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { getSelectedAnimalForDocuments } from "../../../selectors/animalDocumentsSelectors";
import { Browser } from "../../basics/browser-detection/Browser";
import Card from "../../basics/card/Card";
import ClimateChart from "./charts/ClimateChart";
import ForageChart from "./charts/ForageChart";
import WaterChart from "./charts/WaterChart";
import DeviceChartDetailsModal from '../../modals-new/device-chart-details-modal/DeviceChartDetailsModal';

const DeviceCharts = ({ groupInfo }) => {
    const group = useSelector(getSelectedAnimalForDocuments);
    if (!(group?.AnmGrp)) return null;
    if (Browser.is.Mobile()) {
        return (
            <>
                <Card transparent type={"container"} className={"position-relative m-0"}>
                    <Row className={"overflow-auto flex-nowrap scroll-snap"}>
                        <WaterChart groupInfo={groupInfo} />
                        <ForageChart groupInfo={groupInfo} />
                        <ClimateChart groupInfo={groupInfo} />
                    </Row>
                </Card>
            </>
        )
    }
    return (
        <Col xs={12}>
            <WaterChart groupInfo={groupInfo} />
            <ForageChart groupInfo={groupInfo} />
            <ClimateChart groupInfo={groupInfo} />
            <DeviceChartDetailsModal />
        </Col>
    );
}

export default DeviceCharts;