import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {parseNutriProV2} from "../parsers";
import DeviceDefaultContent from "./DeviceDefaultContent";
import {createKeysAndLabels} from "./utils";


const NutriProV2Content = (props) => {
    const {t} = useTranslation();
    const {keys, labels} = useMemo(() => {
        return createKeysAndLabels(
            {
                key: "text",
                label: t("deviceRows.feeding.dispenserRow.foodAmount")
            },
            {
                key: "textDispenserAStatus",
                label: t("dispenserSensorA")
            },
            {
                key: "textDispenserBStatus",
                label: t("dispenserSensorB")
            },
            {
                key: "textFeederStatus",
                label: t("chainFeederSensor")
            }
        )
    }, [t])
    return <DeviceDefaultContent {...props} parseDataFn={parseNutriProV2}
                                 name={t("$t(newSettings.devices.addDevicesView.dispenserNRFIpsum) V2")}
                                 paramKeys={keys}
                                 paramLabel={labels}/>
}


export default React.memo(NutriProV2Content);