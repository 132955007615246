import { isArray, isNil, isString } from "lodash";

export function getPlacementArray(device, index = null) {
    if (!device) return null;
    const plcmntWithIndexes = isString(device.PlcmntID) && !["unbinded", "UNBINDED"].includes(device.PlcmntID) ? [{ PlcmntID: device.PlcmntID }] : isArray(device?.PlcmntID) ? device.PlcmntID : [];
    return [...new Set(plcmntWithIndexes.filter(o => isNil(index) || (Array.isArray(index) ? index.includes(o.Adr) : o.Adr === index)).map(o => o.PlcmntID))];
}

export function extractDevPlcmnts(possiblyDevice, index = null) {
    let possiblePlcmnts = [];
    if (possiblyDevice) {
        possiblePlcmnts = getPlacementArray(possiblyDevice, index);
    }
    return possiblePlcmnts.filter((p) => !!p);
}
