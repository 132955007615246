import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";
import {get} from "lodash";

export function clearStatuses() {
    return function (dispatch) {
        dispatch({
            type: "MQTT_CLEAR_STATUS"
        })
    }
}

export function onPingSuccess(data) {
    return function (dispatch) {
        dispatch({
            type: "MQTT_ON_PING_SUCCESS",
            payload: {
                GatewayID: data?.DeviceId,
                compilation: get(data, 'CAnsw.compilation', undefined),
                version: get(data, 'CAnsw.version', undefined),
            }
        })
    }
}

export function onPingWaiting(GatewayID) {
    return function (dispatch) {
        dispatch({
            type: "MQTT_ON_PING_WAITING",
            payload: GatewayID
        })
    }
}

export function onPingSend(msg) {
    return function (dispatch) {
        dispatch({
            type: "MQTT_ON_PING_SEND",
            payload: msg.DeviceId
        })
    }
}

export function onPingFailure(msg) {
    return function (dispatch) {
        dispatch({
            type: "MQTT_ON_PING_FAILURE",
            payload: msg.DeviceId
        })
    }
}

export function registerIOT() {
    return async function (dispatch) {
        dispatch({
            type: "REGISTER_IOT",
            payload: invokeApig({...Paths.registerIOT()})
        });
    }
}

export function setLocalConnection(isLocal) {
    return function (dispatch) {
        dispatch({
            type: "SET_LOCAL_CONNECTION",
            payload: isLocal
        })
    }
}
