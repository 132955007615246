import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import i18n from "../i18n";
import { upperFirstLetter } from "./TextUtils";
export const GeneratorComponentTypes = {
    VARIABLE: 'V',
    TABLE: 'TA',
    TEXT: 'TE',
    QR: 'QR',
};
export const GeneratorOrientationTypes = {
    VERTICAL:'VE',
    HORIZONTAL:'HO'
}
export const generatorComponentSize = {
    [GeneratorComponentTypes.VARIABLE]: {
        FontSize: 16,
        Width: 50,
        Height: 15,
    },
    [GeneratorComponentTypes.TEXT]: {
        FontSize: 16,
        Width: 50,
        Height: 15,
    },[GeneratorComponentTypes.TABLE]: {
        FontSize: 12,
        Width: 100,
        Height: 50,
        Columns:2,
        Rows:6,
    },
    [GeneratorComponentTypes.QR]: {
        FontSize:16,
        Name: 'QR',
        Width: 45,
        Height: 45,
    },
}

export const GeneratorComponentOptions = {
    [AnimalTypes.SOW]: [
        {Name: "ParturitionDate", Format: "DATE"},
        {Name: "PlannedParturitionDate", Format: "DATE"},
        {Name: "AnmNo1", Format: "STRING"},
        {Name: "TechnologyGroup", Format: "STRING"},
        {Name: "Location", Format: "STRING"},
        {Name: "InseminationDate", Format: "DATE"},
        {Name: "Inseminator", Format: "STRING"},
        {Name: "Age", Format: "STRING"},
        {Name: "CycleNr", Format: "NUMBER"},
        {Name: "RFID", Format: "STRING"},
        {Name: "SeparatedPiglets", Format: "NUMBER"},
        {Name: "BornPiglets", Format: "NUMBER"},
        {Name: "FallenPiglets", Format: "NUMBER"},
        {Name: "Race", Format: "STRING"},
        {Name: "RaceCode", Format: "STRING"},
    ],
    [AnimalTypes.PORKER]: [
        {Name: "InsertionDate", Format: "DATE"},
        {Name: "Supplier", Format: "STRING"},
        {Name: "BirthDate", Format: "DATE"},
        {Name: "AnmGrp", Format: "STRING"},
        {Name: "insertionLocation", Format: "STRING"},
        {Name: "NumberOfGilts", Format: "NUMBER"},
    ],
    ALL: [
        {Name: "AnmNo1", Format: "STRING"},
        {Name: "InsertionDate", Format: "DATE"},
        {Name: "Location", Format: "STRING"},
        {Name: "BirthDate", Format: "DATE"},
        {Name: "AnmGrp", Format: "STRING"},
    ],
};

export const GeneratorTableColumns = {
    //Komentarze
    COMMENTS: 'Comments',
    COMMENTS_DATE: 'CommentsDate',
    //Cykle
    CYCLES: 'Cycles',
    CYCLES_NR: 'CyclesNr',
    CYCLES_ALIVE:'CyclesAlive',
    CYCLES_DEAD:'CyclesDead',
    CYCLES_MUMMY:'CyclesMummy',
    CYCLES_WEAK:'CyclesWeak',
    CYCLES_SEPARATED:'CyclesSeparated',
}


export const GeneratorTableOptions = {
    [AnimalTypes.SOW]: [
        {
            Name: GeneratorTableColumns.COMMENTS,
            Columns: [GeneratorTableColumns.COMMENTS_DATE, GeneratorTableColumns.COMMENTS],
        },
        {
            Name: GeneratorTableColumns.CYCLES,
            Columns: [
                GeneratorTableColumns.CYCLES_NR,
                GeneratorTableColumns.CYCLES_ALIVE,
                GeneratorTableColumns.CYCLES_WEAK,
                GeneratorTableColumns.CYCLES_DEAD,
                GeneratorTableColumns.CYCLES_MUMMY,
                GeneratorTableColumns.CYCLES_SEPARATED,
            ],
        },
    ],
    [AnimalTypes.PORKER]: [],
    ALL: [],
};
export const getColumnsForTableName = (type,name) => {
    const tables = GeneratorTableOptions[type];
    return tables.find(({Name}) => name === Name)?.Columns || []
}

export const GeneratorFontSizes = [
    {
        name: 10,
        value: 10,
    },
    {
        name: 12,
        value: 12,
    },
    {
        name: 14,
        value: 14,
    },
    {
        name: 16,
        value: 16,
    },
    {
        name: 18,
        value: 18,
    },
    {
        name: 20,
        value: 20,
    },
    {
        name: 22,
        value: 22,
    },
    {
        name: 24,
        value: 24,
    },
    {
        name: 26,
        value: 26,
    },
    {
        name: 28,
        value: 28,
    },
    {
        name: 30,
        value: 30,
    },
    {
        name: 32,
        value: 32,
    },
    {
        name: 34,
        value: 34,
    },
    {
        name: 36,
        value: 36,
    },
    {
        name: 38,
        value: 38,
    },
    {
        name: 40,
        value: 40,
    },
    {
        name: 42,
        value: 42,
    },
    {
        name: 44,
        value: 44,
    },
    {
        name: 46,
        value: 46,
    },
    {
        name: 48,
        value: 48,
    },
    {
        name: 52,
        value: 52,
    },
    {
        name: 56,
        value: 56,
    },
    {
        name: 60,
        value: 60,
    },
    {
        name: 64,
        value: 64,
    },
    {
        name: 70,
        value: 70,
    },
    {
        name: 76,
        value: 76,
    },
    {
        name: 80,
        value: 80,
    },
    {
        name: 88,
        value: 88,
    },
    {
        name: 94,
        value: 94,
    },
    {
        name: 100,
        value: 100,
    },
];
export const GeneratorDateFormats = [
    {name: "DD-MM-YYYY", value: "DD-MM-YYYY"},
    {name: "MM-DD-YYYY", value: "MM-DD-YYYY"},
    {name: "DD.MM.YYYY", value: "DD.MM.YYYY"},
    {name: "MM.DD.YYYY", value: "MM.DD.YYYY"},
    {name: "DD/MM/YYYY", value: "DD/MM/YYYY"},
    {name: "MM/DD/YYYY", value: "MM/DD/YYYY"},
];
export function GeneratorTranslator(name) {
    switch (name) {
        case "ParturitionDate":
            return i18n.t("modals.feedingManageFeedingModal.parturitionDate");
        case "AnmNo1":
            return i18n.t("animalNumber");
        case "SeparationDate":
            return i18n.t("grid.dateSeparation");
        case "SeparationAmount":
            return i18n.t("newReports.rankingReport.separatedPiglets");
        case "Falls":
            return i18n.t("menu.fall");
        case "Parturition":
            return i18n.t("printSelectedAnimalsModal.parturition");
        case "Age":
            return i18n.t("age");
        case "AnmGrp":
            return i18n.t("group");
        case "TechnologyGroup":
            return i18n.t("technologyGroup");
        case "PlannedParturitionDate":
            return i18n.t("animalDocuments.plannedBirth");
        case "Location":
            return i18n.t("location");
        case "FallenPiglets":
                return i18n.t("sowCardGenerator.fallenPiglets");
        case "BornPiglets":
            return i18n.t("translation.sowCardGenerator.bornPiglets");
        case "SeparatedPiglets":
            return i18n.t("translation.sowCardGenerator.separatedPiglets");
        case "Race":
            return i18n.t("shadowDescription.ANIMAL.Race._title");
        case "RaceCode":
            return i18n.t("raceCode");
        case "Supplier":
            return i18n.t("supplier");
        case 'BirthDate':
            return i18n.t("birthDate");
        case 'InsertionDate':
            return i18n.t("grid.columnTypeName.6");
        case 'NumberOfGilts':
            return i18n.t("giltsAmount");
        case 'InsertionLocation':
            return i18n.t("insertionLocation");
        case 'Inseminator':
            return i18n.t("grid.inseminator");
        case 'InseminationDate':
            return i18n.t("grid.inseminationDate");
        case GeneratorTableColumns.COMMENTS:
            return i18n.t("comments");
        case GeneratorTableColumns.COMMENTS_DATE:
            return i18n.t("date");
        case GeneratorTableColumns.CYCLES:
            return i18n.t("printSelectedAnimalsModal.cycles");
        case GeneratorTableColumns.CYCLES_NR:
            return i18n.t("CycleNr");
        case GeneratorTableColumns.CYCLES_ALIVE:
            return i18n.t("alive");
        case GeneratorTableColumns.CYCLES_DEAD:
            return i18n.t("dead");
        case GeneratorTableColumns.CYCLES_MUMMY:
            return i18n.t("mummies");
        case GeneratorTableColumns.CYCLES_WEAK:
            return i18n.t("weakPiglets");
        case GeneratorTableColumns.CYCLES_SEPARATED:
            return upperFirstLetter(i18n.t("sowCardGenerator.separated"))

        default:
            return i18n.t(name);
    }
}
export const getNameOptions = (animalType = AnimalTypes.SOW) => {
    const options = GeneratorComponentOptions[animalType];
    if(!options) return []
    return options.map((item) => ({
        name: GeneratorTranslator(item.Name),
        value: item.Name,
    }));
};

export const sortBySelectedFlag = (array, sortOrder = 'ASC') => {
    array.sort((a, b) => {
        if (sortOrder === 'ASC') {
            if (a.selected && !b.selected) {
                return -1;
            } else if (!a.selected && b.selected) {
                return 1;
            } else {
                return 0;
            }
        } else if (sortOrder === 'DSC') {
            if (a.selected && !b.selected) {
                return 1;
            } else if (!a.selected && b.selected) {
                return -1;
            } else {
                return 0;
            }
        }
    });
    
    return array;
}

export function getTableOptions(AnimalType){
    const tableOptions = GeneratorTableOptions[AnimalType]
    const tmp = {name: i18n.t("sowCardGenerator.emptyTable"), value: 'EMPTY'};
    if(!tableOptions) return [tmp]
    return [
        tmp,
        ...tableOptions.map((item) => ({
            name: GeneratorTranslator(item.Name),
            value: item,
        })),
    ];
}

export const isParameterDate = (name, animalType) => {
    if (!name) return false;
    const option = GeneratorComponentOptions[animalType].find(
        (item) => item?.Name === name
    );
    if (!option) return false;
    return option.Format === "DATE";
};