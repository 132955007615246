import moment from "moment";
import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EstimatedWeight from "../../../../beans/estimated-weight/EstimatedWeight";
import { UnitTypes } from "../../../../constans/unitTypes";
import { getForages, getTargetForage } from "../../../../selectors/settingsSelector";
import { utcMomentToLocal } from "../../../../utils/DateTimeUtils";
import { isBetween, isFiniteNumber } from "../../../../utils/MathUtils";
import { convertWeightUnitTo, getUnit } from "../../../../utils/UnitUtils";
import useDeviceLocations from "../useDeviceLocations";
import useDomain from "../useDomain";
import ChartTemplate from "./ChartTemplate";
import { ERRORS, applyOffset, calculateAutoDomain, skipData } from "./utils";
import { makeGetDeviceData } from "../../../../selectors/aggregatedDataSelector";
import useOpenDetailsModal from "../useOpenDetailsModal";
import { arrayValueFormatter } from "../../../../utils/ChartsUtils";

const DATA_TYPE = "forage";


const Chart = ({ groupInfo }) => {

    const getDeviceData = useMemo(makeGetDeviceData, []);
    const locations = useDeviceLocations(DATA_TYPE, false);

    const {
        data,
        error,
        loading
    } = useSelector(state => getDeviceData(state, groupInfo.start, groupInfo.end, locations, DATA_TYPE));

    const allForages = useSelector(getForages);

    const show = !!locations.length;

    const ref = useRef(null);
    const weightFormatter = useCallback((value) => {
        if (value === undefined || value === null || isNaN(value)) return null;
        return convertWeightUnitTo(value, {
            showUnit: false,
            unit: UnitTypes.MEDIUM,
            rawValue: true,
            fixed: 2
        });
    }, []);
    const targetForage = useSelector(getTargetForage);
    const chartDomain = useDomain();

    const { dataSet, forages } = useMemo(() => {
        if (!data) return { dataSet: [], forages: {} };
        const fmt = weightFormatter;
        const ds = [], forages = {};
        const estimate = new EstimatedWeight(0, []);
        estimate.setWeightTable(targetForage.WeeklyTarget);
        estimate.setBirthDate(groupInfo.birthTime);
        const [chartStart, chartEnd] = chartDomain;
        const diff = Math.abs(moment.utc(chartStart).diff(moment.utc(chartEnd), "day"));
        for (let i = 0; i <= diff; i++) {
            const dayUTC = moment.utc(chartStart).add(i, "day");
            const dayLOCAL = utcMomentToLocal(dayUTC);
            const isGroupActive = isBetween(+dayUTC, groupInfo.start, groupInfo.end);
            const deviceData = data[+dayUTC];
            const estimated = estimate.getWeightByDate(+dayLOCAL);
            const offset = [fmt(applyOffset(estimated, -targetForage.Offset)), fmt(applyOffset(estimated, targetForage.Offset))];
            if (!isGroupActive) {
                ds.push({
                    time: +dayUTC,
                    estimated: fmt(estimated),
                    value: null,
                    details: [],
                    offset,
                    error: null
                });
                continue;
            }
            const { details, ...foragesInLocation } = (() => {
                const forageUsage = {};
                const details = [];
                let cnt = 0;
                for (const locationId of locations) {
                    const hasData = deviceData ? !!deviceData[locationId] : false;
                    const { Usage, Forages } = hasData ? deviceData[locationId] : {};
                    const animals = groupInfo.getNumberByTimeAndLocation(+dayLOCAL, locationId);
                    if (skipData(animals)) continue;
                    let tmp = !Forages || Forages.length === 0 ? { unknown: Usage } : Forages.reduce((acc, { FID, Usage }) => ({ ...acc, [FID]: Usage }), {});
                    const locationData = {
                        locationId,
                        value: animals && isFiniteNumber(Usage) ? fmt(Usage / animals) : null,
                        error: null,
                        count: animals
                    };
                    if (locationData.value === null) {
                        locationData.error = ERRORS.NO_DATA;
                    } else if (!isBetween(locationData.value, offset[0], offset[1])) {
                        locationData.error = ERRORS.OUT_OF_BOUNDS;
                    }
                    details.push(locationData);
                    for (let FID in tmp) {
                        if (!forageUsage[FID]) forageUsage[FID] = 0;
                        if (isFiniteNumber(tmp[FID])) forageUsage[FID] += tmp[FID];
                    }
                    if (isFiniteNumber(Usage)) {
                        cnt += animals;
                    }
                }
                let obj = { details };
                for (let key in forageUsage) {
                    if (cnt > 0) {
                        obj[key] = fmt(forageUsage[key] / cnt);
                    }
                }
                return obj;
            })();
            console.log(foragesInLocation);
            const obj = {
                time: +dayUTC,
                estimated: fmt(estimated),
                details,
                offset,
                hasError: details.some(({ error }) => [ERRORS.OUT_OF_BOUNDS].includes(error)),
                error: null
                // error: details.some(({ error }) => [ERRORS.OUT_OF_BOUNDS].includes(error)) ? 0 : null
            };
            let allUsage = 0;
            for (let key in foragesInLocation) {
                let tempKey = key;
                if (!forages[key]) {
                    forages[key] = allForages.find(item => item.SetID === key) || null;
                }
                if (!forages[key]) tempKey = "unknown";
                allUsage += foragesInLocation[tempKey];
                obj[key] = foragesInLocation[tempKey];
            }
            if (obj.hasError) obj.error = allUsage;
            ds.push(obj);
        }
        return { dataSet: ds, forages };

    }, [data, targetForage, groupInfo, weightFormatter, locations, chartDomain, allForages]);

    console.log(dataSet);

    const {t} = useTranslation();

    const dataDef = useMemo(() => {
        const unit = t("perPig", { value: getUnit("weight", UnitTypes.MEDIUM) });
        let tmp = [];
        for (let key in forages) {
            if (!forages[key]) {
                tmp.push({
                    dataKey: key,
                    color: "teal",
                    type: "linear",
                    unit,
                    name: t("deviceRows.feeding.dispenserRow.unknownForage"),
                    _role: "read",
                    chartType: "Bar",
                    stack: "1"
                });
            } else {
                tmp.push({
                    dataKey: key,
                    color: forages[key].SetData.Color,
                    type: "linear",
                    unit,
                    name: forages[key].SetData.Name,
                    _role: "read",
                    chartType: "Bar",
                    stack: "1"
                });
            }
        }
        tmp.push(
            {
                dataKey: "offset",
                color: "teal",
                strokeDasharray: "5 5",
                strokeOpacity: 0.5,
                opacity: 0.2,
                type: "linear",
                unit,
                name: t("idealCharts.plannedUsage"),
                hideLegend: true,
                _role: "range",
                formatter: arrayValueFormatter,
            },
            {
                color: "red",
                opacity: 1,
                dataKey: "error",
                chartType: "Scatter",
                unit,
                name: t("idealCharts.problems")
            })
        return tmp;
    }, [t, forages]);

    const domain = useMemo(() => {
        return calculateAutoDomain(dataSet);
    }, [dataSet]);

    const onClick = useOpenDetailsModal("forage", locations, groupInfo);

    return (
        <ChartTemplate
            show={show}
            domain={domain}
            data={data} error={error}
            YaxisName={t("IOT.forageConsumption")}
            name={t("idealCharts.forageUsage")} loading={loading} id={"cy-forage-chart"}
            dataDef={dataDef}
            dataSet={dataSet}
            defaultExpanded={true}
            forwardRef={ref}
            onClick={onClick}
            saveAsExcell={t("idealCharts.forageUsage")}
        />
    );
};

const ForageChart = ({ groupInfo }) => {
    const hasSiloses = useDeviceLocations(DATA_TYPE, true);
    return hasSiloses ? <Chart groupInfo={groupInfo} /> : null;
};

export default React.memo(ForageChart);