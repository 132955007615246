
import { get } from "lodash";
import React from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import { connectModal } from "redux-modal";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import "./_print-selected-animals-modal.scss";
import { makeGetBuildingsMap } from "../../../selectors/buildingsSelector";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import { getCardTemplates } from "../../../selectors/settingsSelector";
import { printAnimalWorker } from "../../../utils/SowCardGeneratorWorkerUtils";
import { getAnimalEvents, makeGetAnimalsInGroup } from "../../../selectors/animalDocumentsSelectors";
import { getCommentDictionary } from "../../../selectors/dictionarySelectors";
import { getFarmID } from "../../../selectors/farmSelector";
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";

export const ModalName = "print-generator-animal-modal";

async function submit(values, dispatch, props) {
    const {
        template: {AnimalType},
    } = props;

    return printAnimalWorker(props, AnimalType);
}

function mapStateToProps(state) {
    const params = {
        getDeletedAnimals: true,
    }
    let formValues = getFormValues(ModalName)(state);
    let getBuildingsMap = makeGetBuildingsMap();
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    return {
        animals: getAnimalsInGroup(state, params),
        events: getAnimalEvents(state),
        medicines: state.dictionary.medicine,
        buildingsMap: getBuildingsMap(state),
        templates: getCardTemplates(state),
        template: get(formValues, "template", null),
        races: state.dictionary.race.WData,
        FarmID: getFarmID(state),
        commentDictionary: getCommentDictionary(state),
    };
}

class PrintGeneratorAnimalModal extends React.Component {
    constructor(props) {
        super(props);
        const { initialize } = this.props;
        this.state = {
            template: null,
        };
        initialize({ template: null });
    }

    getOptions = () => {
        const { templates, utils } = this.props;
        const tmp = [];
        const animalType = utils.animalType === AnimalTypes.PIGLET ? AnimalTypes.PORKER : utils.animalType;
        for (const item of templates.filter(
            ({ data }) => data.AnimalType === animalType || data.AnimalType === 'ALL'
        )){
            tmp.push({ value: item.data, name: item.data.Name });
        }
        return tmp;
    };
    render() {
        const {
            show,
            handleHide,
            handleSubmit,
            t,
            submitting,
            template
        } = this.props;
        const options = this.getOptions();
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit} name={ModalName}>
                    <ModalHeader
                        title={t("sowCardGenerator.chooseTemplate")}
                        onCloseClick={handleHide}
                    />
                    <ModalBody className={ModalName}>
                        <Field
                            name={"template"}
                            label={t("template")}
                            options={options}
                            clearButton={false}
                            component={ReduxLabeledSelect}
                        />
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton
                        submitting={submitting}
                        formName={ModalName}
                        onCloseClick={handleHide}
                        confirmText={t("grid.print1")}
                        hasButtonDisabled={!template}
                    />
                </form>
            </Modal>
        );
    }
}

export default compose(
    withTranslation(),
    connectModal({ name: ModalName }),
    connect(mapStateToProps),
    reduxForm({
        form: ModalName,
        onSubmit: submit
    })
)(PrintGeneratorAnimalModal);
