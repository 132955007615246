import AnimalModItem from "../AnimalModItem";
import {isString} from "lodash";

const getChanges = ({log, translationManager}) => {
    const {ListParams} = log.log;
    const changes = [];
    const {format: _format, translate} = translationManager;
    const format = (fmt, val) => {
        const result = _format(fmt, val);
        if (isString(result) && result.includes("$t(")) return translate(result);
        return result;
    }
    if (ListParams?.length) {
        // inne formatowanie bo lista zmian
        for (let {path, newValue, oldValue, valueFormatter} of Object.values(ListParams)) {
            changes.push({
                name: translate(log.convertPathToTranslation(path)),
                oldValue: format(valueFormatter, oldValue),
                newValue: format(valueFormatter, newValue),
                path
            });
        }
    }
    return changes;

}

export default (titleFn) => {
    return ({log, translationManager, index}) => {
        const params = log.getCodeParams(log?.log?.Params?.[index ?? 0] ?? {});
        const changes = getChanges({log, translationManager});
        return new AnimalModItem({
            invokedBy: log.getUserObj(),
            timestamp: log.log.DtaCrtTime,
            animalId: log.log.ObjID,
            comment: log.getComment(),
            changes: changes,
            title: titleFn({changes, log, translationManager, params, timestamp: log.log.DtaCrtTime}),
            id: log.log.LogID,
            objectId: log.log.ObjOld?.EvID,
            subgroupName: translationManager.format("subgroupNameFormatter", log.log.ObjID)
        })
    }
}