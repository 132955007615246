import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import i18n from "../../i18n";
import {createErrorNotification, createSuccessNotification} from "../../utils/ResponseUtils";

/**
 * @typedef WeightingData
 * @type {object}
 * @property AnmID {string}         ID zwierzecia
 * @property AnmCnt {number}        Ilość zwierząt, które to dotyczy
 * @property EvTime {number}        czas wykonania zdarzeniia (możliwe, że przejdzie do tablicy animals)
 * @property Comment {string}       komentarz
 * @property GrID {string}          id grupy
 * @property Weight {number}        waga upadłych prosiąt
 * @property Reason {string}        powód upadku
 * @property Plcmnt {string}        miejsce, w ktorym znajdowalo sie zwierze
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego ważenia
 * @param data {[WeightingData]}            Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createWeighting(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createWeighting({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createWeightingNotificationSuccess(response, {uniqueId = myID()} = {}) {
    if (createSuccessNotification(response, "apiNotifications.createWeightingTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createWeightingTitle"),
        message: i18n.t("apiNotifications.createWeightingSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createWeightingNotificationFailure(response, {uniqueId = myID()} = {}) {
    if (createErrorNotification(response, "apiNotifications.createWeightingTitle", {uniqueId})) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createWeightingTitle"),
        message: i18n.t("apiNotifications.createWeightingFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}

export function updateWeightingNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.updateWeightingTitle"),
        message: i18n.t("apiNotifications.updateWeightingSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function updateWeightingNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.updateWeightingTitle"),
        message: i18n.t("apiNotifications.updateWeightingFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}

export function removeWeightingNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("removeWeighting"),
        message: i18n.t("apiNotifications.removeWeightingSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function removeWeightingNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("removeWeighting"),
        message: i18n.t("apiNotifications.removeWeightingFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
