import React from "react";
import PropTypes from "prop-types"
import CycleRenderer from "./CycleRenderer";
import {connect} from "react-redux";
import Tooltip from "../../basics/tooltip/Tooltip";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {checkIfRowHasMoreEvents} from "../../../utils/AnimalDocumentsUtils";

export class CycleNumberRenderer extends React.Component {

    getCellBackground = () => {
        const {cycleTable, cycle: {cycle}} = this.props;
        const howManyCycles = cycleTable.filter(row => row.cycle === cycle).length;
        if (howManyCycles > 3) return "error";
        if (howManyCycles >= 2) return "warning";
        return "";
    };

    render() {
        const {cycle, t, expanded, isAdvanced} = this.props;
        const name = this.getCellBackground();
        const shouldExpand = checkIfRowHasMoreEvents(cycle);
        return (
            <CycleRenderer className={`${name}`}>
                {cycle.cycle}
                {
                    (shouldExpand && (expanded?.rows?.length === 0 || !isAdvanced)) &&
                    <Tooltip tooltipContent={t("sowCardGridMinimal.moreCyclesInRow")} type={"success"}>
                        <div className={`box success`}/>
                    </Tooltip>
                }
            </CycleRenderer>
        );
    }

}

CycleNumberRenderer.propTypes = {
    cycle: PropTypes.isRequired
};

export default compose(
    connect((state) => ({
        cycleTable: state.animalDocuments.cycleTable
    })),
    withTranslation()
)(CycleNumberRenderer);
