import {get} from "lodash";

export const initialValue = {
    connecting: false,
    connected: false,
    status: new Map(),
    subscriptions: [],
    reconnecting: false,
    startTime: null, // czas kiedy sie polaczył
    endTime: null, // czas kiedy stracil polczenie
    localConnection: false
};

export default function mqttReducer(state = initialValue, action) {
    switch (action.type) {
        case "MQTT_CONNECT_PENDING":
            return {
                ...state,
                connecting: true,
                connected: false,
            };
        case "MQTT_CONNECT_FULFILLED":
            return {
                ...state,
                connecting: false,
                connected: true,
                reconnecting: false,
                startTime: +new Date()
            };
        case "MQTT_CONNECTION_FAILURE":
        case "MQTT_CONNECTION_LOST":
            return {
                ...state,
                connecting: false,
                connected: false,
                endTime: +new Date()
            };
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "MQTT_CLEAR_STATUS":
        case "CHANGE_FARM": {
            return {
                ...state,
                status: new Map()
            }
        }
        case "MQTT_ON_PING_WAITING": {
            let map = new Map(state.status);
            let GatewayID = action.payload;
            let currentData = map.get(GatewayID) || {};
            map.set(GatewayID, {...currentData, waiting: true});
            return {
                ...state,
                status: map
            }
        }
        case "MQTT_ON_PING_SEND": {
            let map = new Map(state.status);
            let GatewayID = action.payload;
            let currentData = map.get(GatewayID) || {};
            map.set(GatewayID, {...currentData, send: +new Date(), waiting: false});
            return {
                ...state,
                status: map
            }
        }
        case "MQTT_ON_PING_SUCCESS": {
            let map = new Map(state.status);
            let GatewayID = action.payload.GatewayID;
            let sendTime = get(map.get(GatewayID), "send");
            if (sendTime) {
                map.set(GatewayID, {
                    delay: +new Date() - sendTime,
                    compilation: action.payload.compilation,
                    version: action.payload.version,
                    GatewayID
                });
            } else {
                map.set(GatewayID, {
                    delay: null,
                    compilation: action.payload.compilation,
                    version: action.payload.version,
                    GatewayID
                });
            }
            return {
                ...state,
                status: map
            }
        }
        case "MQTT_ON_PING_FAILURE": {
            let map = new Map(state.status);
            let GatewayID = action.payload;
            map.delete(GatewayID);
            return {
                ...state,
                status: map
            }
        }
        case "SUBSCRIBE_MQTT_TOPICS": {
            return {
                ...state,
                subscriptions: action.payload
            }
        }
        case "MQTT_KEEPS_RECONNECTING": {
            return {
                ...state,
                connecting: false,
                reconnecting: true
            }
        }
        case "SET_LOCAL_CONNECTION": {
            return {...state, localConnection: action.payload}
        }
        default:
            return state
    }
}
