import {getRotatedData} from "../utils";
import SVGPaths from "../../components/SVGPaths";
import React from "react";
import Material from "./Material";

const Ipsum = (props, orientation) => {
    const {id, down, right, className} = props;
    const height = 200;
    const width = 70;

    const animalInside = !!props["animal"];

    const {angle, viewBox, toAbsolute} = getRotatedData(orientation, down, right, width, height);
    return (
        <pattern className={className} id={id} x="0" y="0" width="100%" height="100%"
                 viewBox={viewBox}
                 patternUnits="objectBoundingBox">
            <SVGPaths.IpsumFloor className={Material.FLOOR} translate={toAbsolute(`${width / 2}-50%`, 0)}
                                 rotate={[angle, 0, 0]}/>
            <SVGPaths.IpsumTrough className={Material.TROUGH} translate={toAbsolute(`${width / 2}-50%`, 0)}
                                  rotate={[angle, 0, 0]}/>
            {
                animalInside &&
                <SVGPaths.Pig className={Material.PIG} translate={toAbsolute(`${width / 2}-50%`, 35)}
                              rotate={[angle, 0, 0]}/>
            }

            <SVGPaths.IpsumOutline className={Material.STEEL_DARK} translate={toAbsolute(`${width / 2}-50%`, 0)}
                                   rotate={[angle, 0, 0]}/>
            <SVGPaths.IpsumForeground className={Material.STEEL_LIGHT} translate={toAbsolute(`${width / 2}-50%`, 0)}
                                      rotate={[angle, 0, 0]}/>
            <SVGPaths.Dispenser className={Material.DISPENSER} translate={toAbsolute(`${width / 2}-50%-15`, 25)}
                                rotate={[angle, 0, 0]}/>
        </pattern>
    )
}

export default Ipsum;