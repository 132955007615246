const initialValue = {
    data: {}
};

export default function mapShadowReducer(state = initialValue, action) {
    switch (action.type) {
        case "CHANGE_MAP_SHADOW_DELTA": {
            const nextData = {...state.data};
            const now = Date.now();
            for (let [key, value] of Object.entries(action.payload)) {
                if (nextData[key]) {
                    nextData[key] = {...nextData[key], ...value};
                    nextData[key]._read = now;
                }
            }
            return {
                ...state,
                data: nextData
            };
        }
        case "CHANGE_MAP_SHADOW": {
            const nextData = {...state.data};
            const now = Date.now();
            for (let [key, value] of Object.entries(action.payload)) {
                // `_read` from the payload holds the latest metadata
                const newTime = value?._read ?? now;
                const initTime = nextData[key]?._init ?? 0;
                // check if incoming data is newer
                // (this fixes data being overridden if farm has >1 gateway)
                if (newTime >= initTime) {
                    nextData[key] = {...value};
                    // save init time
                    nextData[key]._init = newTime;
                    nextData[key]._read = now;
                }
            }
            return {
                ...state,
                data: nextData
            };
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state;
    }
}
