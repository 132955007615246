import {invokeApig, refreshSession} from "../libs/awsLib";
import Paths from "../api/paths";
import {getEmployees} from "./userActions";
import NewIOT from "../IOT/NewIOT";
import {notify} from "reapop";
import i18next from "i18next";
import {history} from "../components/router/CustomBrowserRouter";
import {isFunction} from "lodash";

export function changeClientV2(ClientID) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_CLIENT",
            payload: ClientID
        });
    }
}

export function changeClient(ClientID, FarmID) {
    //console.log(ClientID);
    return function (dispatch) {
        dispatch({
            type: "CHANGE_CLIENT",
            payload: invokeApig({
                ...Paths.service(),
                queryParams: {
                    ClientID,
                    FarmID
                }
            })
        }).then(res => {
            console.log("changeClient res", res);
            dispatch({
                type: "REFRESH_TOKEN",
                payload: refreshSession(),
                meta: {
                    ClientID
                }
            }).then(() => {
                dispatch(getEmployees(ClientID));
                NewIOT.subscribeAllTopics();
            })
        }).catch(e => {
            console.error(e);
            history.push("/list");
        })
    }
}

export function checkMaintenanceStatus(onSuccess = undefined, forceFetch = false) {
    return (dispatch) => {
        dispatch({
            type: "MAINTENANCE_STATUS",
            payload: invokeApig({
                ...Paths.getMaintenance(),
                forceFetch
            })
        }).then(() => {
            if (isFunction(onSuccess)) onSuccess();
        }).catch((err) => {
            let msg = {
                title: i18next.t("error"),
                message: err.message,
                status: 'error',
                dismissible: true
            };
            dispatch(notify(msg));
            console.error("checkMaintenanceStatus: ", err)
        })
    }
}

export function deleteFarmData(farmID, params, onSuccess) {
    return function (dispatch) {
        dispatch({
            type: "DELETE_FARM_DATA",
            payload: invokeApig({
                ...Paths.deleteFarmData({farmID}),
                body: {...params}
            })
        }).then((res) => {
            let msg = {
                title: i18next.t("success"),
                message: res.value.message,
                status: "success",
                dismissible: true,
                dismissAfter: 0
            };
            dispatch(notify(msg));
            if (onSuccess) onSuccess();
        }).catch((err) => {
            let msg = {
                title: i18next.t("error"),
                message: err.message,
                status: "error",
                dismissible: true,
                dismissAfter: 0
            };
            dispatch(notify(msg));
        })
    }
}

export function deleteMaintenance(params, index) {
    const {DtaStartTime, DtaEndTime} = params;
    return function (dispatch) {
        dispatch({
            type: "DELETE_MAINTENANCE",
            payload: invokeApig({
                ...Paths.deleteMaintenance(),
                body: {
                    startTime: DtaStartTime,
                    endTime: DtaEndTime
                }
            }),
            meta: {deletingIndex: index}
        }).then(() => {
            let msg = {
                title: i18next.t("success"),
                message: i18next.t("popNotifications.successfullyUpdated"),
                status: "success",
                dismissible: true
            };
            dispatch(notify(msg));
        }).catch((err) => {
            let msg = {
                title: i18next.t("error"),
                message: err.message,
                status: "error",
                dismissible: true
            };
            dispatch(notify(msg));
            console.error("deleteMaintenance: ", err);
        });
    };
}
