import { createSelector } from "reselect";
import { getMedicines } from "./dictionarySelectors";
import { getForages } from "./settingsSelector";
import economyTypes from "@wesstron/utils/Api/constants/economyTypes";
import { getPrice } from "../utils/EconomyUtils";

export const getBasicEconomy = state => state.economy.basic;

export const getBasicEconomyLoading = state => state.economy.loadingBasic;

export const getEconomy = state => state.economy.economy;

export const getPriceListInitialValues = createSelector(getEconomy, getMedicines, getForages, (economy, medicines, forages) => {
    if (economy.length === 0) return null;
    let data = {
        Currency: economy.find(item => item.EID === economyTypes.CURRENCY)?.Currency || null,
        PorkerPrice: getPrice(economy.find(item => item.EID === economyTypes.PORKER_PRICE)) || null,
        PigFallPrice: getPrice(economy.find(item => item.EID === economyTypes.PIG_FALL_PRICE)) || null,
        PigletFallPrice: getPrice(economy.find(item => item.EID === economyTypes.PIGLET_FALL_PRICE)) || null,
        PorkerFallPrice: getPrice(economy.find(item => item.EID === economyTypes.PORKER_FALL_PRICE)) || null,
        RenovationSowFallPrice: getPrice(economy.find(item => item.EID === economyTypes.RENOVATION_SOW_FALL_PRICE)) || null,
        SowFallPrice: getPrice(economy.find(item => item.EID === economyTypes.SOW_FALL_PRICE)) || null,
        WaterPrice: getPrice(economy.find(item => item.EID === economyTypes.WATER)) || null,
        ElectricityPrice: getPrice(economy.find(item => item.EID === economyTypes.ELECTRICITY)) || null,
        EmployeesPrice: getPrice(economy.find(item => item.EID === economyTypes.EMPLOYEES)) || null,
        LoanInstallment: getPrice(economy.find(item => item.EID === economyTypes.LOAN)) || null
    };
    for (let medicine of medicines) {
        data[medicine.WordID] = getPrice(economy.find(item => item.EID === medicine.WordID)) || null
    }
    for (let forage of forages) {
        data[forage.SetID] = getPrice(economy.find(item => item.EID === forage.SetID)) || null
    }
    return data;
});

const getEID = (state, { EID }) => EID;

export const getEconomyObject = createSelector(getEconomy, getEID, (economy, EID) => {
    return economy.find(item => item.EID === EID) || null;
});

export const makeGetEconomyObject = () => getEconomyObject;

export const getCurrency = createSelector(getEconomy, economy => {
    return economy.find(item => item.EID === economyTypes.CURRENCY)?.Currency || null;
})

export const makeGetCurrency = () => getCurrency;
