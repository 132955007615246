import { getUserApiData } from "../../utils/UserUtils";
import { invokeApig } from "../../libs/awsLib";
import Paths from "../paths";
import { myID } from "../../libs/generateID";
import store from "../../store/store";
import { notify } from "reapop";
import i18n from "../../i18n";
import { createErrorNotification, createSuccessNotification } from "../../utils/ResponseUtils";

/**
 * @typedef CastrationData
 * @type {object}
 * @property AnmID {string}         ID zwierzecia
 * @property EvTime {number}        czas wykonania zdarzeniia (możliwe, że przejdzie do tablicy animals)
 * @property Comment {string}       komentarz
 * @property GrID {string}          id grupy
 * @property Castrate {boolean}     Czy byla wykonana kastracja
 * @property Tails {boolean}        Czy byly obcinane ogony
 * @property Teeth {boolean}        Czy bylo cos robione z zebami (nie mam pojecia co)
 * @property Weighting {boolean}    Czy bylo wazenie
 * @property Weight {number}        Waga dla wazenia
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego upadku prosiat
 * @param data {[CastrationData]}           Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createCastration(data, { FarmID, ClientID, LocalUserID } = {}) {
    const { _FarmID, _LocalUserID, _ClientID } = getUserApiData({ FarmID, ClientID, LocalUserID });
    return invokeApig({
        ...Paths.createCastration({ farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID }),
        body: data
    })
}

export function createCastrationNotificationSuccess(response, { uniqueId = myID() } = {}) {
    if (createSuccessNotification(response, "apiNotifications.createProcedureTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createProcedureTitle"),
        message: i18n.t("apiNotifications.createProcedureSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createCastrationNotificationFailure(response, { uniqueId = myID() } = {}) {
    if (createErrorNotification(response, "apiNotifications.createProcedureTitle", { uniqueId })) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createProcedureTitle"),
        message: i18n.t("apiNotifications.createProcedureFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
