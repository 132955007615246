import React from "react";
import PropTypes from "prop-types";
import "./_chip.scss";
import Button from "../button/Button";
import {isFunction} from "lodash";

export default class Chip extends React.Component {

    render() {
        const {children, color, onClick, onClickClose, className, size} = this.props;
        let cName = "fetura-chip";
        (className) && (cName += ` ${className}`);
        (color) && (cName += ` ${color}`);
        (size) && (cName += ` ${size}`);
        (isFunction(onClick)) && (cName += ` pointer`);
        return (
            <span onClick={onClick} className={cName}>
                <div className="fetura-chip-content">
                    {children}
                </div>
                {
                    (isFunction(onClickClose)) &&
                    <Button buttonStyle={"round"} icon={<i className="fas fa-times"/>} onClick={onClickClose}
                            buttonColor={color}/>
                }
            </span>
        );
    }

}
Chip.defaultProps = {
    className: ""
}
Chip.propTypes = {
    size: PropTypes.oneOf(["sm", "lg"]),
    onClickClose: PropTypes.func,
    onClick: PropTypes.func,
    className: PropTypes.string,
    color: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error", ""])
};
