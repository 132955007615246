import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Modal} from "react-bootstrap";
import {connectModal} from "redux-modal";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, reduxForm} from "redux-form";
import {submit, validate} from "./ConfirmRemoveAnimalSubmit";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import InfoBox from "../../basics/info-box/InfoBox";
import {withRouter} from "react-router-dom";
import EventTypes from '@wesstron/utils/Api/constants/eventTypes';

export const ModalName = "confirm-remove-animal";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        events: state.events.eventsForAnimals
    };
}

class ConfirmRemoveAnimal extends Component {
    animalHasEvents = (animal) => {
        if(!animal) return false
        const {events} = this.props;
        const animalEvents = events.get(animal.AnmID)
        const skipEvents = [EventTypes.INSERTION,EventTypes.SOW_CYCLES]
        return animalEvents.some(({EvCode}) => !skipEvents.includes(EvCode)) //Jeżeli nie posiada eventów hodowlanych zwróc false
    }
    render() {
        const {handleHide, show, t, animal, group, submitting} = this.props;
        const hasEvents = this.animalHasEvents(animal)
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader title={t("confirmRemoveAnimal")} onCloseClick={handleHide} />
                <ModalBody>
                    {hasEvents ? (
                        <InfoBox boxColor="error">{t("toRemoveAnimalWithEvents", {AnmNo1: animal?.AnmNo1})}</InfoBox>
                    ) : (
                        <InfoBox>
                            {animal && t("toRemoveAnimal", {AnmNo1: animal?.AnmNo1})}
                            {group && t("toRemoveGroup", {GrNo1: group?.GrNo1})}
                        </InfoBox>
                    )}
                    <Field name={"itemNumber"} component={ReduxLabeledInput} type={"text"} label={t("animalNumber")} />
                    <Field label={t("enterReasonOfYourDecision")} component={ReduxLabeledInput} type={"textarea"} id={"comment"} required name={"comment"} />
                </ModalBody>
                <ModalFooter hasConfirmButton confirmText={t("save")} onCloseClick={handleHide} formName={ModalName} submitting={submitting} />
            </Modal>
        );
    }
}

export default compose(
    connectModal({name: ModalName}),
    withTranslation(),
    connect(mapStateToProps),
    withRouter,
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(ConfirmRemoveAnimal);
