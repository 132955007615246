import { get } from "lodash";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { show } from "redux-modal";
import { FilterTypes } from "../../constans/filter";
import {
    getDetailedLocationDays,
    getSelectedAnimalForDocuments,
    makeGetAnimalsInGroup
} from "../../selectors/animalDocumentsSelectors";
import { getGroupBasicInfoBoxData } from "../../utils/GroupDocumentsUtils";
import { getManageSubgroups } from "../../utils/SettingsUtils";
import { sortAsString } from "../../utils/SortUtils";
import { formatLocationName } from "../../utils/global-formatters/formatLocationName";
import InfoBox from "../basics/info-box/InfoBox";
import TableGrid from "../basics/table-grid/TableGrid";
import DefaultMobileRow from "../basics/table-grid/default-mobile-row/DefaultMobileRow";
import AnimalReferenceDocumentModalGrid from "../grids/animal-reference-document/AnimalReferenceDocumentModalGrid";
import CollapsableContainer from "./containers/CollapsableContainer";
import { getFarmID } from "../../selectors/farmSelector";
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";

function makeMapStateToProps() {
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    return (state) => ({
        farm: getFarmID(state),
        group: getSelectedAnimalForDocuments(state),
        animals: getAnimalsInGroup(state),
        locationDays: getDetailedLocationDays(state)
    });
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ show }, dispatch),
    };
}

function SummaryNumberSection({ amount, text, cyData }) {
    return (
        <>
            <h4 cy-data={cyData} className="justify-content-center">{amount}</h4>
            <span className="text-capitalize">{text}</span>
        </>
    );
}

class GroupAnimalList extends Component {

    canManageSubgroups = getManageSubgroups();

    getSummary = () => {
        const { group, animals, locationDays } = this.props;
        const {
            soldCounter,
            insertedCounter,
            fallCounter,
            animalsSize,
            hasIndividualAnimal,
            insertionCounter,
            toGroupTransferCounter,
            fromGroupTransferCounter,
            removedCounter
        } = getGroupBasicInfoBoxData({ group, animals, locationDays });
        return {
            soldCounter,
            insertedCounter,
            fallCounter,
            animalsSize,
            hasIndividualAnimal,
            insertionCounter,
            toGroupTransferCounter,
            fromGroupTransferCounter,
            removedCounter
        };
    };

    getAnimals = () => {
        const { animals, group } = this.props;
        if (!group) return [];
        const { AnmIDs } = group;
        return animals.filter(({ AnmID }) => AnmIDs.includes(AnmID));
    };

    pcsFormatter = (value) => {
        const { t } = this.props;
        return t("pcs", { count: value });
    };

    animalKindValueFormatter = (value) => {
        const { t } = this.props;
        return t(`animalKind.${value}`);
    };

    isAllowedToDisplayNumber = (value) => {
        return ((get(value, "AnmCnt", 1) === 1 && (![AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(+get(value, "AnimalKind", "")) || get(value, "RFID", "") || get(value, "Tagged", false))) || this.canManageSubgroups);
    }

    animalFormatter = (props) => {
        if (this.isAllowedToDisplayNumber(props?.object)) {
            return <AnimalReferenceDocumentModalGrid {...props} />;
        }
        return "";
    };
                    

    rfidFormatter = ({value,object}) => {
        if (this.isAllowedToDisplayNumber(object)) {
            return value
        }
        return "";
    };

    hasManuallyChangedCnt = () => {
        const { animals } = this.props;
        return animals.some(({ ManuallyChangedCnt }) => ManuallyChangedCnt);
    };

    render() {
        const { t } = this.props;
        const {
            fallCounter,
            soldCounter,
            insertedCounter,
            animalsSize,
            hasIndividualAnimal,
            insertionCounter,
            toGroupTransferCounter,
            fromGroupTransferCounter,
            removedCounter
        } = this.getSummary();
        const animals = this.getAnimals();
        const headers = [];
        if (hasIndividualAnimal || this.canManageSubgroups) {
            headers.push(
                ...[
                    {
                        name: t("animalNumber"),
                        field: "AnmNo1",
                        filterType: FilterTypes.STRING,
                        component: this.animalFormatter,
                        customSort: sortAsString,
                        _mobileHeader: true,
                    },
                    {
                        name: t("rfid"),
                        field: "RFID",
                        filterType: FilterTypes.STRING,
                        component: this.rfidFormatter,
                    },
                ]
            );
        }
        headers.push(
            ...[
                {
                    name: t("location"),
                    field: "PlcmntID",
                    valueFormatter: (value) => formatLocationName(value, { nameDeep: 3 }),
                    filterType: FilterTypes.STRING,
                    
                },
                {
                    name: t("animalsCount"),
                    field: "AnmCnt",
                    valueFormatter: this.pcsFormatter,
                },
                {
                    name: t("animalType"),
                    field: "AnimalKind",
                    valueFormatter: this.animalKindValueFormatter,
                }
            ]
        );

        return (
            <>
                <CollapsableContainer.Card id="cy-inventory-card" header={t("inventory")}
                    defaultExpanded={animals.length > 0}>
                    {
                        this.hasManuallyChangedCnt() &&
                        (
                            <InfoBox outlined boxColor={"warning"}>
                                {t("animalDocuments.anmCntDiffThanInsertion")}
                            </InfoBox>
                        )
                    }
                    <fieldset className="fieldset mb-2">
                        <legend>{t("animalDocuments.anmCntSummary")}</legend>
                        <Row className="d-flex justify-content-center mb-4 mt-2">
                            <Col xs={12} className="text-center">
                                <h1 cy-data="animalsCounter" className="justify-content-center">
                                    {animalsSize}
                                </h1>
                                <span className="text-capitalize">
                                    {t("animalDocuments.animalsNumber")}
                                </span>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center mb-4 mt-2">
                            <Col xs={6} className="text-center">
                                <h2 cy-data="addedCounter" className="justify-content-center color-success">
                                    {insertedCounter}
                                </h2>
                                <span className="text-capitalize">
                                    {t("animalDocuments.addedNumber")}
                                </span>
                            </Col>
                            <Col xs={6} className="text-center">
                                <h2 cy-data="subtractedCounter" className="justify-content-center color-error">
                                    {fallCounter + soldCounter + fromGroupTransferCounter + removedCounter}
                                </h2>
                                <span className="text-capitalize">
                                    {t("animalDocuments.subtractedNumber")}
                                </span>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-center mb-4 mt-2">
                            <Col xs={6} className="text-center">
                                <SummaryNumberSection
                                    cyData="insertedCounter"
                                    amount={insertionCounter}
                                    text={t("animalDocuments.insertedNumber")}
                                />
                                <SummaryNumberSection
                                    cyData="transferToGroupCounter"
                                    amount={toGroupTransferCounter}
                                    text={t("animalDocuments.transferToGroupNumber")}
                                />
                            </Col>
                            <Col xs={6} md={3} className="text-center">
                                <SummaryNumberSection
                                    cyData="fallCounter"
                                    amount={fallCounter}
                                    text={t("animalDocuments.fallNumber")}
                                />
                                <SummaryNumberSection
                                    cyData="transferFromGroupCounter"
                                    amount={fromGroupTransferCounter}
                                    text={t("animalDocuments.transferFromGroupNumber")}
                                />
                            </Col>
                            <Col xs={6} md={3} className="text-center">
                                <SummaryNumberSection
                                    cyData="soldCounter"
                                    amount={soldCounter}
                                    text={t("animalDocuments.soldNumber")}
                                />
                                <SummaryNumberSection
                                    cyData="removedCounter"
                                    amount={removedCounter}
                                    text={t("animalDocuments.removedCounter")}
                                />
                            </Col>
                        </Row>
                    </fieldset>
                    <TableGrid
                        mobileRow={<DefaultMobileRow />}
                        data={animals}
                        headers={headers}
                        paginationItems={10}
                        showPagination
                        showFilter
                    />
                </CollapsableContainer.Card>
            </>
        );
    }
}

export default compose(
    withTranslation(),
    connect(makeMapStateToProps, mapDispatchToProps)
)(GroupAnimalList);
