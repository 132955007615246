import React, {Component} from 'react';
import "./_loading-language-swapper.scss";
import {shuffle} from "lodash";

class LoadingLanguageSwapper extends Component {
    render() {
        const text = shuffle(["Loading translations", "Ładowanie tłumaczeń", "Cargando traducciones", "загрузка переводов", "加载翻译"]);
        return (
            <div className="loading-language-swapper">
                {
                    text.map((text, index) => <div key={index}>{text}</div>)
                }
                {/*Wypełnienie, zeby uzyskać efekt*/}
                <span className="opacity-0">ignore</span>
            </div>
        );
    }
}

export default LoadingLanguageSwapper;
