import {useSelector} from "react-redux";
import {getActiveAnimals} from "../../../selectors/animalSelector";
import React, {useCallback, useMemo, useState} from "react";
import {isVirtualPlacement, normalizePlacement} from "../../../utils/LocationUtils";
import Button from "../../basics/button/Button";
import {Modal} from "react-bootstrap";
import ModalHeader from "../../modals-new/ModalHeader";
import ModalBody from "../../modals-new/ModalBody";
import ModalFooter from "../../modals-new/ModalFooter";
import {useTranslation} from "react-i18next";
import {handleAnimalInventoryRowClick} from "../../../utils/URLUtils";
import {getLocationFormatter} from "../../../selectors/inventorySelector";
import {isFinite} from "lodash";

const RowItem = ({name, location, id, animalKind, toggleShow}) => {
    const gotoDocument = useCallback(() => {
        toggleShow();
        handleAnimalInventoryRowClick({id});
    }, [id, toggleShow]);

    return (
        <tr>
            <td onClick={gotoDocument} className={"pointer text-nowrap"}>
                <i className={"fas fa-arrow-up-right-from-square me-1"}/>
                <strong>{name}</strong></td>
            <td>{animalKind}</td>
            <td>{location}</td>
        </tr>
    )
}

const AnimalsWithNoLocation = () => {

    const [show, setShow] = useState(false);

    const {t} = useTranslation();

    const animals = useSelector(getActiveAnimals);

    const locationFormatter = useSelector(getLocationFormatter);

    const animalKindFormatter = useCallback((value) => {
        if (isFinite(value)) return t(`animalKind.${value}`);
        return "";
    }, [t])

    const animalsWithNoLocation = useMemo(() => {
        return animals
            .filter(({PlcmntID}) => isVirtualPlacement(PlcmntID))
            .map((a) => ({
                name: a.AnmNo1,
                location: locationFormatter(normalizePlacement(a.PlcmntID)),
                id: a.AnmID,
                animalKind: animalKindFormatter(a.AnimalKind)
            }))
    }, [animals, locationFormatter, animalKindFormatter]);

    const toggleShow = useCallback(() => setShow((s) => !s), []);

    if (animalsWithNoLocation.length === 0) return null;

    return (
        <>
            <Button onClick={toggleShow} className="flex-shrink-0" icon={<i className={"fas fa-exclamation"}/>}
                    buttonColor={"warning"} type={"button"} buttonStyle={"round"}/>
            {
                show &&
                <Modal show={show} size={"lg"} onHide={toggleShow}>
                    <ModalHeader title={t("autoTransferredAnimals")} onCloseClick={toggleShow}/>
                    <ModalBody>
                        <div className={"table-responsive w-100"}>
                            <table className={"w-100"}>
                                <thead>
                                <tr>
                                    <th>
                                        {t("number")}
                                    </th>
                                    <th>

                                    </th>
                                    <th>
                                        {t("lastKnownLocation")}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    animalsWithNoLocation.map((o) => (
                                        <RowItem {...o} toggleShow={toggleShow} key={o.id}/>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter onCloseClick={toggleShow}/>
                </Modal>
            }
        </>

    )
}

export default AnimalsWithNoLocation;