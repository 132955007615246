import React, {Component} from 'react';
import {Document, pdfjs, Page} from 'react-pdf';
import {Fade} from "react-bootstrap";

class FilePdfShow extends Component {

    state = {
        show: false,
        maxPages: 0
    }

    constructor(props) {
        super(props);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.url !== prevProps.url) {
            this.setState({
                show: false
            })
        }
    }

    onLoadSuccess = pdf => {
        this.props.onLoadFile();
        this.setState({
            show: true,
            maxPages: pdf.numPages
        })
    }

    onLoadError = error => {
        console.error(error);
        this.props.onLoadFile();
        this.setState({
            show: true
        })
    }

    renderPages() {
        const {maxPages} = this.state;
        const {zoom} = this.props;
        let array = [];
        for (let i = 0; i < maxPages; i++) {
            array.push(
                <Page scale={zoom} pageNumber={i + 1} key={i}/>
            )
        }
        return array;
    }

    render() {
        const {url} = this.props;
        const {show} = this.state;
        if (!url) return null;
        return (
            <Fade in={show}>
                <Document file={url} loading={null} onLoadSuccess={this.onLoadSuccess} onLoadError={this.onLoadError}
                          options={{
                              cMapUrl: 'cmaps/',
                              cMapPacked: true,
                          }}>
                    {this.renderPages()}
                </Document>
            </Fade>
        );
    }
}

export default FilePdfShow;
