import {useEffect} from "react";
import {useIsTabVisible} from "./useIsTabVisible";

export function useInterval(callback, time = 100) {

    const isTabVisible = useIsTabVisible();

    useEffect(() => {
        if (isTabVisible && time) {
            let interval = setInterval(callback, time);
            return () => {
                clearInterval(interval);
            }
        }
    }, [isTabVisible, time, callback])

    return null;
}