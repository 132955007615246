import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal} from 'react-bootstrap'
import {connectModal} from 'redux-modal'
import ModalFooter from "../ModalFooter";
import ModalBody from "../ModalBody";
import {compose} from "redux";
import {connect} from "react-redux";
import {checkMaintenanceStatus} from "../../../actions/serviceActions";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import ModalHeader from "../ModalHeader";

export const ModalName = 'delayed-action-modal';

function mapStateToProps(state) {
    return {}
}

/**
 * Modal wylogowujący po {counter} sekundach
 * @param props
 * @constructor
 */

class DelayedActionModal extends Component {

    static propTypes = {
        handleHide: PropTypes.func,
        counter: PropTypes.number.isRequired,
        action: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        confirmButtonText: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            counter: props.counter
        }
    }

    onButtonClick = () => {
        const {handleHide, dispatch, action} = this.props;
        handleHide();
        setTimeout(() => {
            dispatch(checkMaintenanceStatus(action));
        }, 100);
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.state.counter < 1) {
                this.onButtonClick()
            } else {
                this.setState({counter: this.state.counter - 1})
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {show, counter, title, text, confirmButtonText, t} = this.props;
        const {counter: stateCounter} = this.state;
        return (
            <Modal onHide={() => {}} show={show} counter={counter}>
                <ModalHeader title={t(title)}/>
                <ModalBody className={ModalName}>
                    <p>{t(text, {counter: stateCounter})}</p>
                </ModalBody>
                <ModalFooter hasConfirmButton={true} hasCloseButton={false} confirmText={t(confirmButtonText)}
                             onConfirmed={this.onButtonClick}/>
            </Modal>
        );
    }
}

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect(mapStateToProps),
    connectModal({name: ModalName})
)(DelayedActionModal);
