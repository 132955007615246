export const FeedingType = {
    INDIVIDUAL: 0,
    GROUP: 1,
    MIXED: 3
};

/**
 * @deprecated use CurveType
 * @type {{BEFORE_PARTURITION: string, MIXED_PARTURITION: string, AFTER_PARTURITION: string}}
 */
export const FeedingCurveType = {
    BEFORE_PARTURITION: "4",
    AFTER_PARTURITION: "5",
    MIXED_PARTURITION: "6"
};

/**
 * tlumaczenia sa w ../constants.js
 */
export const CurveType = {
    INDIVIDUAL: "IND",
    MATING: "MAT",
    PARTURITION: "PAR"
};

export const CurveTypesWithParturition = [CurveType.PARTURITION];
export const CurveTypesWithInsemination = [CurveType.MATING];

export const NRFWorkType = {
    SCHEDULE: "H",
    TRIGGER: "Z",
    RFID: "R",
    SENSOR: "C"
};

export const CurveDayShowingType = {
    BEFORE: -1,
    BOTH: 0,
    AFTER: 1,
    NORMAL: 2
};

// this will work for both WST and NRF
export const FeedingDoseCorrection = {
    MIN: -35,
    MAX: 40,
    STEP: 5
}