import React, { useMemo } from 'react';
import useAnimalSingleInfo from '../ideal-charts/useAnimalSingleInfo';
import useSingleAnimalDomain from '../ideal-charts/useSingleAnimalDomain';
import WeightingChart from './WeightingChart';
import {makeGetWeightingEvents} from "../../../selectors/animalDocumentsSelectors";
import { useSelector } from "react-redux";
import { localMomentToUTC } from "../../../utils/DateTimeUtils";

export default function WeightingSingleChart() {

    const animalInfo = useAnimalSingleInfo();

    const domain = useSingleAnimalDomain();

    const getWeightingEvents = useMemo(() => makeGetWeightingEvents(), []);

    const weightingEvents = useSelector(getWeightingEvents);

    const weightDict = useMemo(() => {
        const dictionary = {};
        for (const event of weightingEvents) {
            const utcTIME = +localMomentToUTC(event?.EvTime).startOf("day");
            if (!dictionary[utcTIME]) dictionary[utcTIME] = [];
            dictionary[utcTIME].push({
                [event.EvCode]: event?.EvData?.Weight || 0,
                weight: event?.EvData?.Weight || 0,
                Files: event?.EvData?.Files || [],
                AnmCnt: event?.EvData?.AnmCnt || event?.AnmCnt || 1,
                AnmID: event?.AnmID,
                PlcmntID: event?.EvData?.PlcmntID
            });
        }
        return dictionary;
    }, [weightingEvents]);

    return (
        <WeightingChart weightDict={weightDict} chartDomain={domain} {...animalInfo} />
    )
}
