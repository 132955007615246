export const Defaults = {
    NOT_FOUND_TEXT: "-",
    LOADING_TEXT: "..."
}

export const createKeysAndLabels = (...keyLabelDict) => {
    const k = [], l = [];
    const add = (key, label) => {
        k.push(key);
        l.push(label);
    }
    for (let {key, label} of keyLabelDict) {
        add(key, label);
    }
    return {
        keys: k,
        labels: l
    }
}