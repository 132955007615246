import React, {useCallback, useDeferredValue, useMemo, useRef} from 'react';
import {groupBy, isFunction} from "lodash";
import SmallElementRenderer from "./SmallElementRenderer";
import ElementRenderer from "./ElementRenderer";
import FarmDataGetter from "./FarmDataGetter";
import Flatbush from "flatbush";

function ElementList(props) {

    const {
        onDoubleClick,
        onClick,
        onBeforeRender,
        rotate,
        mobile,
        enableDataFetcher,
        items,
        omitNames
    } = props;

    const deferredViewBox = useDeferredValue(props.viewBox);

    const showSmalls = useDeferredValue(props.showSmalls);

    const viewBox = useMemo(() => {
        const ceil = (value, roundTo, extra = 0) => {
            return Math.ceil(value / roundTo) * roundTo;
        }
        const floor = (value, roundTo, extra = 0) => {
            return Math.floor(value / roundTo) * roundTo;
        }
        // const width = ceil(deferredViewBox.maxX - deferredViewBox.minX, 10);
        // const height = ceil(deferredViewBox.maxY - deferredViewBox.minY, 10);
        return {
            minX: floor(deferredViewBox.minX, 100),
            maxX: ceil(deferredViewBox.maxX, 100),
            minY: floor(deferredViewBox.minY, 100),
            maxY: ceil(deferredViewBox.maxY, 100),
        }
    }, [deferredViewBox]);


    const data = useMemo(() => {
        return items.filter((item) => {
            if (omitNames.includes(item.type)) return false;
            return showSmalls ? true : !["groups", "animals", "standings"].includes(item.type)
        })
    }, [items, showSmalls, omitNames]);

    const lastClick = useRef({time: Date.now(), id: null});

    const lastMouseDown = useRef({time: Date.now(), id: null});

    const getElementByID = useCallback((elementId) => {
        const element = data.find(({id}) => elementId === id);
        // sprawdzamy czy dany element nie "przekierowuje" do innego elementu
        if (element?.overrideId) {
            return data.find(({id}) => element.overrideId === id);
        }
        return element;
    }, [data]);

    const handleMouseDown = useCallback((e) => {
        lastMouseDown.current.time = Date.now();
        lastMouseDown.current.elementId = e?.target?.id ?? null;
    }, []);

    const handleClick = useCallback((e) => {
        e.preventDefault();
        const now = Date.now();
        const elapsedTime = now - lastMouseDown.current.time;
        if (elapsedTime >= 300) return;
        console.log(elapsedTime);
        const elementId = e.target.id;
        if (elementId && !e.target.hasAttribute("disabled")) {
            const elapsedTime = now - lastClick.current.time;
            let handled = false;
            if (isFunction(onDoubleClick)) {
                if (lastClick.current.id === elementId && ((elapsedTime < 250))) {
                    onDoubleClick(getElementByID(elementId));
                    handled = true;
                }
            }
            if (!handled && isFunction(onClick)) {
                if (elementId) {
                    onClick(getElementByID(elementId));
                }
            }
            lastClick.current.time = now;
            lastClick.current.id = elementId;
        }
    }, [onDoubleClick, onClick, getElementByID]);

    const isBackground = useCallback((item) => ["buildings", "sectors", "chambers"].includes(item.type), []);
    const isForeground = useCallback((item) => item.type === "devices", []);

    const createDataIndex = useCallback((data) => {
        const index = data.length ? new Flatbush(data.length) : {
            add: () => {
            }, finish: () => {
            }, search: () => []
        };
        for (const p of data) {
            const v = p._view;
            index.add(v.minX, v.minY, v.maxX, v.maxY);
        }
        index.finish();
        return index;
    }, []);

    const dataGrouped = useMemo(() => {
        const grouped = groupBy(data, (item) => {
            if (isBackground(item)) return "BG";
            if (isForeground(item)) return "FG";
            return "MID"
        });
        grouped.BG ??= [];
        grouped.FG ??= [];
        grouped.MID ??= [];
        grouped.BG._index = createDataIndex(grouped.BG);
        grouped.FG._index = createDataIndex(grouped.FG);
        grouped.MID._index = createDataIndex(grouped.MID);
        return grouped;
    }, [data, createDataIndex, isBackground, isForeground]);

    const {minX, minY, maxX, maxY} = viewBox;

    const elements = useMemo(() => {
        const search = (key) => dataGrouped[key]._index.search(minX, minY, maxX, maxY).map((i) => dataGrouped[key][i]);
        const renderAsCustom = search("MID");
        const renderAsForeground = dataGrouped.FG;
        const renderAsBackground = dataGrouped.BG;
        const elementsInViewBox = [...search("FG"), ...search("BG")];
        return {
            renderAsBackground,
            renderAsCustom,
            renderAsForeground,
            elementsInViewBox
        }
    }, [dataGrouped, minX, minY, maxX, maxY]);

    const {renderAsBackground, renderAsCustom, renderAsForeground, elementsInViewBox} = useDeferredValue(elements);
    return (
        <>
            {
                (enableDataFetcher) &&
                <FarmDataGetter elementsInViewBox={elementsInViewBox}/>
            }
            <g onMouseDown={handleMouseDown} onClick={handleClick}>
                <ElementRenderer data={renderAsBackground}
                                 mobile={mobile}
                                 rotate={rotate} onBeforeRender={onBeforeRender}
                                 showSmalls={showSmalls}/>
                {
                    (!!renderAsCustom.length) &&
                    <SmallElementRenderer data={renderAsCustom}
                                          mobile={mobile}
                                          rotate={rotate} onBeforeRender={onBeforeRender}
                                          showSmalls={showSmalls}/>

                }
                <ElementRenderer data={renderAsForeground}
                                 mobile={mobile}
                                 rotate={rotate} onBeforeRender={onBeforeRender}
                                 showSmalls={showSmalls}/>
            </g>
        </>

    );
}

export default React.memo(ElementList);