import parametersDB from "../database/parametersDB";
import {getBuildingsMap} from "../selectors/buildingsSelector";
import store from "../store/store";
import {checkIfUserIsService} from "../utils/NewRolesUtils";
import {getUtilResults} from "../utils/results/GeneralResultsUtils";
import {getFarmZoneType} from "../utils/TimeUtils";
import {getUser, getUserSub} from "../selectors/userSelector";
import {isMobile} from "../utils/MobileUtils";
import {ReactLocalStorage} from "../utils/ReactLocalStorage";

// let animalParametersWorkerInstance = null;

let parametersTimeout;
let parametersTimeoutSync;
let stackedMessage = null;

export function createWorkerForAnimalParameters(
    {fullCalc, animalChanges, reloadDatabase},
    time = 1000 * 10
) {
    const state = store.getState();
    const args = {
        FarmID: state.location.farm,
        utilResults: getUtilResults(),
        buildingsMap: Array.from(getBuildingsMap(state, {showDeleted: true})),
        type: "CALCULATE_PARAMETERS",
        fullCalc,
        animalChanges,
        reloadDatabase,
        fullParametersCalc: ReactLocalStorage.get("fullParametersCalc", false),
    };
    if (reloadDatabase) {
        args.sub = getUserSub(state);
        args.user = getUser(state);
        args.farmTimeZone = getFarmZoneType(state.location.farm);
        args.isService = checkIfUserIsService();
    }
    console.log(args);
    console.trace();
    return new Promise((resolve, reject) => {
        clearTimeout(parametersTimeout);
        parametersTimeout = setTimeout(() => {
            parametersDB.sendMessageToWorker(
                "CALCULATE_PARAMETERS",
                args,
                (data) => {
                    if (data.error) reject(new Error(data.error));
                    else resolve();
                }
            );
        }, time);
    });
}

export function prepareAnimalParameters(
    {
        fullCalc = false,
        animalChanges,
        reloadDatabase = false,
        disableLoading = false,
    },
    time = 1000 * 10
) {
    return (dispatch) => {
        dispatch({
            type: "CALCULATE_ANIMAL_PARAMETERS",
            payload: createWorkerForAnimalParameters(
                {fullCalc, animalChanges, reloadDatabase},
                time
            ),
            meta: {disableLoading},
        });
    };
}

export function prepareAnimalParametersForceUpdate({
    animalChanges,
    fullCalc = false,
}) {
    return function (dispatch) {
        if (isMobile() && !ReactLocalStorage.get("fullParametersCalc", false))
            return; // wylaczone na mobilce
        console.log(
            "[prepareAnimalParametersForceUpdate] Function executed",
            animalChanges,
            fullCalc,
            stackedMessage
        );
        if (!stackedMessage) {
            stackedMessage = {animalChanges, fullCalc, startTime: +new Date()};
            parametersTimeoutSync = setTimeout(() => {
                console.log(
                    "[prepareAnimalParametersForceUpdate] Dispatching action 1",
                    stackedMessage
                );
                dispatch(
                    prepareAnimalParameters(
                        {
                            animalChanges: stackedMessage.animalChanges,
                            fullCalc: stackedMessage.fullCalc,
                            reloadDatabase: true,
                        },
                        0
                    )
                );
                stackedMessage = null;
            }, 15 * 1000);
        } else {
            stackedMessage.animalChanges = [
                ...stackedMessage.animalChanges,
                ...animalChanges,
            ];
            if (!stackedMessage.fullCalc) stackedMessage.fullCalc = fullCalc; // jezeli juz bylo fullCalc to nie zmieniaj
            if (stackedMessage.startTime + 60 * 1000 > +new Date()) {
                console.log("Clearing timeout");
                // przez 60 sekund stackuj wiadomosci
                clearTimeout(parametersTimeoutSync);
                parametersTimeoutSync = setTimeout(() => {
                    console.log(
                        "[prepareAnimalParametersForceUpdate] Dispatching action 2",
                        stackedMessage
                    );
                    dispatch(
                        prepareAnimalParameters(
                            {
                                animalChanges: stackedMessage.animalChanges,
                                fullCalc: stackedMessage.fullCalc,
                                reloadDatabase: true,
                            },
                            0
                        )
                    );
                    stackedMessage = null;
                }, 15 * 1000);
            }
        }
    };
}

export function setMapSQL(sql, data, ...params) {
    return function (dispatch) {
        dispatch({
            type: "SET_MAP_SQL",
            payload: {sql, data, params},
        });
    };
}

export function clearParameters() {
    console.log("Clearing", parametersTimeout, parametersTimeoutSync);
    clearTimeout(parametersTimeoutSync);
    clearTimeout(parametersTimeout);
    parametersDB.sendMessageToWorker("CLEAR_DATA", null, (data) => {
        console.log("CLEAR_DATA", data);
    });
    stackedMessage = null;
}

export function skipLoading() {
    return function (dispatch) {
        dispatch({type: "SKIP_LOADING"});
    };
}
