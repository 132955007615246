import {createSelector} from "reselect";
import utilSelectors from "./utilSelectors";
import eventsDB from "../database/eventsDB";

export const getEventsForAnimals = state => state.events.eventsForAnimals;

export const getEventChooserFilter = state => state.events.eventChooserFilter;

export const getEventsForAnimalsLoading = state => state.events.eventsForAnimalsLoading;

export const getEventsTime = state => state.events.time;

export const getAllEventsForFarm = createSelector([utilSelectors.getFarmID, getEventsTime], (farmID, eventsTime) => {
    return eventsDB.getAllEventsForFarm(farmID);
});

export const getAnimalEventsMap = createSelector([getAllEventsForFarm], (events) => {
    const eventsMap = {};
    for (const event of events) {
        if (!eventsMap[event.AnmID]) eventsMap[event.AnmID] = [];
        eventsMap[event.AnmID].push(event);
    }
    return eventsMap;
});