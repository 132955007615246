import { maxBy, minBy } from "lodash";
import { resultSelects, resultsSummary } from "../constans/resultSQLSelects";
import { convertWeightUnitTo } from "./UnitUtils";
import { createKeyToValueDictionary } from "./Utils";
import { ResultSummaryTypes } from "../constans/resultSummaryType";

export const RESULT_TARGET_REGEX = new RegExp("{{\\w*}}", "gm");

function createTargetRangeForFormula(sqlKey, targets, targetDictionary) {
    const array = [];
    for (let i = 0; i < 2; i++) { // petla po ilosci elementow, aktualnie min - max
        let tmp = targetDictionary[sqlKey].formula;
        const res = tmp.match(RESULT_TARGET_REGEX);
        for (let item of res) {
            const name = item.slice(2, -2);
            const target = targets?.[name] ? targets[name].target[i] : targetDictionary[name]?.default[i];
            tmp = tmp.replace(item, target);
        }
        tmp = tmp.replace(/[^-()\d/*+.]/g, '');
        // eslint-disable-next-line no-new-func
        const value = new Function(`return ${tmp}`)();
        switch (targetDictionary[sqlKey].round) {
            case "round":
                array.push(Math.round(value));
                break;
            case "floor":
                array.push(Math.floor(value));
                break;
            case "ceil":
                array.push(Math.ceil(value));
                break;
            default: array.push(value);
        }
    }
    return array;
}

export function getTargetRange(sqlKey, targets, targetDictionary) {
    if (targetDictionary[sqlKey].component) return null;
    if (targetDictionary[sqlKey].formula) return createTargetRangeForFormula(sqlKey, targets, targetDictionary);
    if (targets?.[sqlKey]?.target) return targets[sqlKey].target;
    if (targetDictionary[sqlKey].hasOwnProperty("default")) return targetDictionary[sqlKey].default;
    return [];
}

function getValueTarget(customTarget, rowValue) {
    if (!rowValue) return 0;
    let tmp = customTarget;
    const res = tmp.match(RESULT_TARGET_REGEX);
    for (let item of res) {
        const name = item.slice(2, -2);
        const target = rowValue[name] || 0;
        tmp = tmp.replace(item, target);
    }
    tmp = tmp.replace(/[^-()\d/*+.]/g, '');
    // eslint-disable-next-line no-new-func
    return new Function(`return ${tmp}`)();
}

export function isInTargetRange(rowValue, valueKey, target, customTarget) {
    if (typeof target === "function") return target(rowValue);
    const value = (customTarget ? getValueTarget(customTarget, rowValue) : rowValue?.[valueKey]) || 0;
    return value >= target[0] && value <= target[1];
}

export function formatResultValue(value, format, animalUnit) {
    switch (format) {
        case "float": return +value.toFixed(2);
        case "weight": return convertWeightUnitTo(value, {
            unit: animalUnit,
            showUnit: false,
            fixed: 2,
            rawValue: true
        });
        default: return value;
    }
}

export function buildQuery(strings, query, ...args) {
    let tmp = query;
    const ending = strings.slice(1).reduce((prev, curr, index) => {
        if (args[index] === undefined) return prev + curr;
        return prev + curr + args[index];
    }, "");
    if (!tmp.includes("{{END_QUERY}}")) {
        if (!tmp.includes("WHERE")) {
            tmp += " WHERE";
        } else {
            tmp += " AND";
        }
    } else {
        if (tmp.endsWith("{{END_QUERY}}")) {
            tmp = tmp.replace("{{END_QUERY}}", "WHERE");
        } else {
            const index = tmp.indexOf("{{END_QUERY}}");
            const keyword = tmp.slice(0, index).includes("WHERE") ? "AND" : "WHERE";
            tmp = tmp.replace("{{END_QUERY}}", keyword + " " + ending);
            return `${strings[0]}${tmp}`;
        }
    }
    return `${strings[0]}${tmp}${ending}`;
}

function createDefaultSummaryQuery(item, valueKeys, resultsType, year, week) {
    let baseQuery = resultSelects[item.sqlKey];
    const aggregateFunction = item.summaryType === ResultSummaryTypes.SUM ? "SUM" : "AVG";

    if (item.doNotGroupSummary) {
        baseQuery = baseQuery.replace("SELECT", "SELECT VALUE").replace("group_nr, ", "").replace("week, ", "").replace("month, ", "").replace("year,", "").replace("day,", "");
        if (resultsType === "weeks") {
            const min = minBy(valueKeys, "key");
            const max = maxBy(valueKeys, "key");
            return buildQuery`${baseQuery} week >= ${min.key} AND week <= ${max.key} AND year = ${year}`;
        }
        if (resultsType === "months") {
            const min = minBy(valueKeys, "key");
            const max = maxBy(valueKeys, "key");
            return buildQuery`${baseQuery} month >= ${min.key} AND month <= ${max.key} AND year = ${year}`;
        }
        if (resultsType === "year") {
            const min = minBy(valueKeys, "key");
            const max = maxBy(valueKeys, "key");
            return buildQuery`${baseQuery} year >= ${min.key} AND year <= ${max.key}`;
        }
        if (resultsType === "weekDay") {
            return buildQuery`${baseQuery} week = ${week} AND year = ${year}`;
        }
        return buildQuery`${baseQuery} group_nr IN (${valueKeys.map(row => `'${row.key}'`).join(", ")})`;
    }

    if (resultsType === "weeks") {
        const min = minBy(valueKeys, "key");
        const max = maxBy(valueKeys, "key");
        return `SELECT VALUE ${aggregateFunction}(${item.valueKey}) FROM (${buildQuery`${baseQuery} week >= ${min.key} AND week <= ${max.key} AND year = ${year} GROUP BY week`})`;
    }
    if (resultsType === "months") {
        const min = minBy(valueKeys, "key");
        const max = maxBy(valueKeys, "key");
        return `SELECT VALUE ${aggregateFunction}(${item.valueKey}) FROM (${buildQuery`${baseQuery} month >= ${min.key} AND month <= ${max.key} AND year = ${year} GROUP BY month`})`;
    }
    if (resultsType === "year") {
        const min = minBy(valueKeys, "key");
        const max = maxBy(valueKeys, "key");
        return `SELECT VALUE ${aggregateFunction}(${item.valueKey}) FROM (${buildQuery`${baseQuery} year >= ${min.key} AND year <= ${max.key} GROUP BY year`})`;
    }
    if (resultsType === "weekDay") {
        return `SELECT VALUE ${aggregateFunction}(${item.valueKey}) FROM (${buildQuery`${baseQuery} week = ${week} AND year = ${year} GROUP BY day`})`;
    }
    return `SELECT VALUE ${aggregateFunction}(${item.valueKey}) FROM (${buildQuery`${baseQuery} group_nr IN (${valueKeys.map(row => `'${row.key}'`).join(", ")}) GROUP BY group_nr`})`;
}

export function buildSummaryQuery(item, valueKeys, resultsType, year, week) {
    if (item.summaryType === ResultSummaryTypes.CUSTOM) {
        if (resultsType === "weeks") {
            const min = minBy(valueKeys, "key");
            const max = maxBy(valueKeys, "key");
            return buildQuery`${resultsSummary[item.sqlKey]} week >= ${min.key} AND week <= ${max.key} AND year = ${year} GROUP BY week`;
        }
        if (resultsType === "months") {
            const min = minBy(valueKeys, "key");
            const max = maxBy(valueKeys, "key");
            return buildQuery`${resultsSummary[item.sqlKey]} month >= ${min.key} AND month <= ${max.key} AND year = ${year} GROUP BY month`;
        }
        if (resultsType === "year") {
            const min = minBy(valueKeys, "key");
            const max = maxBy(valueKeys, "key");
            return buildQuery`${resultsSummary[item.sqlKey]} year >= ${min.key} AND year <= ${max.key} GROUP BY year`;
        }
        if (resultsType === "weekDay") {
            return buildQuery`${resultsSummary[item.sqlKey]} week = ${week} AND year = ${year} GROUP BY day`;
        }
        return buildQuery`${resultsSummary[item.sqlKey]} group_nr IN (${valueKeys.map(row => `'${row.key}'`).join(", ")}) GROUP BY group_nr`;
    }
    return createDefaultSummaryQuery(item, valueKeys, resultsType, year, week);
}

export function getRowQuery(resultsType, sqlKey, valueKeys, year, week) {
    if (resultsType === "weeks") {
        const min = minBy(valueKeys, "key");
        const max = maxBy(valueKeys, "key");
        return buildQuery`${resultSelects[sqlKey]} week >= ${min.key} AND week <= ${max.key} AND year = ${year} GROUP BY week`;
    }
    if (resultsType === "months") {
        const min = minBy(valueKeys, "key");
        const max = maxBy(valueKeys, "key");
        return buildQuery`${resultSelects[sqlKey]} month >= ${min.key} AND month <= ${max.key} AND year = ${year} GROUP BY month`;
    }
    if (resultsType === "year") {
        const min = minBy(valueKeys, "key");
        const max = maxBy(valueKeys, "key");
        return buildQuery`${resultSelects[sqlKey]} year >= ${min.key} AND year <= ${max.key} GROUP BY year`;
    }
    if (resultsType === "weekDay") {
        return buildQuery`${resultSelects[sqlKey]} week = ${week} AND year = ${year} GROUP BY day`;
    }
    return buildQuery`${resultSelects[sqlKey]} group_nr IN (${valueKeys.map(row => `'${row.key}'`).join(", ")}) GROUP BY group_nr`;
}

export function getQueryDictionary(resultsType, res) {
    if (resultsType === "weeks") return createKeyToValueDictionary(res, "week");
    if (resultsType === "months") return createKeyToValueDictionary(res, "month");
    if (resultsType === "year") return createKeyToValueDictionary(res, "year");
    if (resultsType === "weekDay") return createKeyToValueDictionary(res, "day");
    return createKeyToValueDictionary(res, "group_nr");
}