import React from 'react';
import logo from "../../resources/images/logos/fetura_cloud_colour.svg";
import "./_maintenance-view.scss"
import {useTranslation} from "react-i18next";
import TimeCounter from "../../components/basics/time-counter/TimeCounter";
import {useSelector} from "react-redux";

export default function MaintenanceView() {

    const {t} = useTranslation();

    const maintenance = useSelector(state => state.maintenance.currentMaintenance);

    return (
        <div className="maintenance-view">
            <img src={logo} className={"fetura-logo"} alt="fetura-logo"/>
            <h2 className="strong opacity-75">
                {t(["maintenanceView.backSoon", "We'll be back soon!"]).toUpperCase()}
            </h2>
            <h3 className="text-center strong opacity-75">
                {t(["maintenanceView.inconveniance", "Sorry for the inconveniance but we're performing some mainenance at the moment."])}<br/>
                {t(["maintenanceView.timeToEnd", "The time until the end of the break is"])}:
            </h3>
            <TimeCounter date={maintenance?.DtaEndTime}/>
        </div>
    );
};
