import { getModificationTime, insertInto, setModificationTime } from "../utils/LokiUtils";
import lokiDB from "./lokiDB";

class EconomyDB {

    getModificationTime() {
        return getModificationTime("economy");
    }

    insertEconomy(values) {
        insertInto(values, lokiDB.economy, "EID");
        setModificationTime("economy", values[0].DtaModTime);
    }

    getEconomy(FarmID) {
        return lokiDB.economy.find({
            FarmID,
            DtaDelTime: { $type: 'undefined' }
        });
    }
}

const economyDB = new EconomyDB();
export default economyDB;
