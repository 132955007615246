import React from "react";
import PropTypes from "prop-types";
import "./_helper-note.scss";

export default class HelperNote extends React.Component {

    getIcon = () => {
        const {type} = this.props;
        switch (type) {
            case "error":
                return <i className="fas fa-times fa-fw"/>;
            case "success":
                return <i className="fas fa-check fa-fw"/>;
            case "info":
                return <i className="fas fa-info fa-fw"/>;
            case "warning":
                return <i className="fas fa-exclamation fa-fw"/>;
            default:
                return null;
        }
    };

    render() {
        const {type, children, title} = this.props;
        const className = ["fetura-helper-note", type].filter(o => !!o).join(" ");
        const statusIcon = this.getIcon();
        return (
            <div className={className}>
                {statusIcon} <strong>{title}</strong>
                <br/>
                {children}
            </div>
        );
    }
}

HelperNote.propTypes = {
    type: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error", ""]),
    title: PropTypes.string
};

HelperNote.defaultProps = {
    title: ""
};
