import moment from "moment";
import {createSelector} from "reselect";
import { getPriceForOnePiece, getSumOfPriceNet } from "../utils/SalesUtils";
import { convertWeightUnitTo } from "../utils/UnitUtils";
import { UnitTypes } from "../constans/unitTypes";

export const getSales = state => state.sales.sales;

export const getSalesGroupedByYear = (state) => {
    const getPrice = (sale) => {
        if (sale.SaleType === 3) {
            if (sale.PriceType === "weight") {
                return sale.PriceNetWeight.toFixed(2);
            } else {
                return sale.PriceNetPiece.toFixed(2);
            }
        }
        if (sale.PriceNet) return sale.PriceNet.toFixed(2);
        return getPriceForOnePiece(sale);
    };
    const getOrderValue = (sale) => {
        if (sale.SaleType === 3) {
            if (sale.PriceType === "weight") {
                return (
                    sale.PriceNetWeight *
                    convertWeightUnitTo(sale.Weight, {
                        unit: UnitTypes.MEDIUM,
                        rawValue: true,
                    })
                ).toFixed(2);
            } else {
                return (sale.PriceNetPiece * sale.Amount).toFixed(2);
            }
        }
        if (sale.PriceNet && sale.Weight)
            return (
                sale.PriceNet *
                convertWeightUnitTo(sale.Weight, {
                    unit: UnitTypes.MEDIUM,
                    rawValue: true,
                })
            ).toFixed(2);
        if (sale.SaleType === 1 && sale.PriceNet) return sale.PriceNet.toFixed(2);
        return getSumOfPriceNet(sale).toFixed(2);
    };
    const obj = {};
    for (const sale of state.sales.sales) {
        let pieces = sale.Amount;
        if (!sale.hasOwnProperty("Amount")) {
            sale["Amount"] = sale.AnmCnt;
            pieces = sale.AnmCnt;
        }
        sale["avgWeight"] = sale.Weight / (pieces || 1);
        sale["priceFor"] = getPrice(sale);
        sale["orderValue"] = getOrderValue(sale);

        const year = moment(sale.SaleDta).year();
        if (!(year in obj)) {
            obj[year] = [sale];
        } else {
            obj[year].push(sale);
        }
    }
    return obj;
};

const getSaleIDForEdit = (state, props) => props.match?.params?.SaleID;

export const getSale = createSelector(getSales, getSaleIDForEdit, (sales, id) => {
    return sales.find(item => item.SaleID === id) || null;
})
