import { getUserApiData } from "../../utils/UserUtils";
import { invokeApig } from "../../libs/awsLib";
import Paths from "../paths";
import { myID } from "../../libs/generateID";
import store from "../../store/store";
import { notify } from "reapop";
import i18n from "../../i18n";
import { createErrorNotification, createSuccessNotification } from "../../utils/ResponseUtils";

export function createSell(data, { FarmID, ClientID, LocalUserID } = {}) {
    const { _FarmID, _LocalUserID, _ClientID } = getUserApiData({ FarmID, ClientID, LocalUserID });
    return invokeApig({
        ...Paths.createSell({ farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID }),
        body: data
    })
}

export function createSellNotificationSuccess(response, { uniqueId = myID() } = {}) {
    if (createSuccessNotification(response, "apiNotifications.createSellTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createSellTitle"),
        message: i18n.t("apiNotifications.createSellSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createSellNotificationFailure(response, { uniqueId = myID() } = {}) {
    if (createErrorNotification(response, "apiNotifications.createSellTitle", { uniqueId })) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createSellTitle"),
        message: i18n.t("apiNotifications.createSellFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
