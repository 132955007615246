import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export function getPreferences({LocalUserID, selectedLocalUserID, forceFetch = false} = {}) {
    const {_LocalUserID} = getUserApiData({LocalUserID});
    return invokeApig({
        ...Paths.getPreferences({localUserID: _LocalUserID}),
        queryParams: {selectedLocalUserID},
        forceFetch
    });
}
