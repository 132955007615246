import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import { listSettlements } from "../api/feeding/Settlement";
import { getSettlements } from "../IOT/device-functions/GatewayFunctions";

export function listSettlementsDynamoDB(FarmID, forceFetch = false, signal) {
    return function (dispatch) {
        dispatch({
            type: "LIST_CHAMBER_SETTLEMENTS",
            payload: listSettlements({ FarmID, forceFetch, signal })
        })
    }
}

export function getOfflineSettlements() {
    return function (dispatch, getState) {
        const state = getState();
        const gateways = state.farmDevices.devices.filter(item => item.DevType === DevTypes.GATEWAY);
        console.log("eqwqeqeqweqeqew", gateways);
        for (let gateway of gateways) {
            console.log("eqwqeqeqweqeqew");
            dispatch({
                type: "LIST_CHAMBER_SETTLEMENTS_OFFLINE",
                payload: getSettlements(gateway),
                meta: { GwID: gateway.DevID }
            })
        }
    }
}