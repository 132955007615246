import { AnimalTypes } from "../constans/animalTypes";
import { getManageSubgroups } from "../utils/SettingsUtils";
import { handleAnimalInventoryRowClick } from "../utils/URLUtils";
import { getEventsForAnimal, getEventsForAnimals } from "./eventsActions";

export function changeSelectedItem(item) {
    return function (dispatch) {
        if (item?.AnmID || item?.AnmGrp) {
            const manageSubGroups = getManageSubgroups();
            if (
                !manageSubGroups &&
                [AnimalTypes.PIGLET, AnimalTypes.PORKER].includes(
                    item.AnimalKind
                ) &&
                !(item.RFID || item.Tagged)
            ) {
                dispatch({
                    type: "ANIMAL_DOCUMENTS_RESET",
                });
                handleAnimalInventoryRowClick(null);
                return;
            }
            dispatch({
                type: "ANIMAL_DOCUMENTS_CHANGE_SELECTED_ITEM",
                payload: {
                    item: item,
                },
            });
            if (item.AnmID) {
                dispatch(getEventsForAnimal(item, null));
            } else if (item.AnmGrp) {
                dispatch(
                    getEventsForAnimals([...item.AnmIDs, ...item.Rmvd], {
                        meta: { AnmGrp: item.AnmGrp },
                    })
                );
            }
        } else {
            dispatch({
                type: "ANIMAL_DOCUMENTS_RESET",
            });
        }
    };
}

export function resetSelectedItem() {
    return function (dispatch) {
        dispatch({
            type: "ANIMAL_DOCUMENTS_RESET",
        });
    };
}
