import {isNil} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {FilterTypes} from "../../../constans/filter";
import animalsDB from "../../../database/animalsDB";
import {
    getMostRecentEventIDs,
    getSelectedAnimalForDocuments,
    getSellList,
    makeGetAnimalsInGroup,
} from "../../../selectors/animalDocumentsSelectors";
import {getSales} from "../../../selectors/salesSelector";
import {getEmployeeList, getServiceList} from "../../../selectors/userSelector";
import {dateFormatter} from "../../../utils/AnimalDocumentsUtils";
import {formatAnimalName} from "../../../utils/AnimalsUtils";
import {isMobile} from "../../../utils/MobileUtils";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";
import {employeesValueFormatter} from "../../../utils/RaportsUtils";
import {getAnimalUnit, getManageSubgroups} from "../../../utils/SettingsUtils";
import {sortAsString, sortDateStrings} from "../../../utils/SortUtils";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {formatLocationName} from "../../../utils/global-formatters/formatLocationName";
import TableGrid from "../../basics/table-grid/TableGrid";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import EditSellOrFallModal, {
    ModalName as EditSellOrFallModalName,
} from "../../modals-new/edit-sell-or-fall-modal/EditSellOrFallModal";
import CollapsableContainer from "../containers/CollapsableContainer";
import AnimalReferenceDocumentModalGrid from "../../grids/animal-reference-document/AnimalReferenceDocumentModalGrid";
import {
    ModalName as GroupCardRemoveEventModalName
} from "../../modals-new/group-card-remove-event-modal/GroupCardRemoveEventModal";
import * as EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {removeRenderer} from "../TableComponents";

function makeMapStateToProps() {
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    return (state) => ({
        animals: getAnimalsInGroup(state),
        employees: getEmployeeList(state),
        service: getServiceList(state),
        sales: getSales(state),
        farm: state.location.farm,
        group: getSelectedAnimalForDocuments(state),
        sellEvents: getSellList(state),
        mostRecentEvents: getMostRecentEventIDs(state)([EventTypes.FALL, EventTypes.SELL])
    });
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch),
    };
}

class SellGrid extends React.Component {
    constructor(props) {
        super(props);
        this.showOperator = checkIfUserHasPrivilegedAccess();
        this.mobile = isMobile();
        this.canManageSubgroups = getManageSubgroups();
        this.renderRemove = removeRenderer.bind(this);
    }

    employeesValueFormatter = (value) => {
        const {employees, service} = this.props;
        return employeesValueFormatter(value, employees, service);
    };

    animalFormatter = (AnmID) => {
        const {animals, isGroup} = this.props;
        const animal =
            animals.find((a) => a.AnmID === AnmID) ||
            animalsDB.getAnimalById(AnmID, {
                joinEvents: false,
                findDeleted: true,
            });
        if (!isGroup) return animal?.AnmNo1 || "";
        if (animal.RFID || animal.Tagged || this.canManageSubgroups) {
            return (
                <AnimalReferenceDocumentModalGrid
                    value={animal.AnmNo1}
                    object={animal}
                />
            );
        }
        return "";
    };

    onSellClick = (link) => {
        this.props.history.push(link);
    };

    renderLinkToSell = ({value}) => {
        const {sales, farm} = this.props;
        const sale = sales.find(({EvIDs}) => EvIDs.includes(value));
        if (!sale) return null;
        const link = `/farm/${farm}/sell/edit/${sale.SaleID}`;
        return (
            <i
                className={"fas fa-dollar-sign pointer"}
                onClick={() => this.onSellClick(link)}
            />
        );
    };

    getAnimalById = (animalId) => this.props.animals.find((a) => a.AnmID === animalId) ||
        animalsDB.getAnimalById(animalId, {joinEvents: false, findDeleted: true})

    onRemoveClick = (props) => {
        const {t, group, isGroup} = this.props;
        const animal = this.getAnimalById(props.value.AnmID);
        this.props.show(GroupCardRemoveEventModalName, {
            title: t("removeSell"),
            text: t("removeSellText", {number: formatAnimalName(animal)}),
            name: isGroup ? group.GrNo1 : animal.AnmNo1,
            AnmID: props.value.AnmID,
            AnmCnt: props.value.AnmCnt,
            EvID: props.value.EvID,
            AnmGrp: isGroup ? group.AnmGrp : undefined,
            isGroup: !!isGroup
        });
    }

    onEditClick(props) {
        const {group, show} = this.props;
        show(EditSellOrFallModalName, {event: props.value, group});
    }

    locationValueFormatter = (PlcmntID) => formatLocationName(PlcmntID);

    weightFormatter(value) {
        return isNil(value)
            ? undefined
            : convertWeightUnitTo(value, {
                showUnit: true,
                unit: getAnimalUnit(),
                fixed: 1,
            });
    }

    isIndividualAnimal = (animal) =>
        animal.hasOwnProperty("RFID") || animal.Tagged;

    hasIndividualAnimals = (events) => {
        const {animals} = this.props;
        const _animals = animals.filter(
            (animal) =>
                this.isIndividualAnimal(animal) &&
                events.some((e) => e.AnmID === animal.AnmID)
        );
        return _animals.length > 0;
    };

    renderEdit = (props) => <i className="fas fa-edit pointer" onClick={() => this.onEditClick(props)}/>;

    render() {
        const {t, isGroup, sellEvents} = this.props;
        const hasIndividualAnimals = this.hasIndividualAnimals(sellEvents);
        const headers = [
            isGroup &&
            (hasIndividualAnimals || this.canManageSubgroups) && {
                name: t("animalNumber"),
                field: "AnmID",
                valueFormatter: this.animalFormatter,
            },
            isGroup && {
                name: t("location"),
                field: "EvData.PlcmntID",
                valueFormatter: this.locationValueFormatter,
            },
            {
                name: t("date"),
                field: "EvTime",
                valueFormatter: dateFormatter,
                customSort: sortDateStrings,
                _mobileDate: true,
            },
            {
                name: t("amount"),
                field: "AnmCnt",
                _mobileHide: true,
            },
            {
                name: t("avgPieceWeight"),
                field: "AnmWeight",
                valueFormatter: this.weightFormatter,
                disableValueFormatterSort: true,
            },
            {
                name: t("eventGrid.operator"),
                field: "EvData.OperID",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: this.showOperator,
                colWidth: 2,
            },
            {
                name: t("comment"),
                field: "Comment",
                customSort: sortAsString,
                filterType: FilterTypes.STRING,
            },
            {
                name: "",
                field: "EvID",
                component: this.renderLinkToSell,
                headerClassName: "index",
                itemClassName: "index",
                notSortable: true,
            },
            {
                name: "",
                component: this.renderEdit,
                headerClassName: "index",
                itemClassName: "index",
                notSortable: true,
            },
            {
                name: "",
                component: this.renderRemove,
                notSortable: true,
                headerClassName: "index",
                itemClassName: "index",
            },
        ].filter((o) => !!o);
        return (
            <>
                <CollapsableContainer.Card
                    id="cy-sell-card"
                    header={t("animalDocuments.sellGrid")}
                    defaultExpanded={sellEvents.length > 0}>
                    <TableGrid
                        data={sellEvents}
                        headers={headers}
                        mobileRow={<DefaultMobileRow/>}
                        showPagination={!this.mobile}
                        paginationItems={10}
                        scrollOnPageChange={false}
                    />
                </CollapsableContainer.Card>
                <EditSellOrFallModal/>
            </>
        );
    }
}

SellGrid.defaultProps = {
    isGroup: false,
};

SellGrid.propTypes = {
    isGroup: PropTypes.bool,
};

export default compose(
    withTranslation(),
    connect(makeMapStateToProps, mapDispatchToProps),
    withRouter
)(SellGrid);
