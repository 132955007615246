import {createSelector} from "reselect";
import {get, isEmpty} from "lodash";
import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import {checkIfUserIsService} from "../utils/NewRolesUtils";
import moment from "moment";
import {farmExpiration} from "../constans/farmExpiration";
import {getUser} from "./userSelector";

export const getFarmsFromUser = (state) => state.user.user.FarmData;

const getSelectedUser = (state, {selectedUser}) => selectedUser;

export const getFarm = (state, FarmID) =>
    state.farms.farms.find((item) => item.FarmID === FarmID);

export const getFarmID = (state) => state.location.farm;

const getFarmObjectFromState = (state) => getFarm(state, state.location.farm);

const getFarmIDFromSearch = (_, props) => {
    const search = new URLSearchParams(props?.location?.search || "");
    return search.get("FarmID");
};

export const getFarms = (state) => state.farms.farms;

export const getFarmObject = createSelector(
    getFarmObjectFromState,
    getFarms,
    getFarmIDFromSearch,
    (farm, farms, FarmID) => {
        if (farm) return farm;
        return farms.find((item) => item.FarmID === FarmID);
    }
);

export const getTestSwitchStatus = (state) => state.farms.testSwitch;

export const getSubscriptionFilter = (state) => state.farms.subscriptionFilter;

export const getFarmIfUserHasUserManagementRole = createSelector(
    [getUser, getFarmsFromUser],
    (user, farms) => {
        if (checkIfUserIsService()) {
            return farms.filter((f) => f.ClientID === user.ClientID);
        } else {
            const localRights = get(
                get(user, "Roles", []).find(
                    (role) => role.Role === RoleTypes.USER_MANAGE
                ),
                "LocalRights",
                []
            );
            if (!isEmpty(localRights)) {
                return farms.filter((f) =>
                    localRights.find(
                        (lr) =>
                            lr.FarmID === f.FarmID &&
                            lr.ClientID === user.ClientID
                    )
                );
            }
            return [];
        }
    }
);

export const getFarmsForChoosenUser = createSelector(
    [getFarmsFromUser, getSelectedUser],
    (farms, selectedUser = {}) => {
        // general read wszyscy musza miec ktorzy maja dostep na ferme
        const generalRead = get(selectedUser, "roles", []).find(
            (role) => role.Role === RoleTypes.GENERAL_READ
        );
        if (!isEmpty(generalRead)) {
            return farms.filter((f) =>
                get(generalRead, "LocalRights", []).find(
                    (lr) => lr.FarmID === f.FarmID
                )
            );
        }
        return [];
    }
);

export const getCurrentFarm = createSelector([getFarmObject], (farmObject) => {
    return farmObject;
});

export const getFarmsForChooser = createSelector(getFarms, getTestSwitchStatus, getSubscriptionFilter, (farms, testSwitch, subscriptionFilter) => {
        const markers = {};
        const bounds = [];
        const filteredFarms = checkIfUserIsService() ? farms.filter((item) => testSwitch ? item.TestFarm === true : testSwitch === false ? !item.TestFarm : true) : farms;
        for (const farm of filteredFarms) {
            let coords = [53.228928, 18.095559];
            try {
                if (Array.isArray(farm.FarmCord)) {
                    coords = farm.FarmCord;
                } else {
                    coords = farm.FarmCord.split(",").map((coord) => +coord.trim());
                    if (coords.length !== 2 || isNaN(coords[0]) || isNaN(coords[1])) {
                        coords = [53.228928, 18.095559];
                    }
                }
            } catch (e) {
                console.error(e);
                coords = [53.228928, 18.095559];
            }
            const coordsString = coords.join("");
            bounds.push(coords);
            if (!markers[coordsString]) {
                const aliases = new Map();
                aliases.set(farm.ClientID, farm.Alias);
                markers[coordsString] = {coords, farms: [], aliases: aliases};
            } else {
                // moze byc sytuacja gdzie sa fermy pod tymi samymi lokalizacjami - czytaj ktos skopiowal obiekt
                markers[coordsString].aliases.set(farm.ClientID, farm.Alias);
            }
            const timeToExpiring = moment.utc(farm.LicenseExpirationDate).endOf("day").diff(moment.utc().endOf("day"), "days");
            markers[coordsString].farms.push({
                FarmID: farm.FarmID,
                name: farm.FarmName,
                ClientID: farm.ClientID,
                licenseExpirationDate: farm.LicenseExpirationDate,
                expiring: timeToExpiring < 0 ? "expired" : timeToExpiring < farmExpiration.TWO_WEEKS ? "weeks" : timeToExpiring < farmExpiration.MONTH ? "month" : "no",
                remoteServicing: farm.RemoteServicing,
                isTestFarm: farm.TestFarm || false,
            });
        }
        let farmsToFilter = Object.values(markers);
        if (!subscriptionFilter.active) {
            farmsToFilter = farmsToFilter.filter((item) => {
                item.farms = item.farms.filter((farm) => farm.expiring !== "no");
                return item.farms.length > 0;
            });
        }
        if (!subscriptionFilter.month) {
            farmsToFilter = farmsToFilter.filter((item) => {
                item.farms = item.farms.filter((farm) => farm.expiring !== "month");
                return item.farms.length > 0;
            });
        }
        if (!subscriptionFilter.week) {
            farmsToFilter = farmsToFilter.filter((item) => {
                item.farms = item.farms.filter((farm) => farm.expiring !== "weeks");
                return item.farms.length > 0;
            });
        }
        if (!subscriptionFilter.inactive) {
            farmsToFilter = farmsToFilter.filter((item) => {
                item.farms = item.farms.filter((farm) => farm.expiring !== "expired");
                return item.farms.length > 0;
            });
        }
        return {
            farms: farmsToFilter,
            bounds: bounds.length > 0 ? bounds : null,
        };
    }
);

export const getFarmsForSearch = createSelector(
    getFarms,
    getTestSwitchStatus,
    (farms, testSwitch) => {
        let filteredFarms = checkIfUserIsService()
            ? farms.filter((item) =>
                testSwitch
                    ? item.TestFarm === true
                    : testSwitch === false
                        ? !item.TestFarm
                        : true
            )
            : farms;
        return filteredFarms.map((farm) => ({
            name: farm.FarmName,
            FarmID: farm.FarmID,
            address: farm.Adr,
            alias: farm.Alias,
            ClientID: farm.ClientID,
            farm,
        }));
    }
);

export const getFarmsOptionsForClientID = createSelector(
    getFarms,
    getUser,
    (farms, user) => {
        const isService = checkIfUserIsService();
        return farms
            .filter((farm) => farm.ClientID === user.ClientID)
            .map((farm) => ({
                name: `${farm.FarmName} ${isService ? `(${farm.FarmID})` : ""}`,
                value: farm.FarmID,
                FarmName: farm.FarmName,
            }));
    }
);

export const getDeviceLicenseStatus = createSelector(getFarmObject, (farm) => {
    const {DevicesModule} = farm;
    const license = {};
    for (let licenseName in DevicesModule) {
        let module = DevicesModule[licenseName];
        license[licenseName] = {
            current: module.amount - module.left,
            max: module.amount,
            extended: module.extended,
        };
    }

    return license;
});

export const isTOPIGSFarm = createSelector(
    getFarmObject,
    (farm) => !!farm.TOPIGSBusinessNumber
);

export const getOfflineFarm = createSelector(getFarms, (farms) => farms[0]);

export const getTOPIGSBusinessNumber = createSelector(getFarmObject, (farm) => farm.TOPIGSBusinessNumber || null);