import {getCageUnit} from "../../../utils/SettingsUtils";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import {get, isFinite} from "lodash";
import i18next from "i18next";
import {isFiniteNumber} from "../../../utils/MathUtils";
// import moment from "moment";

export const weightValueFormatter = (value, showUnit = false) => {
    const unit = getCageUnit();
    return isFinite(value) ? convertWeightUnitTo(value, {
        unit: unit,
        showUnit: !!showUnit,
        fixed: unit,
        rawValue: !showUnit,
        acceptNil: true
    }) : null;
}

export const weightValueFormatterWithUnit = (value) => {
    return weightValueFormatter(value, true);
}

export const weightValueUnit = () => {
    const unit = getCageUnit();
    return getUnit("weight", unit);
}

/**
 * zwraca gain obliczony z shadowa z wagi wczoraj i dzisiaj
 * @param device
 * @param shadow
 * @param yesterdayField
 * @param todayField
 * @returns {null|number}
 */
export const getGain = (device, shadow, yesterdayField = "averageWeights.yesterday", todayField = "averageWeights.all") => {
    try {
        if (shadow) {
            if (isFiniteNumber(shadow.gain)) return shadow.gain;
            const averageWeightYesterday = get(shadow, yesterdayField, 0);
            const averageWeight = get(shadow, todayField, 0);
            if (averageWeight && averageWeightYesterday) {
                return averageWeight - averageWeightYesterday;
            }
            // const insertions = device.Settings.Insertions.filter(item => item.StartTime < moment.utc().endOf("day"));
            // const insertion = maxBy(insertions, o => o.StartTime)
            // if (insertion) {
            //     const diff = moment.utc(insertion.StartTime).startOf("day").diff(moment.utc().startOf("day"), "days");
            //     return diff > 0 ? averageWeightYesterday - insertion.Weight : 0;
            // }
        }
        return null;
        // tutaj dorobic pobranie wagi z intertions jesli jest a jesli nie ma to shadow now odjac wczoraj
    } catch (err) {
        return null;
    }
}

export const cageExpeditionWorkTypeFormatter = (workType) => {
    return [1, 2, 3, 4].includes(workType) ? i18next.t(`modals.cageWorkMode.expeditionWorkTypes.${workType}`) : null;
}
