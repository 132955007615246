import {get, isEqual} from "lodash";

export const filterOptions = (filterText, options) => {
    const filterLowerCase = filterText.toLowerCase();
    return !filterText ? options : options
        .filter(({name, value}) => {
            const text = name
                ? get(value, name, name)
                : typeof value !== "object"
                    ? value
                    : "";
            return `${text}`.toLowerCase().includes(filterLowerCase);
        })
}

export const transformOptions = (options, mobile, value, hovered, customRenderer, handlers) => {
    return options.map((option, index) => ({
        mobile,
        option,
        key: index,
        active: isEqual(option.value, value),
        index,
        hovered: hovered === index,
        customRenderer,
        ...handlers
    }));
}