import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export function getTasks({DtaPlanned, DtaPlannedFrom, DtaPlannedTo, IsOpened, IsDone, Title, AssignTo, Priority, EstimatedTime, Limit, NextToken, forceFetch = false, signal = null}, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    let queryParams = {};
    for (let key of Object.keys(arguments[0])) {
        if (arguments[0][key] !== undefined && arguments[0][key] !== null) {
            queryParams[key] = arguments[0][key];
        }
    }
    return invokeApig({
        ...Paths.getTasks({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: queryParams,
        forceFetch,
        signal
    });
}
