import React from "react";
import {pick} from "lodash";

const Background = ({background, opacity = 0.5, blur = 0}) => {
    return (
        <svg>
            <g className={"bg-svg"} style={{opacity, ...blur && {filter: `blur(${blur}px)`}, pointerEvents: "none"}}>
                {
                    background.map(({type, params}, i) => (
                        React.createElement(type, {
                            key: i,
                            ...pick(params, ["d", "id", "fill"])
                        })
                    ))
                }
            </g>
        </svg>
    )
}

export default React.memo(Background);
