const initialValue = {
    loading: false,
    breedingCounters: []
};

export default function breedingCounterReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_BREEDING_COUNTERS":
            return {...state, breedingCounters: action.payload}
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state;
    }
}

