import memoizeOne from "memoize-one";
import moment from "moment";
import { createSelector } from "reselect";
import EstimatedWeight from "../beans/estimated-weight/EstimatedWeight";
import { UnitTypes } from "../constans/unitTypes";
import { utcMomentToLocal } from "../utils/DateTimeUtils";
import { getPrice } from "../utils/EconomyUtils";
import { convertVolumeUnitTo, convertWeightUnitTo } from "../utils/UnitUtils";
import { getEconomy } from "./economySelectors";
import { getTargetForage } from "./settingsSelector";

// 2 selektory wrzucone do innego pliku (powinny byc w aggregatedSelector, ale jak je sie tam wrzuci to babel nie radzi sobie z importami)
export const getAggregatedData = state => state.aggregatedData.data;

export const getAggregatedDataLoading = state => state.aggregatedData.fetching;

const _getDeviceData = state => state.aggregatedData.deviceData;

const getDeviceDataType = (_, __, ___, ____, type) => type;

const getDeviceDataKey = (_, Start, End, Placements) => `${Start}-${End}-${Placements.join("-")}`;

const getDeviceDataForageKey = (_, Start, End, __, Placements) => `${Start}-${End}-${Placements.join("-")}`;

export const getGroupGaugesAggDataLoading = createSelector(_getDeviceData, getDeviceDataKey, getDeviceDataForageKey, (deviceData, waterKey, forageKey) => {
    const waterData = deviceData.water?.[waterKey];
    const forageData = deviceData.forage?.[forageKey];
    return waterData?.loading || forageData?.loading || false;
})

export const makeGetDeviceData = () => createSelector(_getDeviceData, getDeviceDataType, getDeviceDataKey, (deviceData, type, key) => {
    return deviceData[type]?.[key] || { loading: false };
})

function calculateUsage(data, groupInfo) {
    let usage = { usage: 0, forPiece: 0 };
    for (let timestamp in data.data) {
        for (let PlcmntID in data.data[timestamp]) {
            const amount = groupInfo.getNumberByTimeAndLocation(utcMomentToLocal(moment.utc(+timestamp)), PlcmntID);
            const { Usage } = data.data[timestamp][PlcmntID];
            if (amount > 0) {
                usage.usage += Usage || 0;
                usage.forPiece += Usage / amount;
            }
        }
    }
    return usage;
}

const calculateForageUsage = memoizeOne((data, groupInfo, targetForage) => {
    console.log(targetForage);
    const tmp = { sum: 0, forages: {}, plcmnt: {} };
    const estimate = new EstimatedWeight(0, []);
    estimate.setWeightTable(targetForage.WeeklyTarget);
    estimate.setBirthDate(groupInfo.birthTime);
    for (let timestamp in data.data) {
        for (let PlcmntID in data.data[timestamp]) {
            const { Forages, Usage: allUsage } = data.data[timestamp][PlcmntID];
            tmp.sum += allUsage || 0;
            const dayLOCAL = utcMomentToLocal(moment.utc(+timestamp));
            const amount = groupInfo.getNumberByTimeAndLocation(dayLOCAL, PlcmntID);
            console.log("amount", PlcmntID, timestamp, moment.utc(+timestamp).format("L"), amount);
            if (!tmp.plcmnt[PlcmntID]) tmp.plcmnt[PlcmntID] = { usage: 0, forPiece: 0, expected: 0, offset: targetForage.Offset, expectedAll: 0 };
            const expected = estimate.getWeightByDate(+dayLOCAL);
            if (amount > 0) {
                tmp.plcmnt[PlcmntID].usage += allUsage || 0;
                tmp.plcmnt[PlcmntID].forPiece += allUsage / amount;
                tmp.plcmnt[PlcmntID].expected += expected;
                tmp.plcmnt[PlcmntID].expectedAll += expected * amount;
                for (let { FID, Usage } of Forages) {
                    if (Usage > 0) {
                        const usage = tmp.forages[FID] || { usage: 0, forageID: FID, forPiece: 0, expected: 0, offset: targetForage.Offset, expectedAll: 0 };
                        usage.usage += Usage || 0;
                        usage.forPiece += Usage / amount;
                        usage.expected += expected;
                        usage.expectedAll += expected * amount;
                        tmp.forages[FID] = usage;
                    }
                }
            }
        }
    }
    return tmp;
})

const getGroupInfo = (_, __, ___, ____, groupInfo) => groupInfo;

export const getForageUsageForDevice = createSelector(_getDeviceData, getDeviceDataKey, getGroupInfo, getTargetForage, (deviceData, key, groupInfo, targetForage) => {
    console.log(groupInfo);
    const data = deviceData.forage?.[key];
    if (!data) return null;
    return calculateForageUsage(data, groupInfo, targetForage);
    // return calculateUsage(data);
});

export const getWaterUsageForDevice = createSelector(_getDeviceData, getDeviceDataKey, getGroupInfo, (deviceData, key, groupInfo) => {
    const data = deviceData.water?.[key];
    if (!data) return null;
    return calculateUsage(data, groupInfo);
});

export const getForageCostForDevice = createSelector(_getDeviceData, getDeviceDataKey, getEconomy, (deviceData, key, economies) => {
    const data = deviceData.forage?.[key];
    if (!data) return null;
    let cost = 0;
    for (let timestamp in data.data) {
        for (let DevID in data.data[timestamp]) {
            const { Forages } = data.data[timestamp][DevID];
            for (let { FID, Usage } of Forages) {
                const economy = economies.find(item => item.EID === FID);
                const price = getPrice(economy, timestamp);
                const usageInTonnes = convertWeightUnitTo(Usage, { unit: UnitTypes.BIG, rawValue: true });
                cost += price * usageInTonnes;
            }
        }
    }
    return cost;
});

export const getWaterCostForDevice = createSelector(_getDeviceData, getDeviceDataKey, getEconomy, (deviceData, key, economies) => {
    const economy = economies.find(item => item.EID === "Water");
    if (!economy) return null;
    const data = deviceData.water?.[key];
    if (!data) return null;
    let cost = 0;
    for (let timestamp in data.data) {
        for (let DevID in data.data[timestamp]) {
            const { Usage } = data.data[timestamp][DevID];
            const price = getPrice(economy, timestamp);
            const usageInMeters = convertVolumeUnitTo(Usage, { unit: UnitTypes.BIG, rawValue: true });
            cost += price * usageInMeters;
        }
    }
    return cost;
});
