import React, {Component} from 'react';
import Card from "../../basics/card/Card";
import GraftingProgramGridCard from "../grafting/GraftingProgramGridCard";
import PigletsTreatmentCard from "../pigets-treatment/PigletsTreatmentCard";
import Select from "../../basics/select/Select";
import {connect} from 'react-redux';
import {getCycles} from "../../../selectors/animalDocumentsSelectors";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import "./sow-treatment.scss"
import ParturitionHistoryGridCard from "../parturition/ParturitionHistoryGridCard";
import CastrationTasksDone from "../castration/CastrationTasksDone";
import TattooGrid from "../tattoo/TattooGrid";
import SeparationToMommyGiltsGrid from "../separation-to-mommy-gilts/SeparationToMommyGiltsGrid";

function mapStateToProps(state) {
    return {
        cycles: getCycles(state).slice().sort((a, b) => b.cycle - a.cycle),
    };
}

class SowTreatment extends Component {

    state = {
        cycle: this.props.cycles[0]?.cycle || 0
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.cycles.length === 0 && this.props.cycles.length > 0) {
            this.setState({
                cycle: this.props.cycles[0].cycle
            })
        }
    }

    onSelectChange = value => {
        this.setState({
            cycle: value
        })
    };

    render() {
        const {cycles, t} = this.props;
        const {cycle} = this.state;
        const options = cycles.map((cycle) => ({name: t("cycle", {count: cycle.cycle}), value: cycle.cycle}));
        return (
            <Card cyData="sow-general-treatment-card" className="sow-treatment-card">
                <h5>{t("treatmentsAndParturition")}</h5>
                {
                    !!options.length &&
                    <Select options={options} clearButton={false} value={cycle} onChange={this.onSelectChange}/>
                }
                <SeparationToMommyGiltsGrid cycle={cycle}/>
                <TattooGrid cycle={cycle}/>
                <fieldset id="cy-sow-treatment-card" className="fieldset mb-2">
                    <GraftingProgramGridCard cycle={cycle} showCard={false}/>
                </fieldset>
                <CastrationTasksDone cycle={cycle}/>
                <PigletsTreatmentCard cycle={cycle}/>
                <ParturitionHistoryGridCard cycle={cycle}/>
            </Card>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(SowTreatment);
