import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from "moment";
import {withTranslation} from "react-i18next";
import {isMobile} from "../../../utils/MobileUtils";
import {getEmployeeList, getServiceList} from "../../../selectors/userSelector";
import {employeesValueFormatter} from "../../../utils/RaportsUtils";
import {bindActionCreators, compose} from "redux";
import CollapsableContainer from "../../animal-documents/containers/CollapsableContainer";
import {getHeatList, getSelectedAnimalForDocuments} from "../../../selectors/animalDocumentsSelectors";
import {get} from "lodash";
import TableGrid from "../../basics/table-grid/TableGrid";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import {HeatsComponent} from "../../../views/control-lists-view/control-lists/heats/HeatsComponent";
import {show} from "redux-modal";
import {getLanguage} from "../../../selectors/iotAlarmsSelector";
import {ModalName as ConfirmModalName} from "../../modals-new/confirm-modal/ConfirmModal";
import EditHeatModal, {ModalName as EditHeatModalName} from "../../modals-new/edit-heat-modal/EditHeatModal";
import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess
} from "../../../api/cycles/editSowCycle";

function mapStateToProps(state) {
    return {
        animal: getSelectedAnimalForDocuments(state),
        heatsList: getHeatList(state),
        lang: getLanguage(state),
        employees: getEmployeeList(state),
        service: getServiceList(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

class HeatList extends Component {

    constructor(props) {
        super(props);
        this.mobile = isMobile();
        this.state = {
            selectedWeekIndex: null
        };
    }

    dateValueFormatter = value => {
        if (value) return moment(value).format("L");
        return null;
    };

    employeesValueFormatter = value => {
        const {employees, service} = this.props;
        return employeesValueFormatter(value, employees, service);
    };

    onHeatClick = (e) => {
        const id = get(e, "target.id") || "";
        const index = +id.split("_")[1];
        if (typeof index === "number" && index >= 0) {
            const {selectedWeekIndex} = this.state;
            this.setState({
                selectedWeekIndex: selectedWeekIndex === index ? null : index
            });
        }
    }

    onEditHeatClick = ({object}) => {
        const {show} = this.props;
        show(EditHeatModalName, {heat: object});
    }

    onRemoveClick = ({object}) => {
        const {t, show, animal: {AnmNo1, AnmID}} = this.props;
        show(ConfirmModalName, {
            title: t("removeEventForAnimalTitle", {animal: AnmNo1}),
            text: t("removeEventForAnimalText", {animal: AnmNo1}),
            confirmText: t("yes"),
            size: "lg",
            onConfirmed: (props) => {
                return editSowCycle({AnmID, eventsToUpdate: [], eventsToDelete: [object]}).then((res) => {
                    props.handleHide();
                    editSowCycleNotificationSuccess(res);
                }).catch((err) => {
                    editSowCycleNotificationFailure(err);
                });
            }
        });
    }

    getHeaders() {
        const {t} = this.props;
        return [
            {
                name: t("date"),
                field: "EvTime",
                valueFormatter: this.dateValueFormatter,
                _mobileDate: true,
                _mobileHeader: true
            },
            {
                name: t("comment"),
                field: "Comment"
            },
            {
                name: t("eventGrid.operator"),
                field: "EvData.OperID",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess()
            },
            {
                name: "",
                component: (props) => <i className="fas fa-trash pointer" onClick={() => this.onRemoveClick(props)}/>,
                headerClassName: "index",
                itemClassName: "index",
                notSortable:true,
            },
            {
                name: "",
                component: (props) => <i className="fas fa-edit pointer" onClick={() => this.onEditHeatClick(props)}/>,
                headerClassName: "index",
                itemClassName: "index",
                notSortable:true,
            }
        ];
    }

    getLastHeat() {
        const {heatsList} = this.props;
        if (heatsList.length === 0) return null;
        return heatsList.find((item) => item.heats.length > 0)?.heats[0] || null;
    }

    render() {
        const {t, heatsList} = this.props;
        const {selectedWeekIndex} = this.state;
        const lastHeat = this.getLastHeat();
        const data = heatsList[selectedWeekIndex]?.heats || []
        return (
            <CollapsableContainer.Card id="cy-heat-card" header={t("eventGrid.heatList")} defaultExpanded={!!lastHeat}>
                {
                    lastHeat &&
                    <span>
                        {t("dateOfLastHeat")}:
                        <strong className="ms-1 opacity-75">{moment(lastHeat.EvTime).format("L")}</strong>
                    </span>
                }
                <HeatsComponent value={heatsList} size={"large"} onClick={this.onHeatClick}/>
                <TableGrid scrollOnPageChange={false} mobileRow={<DefaultMobileRow/>} showPagination={!this.mobile}
                           data={data} headers={this.getHeaders()} paginationItems={10}/>
                <EditHeatModal/>
            </CollapsableContainer.Card>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(HeatList);
