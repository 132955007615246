export default `
WITH startCte AS (
  SELECT 
    dataEv 
  FROM 
    ParamsTable 
  WHERE 
    group_nr = ? 
    AND dataEv IS NOT NULL 
  ORDER by 
    dataEv 
  LIMIT 
    1
), 
insertedCte AS (
  SELECT 
    SUM(
      CASE WHEN key <> 'pigletReclassifyToPorker' THEN [value] ELSE 0 END
    ) as animals, 
    SUM(
      CASE WHEN key IN (
        'weanedInsertedCnt', 'weanerTransferredTo'
      ) THEN [value] ELSE 0 END
    ) as weaners, 
    SUM(
      CASE WHEN key IN (
        'giltInsertedCnt', 'giltTransferredTo'
      ) THEN [value] ELSE 0 END
    ) as gilts, 
    SUM(
      CASE WHEN key IN (
        'finisherInsertedCnt', 'finisherTransferredTo', 
        'pigletReclassifyToPorker'
      ) THEN [value] ELSE 0 END
    ) as finisher, 
    dataEv - (
      dataEv % (1000 * 60 * 60 * 24)
    ) as date 
  FROM 
    ParamsTable 
  WHERE 
    group_nr = ?
    AND key IN (
      'weanedInsertedCnt', 'giltInsertedCnt', 
      'finisherInsertedCnt', 'weanerTransferredTo', 
      'giltTransferredTo', 'finisherTransferredTo', 
      'pigletReclassifyToPorker'
    ) 
  GROUP BY 
    dataEv - (
      dataEv % (1000 * 60 * 60 * 24)
    )
), 
transferredFromCte AS (
  SELECT 
    SUM([value]) as animals, 
    SUM(
      CASE WHEN key = 'weanerTransferredFrom' THEN [value] ELSE 0 END
    ) as weaners, 
    SUM(
      CASE WHEN key = 'giltTransferredFrom' THEN [value] ELSE 0 END
    ) as gilts, 
    SUM(
      CASE WHEN key = 'finisherTransferredFrom' THEN [value] ELSE 0 END
    ) as finisher, 
    dataEv - (
      dataEv % (1000 * 60 * 60 * 24)
    ) as date 
  FROM 
    ParamsTable 
  WHERE 
    group_nr = ?
    AND key IN (
      'weanerTransferredFrom', 'giltTransferredFrom', 
      'finisherTransferredFrom'
    ) 
  GROUP BY 
    dataEv - (
      dataEv % (1000 * 60 * 60 * 24)
    )
), 
removedCte AS (
SELECT SUM(CASE WHEN key <> 'pigletReclassifyToPorker' THEN [value] ELSE 0 END) as animals,
SUM(
      CASE WHEN key = 'weanedPigSellCnt' OR key = 'pigletReclassifyToPorker' THEN [value] ELSE 0 END
    ) as weaners, 
    SUM(
      CASE WHEN key = 'giltPigSellCnt' THEN [value] ELSE 0 END
    ) as gilts, 
    SUM(
      CASE WHEN key = 'finisherPigSellCnt' THEN [value] ELSE 0 END
    ) as finisher, 
    dataEv - (
      dataEv % (1000 * 60 * 60 * 24)
    ) as date 
  FROM 
    ParamsTable 
  WHERE 
    group_nr = ?
    AND key IN (
      'weanedPigSellCnt', 'giltPigSellCnt', 
      'finisherPigSellCnt', 'pigletReclassifyToPorker'
    ) 
  GROUP BY 
    dataEv - (
      dataEv % (1000 * 60 * 60 * 24)
    )
),
animalAmountCte AS (
  SELECT 
    IIF(insertedCte.date IS NOT NULL, insertedCte.date, transferredFromCte.date) as date, 
    DEF(insertedCte.animals, 0) - DEF(transferredFromCte.animals, 0) as animals, 
    DEF(insertedCte.weaners, 0) - DEF(transferredFromCte.weaners, 0) as weaners, 
    DEF(insertedCte.gilts, 0) - DEF(transferredFromCte.gilts, 0) as gilts, 
    DEF(insertedCte.finisher, 0) - DEF(transferredFromCte.finisher, 0) as finisher 
  FROM 
    insertedCte FULL 
    OUTER JOIN transferredFromCte ON insertedCte.date = transferredFromCte.date
), 
fallenCte AS (
  SELECT 
    SUM([value]) as animals, 
    SUM(
      CASE WHEN key = 'weanedPigDeathCnt' THEN [value] ELSE 0 END
    ) as weaners, 
    SUM(
      CASE WHEN key = 'giltPigDeathCnt' THEN [value] ELSE 0 END
    ) as gilts, 
    SUM(
      CASE WHEN key = 'finisherPigDeathCnt' THEN [value] ELSE 0 END
    ) as finisher, 
    dataEv - (
      dataEv % (1000 * 60 * 60 * 24)
    ) as date 
  FROM 
    ParamsTable 
  WHERE 
    group_nr = ? 
    AND key IN (
      'weanedPigDeathCnt', 'finisherPigDeathCnt', 
      'giltPigDeathCnt'
    ) 
  GROUP BY 
    dataEv - (
      dataEv % (1000 * 60 * 60 * 24)
    )
), 
datesCte AS (
  SELECT 
    GENERATE_DATES(dataEv, ?) as dates 
  FROM 
    startCte
), 
datesCteSimplified AS (
  SELECT 
    VALUE ARRAY(dates) -> 0 
  FROM 
    datesCte
), 
insertedByDayCte AS (
  SELECT 
    datesCteSimplified.date as date, 
    SUM(animals) as animals, 
    SUM(weaners) as weaners, 
    SUM(gilts) as gilts, 
    SUM(finisher) as finisher 
  FROM 
    animalAmountCte 
    RIGHT JOIN datesCteSimplified ON animalAmountCte.date <= datesCteSimplified.date 
  GROUP BY 
    datesCteSimplified.date
), 
fallenByDayCte AS (
  SELECT 
    datesCteSimplified.date as date, 
    SUM(animals) as animals, 
    SUM(weaners) as weaners, 
    SUM(gilts) as gilts, 
    SUM(finisher) as finisher 
  FROM 
    fallenCte 
    RIGHT JOIN datesCteSimplified ON fallenCte.date <= datesCteSimplified.date 
  GROUP BY 
    datesCteSimplified.date
), 
removedByDayCte AS (
SELECT 
    datesCteSimplified.date as date, 
    SUM(animals) as animals, 
    SUM(weaners) as weaners, 
    SUM(gilts) as gilts, 
    SUM(finisher) as finisher 
  FROM 
    removedCte 
    RIGHT JOIN datesCteSimplified ON removedCte.date <= datesCteSimplified.date 
  GROUP BY 
    datesCteSimplified.date
),
joinedCte AS (
  SELECT 
    insertedByDayCte.date as time, 
    insertedByDayCte.animals as insertedAnimals, 
    insertedByDayCte.weaners as insertedWeaners, 
    insertedByDayCte.gilts as insertedGilts, 
    insertedByDayCte.finisher as insertedFinisher, 
    DEF(fallenByDayCte.animals, 0) as fallen, 
    DEF(fallenByDayCte.weaners, 0) as fallenWeaners, 
    DEF(fallenByDayCte.gilts, 0) as fallenGilts, 
    DEF(fallenByDayCte.finisher, 0) as fallenFinisher,
    DEF(removedByDayCte.animals, 0) as removedAnimals,
    DEF(removedByDayCte.weaners, 0) as removedWeaners,
    DEF(removedByDayCte.gilts, 0) as removedGilts,
    DEF(removedByDayCte.finisher, 0) as removedFinisher 
  FROM 
    insertedByDayCte 
    INNER JOIN fallenByDayCte ON insertedByDayCte.date = fallenByDayCte.date
    INNER JOIN removedByDayCte ON insertedByDayCte.date = removedByDayCte.date
) 
SELECT 
  *, 
  fallen / insertedAnimals * 100 as mortality, 
  CASE WHEN insertedWeaners - (removedWeaners + fallenWeaners) <> 0 THEN fallenWeaners / insertedWeaners * 100 ELSE NULL END as weanerMortality, 
  CASE WHEN insertedGilts - (removedGilts + fallenGilts) <> 0 THEN fallenGilts / insertedGilts * 100 ELSE NULL END as giltMortality, 
  CASE WHEN insertedFinisher - (removedFinisher + fallenFinisher) <> 0 THEN fallenFinisher / insertedFinisher * 100 ELSE NULL END as finisherMortality 
FROM 
  joinedCte 
ORDER BY 
  time
`;