import React, {Component} from 'react';
import Card from "../basics/card/Card";
import "./_chunk-load-error-catcher.scss";
import Button from "../basics/button/Button";
import {withTranslation} from "react-i18next";

class ChunkLoadErrorCatcher extends Component {

    state = {
        hasError: false
    }

    componentDidCatch(error, errorInfo) {
        const regex = new RegExp("Loading chunk .+ failed");
        if (regex.test(error.message)) {
            this.setState({hasError: true});
        } else {
            throw error;
        }
    }

    onClick = () => {
        window.location.reload();
    }

    render() {
        const {t} = this.props;
        const {hasError} = this.state;
        if (hasError) {
            return (
                <Card className="chunk-load-error-catcher">
                    <h4>{t("loadChunkError1")}</h4>
                    <h5>{t("loadChunkError2")}</h5>
                    <div className="text-center"><Button className="m-0" onClick={this.onClick}>{t("grid.refreshTooltip")}</Button></div>
                </Card>
            )
        }
        return this.props.children;
    }
}

export default withTranslation()(ChunkLoadErrorCatcher);
