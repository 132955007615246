import React, {useMemo, useRef} from "react";
import ReactDOM from "react-dom";
import {Fade} from "react-bootstrap";
import {getScrollParent} from "../../../utils/DOMUtils";

function getScrollOfElement(element) {
    if (element.tagName.toLowerCase() === "body") return window.scrollY;
    return element.scrollTop;
}

export default function TableItemTitle({show, className, children, parent}) {

    const container = useRef();

    const scrollableElement = useMemo(() => {
        return getScrollParent(parent);
    }, [parent]);

    const styles = useMemo(() => {
        if (show) {
            try {
                const rect = parent.getBoundingClientRect();
                const maxWidth = window.innerWidth - 15 - parent.offsetLeft;
                return {
                    top: rect.top + getScrollOfElement(scrollableElement) - 1,
                    left: rect.left,
                    minWidth: parent.clientWidth,
                    minHeight: parent.clientHeight,
                    maxWidth: maxWidth,
                    position: 'absolute'
                };
            } catch (e) {
                return {
                    top: 0,
                    left: 0,
                    minWidth: 0,
                    minHeight: 0,
                    maxWidth: 0,
                    position: 'absolute'
                }
            }
        }
        return null;
    }, [show, parent, scrollableElement]);

    if (!scrollableElement) return null;
    return ReactDOM.createPortal(
        <Fade in={show} unmountOnExit mountOnEnter>
            <div style={styles} className={`${className} item-title`} ref={container}>
                {children}
            </div>
        </Fade>,
        scrollableElement
    )
}
