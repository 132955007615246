const initialValue = {
    data: null,
    loading: true,
    files: []
};

export default function changelogReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_CHANGELOG_PENDING":
            return {...state, loading: true};
        case "GET_CHANGELOG_REJECTED":
            return {...state, loading: true};
        case "GET_CHANGELOG_FULFILLED":
            return {...state, data: {__html: action.payload.html}, loading: false, files: action.payload.files};
        default:
            return state
    }
}
