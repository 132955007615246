import NewIOT from "../NewIOT";
import { DispenserNRFCommandTypes, GatawayCommandTypes } from "../../constans/mqttMessages";
import { getIOTAttributes } from "../../utils/IOTUtils";
import i18next from "../../i18n";
import { isFunction } from "lodash";
import { Milliseconds } from "../../constans/milliseconds";

/*
    maxOneDose,
    initDose,
    timeOrImpulse,
    workType
 */
export function setWorkType(dev, {
    maxOneDose,
    initDose,
    timeOrImpulse,
    optButt,
    workType
} = {}, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_WORK_TYPE, {
            maxOneDose,
            initDose,
            timeOrImpulse,
            optButt,
            workType
        }, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setOperationMode", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setOperationModeDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setOperationModeFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function setMotorSetup(dev, data, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_MOTOR_SETUP, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setMotorsConfiguration", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setMotorsConfigurationDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setMotorsConfigurationFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) {
            onFailure(new Error('No MQTT params'));
        }
    }
}


export function setFeedEfcTime(dev, data) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FEED_EFC_TIME, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setEfficiencyTime", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setEfficiencyTimeDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setEfficiencyTimeFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setFeedEfcImpulse(dev, data) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FEED_EFC_IMPULSE, data, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setEfficiencyImpulse", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setEfficiencyImpulseDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setEfficiencyImpulseFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function getLogs(dev, onSuccess, onFailure, onSend) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_LOGS, null, null, onSuccess, onFailure, onSend);
    }
}

export function deleteLogs(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.DELETE_LOGS, null, {
            loading: {
                title: i18next.t("logs"),
                message: i18next.t("IOT.removeLogs", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.removeLogsDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.removeLogsFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setLock(dev, status, onSuccess, onFailure) {
    return status ? setSensorsLock(dev, { onSuccess, onFailure }) : setSensorsUnlock(dev, { onSuccess, onFailure });
}


export function startMotor(dev, data, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.START_MOTORS, data, {
            loading: {
                title: i18next.t("motor"),
                message: i18next.t("IOT.startMotor", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.startMotorDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.startMotorFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }

}

export function stopMotor(dev, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.STOP_MOTORS, null, {
            loading: {
                title: i18next.t("motor"),
                message: i18next.t("IOT.stopMotor", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.stoppedTheMotorOn", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.coultNotStopTheEngineAt", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure, undefined);
    } else if (onFailure) {
        onFailure(new Error('No MQTT params'));
    }

}

/**
 * Function to configure pig on RFID stand
 * @param dev - Gateway/GatewayID
 * @param data - configuration data structure {
 *     PlcmntID: chamber locationID as string.
 *     animals: array of pigs [{
 *         RFID: rfid number,
 *         AnmNo1: animal number
 *         AnmID: animal ID,
 *         curveCfg:{
 *             curveNr - curve number 0 - 15
 *             offset
 *             percentageCorrection
 *             startTime
 *             punishment
 *         }
 *     }]
 * }
 * @param animals
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @deprecated use setAnimal
 * @returns {string}
 */
export function addPig(dev, data, animals, onSuccess, onFailure = null, hideNotification = false) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.ADD_PIG, data, !hideNotification ? {
            loading: {
                title: i18next.t("IOT.setFeedingTitle"),
                message: i18next.t("IOT.setFeeding", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setFeedingDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setFeedingFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

/**
 * @deprecated use setAnimal
 * @param dev
 * @param data
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @return {string}
 */
export function setConfigStandard(dev, data, onSuccess, onFailure, hideNotification = false) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    console.log(ClientID, LocalUserID, GatewayID, DevID, Name, isValid);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CONFIG_STANDARD, data, !hideNotification ? {
            loading: {
                title: i18next.t("IOT.setFeedingTitle"),
                message: i18next.t("IOT.setFeeding", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setFeedingDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setFeedingFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

/**
 *
 * @param dev
 * @param data
 * @returns {string}
 */
export function setTypeEvent(dev, data) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    //console.log(ClientID, LocalUserID, GatewayID, DevID, Name, isValid);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_TYPE_EVENT, data, {
            loading: {
                title: i18next.t("IOT.setDispenserNRFTypeEvent"),
                message: i18next.t("IOT.setDispenserNRFTypeEventText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setDispenserNRFTypeEventSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setDispenserNRFTypeEventError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

export function getHistoryStandard(ClientID, LocalUserID, device, onSuccess, onFailure) {
    return NewIOT.createAndSendMessageObject(ClientID, device.GatewayID, LocalUserID, device.DevID, DispenserNRFCommandTypes.GET_HISTORY_STANDARD, null, null, onSuccess, onFailure);
}

/**
 *
 * @param dev
 * @param alert
 * @param onSuccess
 * @param onFailure
 * @returns {string}
 */
export function setAlert(dev, alert = false, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    //console.log(ClientID, LocalUserID, GatewayID, DevID, Name, isValid);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ALERT, { alert: !!alert }, {
            loading: {
                title: i18next.t(`IOT.${alert ? "setDispenserNRFAlarm" : "clearDispenserNRFAlarm"}`),
                message: i18next.t(`IOT.${alert ? "setDispenserNRFAlarmText" : "clearDispenserNRFAlarmText"}`, { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t(`IOT.${alert ? "setDispenserNRFAlarmSuccess" : "clearDispenserNRFAlarmSuccess"}`, { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t(`IOT.${alert ? "setDispenserNRFAlarmError" : "clearDispenserNRFAlarmError"}`, { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) {
            onFailure(new Error('No MQTT params'));
        }
    }

}

export function getUploadSoftwareStatus(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_UPLOAD_SOFTWARE_STATUS);
    }
}

export function getConfiguration(dev, cmd, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_CONFIGURATION, { cmd }, null, onSuccess, onFailure);
    }
}

export function getCurveCRC(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_CURVE_CRC);
    }
}

export function getScheduleCRC(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_SCHEDULE_CRC);
    }
}

export function getFeedEfcTime(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_FEED_EFC_TIME);
    }
}

export function getFeedEfcImpulse(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_FEED_EFC_IMPULSE);
    }
}

export function getCANStatus(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_CAN_STATUS);
    }
}

export function getSkipDoses(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_SKIP_DOSES);
    }
}

/**
 *
 * @param dev
 * @param data {object}
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @param data.dosesToSkip {array}
 * @returns {string}
 */
export function setSkipDoses(dev, data, onSuccess, onFailure, hideNotification) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SKIP_DOSES, data, !hideNotification ? {
            loading: {
                title: i18next.t("IOT.skipDose"),
                message: i18next.t("IOT.setSkipDose", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSkipDoseDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSkipDoseFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }

}

export function setPunishmentSetup(dev, data = []) {
    return new Promise((resolve, reject) => {
        const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
        if (isValid) {
            NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_MINUS_CONFIG, data, null, resolve, reject);
        }
        else {
            reject(new Error('IOt attributes not valid'));
        }
    });
}

/**
 *
 * @param dev
 * @param data
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @param data.dose dawka g
 * @param data.forage index
 * @return {string}
 */
export function setForceFeeding(dev, data, onSuccess, onFailure, hideNotification = false) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FORCE_FEEDING, data, !hideNotification ? {
            loading: {
                title: i18next.t("extraFeeding"),
                message: i18next.t("IOT.setAdditionalFeeding", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAdditionalFeedingDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAdditionalFeedingFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }
}

export function setEmptyDispenser(dev, motorNumber, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FORCE_FEEDING, {
            dose: 12750, type: 0, motorNumber
        }, {
            loading: {
                title: i18next.t("emptyDispenser"),
                message: i18next.t("IOT.setEmptyDispenser", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setEmptyDispenserDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setEmptyDispenserFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

export function setEmptyDispenserIPSUM(dev, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.START_MOTORS, {
            time: 1000 * 60 * 10
        }, {
            loading: {
                title: i18next.t("emptyDispenser"),
                message: i18next.t("IOT.setEmptyDispenser", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setEmptyDispenserDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setEmptyDispenserFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

/**
 *
 * @param dev
 * @param state
 * @returns {string}
 */
export function setSwitchMotorOnInfo(dev, state, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SWITCH_MOTOR_ON_INFO, { state }, {
            loading: {
                title: i18next.t("IOT.setSwitchMotorOnInfo"),
                message: i18next.t("IOT.setSwitchMotorOnInfoText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSwitchMotorOnInfoSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSwitchMotorOnInfoError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }

}

/**
 *
 * @param dev
 * @param section
 * @param onSuccess
 * @param onFailure
 * @returns {string}
 */
export function setClearFRAM(dev, section, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CLEAR_FRAM, { section }, {
            loading: {
                title: i18next.t("IOT.setClearFRAM"),
                message: i18next.t("IOT.setClearFramText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setClearFramSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setClearFramError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure();
    }

}

/**
 *
 * @param dev
 * @param power
 * @param onSuccess
 * @param onFailure
 * @returns {string}
 */
export function setNRFPower(dev, power, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_NRF_POWER, { power }, {
            loading: {
                title: i18next.t("IOT.setNRFPower"),
                message: i18next.t("IOT.setNRFPowerText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setNRFPowerSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setNRFPowerFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }

}

/**
 *
 * @param dev
 * @param duration
 * @param onSuccess
 * @param onFailure
 * @returns {string}
 */
export function setClearHistory(dev, duration, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CLEAR_HISTORY, { duration }, {
            loading: {
                title: i18next.t("IOT.setClearHistory"),
                message: i18next.t("IOT.setClearHistoryText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setClearHistorySuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setClearHistoryFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure();
    }

}

/**
 *
 * @param dev
 * @param command
 * @param onSuccess
 * @param onFailure
 * @returns {string}
 */
export function setControlBootloader(dev, command, onSuccess = null, onFailure = null) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CONTROL_BOOTLOADER, { command }, command === 3 ? {
            loading: {
                title: i18next.t("IOT.setControlBootloader"),
                message: i18next.t("IOT.setControlBootloaderText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setControlBootloaderSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setControlBootloaderFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

export function getFramRamStatusCRC(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_FRAM_RAM_STATUS_CRC);
    }
}

export function unsetInseminationParturition(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_INSEMINATION_PARTURITION, { isInseminated: false }, {
            loading: {
                title: i18next.t("IOT.unsetEvent"),
                message: i18next.t("IOT.unsetEventText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.unsetEventSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.unsetEventError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setParturition(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_INSEMINATION_PARTURITION, { isInseminated: true }, {
            loading: {
                title: i18next.t("setEvent"),
                message: i18next.t("IOT.setParturitionText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setParturitionSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setParturitionError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setInsemination(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_INSEMINATION_PARTURITION, { isInseminated: true }, {
            loading: {
                title: i18next.t("setEvent"),
                message: i18next.t("IOT.setInseminationText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setInseminationSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setInseminationError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }
}

export function setFeedingEfficiency(dev, {
    feedingDoseType = 0,
    feedingTimeEfficiency = 1000,
    feedingImpulseEfficiency = 25
}, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_FEEDING_EFFICIENCY, arguments[1], {
            loading: {
                title: i18next.t("IOT.setFeedingEfficiencyTitle"),
                message: i18next.t("IOT.setFeedingEfficiencyText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setFeedingEfficiencySuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setFeedingEfficiencyError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}
export function setFeedingEfficiencyIpsumV2(dev, {
    feedingDoseType = 0,
    feedingTimeEfficiency = 1000,
    feedingImpulseEfficiency = 25
}, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_IPSUM_EFFICIENCY_V2, arguments[1], {
            loading: {
                title: i18next.t("IOT.setFeedingEfficiencyTitle"),
                message: i18next.t("IOT.setFeedingEfficiencyText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setFeedingEfficiencySuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setFeedingEfficiencyError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}
/**
 *
 * @param dev
 * @param waterEfficiency {number} (0 - 4 294 967 294, [imp/l] lub [ml/30s] zależnie od waterDoseType)
 * @param waterInitDose {number} (0 - 4 294 967 294, dawka inicjująca [ml])
 * @param waterDose {number} (0 - 4 294 967 294, dawka jednorazowa [ml])
 * @param onSuccess {function}
 * @param onFailure {function}
 * @return {string} (id wyslanej komendy)
 */
export function setWater(dev, { waterInitDose = 0, waterDose = 0 }, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_WATER_EFFICIENCY, arguments[1], {
            loading: {
                title: i18next.t('IOT.setWaterTitle'),
                message: i18next.t('IOT.setWaterMsg', { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setWaterMsgDone', { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setWaterMsgFailed', { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}


export function setAdditionalWater(dev, { amount = 0 }, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ADDITIONAL_WATER, {
            waterDoseType: 1,
            waterDose: amount
        }, {
            loading: {
                title: i18next.t('extraWater'),
                message: i18next.t('IOT.setAdditionalWaterMsg', { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.setAdditionalWaterMsgDone', { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.setAdditionalWaterMsgFailed', { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }
}


export function stopWater(dev, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ADDITIONAL_WATER, {
            waterDoseType: 3,
            waterDose: 0
        }, {
            loading: {
                title: i18next.t('IOT.stopWaterTitle'),
                message: i18next.t('IOT.stopWaterMsg', { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.stopWaterMsgDone', { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.stopWaterMsgFailed', { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

export function startWater(dev, { time = 0 }, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ADDITIONAL_WATER, {
            waterDoseType: 0,
            waterDose: time
        }, {
            loading: {
                title: i18next.t('IOT.startWaterTitle', { seconds: Math.floor(time / 1000) }),
                message: i18next.t('IOT.startWaterMsg', { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.startWaterMsgDone', { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.startWaterMsgFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

export function startWaterImpulses(dev, { impulses = 0 }, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ADDITIONAL_WATER, {
            waterDoseType: 4,
            waterDose: impulses
        }, {
            loading: {
                title: i18next.t('IOT.startWaterImpulsesTitle', { impulses: impulses }),
                message: i18next.t('IOT.startWaterImpulsesMsg', { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t('IOT.startWaterImpulsesMsgDone', { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t('IOT.startWaterImpulsesMsgFailed', {
                    name: Name
                }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
}

/**
 *
 * @param dev
 * @param data - configuration data structure {
 *     PlcmntID: chamber locationID as string.
 *     animals: array of pigs [{
 *         RFID: rfid number,
 *         AnmNo1: animal number
 *         AnmID: animal ID,
 *         feeding:{
 *             curveNr - curve number 0 - 15
 *             offset
 *             percentageCorrection
 *             startTime
 *             punishment
 *         }
 *     }]
 * }
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @return {string}
 */
export function setAnimal(dev, data = [], { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        const showSetFeeding = data.length !== data.filter(o => !!o.removed).length;
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ANIMAL_MODIFICATION, data, showNotification ? {
            loading: {
                title: i18next.t(showSetFeeding ? "IOT.setFeedingTitle" : "IOT.setDeletePig"),
                message: i18next.t(showSetFeeding ? "IOT.setFeeding" : "IOT.setDeletePigText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t(showSetFeeding ? "IOT.setFeedingDone" : "IOT.setDeletePigSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t(showSetFeeding ? "IOT.setFeedingFailed" : "IOT.setDeletePigFailure", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }
}

/**
 *
 * @param dev
 * @param data - configuration data structure {
 *     PlcmntID: chamber locationID as string.
 *     animals: array of pigs [{
 *         RFID: rfid number,
 *         AnmNo1: animal number
 *         AnmID: animal ID,
 *         feeding:{
 *             curveNr - curve number 0 - 15
 *             offset
 *             percentageCorrection
 *             startTime
 *             punishment
 *         }
 *     }]
 * }
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @return {string}
 */
export function setAnimalInsemination(dev, data = [], { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ANIMAL_MODIFICATION, data, showNotification ? {
            loading: {
                title: i18next.t("tagReaderDrawer.insemination"),
                message: i18next.t("IOT.setInseminationText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setInseminationSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setInseminationError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

/**
 *
 * @param dev
 * @param data - configuration data structure {
 *     PlcmntID: chamber locationID as string.
 *     animals: array of pigs [{
 *         RFID: rfid number,
 *         AnmNo1: animal number
 *         AnmID: animal ID,
 *         feeding:{
 *             curveNr - curve number 0 - 15
 *             offset
 *             percentageCorrection
 *             startTime
 *             punishment
 *         }
 *     }]
 * }
 * @param onSuccess
 * @param onFailure
 * @param hideNotification
 * @return {string}
 */
export function setAnimalParturition(dev, data = [], { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_ANIMAL_MODIFICATION, data, showNotification ? {
            loading: {
                title: i18next.t("menu.parturition"),
                message: i18next.t("IOT.setParturitionText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setParturitionSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setParturitionError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    }

}

export function setKeepNRFInBoot(dev, state) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, GatawayCommandTypes.SET_KEEP_NRF_IN_BOOT, { state }, {
            loading: {
                title: i18next.t("IOT.setKeepNRFInBoot"),
                message: i18next.t("IOT.setKeepNRFInBootText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setKeepNRFInBootSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setKeepNRFInBootFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        });
    }

}

/**
 *
 * @param dev
 * @param data
 * @param data.scheduleNumber {number} - numer harmonogramu (nie index!!!)
 * @param onSuccess
 * @param onFailure
 * @param showNotification
 * @return {string}
 */
export function setSensorSchedule(dev, data = {}, { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SENSOR_SCHEDULE, data, showNotification ? {
            loading: {
                title: i18next.t("IOT.setSensorSchedule"),
                message: i18next.t("IOT.setSensorScheduleText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSensorScheduleSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSensorScheduleError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }

}

export function setSensorsLock(dev, { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_LOCK, { isLocked: true }, showNotification ? {
            loading: {
                title: i18next.t("IOT.setSensorsLock"),
                message: i18next.t("IOT.setSensorsLockText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSensorsLockSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSensorsLockError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function setSensorsUnlock(dev, { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_LOCK, { isLocked: false }, showNotification ? {
            loading: {
                title: i18next.t("IOT.setSensorsUnlock"),
                message: i18next.t("IOT.setSensorsUnlockText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSensorsUnlockSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSensorsUnlockError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

/**
 *
 * @param dev
 * @param data
 * @param data.sensor1Negation {boolean}
 * @param data.sensor2Negation {boolean}
 * @param data.dose {integer}
 * @param onSuccess
 * @param onFailure
 * @param showNotification
 * @return {string}
 */
export function setSensorConfiguration(dev, data = {}, { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SENSOR_CONFIGURATION, data, showNotification ? {
            loading: {
                title: i18next.t("IOT.setSensorConfiguration"),
                message: i18next.t("IOT.setSensorConfigurationText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSensorConfigurationSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSensorConfigurationError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }

}

export function getSerialNumber(dev, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.GET_SERIAL_NUMBER, null, null, onSuccess, onFailure);
    }
}

export function setSwapSubAddresses(dev, from, to, { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SWAP_SUBADDRESSES, {
            subaddress1: from,
            subaddress2: to
        }, showNotification ? {
            loading: {
                title: i18next.t("IOT.setSwapSubAddresses"),
                message: i18next.t("IOT.setSwapSubAddressesText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSwapSubAddressesSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSwapSubAddressesError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure();
    }

}

export function setRFIDSensorConfiguration(dev, data, { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_RFID_SENSOR_CONFIGURATION, data, showNotification ? {
            loading: {
                title: i18next.t("IOT.setRFIDSensorConfiguration"),
                message: i18next.t("IOT.setRFIDSensorConfigurationText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setRFIDSensorConfigurationSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setRFIDSensorConfigurationError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function setSerialNumber(dev, params, onSuccess, onFailure) {
    console.log(dev);
    const { ClientID, LocalUserID, DevID, isValid, GatewayID } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SERIAL_NUMBER, params, null, onSuccess, onFailure);
    }
}


export function lightLED(dev, { blink = 0, colour = 0, time = Milliseconds.MINUTE } = {}, {
    onSuccess,
    onFailure,
    showNotification = true
} = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_LED_COLOUR, {
            time,
            colour,
            blink
        }, showNotification ? (time === 0 || !colour) ? {
            loading: {
                title: i18next.t("IOT.turnOffLED"),
                message: i18next.t("IOT.turnOffLEDText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.turnOffLEDSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("turnOffLEDError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            }
        } : {
            loading: {
                title: i18next.t("IOT.setLightLED"),
                message: i18next.t("IOT.setLightLEDText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setLightLEDSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setLightLEDError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error("No MQTT params"));
    }
}

export function turnOffLED(dev, { onSuccess, onFailure, showNotification = true } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_LED_COLOUR, {
            time: 0,
            colour: 0,
            blink: 0
        }, showNotification ? {
            loading: {
                title: i18next.t("IOT.turnOffLED"),
                message: i18next.t("IOT.turnOffLEDText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.turnOffLEDSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("turnOffLEDError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        } : null, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function setConfigScales(dev, tens_chs, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CONFIG_SCALES_V2, { tens_chs }, {
            loading: {
                title: i18next.t("IOT.setConfigScales"),
                message: i18next.t("IOT.setConfigScalesText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setConfigScalesSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setConfigScalesError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function setScaleManualScales(dev, scale, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SCALE_MANUAL_SCALES_V2, { scale }, {
            loading: {
                title: i18next.t("IOT.setScaleManualScales"),
                message: i18next.t("IOT.setScaleManualScalesText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setScaleManualScalesSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setScaleManualScalesError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function setAutoTareScales(dev, data, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_AUTO_TARE_PARAM_SCALES_V2, data, {
            loading: {
                title: i18next.t("IOT.setAutoTareScales"),
                message: i18next.t("IOT.setAutoTareScalesText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAutoTareScalesSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAutoTareScalesError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function setTareManualScales(dev, tout, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_TARE_MANUAL_SCALES_V2, { tout }, {
            loading: {
                title: i18next.t("IOT.setTareManualScales"),
                message: i18next.t("IOT.setTareManualScalesText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setTareManualScalesSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setTareManualScalesError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function setMSEAvgWidthScales(dev, data, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_MSE_AVG_WIDTH_SCALES_V2, data, {
            loading: {
                title: i18next.t("IOT.setMSEAvgWidthScales"),
                message: i18next.t("IOT.setMSEAvgWidthScalesText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setMSEAvgWidthScalesSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setMSEAvgWidthScalesError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function setPigExitUpInfo(dev, active, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_PIG_EXIT_UPINFO_SCALES_V2, { active }, {
            loading: {
                title: i18next.t("IOT.setPigExitUpInfo"),
                message: i18next.t("IOT.setPigExitUpInfoText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setPigExitUpInfoSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setPigExitUpInfoError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function clearBufferWithWeights(dev, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_CLEAR_BUFFER_WITH_MEASURMENTS_SCALES_V2, null, {
            loading: {
                title: i18next.t("IOT.clearBufferWithWeights"),
                message: i18next.t("IOT.clearBufferWithWeightsText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.clearBufferWithWeightsSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.clearBufferWithWeightsError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure();
    }
}

export function setAutoScaleWeight(dev, data, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_SCALE_WEIGHT_SCALES_V2, data, {
            loading: {
                title: i18next.t("IOT.setAutoScaleWeight"),
                message: i18next.t("IOT.setAutoScaleWeightText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setAutoScaleWeightSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setAutoScaleWeightError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    } else {
        if (isFunction(onFailure)) onFailure(new Error('No MQTT params'));
    }
}

export function getIPSUMPasses(dev, timestamp) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    return new Promise((resolve, reject) => {
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.GET_IPSUM_PASSES, {
                DevID,
                timestamp
            }, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getIPSUMDetails(dev, timestamp) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    return new Promise((resolve, reject) => {
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.GET_IPSUM_DETAILS_PASSES, {
                DevID,
                timestamp
            }, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function setWeightIPSUMPasses(dev, data) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    return new Promise((resolve, reject) => {
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.SET_WEIGHT_IPSUM_PASSES, {
                DevID,
                ...data
            }, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getIPSUMRFIDs(dev) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    return new Promise((resolve, reject) => {
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.GET_IPSUM_RFIDS, {
                DevID
            }, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function getIPSUMRFIDInfo(dev, RFID, offset = 0) {
    const { ClientID, LocalUserID, GatewayID, DevID, isValid } = getIOTAttributes(dev);
    return new Promise((resolve, reject) => {
        if (isValid) {
            return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, GatewayID, DispenserNRFCommandTypes.GET_IPSUM_RFID_INFO, {
                DevID,
                RFID,
                offset
            }, null, resolve, reject);
        } else {
            reject(new Error('IoT attributes not valid'));
        }
    });
}

export function startSecondaryMotor(dev, { time }, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_START_IPSUM_MOTOR_2_V2, { time }, {
            loading: {
                title: i18next.t("motor"),
                message: i18next.t("IOT.startMotor", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.startMotorDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.startMotorFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure);
    }
    else {
        onFailure(new Error('No MQTT params'))
    }

}

export function stopSecondaryMotor(dev, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_STOP_IPSUM_MOTOR_2_V2, null, {
            loading: {
                title: i18next.t("motor"),
                message: i18next.t("IOT.stopMotor", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.stoppedTheMotorOn", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.coultNotStopTheEngineAt", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure, undefined);
    } else if (onFailure) {
        onFailure(new Error('No MQTT params'));
    }
}

export function setIpsumCurveV2(dev, onSuccess, onFailure) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_IPSUM_CURVE_V2, null, {
            loading: {
                title: i18next.t("IOT.setIpsumCurveV2Title"),
                message: i18next.t("IOT.setIpsumCurveV2", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setIpsumCurveV2Done", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setIpsumCurveV2Failed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure, undefined);
    } else if (onFailure) {
        onFailure("missing iot attributed");
    }
}

export function setIpsumCurveStartV2(dev, { curveNumber, startTime }, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_IPSUM_CURVE_START_V2, {
            curveNumber,
            startTime
        }, {
            loading: {
                title: i18next.t("IOT.setIpsumCurveStartV2Title"),
                message: i18next.t("IOT.setIpsumCurveStartV2", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setIpsumCurveStartV2Done", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setIpsumCurveStartV2Failed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure, undefined);
    } else if (onFailure) {
        onFailure(new Error('No MQTT params'));
    }
}

export function setIpsumScheduleV2(dev, { scheduleNumber }, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_IPSUM_SCHEDULE_V2, { scheduleNumber }, {
            loading: {
                title: i18next.t("IOT.setSensorSchedule"),
                message: i18next.t("IOT.setSensorScheduleText", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setSensorScheduleSuccess", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setSensorScheduleError", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure, undefined);
    } else if (onFailure) {
        onFailure(new Error('No MQTT params'));
    }
}

export function setMinimalWorkTypeIpsumV2(dev, { minimalWorkTime1Percent, minimalWorkTime50Percent, breakTime }, { onSuccess, onFailure } = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_MINIMAL_WORK_TIME_IPSUM_V2, { minimalWorkTime1Percent, minimalWorkTime50Percent, breakTime }, {
            loading: {
                title: i18next.t("configuration"),
                message: i18next.t("IOT.setMotorsConfiguration", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setMotorsConfigurationDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setMotorsConfigurationFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure, undefined);
    } else if (onFailure) {
        onFailure(new Error('No MQTT params'));
    }
}


export function setConfigurationIpsumV2(dev, { sensor1Negation, sensor2Negation, sensor3Negation, dose }, {
    onSuccess,
    onFailure
} = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_IPSUM_CONFIGURATION_V2, {
            sensor1Negation,
            sensor2Negation,
            sensor3Negation,
            dose
        }, {
            loading: {
                title: i18next.t("IOT.setConfigurationIpsumV2Title"),
                message: i18next.t("IOT.setConfigurationIpsumV2", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setConfigurationIpsumV2Done", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setConfigurationIpsumV2Failed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure, undefined);
    } else if (onFailure) {
        onFailure(new Error('No MQTT params'));
    }
}

export function setIPSUMMaximumContinuousDoseAlarm(dev, { maxDose }, {
    onSuccess,
    onFailure
} = {}) {
    const { ClientID, LocalUserID, GatewayID, DevID, Name, isValid } = getIOTAttributes(dev);
    if (isValid) {
        return NewIOT.createAndSendMessageObject(ClientID, GatewayID, LocalUserID, DevID, DispenserNRFCommandTypes.SET_IPSUM_MAXIMUM_CONTINUOUS_DOSE_ALARM_V2, {
            maxDose
        }, {
            loading: {
                title: i18next.t("maximumSensorDoseContinuosAlarm"),
                message: i18next.t("IOT.setIPSUMMaximumContinuousDoseAlarm", { name: Name }),
                status: 'loading',
                dismissible: false,
                dismissAfter: 0
            },
            success: {
                message: i18next.t("IOT.setIPSUMMaximumContinuousDoseAlarmDone", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "success"
            },
            error: {
                message: i18next.t("IOT.setIPSUMMaximumContinuousDoseAlarmFailed", { name: Name }),
                dismissible: true,
                dismissAfter: 3000,
                status: "error"
            },
            DevID: DevID
        }, onSuccess, onFailure, undefined);
    } else if (onFailure) {
        onFailure(new Error('No MQTT params'));
    }
}