import React, {Component} from 'react';
import ReactDOM from "react-dom";
import "./_print.scss";
import Logo from "../../../resources/images/logos/fetura_cloud_colour.svg";
import QRCode from "qrcode";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

function mapStateToProps(state) {
    return {
        animal: state.animalDocuments.selectedItem
    }
}

class PrintableCard extends Component {

    state = {
        printing: false
    }
    pageContent = React.createRef();
    plannedParturition = React.createRef();
    qr = React.createRef();

    async printCard() {
        const {animal} = this.props;
        const information = document.getElementById("info-box");
        const plannedParturition = document.getElementById("parturition-date");
        const sowCard = document.getElementsByClassName("sow-card")[0];
        const sowResults = document.getElementsByClassName("sow-results")[0];
        this.pageContent.current.innerHTML = `<div class="basic-info-box">${information?.innerHTML}</div><div class="sow-card">${sowCard?.innerHTML}</div><div class="d-flex justify-content-center sow-results-container"><div class="sow-results row">${sowResults?.innerHTML}</div></div>`;
        
        if (plannedParturition?.innerHTML === "-") {
            this.plannedParturition.current.classList.add("empty-parturition");
        } else {
            this.plannedParturition.current.innerHTML = plannedParturition?.innerHTML;
        }
        this.qr.current.onload = () => {
            window.print();
            this.setState({
                printing: false
            })
            this.changeGauges();
            document.body.classList.toggle("sow-card-page");
        }
        this.qr.current.src = await QRCode.toDataURL(`ID: ${animal.AnmID}`, {width: 12 * 6});
    }
    changeGauges = () => {
        const sowResults = document.getElementById("sow-results");
        if (sowResults) {
            let sowResultsGaugeMain = sowResults.getElementsByClassName("gauge-main")[0];
            sowResultsGaugeMain.classList.toggle("col-12");
            sowResultsGaugeMain.classList.toggle("col-2");

            let sowResultsGaugeDetails = sowResults.getElementsByClassName("gauge-details")[0];
            sowResultsGaugeDetails.classList.toggle("col-12");
            sowResultsGaugeDetails.classList.toggle("col-10");

            let sowResultsGaugeDetailsValues = sowResultsGaugeDetails?.getElementsByClassName("col-xl-3");

            for (let div of sowResultsGaugeDetailsValues) {
                div.classList.toggle("col-6");
                div.classList.toggle("col-3");
            }

            let selected = sowResults.getElementsByClassName("selected");
            for (let div of selected) {
                div.classList.toggle("selected");
            }
        }
    }
    print = () => {
        document.body.classList.toggle("sow-card-page");
        this.changeGauges();
        this.setState({
            printing: true
        }, () => {
            setTimeout(() => {
                this.printCard();
            }, 300);
        })
    }

    render() {
        const {children, animal, t} = this.props;
        const {printing} = this.state;
        return (
            <>
                {children({print: this.print, printing})}
                {
                    ReactDOM.createPortal((
                        <div className="printable">
                            <div className="sow-card-print">
                                <table>
                                    <thead>
                                    <tr>
                                        <td>
                                            <div className="table-header">
                                                <img src={Logo} alt={"logo"} className="logo"/>
                                                <div>
                                                    <div>
                                                        <h2 className="justify-content-end">{t("animalKind.0")} {animal?.AnmNo1}</h2>
                                                        <h4>{t("animalDocuments.plannedBirth")}: <span
                                                            ref={this.plannedParturition}
                                                            className="ms-2"/></h4>
                                                    </div>
                                                    <div className="qr-container">
                                                        <img src={null} alt={"qr"} ref={this.qr}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td ref={this.pageContent}/>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ), document.getElementById("root"))
                }
            </>
        )
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(PrintableCard);
