function wrapPromise(promise) {
    let status = 'pending';
    let response;

    const suspender = promise.then(
        (res) => {
            status = 'success';
            response = res;
        },
        (err) => {
            status = 'error';
            response = err;
        },
    );

    const read = () => {
        switch (status) {
            case 'pending':
                throw suspender;
            case 'error':
                throw response;
            default:
                return response;
        }
    };

    return { read };
}

/**
 * Funkcja, ktora opakowuje zapytania, tak, aby suspense był wyświetlany
 * @param promise
 * @returns {{read: ((function(): *)|*)}}
 */
export default function fetchData(promise) {
    return wrapPromise(promise);
}
