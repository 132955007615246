import {isArray} from "lodash";

// jesli chcemy zmienic główny klucz to wystarczy zmienić tutaj i będzie działało z selectorem, akcjami itd;
export const UniqAnimalKey = "RFID";

export const initialValue = {
    fetching: {},
    data: {}
};
export default function rfidHistoryReducer(state = initialValue, {
    type,
    meta: {[UniqAnimalKey]: AnimalKey} = {},
    payload
} = {}) {
    switch (type) {
        case "GET_PIG_HISTORY_PENDING": {
            const fetching = {...state.fetching};
            fetching[AnimalKey] = true;
            return {
                ...state,
                fetching

            }
        }
        case "GET_PIG_HISTORY_FULFILLED": {
            const fetching = {...state.fetching};
            delete fetching[AnimalKey];
            const data = [...(state.data[AnimalKey] || [])];
            for (let {status, value} of payload) {
                if (status === "fulfilled") {
                    if (isArray(value)) {
                        for (let historyRow of value) {
                            const index = data.findIndex(({time}) => historyRow.time === time);
                            if (index === -1) {
                                // nie mamy pushujemy
                                data.push(historyRow);
                            } else {
                                if ((data[index].feed < historyRow.feed) || (data[index].additionalFeed < historyRow.additionalFeed)) {
                                    data[index] = historyRow;
                                }
                            }
                        }
                    }
                }
            }
            data.sort((o1, o2) => o1.time - o2.time)
            return {
                ...state,
                data: {...state.data, [AnimalKey]: data},
                fetching

            }
        }
        case "GET_PIG_HISTORY_REJECTED": {
            const fetching = {...state.fetching};
            delete fetching[AnimalKey];
            return {
                ...state,
                fetching

            }
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
