class AnimationManager {
    constructor() {
        this.animationState = {};
    }

    _randomizer() {
        return {
            angle: Math.floor(Math.random() * 360)
        }
    }

    _get(entityId) {
        return this.animationState[entityId];
    }

    _set(entityId, params) {
        this.animationState[entityId] = {
            ...(this.animationState[entityId] || {}),
            ...params,
            _time: Date.now()
        }
        return this._get(entityId);
    }

    Update(entityId) {
        return this._get(entityId) || this._setRandom(entityId);
    }

    _setRandom(entityId) {
        return this._set(entityId, this._randomizer());
    }


    Clear() {
        this.animationState = {};
    }

}

export default AnimationManager;
