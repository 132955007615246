import React from "react";
import {compose} from "redux";
import {connectModal} from "redux-modal";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import {Field, reduxForm} from "redux-form";
import {submit, validate} from "./EditSubgroupDataModalSubmit";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ModalFooter from "../ModalFooter";
import {connect} from "react-redux";
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import {getManageSubgroups} from "../../../utils/SettingsUtils";
import {formatLocationName} from "../../../utils/global-formatters/formatLocationName";
import {makeGetBuildingsMap} from "../../../selectors/buildingsSelector";
import moment from "moment";
import {isService} from "../../../selectors/userSelector";

export const ModalName = "edit-subgroup-data-modal";

function makeMapStateToProps() {
    const getBuildingsMap = makeGetBuildingsMap();
    return (state) => ({
        buildingsMap: getBuildingsMap(state),
        isService: isService(state)
    })
}

class EditSubgroupDataModal extends React.Component {

    constructor(props) {
        super(props);
        const {initialize, animal} = this.props;
        initialize({
            AnmCnt: animal?.AnmCnt,
            DtaBrthTime: animal?.DtaBrthTime
        });
    }

    parseAnmCnt = value => value !== "" ? +value : null;

    isSingleAnimal = animal => animal?.AnmCnt === 1 && animal?.RFID;

    render() {
        const {handleHide, show, handleSubmit, t, dirty, submitting, animal, buildingsMap} = this.props;
        if (!animal) return null;
        const shouldShowAnimalLocation = [AnimalTypes.PORKER, AnimalTypes.PIGLET].includes(animal?.AnimalKind) && !getManageSubgroups();
        const isSingleAnimal = this.isSingleAnimal(animal);
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={t("modals.editBasicsAnimal.title", {
                        type: shouldShowAnimalLocation ? formatLocationName(animal.PlcmntID, {
                            buildingsMap,
                            nameDeep: 2
                        }) : animal.AnmNo1
                    })} onCloseClick={handleHide}/>
                    <ModalBody>
                        {
                            isSingleAnimal &&
                            <Field
                                label={t("birthDate")}
                                component={ReduxLabeledInput}
                                name="DtaBrthTime"
                                type={"date"}
                                format={value => value ? moment(value).format("YYYY-MM-DD") : null}
                                parse={value => value ? +moment(value, "YYYY-MM-DD") : null}
                            />
                        }
                        {
                            !isSingleAnimal &&
                            <Field
                                name="AnmCnt"
                                component={ReduxLabeledInput}
                                label={t("animalCount")}
                                type="number"
                                parse={this.parseAnmCnt}
                            />
                        }
                    </ModalBody>
                    <ModalFooter hasConfirmButton confirmText={t("save")} hasButtonDisabled={!dirty}
                                 onCloseClick={handleHide} formName={ModalName} submitting={submitting}/>
                </form>
            </Modal>
        );
    }

}

export default compose(
    connectModal({name: ModalName}),
    withTranslation(NameSpaces.TRANSLATION),
    connect(makeMapStateToProps),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(EditSubgroupDataModal);
