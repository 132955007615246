import React, {useCallback, useState} from "react";
import CollapsableContainer from "../containers/CollapsableContainer";
import "../../basics/json-to-table/_json-to-table.scss";
import useData from "./useData";
import ButtonGroup from "../../basics/button/button-group/ButtonGroup";
import Button from "../../basics/button/Button";
import ChangesTable from "./ChangesTable";
import ChangesTimeline from "./ChangesTimeline";
import LoadingComponent from "../../loading/LoadingComponent";
import InfoBox from "../../basics/info-box/InfoBox";
import {useTranslation} from "react-i18next";
import {checkIfUserHasPrivilegedAccessSelector} from "../../../selectors/userSelector";
import {useSelector} from "react-redux";
import {getSelectedAnimalIDs} from "../../../selectors/animalDocumentsSelectors";

/**
 * domyślnie `animalIds` jest brane jako wybrany element na dokumencie zwierząt, ale przekazując `animalIds` możemy go nadpisać
 * @param animalIds {null|array<string>}
 * @return {JSX.Element|null}
 */
export default ({animalIds}) => {

    const {t} = useTranslation();

    const hasAccess = useSelector(checkIfUserHasPrivilegedAccessSelector);

    const defaultAnimalIds = useSelector(getSelectedAnimalIDs);

    if (!hasAccess || defaultAnimalIds.length === 0) return null;

///////////////////
////////////////////////////////////////////////////////////
//////////////

    return (
        <CollapsableContainer.Card
            mountOnEnter
            id="cy-modification-list"
            header={t("modificationList")}
            defaultExpanded={false}>
            <Body animalIds={animalIds || defaultAnimalIds}/>
        </CollapsableContainer.Card>
    )
}

const Body = ({animalIds}) => {

    const [frontType, setFrontType] = useState("table");

    const handleOnClick = useCallback((e) => {
        setFrontType(e.target.id);
    }, [setFrontType]);

    const {data, loading, error} = useData(animalIds);

    const isGroup = animalIds.length > 1;


    const {t} = useTranslation();

    return (
        <>
            <ButtonGroup>
                <Button
                    selected={frontType === "timeline"}
                    type={"button"}
                    buttonStyle={"bordered"}
                    icon={<i className={"fas fa-timeline-arrow"}/>}
                    id={"timeline"}
                    onClick={handleOnClick}/>
                <Button
                    selected={frontType === "table"}
                    type={"button"}
                    buttonStyle={"bordered"}
                    icon={<i className={"fas fa-table"}/>}
                    id={"table"}
                    onClick={handleOnClick}/>
            </ButtonGroup>
            <LoadingComponent isLoading={loading}/>
            {
                !!error &&
                <InfoBox boxColor={"error"} outlined={true}>
                    {t("newSettings.devices.settingsInfo.noData")}
                </InfoBox>
            }
            {
                frontType === "table" &&
                <ChangesTable isGroup={isGroup} data={data}/>
            }
            {
                frontType === "timeline" &&
                <ChangesTimeline isGroup={isGroup} data={data}/>
            }

        </>
    )
}

