import {useCallback, useState} from "react";
import {ReactLocalStorage} from "../utils/ReactLocalStorage";

export default function useLocalStorage(key, defaultValue = null, isPrivate = false) {

    const [localStorageValue, setLocalStorageValue] = useState(() => {
        if (isPrivate) return ReactLocalStorage.getPrivate(key, defaultValue);
        return ReactLocalStorage.get(key, defaultValue);
    })

    const setValue = useCallback((value) => {
        if (isPrivate) {
            ReactLocalStorage.setPrivate(key, value);
        } else {
            ReactLocalStorage.set(key, value);
        }
        setLocalStorageValue(value);
    }, [key, isPrivate]);

    return [localStorageValue, setValue];

}
