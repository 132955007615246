
/**
 * Główna klasa
 */
class Bean {

    constructor(object) {
        if (object) {
            Object.assign(this, object);
        } else {
            throw new Error('Passed empty element to Bean constructor');
        }
    }

    /**
     * Przygotowuje obiekt do zapisu usuwając zbędne informacje
     * @return {*}
     */
    prepareBeanToSave() {
        let clone = this.clone();
        delete clone.$loki;
        return clone;
    }
}

export default Bean;