import React from "react";
import PropTypes from "prop-types"
import {get, isArray, isEqual, isFunction, isNumber, isString} from "lodash"
import TableItemTitle from "./TableItemTitle";

class TableItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false
        };
        this.itemRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    getValue = () => {
        const {header: {field, valueFormatter}, object, isPinned} = this.props;
        let value = JSON.parse(JSON.stringify(object)); // tworzy sie referencja, przez co psuje sie formatowanie
        if (field) {
            value = get(object, field, "");
        }
        if (valueFormatter && !isPinned) {
            value = valueFormatter(value);
        }
        return value;
    };

    renderComponent = () => {
        const value = this.getValue();
        const {header: {component, field}, index, page, paginationItems, isPinned, object = {}} = this.props;
        if (!component) {
            // isPinned jest uzywany na ten moment jedynie w raportach jako podsumowanie, jesli bedzie
            // jeszcze gdzie indziej to mozna sie zastanowic nad innym propsem
            if (isPinned) return <strong>{value}</strong>;
            return isArray(value) ? value.join(", ") : value;
        }
        if (isFunction(component)) {
            return component({index, value, page, paginationItems, object, field}, this.props);
        } else {
            return React.cloneElement(component, {index, value, page, paginationItems, object, field})
        }
    };

    getTitle = () => {
        const {header: {component}} = this.props;
        if (component) return null;
        let value = this.getValue();
        return typeof value === "object" ? null : value;
    };

    getClassName() {
        const {header: {itemClassName, colWidth, rtl}, object} = this.props;
        const cName = isFunction(itemClassName) ? itemClassName({object}) : itemClassName;
        const className = ["table-item", cName];
        rtl && (className.push("rtl"));
        colWidth && (className.push(`col-width-${colWidth}`));
        return className.filter(o => o).join(" ");
    }

    handleEnter = (showPortal) => {
        const {hover} = this.state;
        console.log(hover, this.isOverflown(this.itemRef.current));
        if (!hover && showPortal && this.isOverflown(this.itemRef.current)) {
            this.setState({
                hover: true
            })
        }
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    handleLeave = (showPortal, time = 2500) => {
        const {hover} = this.state;
        if (hover && showPortal) {
            this.timeout = setTimeout(() => {
                this.setState({
                    hover: false
                })
            }, time)
        }
    };

    /**
     * Sprawdzenie czy tableItem nie jest za duży na miejsce jakie mu przydzielono
     * @param clientWidth
     * @param clientHeight
     * @param scrollWidth
     * @param scrollHeight
     * @return {boolean}
     */
    isOverflown = ({clientWidth, clientHeight, scrollWidth, scrollHeight}) => {
        const {header: {component}} = this.props;
        if (component) return true;
        return scrollHeight > clientHeight || scrollWidth > clientWidth;
    };

    render() {
        const component = this.renderComponent();
        const {header: {overflowInPortal}, mobile, isClickable, onRowClick} = this.props;
        const title = this.getTitle();
        const className = this.getClassName();
        const {hover} = this.state;
        //div nad dziala tylko na mobilkach na desktopie mamy dzialajacy title
        //                  mobile          czy jest prymitywem             jesli mamy customowy komponent wyswietlany to trzeba przekazac propsa overflowInPortal
        const showPortal = mobile && (isString(component) || isNumber(component)) || overflowInPortal;
        return (
            <>
                <div ref={this.itemRef}
                     onClick={isClickable ? onRowClick : null}
                     onTouchEnd={() => this.handleLeave(showPortal)}
                     onTouchCancel={() => this.handleLeave(showPortal, 1000)}
                     onTouchMove={() => this.handleLeave(showPortal, 0)}
                     onTouchStart={() => this.handleEnter(showPortal)} className={className}
                     title={title}>
                    {component}
                </div>
                <TableItemTitle show={hover} className={className} parent={this.itemRef.current}>
                    {component}
                </TableItemTitle>
            </>
        );
    }

}

export default React.memo(TableItem);

TableItem.propTypes = {
    header: PropTypes.shape({
        name: PropTypes.node.isRequired,
        field: PropTypes.string,
        valueFormatter: PropTypes.func,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
        itemClassName: PropTypes.string,
        overflowInPortal: PropTypes.bool
    }).isRequired,
    object: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
    index: PropTypes.number,
    page: PropTypes.number,
    paginationItems: PropTypes.number,
    isPinned: PropTypes.bool
};
