export const initialState = {
    // results tab
    resultsGridData: [],
    loadingResultsGrid: false,
    forceLoading: false,
    // sow results
    sowsResultsData: [],
    loadingSowsResults: false,
    // single result parameter
    parameterGridData: [],
    loadingParameterGridData: false
};

export default function gridReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_RESULTS_GRID_DATA_PENDING":
            return {...state, resultsGridData: [], loadingResultsGrid: true, forceLoading: action.meta.forceLoading};
        case "SET_RESULTS_GRID_DATA_FULFILLED":
            return {...state, resultsGridData: action.payload, loadingResultsGrid: false, forceLoading: false};
        case "SET_RESULTS_GRID_DATA_REJECTED":
            return {...state, resultsGridData: [], loadingResultsGrid: false, forceLoading: false};
        case "SET_SOWS_RESULTS_PENDING":
            return {...state, loadingSowsResults: true};
        case "SET_SOWS_RESULTS_FULFILLED":
            return {...state, loadingSowsResults: false, sowsResultsData: action.payload};
        case "SET_SOWS_RESULTS_REJECTED":
            return {...state, loadingSowsResults: false, sowsResultsData: []};
        case "SET_RESULTS_SINGLE_PARAMETER_DATA_PENDING":
            return {...state, parameterGridData: [], loadingParameterGridData: true};
        case "SET_RESULTS_SINGLE_PARAMETER_DATA_FULFILLED":
            return {...state, parameterGridData: action.payload, loadingParameterGridData: false};
        case "SET_RESULTS_SINGLE_PARAMETER_DATA_REJECTED":
            return {...state, parameterGridData: [], loadingParameterGridData: false};
        case "CLEAN_RESULTS_SINGLE_PARAMETER":
            return {...state, parameterGridData: [], loadingParameterGridData: false};
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialState;
        }
        default:
            return state;
    }
}
