const initialValue = {
    sales: [],
    lastModificationTime: 0
};

export default function salesReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_SALES":
            return { ...state, sales: action.payload, lastModificationTime: action.meta.DtaModTime || state.lastModificationTime };
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state;
    }
}
