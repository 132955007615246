import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import { cloneDeep, flatten, get, isArray, isEmpty, isNil, set, uniqBy } from "lodash";
import moment from "moment";
import { notify } from "reapop";
import { getFeedState, getFeedStateRFID } from "../../actions/feedingActions";
import { CurveDayShowingType, CurveType, FeedingDoseCorrection } from "../../constans/feedingTypes";
import { Milliseconds } from "../../constans/milliseconds";
import {
    DispenserDriverCommandTypes,
    DispenserNRFCommandTypes,
    GatawayCommandTypes,
    MessageTypes
} from "../../constans/mqttMessages";
import { SectorType } from "../../constans/sectorTypes";
import { SettingTypes } from "../../constans/settingTypes";
import buildingsDB from "../../database/buildingsDB";
import settingsDB from "../../database/settingsDB";
import { myID } from "../../libs/generateID";
import store from "../../store/store";
import { hasFlowMeter, hasWater } from "../../utils/DispenserNRFUtils";
import { getCurveDayShowingType } from "../../utils/FeedingUtils";

const DEBUG = true;
const savedFeedingDataForPig = {};

const _wstCache = {};
const checkIfIsWST = (DevID) => {
    if (_wstCache[DevID] !== undefined) return _wstCache[DevID];
    const devices = store.getState().farmDevices.devices;
    const device = devices.find(d => d.DevID === DevID);
    if (device) {
        _wstCache[DevID] = device.DevType === DevTypes.DISPENSER;
    }
    return !!_wstCache[DevID];
}

export function fakeStartSendingFeedingState(devIds) {
    setTimeout(() => {
        const state = store.getState();
        let sectorType;
        try {
            const chamberId = state.feeding.selectedChamber;
            if (chamberId) {
                sectorType = buildingsDB.getSectorTypeByChamberID(chamberId);
            }
        } catch (e) {
            console.error(e)
        }
        try {
            const data = new Map();
            const chamberId = Object.keys(state.feeding.chamber)[0];
            devIds.forEach((devId, i) => {
                const isWST = checkIfIsWST(devId);
                if (isWST) {
                    for (let j = 0; j < 20; ++j) {
                        data.set(`${devId}_${j}`, cloneDeep(fakeShadow(i, get(Object.values(state.feeding.chamber), "[0].individualFeeding"), sectorType, chamberId === "2klYliZYjjim6Urh6-vq", ["ViB6oUVrN8zetnqDtt-a", "lxDKCzCAGf7UYFk5Pxg8", "gLX4YgxuKtcsvBHrtxXc", "rG9EgwvVSHZEAcIxSaT5", "XkemghSy9xy1sVBRVYIP"].includes(chamberId) ? [4, 5] : [14, 14])));

                    }
                } else {
                    data.set(devId, cloneDeep(fakeShadow(i, get(Object.values(state.feeding.chamber), "[0].individualFeeding"), sectorType, chamberId === "2klYliZYjjim6Urh6-vq", ["ViB6oUVrN8zetnqDtt-a", "lxDKCzCAGf7UYFk5Pxg8", "gLX4YgxuKtcsvBHrtxXc", "rG9EgwvVSHZEAcIxSaT5", "XkemghSy9xy1sVBRVYIP"].includes(chamberId) ? [4, 5] : [14, 14])));
                }

            });
            store.dispatch(getFeedState(data));
            refresh();
        } catch (e) {
            console.error(e);
        }
    }, 900);
}

export function startSendingFeedingStateRFID(gatewayId, CID) {
    setTimeout(() => {
        try {
            const data = new Map();
            const rfids = Object.values(get((store.getState().feeding.feeding), `[${CID}].data`, {})).map((o, i) => ({
                ...o,
                ...fakeShadow(i, false, undefined, undefined, [45, 45])
            }))
            DEBUG && console.log(rfids, "RFIDS")
            // data.set(CID, ;
            const tmp = {};
            rfids.forEach(d => tmp[d.id] = d);
            data.set(CID, tmp)
            store.dispatch(getFeedStateRFID(data));
        } catch (e) {
            console.error(e);
        }
    }, 900);
}

export const fakeTransfer = (id) => {
    DEBUG && console.log("fakeTransfer called", id)
    try {
        const map = getMaps();
        const feeding = store.getState().feeding;
        for (let chamberId of Object.keys(feeding.feeding)) {
            const f = feeding.feeding[chamberId];
            const setFeeding = !!((window.globalThis.__assignAnimals || {})[id]);
            if (f) {
                let delta = cloneDeep(f.data[id]);
                if (delta) {
                    const tmp = new Map();
                    const feedParams = get(delta, "animal.feedParam") || {};
                    const hasAnimal = !!get(delta, "animal");
                    DEBUG && console.log(feedParams, delta, "<->", hasAnimal, setFeeding)
                    if (!hasAnimal || setFeeding) {
                        delta = setFeedingConfig(map, delta, {
                            number: feedParams.curveNr || 0,
                            start: feedParams.startTime || 0,
                            eventStage: feedParams.stage || 0,
                            punishment: feedParams.punishment || 0,
                            correction: feedParams.percentCorrection || 0
                        });
                        delta.feeding = !!feedParams.curveNr;
                    }
                    if (delta.receiver) {
                        tmp.set(isNil(delta.receiver.index) ? `${delta.receiver.deviceId}` : `${delta.receiver.deviceId}_${delta.receiver.index}`, delta)
                        store.dispatch(getFeedState(tmp));
                    } else {
                        tmp.set(id, delta);
                        store.dispatch(getFeedStateRFID(tmp));
                    }
                }
            }
        }
    } catch (e) {
        console.error(e);
    }
}

export function refresh() {
    DEBUG && console.log("refresh called")
    try {
        const map = getMaps();
        const feeding = store.getState().feeding;
        let tmp = new Map();
        for (let chamberId of Object.keys(feeding.feeding)) {
            const f = feeding.feeding[chamberId];
            if (f) {
                Object.keys(f.data).forEach(k => {
                    let newData = cloneDeep(f.data[k]);
                    if (newData.receiver) {
                        if (hasWater(newData.device)) {
                            newData.waterDoseType = +hasFlowMeter(newData.device);
                            newData.waterEfficiency = 1000;
                        }
                        let lastTime = newData.lastSeen.time;
                        newData = {
                            ...newData,
                            ...getExpectedFeed(map, newData.curve.number, newData.curve.day, newData.curve.correction, newData.feed.usage, newData)
                        };
                        newData.running = false;
                        if (newData._extraFeed) {
                            const givenExtra = newData.additionalFeeding.reduce((a, b) => a + b.dose, 0);
                            if (givenExtra < newData._extraFeed) {
                                newData.additionalFeeding.push({
                                    dose: newData._extraFeed - givenExtra >= 125 ? 125 : newData._extraFeed - givenExtra,
                                    success: 1,
                                    time: new Date()
                                });
                                lastTime = new Date();
                                newData.running = !!(newData._extraFeed - newData.additionalFeeding.reduce((a, b) => a + b.dose, 0));
                            }
                        }
                        if (newData.curve.number === 0) {
                            newData.feeding = false;
                        } else {
                            newData.feeding = true;
                        }
                        newData.oldest = +new Date();
                        newData.lastSeen.time = Math.max(lastTime, newData.lastSeen.time);
                        tmp.set(isNil(newData.receiver.index) ? `${newData.receiver.deviceId}` : `${newData.receiver.deviceId}_${newData.receiver.index}`, newData)
                    }
                })
            }
        }
        if (!isEmpty(tmp)) {
            store.dispatch(getFeedState(tmp));
        }
    } catch (e) {
        //
    }

}

function getMaps() {
    const result = {
        [SettingTypes.FEEDING_CURVE]: new Map(),
        [SettingTypes.FEEDING_SCHEDULE]: new Map(),
        [SettingTypes.FEEDING_FORAGE]: new Map()
    };
    const settings = settingsDB.getAllSettings(store.getState().location.farm).filter(o => !o.DtaDelTime);
    settings.forEach(set => {
        const setIdx = get(set, "SetData.Index");
        if ([SettingTypes.FEEDING_FORAGE, SettingTypes.FEEDING_SCHEDULE, SettingTypes.FEEDING_CURVE].includes(set.SetType) && !isNil(setIdx)) {
            result[set.SetType].set(setIdx, set);
        }
    });
    return result;
}

const lastWeekHistory = (plannedUsage = 3000, usage = 0, shadow) => {
    return [
        {
            plannedUsage,
            usage,
            date: +moment().startOf("day")
        },
        ...new Array(14).fill(0).map((o, i) => ({
            usage: get(shadow, `lastWeekHistory[${i + 1}].usage`, ((usage / plannedUsage) === 0 || (usage / plannedUsage) > 0.1) ? plannedUsage : usage * i),
            plannedUsage: get(shadow, `lastWeekHistory[${i + 1}].plannedUsage`, plannedUsage),
            date: +moment().startOf("day").subtract(i, "day")
        }))


    ]
}

function fakeShadow(rowIndex, isIndividual = false, sectorType = SectorType.SOWS, disableFeeding = false, curveDay = [14, 14]) {
    DEBUG && console.log("fakeShadow called", arguments)
    const map = getMaps();
    const curveArray = [...map[SettingTypes.FEEDING_CURVE].entries()];
    let index;
    const findCurve = (type, showingType = CurveDayShowingType.BOTH) => curveArray.find(([index, cur]) => cur.SetData.Type === type && getCurveDayShowingType(cur) === showingType)
    console.log(curveArray, { findCurve });
    switch (sectorType) {
        case SectorType.DELIVERY:
            index = get(findCurve(CurveType.PARTURITION), "[0]", curveArray.length ? curveArray[0][0] : 0)
            break;
        case SectorType.MATING:
            index = get(findCurve(CurveType.MATING), "[0]", curveArray.length ? curveArray[0][0] : 0)
            break;
        default:
            index = get(findCurve(CurveType.INDIVIDUAL, CurveDayShowingType.NORMAL), "[0]", curveArray.length ? curveArray[0][0] : 0)
            break;
    }
    const curve = map[SettingTypes.FEEDING_CURVE].get(index);
    DEBUG && console.log("curve index", index, curve);

    const doseCorrection = rowIndex < 3 ? [-5, -10, 5, 10, 15][Math.round(Math.random() * 4)] : 0;
    const curveNumber = disableFeeding ? 0 : get(curve, "SetData.Index", -1) + 1;
    const setCurveDayIfPossible = (min, max) => {
        const maxDays = get(curve, "SetData.Days.length", 1) - 1;
        const random = min + Math.round(Math.random() * (max - min));
        return Math.min(Math.max(0, random), maxDays);
    }
    const minusDay = rowIndex === 42 && sectorType === SectorType.DELIVERY ? 1 : 3;
    let day = disableFeeding ? 0 : Math.min(Math.max(get(curve, getCurveDayShowingType(curve) === CurveDayShowingType.BOTH ? "SetData.InseminationJumpTo" : "SetData.Days.length", 0) - minusDay, 0), curveDay[0])
    switch (sectorType) {
        case SectorType.SOWS:
            day = setCurveDayIfPossible(...curveDay);
            break;
        default:
            break;

    }
    const schedule = map[SettingTypes.FEEDING_SCHEDULE].get(get(curve, `SetData.Days[${day}].DailyPlan`, -1));
    const forage = map[SettingTypes.FEEDING_FORAGE].get(get(curve, `SetData.Days[${day}].ForageType`, -1));
    return {
        curve: {
            number: disableFeeding ? 0 : curveNumber,
            id: disableFeeding ? null : get(curve, "SetID"),
            day: day,
            punishment: 0,
            correction: disableFeeding ? 0 : doseCorrection,
            eventStage: 0
        },
        rfidSensor: {
            state: 1,
            time: +new Date() - 35000
        },
        antenna: {
            status: 1,
            lastResponseTime: +new Date() - 35000
        },
        efficiency: 1050,
        schedule: {
            number: disableFeeding ? 0 : get(schedule, "SetData.Index", -1) + 1,
            id: disableFeeding ? null : get(schedule, "SetID")
        },
        forage: {
            number: disableFeeding ? 0 : get(forage, "SetData.Index", -1) + 1,
            id: disableFeeding ? null : get(forage, "SetID")
        },
        skipDoses: [0, 0, 0, 0, 0, 0],
        holdOut: 0,
        loop: {
            insemination: 0,
            parturition: 0,
            endDay: 0
        },
        locked: false,
        alert: rowIndex === 4,
        feeding: true,
        errors: rowIndex === 4 ? [5206] : [],
        waterDoseType: 1,
        waterEfficiency: undefined,
        waterHistory: [],
        additionalFeeding: [],
        running: false,
        workType: isIndividual ? "H" : "R",
        oldest: +new Date() - 1000,
        lastSeen: {
            deviceId: "",
            time: 0
        },
        ...getExpectedFeed(map, curveNumber, day, doseCorrection, undefined, undefined, rowIndex < 2 ? [5, 45][rowIndex] : 100),
        //extra only for fake
        _extraFeed: 0,
        _isIndividual: !!isIndividual,
        _rfid: !!isIndividual

    }
}

function getExpectedFeed(map, curveNr = 0, day = 1, correction = 15, lastUsage, shadow, usagePercentage = 100) {
    DEBUG && console.log("getExpectedFeed called", arguments)
    const getUsage = (lastUsage, def) => isNil(lastUsage) ? def : lastUsage;
    if (!curveNr) {
        DEBUG && console.log("curve not found")
        return {
            feed: { usage: getUsage(lastUsage, 0), plannedUsage: 0 },
            lastWeekHistory: lastWeekHistory(0, getUsage(lastUsage, 0), shadow)
        };
    }
    const curve = map[SettingTypes.FEEDING_CURVE].get(curveNr - 1);
    const forageAmount = get(curve, `SetData.Days[${day - 1}].ForageAmount`, 3000);
    const scheduleNr = get(curve, `SetData.Days[${day - 1}].DailyPlan`, -1) + 1;
    DEBUG && console.log("curve", curve, forageAmount, scheduleNr, lastUsage, usagePercentage)
    if (scheduleNr === 0) {
        return {
            feed: { usage: getUsage(lastUsage, 1500), plannedUsage: forageAmount },
            lastWeekHistory: lastWeekHistory(forageAmount, getUsage(lastUsage, 1500), shadow)
        };
    } else {
        const minutesOfDay = (m) => m.minutes() + m.hours() * 60;
        const schedule = map[SettingTypes.FEEDING_SCHEDULE].get(scheduleNr - 1);
        const days = get(schedule, "SetData.Doses", []);
        let usage = 0;
        let lastSeen = 0;
        days.forEach(day => {
            if (minutesOfDay(moment(day.Start)) < minutesOfDay(moment())) {
                DEBUG && console.log("curve", curve, forageAmount, scheduleNr)
                const endTime = +moment().startOf("day").add(minutesOfDay(moment(day.End)), "minute");
                const oneHourAgo = +moment().startOf("day").add(minutesOfDay(moment()) - 60, "minute");
                const startTime = +moment().startOf("day").add(minutesOfDay(moment(day.Start)), "minute");
                const now = +moment();
                usage += ((day.Percent / 100) * forageAmount) || 0;
                lastSeen = (oneHourAgo >= startTime && oneHourAgo <= endTime) ? oneHourAgo : (
                    endTime <= now ? endTime : startTime
                )
                if (usage > forageAmount) {
                    console.error("!!!", usage, forageAmount, lastUsage, usagePercentage);
                }
            }
        });
        const _used = getUsage(lastUsage, ((usagePercentage / 100) * usage))
        return {
            feed: {
                usage: _used,
                plannedUsage: ((100 + correction) / 100) * forageAmount
            },
            lastSeen: { time: _used ? lastSeen : +new Date() - 7 * Milliseconds.DAY },
            lastWeekHistory: lastWeekHistory(((100 + correction) / 100) * forageAmount, _used, shadow)
        }
    }
}

export function fakeSubmitManageFeedingModal(values, dispatch, props) {

}

function getFeedShadow({ chamberId, rfid, deviceId }) {
    console.log("getFeedShadow called with", arguments)
    let result = {};
    const feeding = store.getState().feeding;
    if (deviceId) {
        chamberId = feeding.chamberDeviceDict[deviceId]
    }
    if (chamberId) {
        if (feeding.chamber[chamberId]) {
            result = feeding.feeding[chamberId].data[rfid ? rfid : feeding.chamber[chamberId].deviceToStanding[deviceId]] || {}
        }
    }
    return cloneDeep(result);

}

function getFeedShadowNew({ placementId, rfid }) {
    console.log("getFeedShadow called with", arguments)
    let result = {};
    const feeding = store.getState().feeding;
    if (feeding.feeding[placementId]) {
        //rfid
        result = feeding.feeding[placementId].data[rfid];
    } else {
        //stanowisko
        result = flatten(Object.values(feeding.feeding).map(o => o.data)).reduce((a, b) => ({ ...a, ...b }), {})[placementId]
    }
    return cloneDeep(result);

}

export function fakeCreateAndSendMessageObject(ClientID, GatewayID, LocalUserID, DeviceID, Command, data = undefined, notification = undefined, onSuccess = undefined, onError = undefined, onSend = undefined) {
    console.log("fakeCreateAndSendMessageObject called with", arguments)
    let id = myID();
    const dicts = getMaps();
    const message = {
        MsgId: id,
        PktType: MessageTypes.REQUEST,
        DeviceId: DeviceID,
        RTime: new Date().getTime(),
        Command: Array.isArray(Command) ? Command : [Command],
        CData: {},
        Priority: 69
    };
    if (data) {
        // tmp.CData = this.compressData(data);
        message.CData = data;
    }
    if (notification && notification.success) {
        store.dispatch(notify({
            ...notification.loading,
            ...notification.success
        }));
        console.log(notification);
    }
    if (onSuccess) {
        setTimeout(() => {
            onSuccess();
        }, 1500);
    }
    const cmd = message.Command[0];
    console.log(cmd, message);
    switch (cmd) {
        case DispenserNRFCommandTypes.SET_CONFIG_STANDARD: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({ deviceId: devId });
                delta = setFeedingConfig(dicts, delta, {
                    number: message.CData.curveNr,
                    start: message.CData.startTime,
                    offset: message.CData.offset,
                    punishment: message.CData.punishment,
                    correction: message.CData.percentCorrection,
                    eventStage: message.CData.stage || 0,
                });
                delta.feeding = !!message.CData.curveNr;
                console.log(delta);
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_ANIMAL_MODIFICATION: {
            let mapGroup = new Map();
            let mapIndividual = new Map();
            message.CData.forEach(({ RFID, PlcmntID, feeding, removed }) => {
                if (!removed) {
                    let delta = getFeedShadowNew({ rfid: RFID, placementId: PlcmntID });
                    delta = setFeedingConfig(dicts, delta, {
                        number: feeding.curveNr || 0,
                        start: feeding.startTime || +new Date(),
                        eventStage: feeding.stage || 0,
                        offset: feeding.offset || 0,
                        punishment: feeding.punishment || 0,
                        correction: feeding.percentCorrection || 0,
                    });
                    delta.oldest = +new Date();
                    if (delta.RFID) {
                        const object = mapGroup.get(PlcmntID) || {};
                        object[delta.id] = delta;
                        mapGroup.set(PlcmntID, object);
                    } else {
                        mapIndividual.set(isNil(delta.receiver.index) ? delta.receiver.deviceId : `${delta.receiver.deviceId}_${delta.receiver.index}`, delta);
                    }
                }
            });
            if (!isEmpty(mapGroup)) store.dispatch(getFeedStateRFID(mapGroup));
            if (!isEmpty(mapIndividual)) store.dispatch(getFeedState(mapIndividual));
            refresh();
            break;
        }
        case DispenserNRFCommandTypes.ADD_PIG: {
            let map = new Map();
            const tmp = {};
            const rfids = [];
            message.CData.animals.forEach(a => {
                let delta = getFeedShadow({ chamberId: message.CData.PlcmntID, rfid: a.RFID });
                delta = setFeedingConfig(dicts, delta, {
                    number: a.curveCfg.curveNr || 0,
                    start: a.curveCfg.startTime || 0,
                    offset: a.curveCfg.offset || 0,
                    punishment: a.curveCfg.punishment || 0,
                    correction: a.curveCfg.percentCorrection || 0,
                });
                delta.oldest = +new Date();
                rfids.push(delta);
            });
            rfids.forEach(d => tmp[d.id] = d);
            map.set(message.CData.PlcmntID, tmp);
            store.dispatch(getFeedStateRFID(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_LOCK: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({ deviceId: devId });
                delta.locked = !!message.CData.isLocked;
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_SKIP_DOSES: {
            let map = new Map();
            (isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                if (isArray(message.CData)) {
                    // wst
                    for (let data of message.CData) {
                        const key = isNil(data.number) ? devId : `${devId}_${data.number - 1}`;
                        let delta = getFeedShadow({ deviceId: key });
                        delta.skipDoses = data.dosesToSkip || [];
                        delta.oldest = +new Date();
                        map.set(key, delta);
                    }

                } else {
                    // nrf
                    let delta = getFeedShadow({ deviceId: devId });
                    delta.skipDoses = message.CData.dosesToSkip || [];
                    delta.oldest = +new Date();
                    map.set(devId, delta);
                }

            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_FORCE_FEEDING: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                const key = isNil(message.CData.number) ? devId : `${devId}_${message.CData.number - 1}`
                let delta = getFeedShadow({ deviceId: key });
                delta._extraFeed += message.CData.dose;
                delta.oldest = +new Date();
                map.set(key, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case GatawayCommandTypes.SET_DISPENSERS_DOSE_CORRECTION: {
            let map = new Map();
            let sendRFID = false;
            let tmp = {};
            message.CData.devices.forEach(({ DevID, PlcmntID, animals, correction }) => {
                if (DevID && !sendRFID) {
                    let delta = getFeedShadow({ deviceId: DevID });
                    delta.curve.correction = correction;
                    map.set(DevID, delta);
                } else if (PlcmntID) {
                    sendRFID = true;
                    animals.forEach(({ RFID, correction: correctionRfid }) => {
                        tmp[RFID] = getFeedShadow({ chamberId: PlcmntID, rfid: RFID });
                        tmp[RFID].curve.correction = correctionRfid
                    })
                    map.set(PlcmntID, tmp);
                }
                tmp = {};
            })
            if (sendRFID) {
                store.dispatch(getFeedStateRFID(map))
            } else {
                store.dispatch(getFeedState(map));
            }
            break;
        }
        case DispenserNRFCommandTypes.SET_ALERT: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({ deviceId: devId });
                delta.alert = !!message.CData.alert;
                delta.oldest = +new Date();
                delta.errors = delta.alert ? [5206] : []; //kod na reczne wywolanie alarmu
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_TYPE_EVENT: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({ deviceId: devId });
                delta.curve.eventStage = message.CData.type;
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_LED_COLOUR: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                let delta = getFeedShadow({ deviceId: devId });
                delta.diode = { ...message.CData, setTime: +new Date() };
                if (!message.CData.time) {
                    delete delta.diode;
                    delta.diode = null;
                }
                delta.oldest = +new Date();
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case GatawayCommandTypes.SET_CLEAR_PROBLEM: {
            let map = new Map();
            message.CData.DeviceIDs.forEach(({ DevID, index }) => {
                const devId = isNil(index) ? DevID : `${DevID}_${index}`;
                let delta = getFeedShadow({ deviceId: devId });
                delta.alert = false;
                delta.oldest = +new Date();
                delta.errors = [];
                map.set(devId, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case DispenserNRFCommandTypes.SET_ADDITIONAL_WATER: {
            let map = new Map();
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach((DevID) => {
                let delta = getFeedShadow({ deviceId: DevID });
                delta.waterHistory = [...delta.waterHistory, { amount: message.CData.waterDose || 0, time: +new Date() }];
                delta.oldest = +new Date();
                map.set(DevID, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        case GatawayCommandTypes.SET_CHANGE_DISPENSERS_DOSE_CORRECTION: {
            let map = new Map();
            message.CData.devices.forEach(({ DevID, correctionChange, dispensers }) => {
                if (dispensers) {
                    dispensers.forEach(({ number, correctionChange: corrChange }) => {
                        let delta = getFeedShadow({ deviceId: `${DevID}_${number - 1}` });
                        delta.curve.correction = Math.min(FeedingDoseCorrection.MAX, Math.max(FeedingDoseCorrection.MIN, delta.curve.correction + corrChange));
                        delta.oldest = +new Date();
                        map.set(`${DevID}_${number - 1}`, delta);
                    })
                } else {
                    let delta = getFeedShadow({ deviceId: DevID });
                    delta.curve.correction = Math.min(FeedingDoseCorrection.MAX, Math.max(FeedingDoseCorrection.MIN, delta.curve.correction + correctionChange));
                    delta.oldest = +new Date();
                    map.set(DevID, delta);
                }
            });
            store.dispatch(getFeedState(map));
            break;
        }
        // typowo dla wst
        case DispenserDriverCommandTypes.SET_BLOCK_OUTS: {
            let map = new Map();
            const DevID = isArray(message.DeviceId) ? message.DeviceId[0] : message.DeviceId;
            message.CData.forEach(({ number, lock }) => {
                const key = `${DevID}_${number - 1}`;
                const delta = getFeedShadow({ deviceId: key });
                delta.locked = !!lock;
                delta.oldest = +new Date();
                map.set(key, delta);
            });
            store.dispatch(getFeedState(map));
            break;
        }
        default:
            break;

    }
    return id;
}

export function setFeedingConfig(dicts = new Map(), shadow = {}, {
    number,
    start,
    offset,
    punishment,
    correction,
    eventStage
} = {}) {
    !isNil(number) && set(shadow, "curve.number", number);
    !isNil(start) && set(shadow, "curve.start", start);
    !isNil(offset) && set(shadow, "curve.offset", offset);
    !isNil(punishment) && set(shadow, "curve.punishment", punishment);
    !isNil(correction) && set(shadow, "curve.correction", correction);
    !isNil(eventStage) && set(shadow, "curve.eventStage", eventStage);
    console.log(dicts, number, eventStage, start)
    const day = get(dicts[SettingTypes.FEEDING_CURVE].get(number - 1), `SetData.${eventStage ? "InseminationJumpTo" : "nonexisting"}`, 1) + moment().startOf("day").diff(moment(get(shadow, "curve.start", +new Date())).startOf("day"), "day");
    set(shadow, "curve.day", day);
    console.log("setFeedingConfig ", shadow)
    if (shadow.curve.day) {
        shadow.loop = {
            insemination: 0,
            parturition: 0,
            endDay: 0
        };
        const curve = dicts[SettingTypes.FEEDING_CURVE].get(shadow.curve.number - 1);
        if (curve) {
            if (day > get(curve, "SetData.Days.length", 0)) {
                shadow.curve.day = get(curve, "SetData.Days.length", 0);
                shadow.loop.endDay = day - get(curve, "SetData.Days.length");
            }
            shadow.feed.plannedUsage = get(curve, `SetData.Days[${shadow.curve.day - 1}].ForageAmount`, 3000) * ((((shadow.curve.correction || 0) + 100) / 100))
        }
    }
    if (!shadow.curve.number) {
        delete shadow.schedule;
        delete shadow.forage;
        shadow.curve.id = undefined;
    } else {
        if (shadow.curve.day) {
            const curve = dicts[SettingTypes.FEEDING_CURVE].get(shadow.curve.number - 1);
            shadow.curve.id = get(curve, "SetID");
            const forageNr = get(curve, `SetData.Days[${shadow.curve.day - 1}].ForageType`, -1) + 1;
            const scheduleNr = get(curve, `SetData.Days[${shadow.curve.day - 1}].ForageType`, -1) + 1;
            shadow = {
                ...shadow,
                schedule: {
                    number: scheduleNr,
                    id: get(dicts[SettingTypes.FEEDING_SCHEDULE].get(scheduleNr - 1), "SetID")
                },
                forage: {
                    number: forageNr,
                    id: get(dicts[SettingTypes.FEEDING_FORAGE].get(forageNr - 1), "SetID")
                },
            }
        }

    }
    return shadow;
}


export const getFakeDataHistoryModal = (selectedNodes) => {
    const lastWeekHistory = cloneDeep(get(selectedNodes, "[0].lastWeekHistory", [])).map(o => ({
        plannedUsage: o.plannedUsage || 0,
        usage: o.usage || 0,
        time: o.date,
        name: moment(o.date).format("DD.MM.YY")
    }));
    const MaxDose = (lastWeekHistory.length ? lastWeekHistory[0].plannedUsage : 8900) / 8900;
    const aggDt = [
        {
            "usage": 1.8,
            "plannedUsage": 3.6
        },
        {
            "usage": 3.6,
            "plannedUsage": 3.6
        },
        {
            "usage": 3.6,
            "plannedUsage": 3.6
        },
        {
            "usage": 3.6,
            "plannedUsage": 3.6
        },
        {
            "usage": 3.3,
            "plannedUsage": 3.3
        },
        {
            "usage": 2.8,
            "plannedUsage": 2.8
        },
        {
            "usage": 2.3,
            "plannedUsage": 2.3
        },
        {
            "usage": 2.4,
            "plannedUsage": 2.5
        },
        {
            "usage": 3,
            "plannedUsage": 3.1
        },
        {
            "usage": 3.8,
            "plannedUsage": 3.8
        },
        {
            "usage": 4.4,
            "plannedUsage": 4.4
        },
        {
            "usage": 4.8,
            "plannedUsage": 4.9
        },
        {
            "usage": 5.2,
            "plannedUsage": 5.3
        },
        {
            "usage": 5.6,
            "plannedUsage": 5.7
        },
        {
            "usage": 6.2,
            "plannedUsage": 6.2
        },
        {
            "usage": 6.6,
            "plannedUsage": 6.6
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 6.75,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7
        },
        {
            "usage": 7,
            "plannedUsage": 7.1
        },
        {
            "usage": 7.5,
            "plannedUsage": 7.65
        },
        {
            "usage": 7.8,
            "plannedUsage": 7.85
        },
        {
            "usage": 8,
            "plannedUsage": 8.05
        },
        {
            "usage": 8.2,
            "plannedUsage": 8.25
        },
        {
            "usage": 8.4,
            "plannedUsage": 8.5
        },
        {
            "usage": 8.6,
            "plannedUsage": 8.7
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 8.8,
            "plannedUsage": 8.9
        },
        {
            "usage": 2.2,
            "plannedUsage": 8.9
        }
    ].reverse().map((o, i) => ({
        usage: (MaxDose * o.usage) * 1000,
        plannedUsage: (MaxDose * o.plannedUsage) * 1000,
        time: +moment.utc().subtract(i, "days").startOf("day"),
        name: moment.utc().subtract(i, "days").startOf("day").format("DD.MM.YY")
    }))
    return uniqBy([...lastWeekHistory, ...aggDt], o => o.name)
}

function getRandomCurve(FarmID) {
    let curves = settingsDB.getAllSettings(FarmID).filter(item => item.SetType === SettingTypes.FEEDING_CURVE && !item.DtaDltTime);
    let random = Math.floor(Math.random() * curves.length);
    return curves[random];
}

export function getSchedule(FarmID, curve, day) {
    let currentDay = curve.SetData.Days[day];
    if (currentDay) {
        return settingsDB.getAllSettings(FarmID).find(item => item.SetType === SettingTypes.FEEDING_SCHEDULE && item.SetData.Index === currentDay.DailyPlan);
    }
    return { SetData: { Doses: [] } };
}

export function getFakeFeedingDataForPig(AnmID, FarmID) {
    let curve = FarmID === "24Y82cdBua3ekYq7CBQh" ? settingsDB.getSettingByID("WkniqJO9i3BcItrvOqIB") : FarmID === "ENY82cdBua3ekYq7CBQh" ? settingsDB.getSettingByID("mPjvnxsoYDQDOJg1dgMb") : getRandomCurve(FarmID);
    console.log(curve);
    let max = curve.SetData.Days.length;
    let day = Math.floor(Math.random() * max);
    let corrections = [-20, -15, -10, -5, 0, 5, 10, 15, 20];
    let correctionIndex = Math.floor(Math.random() * corrections.length);
    let currentDay = curve.SetData.Days[day];
    let dailyPlan = getSchedule(FarmID, curve, day);
    let time = +moment(0).hour(moment().hour()).minute(moment().minute());
    let percents = dailyPlan.SetData.Doses.filter(item => item.Start <= time).reduce((a, b) => a + b.Percent, 0) / 100;
    return {
        "locked": false,
        "oldest": 1593089005213,
        "workType": "H",
        "forage": { "id": "zOal0u41csBVe-pn04o9", "number": 2 },
        "schedule": { "id": dailyPlan.SetID, "number": dailyPlan.SetData.Index },
        "curve": {
            "number": curve.SetData.Index + 1,
            "offset": 0,
            "start": +moment().startOf("day").subtract(day, "days"),
            "day": day,
            "punishment": 0,
            "correction": corrections[correctionIndex],
            "eventStage": 0,
            "id": curve.SetID
        },
        "loop": { "insemination": 0, "parturition": 0, "endDay": 0 },
        "feeding": true,
        "skipDoses": [0, 0, 0, 0, 0, 0],
        "alert": 0,
        "errors": [],
        "feed": { "usage": 0, "plannedUsage": 4370 },
        "additionalFeeding": [{
            "dose": 1000,
            "time": 1593086727740,
            "DevID": "Ra2BjoTqU9d1e47DLmaq_0",
            "foodType": 1,
            "success": 1,
            "finished": true,
            "_id": "qcoSv7pasPEGSFSf"
        }],
        "lastWeekHistory": [
            {
                "date": 1593036000000,
                "usage": currentDay ? currentDay.ForageAmount * percents : 0,
                "plannedUsage": currentDay.ForageAmount
            },
            { "date": 1592949600000, "plannedUsage": 1000, "usage": 400 },
            { "date": 1592863200000, "plannedUsage": 1000, "usage": 850 },
            { "date": 1592776800000, "plannedUsage": 1000, "usage": 790 },
            { "date": 1592690400000, "plannedUsage": 1000, "usage": 100 },
            { "date": 1592604000000, "plannedUsage": 1000, "usage": 640 },
            { "date": 1592517600000, "plannedUsage": 1000, "usage": 1000 }
        ],
        "lastSeen": { "time": 1593086727740 },
        AnmID
    }
}

export function setFakeFeedingDataForPig(AnmID, FarmID, curve, startTime, stage, doseCorrection, dosesToSkip) {
    let currentData = savedFeedingDataForPig[AnmID];
    if (!curve) {
        curve = settingsDB.getSettingByID(currentData.curve.id);
    }
    currentData.curve.number = curve.SetData.Index + 1;
    currentData.curve.id = curve.SetID;
    if (startTime !== null) {
        currentData.curve.start = startTime;
        currentData.curve.day = moment().startOf("day").diff(startTime, "days") + 1;
        let schedule = getSchedule(FarmID, curve, currentData.curve.day);
        currentData.schedule.id = schedule.SetID;
        currentData.schedule.number = schedule.SetType.Index;
        let currentDay = curve.SetData.Days[currentData.curve.day];
        currentData.lastWeekHistory[0].plannedUsage = currentDay.ForageAmount;
    }
    if (stage !== null) {
        currentData.curve.stage = stage;
        if (stage === 1) {
            currentData.curve.day += curve.SetData.InseminationJumpTo - 1;
        }
    }
    if (doseCorrection !== null) {
        currentData.curve.correction = doseCorrection;
    }
    if (dosesToSkip) {
        currentData.skipDoses = dosesToSkip;
    }
    savedFeedingDataForPig[AnmID] = currentData;
}
