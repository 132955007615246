import React from "react";
import {compose} from "redux";
import {connectModal} from "redux-modal";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import {Field, getFormValues, reduxForm} from "redux-form";
import {submit, validate} from "./AddMissingSowEventModalSubmit";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import moment from "moment";
import {connect} from "react-redux";
import animalsDB from "../../../database/animalsDB";
import {AnimalTypes} from "../../../constans/animalTypes";
import {getAnimalOptions} from "../../../utils/DataGridUtils";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";

export const ModalName = "add-missing-sow-event-modal";

function mapStateToProps(state) {
    const farmID = state.location.farm;
    const boars = animalsDB.getAllAnimals(farmID, AnimalTypes.BOAR, false, false);
    return {
        modalFormValues: getFormValues(ModalName)(state),
        boars: getAnimalOptions(boars),
        user: state.user.user
    }
}

class AddMissingSowEventModal extends React.Component {

    constructor(props) {
        super(props);
        this.props.initialize({
            Cycles: 1,
            EvTime: moment().format(moment.HTML5_FMT.DATE)
        })
    }

    render() {
        const {show, handleHide, handleSubmit, submitting, t, modalFormValues, boars} = this.props;
        const shouldBeInsemination = modalFormValues?.Cycles && +modalFormValues.Cycles === 0;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={t("modals.addMissingEventSowEventModal.addMissingEvent")} onCloseClick={handleHide}/>
                    <ModalBody>
                        <Field
                            name={"Cycles"}
                            id={"Cycles"}
                            type={"number"}
                            component={ReduxLabeledInput}
                            label={t("modals.addMissingEventSowEventModal.amountOfParturitions")}
                            required
                        />
                        <Field
                            name={"EvTime"}
                            id={"EvTime"}
                            type={"date"}
                            component={ReduxLabeledInput}
                            label={shouldBeInsemination ? t("lastInseminationDate") : t("events.insertionEvent.lastWeaning")}
                            required
                        />
                        {
                            shouldBeInsemination &&
                            <Field
                                name={"Boar"}
                                id={"Boar"}
                                component={ReduxLabeledSelect}
                                label={t("boar")}
                                options={boars}
                                required
                            />
                        }
                    </ModalBody>
                    <ModalFooter
                        submitting={submitting}
                        hasConfirmButton={true}
                        confirmText={t("add")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }
}

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect(mapStateToProps),
    connectModal({name: ModalName}),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(AddMissingSowEventModal);
