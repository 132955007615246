import lokiDB from "./lokiDB";

class SnsDB {

    saveSNS(endpoint) {
        this.clearDB();
        lokiDB.sns.insert({ endpoint });
        lokiDB.saveDB();
    }

    getSNS() {
        return lokiDB.sns?.findOne()?.endpoint;
    }

    clearDB() {
        lokiDB.sns.chain().find().remove();
    }

}

const snsDB = new SnsDB();
export default snsDB;
