const initialValue = {
    list: []
};

export default function syncReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_SYNC_DATA": {
            return {...state, list: action.payload};
        }
        default:
            return state
    }
}
