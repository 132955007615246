import React from "react";
import Water from "./ue-water";
import Grassland from "./ue-grassland";
import Building from "./ue-building";
import Dirt from "./ue-dirt";
import Concrete from "./ue-concrete";
import Forest from "./ue-forest";
import Meadow from "./ue-meadow";
import Asphalt from "./ue-asphalt";
import Standing from "./ue-standing";
import Pig from "./ue-pig";
import Devices from "./ue-devices";
import "./_index.scss";


function View({scale = 1, showStandings = true}) {
    return (
        <>
            <Water scale={scale}/>
            <Building scale={scale}/>
            <Grassland scale={scale}/>
            <Dirt scale={scale}/>
            <Concrete scale={scale}/>
            <Forest scale={scale}/>
            <Asphalt scale={scale}/>
            <Meadow scale={scale}/>
            {
                showStandings &&
                <>
                    <Standing/>
                    <Pig/>
                    <Devices/>
                </>
            }
        </>
    )
}

export default React.memo(View);
