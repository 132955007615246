import StaticListParamsHandler from "./StaticListParamsHandler";
import {isRestored} from "./utils";

const handler = StaticListParamsHandler(({params, translationManager, changes}) => {
    if (isRestored(changes)) {
        // jeśli został usunięty `DtaDelTime` to zmien tłumaczenie
        return translationManager.translate(`$t(animalModList.restore) "{{eventType}} {{date}}"`, params);
    }
    return translationManager.translate(`$t(animalModList.update) "{{eventType}} {{date}}"`, params);
});

handler.RegExp = new RegExp(`^2059$`, "g");

export default handler;