import React, {Component} from 'react';
import "./_rotate.scss";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";

class Rotate extends Component {

    constructor(props) {
        super(props);
        this.rotate = props.rotate;
        this.lastRotate = props.rotate;
    }


    onRotate = (angleDEG) => {
        const {onRotate} = this.props;
        onRotate(angleDEG);
    }

    onReset = () => {
        this.rotate = 0;
        this.onRotate(0);
    }

    onNext = () => {
        const {rotate} = this.props;
        const angle = ((Math.floor(rotate / 90) + 1) * 90) % 360;
        this.onRotate(angle);
    }

    onPrev = () => {
        const {rotate} = this.props;
        const angle = ((Math.floor(rotate / 90) - 1) * 90) % 360;
        this.onRotate(angle);
    }

    render() {
        const {rotate, mobile, t} = this.props;
        const computedRotate = this.lastRotate > rotate ? 360 + rotate : rotate;
        this.lastRotate = computedRotate;
        return (
            <div className={"fetura-farm-map__compass"}>
                {
                    !mobile &&
                    <i title={t("farms.tabs.mapTab.rotateLeft")} onClick={this.onPrev}
                       className={"arrow curve-left"}/>

                }
                <div title={t("farms.tabs.mapTab.rotateReset")} style={{transform: `rotate(${computedRotate}deg)`}}
                     onClick={this.onReset}
                     className={"compass"}/>
                {
                    !mobile &&
                    <i title={t("farms.tabs.mapTab.rotateRight")} onClick={this.onNext}
                       className={"arrow curve-right"}/>
                }
            </div>
        );
    }
}

export default withTranslation(NameSpaces.TRANSLATION)(Rotate)
