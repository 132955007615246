import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isFunction} from "lodash";

class TextSwitchItem extends Component {
    onClick = () => {
        const {onChange, value, disabled} = this.props;
        if (!disabled && isFunction(onChange)) {
            onChange(value)
        }
    };

    render() {
        const {name, selected, title, disabled} = this.props;
        let className = "item";
        if (selected) className += " selected";
        if (disabled) className += " disabled";
        return (
            <div title={title} onClick={this.onClick} className={className}>
                {name}
            </div>
        );
    }
}

TextSwitchItem.propTypes = {
    value: PropTypes.oneOf([PropTypes.string, PropTypes.object, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    disabled: PropTypes.bool
};

export default TextSwitchItem;
