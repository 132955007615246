import * as RoleTypes from "@wesstron/utils/Api/constants/roleTypes";

/**
 * Funkcja sprawdza czy użytkownik jest serwisem
 * @param user
 * @param farm {string|null}
 * @return {boolean}
 */
export function userIsService(user, farm = null) {
    let isService = user.Roles.find(r => r.Role === RoleTypes.SERVICE);
    if (isService) {
        if (farm) {
            let isServiceOfFarm = !!isService.LocalRights.find(item => item.FarmID === farm);
            if (isServiceOfFarm) return true;
        } else {
            return true;
        }
    }
    return false;
}
