import {myID} from "../libs/generateID";

class ParametersDB {
    worker = new Worker(
        new URL("../workers/prepareAnimalParameters.worker.js", import.meta.url)
    );
    messages = {};
    stackData = null;

    constructor() {
        this.worker.onmessage = this.onMessage;
    }

    onMessage = (event) => {
        const data = JSON.parse(new TextDecoder().decode(event.data));
        console.log("onMessage", data.id, event);
        if (this.messages[data.id]) this.messages[data.id](data.data);
        delete this.messages[data.id];
    };

    sendMessageToWorker(type, data, onMessage) {
        const id = myID();
        this.messages[id] = onMessage;
        const message = {id, type, data};
        console.log("sendMessageToWorker", id, type);
        const array = new TextEncoder().encode(JSON.stringify(message));
        this.worker.postMessage(array.buffer, [array.buffer]);
    }

    callQuery(query, params) {
        return new Promise((resolve, reject) => {
            this.sendMessageToWorker("CALL_QUERY", {query, params}, (data) => {
                resolve(data.result);
            });
        });
    }
}

const parametersDB = new ParametersDB();
export default parametersDB;
