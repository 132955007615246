import React, {useCallback, useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import useAnimalGroupInfo from "../useAnimalGroupInfo";
import {isBetween} from "../../../../utils/MathUtils";
import moment from "moment";
import useDomain from "../useDomain";
import {utcMomentToLocal} from "../../../../utils/DateTimeUtils";
import {useSelector} from "react-redux";
import {memoize} from "lodash";
import ChartTemplate from "./ChartTemplate";
import {makeGetManageBuildingsList} from "../../../../selectors/buildingsSelector";
import {ERRORS} from "./utils";

const LocationChart = () => {

    const ref = useRef(null);

    const getManageBuildingsList = useMemo(() => makeGetManageBuildingsList(), []);

    const buildingsList = useSelector(getManageBuildingsList);

    const groupInfo = useAnimalGroupInfo();

    const chartDomain = useDomain();

    const dataSet = useMemo(() => {
        const ds = [];
        const [chartStart, chartEnd] = chartDomain;
        const diff = Math.abs(moment.utc(chartStart).diff(moment.utc(chartEnd), "day"));
        const getLocation = memoize((locationId) => {
            return buildingsList.find(({id}) => id === locationId);
        });
        for (let i = 0; i <= diff; i++) {
            const dayUTC = moment.utc(chartStart).add(i, "day");
            const dayLOCAL = utcMomentToLocal(dayUTC);
            const isGroupActive = isBetween(+dayUTC, groupInfo.start, groupInfo.end);
            if (!isGroupActive) {
                ds.push({
                    time: +dayUTC,
                    value: null,
                    details: [],
                    error: null
                })
                continue;
            }
            const {value, details, estimated} = (() => {
                const details = [];
                let cnt = 0;
                let estimated = 0;
                const locations = Object.keys(groupInfo.animalNumberPerLocation);
                for (const locationId of locations) {
                    const animals = groupInfo.getNumberByTimeAndLocation(+dayLOCAL, locationId);
                    const chamberSize = getLocation(locationId)?.chamberSize || animals;
                    const locationData = {
                        locationId,
                        value: animals,
                        error: null,
                        count: null,
                        estimated: [0, chamberSize]
                    }
                    if (!isBetween(locationData.value, 0, chamberSize)) {
                        locationData.error = ERRORS.OUT_OF_BOUNDS
                    }
                    cnt += animals;
                    estimated += chamberSize;
                    details.push(locationData);
                }
                return {value: cnt, details, estimated};
            })();
            ds.push({
                time: +dayUTC,
                value,
                details,
                estimated: [0, estimated],
                error: (ds[ds.length - 1]?.value !== value) ? details.some(({error}) => [ERRORS.OUT_OF_BOUNDS].includes(error)) ? value || 0 : null : null
            });
        }
        return ds;

    }, [groupInfo, chartDomain, buildingsList]);

    const {t} = useTranslation();

    const estimatedFormatter = useCallback((value) => {
        if (Array.isArray(value)) return value[1];
        return value;
    }, []);

    const dataDef = useMemo(() => {
        return [
            {
                dataKey: "value",
                color: "green",
                type: "stepAfter",
                chartType: "Line",
                unit: t("piecesShort"),
                name: t("animalCard.renderers.animalCount")
            },
            {
                dataKey: "estimated",
                color: "blue",
                opacity: 0.2,
                strokeDasharray: "5 5",
                strokeOpacity: 0.5,
                type: "stepAfter",
                chartType: "Area",
                unit: t("piecesShort"),
                name: t("availableSpace"),
                formatter: estimatedFormatter,
            },
            {
                color: "red",
                opacity: 1,
                dataKey: "error",
                chartType: "Scatter",
                name: t("idealCharts.problems"),
                excelSkip: true,
            }
        ];
    }, [t, estimatedFormatter]);

    return (
        <ChartTemplate
            overrideDesktop={true}
            show={true}
            domain={[0, "dataMax+25"]}
            data={dataSet} error={false}
            YaxisName={t("animalCard.renderers.animalCount")}
            name={t("animalCard.renderers.animalCount")} loading={false} id={"cy-animal-count-chart"}
            dataDef={dataDef}
            dataSet={dataSet}
            saveAsExcell={t("animalCard.renderers.animalCount")}
            defaultExpanded={true} forwardRef={ref}/>
    )
}
export default React.memo(LocationChart);