import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import i18n from "../../i18n";
import {createErrorNotification, createSuccessNotification} from "../../utils/ResponseUtils";

/**
 * @typedef GraftingData
 * @type {object}
 * @property AnmID {string}             ID zwierzecia
 * @property Medicine {string}          ID leku
 * @property EvTime {number}            czas wykonania zdarzeniia (możliwe, że przejdzie do tablicy animals)
 * @property Comment {string}           komentarz
 * @property GrID {string}              id grupy
 * @property Weight {number}            waga upadłych prosiąt
 * @property Reason {string}            powód upadku
 * @property TreatmentType {number}     Typ leczenia
 * @property StartTime {number}         Czas startu dla typow roznych od szczepienia
 * @property EndTime {number}           Czas zakonczenia dla typow roznych od szczepienia
 * @property AnmCnt {number}            Ilość zwierząt
 */

/**
 * Metoda wysyła zapytanie o stworzenie nowego upadku prosiat
 * @param data {[GraftingData]}             Dane zdarzenia
 * @param FarmID {string|null}              ID fermy
 * @param ClientID {string|null}            ID klienta
 * @param LocalUserID {string|null}         ID użytkownika
 * @return {Promise<*|undefined>}
 */
export function createGrafting(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.createGrafting({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function endGrafting(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.endGrafting({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function createGraftingNotificationSuccess(response, {uniqueId = myID()} = {}) {
    if (createSuccessNotification(response, "apiNotifications.createGraftingTitle")) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createGraftingTitle"),
        message: i18n.t("apiNotifications.createGraftingSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createGraftingNotificationFailure(response, {uniqueId = myID()} = {}) {
    if (createErrorNotification(response, "apiNotifications.createGraftingTitle", {uniqueId})) return;
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.createGraftingTitle"),
        message: i18n.t("apiNotifications.createGraftingFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
