import React, {Component} from 'react';
import {withTranslation} from "react-i18next";

class DateHeaderComponent extends Component {
    render() {
        const {name} = this.props;
        return (
            <div>
                {name}
            </div>
        );
    }
}

export default withTranslation()(DateHeaderComponent);
