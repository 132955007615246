import store from "../store/store";
import {history} from "../components/router/CustomBrowserRouter";
import snsDB from "../database/snsDB";

export function logout() {
    history.replace("/logout");
}

export const getUserApiData = ({FarmID, ClientID, LocalUserID} = {}) => {
    const state = store.getState();
    const {user: {user: {LocalUserID: _luid, ClientID: _cid}}, location: {farm: _fid}} = state;
    let endpointARN = snsDB.getSNS();
    return {
        _FarmID: FarmID || _fid,
        _LocalUserID: LocalUserID || _luid,
        _ClientID: ClientID || _cid,
        _EndpointARN: endpointARN
    }
};

export const getAttributesFromCognitoUser = (cognitoUser, sub) => {
    const {
        username,
        attributes: {name, email, phone_number, address, locale, family_name, email_verified, phone_number_verified}
    } = cognitoUser;
    return {
        login: username,
        name,
        email,
        phone: phone_number,
        address,
        locale,
        surname: family_name,
        emailVerified: email_verified,
        phoneVerified: phone_number_verified,
        sub
    };
}

export const UserTypeUtils = {
    // user type negation utils
    isNegation: (str) => /![A-Z]+/.test(str),
    NOT: (userType) => `!${userType}`,
    removeNegation: (userTypeStr) => {
        if (userTypeStr.startsWith("!")) {
            return userTypeStr.substr(1)
        }
        return userTypeStr;
    }
}