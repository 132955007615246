import {
    removeAnimal,
    removeAnimalNotificationFailure,
    removeAnimalNotificationSuccess
} from "../../../api/animal/removeAnimal";
import queryString from "query-string";

export function submit(values, dispatch, props) {
    const {animal: {AnmID, FarmID} = {}, group: {AnmGrp} = {}, location: {search}, farm} = props;
    let body = {
        Comment: values.comment
    };
    if (AnmID) body.AnmID = AnmID;
    else body.AnmGrp = AnmGrp;
    return removeAnimal(body, {FarmID}).then(res => {
        removeAnimalNotificationSuccess(res);
        const query = queryString.parse(search);
        delete query.documentsAnimal;
        delete query.documentsGroup;
        let string = queryString.stringify(query);
        props.history.replace({
            pathname: `/farm/${farm}/inventory`,
            search: string
        })
        props.handleHide();
    }).catch(e => {
        removeAnimalNotificationFailure(e);
    });
}

export function validate(values, props) {
    const {animal, group, t} = props;
    const {itemNumber, comment} = values;
    const errors = {};
    if (animal && itemNumber !== animal.AnmNo1) {
        errors.itemNumber = t("errors.validAnimalNumber");
    }
    if (group && itemNumber !== group.GrNo1) {
        errors.itemNumber = t("errors.validGroupNumber");
    }
    if (!comment) {
        errors.comment = t("required");
    }
    return errors;
}
