const initialValue = {
    basic: null,
    loadingBasic: false,
    economy: []
};

export default function economyReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_BASIC_ECONOMY_PENDING":
            return {...state, loadingBasic: true};
        case "GET_BASIC_ECONOMY_FULFILLED":
            return {...state, loadingBasic: false, basic: action.payload};
        case "GET_BASIC_ECONOMY_REJECTED":
            return {...state, loadingBasic: false};
        case "GET_ECONOMY":
            return {...state, economy: action.payload};
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
