export const getAnimalParametersLoading = (state) =>
    state.animalParameters.fetching;

export const getMapSQL = (state) => state.animalParameters.map.sql;

export const getMapSQLResult = (state) => state.animalParameters.map.data;

export const getMapSQLData = (state) => state.animalParameters.map;

export const getAnimalParametersFirstFetching = (state) =>
    state.animalParameters.firstFetching;
