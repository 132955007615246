import moment from "moment";

export function getPrice(economy, time = +moment.utc(), returnFirstKnown = false, returnNull = false) {
    if (!economy) return returnNull ? null : 0;
    for (let i = economy.Prices.length - 1; i >= 0; i--) {
        if (economy.Prices[i].ValidSince < time) {
            return economy.Prices[i].Price;
        }
    }
    if (returnFirstKnown) {
        return economy.Prices[0]?.Price || (returnNull ? null : 0);
    }
    return returnNull ? null : 0;
}