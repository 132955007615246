import {createSelector} from "reselect";
import i18n from "../i18n";
import {getFarmObject} from "./farmSelector";

export const getPath = state => state.files.path;

const _getFiles = state => state.files.files;

export const getFileLoading = state => state.files.loading;

export const getSelectedFile = state => state.files.selected;

export const getUploadingFiles = state => state.files.uploadFiles;

export const getFilesView = state => state.files.view;

export const getFileSort = state => state.files.sort;

export const getFoldersForPicker = state => state.files.directoryPicker.files;

export const getPathForPicker = state => state.files.directoryPicker.path;

export const getLoadingForPicker = state => state.files.directoryPicker.loading;

export const getFilesMenu = state => state.files.menu;

export const getFileToShow = state => state.files.show.file;

export const getLastModifiedFiles = state => state.files.lastModified.files;

export const isShowFromLastFiles = state => state.files.show.fromLastFile;

export const getAllDirectories = state => state.files.directories.files;

export const getAllDirectoriesLoading = state => state.files.directories.loading;

export const getDirectory = (state, FileID) => state.files.directories.files.find(item => item.FileID === FileID) || null;

export const getFilesOCR = state => state.files.ocr.files;

export const getFilesOCRLoading = state => state.files.ocr.loading;

export const getPathBreadcrumbs = createSelector(getPath, (path) => {
    let breadcrumbs = [];
    breadcrumbs.push({name: i18n.t("drive"), path: ""});
    if (path !== "") {
        let split = path.split("/");
        let p = "";
        for (let text of split) {
            p += p.length === 0 ? text : `/${text}`;
            breadcrumbs.push({name: text, path: p});
        }
    }
    return breadcrumbs;
})

export const isUploadingFile = createSelector(getUploadingFiles, files => {
    for (let row of files) {
        if (row.progress !== 100) return true;
    }
    return false;
})

export const getFiles = createSelector([_getFiles, getFilesView, getFileSort], (files, view, sort) => {
    if (view === "THUMBNAILS") return files;
    let tmp = files.slice(0);
    tmp.sort((a, b) => {
        const {column, type} = sort;
        // sortowanie najpierw katalogow, a jezeli sa dwa katalogi to porownaj nazwy
        if (a.Type === "DIR" && b.Type === "DIR") {
            if (column !== "FileName") {
                return a.FileName.localeCompare(b.FileName);
            }
            if (type === "ASC") {
                return a.FileName.localeCompare(b.FileName);
            } else {
                return b.FileName.localeCompare(a.FileName);
            }
        }
        if (a.Type === "DIR") return -1;
        if (b.Type === "DIR") return 1;
        // sortowanie stringow
        if (typeof a[column] === "string") {
            if (type === "ASC") return a[column].localeCompare(b[column]);
            return b[column].localeCompare(a[column]);
        }
        // sortowanie liczb
        if (type === "ASC") return a[column] - b[column];
        return b[column] - a[column];
    })
    return tmp;
})

export const getFilesForShower = createSelector([getFiles, getLastModifiedFiles, isShowFromLastFiles], (files, lastModifiedFiles, fromLastFiles) => {
    console.log(fromLastFiles, files, lastModifiedFiles);
    let list = fromLastFiles ? lastModifiedFiles : files;
    return list.filter(item => item.Type.includes("image") || item.Type.includes("video"));
})

export const getUploadStatus = createSelector(getUploadingFiles, files => {
    let progress = 0;
    let sendingFiles = [];
    for (let file of files) {
        progress += file.progress;
        if (file.progress !== 100) {
            sendingFiles.push(file.file.name);
        }
    }
    progress = progress / files.length;
    return {
        show: files.length > 0,
        maxFiles: files.length,
        progress,
        sendingFiles
    }
})

const getSelectedOCRFileFromHistory = (state, props) => props.match.params.FileID;

export const getSelectedOCRFile = createSelector([getSelectedOCRFileFromHistory, getFilesOCR], (FileID, files) => {
    return files.find(item => item.FileID === FileID) || null;
})

export const getFilesForLastProtocols = createSelector(getFiles, files => {
    let tmp = files.slice();
    return tmp.sort((a, b) => b.FileName.localeCompare(a.FileName, [], {numeric: true}));
})

export const canUploadFile = createSelector([getPath, getAllDirectories, getFarmObject], (path, directories, farm) => {
    let split = path.split("/");
    let parentName = split[split.length - 1];
    let parentPath = split.slice(0, split.length - 1).join("/");
    if (!parentPath || !parentName) return true;
    let directory = directories.find(item => item.Folder === parentPath && item.FileName === parentName);
    if (directory && directory.DirectoryType !== "OCR") return true;
    return farm.OcrModule?.enabled;
})