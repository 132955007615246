import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from "../../select/Select";

class SelectEditor extends Component {

    onChange = value => {
        const {onRowChange, column: {key}, row} = this.props;
        onRowChange({...row, [key]: value}, true);
    }

    render() {
        const {row, options, ref, column: {key}, customRenderer} = this.props;
        return (
            <Select value={row[key]} options={options} ref={ref} onChange={this.onChange} autofocus
                    insertFirstOnNoSelected={false} stopPropagationOnEnter={false} customRenderer={customRenderer}/>
        );
    }
}

SelectEditor.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SelectEditor;
