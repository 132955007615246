/**
 *
 * @type {{GENERAL: Głowne_X, FEEDING_CURVE: Krzywa_zywienia_C, FEEDING_SCHEDULE: Harmonogram_zywienia_S, YEARLY_PLANS: Plany_roczne_w_tym_wywalonym_gridzie_P, FEEDING_FORAGE: Pasza_F, LAYOUT: Layout_L, ECONOMY: Ekonomia_E, NOTIFICATIONS: Notyfikacje_N}}
 */
export const SettingTypes = {
    GENERAL: "X", //uzywamy v
    FEEDING_CURVE: "C", //uzywamy v
    FEEDING_SCHEDULE: "S", //uzywamy v
    YEARLY_PLANS: "P", //uzywamy v
    FEEDING_FORAGE: "F", //uzywamy v
    LAYOUT: "L", //uzywamy
    ECONOMY: "E", //uzywamy
    DEVICE_TREE: "T", //NIE UŻYWANE
    FRAMES: "FRM", //NIE UŻYWANE
    COMMANDS: "CMD", //NIE UŻYWANE
    ENDPOINT_ARN: "ARN",//uzywamy
    NOTIFICATIONS: "N",//uzywamy
    FARM_MAP: "FM",//uzywamy v
    CLIMATE_CURVE: "CC",
    DEVICE_SETTINGS: "D",
    SYSTEM_SCHEDULE_WORKTYPE: "SSW",
    SYSTEM_SCHEDULE: "SS",
    CLIMATE_SK3_CURVE: "CC3",
    FILE: "FS",
    OCR_TEMPLATE: "OCR",
    ECONOMY_SETTINGS: "ES",
    CUSTOM_TASKS: "CT",
    HERD_SETTINGS: "HS",
    ROUTINES: "R",
    NUTRI_PRO_CURVE: "NPC",
    TECHNOLOGY_GROUP_TASKS: "TGT",
    CARD_TEMPLATES: "SCG"
};

export const MainPageTypes = {
    FARM_VIEW: 1,
    DASHBOARD: 2
};
