import React from "react";
import PropTypes from "prop-types"
import CycleRenderer from "./CycleRenderer";
import {get, isEqual} from "lodash";
import {USG_STATE} from "../../../constans/eventTypes";
import {getEventDate, getExpectedDate} from "./utils";
import {getExpectedDatesForAnimalCycle} from "../../../selectors/animalDocumentsSelectors";
import {compose} from "redux";
import {connect} from "react-redux";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        expectedDates: getExpectedDatesForAnimalCycle(state)
    }
}

class USGRenderer extends React.Component {

    state = {
        hoveredID: "",
    };

    getResult = (usg) => {
        const {event: {EvData: {Pregnant}}} = usg;
        switch (Pregnant) {
            case USG_STATE.REPEAT:
                return <i className="fas fa-fw fa-redo-alt" />;
            case USG_STATE.REPEAT_POSITIVE:
                return (
                    <div>
                        <i className="fas fa-fw fa-check" />
                        <i className="m-0 fas fa-fw fa-redo-alt" />
                    </div>
                );
            case USG_STATE.REPEAT_NEGATIVE:
                return (
                    <div>
                        <i className="fas fa-fw fa-times" />
                        <i className="m-0 fas fa-fw fa-redo-alt" />
                    </div>
                );
            case USG_STATE.POSITIVE:
                return <i className="fas fa-fw fa-check" />;
            case USG_STATE.NEGATIVE:
                return <i className="fas fa-fw fa-times" />;
            default:
                return null;
        }
    };

    onMouseEnter = (event) => {
        const cell = get(event, "currentTarget");
        if (cell) {
            const EvID = cell.id.split(" ")[0];
            this.setState({
                hoveredID: EvID
            })
        }
    };

    onMouseLeave = () => {
        this.setState({
            hoveredID: ""
        })
    };

    render() {
        const {
            cycle: {usgs, key},
            expanded: {rows},
            isAdvanced,
            tooltipContent,
            cycleRenderClass,
            deletionMode,
            onDeleteClick,
            onDoubleEventClick,
            printing,
            rowIndex,
            expectedDates,
            t,
            isInterrupted
        } = this.props;
        const {hoveredID} = this.state;
        let rowValue = rows.find(o => o === key);
        let lastUSG = usgs[usgs.length - 1];
        if (!lastUSG) {
            const expectedDate = getExpectedDate(isInterrupted, rowIndex, expectedDates, EventTypes.USG);
            return (
                <>
                    {
                        isAdvanced &&
                        <CycleRenderer className={expectedDate ? "expected-date" : ""} isInterrupted={isInterrupted}>
                            <div className={`cell-wrapper`} title={t("animalDocuments.plannedDate", {date: expectedDate})}>
                                {expectedDate}
                            </div>
                        </CycleRenderer>
                    }
                    <CycleRenderer comment={cycleRenderClass && tooltipContent} deletionMode={deletionMode}
                                   expected={(!isAdvanced && expectedDate) && t("animalDocuments.plannedDate", {date: expectedDate})}
                                   isInterrupted={isInterrupted}/>
                </>
            )
        }
        const operators = usgs[0].operators;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer>
                        {
                            isAdvanced && (isEqual(key, rowValue) || printing) ?
                                usgs.map((usg, index) => {
                                    const {event: {EvID}} = usg;
                                    return (
                                        <div key={index}
                                             className={`cell-wrapper ${hoveredID === EvID ? "error" : "success"}`}
                                             id={`${EvID} EvTime`} onDoubleClick={onDoubleEventClick}
                                             onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                             onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                             onClick={deletionMode ? onDeleteClick : undefined}
                                        >
                                            {getEventDate(usg)}
                                        </div>
                                    )
                                }) :
                                <div
                                    className={`cell-wrapper ${hoveredID === lastUSG.event.EvID ? "error" : "success"}`}
                                    id={`${lastUSG.event.EvID} EvTime`} onDoubleClick={onDoubleEventClick}
                                    onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                    onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                    onClick={deletionMode ? onDeleteClick : undefined}
                                >
                                    {getEventDate(lastUSG)}
                                </div>
                        }
                    </CycleRenderer>
                }
                <CycleRenderer comment={tooltipContent} isAdvanced={isAdvanced}
                               operators={operators} deletionMode={deletionMode}>
                    {
                        isAdvanced && (isEqual(key, rowValue) || printing) ?
                            usgs.map((usg, index) => {
                                const {event: {EvID}} = usg;
                                return (
                                    <div key={index}
                                         className={`cell-wrapper ${hoveredID === EvID ? "error" : "success"}`}
                                         id={`${EvID} EvData.Pregnant`} onDoubleClick={onDoubleEventClick}
                                         onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                         onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                         onClick={deletionMode ? onDeleteClick : undefined}
                                    >
                                        {this.getResult(usg)}
                                    </div>
                                )
                            }) :
                            <div
                                className={`cell-wrapper ${hoveredID === lastUSG.event.EvID ? "error" : "success"}`}
                                id={`${lastUSG.event.EvID} EvData.Pregnant`} onDoubleClick={onDoubleEventClick}
                                onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                onClick={deletionMode ? onDeleteClick : undefined}
                            >
                                {this.getResult(lastUSG)}
                            </div>
                    }
                </CycleRenderer>
            </>
        );
    }
}

USGRenderer.propTypes = {
    cycle: PropTypes.shape({
        usgs: PropTypes.array.isRequired
    }).isRequired
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(USGRenderer);
