import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";
import React from "react";
import {Col, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {
    getAnimalEvents,
    getDetailedLocationDays,
    getMergedInsertionEventsForSubgroupAnimal,
    getSelectedAnimalForDocuments,
    getTechnologyGroupTransfers,
    makeGetAnimalsInGroup
} from "../../selectors/animalDocumentsSelectors";
import {getGroupBasicInfoBoxData} from "../../utils/GroupDocumentsUtils";
import {getAnimalUnit} from "../../utils/SettingsUtils";
import {convertWeightUnitTo} from "../../utils/UnitUtils";
import Card from "../basics/card/Card";
import Tooltip from "../basics/tooltip/Tooltip";
import ConfirmRemoveAnimal from "../modals-new/confirm-remove-animal/ConfirmRemoveAnimal";
import ConfirmResurrectAnimal from "../modals-new/confirm-resurect-animal/ConfirmResurrectAnimal";
import {ModalName as EditGroupBasicsModalName} from "../modals-new/edit-group-basics-modal/EditGroupBasicsModal";
import {ModalName as EditInsertionsModalName} from "../modals-new/edit-insertions-modal/EditInsertionsModal";
import "./css/_basic-info-box.scss";
import Button from "../basics/button/Button";
import {
    ModalName as PrintGeneratorAnimalModalName
} from '../modals-new/print-generator-animal/PrintGeneratorAnimalModal'
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import {getFarmID} from "../../selectors/farmSelector";

function makeMapStateToProps () {
    const params = {
        getDeletedAnimals: true,
    }
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    return (state) => {
        return {
            farm: getFarmID(state),
            group: getSelectedAnimalForDocuments(state),
            clients: state.dictionary.clients.WData,
            animals: getAnimalsInGroup(state, params),
            technologyGroupTransfers: getTechnologyGroupTransfers(state),
            locationDays: getDetailedLocationDays(state),
            mergedInsertions: getMergedInsertionEventsForSubgroupAnimal(state),
            events: getAnimalEvents(state),
        };
    };
}

function mapDispatchToProps (dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch),
    };
}

class BasicInfoGroupBox extends React.Component {
    getGroupInfoSummary = () => {
        const {group, animals, clients, locationDays, mergedInsertions: {insertions}} = this.props;
        const {
            supplier,
            death,
            sell,
            birth,
            insertion,
            animalsSize,
            name,
            weight,
            herdNumber
        } = getGroupBasicInfoBoxData({group, animals, clients, locationDays, insertions});


        return {
            supplier,
            death,
            sell,
            birth,
            insertion,
            animalsSize,
            name,
            weight,
            herdNumber
        }
    }

    weightFormatter = (weight) => {
        if (!weight) return "-";
        return convertWeightUnitTo(weight, {
            unit: getAnimalUnit(),
            showUnit: true,
        });
    };

    onEditGroupClick = () => {
        const {show, group} = this.props;
        show(EditGroupBasicsModalName, {group});
    };

    onEditInsertionClick = () => {
        const {show, group} = this.props;
        show(EditInsertionsModalName, {group});
    };

    getTooltipContent = (value, content) => {
        if (!value || value === "-") return value;
        return (
            <span>
                {value}
                <Tooltip tooltipContent={content}>
                    <i className="fas fa-info-circle ms-1 fa-fw"/>
                </Tooltip>
            </span>
        );
    };

    getFirstTechnologyGroup () {
        const {technologyGroupTransfers} = this.props;
        let firstTransfer = technologyGroupTransfers
            .slice()
            .sort((a, b) => a.EvTime - b.EvTime)[0];
        if (!firstTransfer) return "-";
        return firstTransfer.TechnoGroupName;
    }

    dateToMs (date) {
        if (date.includes('-')) { //Jeżeli zakres dat weź pierwszą
            date = date.split('-')[0]
        }
        const [day, month, year] = date.split(".").map(Number);
        return +new Date(`20${year}`, month - 1, day);
    }

    openPrintModal = () => {
        const {animals, group, show, events} = this.props;

        const {
            supplier,
            birth,
            insertion
        } = this.getGroupInfoSummary()


        const _insertion = this.dateToMs(insertion);
        const _birth = this.dateToMs(birth);
        const utils = {
            animalType: AnimalTypes.PORKER,
            group,
            supplier,
            birth: _birth,
            insertion: _insertion,
        };

        show(PrintGeneratorAnimalModalName, {events, animals, utils});
    };

    render () {
        const {t} = this.props;

        const {
            supplier,
            death,
            sell,
            birth,
            insertion,
            animalsSize,
            name,
            weight,
            herdNumber
        } = this.getGroupInfoSummary()

        return (
            <Card className="basic-info-box">
                <h5>{t("animalDocuments.basicInformation")}</h5>
                <div id="info-box">
                    <Row>
                        <Col md={6}>
                            <div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("groupNumber")}
                                    </div>
                                    <div className="info-column">{name}</div>
                                </div>

                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("grid.columnTypeName.6")}
                                    </div>
                                    <div className="info-column">
                                        {this.getTooltipContent(
                                            insertion,
                                            t("animalDocuments.insertionDate")
                                        )}
                                    </div>
                                </div>

                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("supplier")}
                                    </div>
                                    <div className="info-column">
                                        {supplier}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("animalsCount")}
                                    </div>
                                    <div className="info-column">
                                        {animalsSize}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("herdNumber")}
                                    </div>
                                    <div className="info-column">
                                        {herdNumber || "-"}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("birthDate")}
                                    </div>
                                    <div className="info-column">
                                        {this.getTooltipContent(
                                            birth,
                                            t("animalDocuments.birthDate")
                                        )}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("saleDate")}
                                    </div>
                                    <div className="info-column">
                                        {this.getTooltipContent(
                                            sell,
                                            t("animalDocuments.subgroupRange")
                                        )}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("animalDocuments.deathDate")}
                                    </div>
                                    <div className="info-column">
                                        {this.getTooltipContent(
                                            death,
                                            t("animalDocuments.subgroupRange")
                                        )}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("animalDocuments.insertionWeight")}
                                    </div>
                                    <div className="info-column">
                                        {this.weightFormatter(weight)}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("technologyGroup")}
                                    </div>
                                    <div className="info-column">
                                        {this.getFirstTechnologyGroup()}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Button
                        className={"print-display-none mt-2 float-end"}
                        onClick={this.onEditInsertionClick}
                        buttonStyle={"bordered"}
                        text={t("insertionsEdition")}
                    />
                    <Button
                        className={"print-display-none mt-2 float-end"}
                        onClick={this.onEditGroupClick}
                        buttonStyle={"bordered"}
                        text={t("basicDataEdition")}
                    />
                    <Button
                        type="button"
                        onClick={this.openPrintModal}
                        buttonColor="info"
                        className={"print-display-none mt-2 float-end"}>
                        {t("grid.print1")}
                    </Button>
                </div>
                <ConfirmRemoveAnimal/>
                <ConfirmResurrectAnimal/>
            </Card>
        );
    }
}

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect(makeMapStateToProps, mapDispatchToProps)
)(BasicInfoGroupBox);
