import {withTranslation} from "react-i18next";
import SummaryTemplate from "./SummaryTemplate";
import React from "react";
import {parseClimate} from "../parsers";
import {makeExtractKeys} from "../../../../utils/FarmMapUtils";

const getKeys = makeExtractKeys();

function TempSummary({devices, data, t}) {
    const result = [];
    const keys = getKeys(devices);
    for (let key of keys) {
        const {text, isLive} = parseClimate(data[key])
        result.push(<SummaryTemplate icon={"fas fa-wheat"} header={t("averageTemperature")}
                                     alerts={data[key]?.Alerts}
                                     warnings={data[key]?.Warnings}
                                     isWarning={!isLive} content={text}/>)
    }
    return result;
}

export default withTranslation()(TempSummary);