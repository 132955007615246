import React, {useCallback, useEffect, useRef, useState} from 'react';
import {getFileObject as getFileObjectAPI} from "../../../../api/files/getFileObject";
import {
    InputActionsWrapperFileItemRenderer
} from "../../../basics/input-actions-wrapper/file-list/InputActionsWrapperFileItem";
import {useOnScreen} from "../../../../hooks/useOnScreen";
import DesktopCalendarTaskDrawerFileMenu from "./DesktopCalendarTaskDrawerFileMenu";
import {showFile} from "../../../../actions/filesActions";
import {useDispatch} from "react-redux";

function DesktopCalendarTaskDrawerFileItem({file, drawer, FarmID}) {

    const dispatch = useDispatch();
    const [fileObject, setFileObject] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [menu, setMenu] = useState(null);
    const {FileID} = file;
    const container = useRef();

    const isOnScreen = useOnScreen(container, drawer?.current);

    useEffect(() => {
        if (!isOnScreen || fileObject) return;

        async function getFileObject() {
            let res = await getFileObjectAPI(FileID, {FarmID});
            setFileObject(res.file);
            setThumbnail(res.thumbnail);
        }

        getFileObject();
    }, [isOnScreen, FileID, fileObject, FarmID]);

    const onPreviewClick = useCallback(() => {
        dispatch(showFile(fileObject));
    }, [fileObject, dispatch]);

    const onContextMenu = useCallback((e) => {
        e.preventDefault();
        setMenu(e);
    }, []);

    let canUseThumbnail = fileObject && fileObject.Type.startsWith("image/") && !fileObject.Type.includes("gif");
    let canShowPreview = fileObject && (fileObject.Type.includes("image") || fileObject.Type.includes("video") || fileObject.Type.includes("pdf"));
    return (
        <div ref={container}>
            <InputActionsWrapperFileItemRenderer type={fileObject?.Type || ""} name={fileObject?.FileName || file.Name}
                                                 onClick={canShowPreview ? onPreviewClick : null}
                                                 onContextMenu={fileObject ? onContextMenu : null}
                                                 thumbnail={canUseThumbnail && thumbnail}
                                                 showRemove={false}/>
            <DesktopCalendarTaskDrawerFileMenu menu={menu} fileObject={fileObject} FarmID={FarmID}/>
        </div>
    )
}

export default React.memo(DesktopCalendarTaskDrawerFileItem);
