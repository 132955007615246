import React from "react";
import CycleRenderer from "./CycleRenderer";
import PropTypes from "prop-types";
import {get} from "lodash";
import {compose} from "redux";
import {connect} from "react-redux";
import {getEventDate} from "./utils";

function mapStateToProps(state) {
    return {
        noPregnancyReason: state.dictionary.noPreganancy,
        lang: state.language.lang.lang
    }
}

class NoPregnancyRenderer extends React.Component {

    state = {
        hoveredID: "",
    };

    onMouseEnter = (event) => {
        const cell = get(event, "currentTarget");
        if (cell) {
            const EvID = cell.id.split(" ")[0];
            this.setState({
                hoveredID: EvID
            })
        }
    };

    onMouseLeave = () => {
        this.setState({
            hoveredID: ""
        })
    };

    render() {
        const {
            cycle: {noPregnancy},
            isAdvanced,
            noPregnancyReason,
            deletionMode,
            onDeleteClick,
            onDoubleEventClick,
            isInterrupted
        } = this.props;
        const {hoveredID} = this.state;
        const nP = noPregnancy[0];
        if (!nP) {
            return (
                <>
                    {
                        isAdvanced && <CycleRenderer className="print-display-none" isInterrupted={isInterrupted}/>
                    }
                    <CycleRenderer className="print-display-none last-cell" isInterrupted={isInterrupted}/>
                </>
            )
        }
        const reason = noPregnancyReason.WData.find((item) => item.ID === get(nP, "event.EvData.Reason"));
        const operators = noPregnancy[0].operators;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer className="print-display-none">
                        <div
                            className={`cell-wrapper ${hoveredID === nP.event.EvID ? "error" : "success"}`}
                            id={`${nP.event.EvID} EvTime`} onDoubleClick={onDoubleEventClick}
                            onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                            onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                            onClick={deletionMode ? onDeleteClick : undefined}
                        >
                            {getEventDate(nP)}
                        </div>
                    </CycleRenderer>
                }
                <CycleRenderer className="print-display-none last-cell" isAdvanced={isAdvanced} operators={operators}
                               deletionMode={deletionMode}>
                    <div className={`cell-wrapper ${hoveredID === nP.event.EvID ? "error" : "success"}`}
                         id={`${nP.event.EvID} EvData.Reason`} onDoubleClick={onDoubleEventClick}
                         onClick={deletionMode ? onDeleteClick : undefined}
                         onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                         onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                    >
                        {get(reason, "Value", "")}
                    </div>
                </CycleRenderer>
            </>
        )
    }
}

NoPregnancyRenderer.propTypes = {
    cycle: PropTypes.shape({
        noPregnancy: PropTypes.array.isRequired
    }).isRequired
};

export default compose(
    connect(mapStateToProps)
)(NoPregnancyRenderer);
