import React from "react";
import PropTypes from "prop-types";
import CycleRenderer from "./CycleRenderer";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {get, isEqual, sumBy} from "lodash";
import {getEventDate} from "./utils";
import {getAnimalUnit} from "../../../utils/SettingsUtils";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";

class WeightingSowRenderer extends React.Component {

    state = {
        hoveredID: "",
    };

    getPigletWeight = (data) => {
        return convertWeightUnitTo(get(data, "event.EvData.Weight"), {
            unit: getAnimalUnit(),
            showUnit: true,
            fixed: 2
        })
    };

    getAveragePigletWeights = () => {
        const {cycle: {weightings}} = this.props;
        let weight = 0;
        let _weightings = weightings.filter((w) => w?.event?.EvData?.Weight);
        if (weightings.length) {
            weight = sumBy(weightings, "event.EvData.Weight") / _weightings.length;
        }
        return convertWeightUnitTo(weight, {
            unit: getAnimalUnit(),
            showUnit: true,
            fixed: 2
        });
    };

    onMouseEnter = (event) => {
        const cell = get(event, "currentTarget");
        if (cell) {
            const EvID = cell.id.split(" ")[0];
            this.setState({
                hoveredID: EvID
            })
        }
    };

    onMouseLeave = () => {
        this.setState({
            hoveredID: ""
        })
    };

    getParturitionDate() {
        const {cycle: {parturitions}} = this.props;
        return get(parturitions, "[0].event.EvTime", null);
    }

    render() {
        const {
            t,
            cycle: {weightings, key},
            expanded: {rows},
            isAdvanced,
            deletionMode,
            onDeleteClick,
            onDoubleEventClick,
            printing,
            isInterrupted
        } = this.props;
        const {hoveredID} = this.state;
        let rowValue = rows.find(o => o === key);
        let firstWeighting = weightings[0];
        if (!firstWeighting) {
            return (
                <>
                    {
                        isAdvanced &&
                        <CycleRenderer isInterrupted={isInterrupted}/>
                    }
                    <CycleRenderer isInterrupted={isInterrupted}/>
                </>
            )
        }
        const operators = weightings[0].operators;
        const parturitionDate = this.getParturitionDate();
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer>
                        {
                            isAdvanced && (isEqual(key, rowValue) || printing) ?
                                weightings.map((weighting, index) => {
                                    const {event: {EvID}} = weighting;
                                    return (
                                        <div className={`cell-wrapper ${hoveredID === EvID ? "error" : "success"}`}
                                             key={index} id={`${EvID} EvTime`} onDoubleClick={onDoubleEventClick}
                                             onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                             onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                             onClick={deletionMode ? onDeleteClick : undefined}
                                        >
                                            {
                                                parturitionDate !== null ?
                                                    t("Xdays", {
                                                        count: getEventDate(weighting, {
                                                            inDays: true,
                                                            fromDay: parturitionDate
                                                        })
                                                    }) : "-"
                                            }
                                        </div>
                                    )
                                }) :
                                <div
                                    className={`cell-wrapper ${hoveredID === firstWeighting.event.EvID ? "error" : "success"}`}
                                    id={`${firstWeighting.event.EvID} EvTime`} onDoubleClick={onDoubleEventClick}
                                    onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                    onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                    onClick={deletionMode ? onDeleteClick : undefined}
                                >
                                    {
                                        parturitionDate !== null ?
                                            t("Xdays", {
                                                count: getEventDate(firstWeighting, {
                                                    inDays: true,
                                                    fromDay: parturitionDate
                                                })
                                            }) : "-"
                                    }
                                </div>
                        }
                    </CycleRenderer>
                }
                <CycleRenderer isAdvanced={isAdvanced} operators={operators}
                               deletionMode={deletionMode}>
                    {
                        isAdvanced && (isEqual(key, rowValue) || printing) ?
                            weightings.map((weighting, index) => {
                                const {event: {EvID}} = weighting;
                                return (
                                    <div className={`cell-wrapper ${hoveredID === EvID ? "error" : "success"}`}
                                         key={index} id={`${EvID} EvData.Weight`} onDoubleClick={onDoubleEventClick}
                                         onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                         onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                         onClick={deletionMode ? onDeleteClick : undefined}
                                    >
                                        {this.getPigletWeight(weighting)}
                                    </div>
                                )
                            }) :
                            <div
                                className={`cell-wrapper ${hoveredID === firstWeighting.event.EvID ? "error" : "success"}`}
                                id={`${firstWeighting.event.EvID} EvData.Weight`} onDoubleClick={onDoubleEventClick}
                                onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                                onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                                onClick={deletionMode ? onDeleteClick : undefined}
                            >
                                {this.getAveragePigletWeights()}
                            </div>
                    }
                </CycleRenderer>
            </>
        );
    }
}

WeightingSowRenderer.propTypes = {
    cycle: PropTypes.shape({
        weightings: PropTypes.array.isRequired
    }).isRequired
};

export default withTranslation(NameSpaces.TRANSLATION)(WeightingSowRenderer);
