import React from "react";
import TransferGrid from "../transfer/TransferGrid";
import GraftingProgramGridCard from "../grafting/GraftingProgramGridCard";
import HeatList from "../../grids/animal-documents/HeatList";
import AnimalDocumentsComments from "../comments/AnimalDocumentsComments";
import WeightingSingleChart from "../weighting/WeightingSingleChart";
import AnimalModificationList from "../animal-modification-list/AnimalModificationList";

export default class RenovationSowCard extends React.Component {

    render() {
        return (
            <>
                <WeightingSingleChart/>
                <AnimalDocumentsComments/>
                <GraftingProgramGridCard/>
                <HeatList/>
                <TransferGrid/>
                <AnimalModificationList/>
            </>
        );
    }

}
