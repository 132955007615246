export const initialValue = {
    lastRFIDScanned: null,
    rfidScanned: []
};

export default function rfidReducer(state = initialValue, action) {
    switch (action.type) {

        case "SCANNED_RFID": {
            return {
                ...state, lastRFIDScanned: action.payload
            }
        }
        case "PUSH_RFID_VALUES": {
            const rfidScanned = state.rfidScanned.slice(0);
            rfidScanned.unshift(
                {
                    ...action.payload,
                    date: new Date().getTime()
                });
            return {
                ...state, rfidScanned
            }
        }


        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
