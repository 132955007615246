import React from "react";
import {useTranslation} from "react-i18next";
import {parseElectric} from "../parsers";
import DeviceDefaultContent from "./DeviceDefaultContent";


const ElectricContent = (props) => {
    const {t} = useTranslation();
    return <DeviceDefaultContent {...props} parseDataFn={parseElectric}
                                 name={t("deviceRows.electricityFlowMeter.electricityFlowMeterRow.electricityConsumedToday")}
                                 paramLabel={t("averageTemperature")}/>
}


export default React.memo(ElectricContent);