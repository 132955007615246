import {utils} from "xlsx"

export default class Sheet {

    constructor(data) {
        Object.assign(this, utils.aoa_to_sheet(data))
    }

    addData(data, origin) {
        utils.sheet_add_aoa(this, data, {
            origin
        })
    }

}