import { InventorySortType } from "../selectors/inventorySelector";
import { DefaultInventoryValues } from "../utils/InventoryUtils";

export const initialValue = {
    inventorySort: InventorySortType.BY_ANIMAL_NUMBER_DESC,
    loading: true,
    inventoryFilter: "",
    locationFilter: "",
    showDead: false,
    animalTypeMap: new Map(),
    showEmpty: false,
    showDeleted: false,
    showAge: false,
    formatAge: null,
    showHerdNumber: false,
    inventoryAnimalKind: null,
    showRace: false,
    showCodeRace: false,
    showTattoo: false,
    raceFilter: "",
    inventoryColumns: DefaultInventoryValues
};

export default function inventoryReducer(state = initialValue, action) {
    switch (action.type) {
        case "INVENTORY_INITIALIZE": {
            const { animalTypeMap = new Map() } = action.payload;
            return { ...state, loading: false, animalTypeMap };
        }
        case "INVENTORY_FILTER": {
            const { inventoryFilter = "" } = action.payload;
            return { ...state, inventoryFilter: inventoryFilter };
        }
        case "INVENTORY_ANIMAL_KIND": {
            const { inventoryAnimalKind } = action.payload;
            return { ...state, inventoryAnimalKind };
        }
        case "INVENTORY_SORT": {
            const { inventorySort } = action.payload;
            return { ...state, inventorySort };
        }
        case "INVENTORY_LOCATION_FILTER": {
            const { location } = action.payload;
            return { ...state, locationFilter: location ? location : null };
        }
        case "INVENTORY_LOCATION_FILTER_ISFARM": {
            const { isFarm } = action.payload;
            return { ...state, locationIsFarm: isFarm };
        }
        case "INVENTORY_SHOW_DEAD": {
            const { showDead } = action.payload;
            return { ...state, showDead: !!showDead };
        }
        case "INVENTORY_SHOW_EMPTY": {
            const { showEmpty } = action.payload;
            return { ...state, showEmpty: !!showEmpty };
        }
        case "INVENTORY_SHOW_DELETED": {
            const { showDeleted } = action.payload;
            return { ...state, showDeleted: !!showDeleted };
        }
        case "INVENTORY_SHOW_AGE": {
            const { showAge } = action.payload;
            return { ...state, showAge: !!showAge };
        }
        case "INVENTORY_FORMAT_AGE": {
            const { formatAge } = action.payload;
            return { ...state, formatAge };
        }
        case "INVENTORY_SHOW_HERD_NUMBER": {
            const { showHerdNumber } = action.payload;
            return { ...state, showHerdNumber: !!showHerdNumber };
        }
        case "INVENTORY_SHOW_RACE": {
            const { showRace } = action.payload;
            return { ...state, showRace: !!showRace };
        }
        case "INVENTORY_SHOW_CODE_RACE": {
            const { showCodeRace } = action.payload;
            return { ...state, showCodeRace: !!showCodeRace };
        }
        case "INVENTORY_SHOW_TATTOO": {
            const { showTattoo } = action.payload;
            return { ...state, showTattoo: !!showTattoo };
        }
        case "INVENTORY_RACE_FILTER": {
            const { raceFilter = "" } = action.payload;
            return { ...state, raceFilter };
        }
        case "INVENTORY_CHANGE_TABLE": {
            const { inventoryColumns } = action.payload;
            return { ...state, inventoryColumns };
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state;
    }
}
