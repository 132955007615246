import {useCallback, useEffect, useMemo, useState} from "react";
import {getAnimalHistoryOfChanges} from "../../../api/logs/getAnimalHistory";
import {useTranslation} from "react-i18next";
import {getKeyValueLogDict} from "../../../selectors/logSelector";
import {useSelector} from "react-redux";
import {LogParser} from "../../../beans/LogParser";
import updateEventsHandler from "./handlers/updateEvents.handler";
import deleteEventsHandler from "./handlers/deleteEvents.handler";
import {isFunction, memoize, flatten} from "lodash";
import deleteAnimalHandler from "./handlers/deleteAnimal.handler";
import updateAnimalHandler from "./handlers/updateAnimal.handler";
import {getAllAnimals} from "../../../selectors/animalSelector";
import {formatLocationName} from "../../../utils/global-formatters/formatLocationName";

const useAnimalFormatter = () => {
    const aliveAnimals = useSelector(getAllAnimals);

    const resolveAnimalById = useMemo(() => {
        return memoize((animalId) => aliveAnimals.find((a) => a.AnmID === animalId));
    }, [aliveAnimals]);

    return useCallback((animalId) => {
        const animal = resolveAnimalById(animalId);
        if (!animal) return animalId;
        if (animal.RFID || animal.Tagged) {
            return animal.AnmNo1;
        }
        const isAtFarm = animal.PlcmntID === animal.FarmID;
        return formatLocationName(isAtFarm ? (animal.DelPlcmntID ?? animal.PlcmntID) : animal.PlcmntID, {nameDeep: 2});
    }, [resolveAnimalById]);


}

export default (animalIds) => {
    const key = JSON.stringify(animalIds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const AnmIDs = useMemo(() => animalIds, [key]);

    const [data, setData] = useState(null);

    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const abortController = new AbortController(); // wiadomo to nie wstyd
        setError(null);
        setLoading(true);
        Promise.all(AnmIDs.map((AnmID) => {
            return getAnimalHistoryOfChanges({ObjID: AnmID, signal: abortController.signal})
        })).then((res) => {
            setData(flatten(res));
        }).catch((e) => {
            setError(e.message)
        }).finally(() => {
            setLoading(false);
        });
        return () => {
            abortController.abort();
        }
    }, [AnmIDs]);

    const {t} = useTranslation();

    const dictionaries = useSelector(getKeyValueLogDict);

    const handlers = useMemo(() => {
        return [
            updateEventsHandler,
            deleteEventsHandler,
            deleteAnimalHandler,
            updateAnimalHandler
        ]
    }, []);

    const animalFormatter = useAnimalFormatter();

    const translationManager = useMemo(() => {
        const log = new LogParser({}, dictionaries, false);
        return {
            translate: t,
            format: (formatter, value) => {
                // customowy formatter tylko dla widoku karty grup
                if (formatter === "subgroupNameFormatter") return animalFormatter(value);
                // resztę formatujmy tym co logi
                log.log = {value};
                const path = "value";
                return log.getFormatter(formatter)({path, defaultValue: null});
            }
        }
    }, [dictionaries, t, animalFormatter])

    const transformed = useMemo(() => {
        if (!data) return [];
        const getHandler = memoize((codes) => {
            let handler = null, code = 0, index = 0;
            handlerLoop: for(let h of handlers) {
                index = 0;
                for(let c of codes) {
                    if(c.match(h.RegExp)) {
                        handler = h;
                        code = c;
                        break handlerLoop;
                    }
                    index++;
                }
            }
            return {handler, code, index};
        }, (codes) => codes.join("+"));
        return data
            .map((rawLog) => {
                const {handler, code, index} = getHandler(rawLog.Codes);
                if (!isFunction(handler)) {
                    // API zwróciło jakiś kod log-a, ale nie mamy obsłużonego go na froncie
                    console.warn("handler not found for %s", rawLog.Codes);
                    return null;
                }
                const log = new LogParser(rawLog, dictionaries, false);
                return handler({log, translationManager, code, index});
            })
            .filter((o) => o)
            .sort((o1, o2) => o2.timestamp - o1.timestamp);
    }, [data, translationManager, dictionaries, handlers]);

    return {data: transformed, loading, error};
}