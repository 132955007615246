export const reportStatus = {
    LOADING: "RUNNING",
    READY: "SUCCEEDED",
    FAILED: "FAILED",
    QUERY_EXECUTION_NOT_STARTED_FATAL: "QUERY_EXECUTION_NOT_STARTED_FATAL",
    INVALID_QUERY_PARAMETERS: "INVALID_QUERY_PARAMETERS",
    CANCELLED: "CANCELLED",
    REMOVING: "REMOVING"
};

export const reportType = {
    BIRTH: "birth",
    IDLE: "idle",
    INSEMINATION: "insemination",
    RANKING: "animalsRanking",
    LIST_OF_INSEMINATION: "listOfInsemination",
    SEPARATION: "separation",
    STRUCTURE: "structure",
    SELECTION: "selection",
    DEAD: "dead",
    GILTS_DEAD:'giltsDead',
    GILTS:'gilts',
    GILTS_SEPARATION:'giltsSeparation',
    SALES: "sale",
    TREATMENT: "treatment",
    MEDICINE_CONSUMPTION: "medicineConsumption",
    HISTORY_OF_EVENTS: "historyOfEvents",
    COMMENTS: "comments",
    // karmienie
    DISPENSER_USAGE_Q03: "Q03_sumoffeedings_by_devid",
    DISPENSER_USAGE_Q49: "Q49_forage_use_per_device",
    DISPENSER_USAGE_Q51: "Q51_feedings_for_animalid",
    SILO_FORAGE_FLOW_Q53: "Q53_silo_forage_flow",
    FORAGE_USE_Q54: "Q54_forage_no_fti",
    FORAGE_USE_Q55: "Q55_forage_with_fti",
    SILO_IN_Q56: "Q56_silo_in",
    Q57: "Q57_feedings_for_animalid_pre",
    // klimat
    TEMPERATURE_STATS_Q52: "Q52_devices_temperatures_stats",
    // preevents
    Q1001_EVENTS_FOR_PREVENTS: "Q1001_events_for_preevents",
    // topigs
    TOPIGS_Q1000: "Q1000_excel", //Q102_excel_farrowing, Q103_excel_weaning, Q104_excel_tattooing, Q105_excel_insemination, Q106_excel_arrival
    // uzywane przy generowaniu raportu topgis, nie mozna wyklikac bezposrednio z apki
    Q101: "Q101_events_for_preevents",
    Q102: "Q102_excel_farrowing",
    Q103: "Q103_excel_weaning",
    Q104: "Q104_excel_tattooing",
    Q105: "Q105_excel_insemination",
    Q106: "Q106_excel_arrival",
};

export const reportCategory = {
    ALL: "all",
    BREEDING: "breeding",
    SALE: "sale",
    TREATMENT: "treatment",
    MANAGEMENT: "management",
    FEEDING: "feeding",
    CLIMATE: "climate"
};
