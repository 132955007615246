import { isAnimalRemovedOrSoldOrFallen } from "../../utils/AnimalsUtils";
import React from "react";
import i18next from "i18next";

/**
 * bind inside Grid component, make sure you have getAnimalById and onRemoveClick methods implemented
 * @param props
 * @return {JSX.Element|null}
 */
export function removeRenderer(props) {
    const { value: { AnmID, EvID, EvData } } = props;
    if (EvData?.SaleID) return null;
    const { mostRecentEvents } = this.props;
    const animal = this.getAnimalById(AnmID);
    const isDeadge = isAnimalRemovedOrSoldOrFallen(animal);
    // jeśli zwierzę jest upadnięte/usunięte/sprzedane to do usunięcia dostępny jest tylko najnowszy event
    const show = isDeadge ? mostRecentEvents?.[AnmID]?.EvID === EvID : true;
    if (!show) return null;
    // jeśli zwierzę jest usunięte serwisowo to blokujemy usuwanie #10395
    if (animal?.DtaDelTime) return (
        <i title={i18next.t("groupDeleted")} className={"fas fa-trash opacity-25"} />
    );
    console.log(props);
    let showNotRecentLocationError = false;
    // jeśli zwierzę nie jest w aktualnej lokalizacji co event to blokujemy takie usuwanie
    console.log(EvData?.PlcmntID, animal?.DelPlcmntID, animal?.PlcmntID);
    if (animal?.DtaDthTime) {
        // gdy całe zostało usunięte to powinno mieć pod kluczem `DelPlcmntID`
        showNotRecentLocationError = EvData?.PlcmntID !== animal?.DelPlcmntID;
    } else if (EvData?.PlcmntID !== animal?.PlcmntID) { // gdy częściowo to `PlcmntID`
        showNotRecentLocationError = true;
    }
    if (showNotRecentLocationError) return (
        <i title={i18next.t("rfidApp.noAnimalsInCurrentLocation")} className={"fas fa-trash opacity-25"} />
    );
    return (
        <i
            className="fas fa-trash pointer"
            onClick={() => this.onRemoveClick(props)}
        />
    );
}
