import React from "react";
import {useTranslation} from "react-i18next";
import {parseClimate} from "../parsers";
import DeviceDefaultContent from "./DeviceDefaultContent";


const ClimateContent = (props) => {
    const {t} = useTranslation();
    return <DeviceDefaultContent {...props} parseDataFn={parseClimate} name={t("braintree.addons.FRM_Climates.0")}
                                 paramLabel={t("averageTemperature")}/>
}


export default React.memo(ClimateContent);