import { useMemo } from "react";
import devicesDB from "../database/devicesDB";
import { flatten } from "lodash";

export default function useDevicesInLocations(locations, DevTypes) {
    const devices = useMemo(() => {
        return flatten(locations.map(location => devicesDB.getDevicesInPlcmntID(location))).filter(item => DevTypes.includes(item.DevType));
    }, [locations, DevTypes]);

    return devices;
}