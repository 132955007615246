import React, {Component} from "react";
import {Fade, Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import {removeClass, scrollHandler} from "../scroll-fix/ScrollFix";
import {catchify} from "../../utils/Utils";

class ModalBody extends Component {
    constructor(props) {
        super(props);
        this.body = React.createRef();
    }

    componentDidMount() {
        const {disableFocus} = this.props;
        if (!disableFocus) {
            this.setFocus();
        }
        // dodaj blokowanie eventów na scrollu, żeby użytkownik przypadkowo nie kliknął palcem w jakiś input
        this.addDisableEventsOnScrollListener();
    }

    addDisableEventsOnScrollListener = catchify(() => {
        document.getElementsByClassName("modal-dialog")[0].parentElement.addEventListener("scroll", scrollHandler);
    });

    removeDisableEventsOnScrollListener = catchify(() => {
        document.getElementsByClassName("modal-dialog")[0].parentElement.removeEventListener("scroll", scrollHandler);
    })

    setFocus = () => {
        this.focusTimeout = setTimeout(() => {
            if (this.body.current) {
                const availableInputs = [...this.body.current.getElementsByTagName("input")];
                for (const input of availableInputs) {
                    // zaznacz pierwszy edytowalny input, który nie jest selectem
                    if (input.readOnly === false && input.type !== "search") {
                        input.focus();
                        break;
                    }
                }
            }
        }, 200)
    };

    componentWillUnmount() {
        clearTimeout(this.focusTimeout);
        this.removeDisableEventsOnScrollListener();
        removeClass();
    }

    render() {
        const {className, isLoading, children} = this.props;
        return (
            <Modal.Body className={className} ref={this.body}>
                <Fade in={isLoading} timeout={500} unmountOnExit={true}>
                    <div className="loading">
                        <i className="fas fa-circle-notch fa-spin"/>
                    </div>
                </Fade>
                {children}
            </Modal.Body>
        );
    }
}

export default (ModalBody)

ModalBody.propTypes = {
    className: PropTypes.string, //doatkowa nazwa
    isLoading: PropTypes.bool,
    children: PropTypes.node.isRequired,
    disableFocus: PropTypes.bool
};

ModalBody.defaultProps = {
    isLoading: false
};
