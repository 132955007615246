import {isFinite, isNil} from "lodash";
import {enhancedComparer} from "../TextUtils";
import memoizeOne from "memoize-one";

const isEqualStringify = (newArgs, lastArgs) => JSON.stringify(newArgs) === JSON.stringify(lastArgs);

/**
 * funkcja zamieniajaca obiekty budynkow na stary typ dla zachowania kompatybilnosci wstecznej
 * jest w osobnym pliku do webpack dostaje memory leaka gdy jest w Utilsach od Budynków
 * @param buildings
 * @return {*}
 */
export const convertBuildingsToOldObject = (buildings) => {
    // console.time("convertBuildingsToOldObject");
    // zwracamy kopie wartości memoiza bo mutowanie w naszej apce jest na porządku dziennym
    // console.timeEnd("convertBuildingsToOldObject");
    return JSON.parse(JSON.stringify(_convertBuildingsToOldObject(buildings)));
}


const _convertBuildingsToOldObject = memoizeOne((_buildings) => {
    const buildings = JSON.parse(JSON.stringify(_buildings || []))
    return buildings.map((building) => {
        if (isFinite(building.DtaDelTime)) return null;
        if (building.Sectors) {
            building.Sectors = building.Sectors.filter((sector) => isNil(sector.DtaDelTime));
            // building.Sectors.sort((o1, o2) => enhancedComparer(o1.SName, o2.SName, {numeric: true}));
            building.Sectors = building.Sectors.map((sector) => {
                if (sector.Chambers) {
                    sector.Chambers = sector.Chambers.filter((chamber) => isNil(chamber.DtaDelTime));
                    // sector.Chambers.sort((o1, o2) => enhancedComparer(o1.CName, o2.CName, {numeric: true}));
                    sector.Chambers = sector.Chambers.map((chamber) => {
                        if (!chamber.IndividualFeeding) {
                            delete chamber.Boxes;
                        } else if (chamber.Boxes) {
                            chamber.Boxes = chamber.Boxes.filter((box) => isNil(box.DtaDelTime));
                            // add this flag on db if user had deliberately organized standings in a chaotic way
                            // i.e: `'A61'-'A1','H1'-'H64'` etc. (normal way is `1-n` or `'Standing 1' - 'Standing N'` [legacy])
                            if(!chamber._do_not_sort) {
                                chamber.Boxes.sort((o1, o2) => enhancedComparer(o1.BoxesName, o2.BoxesName, {numeric: true}));
                            }
                        } else {
                            chamber.Boxes = [];
                        }
                        return chamber;
                    });
                }
                return sector;
            });
        } else {
            building.Sectors = [];
        }
        return building;
    })
        .filter((o) => !!o);
}, isEqualStringify);
