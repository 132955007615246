const stage = process.env.REACT_APP_STAGE;
const GONZO_USER_POOL_ID = "eu-central-1_eM38GHx1r";
const GONZO_APP_CLIENT_ID = "62pm8ngk159n9it8m1or1v25t7";

// const DEV_USER_POOL_ID = "eu-central-1_S0G5GCXTw";
// const DEV_APP_CLIENT_ID = "4onajf61cfms7rjirfn85pilqg";

// const STAGING_USER_POOL_ID = "eu-central-1_tD9dV4Siw";
// const STAGING_APP_CLIENT_ID = "pma0l01hk2go5kted6l4bj5m1";

// const PROD_USER_POOL_ID = "eu-central-1_gmKzv6hQG";
// const PROD_APP_CLIENT_ID = "573gftkaaekcjl5use98q5r9ph";


const stageConfigs = {
    development: {
        cognito: {
            USER_POOL_ID: GONZO_USER_POOL_ID,
            APP_CLIENT_ID: GONZO_APP_CLIENT_ID
        },
        api: {
            URL: "https://dev.fetura.com.pl/"
        },
        iot: {
            PREFIX: `dev/`
        },
        stageName: "dev",
        braintree: {
            BT_ENVIRONMENT: "Sandbox",
            BT_MERCHANT_ID: "qpm8f8g4gvkykh5b",
            BT_PUBLIC_KEY: "49g4syzd34nfy243",
            BT_PRIVATE_KEY: "bd6a33d1f9f2c086f040865b8d1ff622"
        },
        analytics: {
            token: "G-4KBX0QS2B2"
        }
    },
    staging: {
        cognito: {
            USER_POOL_ID: GONZO_USER_POOL_ID,
            APP_CLIENT_ID: GONZO_APP_CLIENT_ID
        },
        api: {
            URL: "https://stage.fetura.com.pl/"
        },
        iot: {
            PREFIX: `staging/`
        },
        stageName: "staging",
        braintree: {
            BT_ENVIRONMENT: "Sandbox",
            BT_MERCHANT_ID: "qpm8f8g4gvkykh5b",
            BT_PUBLIC_KEY: "49g4syzd34nfy243",
            BT_PRIVATE_KEY: "bd6a33d1f9f2c086f040865b8d1ff622"
        },
        analytics: {
            token: "G-SBXY024R2W"
        }
    },
    production: {
        cognito: {
            USER_POOL_ID: GONZO_USER_POOL_ID,
            APP_CLIENT_ID: GONZO_APP_CLIENT_ID
        },
        api: {
            URL: "https://prod.fetura.com.pl/"
        },
        iot: {
            PREFIX: `prod/`
        },
        stageName: "prod",
        braintree: {
            BT_ENVIRONMENT: "Sandbox",
            BT_MERCHANT_ID: "qpm8f8g4gvkykh5b",
            BT_PUBLIC_KEY: "49g4syzd34nfy243",
            BT_PRIVATE_KEY: "bd6a33d1f9f2c086f040865b8d1ff622"
        },
        analytics: {
            token: "G-98TYN4631F"
        }
    },
    demo: {
        cognito: {
            USER_POOL_ID: GONZO_USER_POOL_ID,
            APP_CLIENT_ID: GONZO_APP_CLIENT_ID
        },
        api: {
            URL: "https://stage.fetura.com.pl/"
        },
        iot: {
            PREFIX: `staging/`
        },
        stageName: "staging",
        braintree: {
            BT_ENVIRONMENT: "Sandbox",
            BT_MERCHANT_ID: "qpm8f8g4gvkykh5b",
            BT_PUBLIC_KEY: "49g4syzd34nfy243",
            BT_PRIVATE_KEY: "bd6a33d1f9f2c086f040865b8d1ff622"
        },
        analytics: {
            token: "G-4STP3JMZVS"
        }
    }
};
const userPool = stageConfigs[stage] ? stageConfigs[stage].cognito : stageConfigs.development.cognito; // bezpieczniej zawsze defaulltowo korzystać z dev cognito, gdy coś pójdzie nie tak - nie zepsujemy u klienta
const apiData = stageConfigs[stage] ? stageConfigs[stage].api : stageConfigs.development.api; // defaultowo bierzemy developa
const braintree = stageConfigs[stage] ? stageConfigs[stage].braintree : stageConfigs.development.braintree; // bezpieczniej zawsze defaulltowo korzystać z dev portfela, gdy coś pójdzie nie tak - nie zepsujemy na prawdziwych płatnościach
const analytics = stageConfigs[stage] ? stageConfigs[stage].analytics : stageConfigs.development.analytics;
const iot = stageConfigs[stage] ? stageConfigs[stage].iot : stageConfigs.development.iot;
const stageName = stageConfigs[stage] ? stageConfigs[stage].stageName : stageConfigs.development.stageName;

export default {
    cognito: {
        ...userPool,
        REGION: "eu-central-1",
        IDENTITY_POOL_ID: "eu-central-1:419e1198-ad2a-4ada-9921-895a83a624ca",
    },
    MAX_ATTACHMENT_SIZE: 5000000,
    apiGateway: {
        //URL: "https://4xj42tnrjh.execute-api.eu-central-1.amazonaws.com/dev", // old old
        // URL: "https://dev-services.fetura.info.pl/", // old dev
        // URL: "https://staging-services.fetura.info.pl/", // old staging
        // URL: "https://services.fetura.info.pl/", // old prod
        //URL: "https://e3tghr6yh0.execute-api.eu-central-1.amazonaws.com/dev",
        ...apiData, // new
        REGION: "eu-central-1"
    },
    s3: {
        BUCKET: "gonzo-central"
    },
    sns: {
        platformApplicationArn: "arn:aws:sns:eu-central-1:379932291578:app/GCM/FeturaSNS",
        mainTopic: "arn:aws:sns:eu-central-1:379932291578:Fetura"
    },
    braintree,
    analytics,
    stageName,
    iot
};
