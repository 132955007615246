import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from "../../input/Input";
import moment, { isMoment } from "moment";
import {myID} from "../../../../libs/generateID";
import {normalizeRFID} from "../../../../utils/NormalizeFunctions";
import {isFunction} from "lodash";

class InputEditor extends Component {

    id = myID();
    container = React.createRef();

    onKeyDown = e => {
        const {type, row, column: {key}, onRowChange} = this.props;
        if (type === "date" || type === "datetime-local") {
            if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
                let value = row[key] ? row[key].clone() : moment();
                if (e.key === "ArrowUp") {
                    value.add(1, "day");
                } else if (e.key === "ArrowDown") {
                    value.subtract(1, "day");
                }
                onRowChange({...row, [key]: value});
                e.stopPropagation();
            }
        }
        return false;
    }

    onChange = (value) => {
        const {type, allowFloat, maxWidth, onRowChange, row, column: {key}, rfid, onChange, childKey} = this.props;
        if (type === "date" || type === "datetime-local") {
            const date = moment(value);
            const newRow = {...row, [key]: date.isValid() ? date : row[key] || moment()};
            onRowChange(isFunction(onChange) ? onChange(newRow) : newRow);
        } else if (type === "number") {
            if (rfid) {
                onRowChange({...row, [key]: normalizeRFID(value)});
            } else {
                if (maxWidth) {
                    let tmp = value + "";
                    if (tmp.length > maxWidth) {
                        tmp = tmp.slice(0, maxWidth);
                        value = tmp;
                    }
                }
                let val = value.replace("e", "");
                val = parseFloat(val);
                if (isNaN(val)) {
                    onRowChange({...row, [key]: null});
                } else {
                    onRowChange({...row, [key]: allowFloat ? val : Math.floor(val)});
                }
            }
        } else {
            if (maxWidth && value.length > maxWidth) {
                value = value.slice(0, maxWidth);
            }
            const _value = value === "" ? null : value;
            const _row = {...row};
            if (childKey) _row[key][childKey] = _value;
            else _row[key] = _value;
            onRowChange(_row);
        }
    };

    isDisabled() {
        const {disabled} = this.props;
        if (typeof disabled === "function") {
            return disabled(this.props);
        }
        return disabled;
    }

    getValue() {
        const {row, column: {key}, type, onRowChange, childKey} = this.props;
        if (type === "date" || type === "datetime-local") {
            if (row[key] && isMoment(row[key])) {
                return row[key].format(type === "date" ? moment.HTML5_FMT.DATE : moment.HTML5_FMT.DATETIME_LOCAL);
            }
            let date = moment();
            onRowChange({...row, [key]: date});
            return date.format(type === "date" ? moment.HTML5_FMT.DATE : moment.HTML5_FMT.DATETIME_LOCAL);
        }
        const value = row[key];
        if (childKey) return value[childKey];
        return value;
    }

    autoFocusAndSelect(input) {
        if (input) {
            input.focus();
            input.select();
        }
    }

    render() {
        const {type, height, unit} = this.props;
        return (
            <div ref={this.container}>
                <Input type={type} onChange={this.onChange} onKeyDown={this.onKeyDown}
                       value={this.getValue()} style={{height}} disabled={this.isDisabled()} id={this.id}
                       unit={unit} inputRef={this.autoFocusAndSelect}
                       max={type === "date" || type === "datetime-local" ? "9999-12-31" : null}/>
            </div>
        );
    }
}

InputEditor.propTypes = {
    type: PropTypes.string.isRequired,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    allowFloat: PropTypes.bool,
    unit: PropTypes.string,
    maxWidth: PropTypes.number,
    rfid: PropTypes.bool,
    childKey: PropTypes.string
};

InputEditor.defaultProps = {
    allowFloat: true
}

export default InputEditor;
