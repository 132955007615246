//jak ktoś coś bedzię tutaj zmieniał to prosze wyszukać frazę "adam-malysz-koronawirus" i zmienić kolejnosć kluczy w tablicy w tamtym miejscu
export const medicineTypes = {
    STIMULATOR: 1,
    FORAGE: 2,
    VACCINE: 3,
    DOSATRON: 4 // nie wiem jaka nazwa, moze sie zmienic na leki plynne albo cos w tym rodzaju
};

export const castrationTypes = {
    CASTRATE: 1,
    TAILS: 2,
    TEETH: 3,
    WEIGHTING: 4
};
