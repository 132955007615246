import moment from "moment";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getSelectedAnimalForDocuments } from "../../../selectors/animalDocumentsSelectors";

export default function useAnimalSingleInfo() {

    const animal = useSelector(getSelectedAnimalForDocuments);

    const { start, end } = useMemo(() => {
        return {
            start: animal.DtaBrthTime,
            end: animal.DtaDthTime || animal.DtaDelTime || +moment.utc().startOf("day")
        }
    }, [animal])

    return useMemo(() => ({
        birthTime: animal.DtaBrthTime,
        start,
        end,
    }), [animal, start, end])
}