import React from "react";
import "./_no-role-component.scss";
import Card from "./basics/card/Card";
import {logout} from "../utils/UserUtils";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Button from "./basics/button/Button";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

export function mapStateToProps(state) {
    return {
        isAuthenticated: state.user.isAuthenticated
    }
}

class NoRoleComponent extends React.Component {

    onLogoutClick = () => {
        logout();
    };

    onBackClick = () => {
        this.props.history.push("/");
    };

    render() {
        const {t, isAuthenticated} = this.props;
        return (
            <div className={"no-role-component"}>
                <Row bsPrefix={"row h-100 justify-content-center align-items-center w-100 mx-auto"}>
                    <Col className={"col-auto"}>
                        <Card>
                            <h4 className={"align-items-center"}>
                                <i
                                    className="fas fa-exclamation-triangle fa-fw"/> {t('noRoleComponent.accessForbidden')}
                            </h4>
                            <p>
                                {t("noRoleComponent.chooseAction")}{' '}
                                <Button type={"button"} onClick={this.onBackClick}>
                                    {t("noRoleComponent.homePage")}
                                </Button>
                                {
                                    isAuthenticated &&
                                    <>
                                        {' '}{t("noRoleComponent.or")}{' '}
                                        <Button type={"button"} onClick={this.onLogoutClick}>
                                            {t("noRoleComponent.logout")}
                                        </Button>
                                    </>
                                }
                            </p>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    withRouter
)(NoRoleComponent);
