import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {withTranslation} from "react-i18next";

class DateItem extends React.Component {

    onClick = () => {
        this.props.onClick(this.props.date);
    };

    render() {
        const {date, transform, selected} = this.props;
        return (
            <div className={selected ? "date-item selected" : "date-item"}
                 style={{transform: `translate(-${transform}%, 0)`}} onClick={this.onClick}>
                <div className="date-item-content">
                    <div className="date-title">
                        {date.format("DD.MM")}
                    </div>
                    <div className="date-text">
                        {date.format("ddd")}
                    </div>
                </div>
            </div>
        );
    }

}

export default withTranslation()(DateItem);

DateItem.propTypes = {
    date: PropTypes.instanceOf(moment).isRequired,
    transform: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool
};
