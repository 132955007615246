import React from "react";
import PropTypes from "prop-types";
import CycleRenderer from "./CycleRenderer";
import {findIndex} from "lodash";
import {checkIfRowHasMoreEvents} from "../../../utils/AnimalDocumentsUtils";

export default class ExpandRenderer extends React.Component {

    onClick = () => {
        const {cycle: {key}, onPress} = this.props;
        const isOpened = this.isOpened();
        onPress(key, !isOpened);
    };

    isOpened = () => {
        const {cycle: {key}, expanded: {rows}} = this.props;
        return findIndex(rows, (n) => n === key) !== -1;
    };

    render() {
        const {cycle} = this.props;
        const shouldExpand = checkIfRowHasMoreEvents(cycle);
        const opened = this.isOpened();
        if (shouldExpand) {
            return (
                <>
                    <CycleRenderer className="print-display-none">
                        <div onClick={this.onClick} className="cell-wrapper">
                            <i className={`fas fa-chevron-${opened ? 'up' : 'down'}`}/>
                        </div>
                    </CycleRenderer>
                </>
            );
        } else {
            return <CycleRenderer className="print-display-none"/>
        }
    }

}

ExpandRenderer.propTypes = {
    cycle: PropTypes.shape({
        shouldExpand: PropTypes.bool.isRequired
    }).isRequired
};

