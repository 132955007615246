import {clearCanvas} from "../../../utils/DOMUtils";
import {limitCanvasSize} from "../../../utils/FarmMapUtils";
import classifyPoint from "robust-point-in-polygon";

const __defaultOptions__ = {
    collisionType: "pixel"
}

export default class Collision {

    /**
     * obiekty musza implementować metodę getPath2D
     * @param obj1
     * @param obj2
     * @param collisionType
     */
    static isCollision(obj1, obj2, {collisionType = __defaultOptions__.collisionType} = __defaultOptions__) {
        if (!["pixel", "noCanvas"].includes(collisionType)) {
            collisionType = __defaultOptions__.collisionType;
        }
        return Collision[`_${collisionType}Collision`](obj1, obj2);
    }

    /**
     * this is VERY slow
     * @param polygons
     * @param point
     * @return {boolean}
     * @private
     */
    static _pointInPolyCollision(polygons, point) {
        // transform data to match `classifyPoint` args
        const vs = polygons.map(({x, y}) => [x, y]);
        const p = [point.x, point.y]
        // result -1 (outside), 0 (bounds), 1 (inside)
        const result = classifyPoint(vs, p);
        return result !== -1;
    }

    static _checkIfRectsIntersect(r1, r2) {
        return (
            r1.minX < r2.maxX && r1.maxX > r2.minX &&
            r1.minY < r2.maxY && r1.maxY > r2.minY
        )
    }

    static _noCanvasCollision(obj1, obj2) {
        const v1 = obj1.getVertices();
        const v2 = obj2.getVertices();
        return v1.some((p) => Collision._pointInPolyCollision(v2, p));
    }

    // trzeba wyrenderowac jeden collision w parencie zeby uzywac funkcji
    // zasada dzialania render 2 pathy w canvasie z opacity i sprawdzenie czy sie nakladaja po pixelach
    static _pixelCollision(obj1, obj2) {
        const rect1 = obj1.getRect(), rect2 = obj2.getRect();
        let collides = false;
        if (Collision._checkIfRectsIntersect(rect1, rect2)) {
            const canvas = document.getElementById("collision-canvas");
            if (!canvas) return true;
            // apply boolean algebra to only get part of rect which is intersecting
            const intersectingRect = {};
            intersectingRect.x = Math.max(rect1.x, rect2.x);
            intersectingRect.y = Math.max(rect1.y, rect2.y);
            intersectingRect.maxX = Math.min(rect1.maxX, rect2.maxX);
            intersectingRect.maxY = Math.min(rect1.maxY, rect2.maxY);
            intersectingRect.width = Math.ceil(intersectingRect.maxX - intersectingRect.x);
            intersectingRect.height = Math.ceil(intersectingRect.maxY - intersectingRect.y);
            // use small canvas size so the operation will be fast
            const {width, height, scale} = limitCanvasSize(intersectingRect, 128 * 128);
            // resize and clear canvas
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d", {willReadFrequently: true});
            // detection is quite good
            // 1. render half opaque object
            // 2. render other object the same way
            // 3. check if one pixel has greater value than opaque one
            // 4. done
            ctx.fillStyle = `rgba(0, 0, 0, 0.5)`;
            // move and scale object to the beginning of canvas
            ctx.scale(scale, scale);
            ctx.translate(-intersectingRect.x, -intersectingRect.y);
            // draw objects
            ctx.fill(obj1.getPath2D());
            ctx.fill(obj2.getPath2D());
            // get only common data
            try {
                const data = ctx.getImageData(0, 0, width, height).data;
                // check if one point overlaps
                for (let i = 3; i < data.length; i += 4) {
                    if (data[i] > 128) {
                        collides = true;
                        break;
                    }
                }
            } catch (e) {
                // data with is equal to 0
            }
            // help GC and clear the canvas manually
            clearCanvas(canvas);
        }
        return collides;
    }
}
