import { convertWeightUnitTo } from "./UnitUtils";
import { UnitTypes } from "../constans/unitTypes";
import { get } from "lodash";

export function getPriceNet(data, unitSystem) {
    let weight = convertWeightUnitTo(data?.Weight || 0, { unit: UnitTypes.MEDIUM, rawValue: true, unitSystem });
    let price = data?.PriceForOneWeightUnit || 0;
    return weight * price;
}

export function getSumOfPriceNet(data, unitSystem) {
    const { ClassS, ClassE, ClassU, ClassR, ClassO, ClassP, AllOutSEUROP, AllConfiscations } = data;
    let classSPriceNet = getPriceNet(ClassS, unitSystem);
    let classEPriceNet = getPriceNet(ClassE, unitSystem);
    let classUPriceNet = getPriceNet(ClassU, unitSystem);
    let classRPriceNet = getPriceNet(ClassR, unitSystem);
    let classOPriceNet = getPriceNet(ClassO, unitSystem);
    let classPPriceNet = getPriceNet(ClassP, unitSystem);
    let outPriceNet = getPriceNet(AllOutSEUROP, unitSystem);
    let confiscationsPriceNet = getPriceNet(AllConfiscations, unitSystem);
    return (classSPriceNet + classEPriceNet + classUPriceNet + classRPriceNet + classOPriceNet + classPPriceNet + outPriceNet - confiscationsPriceNet);
}

export function getPriceForOnePiece(data, unitSystem) {
    console.log(data);
    if (get(data, "Weight", 0) === 0) return "0.00";
    let priceNet = getSumOfPriceNet(data, unitSystem);
    return (priceNet / convertWeightUnitTo(data.Weight || 0, {
        unit: UnitTypes.MEDIUM,
        rawValue: true,
        unitSystem
    })).toFixed(2);
}
