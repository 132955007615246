import lokiDB from "./lokiDB";

class LocalReportDataDB {

    saveLocalData(id, data) {
        lokiDB.localReportData.insert({ id, data });
        // lokiDB.saveDB();
    }

    getData(id) {
        return lokiDB.localReportData.findOne({ id })?.data || [];
    }
}

const localDataDB = new LocalReportDataDB();
export default localDataDB;
