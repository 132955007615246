import {createSelector} from 'reselect'

const emptyObj = {};

export const getShadows = state => state.shadows.shadows;

export const getShadowLoadings = state => state.shadows.loading;

export const getShadow = (state, {DevID} = emptyObj) =>
    state.shadows.shadows.get(DevID);

//status czy pobiera czy nie zeby nie uzywac intervalow i timeoutow w komponentach jak neandertale
const getLoading = (state, {DevID} = emptyObj) =>
    (state.shadows.loading || {})[DevID];

export const makeGetShadowByDevice = () => {
    return createSelector(
        [getShadow, getLoading],
        (_shadow, _status) => {
            return {
                fetched: !!_shadow,
                fetching: !!_status,
                shadow: _shadow
            }
        }
    );
}

export const getUploadData = state => state.shadows.upload.data;

export const getUploadLoading = state => state.shadows.upload.loading;

export const getProgramDetails = state => state.shadows.upload.details;
