import { applyMiddleware, compose, createStore } from "redux"
import { createLogger } from "redux-logger"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"
import reducer from "../reducers"

const enhancer = compose(
    ["production", /*"staging",*/ "test"].includes(process.env.REACT_APP_STAGE) ?
        applyMiddleware(promise, thunk) :
        applyMiddleware(promise, thunk, createLogger())
);
//const middleware = applyMiddleware(promise, thunk, createLogger(), logger);
const store = createStore(reducer, enhancer);
export default store

export function createStoreTest(preloadedState) {
    const enhancer = applyMiddleware(promise, thunk);
    return createStore(reducer, preloadedState, enhancer);
}
