import {useMemo} from "react";

export default function FatteningHeader ({children, summary, top}) {

    const className = useMemo(() => {
        let cName = "header span-row-2 p-2 last-header";
        if (summary) cName += " summary";
        if (top) cName += " sticky-header";
        return cName;
    }, [summary, top]);


    return <div className={className}>{children}</div>
};