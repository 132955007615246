import {get, isNil} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {show} from "redux-modal";
import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess,
} from "../../../api/cycles/editSowCycle";
import {EventTypes} from "../../../constans/eventTypes";
import {GraftingProgramApplicationTypes} from "../../../constans/graftingProgramApplicationTypes";
import {GRAFTING_RESULT} from "../../../constans/graftingResults";
import animalsDB from "../../../database/animalsDB";
import {
    getMedicineArrayForGroup,
    getMedicineArrayForSelectedAnimal,
    getSelectedAnimalForDocuments,
    makeGetAnimalsInGroup,
} from "../../../selectors/animalDocumentsSelectors";
import {getEmployeeList, getServiceList} from "../../../selectors/userSelector";
import {isMobile} from "../../../utils/MobileUtils";
import {checkIfUserHasPrivilegedAccess} from "../../../utils/NewRolesUtils";
import {employeesValueFormatter} from "../../../utils/RaportsUtils";
import {getManageSubgroups} from "../../../utils/SettingsUtils";
import {sortDateStrings} from "../../../utils/SortUtils";
import {formatLocationName} from "../../../utils/global-formatters/formatLocationName";
import TableGrid from "../../basics/table-grid/TableGrid";
import DefaultMobileRow from "../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import {ModalName as ConfirmModalName} from "../../modals-new/confirm-modal/ConfirmModal";
import {ModalName as EditGraftingModalName} from "../../modals-new/edit-grafting-modal/EditGraftingModal";
import {ModalName as EditTreatmentModalName} from "../../modals-new/edit-treatment-modal/EditTreatmentModal";
import CollapsableContainer from "../containers/CollapsableContainer";
import DateHeaderComponent from "./DateHeaderComponent";
import DoneHeaderComponent from "./DoneHeaderComponent";

function makeMapStateToProps() {
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    return (state, props) => {
        let animal = getSelectedAnimalForDocuments(state);
        return {
            employees: getEmployeeList(state),
            service: getServiceList(state),
            graftingReasons: state.dictionary.graftingReason.WData,
            animal,
            data: props.isGroup
                ? getMedicineArrayForGroup(state)
                : getMedicineArrayForSelectedAnimal(state, {
                      AnimalKind: animal.AnimalKind,
                      cycleNumber: props.cycle,
                  }),
            animals: getAnimalsInGroup(state),
        };
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch),
    };
}

class GraftingProgramGridCard extends React.Component {
    constructor(props) {
        super(props);
        this.mobile = isMobile();
        this.canManageSubgroups = getManageSubgroups();
    }

    dateValueFormatter = (value) => {
        const {t} = this.props;
        const _dateFormatter = "DD.MM.YY";
        if (value) {
            if (value.Start && !moment(value.Start).isSame(value.End)) {
                let text = moment(value.Start).format(_dateFormatter) + " - ";
                if (value.End) {
                    text += moment(value.End).format(_dateFormatter);
                } else {
                    text += "...";
                }
                return text;
            } else if (value.Date) {
                return `${moment(value.Date).format(_dateFormatter)} (${
                    value.Delay < 0
                        ? t("cycleReasons.parturitionBeforeDays", {
                              count: Math.abs(value.Delay),
                          })
                        : t("cycleReasons.parturitionAfterDays", {
                              count: value.Delay,
                          })
                })`;
            }
            return moment(value).format(_dateFormatter);
        }
        return "-";
    };

    medicineValueFormatter = (value) => {
        if (value) return value.WData.Name;
        else return "-";
    };

    employeesValueFormatter = (value) => {
        const {employees, service} = this.props;
        if (typeof value === "string") return employeesValueFormatter(value, employees, service);
        else if (isNil(value)) return ''
        else return value.map((item) => employeesValueFormatter(item, employees, service) + " ");
    };

    reasonValueFormatter = (value) => {
        const {graftingReasons} = this.props;
        let reason = graftingReasons.find((reason) => reason.ID === value);
        if (reason) return reason.Value;
    };

    getIconValue = (value) => {
        switch (value) {
            case GRAFTING_RESULT.NOT_DONE:
                return "fas fa-fw fa-times error";
            case GRAFTING_RESULT.DONE:
                return "fas fa-fw fa-check success";
            case GRAFTING_RESULT.PENDING:
                return "fas fa-fw fa-clock info";
            default:
                return "";
        }
    };

    dayValueFormatter = (value) => {
        const {graftingProgramReason, daysAfter, daysToGiveMedicine, daysReason, done} = value;

        if (isNil(daysAfter)) return "-";
        const {t, animal, isGroup} = this.props;
        let additionalText = "";
        switch (graftingProgramReason) {
            case GraftingProgramApplicationTypes.PARTURITION: {
                additionalText = t("newSettings.breeding.graftingProgram.afterParturition").toLowerCase();
                break;
            }
            case GraftingProgramApplicationTypes.INSERTION: {
                additionalText = t("newSettings.breeding.graftingProgram.afterInsertion").toLowerCase();
                break;
            }
            case GraftingProgramApplicationTypes.AGE: {
                additionalText = t("newSettings.breeding.graftingProgram.afterBirth").toLowerCase();
                break;
            }
            case GraftingProgramApplicationTypes.SEPARATION: {
                additionalText = t("newSettings.breeding.graftingProgram.afterSeparation").toLowerCase();
                break;
            }
            case GraftingProgramApplicationTypes.INSEMINATION: {
                additionalText = t("newSettings.breeding.graftingProgram.afterInsemination").toLowerCase();
                break;
            }
            default: {
                switch (daysReason) {
                    case GraftingProgramApplicationTypes.INSERTION: {
                        additionalText = t("newSettings.breeding.graftingProgram.afterInsertion").toLowerCase();
                        break;
                    }
                    case GraftingProgramApplicationTypes.AGE: {
                        additionalText = t("newSettings.breeding.graftingProgram.afterBirth").toLowerCase();
                        break;
                    }
                    case GraftingProgramApplicationTypes.INSEMINATION: {
                        additionalText = t("newSettings.breeding.graftingProgram.afterInsemination").toLowerCase();
                        break;
                    }
                    default: {
                        !isGroup && !animal.AnimalKind
                            ? (additionalText = t(
                                  "newSettings.breeding.graftingProgram.afterInsemination"
                              ).toLowerCase())
                            : (additionalText = t("newSettings.breeding.graftingProgram.afterBirth").toLowerCase());
                        break;
                    }
                }
                break;
            }
        }
        if (daysToGiveMedicine > 1 && done !== 1)
            return `${daysAfter} - ${daysAfter + daysToGiveMedicine - 1} ${additionalText}`;
        return `${daysAfter} ${additionalText}`;
    };

    onEditClick = (object) => {
        if (object.EvCode === EventTypes.TREATMENT) {
            this.props.show(EditTreatmentModalName, {treatment: object});
        } else {
            this.props.show(EditGraftingModalName, {event: object});
        }
    };

    onRemoveClick = (object) => {
        const {t, animal, show} = this.props;
        show(ConfirmModalName, {
            title: t("removeEventForAnimalTitle", {animal: animal.AnmNo1}),
            text: t("removeEventForAnimalText", {animal: animal.AnmNo1}),
            confirmText: t("yes"),
            size: "lg",
            onConfirmed: (props) => {
                return editSowCycle(
                    {
                        AnmID: animal.AnmID,
                        eventsToUpdate: [],
                        eventsToDelete: [object],
                    },
                    {FarmID: animal.FarmID}
                )
                    .then((res) => {
                        props.handleHide();
                        editSowCycleNotificationSuccess(res);
                    })
                    .catch((e) => {
                        editSowCycleNotificationFailure(e);
                    });
            },
        });
    };

    animalFormatter = (AnmID) => {
        const {animals, isGroup} = this.props;
        const animal =
            animals.find((a) => a.AnmID === AnmID) ||
            animalsDB.getAnimalById(AnmID, {
                joinEvents: false,
                findDeleted: true,
            });
        if (!isGroup) return animal?.AnmNo1 || "";
        if ((get(animal, "AnmCnt", 1) === 1 && get(animal, "RFID", "")) || this.canManageSubgroups) {
            return get(animal, "AnmNo1", "");
        }
        return "";
    };

    locationValueFormatter = (AnmIDs) => {
        const {animals} = this.props;
        let locations = "";
        AnmIDs?.forEach((item) => {
            const animal =
                animals.find((a) => a.AnmID === item) ||
                animalsDB.getAnimalById(item, {
                    joinEvents: false,
                    findDeleted: true,
                });
            let tempLoc = formatLocationName(animal?.DelPlcmntID || animal?.PlcmntID);
            if (!locations.includes(tempLoc)) locations += tempLoc + " ";
        });
        return locations;
    };

    isIndividualAnimal = (animal) => animal.hasOwnProperty("RFID") && animal.AnmCnt === 1;

    hasIndividualAnimals = () => {
        const {animals, data} = this.props;
        const _animals = animals.filter(
            (animal) => this.isIndividualAnimal(animal) && data.some((d) => d._AnmID === animal.AnmID)
        );
        return _animals.length > 0;
    };

    renderGrid() {
        const {t, animal, data, isGroup} = this.props;
        const headers = [
            isGroup && {
                name: t("location"),
                field: "_AnmIDs",
                valueFormatter: this.locationValueFormatter,
            },
            isGroup || animal.AnmCnt > 1
                ? {
                      name: t("animalCount"),
                      field: "animalsCnt",
                  }
                : null,
            {
                name: t("date"),
                field: "date",
                valueFormatter: this.dateValueFormatter,
                customSort: sortDateStrings,
                _mobileDate: true,
            },
            {
                name: t("day"),
                headerComponent: <DateHeaderComponent animal={animal} showFromInsemination={true} />,
                valueFormatter: this.dayValueFormatter,
            },
            {
                name: t("medicine"),
                field: "medicine",
                valueFormatter: this.medicineValueFormatter,
                colWidth: 2,
            },
            {
                name: t("givenDose"),
                field: "dose",
            },
            {
                name: t("reason"),
                field: "reason",
                valueFormatter: this.reasonValueFormatter,
            },
            {
                name: t("done"),
                field: "done",
                headerComponent: <DoneHeaderComponent showDoneTooltip={true} />,
                component: (props) => <i className={this.getIconValue(props.value)} />,
            },
            {
                name: t("eventGrid.operator"),
                field: "operator",
                valueFormatter: this.employeesValueFormatter,
                shouldShow: () => checkIfUserHasPrivilegedAccess(),
                colWidth: 2,
            },
            {
                name: t("comment"),
                field: "comment",
            },
            isGroup
                ? null
                : {
                      name: "",
                      headerClassName: "index",
                      itemClassName: "index",
                      notSortable:true,
                      component: (props) =>
                          props.object.event ? (
                              <i className="fas fa-edit pointer" onClick={() => this.onEditClick(props.object.event)} />
                          ) : null,
                  },
            isGroup
                ? null
                : {
                      name: "",
                      headerClassName: "index",
                      itemClassName: "index",
                      notSortable:true,
                      component: (props) =>
                          props.object.event ? (
                              <i
                                  className="fas fa-trash pointer"
                                  onClick={() => this.onRemoveClick(props.object.event)}
                              />
                          ) : null,
                  },
        ].filter((o) => !!o);
        return (
            <div>
                <TableGrid
                    data={data}
                    headers={headers}
                    mobileRow={<DefaultMobileRow />}
                    showPagination={!this.mobile}
                    paginationItems={isGroup ? 50 : 10}
                    scrollOnPageChange={false}
                />
            </div>
        );
    }

    render() {
        const {showCard, t, data} = this.props;
        if (showCard) {
            return (
                <>
                    <CollapsableContainer.Card
                        id="cy-grafting-card"
                        header={t("graftingProgramGridCardTitle")}
                        defaultExpanded={data.length > 0}>
                        {this.renderGrid()}
                    </CollapsableContainer.Card>
                </>
            );
        }
        return (
            <>
                <CollapsableContainer.Div header={t("graftingProgramGridCardTitle")} defaultExpanded={data.length > 0}>
                    {this.renderGrid()}
                </CollapsableContainer.Div>
            </>
        );
    }
}

GraftingProgramGridCard.propTypes = {
    cycle: PropTypes.number,
    showCard: PropTypes.bool,
    isGroup: PropTypes.bool,
};

GraftingProgramGridCard.defaultProps = {
    showCard: true,
    isGroup: false,
};

export default compose(withTranslation(), connect(makeMapStateToProps, mapDispatchToProps))(GraftingProgramGridCard);
