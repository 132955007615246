export const BreakPoint = {
    XS: 576,
    SM: 768,
    MD: 992,
    LG: 1200,
    XL: 1600,
    TV: 2200,
};

export const BreakPointComponents = {
    BREEDING_FILTER_BUTTONS: 1283
};
