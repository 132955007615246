import CircleParser from "./CircleParser";
import PathParser from "./PathParser";

export default (type, params) => {
    switch (type) {
        case "circle":
            return new CircleParser(params);
        case "path":
            return new PathParser(params);
        default:
            return null;
    }
}


export const getMemoKey = (type, params) => {
    switch (type) {
        case "circle":
            return CircleParser.getMemoKey(params);
        case "path":
            return PathParser.getMemoKey(params);
        default:
            return null;
    }
}