import {getRotatedData} from "../utils";
import SVGPaths from "../../components/SVGPaths";
import React from "react";
import Material from "./Material";

const CageTwoWay = (props, orientation) => {
    const {id, down, right, className} = props;
    const height = 200;
    const width = 200;

    const entranceOpened = !!props["enter-1"];
    const entranceClosed = !!props["enter-0"];
    const entranceUndefined = !entranceOpened && !entranceClosed;

    const exitOpened = !!props["mid-1"];
    const exitClosed = !!props["mid-0"];
    const exitUndefined = !exitClosed && !exitOpened;

    const openedLeft = !!props["exit-left"];
    const openedRight = !!props["exit-right"];
    const openedUndefined = !openedLeft && !openedRight;

    const {angle, viewBox, toAbsolute} = getRotatedData(orientation, down, right, width, height);
    return (
        <pattern className={className} id={id} x="0" y="0" width="100%" height="100%"
                 viewBox={viewBox}
                 patternUnits="objectBoundingBox">
            {/*<SVGPaths.CageTwoWayFloor className={Material.FLOOR} translate={toAbsolute(0, 0)} rotate={[angle, 0, 0]}/>*/}
            {
                openedLeft &&
                <SVGPaths.CageDoorBig className={Material.DOOR} translate={toAbsolute(`${width / 2}-50%+10`, 23)}
                                      rotate={[[-30], [angle, 0, 0]]}/>
            }
            {
                openedRight &&
                <SVGPaths.CageDoorBig className={Material.DOOR} translate={toAbsolute(`${width / 2}-50%-10`, 23)}
                                      rotate={[[30], [angle, 0, 0]]}/>
            }
            {
                openedUndefined &&
                <SVGPaths.CageDoorBig className={Material.DOOR} translate={toAbsolute(`${width / 2}-50%`, 27)}
                                      rotate={[angle, 0, 0]}/>
            }
            {
                exitUndefined &&
                <>
                    <SVGPaths.CageDoorSmall className={Material.DOOR} translate={toAbsolute(`${width / 2}-50%+15`, 64)}
                                            rotate={[[-45], [angle, 0, 0]]}/>
                    <SVGPaths.CageDoorSmall className={Material.DOOR}
                                            translate={toAbsolute(`${width / 2}-50%-15.5`, 64)}
                                            rotate={[[45], [angle, 0, 0]]}/>
                </>
            }
            {
                exitOpened &&
                <>
                    <SVGPaths.CageDoorSmall className={Material.DOOR} translate={toAbsolute(`${width / 2}-50%+14`, 60)}
                                            rotate={[angle, 0, 0]}/>
                    <SVGPaths.CageDoorSmall className={Material.DOOR}
                                            translate={toAbsolute(`${width / 2}-50%-14.5`, 60)}
                                            rotate={[angle, 0, 0]}/>
                </>
            }
            {
                exitClosed &&
                <>
                    <SVGPaths.CageDoorSmall className={Material.DOOR} translate={toAbsolute(`${width / 2}-50%+11`, 64)}
                                            rotate={[[-90], [angle, 0, 0]]}/>
                    <SVGPaths.CageDoorSmall className={Material.DOOR}
                                            translate={toAbsolute(`${width / 2}-50%-10.5`, 64)}
                                            rotate={[[90], [angle, 0, 0]]}/>
                </>
            }
            {
                entranceUndefined &&
                <>
                    <SVGPaths.CageDoorSmall className={Material.DOOR} translate={toAbsolute(`${width / 2}-50%+15`, 168)}
                                            rotate={[[45], [angle, 0, 0]]}/>
                    <SVGPaths.CageDoorSmall className={Material.DOOR}
                                            translate={toAbsolute(`${width / 2}-50%-15.5`, 168)}
                                            rotate={[[-45], [angle, 0, 0]]}/>
                </>
            }
            <SVGPaths.CageTwoWayOutline className={Material.STEEL_DARK} translate={toAbsolute(`${width / 2}-50%`, 0)}
                                        rotate={[angle, 0, 0]}/>
            <SVGPaths.CageTwoWayForeground className={Material.STEEL_LIGHT}
                                           translate={toAbsolute(`${width / 2}-50%`, 0)}
                                           rotate={[angle, 0, 0]}/>
            {
                entranceOpened &&
                <>
                    <SVGPaths.CageDoorSmall className={Material.DOOR} translate={toAbsolute(`${width / 2}-50%-17`, 173)}
                                            rotate={[angle, 0, 0]}/>
                    <SVGPaths.CageDoorSmall className={Material.DOOR}
                                            translate={toAbsolute(`${width / 2}-50%+17.5`, 173)}
                                            rotate={[angle, 0, 0]}/>
                </>
            }
            {
                entranceClosed &&
                <>
                    <SVGPaths.CageDoorSmall className={Material.DOOR} translate={toAbsolute(`${width / 2}-50%+10`, 166)}
                                            rotate={[[-90], [angle, 0, 0]]}/>
                    <SVGPaths.CageDoorSmall className={Material.DOOR}
                                            translate={toAbsolute(`${width / 2}-50%-9.5`, 166)}
                                            rotate={[[90], [angle, 0, 0]]}/>
                </>
            }
        </pattern>
    )
}

export default CageTwoWay;