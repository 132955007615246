import React from "react";

export default function (){
    return (
        <pattern id="ue-concrete" x="0" y="0" width="500" height="500" viewBox="0 0 500 500"
                 patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="500" height="500"/>
        </pattern>
    )
}
