/** merge wszystkich dostępnych plików z językami */
module.exports = {
    entry: './ApiLanguage.js',
    output: {path: __dirname, filename: './test.json'},
    module: {
        loaders: [
            {test: /\.json$/, loader: 'json'}
        ]
    }
};

// function requireAll( requireContext ) {
//     console.log('requireContext', requireContext.keys());
//     return requireContext.keys().map( requireContext );
// }

// let content = requireAll( require.context("./languages", false, /.json$/) );
// let content = require('./content.json');
//let contentDynamic = require('../conf/constants.json');
let languages = require('./supportedLanguages.json');

/*
* metoda pobierajaca tlumaczenia (teksty) z pliku
* trzeba będzie zamienić tą metode na pobieranie z bazy
* mozna zmienic zeby nie pobierala calosci tylko fragment ktory
* jest potrzebny
*
*/

let Api = {
    getLanguage() {
        return languages;
    }
};

module.exports = Api;