import Paths from "../paths";
import {invokeApig} from "../../libs/awsLib";
import {getUserApiData} from "../../utils/UserUtils";

export function getProjects({ClientID, LocalUserID} = {}) {
    const {_LocalUserID, _ClientID} = getUserApiData({ClientID, LocalUserID});
    return invokeApig({
        ...Paths.getProjects({clientID: _ClientID, localUserID: _LocalUserID})
    });
}
