import React from "react";
import SVGPaths from "../components/SVGPaths";
import {groupAnimalsBySize} from "../../../utils/FarmMapUtils";
import {customPigColors, sizes} from "./utils";

const getData = (props) => {
    const {id, error, warning, success, info, viewportH} = props;
    const cName = ["def-pig"];
    if (error) cName.push("error");
    if (warning) cName.push("warning");
    if (info) cName.push("info");
    if (success) cName.push("success");
    const feedKey = Object.keys(props).find((key) => key.startsWith("feed-")) || null;
    if (feedKey) cName.push(feedKey);
    return {
        className: cName.join(" "),
        hasFeed: !!feedKey,
        id,
        viewBox: `0 0 ${viewportH}0 ${viewportH}0`,
        feedOffset: viewportH / 2
    }
}

export const Pattern = ({children, data}) => {
    const {id, className, hasFeed, viewBox} = getData(data);

    return <pattern className={className} id={id} x="0" y="0" width="100%" height="100%" viewBox={viewBox}
                    patternUnits="objectBoundingBox">
        {children}
        {
            hasFeed &&
            <circle className="feed" cx="80" cy="80" r="10"/>
        }
    </pattern>
}

const Pig = {
    Single: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["80-50%", "80-50%"]} rotate={[0]}/>
            </Pattern>
        )
    },
    Two: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90-100%", "90-50%"]} rotate={[0]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90+25%", "90-50%"]} rotate={[30]}/>
            </Pattern>
        )
    },
    Three: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90-100%", "90-50%"]} rotate={[-15]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90+25%", "90-50%"]} rotate={[24]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90", "100%-30"]} rotate={[90]}/>
            </Pattern>
        )
    },
    Four: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90-100%", "90-50%"]} rotate={[185]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90+25%", "90-50%"]} rotate={[5]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["140", "90-30%"]} rotate={[177]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["80", "100%-15"]} rotate={[290]}/>
            </Pattern>
        )
    },
    Five: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["100-100%", "90-50%"]} rotate={[176]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90+25%", "90-50%"]} rotate={[5]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["150", "30"]} rotate={[180]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["50", "100%-15"]} rotate={[60]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["120", "100%"]} rotate={[80]}/>
            </Pattern>
        )
    },
    Six: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["100-125%", "90-50%"]} rotate={[190]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90+25%", "90-50%"]} rotate={[5]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["150", "30"]} rotate={[170]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["50", "100%-15"]} rotate={[15]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["120", "100%"]} rotate={[12]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["190", "75%"]} rotate={[190]}/>
            </Pattern>
        )
    },
    Seven: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["30", "30"]} rotate={[12]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["100", "-15"]} rotate={[90]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["200", "30"]} rotate={[148]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["60", "100"]} rotate={[8]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["110", "80"]} rotate={[12]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["160", "120"]} rotate={[8]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["220", "135"]} rotate={[10]}/>
            </Pattern>
        )
    },
    Eight: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["40", "30"]} rotate={[1]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90", "30"]} rotate={[180]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["140", "30"]} rotate={[-1]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["200", "30"]} rotate={[178]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["35", "151"]} rotate={[175]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["87", "143"]} rotate={[181]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["135", "155"]} rotate={[181]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["180", "150"]} rotate={[170]}/>
            </Pattern>
        )
    },
    Nine: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["40", "30"]} rotate={[-5]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90", "30"]} rotate={[180]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["140", "30"]} rotate={[-1]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["200", "30"]} rotate={[178]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["35", "151"]} rotate={[175]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["75", "143"]} rotate={[178]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["120", "155"]} rotate={[178]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["165", "150"]} rotate={[180]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["205", "150"]} rotate={[170]}/>
            </Pattern>
        )
    },
    Ten: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["40", "20"]} rotate={[-5]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90", "10"]} rotate={[180]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["140", "10"]} rotate={[-1]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["200", "20"]} rotate={[178]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["100", "100"]} rotate={[90]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["35", "171"]} rotate={[175]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["75", "183"]} rotate={[178]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["120", "175"]} rotate={[178]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["165", "180"]} rotate={[180]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["205", "170"]} rotate={[170]}/>
            </Pattern>
        )
    },
    SmallGroup: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["25", "10"]} rotate={[30]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["120", "-20"]} rotate={[90]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["240", "-20"]} rotate={[100]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["200", "20"]} rotate={[97]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["20", "125"]} rotate={[3]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["80", "100"]} rotate={[5]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["130", "140"]} rotate={[-5]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["180", "105"]} rotate={[-15]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["250", "125"]} rotate={[5]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["25", "235"]} rotate={[-30]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["300", "60"]} rotate={[170]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["300", "180"]} rotate={[183]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["240", "245"]} rotate={[295]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["170", "270"]} rotate={[-90]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90", "200"]} rotate={[-45]}/>
            </Pattern>
        )
    },
    BigGroup: (props) => {
        return (
            <Pattern data={props}>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["25", "10"]} rotate={[30]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["120", "-20"]} rotate={[90]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["110", "20"]} rotate={[100]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["230", "40"]} rotate={[75]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["130", "100"]} rotate={[88]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["240", "90"]} rotate={[90]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["240", "150"]} rotate={[115]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["50", "140"]} rotate={[91]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["40", "70"]} rotate={[70]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["240", "-20"]} rotate={[100]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["320", "180"]} rotate={[183]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["320", "40"]} rotate={[-15]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["240", "245"]} rotate={[295]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["170", "270"]} rotate={[-90]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["90", "200"]} rotate={[-45]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["20", "240"]} rotate={[-15]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["100", "300"]} rotate={[-80]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["300", "300"]} rotate={[90]}/>
                <SVGPaths.Pig scale={props.scale} className="animal" translate={["165", "165"]} rotate={[120]}/>
            </Pattern>
        )
    }
}

const classNames = ["", "warning", "error", ...customPigColors/*, ...feeds.map((n) => `feed-${n}`)*/];

const renderFromTemplate = (Component, numberOfPigs) => {
    const viewportH = Math.sqrt(+Object.keys(groupAnimalsBySize([{AnmCnt: numberOfPigs || 1}]))[0]);
    return (
        <>
            {classNames.map(className => (
                <>
                    {
                        sizes.map(size => (
                            <Component viewportH={viewportH}
                                       scale={size.scale} {...(className ? {[className]: true} : {})}
                                       id={`ue-pig-${numberOfPigs}${size.className ? `-${size.className}` : ""}${className ? `-${className}` : ""}`}
                                       key={`key-${numberOfPigs}${className}${size.className}`}
                            />
                        ))
                    }
                </>

            ))
            }
        </>
    )
}

function Pigs() {
    return (
        <>
            <Pig.Single id={"ue-pig"} viewportH={16}/>
            {renderFromTemplate(Pig.Single, 1)}
            {renderFromTemplate(Pig.Two, 2)}
            {renderFromTemplate(Pig.Three, 3)}
            {renderFromTemplate(Pig.Four, 4)}
            {renderFromTemplate(Pig.Five, 5)}
            {renderFromTemplate(Pig.Six, 6)}
            {renderFromTemplate(Pig.Seven, 7)}
            {renderFromTemplate(Pig.Eight, 8)}
            {renderFromTemplate(Pig.Nine, 9)}
            {renderFromTemplate(Pig.Ten, 10)}
            {renderFromTemplate(Pig.SmallGroup, 100)}
            {renderFromTemplate(Pig.BigGroup, 500)}
        </>
    )
}

export default React.memo(Pigs);
