import {getBoarWeights, getOtherWeights, getSowWeights} from "../../utils/SettingsUtils";
import moment from "moment";
import {localMomentToUTC} from "../../utils/DateTimeUtils";
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";

export default class EstimatedWeight {

    constructor(birthTimestamp = Date.now(), weights = getOtherWeights(), animalKind) {
        this.weightTable = this.getWeightTable(animalKind, weights);
        this.birthDate = localMomentToUTC(birthTimestamp).startOf("day");
    }

    getWeightsForAnimalKind(type) {
        switch (type) {
            case AnimalTypes.SOW:
                return getSowWeights();
            case AnimalTypes.BOAR:
                return getBoarWeights();
            default:
                return getOtherWeights();
        }
    }

    getWeightTable(type, weights) {
        return [1200, ...this.getWeights(type, weights)]
    }

    getWeights(type, weights) {
        if (weights.length) return weights;
        return this.getWeightsForAnimalKind(type);
    }

    setBirtDateByInsertionDateAndPigAge(insertionDateUTC, animalAgeInDays) {
        this.birthDate = moment.utc(insertionDateUTC).startOf("day").subtract(animalAgeInDays, "days");
        return this;
    }

    setWeightTable(weightTable, startingWeight = null) {
        this.weightTable = startingWeight === null ? [weightTable[0], ...weightTable] : [startingWeight, ...weightTable];
        return this;
    }

    setBirthDate(birthTimestamp) {
        this.birthDate = localMomentToUTC(birthTimestamp).startOf("day");
        return this;
    }

    getWeightByDate(timestamp) {
        const evaluatingDay = localMomentToUTC(timestamp).startOf("day");
        const age = evaluatingDay.diff(this.birthDate, "days");
        return this.getWeightByAge(age);
    }

    getWeightByAge(ageAsDays = 0) {
        if (ageAsDays < 0) return null;
        const weekIndex = Math.floor(ageAsDays / 7);
        const index = ageAsDays % 7;
        const weightStart = this.weightTable[weekIndex] ?? this.weightTable[this.weightTable.length - 1];
        const weightEnd = this.weightTable[weekIndex + 1] ?? this.weightTable[this.weightTable.length - 1];
        const step = (weightEnd - weightStart) / 7;
        return Math.round(index * step + weightStart);
    }
}
