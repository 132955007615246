const initialValue = {
    history: {},
    terminal: {},
    commands: {}
};
export default function managePCReducer(state = initialValue, action) {
    switch (action.type) {
        case "SUBMIT_MANAGE_PC_COMMAND": {
            const history = JSON.parse(JSON.stringify(state.history));
            if (action.meta.saveHistory) {
                if (!history[action.meta.GwID]) history[action.meta.GwID] = [];
                history[action.meta.GwID].push(action.payload);
            }
            const terminal = JSON.parse(JSON.stringify(state.terminal));
            if (!terminal[action.meta.GwID]) terminal[action.meta.GwID] = [];
            terminal[action.meta.GwID].push({text: action.payload, type: "command"});
            return {...state, terminal, history};
        }
        case "CLEAR_MANAGE_PC_TERMINAL": {
            const terminal = JSON.parse(JSON.stringify(state.terminal));
            terminal[action.meta.GwID] = [];
            return {...state, terminal};
        }
        case "RPI_RESPONSE_ARRIVE": {
            const terminal = JSON.parse(JSON.stringify(state.terminal));
            if (!terminal[action.meta.GwID]) terminal[action.meta.GwID] = [];
            if (action.payload.CAnsw.error) {
                terminal[action.meta.GwID].push({text: action.payload.CAnsw.error, type: "error"});
            } else {
                terminal[action.meta.GwID].push({text: action.payload.CAnsw, type: "response"});
            }
            return {...state, terminal}
        }
        case "RPI_GET_COMMANDS": {
            const commands = JSON.parse(JSON.stringify(state.commands));
            commands[action.meta.GwID] = action.payload.CAnsw;
            return {...state, commands};
        }
        case"USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state;
    }
}
