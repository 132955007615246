import lokiDB from "./lokiDB";
import { isString } from "lodash";
import { myID } from "../libs/generateID";

class Api {

    insert(path, method, headers, query, body) {
        lokiDB.api.insert({
            time: new Date().getTime(),
            path,
            method,
            headers,
            query,
            body: isString(body) ? JSON.parse(body) : {},
            OID: myID()
        });
        lokiDB.saveDB();
    }

    getData(left = null) {
        if (left) {
            if (left.length === 0) {
                lokiDB.api.chain().find().remove();
            } else {
                lokiDB.api.chain().find({ OID: { $nin: left } }).remove();
            }
        }
        console.log(left, lokiDB.api.chain().find().data());
        // let minTime = new Date().getTime() - (1000 * 60 * 60 * 24);
        // lokiDB.api.chain().find({time: {$lt: minTime}}).remove();
        return lokiDB.api.find();
    }

    async removeRows(ids) {
        for (let OID of ids) {
            console.log("Removing sync ID", OID);
            lokiDB.api.chain().find({ OID }).remove();
        }
        await lokiDB.saveDB();
    }

    async updateRows(rows) {
        for (let row of rows) {
            lokiDB.api.update(row);
        }
        await lokiDB.saveDB();
    }

}

const apiDB = new Api();
export default apiDB;
