import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

class CustomBrowserRouter extends BrowserRouter {
    history = history;

    componentDidMount() {
        // przy wejsciu na feturke sprawdza, czy nie ma query na redirect, glownie uzyte na prkach, aby ogarnac przekierowanie
        const { location: { search } } = history;
        const params = new URLSearchParams(search);
        if (params.has("redirect")) {
            let path = params.get("redirect");
            path = path.replace(new RegExp("pr/\\d+"), "");
            if (!path.startsWith("/")) path = `/${path}`;
            history.replace(path);
        }
    }

}

export default CustomBrowserRouter;
