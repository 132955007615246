import {invokeApig} from "../libs/awsLib";
import Paths from "../api/paths";

export function listDocumentsDynamoDB() {
    return function (dispatch) {
        dispatch({
            type: "LIST_DOCUMENTS", payload: invokeApig({
                ...Paths.listDocuments(),
                noAuthorization: true,
                queryParams: {DtaModTime: 0}
            })
        }).then(res => {
            console.log("list documents -> ", res);
            }
        ).catch(error => {
            console.error("list documents -> ", error)
        })
    }
}

export function markDocumentAsRead(docID) {
    return function (dispatch) {
        dispatch({
            type: "MARK_DOCUMENT_AS_READ", payload: invokeApig({
                ...Paths.markDocumentAsRead(),
                body: {DocID: docID}
            })
        }).then(res => {
                console.log("markDocumentAsRead success");
                dispatch({
                    type: "UPDATE_DOC_READ",
                    payload: docID
                })
            }
        ).catch(error => {
            console.error(error);
        })
    }
}