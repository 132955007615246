import React, {Component} from "react";
import Highlight from "./highlight/Highlight";

export default class BetaSign extends Component {

    render() {
        return (
            <small>
                <Highlight highlightColor={"secondary"}>BETA</Highlight>
            </small>
        );
    }
}
