import React from "react";
import PropTypes from "prop-types"
import Tooltip from "../../basics/tooltip/Tooltip";

export default class CycleGridHeader extends React.Component {

    getHeader = (column) => {
        if (column.tooltipContent) {
            return (
                <div className="d-flex">
                    {column.name}
                    <Tooltip tooltipContent={column.tooltipContent}>
                        <div>
                            <i className="fas fa-info-circle ms-1"/>
                        </div>
                    </Tooltip>
                </div>
            )
        }
        return column.name;
    };

    render() {
        const {title, columns, className} = this.props;
        return (
            <>
                <div
                    className={columns ? `cycle-grid-header has-children ${className}` : `cycle-grid-header ${className}`}
                    style={{gridColumn: columns ? `span ${columns.length}` : ""}}>
                    {title}
                </div>
                {
                    columns && columns.map((col, index) =>
                        <div className={`cycle-grid-header ${className} ${col.className}`} key={index}
                             style={{gridRow: 2}}>
                            {this.getHeader(col)}
                        </div>
                    )
                }
            </>
        );
    }

}

CycleGridHeader.propTypes = {
    title: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string
};
