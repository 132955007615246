import React, {Component} from 'react';
import {connect} from 'react-redux';
import {get} from "lodash";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import {Field, FormSection, formValueSelector, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import moment from "moment";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {getDictionary, getMedicines} from "../../../selectors/dictionarySelectors";
import {medicineTypes} from "../../../constans/medicine";
import {submit, validate} from "./EditGraftingModalSubmit";
import {EventTypes} from "../../../constans/eventTypes";

export const ModalName = "edit-grafting-modal";

function makeMapStateToProps() {
    const selector = formValueSelector(ModalName);
    return state => {
        return {
            medicines: getMedicines(state),
            medicine: selector(state, "EvData.Medicine"),
            reasons: getDictionary(state, "graftingReason")
        };
    }
}

class EditGraftingModal extends Component {

    constructor(props) {
        super(props);
        const {event, initialize} = this.props;
        if (!event.EvData.Dose) {
            const {medicines} = this.props;
            const currentMedicine = medicines.find(item => item.WordID === event.EvData.Medicine);
            initialize({
                ...event,
                EvData: {
                    ...event.EvData,
                    Dose: currentMedicine?.WData.Dose || 0
                }
            });
        } else {
            initialize(event);
        }
    }

    getUnit(currentMedicine) {
        if (currentMedicine) {
            return currentMedicine.WData.Unit.split("/")[0];
        }
        return null;
    }

    showDose(medicine) {
        if (medicine) {
            return !medicine.WData.Unit.includes("szt");
        }
        return false;
    }

    onMedicineChange = (ev, newValue, oldValue) => {
        const {medicines, event, change} = this.props;
        let newMedicine = medicines.find(item => item.WordID === newValue);
        let oldMedicine = medicines.find(item => item.WordID === oldValue);
        let canShowNewDose = this.showDose(newMedicine);
        let canShowOldDose = this.showDose(oldMedicine);
        if (!canShowNewDose && canShowOldDose) {
            change("EvData.Dose", +newMedicine.WData.Dose);
        } else if (canShowNewDose && !canShowOldDose) {
            change("EvData.Dose", +event.EvData.Dose);
        }
    }

    getOptions(opts, namePath, valuePath) {
        return opts.map((x) => ({
            name: get(x, namePath, ""),
            value: get(x, valuePath, "")
        }));
    };

    render() {
        const {show, handleSubmit, handleHide, t, medicines, reasons, medicine, submitting, event} = this.props;
        let currentMedicine = medicines.find(item => item.WordID === medicine);
        return (
            <Modal show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={t("editTreatment")} onCloseClick={handleHide}/>
                    <ModalBody>
                        {
                            currentMedicine?.WData.Type !== medicineTypes.FORAGE &&
                            <Field
                                name="EvTime"
                                component={ReduxLabeledInput}
                                type={"date"}
                                label={t("date")}
                                parse={value => value ? moment(value).toDate().getTime() : null}
                                format={value => value ? moment(value).format(moment.HTML5_FMT.DATE) : null}
                            />
                        }
                        <FormSection name={"EvData"}>
                            {
                                currentMedicine?.WData.Type === medicineTypes.FORAGE &&
                                <>
                                    <Field
                                        name="StartTime"
                                        component={ReduxLabeledInput}
                                        type={"date"}
                                        label={t("startDate")}
                                        parse={value => value ? moment(value).toDate().getTime() : null}
                                        format={value => value ? moment(value).format(moment.HTML5_FMT.DATE) : null}
                                    />
                                    <Field
                                        name="EndTime"
                                        component={ReduxLabeledInput}
                                        type={"date"}
                                        label={t("endDate")}
                                        parse={value => value ? moment(value).endOf("day").toDate().getTime() : null}
                                        format={value => value ? moment(value).format(moment.HTML5_FMT.DATE) : null}
                                    />
                                </>
                            }
                            {
                                event.EvCode === EventTypes.PIGLETS_TREATMENT &&
                                <Field
                                    name="PiCnt"
                                    component={ReduxLabeledInput}
                                    type={"number"}
                                    parse={value => value ? +value : null}
                                    label={t("pigletsAmount")}
                                />
                            }
                            <Field
                                name={"Medicine"}
                                component={ReduxLabeledSelect}
                                label={t("medicine")}
                                options={this.getOptions(medicines, "WData.Name", "WordID")}
                                clearButton={false}
                                onChange={this.onMedicineChange}
                            />
                            {
                                this.showDose(currentMedicine) &&
                                <Field
                                    name={"Dose"}
                                    component={ReduxLabeledInput}
                                    label={t("dose")}
                                    type={"number"}
                                    parse={value => value ? +value : null}
                                    unit={this.getUnit(currentMedicine)}
                                />
                            }
                            <Field
                                name={"Reasn"}
                                component={ReduxLabeledSelect}
                                label={t("reason")}
                                options={this.getOptions(reasons, "Value", "ID")}
                                clearButton={false}
                            />
                        </FormSection>
                        <Field
                            name="Comment"
                            component={ReduxLabeledInput}
                            type={"text"}
                            label={t("comment")}
                        />
                    </ModalBody>
                    <ModalFooter hasConfirmButton confirmText={t("save")} onCloseClick={handleHide} formName={ModalName}
                                 submitting={submitting}/>
                </form>
            </Modal>
        );
    }
}

export default compose(
    withTranslation(),
    connect(makeMapStateToProps),
    connectModal({name: ModalName}),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(EditGraftingModal);
