import {makeParser} from "./DataParser";


export const getParser = (() => {
    let parser = null;
    return () => parser ? parser : makeParser(true);
})();

export const parsePrimitive = (_dataView, _type) => {
    return getParser().toValue(_type, _dataView);
}

export const formatPrimitive = (_value, _type) => {
    return getParser().toBuffer(_type, _value);
}

export const parseHex = (_dataView, _type) => {
    return getParser().hex.toString(_type, _dataView);

}

export const formatHex = (_value, _type) => {
    return getParser().hex.toBuffer(_type, _value);

}