import React from "react";
import PropTypes from "prop-types"
import XLSX from "../../../beans/XLSX/XLSX";
import Button from "../button/Button";
import Tooltip from "../tooltip/Tooltip";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";

class SaveAsExcell extends React.Component {

    onSaveClick = () => {
        const {Xaxis, dataDef, data, fileName, addDefaultDate} = this.props;
        let headers = [
            {
                name: Xaxis.name,
                field: Xaxis.dataKey,
                excelFormatter: Xaxis.formatter
            },
        ];
        for (let definition of dataDef) {
            let tmp = {
                name: definition.name,
                field: definition.dataKey,
                excelFormatter: definition.formatter
            };
            definition.unit && (tmp.name = `${tmp.name} (${definition.unit})`);
            if (!definition.excelSkip) headers.push(tmp);
        }
        let formatData = XLSX.formatData(headers, data);
        let xlsx = new XLSX(formatData, {
            headers,
            addDefaultDate
        });
        xlsx.save(fileName);
    };

    render() {
        const {t} = this.props;
        return (
            <div className={"save-as-excell-container"}>
                <Tooltip tooltipContent={t("exportToExcell")} placement={"auto"}>
                    <Button
                        buttonColor={"background"}
                        buttonStyle={"text"}
                        type={"button"}
                        onClick={this.onSaveClick}
                        icon={<i className="fas fa-file-export"/>}/>
                </Tooltip>

            </div>
        );
    }

}

SaveAsExcell.defaultProps = {
    addDefaultDate: false
}

SaveAsExcell.propTypes = {
    Xaxis: PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataKey: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.array.isRequired,
    dataDef: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        dataKey: PropTypes.string.isRequired,
        unit: PropTypes.string
    })),
    addDefaultDate: PropTypes.bool
};

export default withTranslation(NameSpaces.TRANSLATION)(SaveAsExcell);
