import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {notify} from "reapop";
import i18n from "../../i18n";

export function editSellOrFall(data, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.editSellOrFall({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: data
    })
}

export function editFallNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.editFallTitle"),
        message: i18n.t("apiNotifications.editFallSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function editFallNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(notify({
        id: uniqueId,
        title: i18n.t("apiNotifications.editFallTitle"),
        message: i18n.t("apiNotifications.editFallFailure"),
        status: 'error',
        dismissible: true,
        // buttons: checkLogsButton(),
        dismissAfter: 15000
    }))
}
