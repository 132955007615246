const initialValue = {
    expanded: true
};

export default function sideBarReducer(state = initialValue, action) {
    const {type, payload} = action;
    if (type === "CHANGE_SIDE_BAR_STATEMENT") {
        return {...state, expanded: payload, footer: payload}
    }
    return state;
}
