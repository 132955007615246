import {invokeApig} from "../libs/awsLib";
import buildingsDB from "../database/buildingsDB";
import Paths from "../api/paths";
import {ReactLocalStorage} from "../utils/ReactLocalStorage";

/*
    ZAMIENIONO NA NOWE API 2019.04
 */

/**
 * Pobranie budynków z AWSa dla danej Farmy
 * @param FarmID
 * @param forceFetch
 * @returns {Function}
 */
export function fetchBuildingsByFarmID(FarmID, forceFetch = false) {
    return function (dispatch) {
        dispatch({
            type: "FETCH_BUILDINGS_BY_FARM_ID",
            payload: invokeApig({
                ...Paths.listLocation({farmID: FarmID}),
                queryParams: {
                    FarmID: FarmID,
                    DtaModTime: parseInt(buildingsDB.getModificationTime(FarmID).DtaModTime),
                    newAPI: true,
                },
                forceFetch
            })
        }).then((res) => {
                let l = {};
                Object.assign(l, res);
                if (res.value.length > 0) {
                    let tmp = [];
                    Object.assign(tmp, res.value);
                    buildingsDB.insertIntoBuildings(tmp);
                }
                dispatch(getAllBuildings(FarmID));
            }
        ).catch(error => {
            dispatch(getAllBuildings(FarmID));
            console.error(error);
        })
    }
}


/**
 * Pobranie budynków z lokiego i wrzucenie ich do stora
 * @param id
 * @returns {Function}
 */
export function getAllBuildings(id) {
    return function (dispatch) {
        dispatch({
            type: "GET_ALL_BUILDINGS", payload: buildingsDB.getBuildingsForStore(id)
        });
    }
}

export function changeTestSwitchValue(value) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_TEST_SWITCH",
            payload: value
        });
        ReactLocalStorage.setPrivate("testSwitch", value);
    }
}

export function changeSubscriptionFilterValue(field) {
    return function (dispatch) {
        dispatch({
            type: "CHANGE_SUBSCRIPTION_FILTER_VALUE",
            payload: {field}
        })
    }
}
