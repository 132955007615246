import React from "react";

export default function TransferCounterComponent({value, object, selectedItem}) {

    const getClassName = () => {
        let className = "";
        const newGroupID = object?.EvData?.NewGroupID;
        const oldGroupID = object?.EvData?.OldGroupID;
        const initial = object?.EvData?.initial;
        const groupID = selectedItem?.AnmGrp;
        if (oldGroupID !== groupID) {
            if (initial || newGroupID === groupID || (oldGroupID && oldGroupID !== groupID)) className = "color-success";
            else if (oldGroupID === groupID) className = "color-error";
        }
        return className;
    }

    const className = getClassName();

    return (
        <span className={className}>{value || null}</span>

    )
}