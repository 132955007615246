import {get, set} from "lodash";
import store from "../../store/store";
import devicesDB from "../../database/devicesDB";
import animalsDB from "../../database/animalsDB";
import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import {changeMapShadow, changeMapShadowDelta} from "../../actions/mapShadowActions";
import {myID} from "../../libs/generateID";
import {
    DispenserNRFCommandTypes,
    MessageTypes
} from "../../constans/mqttMessages";
import {notify} from "reapop";
import {cloneFast} from "../../utils/Utils";
import {getWeightedRandomNumber} from "../../utils/MathUtils";
import {isIPSUM, isNutriPro, isNutriProV2, isRFID} from "../../utils/DispenserNRFUtils";

const result = {};


export const fakeSendMapState = (GatewayID, items, {onSuccess, onFailure} = {}) => {
    // normalnie w items przekazujemy szczegółową liste komór z których chcemy wyświetlić dane
    // ale na potrzeby demo oraz optymalizacji szybciej będzie po prostu wysłać dane dla wszystkich urządzeń / zwierząt
    const state = store.getState();
    const currentFarmId = get(state, "location.farm", null);
    const currentData = get(state, "farmMap.data", {});
    if (!currentFarmId) return;
    const devices = devicesDB.getDevices(currentFarmId);
    const animals = animalsDB.getAllAnimals(currentFarmId, undefined, false, false, false);
    const now = Date.now();
    const checkIfAlreadyHasData = (key) => {
        if (currentData[key] || result[key]) {
            // kopiujemy wartosci zeby nam na pomaranczowo sie nie swiecilo po 15 minutach
            result[key] = {...result[key], ...currentData[key]};
            // usuniecie jakis rzeczy ktore ustawiamy an dany czas np. led na 10 min
            const attributeNames = Object.keys(result[key]);
            for (let attr of attributeNames) {
                if (attr.startsWith("__removeKey__")) {
                    if (result[key][attr] < now) {
                        delete result[key][attr];
                        const attrNameForRemoval = attr.replace("__removeKey__", "");
                        delete result[key][attrNameForRemoval];
                    }
                }
            }
            return true;
        }
        return false;
    }
    // urzadzenia
    let counter = 0;
    for (let device of devices) {
        // karmienie
        switch (device.DevType) {
            // karmienie
            case DevTypes.DISPENSER: {
                for (let i = 0; i < 20; i++) {
                    const key = `${device.DevID}_${i}`;
                    if (!checkIfAlreadyHasData(key)) {
                        result[key] = createFakeData("FEED", counter);
                    }
                }
                break;
            }
            case DevTypes.DISPENSER_NRF: {
                if (!checkIfAlreadyHasData(device.DevID)) {
                    if (isIPSUM(device)) {
                        result[device.DevID] = createFakeData("IPSUM", counter);
                    } else if (isNutriPro(device)) {
                        result[device.DevID] = createFakeData("NUTRIPRO", counter);
                    } else if (isNutriProV2(device)) {
                        result[device.DevID] = createFakeData("NUTRIPROV2", counter);
                    } else if (isRFID(device)) {
                        result[device.DevID] = createFakeData("GROUP", counter);
                    } else {
                        result[device.DevID] = createFakeData("FEED", counter);
                    }
                }
                break;
            }
            // klimat
            case DevTypes.CLIMATE:
            case DevTypes.CLIMATE_SK3:
            case DevTypes.CLIMATE_SK4: {
                if (!checkIfAlreadyHasData(device.DevID)) {
                    result[device.DevID] = createFakeData("CLIMATE", counter);
                }
                break;
            }
            // klatki selekcyjne
            case DevTypes.CAGE:
            case DevTypes.CAGE_2WAY: {
                if (!checkIfAlreadyHasData(device.DevID)) {
                    result[device.DevID] = createFakeData("CAGE", counter);
                }
                break;
            }
            // silosy
            case DevTypes.SCALE: {
                for (let i = 0; i < 8; i++) {
                    const key = `${device.DevID}_${i}`;
                    if (!checkIfAlreadyHasData(key)) {
                        result[key] = createFakeData("SILO", counter);
                    }
                }
                break;
            }
            // woda
            case DevTypes.WATER_FLOW_METER:
            case DevTypes.WIRELESS_WATER_FLOW_METER: {
                if (!checkIfAlreadyHasData(device.DevID)) {
                    result[device.DevID] = createFakeData("WATER", counter);
                }
                break;
            }
            // prad
            case DevTypes.ELECTRICITY_FLOW_METER_MODBUS:
            case DevTypes.ELECTRICITY_FLOW_METER: {
                if (!checkIfAlreadyHasData(device.DevID)) {
                    result[device.DevID] = createFakeData("ELECTRIC", counter);
                }
                break;
            }
            default:
                break;
        }
        counter++;
    }
    // karmienie rfid
    counter = 0;
    for (let {RFID, AnmID} of animals) {
        if (RFID) {
            if (!checkIfAlreadyHasData(AnmID)) {
                result[AnmID] = createFakeData("FEED", counter);
            }
            counter++;
        }
    }
    store.dispatch(changeMapShadow(result));
    onSuccess();
}

const createFakeData = (type, index = 0) => {
    const baseObj = {
        _type: type,
        Alerts: [],
        Warnings: []
    }
    switch (type) {
        case "FEED": {
            const plannedForCurrentTime = 2500;
            let Percentage = 0;
            switch (index % 100) {
                case 99:
                    Percentage = 10;
                    break;
                case 98:
                    Percentage = 40;
                    break;
                default:
                    Percentage = getWeightedRandomNumber([
                        {w: 95, v: 100},
                        {w: 3, v: 90},
                        {w: 1, v: 40},
                        {w: 1, v: 10}
                    ]);
                    break;
            }
            return {
                ...baseObj,
                PlannedConsumption: 5000,
                Percentage,
                Consumed: Math.round(Percentage * plannedForCurrentTime / 100)
            }
        }
        case "GROUP": {
            return {
                ...baseObj,
                Animal: null
            }
        }
        case "CLIMATE": {
            return {
                ...baseObj,
                Temperature: Math.round(Math.random() * 5) + 24
            }
        }
        case "CAGE": {
            return {
                ...baseObj,
                AverageWeight: (Math.round(Math.random() * 5) + 55) * 1000,
                Entrance: "open",
                Middle: "open",
                Exit: "left"
            }
        }
        case "SILO": {
            return {
                ...baseObj,
                Weight: (Math.round(Math.random() * 200) + 1000) * 1000
            }
        }
        case "WATER": {
            return {
                ...baseObj,
                Consumed: Math.random() * 5000 + 10000
            }
        }
        case "ELECTRIC": {
            return {
                ...baseObj,
                Consumed: Math.random() * 5000 + 1000
            }
        }
        case "NUTRIPROV2": {
            return {
                ...baseObj,
                Consumed: Math.random() * 5000 + 1000,
                FeederStatus: true, // czujnik w automacie paszowym
                DispenserAStatus: false, // czujnik w dozowniku A
                DispenserBStatus: false, // czujnik w dozowniku B
            }
        }
        case "NUTRIPRO": {
            return {
                ...baseObj,
                Consumed: Math.random() * 5000 + 1000,
                FeederStatus: true, // czujnik w automacie paszowym
                DispenserStatus: false, // czujnik w dozowniku
            }
        }
        case "IPSUM": {
            return {
                ...baseObj,
                Weight: (Math.round(Math.random() * 5) + 55) * 1000,
                FeederStatus: true, // czujnik w automacie paszowym
                DispenserStatus: false, // czujnik w dozowniku
            }
        }
        default: {
            return {};
        }
    }
}

export function fakeCreateAndSendMessageObject(ClientID, GatewayID, LocalUserID, DeviceID, Command, data = undefined, notification = undefined, onSuccess = undefined, onError = undefined, onSend = undefined) {
    let id = myID();
    const message = {
        MsgId: id,
        PktType: MessageTypes.REQUEST,
        DeviceId: DeviceID,
        RTime: new Date().getTime(),
        Command: Array.isArray(Command) ? Command : [Command],
        CData: {},
        Priority: 69
    };
    if (data) {
        message.CData = data;
    }
    const cmd = message.Command[0];
    if (notification && notification.success) {
        store.dispatch(notify({
            ...notification.loading,
            ...notification.success
        }));
    }
    console.log(cmd);
    const now = Date.now();
    switch (cmd) {
        case DispenserNRFCommandTypes.SET_LED_COLOUR: {
            const delta = {};
            (Array.isArray(DeviceID) ? DeviceID : [DeviceID]).forEach(devId => {
                const newShadow = cloneFast(result[devId]);
                if (newShadow) {
                    set(newShadow, "LED", {
                        Blink: message.CData.blink,
                        Colour: message.CData.colour
                    });
                    set(newShadow, "__removeKey__LED", now + message.CData.time);
                }
                delta[devId] = newShadow;
            });
            store.dispatch(changeMapShadowDelta(delta));
            break;
        }
        default:
            break;

    }
    if (onSuccess) setTimeout(() => onSuccess(), 250);
    return id;
}
