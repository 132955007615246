import React, {Component} from 'react';
import DateChooser from "../../basics/date-chooser/DateChooser";

class EditTreatmentModalDateChooser extends Component {

    onChangeDates = value => {
        this.props.input.onChange(value);
        this.props.input.onBlur();
    }

    render() {
        const {input: {value}, meta: {touched, error}} = this.props;
        return (
            <div className="position-relative">
                <DateChooser selectedDates={value} onChange={this.onChangeDates}/>
                {touched && error && <div className="error-item">{error}</div>}
            </div>
        );
    }
}

export default EditTreatmentModalDateChooser;
