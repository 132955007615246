import React, {useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getActiveAnimalsWithoutSkip } from "../../../../selectors/animalSelector";
import { getBuildingsMap } from "../../../../selectors/buildingsSelector";
import AnimalTypes from "@wesstron/utils/Api/constants/animalTypes";
import SectorTypes from "@wesstron/utils/Api/constants/sectorTypes";
import { clamp, isNil } from "lodash";
import { upperFirstLetter } from "../../../../utils/TextUtils";

const InventoryContent = ({expanded, toggleExpand}) => {
    const {t} = useTranslation();
    const animals = useSelector(getActiveAnimalsWithoutSkip);
    const buildingsMap = useSelector(getBuildingsMap)

    const data = useMemo(() => {
        const result = {
            [AnimalTypes.SOW]: {
                [SectorTypes.MATING]: 0,
                [SectorTypes.SOWS]: 0,
                [SectorTypes.DELIVERY]: 0,
                OTHER: 0,
            },
            [AnimalTypes.RENOVATION_SOW]: {
                [SectorTypes.RENOVATION_SOWS]: 0,
                OTHER: 0,
            },
            [AnimalTypes.PIGLET]: {
                OTHER: 0,
            },
            [AnimalTypes.PORKER]: {
                OTHER: 0,
            },
        };
        let key = '';
        if (animals.length > 0 && buildingsMap) {
            for (let animal of animals) {
                const building = buildingsMap.get(animal.PlcmntID);
                const animalCnt = clamp(
                    animal.AnmCnt ?? 0,
                    0,
                    Number.MAX_SAFE_INTEGER
                );
                if(!result[animal.AnimalKind]) continue;
                if (!isNil(result[animal.AnimalKind][building?.SType])) key = building.SType;
                else{
                    key = 'OTHER'
                }
                result[animal.AnimalKind][key] += animalCnt
            }
            return result;
        }
    }, [animals, buildingsMap]);

    const printData = useMemo(() => {
        if (data)
            return Object.keys(data).map((animalType) => (
                <div className={"map-drawer-animals-counter-box"}>
                    <span>
                        {upperFirstLetter(t(`animalKind.${animalType}`))}
                    </span>
                    <div className={"map-drawer-animals-counter-location"}>
                        {Object.keys(data[animalType]).map((sectorType) => (
                            <div className="map-drawer-animals-counter-location-container">
                                <div className="pieces">
                                    {data[animalType][sectorType]}
                                </div>
                                <small className="location">
                                    {sectorType === "OTHER"
                                        ? Object.keys(data[animalType])
                                              .length === 1
                                            ? t(
                                                  "objectDescription.ANIMAL.AnmCnt._title"
                                              )
                                            : upperFirstLetter(t("other"))
                                        : upperFirstLetter(
                                              t(`SType.${sectorType - 1}`)
                                          )}
                                </small>
                            </div>
                        ))}
                    </div>
                </div>
            ));
    }, [data, t]);
    const classNames = ["map-drawer"];
    if (expanded) classNames.push("expanded");
    return (
        <div className={classNames.join(" ")}>
            <div className={"toggle-expand"} aria-label={expanded ? t("collapse") : t("animalDocuments.expand")}
                onClick={toggleExpand} />
            <div className={"map-drawer-content"}>
                <div className={"map-drawer-header"}>
                    <div>{t('inventory')}</div>
                    <div>{t('animalCounterGroupedBySectors')}</div>
                </div>
                <div className={'map-drawer-animals-counter'}>
                {printData}
                </div>
            </div>

        </div>
    )
}

export default React.memo(InventoryContent);