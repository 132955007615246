import {isFiniteNumber} from "../../../../utils/MathUtils";
import {Milliseconds} from "../../../../constans/milliseconds";
import moment from "moment";

export const applyOffset = (value, offsetPercentage = 0) => ((100 + offsetPercentage) * value) / 100;

export const ERRORS = {
    OUT_OF_BOUNDS: "outOfBounds",
    NO_DATA: "noData",
    ALERT_ON_DEVICE: "alertOnDevice"
}

export const autoDomain = [
    (min) => {
        if (isFiniteNumber(min)) {
            return Math.floor(min / 5) * 5
        } else {
            return 0;
        }
    },
    (max) => {
        if (isFiniteNumber(max)) {
            return Math.ceil(max / 5) * 5
        } else {
            return 1;
        }
    },
]

export const calculateAutoDomain = (dataSet) => {
    let min = dataSet?.[0]?.offset?.[0] || 0;
    let max = dataSet?.[0]?.offset?.[1] || 0;
    dataSet.forEach(({offset}) => {
        min = Math.min(min, offset[0]);
        max = Math.max(max, offset[1]);
    })
    return [autoDomain[0](min), autoDomain[1](max)];
}

export const calculateAutoDomainForWeightingChart = (dataSet) => {
    let min = 0, max = 0;
    dataSet.forEach(({weight = 0, estimated}) => {
        max = Math.max(max, Math.max(weight, estimated));
    });
    return [min, max + 5000]; // in grams
};
export const calculateAutoDomainForP2Chart = (dataSet) => {
    let min = 0, max = 0;
    dataSet.forEach(({fat = 0}) => {
        max = Math.max(max, fat);
    });
    return [min, max + 25]; // in mm
};
export const fixAnimalTimespan = (domain) => {
    const MAX_YEARS = 7;
    const diff = Math.abs((domain[0] - domain[1])) / Milliseconds.YEAR;
    // this should happen only when user has entered a BAD DATE
    if (diff > MAX_YEARS) {
        console.warn("timespan exceeds %s years domain=[%s, %s]", MAX_YEARS, domain[0], domain[1]);
        if (domain[0] < +moment.utc("2000-01-01", "YYYY-MM-DD")) {
            console.warn("birth time below year 2000");
            return [+moment.utc(domain[1]).startOf("day").subtract(MAX_YEARS, "years"), domain[1]];
        }
        return [domain[0], +moment.utc(domain[0]).startOf("day").add(MAX_YEARS, "years")];
    }
    return domain;
}

/**
 * do odsiewania komór ze względu na ilość świń
 */
export const skipData = (size) => {
    if (size === 0) return true;
    if (size < 0) {
        console.warn("!!! AnmCnt below zero -> %s", size);
        return true;
    }
    return false;
}