import React from "react";
import {get} from "lodash";
import PropTypes from "prop-types";
import {Collapse} from "react-bootstrap";

export default class TreeSelectItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpened: props.expanded
        };
    }

    onIconClick = () => {
        this.setState(state => ({
            isOpened: !state.isOpened
        }))
    };

    onItemClick = () => {
        this.props.onSelect(this.props.option);
    };

    getClassName = () => {
        const {level, children, selected} = this.props;
        let className = `tree-select-menu-item level-${level}`;
        if (children.length === 0) className += " no-children";
        if (selected) className += " active";
        return className;
    };

    render() {
        const {children, option} = this.props;
        const {isOpened} = this.state;
        return (
            <div
                className={this.getClassName()}>
                {
                    option.children && option.children.length > 0 &&
                    <div
                        className={isOpened ? "tree-select-menu-item-triangle opened" : "tree-select-menu-item-triangle closed"}
                        onClick={this.onIconClick}/>
                }
                <span onClick={this.onItemClick}>
                    {get(option.object, option.name, "")}
                </span>
                <Collapse in={isOpened}>
                    <div>
                        {children}
                    </div>
                </Collapse>
            </div>
        );
    }

}

TreeSelectItem.propTypes = {
    option: PropTypes.shape({
        object: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
        name: PropTypes.string.isRequired
    }),
    level: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    expanded: PropTypes.bool,
};

TreeSelectItem.defaultProps = {
    selected: false,
    expanded: false
};

