import React, { Component } from 'react';
import Card from "../../components/basics/card/Card";
import ButtonGroup from "../../components/basics/button/button-group/ButtonGroup";
import Button from "../../components/basics/button/Button";
import Printable from "../../components/basics/printable/Printable";
import { withTranslation } from "react-i18next";
import "./_reports-container.scss";
import { compose } from "redux";
import { connect } from "react-redux";
import { getSelectedAnimalForDocuments } from "../../selectors/animalDocumentsSelectors";

function mapStateToProps(state) {
    return {
        selectedItem: getSelectedAnimalForDocuments(state)
    }
}

class ReportsContainer extends Component {

    state = {
        printing: false
    }

    onPrintClick = () => {
        this.setState({
            printing: true
        })
    };

    onPrintEnd = () => {
        this.setState({
            printing: false
        })
    };

    render() {
        const { printing } = this.state;
        const { children, name, style, t, selectedItem } = this.props;
        return (
            <Card style={style}>
                <Printable onPrintEnd={this.onPrintEnd} name={name} printing={printing} isReport>
                    {children}
                </Printable>
                {
                    !selectedItem &&
                    <ButtonGroup fixed className={"reports-container-button-group"}>
                        <Button title={t("newReports.print")} buttonColor={"success"} buttonStyle={"round"}
                            icon={<i className="fas fa-print" />} onClick={this.onPrintClick} />
                    </ButtonGroup>
                }
            </Card>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(ReportsContainer);