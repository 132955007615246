import {
    cloneDeep,
    findLast,
    first,
    flatten,
    get,
    groupBy,
    isEmpty,
    isNaN,
    isNil,
    isNumber,
    last,
    minBy,
} from "lodash";
import * as EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import moment from "moment";
import {
    getCalculateResultsAfterSowCycles,
    getDaysForRepeatedInsemination,
    getFirstCycleIndex,
    getMaxDelayForBirth,
    getMaximumIntervalBetweenSeparations,
    getMinAgeForFirstMating,
    getTimeFromInseminationToPartuition,
    getTimeFromInseminationToPregnancy,
    getTimeOnBirthRoomMommy,
    getValidationSettings,
} from "./SettingsUtils";
import {getPigBalance} from "./EventUtils";
import {CycleActions} from "../constans/cycleActions";
import {USG_STATE} from "../constans/eventTypes";
import {getPercent} from "./GaugeUtils";
import {getWeightedAverage} from "./MathUtils";
import {ValidationDefaultValues} from "../views/new-settings-view/validations/validation-values/ValidationDefaultValues";

export const EventsSortKeys = {
    [EventTypes.INSEMINATION]: 1,
    [EventTypes.USG]: 2,
    [EventTypes.PARTURITION]: 3,
    [EventTypes.PARTURITION_STATE]: 4,
    [EventTypes.FALL_PIGLETS]: 5,
    [EventTypes.SEPARATION_TO_MOMMY]: 6,
    [EventTypes.MOMMY]: 7,
    [EventTypes.SEPARATION]: 8,
    [EventTypes.SOW_CYCLES]: 9,
    [EventTypes.ACTIVE_NIPPLES]: 10,
    [EventTypes.WEIGHTING]: 11,
    [EventTypes.NO_PREGNANCY]: 12,
    [EventTypes.CASTRATION]: 13,
    [EventTypes.TATTOO]: 14,
};

const getEventsFromCard = () => {
    return [
        EventTypes.INSEMINATION,
        EventTypes.USG,
        EventTypes.PARTURITION,
        EventTypes.PARTURITION,
        EventTypes.FALL_PIGLETS,
        EventTypes.SEPARATION_TO_MOMMY,
        EventTypes.MOMMY,
        EventTypes.SEPARATION,
        EventTypes.SOW_CYCLES,
        EventTypes.ACTIVE_NIPPLES,
        EventTypes.NO_PREGNANCY,
    ];
};

export const getEmptyCycleRow = () => {
    return {
        inseminations: [],
        usgs: [],
        parturitions: [],
        separations: [],
        falls: [],
        weightings: [],
        separationsMommy: [],
        mommys: [],
        noPregnancy: [],
        activeNipples: [],
    };
};

export const checkIfRowHasMoreEvents = (cycle) => {
    const cycleHeaders = getEmptyCycleRow();
    for (const property in cycle) {
        if (
            cycle.hasOwnProperty(property) &&
            Object.keys(cycleHeaders).includes(property) &&
            Array.isArray(cycle[property]) &&
            cycle[property].length > 1
        ) {
            return true;
        }
    }
    return false;
};

/**
 * Funkcja wyliczająca cykle na podstawie ZDARZEN jakie wykonaliśmy na maciorze.
 * @param animalEvents
 * @param animal
 * @param fromInventory - jesli z inwenatarza to nie filtruj po delTime bo moga sie pojawic jakies eventy na usunietych zwierzeciach
 * @param cycleSettings - obiekt przekazywany ze state z racji iz nie mozna wolac store na reducerze bezposrednio
 * @param shouldCheckToOmitSowCycles
 * @returns {{cycleTable: *[], events: *}}
 */
export function preEvents(
    animalEvents = [],
    animal = null,
    fromInventory = false,
    cycleSettings,
    shouldCheckToOmitSowCycles = false
) {
    let eventz = animalEvents.slice(0);
    let settingsData = {
        timeFromInseminationToParturition:
            getTimeFromInseminationToPartuition(cycleSettings),
        timeOnBirthRoomMommy: getTimeOnBirthRoomMommy(cycleSettings),
        firstCycleIndex: getFirstCycleIndex(cycleSettings),
        daysForRepeatedInsemination:
            getDaysForRepeatedInsemination(cycleSettings),
        maximumIntervalBetweenSeparations:
            getMaximumIntervalBetweenSeparations(cycleSettings),
        calculateResultsAfterSowCycles:
            getCalculateResultsAfterSowCycles(cycleSettings),
        timeFromInseminationToPregnancy:
            getTimeFromInseminationToPregnancy(cycleSettings),
        validations: getValidationSettings(cycleSettings),
    };
    let daysFromInseminationToBeingMommy =
        settingsData.timeFromInseminationToParturition +
        settingsData.timeOnBirthRoomMommy;
    let timeBetweenInseminations = settingsData.daysForRepeatedInsemination;
    let firstCycleIndex = settingsData.firstCycleIndex;
    let checkDaysFromInseminationToUSG = get(
        settingsData.validations,
        "checkDaysFromInseminationToUSG",
        ValidationDefaultValues.checkDaysFromInseminationToUSG
    );
    let timeFromInseminationToPregnancy =
        settingsData.timeFromInseminationToPregnancy;
    if (!Array.isArray(eventz)) {
        eventz = [];
    }
    eventz = eventz.filter((ev) => !!ev.EvTime);
    if (!fromInventory) eventz = eventz.filter((ev) => !ev.DtaDelTime);
    let sowCyclesEvent = eventz.find(
        (ev) => ev.EvCode === EventTypes.SOW_CYCLES
    );
    eventz = eventz.filter((ev) =>
        [
            EventTypes.INSEMINATION,
            EventTypes.NO_PREGNANCY,
            EventTypes.PARTURITION,
            EventTypes.SEPARATION,
            EventTypes.FALL_PIGLETS,
            EventTypes.WEIGHTING,
            EventTypes.USG,
            EventTypes.SEPARATION_TO_MOMMY,
            EventTypes.MOMMY,
            EventTypes.ACTIVE_NIPPLES,
            EventTypes.SOW_CYCLES,
            EventTypes.PARTURITION_STATE,
            EventTypes.CASTRATION,
            EventTypes.TATTOO,
            EventTypes.COMMENTS,
            EventTypes.SEPARATION_TO_MOMMY_GILTS,
            EventTypes.IS_MOMMY,
            EventTypes.FAT_MEASUREMENT,
        ].includes(ev.EvCode)
    );
    eventz.sort((a, b) => {
        let tmpA = +moment(a.EvTime).startOf("day");
        let tmpB = +moment(b.EvTime).startOf("day");
        if (tmpA === tmpB)
            return EventsSortKeys[a.EvCode] - EventsSortKeys[b.EvCode];
        return a.EvTime - b.EvTime;
    });

    let eventsBeforeSowInsertion = [];
    let eventsAfterSowInsertion = [];

    const cycleTableBefore = [];
    const cycleTableAfter = [];

    let actualCycleBefore = firstCycleIndex;
    let actualCycleAfter = firstCycleIndex;

    let startTimeBefore;
    let endTimeBefore;
    let startTimeAfter;
    let endTimeAfter;

    const expectedParturition = {
        inseminationDate: 0,
        expectedParturitionDate: 0,
        waiting: false,
    };

    //jesli nie ma sowCycles event oznacza to stara swinie
    if (!isEmpty(sowCyclesEvent)) {
        // wyciagniecie wszystkich waznych eventow przed data ostatniego odsadu podanego w evencie sow cycles oraz samo sow cycles
        eventsBeforeSowInsertion = eventz.filter(
            (event) =>
                event.EvTime <= sowCyclesEvent.EvTime ||
                event.EvCode === EventTypes.SOW_CYCLES
        );
        // pobranie eventow po ostatnim odsadzeniu ktory podalismy na wprowadzieniu swini
        eventsAfterSowInsertion = eventz.filter(
            (event) =>
                event.EvTime > sowCyclesEvent.EvTime &&
                event.EvCode !== EventTypes.SOW_CYCLES
        );
        // wyliczenie cyklu w ktorym zostala podana data ostatniego odsadu czyli jesli podamy 2, to data ostatniego odsadu zaliczy sie do 1 cyklu
        actualCycleBefore =
            get(sowCyclesEvent, "EvData.Cycles", 1) - 1 + firstCycleIndex;
        // wyliczeniu nastepnego cyklu do ktorego beda zaliczone eventy po dacie ostatniego odsadzenia
        actualCycleAfter =
            get(sowCyclesEvent, "EvData.Cycles", 1) + firstCycleIndex;
        // pobranie daty ostatniego odsadu
        const lastSeparationTime = get(sowCyclesEvent, "EvTime", 0);
        startTimeBefore = moment(lastSeparationTime)
            .subtract(daysFromInseminationToBeingMommy, "days")
            .startOf("day")
            .toDate()
            .getTime();
        endTimeBefore = moment(lastSeparationTime)
            .endOf("day")
            .toDate()
            .getTime();
        startTimeAfter = moment(lastSeparationTime)
            .add(1, "day")
            .startOf("day")
            .toDate()
            .getTime();
        endTimeAfter = moment(lastSeparationTime)
            .add(daysFromInseminationToBeingMommy + 1, "days")
            .endOf("day")
            .toDate()
            .getTime();
    } else {
        actualCycleAfter = firstCycleIndex;
        eventsAfterSowInsertion = eventz.slice(0);
    }

    const emptyRow = {
        [EventTypes.INSEMINATION]: [],
        [EventTypes.USG]: [],
        [EventTypes.NO_PREGNANCY]: [],
        [EventTypes.PARTURITION]: [],
        [EventTypes.SEPARATION_TO_MOMMY]: [],
        [EventTypes.MOMMY]: [],
        [EventTypes.FALL_PIGLETS]: [],
        [EventTypes.WEIGHTING]: [],
        [EventTypes.ACTIVE_NIPPLES]: [],
        [EventTypes.SEPARATION]: [],
        [EventTypes.SOW_CYCLES]: [],
        [EventTypes.PARTURITION_STATE]: [],
        [EventTypes.CASTRATION]: [],
        [EventTypes.TATTOO]: [],
        [EventTypes.COMMENTS]: [],
        [EventTypes.SEPARATION_TO_MOMMY_GILTS]: [],
        [EventTypes.PARTURITION_START]: [],
        [EventTypes.PARTURITION_END]: [],
        [EventTypes.IS_MOMMY]: [],
        [EventTypes.FAT_MEASUREMENT]: [],
        isInterrupted: false,
    };

    let rowBefore = {
        ...cloneDeep(emptyRow),
        cycle: actualCycleBefore,
        StartTime: startTimeBefore,
        EndTime: endTimeBefore,
    };

    let row = {
        ...cloneDeep(emptyRow),
        cycle: actualCycleAfter,
        StartTime: startTimeAfter,
        EndTime: endTimeAfter,
    };

    if (!isEmpty(eventsBeforeSowInsertion)) {
        eventsBeforeSowInsertion.forEach((event, index) => {
            const {EvCode, EvTime} = event;
            if (!isEmpty(rowBefore[EventTypes.NO_PREGNANCY])) {
                rowBefore.EndTime = moment(EvTime)
                    .endOf("day")
                    .toDate()
                    .getTime();
                rowBefore.isInterrupted = true;
                cycleTableBefore.push(rowBefore);
                rowBefore = {
                    ...cloneDeep(emptyRow),
                    cycle: actualCycleBefore,
                    StartTime: moment(EvTime)
                        .add(1, "day")
                        .startOf("day")
                        .toDate()
                        .getTime(),
                    EndTime: moment(EvTime)
                        .add(daysFromInseminationToBeingMommy + 1, "days")
                        .endOf("day")
                        .toDate()
                        .getTime(),
                };
            }
            if (!isEmpty(rowBefore[EventTypes.SEPARATION_TO_MOMMY])) {
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.NO_PREGNANCY,
                        EventTypes.PARTURITION,
                    ].includes(EvCode)
                ) {
                    rowBefore.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleBefore,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.MOMMY])) {
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.NO_PREGNANCY,
                        EventTypes.PARTURITION,
                    ].includes(EvCode)
                ) {
                    rowBefore.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleBefore,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.FALL_PIGLETS])) {
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.PARTURITION,
                        EventTypes.NO_PREGNANCY,
                    ].includes(EvCode)
                ) {
                    rowBefore.EndTime = rowBefore.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleBefore,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.SEPARATION])) {
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.NO_PREGNANCY,
                        EventTypes.PARTURITION,
                    ].includes(EvCode) ||
                    (EvCode === EventTypes.SEPARATION &&
                        moment(EvTime).diff(
                            moment(
                                last(rowBefore[EventTypes.SEPARATION]).EvTime
                            ),
                            "days"
                        ) > settingsData.maximumIntervalBetweenSeparations)
                ) {
                    rowBefore.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleBefore,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.PARTURITION])) {
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.NO_PREGNANCY,
                        EventTypes.PARTURITION,
                    ].includes(EvCode)
                ) {
                    rowBefore.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableBefore.push(rowBefore);
                    actualCycleBefore--;
                    rowBefore = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleBefore,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.USG])) {
                const isPregant = !isEmpty(rowBefore[EventTypes.USG])
                    ? rowBefore[EventTypes.USG][
                          rowBefore[EventTypes.USG].length - 1
                      ].EvData.Pregnant
                    : true;
                if (isPregant && [EventTypes.INSEMINATION].includes(EvCode)) {
                    rowBefore.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    rowBefore.isInterrupted = true;
                    cycleTableBefore.push(rowBefore);
                    rowBefore = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleBefore,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
                //jesli stwierdzony zostal brak ciazy traktujemy z gory jako powtorke
                if (
                    !isPregant &&
                    (EvCode !== EventTypes.USG ||
                        moment(EvTime)
                            .endOf("day")
                            .diff(
                                moment(
                                    last(rowBefore[EventTypes.USG]).EvTime
                                ).startOf("day"),
                                "days"
                            ) > 3)
                ) {
                    rowBefore.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    rowBefore.isInterrupted = true;
                    cycleTableBefore.push(rowBefore);
                    rowBefore = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleBefore,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(rowBefore[EventTypes.INSEMINATION])) {
                let lastInsemination =
                    rowBefore[EventTypes.INSEMINATION][
                        rowBefore[EventTypes.INSEMINATION].length - 1
                    ];
                const isPregant = !isEmpty(rowBefore[EventTypes.USG])
                    ? rowBefore[EventTypes.USG][
                          rowBefore[EventTypes.USG].length - 1
                      ].EvData.Pregnant
                    : true;
                if ([EventTypes.INSEMINATION].includes(EvCode)) {
                    if (
                        moment(+lastInsemination.EvTime)
                            .startOf("day")
                            .diff(
                                moment(+event.EvTime).startOf("day"),
                                "days"
                            ) > timeBetweenInseminations
                    ) {
                        rowBefore.EndTime = moment(EvTime)
                            .endOf("day")
                            .toDate()
                            .getTime();
                        rowBefore.isInterrupted = true;
                        cycleTableBefore.push(rowBefore);
                        rowBefore = {
                            ...cloneDeep(emptyRow),
                            cycle: actualCycleBefore,
                            StartTime: moment(EvTime)
                                .add(1, "day")
                                .startOf("day")
                                .toDate()
                                .getTime(),
                            EndTime: moment(EvTime)
                                .add(
                                    daysFromInseminationToBeingMommy + 1,
                                    "days"
                                )
                                .endOf("day")
                                .toDate()
                                .getTime(),
                        };
                    }
                } else if (
                    !isPregant &&
                    EvCode === EventTypes.USG &&
                    (!checkDaysFromInseminationToUSG ||
                        moment(EvTime)
                            .startOf("day")
                            .diff(
                                moment(+lastInsemination.EvTime).startOf("day"),
                                "days"
                            ) > timeFromInseminationToPregnancy) &&
                    moment(EvTime)
                        .endOf("day")
                        .diff(
                            moment(
                                last(rowBefore[EventTypes.USG]).EvTime
                            ).startOf("day"),
                            "days"
                        ) > 3
                ) {
                    rowBefore.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    rowBefore.isInterrupted = true;
                    cycleTableAfter.push(rowBefore);
                    rowBefore = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleAfter,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (eventsBeforeSowInsertion.length - 1 === index) {
                if (isRowCycleEmpty(rowBefore)) {
                    rowBefore.StartTime = moment(EvTime)
                        .startOf("day")
                        .toDate()
                        .getTime();
                    rowBefore.EndTime = moment(EvTime)
                        .add(daysFromInseminationToBeingMommy, "days")
                        .endOf("day")
                        .toDate()
                        .getTime();
                }
                rowBefore[EvCode].push(event);
                cycleTableBefore.push(rowBefore);
            } else {
                if (isRowCycleEmpty(rowBefore)) {
                    rowBefore.StartTime = moment(EvTime)
                        .startOf("day")
                        .toDate()
                        .getTime();
                    rowBefore.EndTime = moment(EvTime)
                        .add(daysFromInseminationToBeingMommy, "days")
                        .endOf("day")
                        .toDate()
                        .getTime();
                }
                rowBefore[EvCode].push(event);
            }
        });
    }

    if (!isEmpty(eventsAfterSowInsertion)) {
        eventsAfterSowInsertion.forEach((event, index) => {
            const {EvCode, EvTime} = event;
            if (!isEmpty(row[EventTypes.NO_PREGNANCY])) {
                row.EndTime = moment(EvTime).endOf("day").toDate().getTime();
                row.isInterrupted = true;
                cycleTableAfter.push(row);
                row = {
                    ...cloneDeep(emptyRow),
                    cycle: actualCycleAfter,
                    StartTime: moment(EvTime)
                        .add(1, "day")
                        .startOf("day")
                        .toDate()
                        .getTime(),
                    EndTime: moment(EvTime)
                        .add(daysFromInseminationToBeingMommy + 1, "days")
                        .endOf("day")
                        .toDate()
                        .getTime(),
                };
            }
            if (!isEmpty(row[EventTypes.SEPARATION_TO_MOMMY])) {
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.NO_PREGNANCY,
                        EventTypes.PARTURITION,
                    ].includes(EvCode)
                ) {
                    row.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleAfter,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(row[EventTypes.MOMMY])) {
                //mamka moze byc pare razy, ale jesli przyjdzie inseminacja, usg, brak ciazy lub porod to ucinamy wiersz, i nowy cykl. Traktujemy event ten jak wyproszenie!
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.NO_PREGNANCY,
                        EventTypes.PARTURITION,
                    ].includes(EvCode)
                ) {
                    row.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleAfter,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(row[EventTypes.FALL_PIGLETS])) {
                //upadek prosiat moze byc kilka wiec jesli przyjdzie inseminacja, usg, porod, brak ciazy ucinamy wiersz i nowy cykl
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.PARTURITION,
                        EventTypes.NO_PREGNANCY,
                    ].includes(EvCode)
                ) {
                    row.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleAfter,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(row[EventTypes.SEPARATION])) {
                //jesli po odsadzie przyjdzie inseminacja, usg, brak ciazy, porod lub kolejny odsad z gory zakladamy NOWY CYKL
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.NO_PREGNANCY,
                        EventTypes.PARTURITION,
                    ].includes(EvCode) ||
                    (EvCode === EventTypes.SEPARATION &&
                        moment(EvTime).diff(
                            moment(last(row[EventTypes.SEPARATION]).EvTime),
                            "days"
                        ) > settingsData.maximumIntervalBetweenSeparations)
                ) {
                    row.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleAfter,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(row[EventTypes.PARTURITION])) {
                //jesli po porodzie przyjdzie inseminacja, usg, brak ciazy, porod oznacza to NOWY CYKL
                if (
                    [
                        EventTypes.INSEMINATION,
                        EventTypes.USG,
                        EventTypes.NO_PREGNANCY,
                        EventTypes.PARTURITION,
                    ].includes(EvCode)
                ) {
                    row.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    cycleTableAfter.push(row);
                    actualCycleAfter++;
                    row = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleAfter,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(row[EventTypes.USG])) {
                const isPregant = !isEmpty(row[EventTypes.USG])
                    ? row[EventTypes.USG][row[EventTypes.USG].length - 1].EvData
                          .Pregnant
                    : true;
                //jesli nastepnym eventem jest inseminacja sprawdzam czy stwierdzona byla ciaza
                //jesli stwierdzona zostala ciaza a zrobilismy inseminacje oznacza to rowniez powtorke bo nie otrzymalismy prosiakow
                if (isPregant && [EventTypes.INSEMINATION].includes(EvCode)) {
                    row.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    row.isInterrupted = true;
                    cycleTableAfter.push(row);
                    row = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleAfter,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
                // jesli stwierdzony zostal brak ciazy i zrobilismy inseminacje POWTORKA
                // chyba ze pomiedzy badaniami nie minelo 3 dni ~ https://redmine.wesstron.local/issues/7281
                if (
                    !isPregant &&
                    !checkDaysFromInseminationToUSG &&
                    (EvCode !== EventTypes.USG ||
                        moment(EvTime)
                            .endOf("day")
                            .diff(
                                moment(
                                    last(row[EventTypes.USG]).EvTime
                                ).startOf("day"),
                                "days"
                            ) > 3)
                ) {
                    row.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    row.isInterrupted = true;
                    cycleTableAfter.push(row);
                    row = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleAfter,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }
            if (!isEmpty(row[EventTypes.INSEMINATION])) {
                //jesli po inseminacji przyjdzie inseminacja sprawdzamy jaki czas zdefiniowal sobie uzytkownik pomiedzy powtórkami
                //1. Jesli kolejna inseminacja jest wieksza od tego czasu, zaliczamy to do powtórki - nowy wiersz
                //2. Inaczej jest w tym samym wierszu
                let lastInsemination =
                    row[EventTypes.INSEMINATION][
                        row[EventTypes.INSEMINATION].length - 1
                    ];
                const isPregant = !isEmpty(row[EventTypes.USG])
                    ? row[EventTypes.USG][row[EventTypes.USG].length - 1].EvData
                          .Pregnant
                    : true;
                if ([EventTypes.INSEMINATION].includes(EvCode)) {
                    if (
                        moment(+event.EvTime)
                            .startOf("day")
                            .diff(
                                moment(+lastInsemination.EvTime).startOf("day"),
                                "days"
                            ) > timeBetweenInseminations
                    ) {
                        row.EndTime = moment(EvTime)
                            .endOf("day")
                            .toDate()
                            .getTime();
                        row.isInterrupted = true;
                        cycleTableAfter.push(row);
                        row = {
                            ...cloneDeep(emptyRow),
                            cycle: actualCycleAfter,
                            StartTime: moment(EvTime)
                                .add(1, "day")
                                .startOf("day")
                                .toDate()
                                .getTime(),
                            EndTime: moment(EvTime)
                                .add(
                                    daysFromInseminationToBeingMommy + 1,
                                    "days"
                                )
                                .endOf("day")
                                .toDate()
                                .getTime(),
                        };
                    }
                } else if (
                    !isPregant &&
                    EvCode === EventTypes.USG &&
                    (!checkDaysFromInseminationToUSG ||
                        moment(EvTime)
                            .startOf("day")
                            .diff(
                                moment(+lastInsemination.EvTime).startOf("day"),
                                "days"
                            ) > timeFromInseminationToPregnancy) &&
                    moment(EvTime)
                        .endOf("day")
                        .diff(
                            moment(last(row[EventTypes.USG]).EvTime).startOf(
                                "day"
                            ),
                            "days"
                        ) > 3
                ) {
                    row.EndTime = moment(EvTime)
                        .endOf("day")
                        .toDate()
                        .getTime();
                    row.isInterrupted = true;
                    cycleTableAfter.push(row);
                    row = {
                        ...cloneDeep(emptyRow),
                        cycle: actualCycleAfter,
                        StartTime: moment(EvTime)
                            .add(1, "day")
                            .startOf("day")
                            .toDate()
                            .getTime(),
                        EndTime: moment(EvTime)
                            .add(daysFromInseminationToBeingMommy + 1, "days")
                            .endOf("day")
                            .toDate()
                            .getTime(),
                    };
                }
            }

            if (eventsAfterSowInsertion.length - 1 === index) {
                // ostatni event
                if (isRowCycleEmpty(row)) {
                    row.StartTime = moment(EvTime)
                        .startOf("day")
                        .toDate()
                        .getTime();
                    row.EndTime = moment(EvTime)
                        .add(daysFromInseminationToBeingMommy, "days")
                        .endOf("day")
                        .toDate()
                        .getTime();
                    if (cycleTableBefore.length > 0) {
                        cycleTableBefore[cycleTableBefore.length - 1].EndTime =
                            moment(EvTime)
                                .subtract(1, "day")
                                .endOf("day")
                                .toDate()
                                .getTime();
                    }
                }
                row[EvCode].push(event);
                cycleTableAfter.push(row);
            } else {
                if (isRowCycleEmpty(row)) {
                    row.StartTime = moment(EvTime)
                        .startOf("day")
                        .toDate()
                        .getTime();
                    row.EndTime = moment(EvTime)
                        .add(daysFromInseminationToBeingMommy, "days")
                        .endOf("day")
                        .toDate()
                        .getTime();
                    if (cycleTableBefore.length > 0) {
                        cycleTableBefore[cycleTableBefore.length - 1].EndTime =
                            moment(EvTime)
                                .subtract(1, "day")
                                .endOf("day")
                                .toDate()
                                .getTime();
                    }
                }
                row[EvCode].push(event);
            }

            if (
                row[EventTypes.INSEMINATION].length &&
                event.EvCode === EventTypes.USG
            ) {
                const {
                    EvData: {Pregnant},
                } = event;
                if ([USG_STATE.POSITIVE, true].includes(Pregnant)) {
                    const inseminationDate =
                        row[EventTypes.INSEMINATION][0].EvTime;
                    expectedParturition.inseminationDate = inseminationDate;
                    expectedParturition.expectedParturitionDate = moment(
                        inseminationDate
                    )
                        .startOf("day")
                        .add(getTimeFromInseminationToPartuition(), "days")
                        .toDate()
                        .getTime();
                    expectedParturition.waiting = true;
                }
            }
            // nie chcemy miec oczekiwanych w przeszlosci wiec do teraz nie moze miec zgloszonych zadnych upadkow itp
            // bo bedzie to oznaczalo ze taka swinia
            // bo tylko konta serwisowe moga zglaszac w przyszlosci
            if (
                flatten([
                    row[EventTypes.NO_PREGNANCY],
                    row[EventTypes.PARTURITION],
                    row[EventTypes.SEPARATION_TO_MOMMY],
                    row[EventTypes.MOMMY],
                    row[EventTypes.SEPARATION],
                    row[EventTypes.SOW_CYCLES],
                    row[EventTypes.FALL_PIGLETS],
                    row[EventTypes.PARTURITION_STATE],
                ]).length > 0
            ) {
                expectedParturition.inseminationDate = 0;
                expectedParturition.expectedParturitionDate = 0;
                expectedParturition.waiting = false;
            }
        });
    }

    const replacedEventsBefore = cycleTableBefore.map((row, index) => ({
        cycle: cycleTableBefore[cycleTableBefore.length - 1 - index].cycle,
        [EventTypes.INSEMINATION]: row[EventTypes.INSEMINATION],
        [EventTypes.USG]: row[EventTypes.USG],
        [EventTypes.NO_PREGNANCY]: row[EventTypes.NO_PREGNANCY], // przerywa oczekiwanie
        [EventTypes.PARTURITION]: row[EventTypes.PARTURITION], // przerywa oczekiwanie
        [EventTypes.SEPARATION_TO_MOMMY]: row[EventTypes.SEPARATION_TO_MOMMY], // przerywa oczekiwanie
        [EventTypes.MOMMY]: row[EventTypes.MOMMY], // przerywa oczekiwanie
        [EventTypes.FALL_PIGLETS]: row[EventTypes.FALL_PIGLETS], // przerywa oczekiwanie
        [EventTypes.WEIGHTING]: row[EventTypes.WEIGHTING],
        [EventTypes.ACTIVE_NIPPLES]: row[EventTypes.ACTIVE_NIPPLES],
        [EventTypes.SEPARATION]: row[EventTypes.SEPARATION], // przerywa oczekiwanie
        [EventTypes.SOW_CYCLES]: row[EventTypes.SOW_CYCLES], // przerywa oczekiwanie
        [EventTypes.PARTURITION_STATE]: row[EventTypes.PARTURITION_STATE], // przerywa oczekiwanie,
        [EventTypes.CASTRATION]: row[EventTypes.CASTRATION],
        [EventTypes.TATTOO]: row[EventTypes.TATTOO],
        [EventTypes.COMMENTS]: row[EventTypes.COMMENTS],
        [EventTypes.SEPARATION_TO_MOMMY_GILTS]:
            row[EventTypes.SEPARATION_TO_MOMMY_GILTS],
        [EventTypes.PARTURITION_START]: row[EventTypes.PARTURITION_START],
        [EventTypes.PARTURITION_END]: row[EventTypes.PARTURITION_END],
        [EventTypes.IS_MOMMY]: row[EventTypes.IS_MOMMY],
        [EventTypes.FAT_MEASUREMENT]: row[EventTypes.FAT_MEASUREMENT],
        StartTime: row.StartTime,
        EndTime: row.EndTime,
        isInterrupted: row.isInterrupted,
    }));
    const finalTable = [];
    // @piotr.arent wyłączyłem sortowanie bo sortuje źle przez co kolejność cykli na karcie była błędna
    // const cycleTable = [...replacedEventsBefore, ...cycleTableAfter].sort((a, b) => a.EndTime - b.EndTime);
    const cycleTable = [];
    if (
        !shouldCheckToOmitSowCycles ||
        !settingsData.calculateResultsAfterSowCycles
    ) {
        cycleTable.push(...replacedEventsBefore);
    }
    cycleTable.push(...cycleTableAfter);
    for (const cycleRow of cycleTable) {
        if (cycleRow.isInterrupted) continue;
        if (
            cycleRow[EventTypes.NO_PREGNANCY].length ||
            (cycleRow[EventTypes.USG].length &&
                !last(cycleRow[EventTypes.USG])?.EvData?.Pregnant)
        ) {
            cycleRow.isInterrupted = true;
        }
    }
    const currentTime = moment().toDate().getTime();
    if (cycleTable[cycleTable?.length - 1]?.EndTime < currentTime) {
        cycleTable[cycleTable?.length - 1].EndTime = currentTime;
    }
    const lessCycle = !isEmpty(cycleTable)
        ? minBy(cycleTable, "cycle").cycle
        : firstCycleIndex;
    let idx = firstCycleIndex;
    for (idx; idx < lessCycle; idx++) {
        finalTable.push({
            ...cloneDeep(emptyRow),
            cycle: idx,
        });
    }
    let showData = [...finalTable, ...cycleTable];
    for (let tempIdx = showData.length - 1; tempIdx >= 0; tempIdx--) {
        if (
            !isEmpty(showData[tempIdx - 1]) &&
            isRowCycleEmpty(showData[tempIdx - 1])
        ) {
            showData[tempIdx - 1].EndTime = moment(showData[tempIdx].StartTime)
                .subtract(1, "day")
                .endOf("day")
                .toDate()
                .getTime();
            showData[tempIdx - 1].StartTime = moment(
                showData[tempIdx].StartTime
            )
                .subtract(daysFromInseminationToBeingMommy + 1, "day")
                .startOf("day")
                .toDate()
                .getTime();
        }
    }
    return {
        events: eventz,
        cycleTable: showData,
        resultTable: cycleTable, //nie dla reducera to, dla wyników to
        expectedParturition,
    };
}

/**
 * funkcja sprawdzająca czy wiersz cyklu jest pusty
 * @param row
 * @returns {boolean}
 */
function isRowCycleEmpty(row) {
    let empty = true;
    for (const property in row) {
        if (Array.isArray(row[property]) && !isEmpty(row[property])) {
            empty = false;
        }
    }
    return empty;
}

/**
 * Funkcja flatująca powtórki, i cykle rozróżniane jako jeden obiekt
 * @param data
 * @returns {Array}
 */
export function convertRowsToCycles(data) {
    const result = [];
    let clone = cloneDeep(data);
    clone = groupBy(clone, "cycle");
    for (const value of Object.values(clone)) {
        if (Array.isArray(value)) {
            let obj = {
                [EventTypes.INSEMINATION]: [],
                [EventTypes.USG]: [],
                [EventTypes.NO_PREGNANCY]: [],
                [EventTypes.PARTURITION]: [],
                [EventTypes.SEPARATION_TO_MOMMY]: [],
                [EventTypes.MOMMY]: [],
                [EventTypes.FALL_PIGLETS]: [],
                [EventTypes.WEIGHTING]: [],
                [EventTypes.ACTIVE_NIPPLES]: [],
                [EventTypes.SEPARATION]: [],
                [EventTypes.SOW_CYCLES]: [],
                [EventTypes.PARTURITION_STATE]: [],
                [EventTypes.CASTRATION]: [],
                [EventTypes.TATTOO]: [],
                [EventTypes.COMMENTS]: [],
                [EventTypes.SEPARATION_TO_MOMMY_GILTS]: [],
                [EventTypes.PARTURITION_START]: [],
                [EventTypes.PARTURITION_END]: [],
                [EventTypes.IS_MOMMY]: [],
                [EventTypes.FAT_MEASUREMENT]: [],
            };
            for (const element of value) {
                obj.cycle = element.cycle;
                obj.StartTime = element.StartTime;
                obj.EndTime = element.EndTime;
                obj.isInterrupted = element.isInterrupted;
                for (let [key, value] of Object.entries(element)) {
                    if (Array.isArray(value)) {
                        obj[key] = [...obj[key], ...value];
                    }
                }
            }
            result.push(obj);
        }
    }
    return result;
}

/**
 * Funkcja walidująca wyliczone cykle pod kątem ich zgodności zwracająca taką samą tablice powiększoną
 * w każdym obiekcie o tablice invalidEvents.
 * @param cycles
 * @param animal
 * @returns {Array}
 */
export function getCorrectValidatedEventsData(cycles, animal) {
    const arr = [];
    const timeFromInseminationToPregnancy =
        getTimeFromInseminationToPregnancy();
    const timeFromInseminationToParturition =
        getTimeFromInseminationToPartuition();
    cycles
        .map((el) => ({...el, invalidEvents: []}))
        .forEach((row, index) => {
            const rowEvents = getEventsFromRow(row);
            const firstInsemination = row[EventTypes.INSEMINATION][0];
            const firstUSG = first(row[EventTypes.USG]);
            const lastUSG = last(row[EventTypes.USG]);
            const firstParturition = row[EventTypes.PARTURITION][0];
            const separation = row[EventTypes.SEPARATION][0];
            const separationToMommy = row[EventTypes.SEPARATION_TO_MOMMY][0];
            const activeNipples = row[EventTypes.ACTIVE_NIPPLES][0];
            const sowCycle = row[EventTypes.SOW_CYCLES][0];
            const productionEvent = rowEvents.find((ev) =>
                [
                    EventTypes.USG,
                    EventTypes.PARTURITION,
                    EventTypes.FALL_PIGLETS,
                    EventTypes.MOMMY,
                    EventTypes.SEPARATION,
                    EventTypes.ACTIVE_NIPPLES,
                ].includes(ev.EvCode)
            );

            //jesli byla inseminacja
            if (!!firstInsemination) {
                if (animal.DtaBrthTime) {
                    const birthDate = +animal.DtaBrthTime;
                    const timeBetweenBirthToFirstInsemination = moment(
                        +firstInsemination.EvTime
                    )
                        .startOf("day")
                        .diff(moment(birthDate).startOf("day"), "days");
                    const minAgeForFirstMatingInDays =
                        getMinAgeForFirstMating();
                    //jesli ilosc dni pomiedzy urodzeniem, a inseminacja jest mniejszy niz zdefiniowany czas w ustawieniach wyrzuc blad
                    if (
                        timeBetweenBirthToFirstInsemination <
                        minAgeForFirstMatingInDays
                    ) {
                        row.invalidEvents.push({
                            EvCode: EventTypes.INSEMINATION,
                            Reason: CycleActions.FIRST_INSEMINATION_FROM_BIRTH,
                            Cycle: row.cycle,
                            Date: firstInsemination.EvTime,
                            AdditionalData: {
                                timeBetweenBirthToFirstInsemination,
                                minAgeForFirstMatingInDays,
                            },
                        });
                    }
                }
                if (!!lastUSG) {
                    const timeBetweenBothEvents = moment(+lastUSG.EvTime)
                        .startOf("day")
                        .diff(
                            moment(+firstInsemination.EvTime).startOf("day"),
                            "days"
                        );
                    // jesli ostatnie badanie usg zostalo zrobione przed uplywem x dni wyrzuc blad

                    if (
                        timeBetweenBothEvents < timeFromInseminationToPregnancy
                    ) {
                        row.invalidEvents.push({
                            EvCode: EventTypes.USG,
                            Date: lastUSG.EvTime,
                            Reason: CycleActions.USG_BEFORE_X_DAYS,
                            Cycle: row.cycle,
                        });
                    }
                }
                if (!!firstUSG) {
                    const timeBetweenBothEvents = moment(+firstUSG.EvTime)
                        .startOf("day")
                        .diff(
                            moment(+firstInsemination.EvTime).startOf("day"),
                            "days"
                        );
                    // jesli pierwsze badanie usg zostalo zrobione po uplywie x dni wyrzuc blad

                    if (
                        timeBetweenBothEvents >= timeFromInseminationToPregnancy
                    ) {
                        row.invalidEvents.push({
                            EvCode: EventTypes.USG,
                            Date: firstUSG.EvTime,
                            Reason: CycleActions.USG_AFTER_X_DAYS,
                            Cycle: row.cycle,
                        });
                    }
                }
                //jesli jest poród
                if (!!firstParturition) {
                    const timeBetweenBothEvents = moment(
                        +firstParturition.EvTime
                    )
                        .startOf("day")
                        .diff(
                            moment(+firstInsemination.EvTime).startOf("day"),
                            "days"
                        );
                    const timeFromInseminationToParturition =
                        getTimeFromInseminationToPartuition();
                    const maxDelayForBirth = getMaxDelayForBirth();
                    const rowItem = {
                        EvCode: EventTypes.PARTURITION,
                        Cycle: row.cycle,
                        Date: firstParturition.EvTime,
                    };
                    //wyproszenie przed uplywem x dni
                    if (
                        timeBetweenBothEvents + maxDelayForBirth <
                        timeFromInseminationToParturition
                    ) {
                        rowItem.Reason = CycleActions.PARTURITION_BEFORE_X_DAYS;
                        rowItem.AdditionalData = {
                            difference: Math.abs(
                                timeBetweenBothEvents +
                                    maxDelayForBirth -
                                    timeFromInseminationToParturition
                            ),
                        };
                        row.invalidEvents.push(rowItem);
                    }
                    //porod zgloszony za pozno
                    else if (
                        timeBetweenBothEvents >
                        timeFromInseminationToParturition + maxDelayForBirth
                    ) {
                        rowItem.Reason = CycleActions.PARTURITION_AFTER_X_DAYS;
                        rowItem.AdditionalData = {
                            timeBetweenBothEvents,
                            difference:
                                timeBetweenBothEvents -
                                (timeFromInseminationToParturition +
                                    maxDelayForBirth),
                        };
                        row.invalidEvents.push(rowItem);
                    }
                }
            }
            //Brak zgloszonej inseminacji a wystapi event w danym cyklu np. USG, Wyproszenie upadek prosiaka, mamka, odsad, aktywne sutki
            else if (!firstInsemination && productionEvent) {
                row.invalidEvents.push({
                    EvCode: EventTypes.INSEMINATION,
                    Reason: CycleActions.NO_INSEMINATION_BEFORE_EV,
                    Cycle: row.cycle,
                    Date: productionEvent.EvTime,
                });
            }
            //Brak zgloszonego badania usg - zgloszone w danym cyklu wyproszenie, updaek prosiaka odsad, lub aktywne sutki
            // wykomentowane na prośbę ~ issue #2838
            // if (!firstUSG && rowEvents.filter(ev => [EventTypes.PARTURITION, EventTypes.FALL_PIGLETS, EventTypes.SEPARATION, EventTypes.ACTIVE_NIPPLES].includes(ev.EvCode)).length) {
            //     row.invalidEvents.push({EvCode: EventTypes.USG, Reason: CycleActions.NO_USG_BEFORE_EV, Cycle: row.cycle});
            // }
            // pokazuj tylko brak badania jesli nie ma porodu
            if (!firstUSG && !firstParturition && !!firstInsemination) {
                // jesli po inseminacji minelo tyle dni ile ustawil w ustawieniach cyklu
                if (
                    moment
                        .utc()
                        .startOf("day")
                        .diff(
                            moment.utc(firstInsemination.EvTime).startOf("day"),
                            "days"
                        ) > timeFromInseminationToPregnancy
                ) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.USG,
                        Reason: CycleActions.NO_USG_BEFORE_EV,
                        Cycle: row.cycle,
                        Date: moment
                            .utc(firstInsemination.EvTime)
                            .startOf("day")
                            .add(timeFromInseminationToPregnancy, "days")
                            .toDate()
                            .getTime(),
                    });
                }
            }
            const fallOrSeparation = rowEvents.find((ev) =>
                [EventTypes.SEPARATION, EventTypes.FALL_PIGLETS].includes(
                    ev.EvCode
                )
            );
            //brak zgloszonego wyproszenia - zgloszony odsad lub upadek prosiaka
            if (!firstParturition && fallOrSeparation) {
                row.invalidEvents.push({
                    EvCode: EventTypes.PARTURITION,
                    Reason: CycleActions.NO_PARTURITION_AFTER_EV,
                    Cycle: row.cycle,
                    Date: fallOrSeparation.EvTime,
                });
            }
            //brak zgloszonego odasdu - po zgloszonym wczesniej wyproszeniu
            if (!separation) {
                const parturition = rowEvents.find((ev) =>
                    [EventTypes.PARTURITION].includes(ev.EvCode)
                );
                //const timeOnBirthRoom = getTimeOnBirthRoom(); // czas na porodówce
                if (parturition) {
                    if (index !== cycles.length - 1) {
                        // jesli nie jest to ostatni wiersz jaki rozpatrujemy
                        row.invalidEvents.push({
                            EvCode: EventTypes.SEPARATION,
                            Reason: CycleActions.NO_SEPARATION_AFTER_EV,
                            Cycle: row.cycle,
                            Date: parturition.EvTime,
                        });
                    }
                }
            } else {
                // jesli odsad byl zgloszony wylicz balans i zobacz czy wszystkie prosieta zostaly odsadzone
                const balance = getPigBalance(rowEvents);
                if (balance > 0) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.SEPARATION,
                        Reason: CycleActions.HAS_NOT_SEPARATED_ALL_PIGLETS_YET,
                        Cycle: row.cycle,
                        Date: separation.EvTime,
                    });
                }
            }
            //Brak zgloszonego porodu po uplywie x dni od a po zgloszonym qwczesniej inseminacji i nie zgloszonych eventrach, ktore zmienily by cykl lub swiadczyly o powtorce
            if (!!firstInsemination && !firstParturition) {
                //brak zgloszonego porodu musi byc sprawdzany dopiero po x dniach
                let timeAfterInsemination = +moment(+firstInsemination.EvTime)
                    .startOf("day")
                    .add(timeFromInseminationToParturition, "days");
                let eventAfterInsemination = rowEvents.find(
                    (ev) =>
                        +ev.EvTime >= timeAfterInsemination &&
                        ev.EvCode !== EventTypes.SEPARATION
                );
                if (eventAfterInsemination) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.PARTURITION,
                        Reason: CycleActions.NO_PARTURITION_AFTER_X_DAYS,
                        Cycle: row.cycle,
                        Date: eventAfterInsemination.EvTime,
                    });
                }
            }
            //ilosc odsadzonych prosiat wieksza niz porod - upadki + mamka
            if (firstParturition && separation) {
                let sowPigletsBalance = 0;
                let separatedPiglets = separation.EvData.PiCnt;
                [
                    ...row[EventTypes.FALL_PIGLETS],
                    ...row[EventTypes.SEPARATION_TO_MOMMY],
                ].forEach((ev) => {
                    if (ev.EvCode === EventTypes.FALL_PIGLETS)
                        sowPigletsBalance -= +ev.EvData.Piglets;
                    if (ev.EvCode === EventTypes.SEPARATION_TO_MOMMY)
                        sowPigletsBalance -= +ev.EvData.PiCnt;
                });
                [
                    ...row[EventTypes.PARTURITION],
                    ...row[EventTypes.MOMMY],
                ].forEach((ev) => {
                    if (ev.EvCode === EventTypes.PARTURITION)
                        sowPigletsBalance += +ev.EvData.HealthyCnt;
                    if (ev.EvCode === EventTypes.MOMMY)
                        sowPigletsBalance += +ev.EvData.PiCnt;
                });

                if (separatedPiglets > sowPigletsBalance) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.SEPARATION,
                        Reason: CycleActions.SEPARATION_CNT_BIGGER_THAN_PARTURITION_CNT,
                        Cycle: row.cycle,
                        Date: separation.EvTime,
                    });
                }
            }
            //ilosc odsadzonych prosiat do mamki wieksza niz ilosc urodzonych - upadki do momentu zgloszenia
            if (firstParturition && separationToMommy) {
                const lastSeparationToMommy =
                    row[EventTypes.SEPARATION_TO_MOMMY][
                        row[EventTypes.SEPARATION_TO_MOMMY].length - 1
                    ];
                const parturitionBalance = getPigletsCountFromParturitions(
                    row[EventTypes.PARTURITION]
                );
                const fallPigletsBalance = !isEmpty(
                    row[EventTypes.FALL_PIGLETS]
                )
                    ? row[EventTypes.FALL_PIGLETS]
                          .filter(
                              (fall) =>
                                  +fall.EvTime <= +lastSeparationToMommy.EvTime
                          )
                          .reduce((sum, ev) => {
                              return sum + +ev.EvData.Piglets;
                          }, 0)
                    : 0;
                const separationToMommyBalance = !isEmpty(
                    row[EventTypes.SEPARATION_TO_MOMMY]
                )
                    ? row[EventTypes.SEPARATION_TO_MOMMY].reduce((sum, ev) => {
                          return sum + +ev.EvData.PiCnt;
                      }, 0)
                    : 0;
                const mommyBalance = !isEmpty(row[EventTypes.MOMMY])
                    ? row[EventTypes.MOMMY].reduce((sum, ev) => {
                          return sum + +ev.EvData.PiCnt;
                      }, 0)
                    : 0;
                const balance =
                    parturitionBalance +
                    mommyBalance -
                    separationToMommyBalance -
                    fallPigletsBalance;
                const negativeAmount = Math.abs(balance);
                if (balance < 0) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.SEPARATION_TO_MOMMY,
                        Reason: CycleActions.SEPARATION_CNT_BIGGER_THAN_BIRTH_CNT,
                        Cycle: row.cycle,
                        Date: separationToMommy.EvTime,
                        AdditionalData: {
                            balance: negativeAmount,
                        },
                    });
                }
            }
            //ilosc prosiakow wieksza niz aktywne sutki (bierzemy pod uwage tylko ostatnie)
            if (activeNipples && firstParturition) {
                const lastActiveNipplesCnt =
                    row[EventTypes.ACTIVE_NIPPLES][
                        row[EventTypes.ACTIVE_NIPPLES].length - 1
                    ].Nipples;
                const events = rowEvents
                    .filter((ev) =>
                        [
                            EventTypes.PARTURITION,
                            EventTypes.FALL_PIGLETS,
                            EventTypes.SEPARATION_TO_MOMMY,
                            EventTypes.SEPARATION,
                            EventTypes.MOMMY,
                            EventTypes.INSEMINATION,
                        ].includes(ev.EvCode)
                    )
                    .filter((ev) => +ev.EvTime >= +firstParturition.EvTime);
                const pigBalance = getPigBalance(events);
                if (pigBalance > lastActiveNipplesCnt) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.ACTIVE_NIPPLES,
                        Reason: CycleActions.PIGLET_CNT_BIGGER_THAN_NIPPLES,
                        Cycle: row.cycle,
                        Date: firstParturition.EvTime,
                    });
                }
            }
            if (sowCycle) {
                const lastSeparationDay = moment
                    .utc(get(sowCycle, "EvTime"))
                    .startOf("day");
                if (!separation) {
                    row.invalidEvents.push({
                        EvCode: EventTypes.SEPARATION,
                        Reason: CycleActions.NO_SEPARATION_BUT_SOW_CYCLES,
                        Cycle: row.cycle,
                        type: "success",
                        Date: +lastSeparationDay,
                        AdditionalData: {
                            lastSeparation: lastSeparationDay.format("L"),
                        },
                    });
                } else {
                    const isSame = moment
                        .utc(get(separation, "EvTime"))
                        .startOf("day")
                        .isSame(lastSeparationDay);
                    if (!isSame) {
                        row.invalidEvents.push({
                            EvCode: EventTypes.SEPARATION,
                            Date: +lastSeparationDay,
                            Reason: CycleActions.LAST_SEPARATION_DIFF_THAN_IN_REALITY,
                            Cycle: row.cycle,
                            type: "warning",
                        });
                    }
                }
            }
            arr.push(row);
        });
    return arr;
}

/**
 * Funkcja zwracjąca ilość otrzymanych prosiąt z porodów.
 * @param parturitions
 * @returns {*}
 */
function getPigletsCountFromParturitions(parturitions) {
    return parturitions.reduce((sum, ev) => {
        return sum + +ev.EvData.HealthyCnt;
    }, 0);
}

/**
 * Funkcja filtrująca wiersz z niepotrzebnych kluczy zostawiająca tylko te zawierające eventy.
 * @param row
 * @returns {Array}
 */
export function getEventsFromRow(row) {
    const events = [];
    for (let [key, value] of Object.entries(row || {})) {
        if (!["cycle", "invalidEvents"].includes(key)) {
            if (Array.isArray(value) && !isEmpty(value))
                value.forEach((el) => events.push(el));
        }
    }
    return events;
}

export function getParturitionIndexForASow(
    animal,
    sowCycles,
    utilResults,
    endTimeResult = undefined
) {
    let parturitionIndex = null;
    let productiveDays = 0;
    let parturitionDays = 0;
    let parturitionCycles = 0;
    let separationCycles = 0;
    let separationToMommyCnt = 0;
    let separationToMommyAmount = 0;
    let mommyCnt = 0;
    let mommyAmount = 0;
    let lactationDays = 0;
    let avgLactationDays = 0;
    let idleDays = 0;
    let avgIdleDays = 0;
    let idleDaysCnt = 0;
    let parturitionWeight = 0;
    let parturitionCnt = 0;
    let healthyCnt = 0;
    let avgHealthyCnt = 0;
    let mummyCnt = 0;
    let avgMummyCnt = 0;
    let deadCnt = 0;
    let avgDeadCnt = 0;
    let weakCnt = 0;
    let avgWeakCnt = 0;
    let giltsCnt = 0;
    let avgGiltsCnt = 0;
    let separationWeight = 0;
    let separationCnt = 0;
    let showSeparationResultAsZero = false;
    let separatedCnt = 0;
    let avgSeparatedCnt = 0;
    let activeNipples = 0;
    let falls = 0;
    let fallPigletsCnt = 0;
    let avgFallPigletsCnt = 0;
    let firstParturitionTime = 0;
    let lastParturitionTime = 0;
    let firstSeparationTime = 0;
    let lastSeparationTime = 0;
    let pigletsMortality = 0;
    let averageAgeOfFallenPiglets = 0;
    let currentCycle = null;
    let repetitions = 0;
    const parturitionNumbers = [];
    const parturitionWeights = [];
    const separationNumbers = [];
    const separationWeights = [];
    const cycleResults = [];
    // jesli jestesmy w aktualnym tygodniu to chcemy liczyć do dzisiaj - w innym wypadku weź pełen koniec tygodnia
    const endTime =
        endTimeResult && !endTimeResult.isAfter(moment.utc().endOf("day"))
            ? +endTimeResult
            : undefined;
    const setCycleValues = (cycle) => {
        let hasSeparation = false;
        [
            ...cycle[EventTypes.PARTURITION],
            ...cycle[EventTypes.CASTRATION],
            ...cycle[EventTypes.SEPARATION],
            ...cycle[EventTypes.FALL_PIGLETS],
            ...cycle[EventTypes.MOMMY],
            ...cycle[EventTypes.SEPARATION_TO_MOMMY],
        ].forEach((o) => {
            const {EvCode, EvTime} = o;
            switch (EvCode) {
                case EventTypes.MOMMY:
                    mommyAmount += get(o, "EvData.PiCnt", 0);
                    mommyCnt++;
                    break;
                case EventTypes.SEPARATION_TO_MOMMY:
                    separationToMommyAmount += get(o, "EvData.PiCnt", 0);
                    separationToMommyCnt++;
                    break;
                case EventTypes.PARTURITION: {
                    const healthyPiglets = get(o, "EvData.HealthyCnt", 0);
                    const castrationWeighting = cycle[
                        EventTypes.CASTRATION
                    ].find((castration) =>
                        get(castration, "EvData.Weighting", false)
                    );
                    if (castrationWeighting) {
                        parturitionNumbers.push(
                            get(castrationWeighting, "EvData.Weight", 0)
                        );
                        parturitionWeights.push(healthyPiglets);
                    }
                    healthyCnt += healthyPiglets;
                    weakCnt += get(o, "EvData.WeakCnt", 0);
                    mummyCnt += get(o, "EvData.MummyCnt", 0);
                    deadCnt += get(o, "EvData.DeadCnt", 0);
                    giltsCnt += get(o, "EvData.GiltsCnt", 0);
                    parturitionCnt++;
                    break;
                }
                case EventTypes.SOW_CYCLES:
                case EventTypes.SEPARATION: {
                    const weight = get(o, "EvData.PiWeight", 0);
                    const amount = get(o, "EvData.PiCnt", 0);
                    const separationWeighting = cycle[
                        EventTypes.SEPARATION
                    ].find((event) => get(event, "EvData.PiWeight", false));
                    if (separationWeighting) {
                        separationNumbers.push(weight);
                        separationWeights.push(amount);
                    }
                    separatedCnt += amount;
                    separationWeight += weight;
                    if (weight) hasSeparation = true;
                    break;
                }
                case EventTypes.FALL_PIGLETS: {
                    falls++;
                    fallPigletsCnt += get(o, "EvData.Piglets", 0);
                    if (cycle[EventTypes.PARTURITION].length) {
                        averageAgeOfFallenPiglets += moment(EvTime).diff(
                            cycle[EventTypes.PARTURITION][0].EvTime,
                            "days"
                        );
                    }
                    break;
                }
                default:
                    break;
            }
        });
        if (hasSeparation) separationCnt++;
    };
    for (let i = 0; i < sowCycles.length; i++) {
        const cycle = sowCycles[i];
        if (i > 0 && currentCycle === cycle.cycle) repetitions++;
        currentCycle = cycle.cycle;
        const isLastCycle = i === sowCycles.length - 1;
        const nextCycle = isLastCycle ? null : sowCycles[i + 1];
        const previousCycle = i === 0 ? null : sowCycles[i - 1];
        // flaga bo jesli sa powtorki to powinno pokazac wiersze ale finalnie podzielic przez ilosc cykli
        const canAddIdleDays =
            !previousCycle || previousCycle.cycle !== cycle.cycle;
        const hasInsemination = cycle[EventTypes.INSEMINATION].length > 0;
        const hasParturition = cycle[EventTypes.PARTURITION].length > 0;
        const separations = [
            ...cycle[EventTypes.SOW_CYCLES],
            ...cycle[EventTypes.SEPARATION],
        ];
        const hasSeparation =
            [...cycle[EventTypes.SOW_CYCLES], ...cycle[EventTypes.SEPARATION]]
                .length > 0;
        const hasMommy = cycle[EventTypes.MOMMY].length > 0;
        const hasActiveNipples = cycle[EventTypes.ACTIVE_NIPPLES].length > 0;
        const weightingEvents = cycle[EventTypes.CASTRATION].filter(
            (e) => e.EvData.Weighting
        );
        const hasNoPregnancy = cycle[EventTypes.NO_PREGNANCY].length > 0;
        const hasUsg = cycle[EventTypes.USG].length > 0;
        const hasNegativeUsg =
            hasUsg &&
            [false, USG_STATE.NEGATIVE].includes(
                get(
                    last(cycle[EventTypes.USG]),
                    "EvData.Pregnant",
                    USG_STATE.POSITIVE
                )
            );
        const deadTime = animal.DtaDthTime || undefined;
        const hasErrors = hasNegativeUsg || hasNoPregnancy || deadTime;
        const nextCycleInsemination = get(
            nextCycle,
            `[${EventTypes.INSEMINATION}][0]`
        );
        const cycleEvents = getEventsFromRow(cycle);
        const balance = getPigBalance(cycleEvents);
        const separationTime = get(
            last([
                ...cycle[EventTypes.SOW_CYCLES],
                ...cycle[EventTypes.SEPARATION],
            ]),
            "EvTime"
        );
        cycleEvents.sort((a, b) => b.EvTime - a.EvTime);
        const lastCycleEvent = cycleEvents[0];
        const hasSomePiglets = balance !== 0;
        const firstEvent = findLast(cycleEvents, ({EvCode}) =>
            getEventsFromCard().includes(EvCode)
        );
        const cycleStartTime = get(firstEvent, "EvTime", cycle.StartTime);
        setCycleValues(cycle);
        if (hasInsemination) {
            if (hasParturition && hasSeparation) {
                const parturitionTime = last(
                    cycle[EventTypes.PARTURITION]
                ).EvTime;
                const _productiveDays = moment
                    .utc(separationTime)
                    .endOf("day")
                    .diff(moment.utc(cycleStartTime).startOf("day"), "days");
                const _lactationDays = moment
                    .utc(separationTime)
                    .endOf("day")
                    .diff(moment.utc(parturitionTime).startOf("day"), "days");
                const _parturitionDays = moment
                    .utc(parturitionTime)
                    .endOf("day")
                    .diff(moment.utc(cycleStartTime).startOf("day"), "days");
                let _mommyDays = 0;
                let _idleDays = 0;
                if (hasMommy && separations.length > 1) {
                    const mommyTime = moment(
                        cycle[EventTypes.MOMMY][0].EvTime
                    ).startOf("day");
                    const firstSeparationTime = moment(
                        separations[0].EvTime
                    ).startOf("day");
                    if (+mommyTime >= +firstSeparationTime) {
                        _mommyDays = moment
                            .utc(separationTime)
                            .endOf("day")
                            .diff(firstSeparationTime, "days");
                    }
                }
                productiveDays += _productiveDays;
                lactationDays += _lactationDays;
                parturitionDays += _parturitionDays;
                if (!hasSomePiglets) {
                    if (isLastCycle) {
                        _idleDays = moment
                            .utc(endTime)
                            .endOf("day")
                            .diff(
                                moment.utc(separationTime).startOf("day"),
                                "days"
                            );
                    } else {
                        _idleDays = moment
                            .utc(nextCycle.StartTime)
                            .endOf("day")
                            .diff(
                                moment.utc(separationTime).startOf("day"),
                                "days"
                            );
                    }
                    idleDays += _idleDays;
                    if (canAddIdleDays) idleDaysCnt++;
                }
                parturitionCycles++;
                separationCycles++;
                const _healthyCnt = get(
                    cycle,
                    `[${EventTypes.PARTURITION}][0].EvData.HealthyCnt`,
                    null
                );
                const _mommyCnt = get(cycle, `${EventTypes.MOMMY}`, []).reduce(
                    (a, b) => a + get(b, "EvData.PiCnt", 0),
                    null
                );
                const getSeparationWeight = (cycle) => {
                    if (cycle[EventTypes.SEPARATION].length) {
                        let separationWeights = [];
                        let separationNumbers = [];
                        get(cycle, `[${EventTypes.SEPARATION}]`, []).forEach(
                            (event) => {
                                separationNumbers.push(
                                    get(event, "EvData.PiWeight", 0)
                                );
                                separationWeights.push(
                                    get(event, "EvData.PiCnt", 0)
                                );
                            }
                        );
                        return getWeightedAverage(
                            separationNumbers,
                            separationWeights
                        );
                    }
                    return null;
                };
                cycleResults.push({
                    productiveDays: _productiveDays,
                    lactationDays: _lactationDays,
                    parturitionDays: _parturitionDays,
                    idleDays: _idleDays,
                    mommyDays: _mommyDays,
                    cycleIndex: cycle.cycle,
                    activeNipples: get(
                        cycle,
                        `${EventTypes.ACTIVE_NIPPLES}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.Nipples", 0), null),
                    healthyCnt: _healthyCnt,
                    deadCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.DeadCnt`,
                        null
                    ),
                    mummyCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.MummyCnt`,
                        null
                    ),
                    separationToMommyCnt: get(
                        cycle,
                        `${EventTypes.SEPARATION_TO_MOMMY}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.PiCnt", 0), null),
                    mommyCnt: _mommyCnt,
                    weakCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.WeakCnt`,
                        null
                    ),
                    giltsCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.GiltsCnt`,
                        0
                    ),
                    separationCnt: get(
                        cycle,
                        `${EventTypes.SEPARATION}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.PiCnt", 0), null),
                    parturitionWeight: weightingEvents.length
                        ? weightingEvents.reduce(
                              (a, b) => a + get(b, "EvData.Weight", 0),
                              0
                          )
                        : 0,
                    separationWeight: getSeparationWeight(cycle),
                    fallPigletsCnt: get(
                        cycle,
                        `${EventTypes.FALL_PIGLETS}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.Piglets", 0), null),
                    healthyWithMommy:
                        _healthyCnt === null && _mommyCnt === null
                            ? null
                            : (_healthyCnt || 0) + (_mommyCnt || 0),
                });
                // wagi
                if (firstParturitionTime === 0)
                    firstParturitionTime = parturitionTime;
                if (firstSeparationTime === 0)
                    firstSeparationTime = separationTime;
                if (lastParturitionTime < parturitionTime)
                    lastParturitionTime = parturitionTime;
                if (lastSeparationTime < separationTime)
                    lastSeparationTime = separationTime;
            } else if (!isLastCycle) {
                let _idleDays = 0;
                let _lactationDays = 0;
                let _parturitionDays = 0;
                let _productiveDays = lastCycleEvent
                    ? moment
                          .utc(lastCycleEvent.EvTime)
                          .endOf("day")
                          .diff(
                              moment.utc(cycleStartTime).startOf("day"),
                              "days"
                          )
                    : 0;
                productiveDays += _productiveDays;
                if (hasParturition && !hasErrors) {
                    const parturitionTime = last(
                        cycle[EventTypes.PARTURITION]
                    ).EvTime;
                    _parturitionDays = moment
                        .utc(parturitionTime)
                        .endOf("day")
                        .diff(
                            moment.utc(cycleStartTime).startOf("day"),
                            "days"
                        );
                    parturitionDays += _parturitionDays;
                    parturitionCycles++;
                }
                if (nextCycle && !hasParturition && !hasSomePiglets) {
                    _idleDays = moment
                        .utc(nextCycle.StartTime)
                        .endOf("day")
                        .diff(
                            moment
                                .utc(
                                    hasSeparation
                                        ? separationTime
                                        : cycleStartTime
                                )
                                .startOf("day"),
                            "days"
                        );
                    idleDays += _idleDays;
                    if (canAddIdleDays) idleDaysCnt++;
                } else if (
                    nextCycleInsemination &&
                    hasParturition &&
                    !hasSomePiglets
                ) {
                    // jesli ma juz nastepny cykl z inseminacja a nie ma zadnych prosiakow to mozemy policzyc dni jalowe
                    const lastEventFromCycle = cycleEvents
                        .slice()
                        .sort((a, b) => b.EvTime - a.EvTime)[0];
                    _idleDays = moment
                        .utc(nextCycleInsemination.EvTime)
                        .endOf("day")
                        .diff(
                            moment
                                .utc(lastEventFromCycle.EvTime)
                                .startOf("day"),
                            "days"
                        );
                    idleDays += _idleDays;
                    if (canAddIdleDays) idleDaysCnt++;
                    // jesli nie odsadzila pomimo porodu to wliczamy ją do statystyk na zero
                    if (!hasSeparation) showSeparationResultAsZero = true;
                    // dni laktacji od porodu do ostatniego prosiaka
                    _lactationDays =
                        moment(lastEventFromCycle.EvTime)
                            .endOf("day")
                            .diff(
                                moment(
                                    cycle[EventTypes.PARTURITION][0].EvTime
                                ).startOf("day"),
                                "days"
                            ) + 1;
                    lactationDays += _lactationDays;
                    separationCycles++;
                }
                const _healthyCnt = get(
                    cycle,
                    `[${EventTypes.PARTURITION}][0].EvData.HealthyCnt`,
                    null
                );
                const _mommyCnt = get(cycle, `${EventTypes.MOMMY}`, []).reduce(
                    (a, b) => a + get(b, "EvData.PiCnt", 0),
                    null
                );
                cycleResults.push({
                    productiveDays: _productiveDays,
                    lactationDays: _lactationDays,
                    parturitionDays: _parturitionDays,
                    idleDays: _idleDays,
                    mommyDays: 0,
                    cycleIndex: cycle.cycle,
                    activeNipples: get(
                        cycle,
                        `${EventTypes.ACTIVE_NIPPLES}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.Nipples", 0), null),
                    healthyCnt: _healthyCnt,
                    deadCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.DeadCnt`,
                        null
                    ),
                    mummyCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.MummyCnt`,
                        null
                    ),
                    weakCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.WeakCnt`,
                        null
                    ),
                    giltsCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.GiltsCnt`,
                        null
                    ),
                    separationToMommyCnt: get(
                        cycle,
                        `${EventTypes.SEPARATION_TO_MOMMY}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.PiCnt", 0), null),
                    mommyCnt: _mommyCnt,
                    separationCnt: showSeparationResultAsZero
                        ? 0
                        : get(cycle, `${EventTypes.SEPARATION}`, []).reduce(
                              (a, b) => a + get(b, "EvData.PiCnt", 0),
                              null
                          ),
                    parturitionWeight: weightingEvents.length
                        ? weightingEvents.reduce(
                              (a, b) => a + get(b, "EvData.Weight", 0),
                              0
                          )
                        : 0,
                    separationWeight: cycle[EventTypes.SEPARATION].length
                        ? get(cycle, `[${EventTypes.SEPARATION}]`, []).reduce(
                              (a, b) => a + get(b, "EvData.PiWeight", 0),
                              0
                          ) / cycle[EventTypes.SEPARATION].length
                        : null,
                    fallPigletsCnt: get(
                        cycle,
                        `${EventTypes.FALL_PIGLETS}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.Piglets", 0), null),
                    healthyWithMommy:
                        _healthyCnt === null && _mommyCnt === null
                            ? null
                            : (_healthyCnt || 0) + (_mommyCnt || 0),
                });
            } else {
                let _parturitionDays = null;
                let _idleDays = null;
                let _productiveDays = Math.abs(
                    moment
                        .utc(endTime)
                        .endOf("day")
                        .diff(moment.utc(cycleStartTime).startOf("day"), "days")
                );
                if (hasParturition && !hasErrors) {
                    const parturitionTime = last(
                        cycle[EventTypes.PARTURITION]
                    ).EvTime;
                    _parturitionDays = moment
                        .utc(parturitionTime)
                        .endOf("day")
                        .diff(
                            moment.utc(cycleStartTime).startOf("day"),
                            "days"
                        );
                    parturitionDays += _parturitionDays;
                    // dni laktacji liczymy tylko jesli w cyklu byl juz odsad - https://redmine.wesstron.local/issues/7448
                    // _lactationDays = moment(endTime).endOf("day").diff(moment(cycle[EventTypes.PARTURITION][0].EvTime).startOf("day"), "days");
                    // lactationDays += _lactationDays;
                    parturitionCycles++;
                } else if (hasErrors && !hasSomePiglets) {
                    _productiveDays = 0;
                    if (hasParturition) {
                        // jezeli byl porod to znaczy ze miala prosiaki, wiec dni jalowe liczymy od ostatniego eventu utraty prosiakow #11151
                        const managePigletsEvents = [
                            ...cycle[EventTypes.FALL_PIGLETS],
                            ...cycle[EventTypes.SEPARATION_TO_MOMMY],
                            ...cycle[EventTypes.TATTOO],
                        ].sort((a, b) => b.EvTime - a.EvTime);
                        _idleDays = moment
                            .utc(deadTime)
                            .endOf("day")
                            .diff(
                                moment
                                    .utc(
                                        managePigletsEvents?.[0]?.EvTime ||
                                            cycleStartTime
                                    )
                                    .startOf("day"),
                                "days"
                            );
                    } else {
                        _idleDays = moment
                            .utc(deadTime)
                            .endOf("day")
                            .diff(
                                moment.utc(cycleStartTime).startOf("day"),
                                "days"
                            );
                    }
                    idleDays += _idleDays;
                    if (canAddIdleDays) idleDaysCnt++;
                } else if (!hasParturition && !hasErrors) {
                    const delay = utilResults
                        ? utilResults.TimeFromInseminationToParturition +
                          utilResults.MaxDelayForBirth
                        : getTimeFromInseminationToPartuition() +
                          getMaxDelayForBirth();
                    const expectedParturitionDate = moment
                        .utc(cycleStartTime)
                        .startOf("day")
                        .add(delay, "days");
                    // jesli sie nie oprosila w wyznaczonym czasie (114 + max opoznienie) to zaliczamy wszystkie dni jako jalowe
                    if (
                        (!hasSomePiglets &&
                            moment
                                .utc(endTime)
                                .endOf("day")
                                .isAfter(expectedParturitionDate)) ||
                        hasSeparation
                    ) {
                        _productiveDays = 0;
                        _idleDays = moment
                            .utc(endTime)
                            .endOf("day")
                            .diff(
                                moment
                                    .utc(
                                        hasSeparation
                                            ? separationTime
                                            : cycleStartTime
                                    )
                                    .startOf("day"),
                                "days"
                            );
                        idleDays += _idleDays;
                        if (canAddIdleDays) idleDaysCnt++;
                    }
                }
                productiveDays += _productiveDays;
                const _healthyCnt = get(
                    cycle,
                    `[${EventTypes.PARTURITION}][0].EvData.HealthyCnt`,
                    null
                );
                const _mommyCnt = get(cycle, `${EventTypes.MOMMY}`, []).reduce(
                    (a, b) => a + get(b, "EvData.PiCnt", 0),
                    null
                );
                cycleResults.push({
                    productiveDays: _productiveDays,
                    lactationDays: 0,
                    parturitionDays: _parturitionDays,
                    idleDays: _idleDays,
                    mommyDays: 0,
                    cycleIndex: cycle.cycle,
                    activeNipples: get(
                        cycle,
                        `${EventTypes.ACTIVE_NIPPLES}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.Nipples", 0), null),
                    healthyCnt: _healthyCnt,
                    deadCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.DeadCnt`,
                        null
                    ),
                    mummyCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.MummyCnt`,
                        null
                    ),
                    weakCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.WeakCnt`,
                        null
                    ),
                    giltsCnt: get(
                        cycle,
                        `[${EventTypes.PARTURITION}][0].EvData.GiltsCnt`,
                        null
                    ),
                    separationToMommyCnt: get(
                        cycle,
                        `${EventTypes.SEPARATION_TO_MOMMY}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.PiCnt", 0), null),
                    mommyCnt: _mommyCnt,
                    separationCnt: get(
                        cycle,
                        `${EventTypes.SEPARATION}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.PiCnt", 0), null),
                    parturitionWeight: weightingEvents.length
                        ? weightingEvents.reduce(
                              (a, b) => a + get(b, "EvData.Weight", 0),
                              0
                          )
                        : 0,
                    separationWeight: cycle[EventTypes.SEPARATION].length
                        ? get(cycle, `[${EventTypes.SEPARATION}]`, []).reduce(
                              (a, b) => a + get(b, "EvData.PiWeight", 0),
                              0
                          ) / cycle[EventTypes.SEPARATION].length
                        : null,
                    fallPigletsCnt: get(
                        cycle,
                        `${EventTypes.FALL_PIGLETS}`,
                        []
                    ).reduce((a, b) => a + get(b, "EvData.Piglets", 0), null),
                    healthyWithMommy:
                        _healthyCnt === null && _mommyCnt === null
                            ? null
                            : (_healthyCnt || 0) + (_mommyCnt || 0),
                });
            }
        } else {
            let _idleDays =
                hasErrors && !hasSomePiglets
                    ? moment
                          .utc(
                              isLastCycle
                                  ? endTime
                                      ? endTime
                                      : undefined
                                  : nextCycle.StartTime
                          )
                          .endOf("day")
                          .diff(
                              moment.utc(cycleStartTime).startOf("day"),
                              "days"
                          )
                    : null;
            if (hasSeparation && !hasParturition && nextCycleInsemination) {
                _idleDays = moment
                    .utc(nextCycleInsemination.EvTime)
                    .endOf("day")
                    .diff(moment.utc(separationTime).startOf("day"), "days");
            }
            if (!isNil(_idleDays)) {
                idleDays += _idleDays;
                idleDaysCnt++;
            }
            const _healthyCnt = get(
                cycle,
                `[${EventTypes.PARTURITION}][0].EvData.HealthyCnt`,
                null
            );
            const _mommyCnt = get(cycle, `${EventTypes.MOMMY}`, []).reduce(
                (a, b) => a + get(b, "EvData.PiCnt", 0),
                null
            );
            cycleResults.push({
                productiveDays: null,
                lactationDays: null,
                parturitionDays: null,
                idleDays: _idleDays,
                cycleIndex: cycle.cycle,
                mommyDays: 0,
                warning: true,
                activeNipples: get(
                    cycle,
                    `${EventTypes.ACTIVE_NIPPLES}`,
                    []
                ).reduce((a, b) => a + get(b, "EvData.Nipples", 0), null),
                healthyCnt: _healthyCnt,
                deadCnt: get(
                    cycle,
                    `[${EventTypes.PARTURITION}][0].EvData.DeadCnt`,
                    null
                ),
                mummyCnt: get(
                    cycle,
                    `[${EventTypes.PARTURITION}][0].EvData.MummyCnt`,
                    null
                ),
                weakCnt: get(
                    cycle,
                    `[${EventTypes.PARTURITION}][0].EvData.WeakCnt`,
                    null
                ),
                giltsCnt: get(
                    cycle,
                    `[${EventTypes.PARTURITION}][0].EvData.GiltsCnt`,
                    null
                ),
                separationToMommyCnt: get(
                    cycle,
                    `${EventTypes.SEPARATION_TO_MOMMY}`,
                    []
                ).reduce((a, b) => a + get(b, "EvData.PiCnt", 0), null),
                mommyCnt: _mommyCnt,
                separationCnt: get(
                    cycle,
                    `${EventTypes.SEPARATION}`,
                    []
                ).reduce((a, b) => a + get(b, "EvData.PiCnt", 0), null),
                parturitionWeight: weightingEvents.length
                    ? weightingEvents.reduce(
                          (a, b) => a + get(b, "EvData.Weight", 0),
                          0
                      )
                    : 0,
                separationWeight: cycle[EventTypes.SEPARATION].length
                    ? get(cycle, `[${EventTypes.SEPARATION}]`, []).reduce(
                          (a, b) => a + get(b, "EvData.PiWeight", 0),
                          0
                      ) / cycle[EventTypes.SEPARATION].length
                    : null,
                fallPigletsCnt: get(
                    cycle,
                    `${EventTypes.FALL_PIGLETS}`,
                    []
                ).reduce((a, b) => a + get(b, "EvData.Piglets", 0), null),
                healthyWithMommy:
                    _healthyCnt === null && _mommyCnt === null
                        ? null
                        : (_healthyCnt || 0) + (_mommyCnt || 0),
            });
        }
        if (hasActiveNipples) {
            const nipples = get(
                last(cycle[EventTypes.ACTIVE_NIPPLES]),
                "EvData.Nipples",
                0
            );
            activeNipples += nipples;
        }
    }
    const healthyWithMommy = healthyCnt + mommyAmount;
    parturitionDays = parturitionCycles
        ? +parseFloat(`${parturitionDays / parturitionCnt}`).toFixed(2)
        : null;
    avgLactationDays = separationCycles
        ? +parseFloat(`${lactationDays / separationCycles}`).toFixed(2)
        : null;
    const secondParturitionIndexDenominator = productiveDays + idleDays;
    const firstParturitionIndexDenominator = parturitionDays + avgLactationDays;
    parturitionIndex =
        parturitionCycles &&
        firstParturitionIndexDenominator > 0 &&
        secondParturitionIndexDenominator > 0
            ? +parseFloat(
                  `${
                      (productiveDays / firstParturitionIndexDenominator) *
                      (365 / secondParturitionIndexDenominator)
                  }`
              ).toFixed(2)
            : null;
    parturitionWeight =
        parturitionNumbers.length > 0
            ? getWeightedAverage(parturitionNumbers, parturitionWeights)
            : null;
    separationWeight =
        separationNumbers.length > 0
            ? getWeightedAverage(separationNumbers, separationWeights)
            : null;
    avgHealthyCnt = parturitionCycles
        ? +parseFloat(`${healthyCnt / parturitionCnt}`).toFixed(2)
        : null;
    avgWeakCnt = parturitionCycles
        ? +parseFloat(`${weakCnt / parturitionCnt}`).toFixed(2)
        : null;
    avgGiltsCnt = parturitionCycles
        ? +parseFloat(`${giltsCnt / parturitionCnt}`).toFixed(2)
        : null;
    avgMummyCnt = parturitionCycles
        ? +parseFloat(`${mummyCnt / parturitionCnt}`).toFixed(2)
        : null;
    avgDeadCnt = parturitionCycles
        ? +parseFloat(`${deadCnt / parturitionCnt}`).toFixed(2)
        : null;
    avgSeparatedCnt = separationCnt
        ? +parseFloat(`${separatedCnt / separationCnt}`).toFixed(2)
        : null;
    avgFallPigletsCnt = falls
        ? +parseFloat(`${fallPigletsCnt / falls}`).toFixed(2)
        : null;
    avgIdleDays = idleDaysCnt
        ? +parseFloat(`${idleDays / idleDaysCnt}`).toFixed(2)
        : null;
    separationToMommyAmount = separationToMommyAmount
        ? +parseFloat(
              `${separationToMommyAmount / separationToMommyCnt}`
          ).toFixed(2)
        : null;
    pigletsMortality =
        healthyWithMommy > 0 && fallPigletsCnt > 0
            ? +parseFloat(
                  `${(fallPigletsCnt / healthyWithMommy) * 100}`
              ).toFixed(2)
            : null;
    mommyAmount = mommyAmount
        ? +parseFloat(`${mommyAmount / mommyCnt}`).toFixed(2)
        : null;
    averageAgeOfFallenPiglets =
        falls > 0 && averageAgeOfFallenPiglets > 0
            ? +parseFloat(`${averageAgeOfFallenPiglets / falls}`).toFixed(2)
            : null;

    return {
        results: {
            parturitionIndex,
            lactationDays: avgLactationDays,
            idleDays,
            avgIdleDays,
            parturitionWeight,
            separationWeight,
            healthyCnt,
            healthyWithMommy,
            avgHealthyCnt,
            weakCnt,
            avgWeakCnt,
            mummyCnt,
            avgMummyCnt,
            deadCnt,
            avgGiltsCnt,
            giltsCnt,
            avgDeadCnt,
            separatedCnt,
            avgSeparatedCnt: showSeparationResultAsZero ? 0 : avgSeparatedCnt,
            activeNipples,
            fallPigletsCnt,
            avgFallPigletsCnt,
            repetitions,
            currentCycle,
            separationToMommyAmount,
            mommyAmount,
            pigletsMortality,
            averageAgeOfFallenPiglets,
            parturitionCnt,
        },
        cycleResults,
    };
}

export const dateFormatter = (value) =>
    value ? moment(value).format("DD.MM.YY") : "";

export const getLactationDaysDeviation = (
    min,
    max,
    value,
    {AverageLactationDaysRange, TimeOnBirthRoom} = {}
) => {
    const idealLactationDays = !AverageLactationDaysRange.includes("auto")
        ? +parseFloat(
              `${
                  (AverageLactationDaysRange[0] +
                      AverageLactationDaysRange[1]) /
                  2
              }`
          ).toFixed(2)
        : TimeOnBirthRoom;
    if (!isNumber(value)) return null;
    else if (value === idealLactationDays) return 50;
    else {
        let _min = !AverageLactationDaysRange.includes("auto")
            ? min
            : min + TimeOnBirthRoom;
        let _max = !AverageLactationDaysRange.includes("auto")
            ? max
            : max + TimeOnBirthRoom;
        if (_max - _min === 0) return null;
        let a = 100 / (_max - _min);
        let b = 0 - a * _min;
        let result = a * value + b;
        return result < 0 ? 0 : result > 100 ? 100 : result;
    }
};

export const getLactationDaysDeviationPercent = (percent) => {
    if (percent === null) return percent;
    if (percent === 50) return 100;
    if (percent === 0 || percent === 100) return 0;
    let value;
    if (percent < 50) {
        value = 2 * percent;
    } else {
        value = -2 * percent + 200;
    }
    return value;
};

export function getSowResult(data, animal, utilResults) {
    const timeOnBirthRoom = utilResults.TimeOnBirthRoom;
    const result = {
        parturitionIndexMin: !utilResults.ParturitionIndexRange.includes("auto")
            ? utilResults.ParturitionIndexRange[0]
            : 0,
        parturitionIndexMax: !utilResults.ParturitionIndexRange.includes("auto")
            ? utilResults.ParturitionIndexRange[1]
            : null,
        avgHealthyCntMin: !utilResults.HealthyPigletsPerLitterRange.includes(
            "auto"
        )
            ? utilResults.HealthyPigletsPerLitterRange[0]
            : 0,
        avgHealthyCntMax: !utilResults.HealthyPigletsPerLitterRange.includes(
            "auto"
        )
            ? utilResults.HealthyPigletsPerLitterRange[1]
            : null,
        avgSeparatedCntMin:
            !utilResults.SeparatedPigletsPerLitterRange.includes("auto")
                ? utilResults.SeparatedPigletsPerLitterRange[0]
                : 0,
        avgSeparatedCntMax:
            !utilResults.SeparatedPigletsPerLitterRange.includes("auto")
                ? utilResults.SeparatedPigletsPerLitterRange[1]
                : null,
        avgParturitionedPigletWeightMin:
            !utilResults.AverageParturitionWeightRange.includes("auto")
                ? utilResults.AverageParturitionWeightRange[0]
                : 0,
        avgParturitionedPigletWeightMax:
            !utilResults.AverageParturitionWeightRange.includes("auto")
                ? utilResults.AverageParturitionWeightRange[1]
                : null,
        avgSeparatedPigletWeightMin:
            !utilResults.AverageSeparationWeightRange.includes("auto")
                ? utilResults.AverageSeparationWeightRange[0]
                : 0,
        avgSeparatedPigletWeightMax:
            !utilResults.AverageSeparationWeightRange.includes("auto")
                ? utilResults.AverageSeparationWeightRange[1]
                : null,
        avgIdleDaysMin: !utilResults.AverageIdleDaysRange.includes("auto")
            ? utilResults.AverageIdleDaysRange[0]
            : null,
        avgIdleDaysMax: !utilResults.AverageIdleDaysRange.includes("auto")
            ? utilResults.AverageIdleDaysRange[1]
            : null,
        pigletMortalityMin: utilResults.PigletsMortalityRange[0],
        pigletMortalityMax: utilResults.PigletsMortalityRange[1],
        lactationMinDaysDeviation:
            !utilResults.AverageLactationDaysRange.includes("auto")
                ? utilResults.AverageLactationDaysRange[0]
                : null,
        lactationMaxDaysDeviation:
            !utilResults.AverageLactationDaysRange.includes("auto")
                ? utilResults.AverageLactationDaysRange[1]
                : null,
        // procenty
        parturitionIndexPercent: null,
        healthyCntPercent: null,
        separatedCntPercent: null,
        parturitionWeightPercent: null,
        separationWeightPercent: null,
        idleDaysDeviation: null,
        lactationDaysDeviation: null,
        generalResultPercent: null,
        pigletsMortalityPercent: null,
    };
    const sowResults = data.find((x) => x.AnmID === animal.AnmID);
    for (const item of data) {
        const lactationDaysDeviation = !isNil(item?.AverageLactationDays)
            ? item?.AverageLactationDays - timeOnBirthRoom
            : 0;
        if (
            !isNil(item?.ParturitionIndex) &&
            utilResults.ParturitionIndexRange.includes("auto") &&
            (result.parturitionIndexMax === null ||
                item?.ParturitionIndex > result.parturitionIndexMax)
        ) {
            result.parturitionIndexMax = item?.ParturitionIndex;
        }
        if (
            !isNil(item?.AverageHealthyPiglets) &&
            utilResults.HealthyPigletsPerLitterRange.includes("auto") &&
            (result.avgHealthyCntMax === null ||
                item?.AverageHealthyPiglets > result.avgHealthyCntMax)
        ) {
            result.avgHealthyCntMax = item?.AverageHealthyPiglets;
        }
        if (
            !isNil(item?.AverageSeparatedPiglets) &&
            utilResults.SeparatedPigletsPerLitterRange.includes("auto") &&
            (result.avgSeparatedCntMax === null ||
                item?.AverageSeparatedPiglets > result.avgSeparatedCntMax)
        ) {
            result.avgSeparatedCntMax = item?.AverageSeparatedPiglets;
        }
        if (
            !isNil(item?.AverageParturitionedPigletWeight) &&
            utilResults.AverageParturitionWeightRange.includes("auto") &&
            (result.avgParturitionedPigletWeightMax === null ||
                item?.AverageParturitionedPigletWeight >
                    result.avgParturitionedPigletWeightMax)
        ) {
            result.avgParturitionedPigletWeightMax =
                item?.AverageParturitionedPigletWeight;
        }
        if (
            !isNil(item?.AverageSeparatedPigletWeight) &&
            utilResults.AverageSeparationWeightRange.includes("auto") &&
            (result.avgSeparatedPigletWeightMax === null ||
                item?.AverageSeparatedPigletWeight >
                    result.avgSeparatedPigletWeightMax)
        ) {
            result.avgSeparatedPigletWeightMax =
                item?.AverageSeparatedPigletWeight;
        }
        if (
            !isNil(item?.AverageIdleDays) &&
            utilResults.AverageIdleDaysRange.includes("auto") &&
            (result.avgIdleDaysMin === null ||
                item?.AverageIdleDays < result.avgIdleDaysMin)
        ) {
            result.avgIdleDaysMin = item?.AverageIdleDays;
        }
        if (
            !isNil(item?.AverageIdleDays) &&
            utilResults.AverageIdleDaysRange.includes("auto") &&
            (result.avgIdleDaysMax === null ||
                item?.AverageIdleDays > result.avgIdleDaysMax)
        ) {
            result.avgIdleDaysMax = item?.AverageIdleDays;
        }
        if (
            !isNil(item?.AverageLactationDays) &&
            utilResults.AverageLactationDaysRange.includes("auto") &&
            (result.lactationMaxDaysDeviation === null ||
                lactationDaysDeviation > result.lactationMaxDaysDeviation)
        ) {
            result.lactationMaxDaysDeviation = lactationDaysDeviation;
        }
        if (
            !isNil(item?.AverageLactationDays) &&
            utilResults.AverageLactationDaysRange.includes("auto") &&
            (result.lactationMinDaysDeviation === null ||
                lactationDaysDeviation < result.lactationMinDaysDeviation)
        ) {
            result.lactationMinDaysDeviation = lactationDaysDeviation;
        }
    }
    const idleDaysDeviation = isNumber(sowResults?.AverageIdleDays)
        ? getPercent(
              sowResults?.AverageIdleDays,
              result.avgIdleDaysMin,
              result.avgIdleDaysMax,
              true
          )
        : null;
    const lactationDaysDeviation = getLactationDaysDeviation(
        result.lactationMinDaysDeviation,
        result.lactationMaxDaysDeviation,
        sowResults?.AverageLactationDays,
        utilResults
    );
    result.parturitionIndexPercent = isNumber(sowResults?.ParturitionIndex)
        ? getPercent(
              sowResults?.ParturitionIndex,
              result.parturitionIndexMin,
              result.parturitionIndexMax
          )
        : null;
    result.healthyCntPercent = isNumber(sowResults?.AverageHealthyPiglets)
        ? getPercent(
              sowResults?.AverageHealthyPiglets,
              result.avgHealthyCntMin,
              result.avgHealthyCntMax
          )
        : null;
    result.separatedCntPercent = isNumber(sowResults?.AverageSeparatedPiglets)
        ? getPercent(
              sowResults?.AverageSeparatedPiglets,
              result.avgSeparatedCntMin,
              result.avgSeparatedCntMax
          )
        : null;
    result.pigletsMortalityPercent = isNumber(sowResults?.PigletsMortality)
        ? getPercent(
              sowResults?.PigletsMortality,
              result.pigletMortalityMin,
              result.pigletMortalityMax,
              true
          )
        : null;
    result.parturitionWeightPercent = isNumber(
        sowResults?.AverageParturitionedPigletWeight
    )
        ? getPercent(
              sowResults?.AverageParturitionedPigletWeight,
              result.avgParturitionedPigletWeightMin,
              result.avgParturitionedPigletWeightMax
          )
        : null;
    result.separationWeightPercent = isNumber(
        sowResults?.AverageSeparatedPigletWeight
    )
        ? getPercent(
              sowResults?.AverageSeparatedPigletWeight,
              result.avgSeparatedPigletWeightMin,
              result.avgSeparatedPigletWeightMax
          )
        : null;
    result.idleDaysDeviation = idleDaysDeviation;
    result.lactationDaysDeviation = lactationDaysDeviation;
    const percents = [
        result.parturitionIndexPercent,
        result.healthyCntPercent,
        result.separatedCntPercent,
        result.parturitionWeightPercent,
        result.separationWeightPercent,
        result.idleDaysDeviation,
        getLactationDaysDeviationPercent(result.lactationDaysDeviation),
        result.pigletsMortalityPercent,
    ].filter((p) => !isNil(p) && !isNaN(p));
    result.generalResultPercent = percents.length
        ? +parseFloat(
              `${percents.reduce((p1, p2) => p1 + p2, 0) / percents.length}`
          ).toFixed(2)
        : null;
    return {sowResults, result};
}
