import React from "react";
import {Modal} from "react-bootstrap";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connectModal} from "redux-modal";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import {Field, getFormValues, reduxForm} from "redux-form";
import {submit} from "./GridColumnManageModalSubmit";
import ModalBody from "../ModalBody";
import ReduxCheckbox from "../../basics/checkbox/ReduxCheckbox";
import {connect} from "react-redux";
import InfoBox from "../../basics/info-box/InfoBox";

export const ModalName = "grid-column-manage-modal";

function mapStateToProps(state) {
    return {
        values: getFormValues(ModalName)(state)
    }
}

class GridColumnManageModal extends React.Component {

    constructor(props) {
        super(props);
        const {initialize, headers, headersToDisplay} = this.props;
        const initialValue = {};
        headers.forEach((item) => {
            const toDisplayItem = headersToDisplay.find((h) => h.field === item.field);
            initialValue[item.field] = !!toDisplayItem;
        });
        initialize({
            ...initialValue
        });
    }

    isError = () => {
        const {maxColumns, values} = this.props;
        if (values) {
            const positiveValues = Object.values(values).filter((v) => v);
            if (maxColumns < positiveValues.length) {
                return {error: true, max: true};
            } else if (positiveValues.length === 0) {
                return {error: true, max: false}
            }
        }
        return {error: false, max: false};

    };

    render() {
        const {t, show, handleHide, handleSubmit, submitting, headers, maxColumns} = this.props;
        const {error, max} = this.isError();
        return (
            <Modal show={show} size={"lg"} onHide={handleHide}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={t("gridColumnManageModal.showHideColumns")} onCloseClick={handleHide}/>
                    <ModalBody>
                        {
                            error &&
                            <InfoBox boxColor={"error"}>
                                {max ? t("errors.maxColumns", {maxColumns}) : t("errors.minColumns")}
                            </InfoBox>
                        }
                        {
                            headers.map((item) =>
                                <>
                                    <Field
                                        name={item.field}
                                        label={item.name}
                                        component={ReduxCheckbox}
                                        id={item.field}
                                    />
                                    <br/>
                                </>
                            )
                        }
                    </ModalBody>
                    <ModalFooter confirmText={t("save")} onCloseClick={handleHide} formName={ModalName}
                                 hasConfirmButton submitting={submitting} hasButtonDisabled={error}/>
                </form>
            </Modal>
        );
    }

}

export default compose(
    withTranslation(),
    connectModal({name: ModalName}),
    reduxForm({
        form: ModalName,
        onSubmit: submit
    }),
    connect(mapStateToProps)
)(GridColumnManageModal);
