import {useTranslation} from "react-i18next";
import React, {useMemo} from "react";
import {localDateTimeFormatter} from "../../../utils/DateTimeUtils";
import TableGrid from "../../basics/table-grid/TableGrid";
import ChangeList from "./ChangeList";

export default ({data, isGroup}) => {
    const {t} = useTranslation();

    const headers = useMemo(() => {
        return [
            {
                name: t("date"),
                field: "timestamp",
                valueFormatter: localDateTimeFormatter,
                itemClassName: "text-start"
            },
            isGroup
                ?
                {
                    name: t("newReports.forageForAnimal.animal"),
                    field: "subgroupName",
                    colWidth: 2,
                    itemClassName: "text-start"
                }
                :
                null,
            {
                name: t("eventGrid.operator"),
                field: "invokedBy.name",
                colWidth: 2,
                itemClassName: "text-start"
            },
            {
                name: t("controlLists.mainView.description"),
                field: "title",
                colWidth: 5,
                itemClassName: "text-start"
            },
            {
                name: t("logView.changes"),
                field: "changes",
                component: ChangeList,
                colWidth: 3,
                itemClassName: "text-start"
            },
            {
                name: t("comment"),
                field: "comment",
                colWidth: 5,
                itemClassName: "text-end"
            }
        ].filter((o) => o);
    }, [t, isGroup])

    return (
        <TableGrid
            className={"allow-multiline"}
            data={data}
            showPagination={true}
            paginationItems={25}
            headers={headers}
        />
    )

}