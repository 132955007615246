import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {getMedicines} from "../../../selectors/dictionarySelectors";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        medicines: getMedicines(state)
    };
}

class EditTreatmentModalSelectMedicines extends Component {

    isSelected(medicine) {
        const {input: {value}} = this.props;
        return value.includes(medicine.WordID)
    }

    onMedicineClick(medicine) {
        let value = this.props.input.value.slice();
        if (value.includes(medicine.WordID)) {
            value = value.filter(item => item !== medicine.WordID);
        } else {
            value.push(medicine.WordID);
        }
        this.props.input.onChange(value);
        this.props.input.onBlur();
    }

    render() {
        const {medicines, meta: {touched, error}} = this.props;
        return (
            <div className="position-relative">
                {
                    medicines.map((medicine, index) =>
                        <div
                            className={this.isSelected(medicine) ? "medicine-item selected" : "medicine-item"}
                            key={index} onClick={() => this.onMedicineClick(medicine)}>
                            <div className="medicine-text">{medicine.WData.Name}</div>
                            <div style={{background: medicine.WData.Color || "#fff"}} className="color"/>
                        </div>
                    )
                }
                {touched && error && <div className="error-item">{error}</div>}
            </div>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(EditTreatmentModalSelectMedicines);
