import {getFile} from "./getFile";

export async function downloadFile(file) {
    const {FileID, FileName, FarmID} = file;
    let url = await getFile(FileID, {FarmID});
    let link = document.createElement("a");
    link.setAttribute("download", FileName);
    link.setAttribute("href", url);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
