import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from "../../basics/card/Card";
import {isString} from "lodash";
import ShowMoreLinkComponent from "../../basics/show-more-link/ShowMoreLinkComponent";
import {Collapse} from "react-bootstrap";

class CollapsableContainer extends Component {

    internals = {
        touched: false
    }

    state = {
        opened: this.props.defaultExpanded || false
    }

    onExpandToggle = () => {
        this.internals.touched = true;
        this.setState((state) => ({
            opened: !state.opened
        }))
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.internals.touched === false) {
            const {defaultExpanded} = this.props;
            if (!!defaultExpanded !== !!prevProps.defaultExpanded) {
                this.setState(({
                    opened: !!defaultExpanded
                }));
            }
        }
    }

    render() {
        const {props: {containerElement, children, header, id, className, forwardRef, passProps, mountOnEnter = false}, state: {opened}} = this;
        return React.createElement(containerElement, {
            ...passProps,
            id,
            className,
            children: (
                <>
                    <div ref={forwardRef} className="d-flex justify-content-between pointer" onClick={this.onExpandToggle}>
                        <h5>{header}&nbsp;</h5>
                        <ShowMoreLinkComponent opened={opened}/>
                    </div>
                    <Collapse mountOnEnter={mountOnEnter} in={opened}>
                        <div>
                            <div className={"mt-2"}/>
                            {children}
                        </div>
                    </Collapse>
                </>
            )
        })
    }
}

CollapsableContainer.propTypes = {
    defaultExpanded: PropTypes.bool,
    header: PropTypes.string.isRequired
};

const mergeClassName = (props, className = "") => {
    const cName = [props.className, className].filter(isString);
    return cName.length ? cName.join(" ") : undefined;
}

export default {
    Card: (props) => <CollapsableContainer {...props} containerElement={Card}/>,
    Div: (props) => <CollapsableContainer {...props} containerElement={"div"}/>,
    Fieldset: (props) => <CollapsableContainer {...props} className={mergeClassName(props, "fieldset mb-2")}
                                               containerElement={"fieldset"}/>
};