import { getModificationTime, insertInto, setModificationTime } from "../utils/LokiUtils";
import lokiDB from "./lokiDB";

class Dictionaries {

    /**********************************
     ***         FARMS              ***
     **********************************/

    /**
     * Funkcja do pobierania ostatniej daty modyfikacji farmy na danej fermie
     * @return {{DtaModTime: string}} 0 jeśli nie było wcześniej informacji o ostatniej dacie modyfikacji dla danego farmdID
     */
    getModificationTime(ClientID) {
        return getModificationTime("dictionaryV2", "ClientID", ClientID);
    }

    //insert into dictionary
    insertIntoDictionaries(values) {
        insertInto(values, lokiDB.dictionary, "WordID", "ClientID");
        setModificationTime("dictionaryV2", values[values.length - 1].DtaModTime, "ClientID", values[values.length - 1].ClientID);
    }

    getAllDictionaries() {
        try {
            return lokiDB.dictionary.find({
                DtaDelTime: { $type: 'undefined' }
            });
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    // updateDictionary(d) {
    //     return new Promise((resolve, reject) => {
    //         let res = lokiDB.dictionary.update(d);
    //         if (res !== d) reject("Błąd zamiany");
    //         db.saveDatabase(function (err) {
    //             if (err)
    //                 console.log("error obtain: ", err);
    //             //console.log("save database successfull")
    //         });
    //         resolve(res);
    //     })
    // }

    findDictionary(type) {
        return lokiDB.dictionary.find({
            Type: type, ClientID: { $ne: "Default" },
            DtaDelTime: { $type: 'undefined' }
        });
    }

    findRemovedDictionary(id) {
        let obj = lokiDB.dictionary.findOne({
            WordID: id
        });
        if (obj) return obj;
        return null;
    }

}

const dictionariesDB = new Dictionaries();
export default dictionariesDB;
