import { findIndex } from "lodash";
import { DICTIONARY_TYPE } from "../constans/general";

export const initialValue = {
    race: {
        Type: DICTIONARY_TYPE.race,
        WData: [],
    },
    noPreganancy: {
        Type: DICTIONARY_TYPE.noPreganancy,
        WData: [],
    },
    graftingReason: {
        Type: DICTIONARY_TYPE.graftingReason,
        WData: [],
    },
    clients: {
        Type: DICTIONARY_TYPE.clients,
        WData: [],
    },
    fallReasons: {
        Type: DICTIONARY_TYPE.fallReasons,
        WData: [],
    },
    selectionReason: {
        Type: DICTIONARY_TYPE.selectionReason,
        WData: [],
    },
    weighting: {
        Type: DICTIONARY_TYPE.weighting,
        WData: [],
    },
    taskCategory: {
        Type: DICTIONARY_TYPE.taskCategory,
        WData: [],
    },
    files: {
        Type: DICTIONARY_TYPE.files,
        WData: [],
    },
    comments: {
        Type: DICTIONARY_TYPE.comments,
        WData: [],
    },
    crossBreeding: {
        Type: DICTIONARY_TYPE.crossBreeding,
        WData: [],
    },
    sellers: {
        Type: DICTIONARY_TYPE.sellers,
        WData: [],
    },
    fetching: false,
    controlLists: [],
    medicine: [],
    graftingPrograms: [],
    forageIngredients: [],
    automatedActions: [],
};

function splitDictionary(array) {
    let obj = {};
    let clients = array.find((item) => item.Type === DICTIONARY_TYPE.clients);
    if (clients) obj.clients = clients;

    let sellers = array.find((item) => item.Type === DICTIONARY_TYPE.sellers);
    if (sellers) obj.sellers = sellers;

    let fallReasons = array.find(
        (item) => item.Type === DICTIONARY_TYPE.fallReasons
    );
    if (fallReasons) obj.fallReasons = fallReasons;

    let noPregnancy = array.find(
        (item) => item.Type === DICTIONARY_TYPE.noPreganancy
    );
    if (noPregnancy) obj.noPreganancy = noPregnancy;

    let graftingReasons = array.find(
        (item) => item.Type === DICTIONARY_TYPE.graftingReason
    );
    if (graftingReasons) obj.graftingReason = graftingReasons;

    let race = array.find((item) => item.Type === DICTIONARY_TYPE.race);
    if (race) obj.race = race;

    let selection = array.find(
        (item) => item.Type === DICTIONARY_TYPE.selectionReason
    );
    if (selection) obj.selectionReason = selection;

    let weighting = array.find(
        (item) => item.Type === DICTIONARY_TYPE.weighting
    );
    if (weighting) obj.weighting = weighting;

    let taskCategory = array.find(
        (item) => item.Type === DICTIONARY_TYPE.taskCategory
    );
    if (taskCategory) obj.taskCategory = taskCategory;

    let files = array.find((item) => item.Type === DICTIONARY_TYPE.files);
    if (files) obj.files = files;

    let comments = array.find((item) => item.Type === DICTIONARY_TYPE.comments);
    if (comments) obj.comments = comments;

    let crossBreeding = array.find(
        (item) => item.Type === DICTIONARY_TYPE.crossBreeding
    );
    if (crossBreeding) obj.crossBreeding = crossBreeding;

    obj.controlList = array.filter(
        (item) => item.Type === DICTIONARY_TYPE.controlLists
    );
    obj.medicines = array.filter(
        (item) => item.Type === DICTIONARY_TYPE.medicine
    );
    obj.graftingPrograms = array.filter(
        (item) => item.Type === DICTIONARY_TYPE.graftingProgram
    );
    obj.forageIngredients = array.filter(
        (item) => item.Type === DICTIONARY_TYPE.forageIngredient
    );
    obj.automatedActions = array.filter(
        (item) => item.Type === DICTIONARY_TYPE.automatedAction
    );
    return obj;
}

function mergeArray(before, newArray) {
    let copy = before.slice();
    for (let row of newArray) {
        let index = findIndex(before, (o) => o.WordID === row.WordID);
        if (index === -1) {
            copy.push(row);
        } else {
            if (row.DtaDelTime) {
                copy.splice(index, 1);
            } else {
                copy[index] = row;
            }
        }
    }
    return copy;
}

export default function dictionaryReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_ALL_DICTIONARIES": {
            let obj = splitDictionary(action.payload);
            let controlList = obj.controlList;
            let medicines = obj.medicines;
            let graftingPrograms = obj.graftingPrograms;
            let forageIngredients = obj.forageIngredients;
            let automatedActions = obj.automatedActions;
            delete obj.controlList;
            delete obj.medicines;
            delete obj.graftingPrograms;
            delete obj.forageIngredients;
            delete obj.automatedActions;
            return {
                ...state,
                ...obj,
                medicine: medicines,
                controlLists: controlList,
                graftingPrograms: graftingPrograms,
                forageIngredients: forageIngredients,
                automatedActions,
            };
        }
        case "LIST_DICTIONARY_PENDING":
        case "LIST_DEFAULT_DICTIONARY_PENDING":
        case "CREATE_DEVICE_PENDING":
        case "UPDATE_DICTIONARY_PENDING":
            return { ...state, fetching: true };
        case "LIST_DICTIONARY_FULFILLED": {
            // akcja wywolywana w momencie pobierania nowych danych po przyjsciu trigerra mqtt
            // zrobione, aby listy kontrolne nie bagowaly sie po szybkim dodawaniu do ulubionych
            let obj = splitDictionary(action.payload);
            let controlList = mergeArray(
                state.controlLists,
                obj.controlList
            );
            let medicines = mergeArray(
                state.medicine,
                obj.medicines
            );
            let graftingPrograms = mergeArray(
                state.graftingPrograms,
                obj.graftingPrograms
            );
            let forageIngredients = mergeArray(
                state.forageIngredients,
                obj.forageIngredients
            );
            let automatedActions = mergeArray(
                state.automatedActions,
                obj.automatedActions
            );
            delete obj.controlList;
            delete obj.medicines;
            delete obj.graftingPrograms;
            delete obj.forageIngredients;
            return {
                ...state,
                ...obj,
                fetching: false,
                controlLists: controlList,
                medicine: medicines,
                graftingPrograms,
                forageIngredients,
                automatedActions,
            };
        }
        case "LIST_DICTIONARY_REJECTED":
        case "LIST_DEFAULT_DICTIONARY_FULFILLED":
        case "LIST_DEFAULT_DICTIONARY_REJECTED":
        case "CREATE_DEVICE_REJECTED":
        case "CREATE_DEVICE_FULFILLED":
        case "UPDATE_DICTIONARY_REJECTED":
            return { ...state, fetching: false };
        case "UPDATE_CONTROL_LIST":
            let controlLists = state.controlLists.slice(0);
            let index = findIndex(
                controlLists,
                (o) => o.WordID === action.payload.WordID
            );
            controlLists[index] = action.payload;
            return { ...state, controlLists };
        case "STAR_CONTROL_LIST": {
            const copy = state.controlLists.slice(0);
            const index = findIndex(copy, o => o.WordID === action.meta.WordID);
            if (!Array.isArray(copy[index].WData.Favorite)) copy[index].WData.Favorite = [];
            if (copy[index].WData.Favorite.includes(action.payload.LocalUserID)) {
                copy[index].WData.Favorite = copy[index].WData.Favorite.filter(item => item !== action.payload.LocalUserID);
            } else {
                copy[index].WData.Favorite.push(action.payload.LocalUserID);
            }
            copy.DtaModTime = +new Date();
            return { ...state, controlLists: copy };
        }
        case "CLEAR_DICTIONARY":
        case "USER_LOGOUT_FULFILLED":
            return initialValue;
        default:
            return state;
    }
}
