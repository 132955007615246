export const resultsTypes = {
    /////////////////////////////////////////[REPRODUKCJA]///////////////////////////////////////////////////////////////////////
    INSEMINATED_RENOVATION_SOWS: "inseminatedRenovationSows",
    INSEMINATED_SOWS: "inseminatedSows",
    INSEMINATED_ALL_SOWS: "inseminatedAllSows",
    ALL_USGS: "allUsgs",
    PARTURITION_SOWS_UNTIL_5_WEEK: "parturitionSowsUntilFiveWeek",
    PARTURITION_SOWS_UNTIL_8_WEEK: "parturitionSowsUntilEightWeek",
    REPETITIONS_FOR_RENOVATION_SOWS: "repetitionsForRenovationSows",
    REPETITIONS_FOR_SOWS: "repetitionsForSows",
    NUMBER_OF_REPETITIONS: "numberOfRepetitions",
    AVG_BRAKE_FROM_INSEMINATION_TO_REPETITION: "avgBrakeFromInsToRep",
    SEP_SOWS_PREGNANT_IN_1_CYCLE: "sepSowsThatHaveBeenPregnantWithinSevenDaysInFirstCycle",
    SEP_SOWS_PREGNANT_IN_2_CYCLE: "sepSowsThatHaveBeenPregnantWithinSevenDaysInSecondCycle",
    SEP_SOWS_PREGNANT_IN_3_CYCLE: "sepSowsThatHaveBeenPregnantWithinSevenDaysInThirdCycle",
    SEP_SOWS_PREGNANT_ABOVE_7_DAYS_IN_1_CYCLE: "sepSowsThatHaveBeenPregnantAboveSevenDaysInFirstCycle",
    SEP_SOWS_PREGNANT_ABOVE_7_DAYS_IN_2_CYCLE: "sepSowsThatHaveBeenPregnantAboveSevenDaysInSecondCycle",
    SEP_SOWS_PREGNANT_ABOVE_7_DAYS_IN_3_CYCLE: "sepSowsThatHaveBeenPregnantAboveSevenDaysInThirdCycle",
    PERCENTAGE_OF_SEP_SOWS_PREGNANT_IN_1_CYCLE: "percentageOfSepSowsThatHaveBeenPregnantWithinSevenDaysInFirstCycle",
    PERCENTAGE_OF_SEP_SOWS_PREGNANT_IN_2_CYCLE: "percentageOfSepSowsThatHaveBeenPregnantWithinSevenDaysInSecondCycle",
    PERCENTAGE_OF_SEP_SOWS_PREGNANT_IN_3_CYCLE: "percentageOfSepSowsThatHaveBeenPregnantWithinSevenDaysInThirdCycle",
    PERCENTAGE_OF_SEP_SOWS_PREGNANT_ABOVE_7_DAYS_IN_1_CYCLE: "percentageOfSepSowsThatHaveBeenPregnantAboveSevenDaysInFirstCycle",
    PERCENTAGE_OF_SEP_SOWS_PREGNANT_ABOVE_7_DAYS_IN_2_CYCLE: "percentageOfSepSowsThatHaveBeenPregnantAboveSevenDaysInSecondCycle",
    PERCENTAGE_OF_SEP_SOWS_PREGNANT_ABOVE_7_DAYS_IN_3_CYCLE: "percentageOfSepSowsThatHaveBeenPregnantAboveSevenDaysInThirdCycle",
    AVG_BRAKE_FROM_SEP_TO_INS: "avgBrakeFromSepToIns",
    RETENTION: "retention",
    // AVG_LACTATION_DAYS: "averageLactationDays",
    /////////////////////////////////////////[PORODY]///////////////////////////////////////////////////////////////////////
    // GENERAL_RESULT: "generalResult",
    // PARTURITION_INDEX: "parturitionIndex",
    EXPECTED_PARTURITIONS: "expectedParturitions",
    PARTURITIONED_SOWS: "parturitionedSows",
    PARTURITION_EFFECTIVENESS: "parturitionEffectiveness",
    PERCENTAGE_OF_USED_STANDINGS: "percentageOfUsedStandings",
    INSEMINATION_EFFECTIVNES: "inseminationEffectiveness",
    ALL_BORN_PIGLETS: "allBornPiglets",
    ALL_BORN_PIGLETS_PER_SOW: "allBornPigletsPerSow",
    BORN_HEALTHY_PIGLETS: "bornHealthyPiglets",
    BORN_HEALTHY_PIGLETS_PER_SOW: "bornHealthyPigletsPerSow",
    BORN_WEAK_PIGLETS: "bornWeakPiglets",
    BORN_WEAK_PIGLETS_PER_SOW: "bornWeakPigletsPerSow",
    PERCENTAGE_OF_WEAK_PIGLETS_RELATIVE_TO_HEALTHY: "percentageOfWeakPigletsBornRelativeToHealthy",
    BORN_MUMMY_PIGLETS: "bornMummyPiglets",
    BORN_MUMMY_PIGLETS_PER_SOW: "bornMummyPigletsPerSow",
    BORN_DEAD_PIGLETS: "bornDeadPiglets",
    BORN_DEAD_PIGLETS_PER_SOW: "bornDeadPigletsPerSow",
    AVG_WEIGHT_OF_PIGLET: "averageWeightOfPiglet",
    SEPARATED_SOWS: "separatedSows",
    AMOUNT_OF_SEP_PIGLETS: "amountOfSeparatedPiglets",
    AMOUNT_OF_SEP_PIGLETS_TO_BABYROOM:"amountOfSeparatedPigletsToBabyroom",
    AMOUNT_OF_SEP_PIGLETS_PER_SOW: "amountOfSeparatedPigletsPerSow",
    WEIGHT_OF_PITCHER_WEIGHT: "weightOfPitcherWeight",
    LITTER_WEIGHT: "litterWeight",
    AVG_WEIGHT_OF_SEP_PIGLET: "averageWeightOfSeparatedPiglet",
    PIGLETS_MORTALITY: "pigletsMortality", // smiertlenosc prosiat
    /////////////////////////////////////////[INWENTARZ]///////////////////////////////////////////////////////////////////////
    INSERTED_RENOVATION_SOWS: "insertedRenovationSows",
    RENOVATION_SOWS_AMOUNT: "renovationSowsAmount",
    RENOVATION_SOWS_UNTIL_26_WEEK: "renovationSowsUntil26Week",
    RENOVATION_SOWS_ABOVE_26_WEEK: "renovationSowsAbove26Week",
    NUMBER_OF_RENOVATION_SOW_SOLD: "numberOfRenovationSowsSold",
    NUMBER_OF_FALLEN_RENOVATION_SOWS: "numberOfFallenRenovationSows",
    INSERTED_SOWS: "insertedSows",
    AMOUNT_SOWS: "amountSows",
    PARTURITIONED_SOWS_AMOUNT: "parturitionedSowsAmount",
    NUMBER_OF_SOWS_SOLD: "numberOfSowsSold",
    NUMBER_OF_FALLEN_SOWS: "numberOfFallenSows",
    PIGLETS_AMOUNT: "pigletsAmount",
    NUMBER_OF_FALLEN_PIGS: "numberOfFallenPigs", // liczba padlych prosiat
    NUMBER_OF_PIGLETS: "numberOfPiglets",
    NUMBER_OF_FALLEN_PIGLETS: "numberOfFallenPiglets", // liczba upadlych warchlakow
    RECLASSIFIED_PIGLETS: "reclassifiedPiglets",
    WEANER_MORTALITY: "weanerMortality", // smiertlenosc warchlaka
    NUMBER_OF_PIGLETS_SOLD: "numberOfPigletsSold",
    NUMBER_OF_BOARS: "numberOfBoars",
    NUMBER_OF_FALLEN_BOARS: "numberOfFallenBoars",
    NUMBER_OF_BOARS_SOLD: "numberOfBoarsSold",
    NUMBER_OF_PORKERS: "numberOfPorkers",
    NUMBER_OF_FALLEN_PORKERS: "numberOfFallenPorkers",
    PORKER_MORTALITY: "porkerMortality", // smiertlenosc tucznika
    NUMBER_OF_PORKERS_SOLD: "numberOfPorkersSold",
    PARTURITION_DIFF_DAYS: "parturitionDiffDays",
    AVG_ALL_HEALTHY_BORN_PIGLETS_WEIGHT: "averageBornPigletsWeight", // średnia waga wyproszeniowa
    INSERTED_WEANERS: "insertedWeaners",
    INSERTED_PORKERS: "insertedPorkers",
    /////////////////////////////////////////[GRUPY]/////////////////////////////////////////
    FATTENING_PERIOD: "fatteningPeriod",
    AVERAGE_GAIN: "averageGain",
    AVERAGE_INSERTION_WEIGHT: "averageInsertionWeight",
    AVERAGE_SELL_WEIGHT: "averageSellWeight",
    SELL_WEIGHT: "sellWeight",
    MEDICINE_COST: "medicineCost",
    FIXED_COST: "fixedCost",
    BUY_COST: "buyCost",
    AVERAGE_PIGLET_INSERTION_WEIGHT: "averagePigletInsertionWeight",
    PIGLET_REARING_PERIOD: "pigletRearingPeriod",
    AVERAGE_PIGLET_SELL_WEIGHT: "averagePigletSellWeight",
};

export const ResultCategory = {
    REPRODUCTION: "REP",
    PARTURITION: "PAR",
    SEPARATION: "SEP",
    INVENTORY: "INV",
    ADDITIONAL: "ADD"
};

export const SowResultsTypes = {
    GENERAL: "general",
    PARTURITION_INDEX: "parturitionIndex",
    HEALTHY_PIGLETS_PER_LITTER: "healthyPigletsPerLitter",
    SEPARATED_PIGLETS_PER_LITTER: "separatedPigletsPerLitter",
    AVG_PARTURITION_WEIGHT: "avgParturitionWeight",
    AVG_SEPARATION_WEIGHT: "avgSeparationWeight",
    AVG_IDLE_DAYS: "avgIdleDays",
    AVG_LACTATION_DAYS: "avgLactationDays",
    PIGLETS_MORTALITY: "pigletsMortality",
    WEANER_MORTALITY: "weanersMortality",
    PORKERS_MORTALITY: "porkerMortality",
    AVG_GROWTH_PIGLETS: "averageGrowthOfPiglets",
    AVG_GROWTH_PORKERS: "averageGrowthOfPorkers",
};
