import NewIOT from "../IOT/NewIOT";
import {getDebugGateway} from "../selectors/deviceDebugSelectors";

export function submitManagePCCommand(command) {
    return function (dispatch, getState) {
        const state = getState();
        let gateway = getDebugGateway(state);
        dispatch({
            type: "SUBMIT_MANAGE_PC_COMMAND",
            payload: command,
            meta: {
                GwID: gateway?.DevID,
                saveHistory: true
            }
        })
        if (command === "clear") {
            dispatch({
                type: "CLEAR_MANAGE_PC_TERMINAL",
                meta: {
                    GwID: gateway?.DevID
                }
            })
        } else {
            const {user: {user: {ClientID, LocalUserID}}} = state;
            NewIOT.sendRPICommand(gateway, command, ClientID, LocalUserID);
        }
    }
}

export function submitManagePCPredefinedCommand(command, data) {
    return function (dispatch, getState) {
        const state = getState();
        let gateway = getDebugGateway(state);
        dispatch({
            type: "SUBMIT_MANAGE_PC_COMMAND",
            payload: command,
            meta: {
                GwID: gateway?.DevID,
                saveHistory: false
            }
        })
        const {user: {user: {ClientID, LocalUserID}}} = state;
        NewIOT.sendRPIPredefinedCommand(gateway, command, data, ClientID, LocalUserID);
    }
}

export function onRPIResponse(data, GwID) {
    return function (dispatch) {
        dispatch({
            type: "RPI_RESPONSE_ARRIVE",
            payload: data,
            meta: {
                GwID
            }
        })
    }
}

export function onRPIGetCommands(data, GwID) {
    return function (dispatch) {
        dispatch({
            type: "RPI_GET_COMMANDS",
            payload: data,
            meta: {
                GwID
            }
        })
    }
}
