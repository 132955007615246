import {get, maxBy} from "lodash";

const initialValue = {
    groups: [],
    groupTime: 0
};

export default function groupReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_ALL_GROUPS":
            const newObject = action.payload.slice(0);
            const newGroupTime = get(maxBy(newObject, o => o.DtaModTime), "DtaModTime", state.groupTime + 1)
            return {...state, groupTime: newGroupTime, groups: newObject};
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
