import React, {Component} from 'react';
import TOPIGSLogo from "../../../resources/images/topigs/topigs_norsvin_horizontal.png";
import "./_topigs-fieldset.scss"

class TopigsFieldSet extends Component {
    render() {
        const {children} = this.props;
        return (
            <fieldset className="fieldset topigs-fieldset">
                {/*Mozliwe ze trzeba bedzie wywalic logo, Daniel ma pogadac z przedstawicielami*/}
                <legend>
                    <img src={TOPIGSLogo} alt={"TOPIGS Norsvin"}/>
                </legend>
                {children}
            </fieldset>
        );
    }
}

export default TopigsFieldSet;