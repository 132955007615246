import React from "react";
import PropTypes from "prop-types";
import CycleRenderer from "./CycleRenderer";
import {get} from "lodash";
import {getEventDate, getExpectedDate} from "./utils";
import {getExpectedDatesForAnimalCycle} from "../../../selectors/animalDocumentsSelectors";
import {compose} from "redux";
import {connect} from "react-redux";
import EventTypes from "@wesstron/utils/Api/constants/eventTypes";
import {withTranslation} from "react-i18next";
import {getShowGiltsOnParturition} from "../../../selectors/settingsSelector";

function mapStateToProps(state) {
    return {
        expectedDates: getExpectedDatesForAnimalCycle(state),
        showGilts: getShowGiltsOnParturition(state)
    }
}

class ParturitionRenderer extends React.Component {

    state = {
        hoveredID: ""
    };

    onMouseEnter = (event) => {
        const cell = get(event, "currentTarget");
        if (cell) {
            const {cycle: {parturitions}} = this.props;
            let EvID = cell.id.split(" ")[0];
            // moze byc tak ze najedzie na kastracje wiec tak naprawde chce usunac porod
            if (EvID !== parturitions[0]?.event?.EvID) {
                EvID = parturitions[0]?.event?.EvID;
            }
            this.setState({
                hoveredID: EvID
            })
        }
    };

    onMouseLeave = () => {
        this.setState({
            hoveredID: ""
        })
    };

    render() {
        const {
            cycle: {parturitions},
            isAdvanced,
            tooltipContent,
            cycleRenderClass,
            onDoubleEventClick,
            deletionMode,
            onDeleteClick,
            rowIndex,
            expectedDates,
            t,
            isInterrupted,
            showGilts
        } = this.props;
        const {hoveredID} = this.state;
        let parturition = parturitions[0];
        if (!parturition) {
            const expectedDate = getExpectedDate(isInterrupted, rowIndex, expectedDates, EventTypes.PARTURITION);
            return (
                <>
                    {
                        isAdvanced &&
                        <CycleRenderer className={expectedDate ? "expected-date" : ""} isInterrupted={isInterrupted}>
                            <div className={`cell-wrapper`}
                                 title={t("animalDocuments.plannedDate", {date: expectedDate})}>
                                {expectedDate}
                            </div>
                        </CycleRenderer>
                    }
                    <CycleRenderer comment={cycleRenderClass && tooltipContent} deletionMode={deletionMode}
                                   expected={(!isAdvanced && expectedDate) && t("animalDocuments.plannedDate", {date: expectedDate})}
                                   isInterrupted={isInterrupted}/>
                    <CycleRenderer isInterrupted={isInterrupted}/>
                    <CycleRenderer isInterrupted={isInterrupted}/>
                    <CycleRenderer isInterrupted={isInterrupted}/>
                    {showGilts && <CycleRenderer isInterrupted={isInterrupted}/>}
                </>
            );
        }
        const operators = parturitions[0].operators;
        const parturitionID = parturitions[0].event.EvID;
        return (
            <>
                {
                    isAdvanced &&
                    <CycleRenderer>
                        <div className={`cell-wrapper ${hoveredID === parturitionID ? "error" : "success"}`}
                             id={`${parturitionID} EvTime`} onDoubleClick={onDoubleEventClick}
                             onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                             onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                             onClick={deletionMode ? onDeleteClick : undefined}
                        >
                            {getEventDate(parturition)}
                        </div>
                    </CycleRenderer>
                }
                <CycleRenderer comment={cycleRenderClass && tooltipContent}
                               isAdvanced={isAdvanced} operators={operators} deletionMode={deletionMode}>
                    <div className={`cell-wrapper ${hoveredID === parturitionID ? "error" : "success"}`}
                         id={`${parturitionID} EvData.HealthyCnt`} onDoubleClick={onDoubleEventClick}
                         onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                         onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                         onClick={deletionMode ? onDeleteClick : undefined}
                    >
                        {get(parturition, "event.EvData.HealthyCnt", 0)}
                    </div>
                </CycleRenderer>
                <CycleRenderer>
                    <div className={`cell-wrapper ${hoveredID === parturitionID ? "error" : "success"}`}
                         id={`${parturitionID} EvData.WeakCnt`} onDoubleClick={onDoubleEventClick}
                         onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                         onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                         onClick={deletionMode ? onDeleteClick : undefined}
                    >
                        {get(parturition, "event.EvData.WeakCnt", 0)}
                    </div>
                </CycleRenderer>
                <CycleRenderer>
                    <div className={`cell-wrapper ${hoveredID === parturitionID ? "error" : "success"}`}
                         id={`${parturitionID} EvData.DeadCnt`} onDoubleClick={onDoubleEventClick}
                         onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                         onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                         onClick={deletionMode ? onDeleteClick : undefined}
                    >
                        {get(parturition, "event.EvData.DeadCnt", 0)}
                    </div>
                </CycleRenderer>
                <CycleRenderer>
                    <div className={`cell-wrapper ${hoveredID === parturitionID ? "error" : "success"}`}
                         id={`${parturitionID} EvData.MummyCnt`} onDoubleClick={onDoubleEventClick}
                         onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                         onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                         onClick={deletionMode ? onDeleteClick : undefined}
                    >
                        {get(parturition, "event.EvData.MummyCnt", 0)}
                    </div>
                </CycleRenderer>
                {
                    showGilts &&
                    <CycleRenderer>
                        <div className={`cell-wrapper ${hoveredID === parturitionID ? "error" : "success"}`}
                             id={`${parturitionID} EvData.GiltsCnt`} onDoubleClick={onDoubleEventClick}
                             onMouseEnter={deletionMode ? this.onMouseEnter : undefined}
                             onMouseLeave={deletionMode ? this.onMouseLeave : undefined}
                             onClick={deletionMode ? onDeleteClick : undefined}
                        >
                            {get(parturition, "event.EvData.GiltsCnt", 0)}
                        </div>
                    </CycleRenderer>
                }
            </>
        );
    }
}

ParturitionRenderer.propTypes = {
    cycle: PropTypes.shape({
        parturitions: PropTypes.array.isRequired
    }).isRequired
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(ParturitionRenderer);
