import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import EstimatedWeight from "../beans/estimated-weight/EstimatedWeight";
import { useSelector } from "react-redux";
import { getOtherWeightsSelector, makeGetGroupReferenceLineType } from "../selectors/settingsSelector";
import { convertWeightUnitTo } from "../utils/UnitUtils";
import { getColorByName } from "../utils/ColorUtils";
import { localMomentToUTC } from "../utils/DateTimeUtils";
import moment from "moment";
import { getAnimalUnit } from "../utils/SettingsUtils";

export default function useReferenceLineCreator(birthTime, domain, yAxisId) {

    const getGroupReferenceLineType = useMemo(makeGetGroupReferenceLineType, []);

    const referenceLineType = useSelector(getGroupReferenceLineType);
    const weightArray = useSelector(getOtherWeightsSelector);
    const animalUnit = useRef(getAnimalUnit());
    const { t } = useTranslation();

    const estimate = useMemo(() => {
        const estimate = new EstimatedWeight(0, []);
        estimate.setWeightTable(weightArray, 1200);
        estimate.setBirthDate(birthTime);
        return estimate;
    }, [weightArray, birthTime])

    const getWeight = useCallback(date => {
        return convertWeightUnitTo(estimate.getWeightByDate(+date), {
            unit: animalUnit.current,
            fixed: 2,
            showUnit: true
        })
    }, [estimate]);

    const getName = useCallback((week, date) => {
        if (referenceLineType === "EXPECTED_WEIGHT") return getWeight(date);
        if (referenceLineType === "WEEK_AND_EXPECTED_WEIGHT") {
            const weekText = t("week");
            const weightText = t("weight");
            return `${weekText.charAt(0).toUpperCase()}: ${week} | ${weightText.charAt(0).toUpperCase()}: ${getWeight(date)}`;
        };
        return `${t("week")} ${week}`
    }, [t, referenceLineType, getWeight]);

    return useMemo(() => {
        if (!birthTime) return [];
        const color = getColorByName("secondary");
        const birthUTC = localMomentToUTC(birthTime);
        let diffWeeksFromDomainStart = moment.utc(domain[0]).diff(birthUTC, "weeks");
        const weekStart = moment.utc(birthUTC).add(diffWeeksFromDomainStart, "weeks");
        if (weekStart.isSameOrBefore(domain[0], "day")) { // poczatek tygodniia wypadl przed wykresem wiec jest przesuniety o tydzien
            weekStart.add(1, "weeks");
            diffWeeksFromDomainStart++;
        }
        const weeksToEnd = moment.utc(domain[1]).diff(weekStart, "weeks");
        const lines = [];
        for (let i = 0; i <= weeksToEnd; i += 2) {
            const date = weekStart.clone().add(i, "weeks");
            lines.push({
                x: +date,
                name: getName(i + diffWeeksFromDomainStart, date),
                strokeDasharray: "5 5",
                color,
                yAxisId
            })
        }
        return lines;
    }, [birthTime, domain, yAxisId, getName])
}