import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import ConfirmModificationField from "../../custom-fields/ConfirmModificationField";
import {
    resurrectAnimal, resurrectAnimalNotificationFailure,
    resurrectAnimalNotificationSuccess,
    showRemoveSaleInfo
} from "../../../api/animal/resurrectAnimal";

export const ModalName = 'group-card-remove-event-modal';

class GroupCardRemoveEventModal extends Component {

    render() {
        const {
            show,
            handleHide,
            t,
            handleSubmit,
            submitting,
            EvID,
            name,
            invalid,
            text,
            title,
            isGroup
        } = this.props;

        return (
            <Modal onHide={handleHide} show={EvID ? show : false} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={title ?? t("animalDocuments.deleteEventTitle")}
                        onCloseClick={handleHide}/>
                    <ModalBody>
                        <p>{text}</p>
                        <ConfirmModificationField group={isGroup} name={name} edit={false}/>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        hasButtonDisabled={invalid}
                        confirmText={t("yes")}
                        closeText={t("cancel")}
                        onCloseClick={handleHide}
                        submitting={submitting}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }
}

GroupCardRemoveEventModal.propTypes = {
    AnmID: PropTypes.string.isRequired,
    AnmCnt: PropTypes.number.isRequired,
    EvID: PropTypes.number.isRequired,
    AnmGrp: PropTypes.string,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isGroup: PropTypes.bool.isRequired
};

const submit = (values, dispatch, props) => {
    console.log(values, props);
    const {EvID, AnmGrp, AnmID, AnmCnt} = props;
    const {_Comment} = values;
    return resurrectAnimal({
        AnmID: AnmID,
        AnmCnt: AnmCnt,
        EvID: EvID,
        AnmGrp: AnmGrp ?? undefined,
        Comment: _Comment
    })
        .then((res) => {
            resurrectAnimalNotificationSuccess(res);
            showRemoveSaleInfo(res, dispatch);
            props.handleHide();
        })
        .catch((e) => {
            resurrectAnimalNotificationFailure(e);
        });
}

export default compose(
    connectModal({name: ModalName}),
    connect(null),
    reduxForm({
        form: ModalName,
        onSubmit: submit
    }),
    withTranslation(),
)(GroupCardRemoveEventModal)
