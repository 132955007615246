import Bean from "./Bean";
import { myID } from "../libs/generateID";
import store from "../store/store";
import backupDB from "../database/backupDB";
import moment from "moment";

export default class Backup extends Bean {

    static TYPE = {
        GENERAL: "GENERAL",
        FARM_MAP: "FARM_MAP"
    }

    static VERSION = {
        [Backup.TYPE.GENERAL]: 0,
        [Backup.TYPE.FARM_MAP]: 1
    }

    static createBackup(type = Backup.GENERAL, value = {}, FarmID = store.getState().location.farm) {
        return new Backup({
            FarmID,
            Type: type,
            Value: value,
            DtaModTime: +new Date(),
            ID: myID(),
            Used: false,
            Version: Backup.VERSION[type]
        })
    }

    setAsUsed() {
        this.Used = true;
    }

    isUsed() {
        return !!this.Used;
    }

    getValue() {
        return this.Value;
    }

    getDate() {
        return this.DtaModTime;
    }

    getDateFormatted() {
        return moment(this.DtaModTime).format("DD.MM.YY HH:mm")
    }

    save() {
        backupDB.save(this);
    }
}
