import { getColorByName } from "./ColorUtils";

const pi = Math.PI;
const halfPi = Math.PI / 2;
const tau = 2 * pi;
const epsilon = 1e-6;
const tauEpsilon = tau - epsilon;
// wartosci ustalone metoda prob i bledow
const MIN_ANGLE = -Math.PI / 2 + .19;
const MAX_ANGLE = Math.PI / 2 - .25;

export const getCoordsOnArc = (angle, offset = 10) => {
    const first = Math.cos(angle - Math.PI / 2) * offset;
    const last = Math.sin(angle - Math.PI / 2) * offset;
    return [first, last];
};

export const getPercent = (value, min, max, reverse = false) => {
    if (max) {
        if (reverse) {
            // if (min === max) return 0;
            // if (value > max) return 0;
            // if (value < min) return 100;
            return +parseFloat(`${(Math.abs(value - max) / Math.abs(min - max)) * 100}`).toFixed(2);
            // return Math.min(percent, 100.00);
        }
        // if (min === max) return 100;
        // if (value < min) return 0;
        return +parseFloat(`${((value - min) / (max - min)) * 100}`).toFixed(2);
        // return Math.min(percent, 100.00);
    }
    return null;
};

export const getAngle = (percent) => {
    const angle = +parseFloat(`${(percent * Math.PI) - (Math.PI / 2)}`);
    if (angle <= MIN_ANGLE) return MIN_ANGLE;
    if (angle >= MAX_ANGLE) return MAX_ANGLE;
    return angle;
};

const acos = (x) => x > 1 ? 0 : x < -1 ? pi : Math.acos(x);

const asin = (x) => x >= 1 ? halfPi : x <= -1 ? -halfPi : Math.asin(x);

function intersect(x0, y0, x1, y1, x2, y2, x3, y3) {
    let x10 = x1 - x0, y10 = y1 - y0, x32 = x3 - x2, y32 = y3 - y2, t = y32 * x10 - x32 * y10;
    if (t * t < epsilon) return;
    t = (x32 * (y0 - y2) - y32 * (x0 - x2)) / t;
    return [x0 + t * x10, y0 + t * y10];
}

function cornerTangents(x0, y0, x1, y1, r1, rc, cw) {
    let x01 = x0 - x1,
        y01 = y0 - y1,
        lo = (cw ? rc : -rc) / Math.sqrt(x01 * x01 + y01 * y01),
        ox = lo * y01,
        oy = -lo * x01,
        x11 = x0 + ox,
        y11 = y0 + oy,
        x10 = x1 + ox,
        y10 = y1 + oy,
        x00 = (x11 + x10) / 2,
        y00 = (y11 + y10) / 2,
        dx = x10 - x11,
        dy = y10 - y11,
        d2 = dx * dx + dy * dy,
        r = r1 - rc,
        D = x11 * y10 - x10 * y11,
        d = (dy < 0 ? -1 : 1) * Math.sqrt(Math.max(0, r * r * d2 - D * D)),
        cx0 = (D * dy - dx * d) / d2,
        cy0 = (-D * dx - dy * d) / d2,
        cx1 = (D * dy + dx * d) / d2,
        cy1 = (-D * dx + dy * d) / d2,
        dx0 = cx0 - x00,
        dy0 = cy0 - y00,
        dx1 = cx1 - x00,
        dy1 = cy1 - y00;
    if (dx0 * dx0 + dy0 * dy0 > dx1 * dx1 + dy1 * dy1) {
        cx0 = cx1;
        cy0 = cy1;
    }

    return {
        cx: cx0,
        cy: cy0,
        x01: -ox,
        y01: -oy,
        x11: cx0 * (r1 / r - 1),
        y11: cy0 * (r1 / r - 1)
    };
}

class Path {

    constructor() {
        this._x0 = null;
        this._y0 = null;
        this._x1 = null;
        this._y1 = null;
        this._ = "";
    }

    moveTo(x, y) {
        this._ += "M" + (this._x0 = this._x1 = +x) + "," + (this._y0 = this._y1 = +y);
    }

    closePath() {
        if (this._x1 !== null) {
            this._x1 = this._x0;
            this._y1 = this._y0;
            this._ += "Z";
        }
    }

    lineTo(x, y) {
        this._ += "L" + (this._x1 = +x) + "," + (this._y1 = +y);
    }

    arc(x, y, r, a0, a1, ccw) {
        let dx = r * Math.cos(a0), dy = r * Math.sin(a0), x0 = x + dx, y0 = y + dy, cw = 1 ^ ccw, da = ccw ? a0 - a1 : a1 - a0;
        if (r < 0) throw new Error("negative radius: " + r);
        if (this._x1 === null) {
            this._ += "M" + x0 + "," + y0;
        } else if (Math.abs(this._x1 - x0) > epsilon || Math.abs(this._y1 - y0) > epsilon) {
            this._ += "L" + x0 + "," + y0;
        }
        if (!r) return;
        if (da < 0) da = da % tau + tau;
        if (da > tauEpsilon) {
            this._ += "A" + r + "," + r + ",0,1," + cw + "," + (x - dx) + "," + (y - dy) + "A" + r + "," + r + ",0,1," + cw + "," + (this._x1 = x0) + "," + (this._y1 = y0);
        } else if (da > epsilon) {
            this._ += "A" + r + "," + r + ",0," + (+(da >= pi)) + "," + cw + "," + (this._x1 = x + r * Math.cos(a1)) + "," + (this._y1 = y + r * Math.sin(a1));
        }
    }

    toString() {
        return this._;
    }
}

export const generateArc = (startAngle, endAngle) => {
    let r = null;
    let r0 = 0.65;
    let r1 = 1;
    let a0 = startAngle - halfPi;
    let a1 = endAngle - halfPi;
    let da = Math.abs(a1 - a0);
    let cw = a1 > a0;
    let context = new Path();
    if (r1 < r0) {
        r = r1;
        r1 = r0;
        r0 = r;
    }
    if (!(r1 > epsilon)) context.moveTo(0, 0);
    else if (da > tau - epsilon) {
        context.moveTo(r1 * Math.cos(a0), r1 * Math.sin(a0));
        context.arc(0, 0, r1, a0, a1, !cw);
        if (r0 > epsilon) {
            context.moveTo(r0 * Math.cos(a1), r0 * Math.sin(a1));
            context.arc(0, 0, r0, a1, a0, cw);
        }
    } else {
        let a01 = a0;
        let a11 = a1;
        let a00 = a0;
        let a10 = a1;
        let da0 = da;
        let da1 = da;
        let ap = 0;
        let rp = Math.sqrt(r0 * r0, +r1 * r1);
        let rc = Math.min(Math.abs(r1 - r0) / 2, 1);
        let rc0 = rc;
        let rc1 = rc;
        let t0;
        let t1;
        let x00 = 0;
        let y00 = 0;
        let x11 = 0;
        let y11 = 0;

        if (rp > epsilon) {
            let p0 = asin(rp / r0 * Math.sin(ap));
            let p1 = asin(rp / r1 * Math.sin(ap));
            if ((da0 -= p0 * 2) > epsilon) {
                p0 *= (cw ? 1 : -1);
                a00 += p0;
                a10 -= p0;
            } else {
                da0 = 0;
                a10 = (a0 + a1) / 2;
                a00 = a10;
            }
            if ((da1 -= p1 * 2) > epsilon) {
                p1 *= (cw ? 1 : -1);
                a01 += p1;
                a11 -= p1;
            } else {
                da1 = 0;
                a11 = (a0 + a1) / 2;
                a01 = a11;
            }
        }
        let x01 = r1 * Math.cos(a01);
        let y01 = r1 * Math.sin(a01);
        let x10 = r0 * Math.cos(a10);
        let y10 = r0 * Math.sin(a10);

        if (rc > epsilon) {
            x11 = r1 * Math.cos(a11);
            y11 = r1 * Math.sin(a11);
            x00 = r0 * Math.cos(a00);
            y00 = r0 * Math.sin(a00);
            let oc;

            if (da < pi && (oc = intersect(x01, y01, x00, y00, x11, y11, x10, y10))) {
                let ax = x01 - oc[0];
                let ay = y01 - oc[1];
                let bx = x11 - oc[0];
                let by = y11 - oc[1];
                let kc = 1 / Math.sin(acos((ax * bx + ay * by) / (Math.sqrt(ax * ax + ay * ay) * Math.sqrt(bx * bx + by * by))) / 2);
                let lc = Math.sqrt(oc[0] * oc[0] + oc[1] * oc[1]);
                rc0 = Math.min(rc, (r0 - lc) / (kc - 1));
                rc1 = Math.min(rc, (r1 - lc) / (kc + 1));
            }
        }
        if (!(da1 > epsilon)) context.moveTo(x01, y01);
        else if (rc1 > epsilon) {
            t0 = cornerTangents(x00, y00, x01, y01, r1, rc1, cw);
            t1 = cornerTangents(x11, y11, x10, y10, r1, rc1, cw);
            context.moveTo(t0.cx + t0.x01, t0.cy + t0.y01);
            if (rc1 < rc) context.arc(t0.cx, t0.cy, rc1, Math.atan2(t0.y01, t0.x01), Math.atan2(t1.y01, t1.x01), !cw);
            else {
                context.arc(t0.cx, t0.cy, rc1, Math.atan2(t0.y01, t0.x01), Math.atan2(t0.y11, t0.x11), !cw);
                context.arc(0, 0, r1, Math.atan2(t0.cy + t0.y11, t0.cx + t0.x11), Math.atan2(t1.cy + t1.y11, t1.cx + t1.x11), !cw);
                context.arc(t1.cx, t1.cy, rc1, Math.atan2(t1.y11, t1.x11), Math.atan2(t1.y01, t1.x01), !cw);
            }
        } else {
            context.moveTo(x01, y01);
            context.arc(0, 0, r1, a01, a11, !cw);
        }
        if (!(r0 > epsilon) || !(da0 > epsilon)) context.lineTo(x10, y10);
        else if (rc0 > epsilon) {
            t0 = cornerTangents(x10, y10, x11, y11, r0, -rc0, cw);
            t1 = cornerTangents(x01, y01, x00, y00, r0, -rc0, cw);
            context.lineTo(t0.cx + t0.x01, t0.cy + t0.y01);
            if (rc0 < rc) context.arc(t0.cx, t0.cy, rc0, Math.atan2(t0.y01, t0.x01), Math.atan2(t1.y01, t1.x01), !cw);
            else {
                context.arc(t0.cx, t0.cy, rc0, Math.atan2(t0.y01, t0.x01), Math.atan2(t0.y11, t0.x11), !cw);
                context.arc(0, 0, r0, Math.atan2(t0.cy + t0.y11, t0.cx + t0.x11), Math.atan2(t1.cy + t1.y11, t1.cx + t1.x11), cw);
                context.arc(t1.cx, t1.cy, rc0, Math.atan2(t1.y11, t1.x11), Math.atan2(t1.y01, t1.x01), !cw);
            }
        } else context.arc(0, 0, r0, a10, a00, cw);
        context.closePath();
    }
    return context.toString();
};

export const getColorByPercent = (colors, percent, sideToSide) => {
    if (sideToSide) {
        console.log("percent", percent);
        if (percent < 0 || percent > 100) return getColorByName("error");
        if (percent <= 10 || percent >= 90) return colors[0];
        return colors[1];
    }
    const index = Math.round(percent * colors.length - 1);
    return colors[index] || colors[0];
};

export const getColors = (reverse = false, sideToSide = false, isDisabled = false) => {
    if (isDisabled) return ["rgb(76, 76, 76)"];
    const colors = [
        "rgb(185, 14, 14)",
        "rgb(199, 58, 23)",
        "rgb(213, 102, 32)",
        "rgb(227, 147, 41)",
        "rgb(241, 191, 50)",
        "rgb(255, 235, 59)",
        "rgb(220, 225, 62)",
        "rgb(184, 215, 64)",
        "rgb(149, 204, 67)",
        "rgb(113, 194, 69)",
        "rgb(78, 184, 72)"
    ];
    if (sideToSide) {
        return [
            getColorByName("warning"),
            getColorByName("success")
        ];
    }
    if (reverse) return [...colors].reverse();
    return colors;
};
