import React from "react";
import TransferGrid from "../transfer/TransferGrid";
import GraftingProgramGridCard from "../grafting/GraftingProgramGridCard";
import WeightingSingleChart from "../weighting/WeightingSingleChart";
import AnimalModificationList from "../animal-modification-list/AnimalModificationList";

export default class BoarCard extends React.Component {

    render() {
        return (
            <>
                <WeightingSingleChart/>
                <GraftingProgramGridCard/>
                <TransferGrid/>
                <AnimalModificationList/>
            </>
        )
    }
}
