import React from "react";

export default function ({t, warnings, isWarningInChildren}) {
    return (
        <div className={"map-drawer-warnings"}
             aria-label={t("newSettings.notifications.events.warning")}>
            {
                !warnings.isEmpty() &&
                <ul className={"helper-tutorial"}>
                    {
                        warnings.groupByType().map(({key, name, alerts}) => (
                            <React.Fragment key={key}>
                                <li className={"header"}>
                                    {name}
                                </li>
                                {
                                    alerts.map((alert, i) => (
                                        <li
                                            key={`${key}_${i}`}>{alert.getTranslated()}
                                            {
                                                (alert.getCounter() > 1) &&
                                                <small>
                                                    {`x ${alert.getCounterByType(key)}`}
                                                </small>
                                            }
                                        </li>
                                    ))
                                }
                            </React.Fragment>
                        ))
                    }
                </ul>
            }
            {
                !!isWarningInChildren &&
                <div>
                    {t("newSettings.buildings.farmMap.clickToShowWarning")}
                </div>
            }
        </div>
    )
}
