import React, { useMemo } from 'react';
import useAnimalSingleInfo from '../ideal-charts/useAnimalSingleInfo';
import {makeGetFatMeasurementEvents} from "../../../selectors/animalDocumentsSelectors";
import { useSelector } from "react-redux";
import { localMomentToUTC } from "../../../utils/DateTimeUtils";
import FatMeasurementChart from './FatMeasurementChart';
import moment from 'moment';

export default function FatMeasurementSingleChartChart() {

    const animalInfo = useAnimalSingleInfo();

    const getFatMeasurementEvents = useMemo(() => makeGetFatMeasurementEvents(), []);

    const events = useSelector(getFatMeasurementEvents);
    const Dict = useMemo(() => {
        const dictionary = {};
        for (const event of events) {
            const utcTIME = +localMomentToUTC(event?.EvTime).startOf("day");
            if (!dictionary[utcTIME]) dictionary[utcTIME] = [];
            dictionary[utcTIME].push({
                [event.EvCode]: event?.EvData?.Fat ??  0,
                fat: event?.EvData?.Fat ?? 0,
                AnmID: event?.AnmID,
            });
        }
        return dictionary;
    }, [events]);

    const domain = useMemo(() => {
        const dates = Object.keys(Dict)
        const min = Math.min(...dates)
        const max =  Math.max(...dates)
        return [+moment(min).subtract(3,'d'),+moment(max).add(3,'d')]
    }, [Dict])
    return (
        <FatMeasurementChart fatDict={Dict} chartDomain={domain} {...animalInfo} />
    )
}
