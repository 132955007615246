import React from 'react';
import useCountdown from "../../../hooks/useCountdown";
import TimeCounterItem from "./TimeCounterItem";
import "./_time-counter.scss";
import {useTranslation} from "react-i18next";

function TimeCounterContainer({container, children}) {
    return React.createElement(container, {className: "time-counter"}, children);
}

export default function TimeCounter({container = "h2", date}) {

    const [days, hours, minutes, seconds] = useCountdown(date);
    const {t} = useTranslation();

    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
        return <TimeCounterContainer container={container}>{t("soon")}</TimeCounterContainer>
    }

    return (
        <TimeCounterContainer container={container}>
            <TimeCounterItem name={t("days")} counter={days}/>
            <TimeCounterItem name={t("hours")} counter={hours}/>
            <TimeCounterItem name={t("minutes")} counter={minutes}/>
            <TimeCounterItem name={t("seconds")} counter={seconds}/>
        </TimeCounterContainer>
    );
};
