import {LogTableTypes, LogTableTypesShort} from "../constans/logCodeTypes";
import {has, get, isArray} from "lodash";
import {SettingTypes} from "../constans/settingTypes";

export function getLogType(name, codes = []) {
    if (codes.includes("2246")) return "validations";
    return name;
}

export function shouldGetExternalName(log) {
    const setType = log?.ObjNew?.SetType;
    const codes = log?.Codes || [];
    if (setType && [SettingTypes.GENERAL, SettingTypes.NOTIFICATIONS, SettingTypes.FARM_MAP, SettingTypes.ECONOMY_SETTINGS].includes(setType)) return true;
    return codes.some((code) => ["2246", "2228"].includes(code));
}

export function getIconClassName(type) {
    let value;
    switch (type) {
        case "validations":
            value = "fa-shield-check";
            break;
        case "codes":
            value = "fa-text";
            break;
        case "date":
            value = "fa-calendar-day";
            break;
        case "user":
            value = "fa-user";
            break;
        case "anls":
        case LogTableTypes.ANIMAL_GROUP:
        case LogTableTypesShort.ANIMAL_GROUP:
        case LogTableTypes.ANIMALS:
        case LogTableTypesShort.ANIMALS:
        case LogTableTypes.FTI:
        case LogTableTypesShort.FTI:
            value = "fa-pig";
            break;
        case LogTableTypes.SALES:
        case LogTableTypesShort.SALES:
            value = "fa-file-invoice-dollar";
            break;
        case LogTableTypes.DEVICES:
        case LogTableTypesShort.DEVICES:
            value = "fa-microchip";
            break;
        case LogTableTypes.SETTINGS:
        case LogTableTypesShort.SETTINGS:
        case LogTableTypes.GENERAL:
        case LogTableTypesShort.GENERAL:
            value = "fa-cog";
            break;
        case LogTableTypesShort.DICTIONARY:
        case LogTableTypes.DICTIONARY:
            value = "fa-book";
            break;
        case "bugs":
        case LogTableTypes.BUILDINGS:
        case LogTableTypesShort.BUILDINGS:
            value = "fa-farm";
            break;
        case "taks":
        case LogTableTypes.TASKS:
        case LogTableTypesShort.TASKS:
            value = "fa-tasks";
            break;
        case "Medicine":
            value = "fa-pills";
            break;
        case "ControlList":
            value = "fa-list-alt";
            break;
        case "GraftingProgram":
            value = "fa-syringe";
            break;
        case "Ingredient":
            value = "fa-wheat";
            break;
        case LogTableTypes.BREEDING_COUNTERS:
        case LogTableTypesShort.BREEDING_COUNTERS:
            value = "fa-binary";
            break;
        default:
            value = "";
            break;
    }
    return `fas pes-1 fa-fw ${value}`;
}


export const getFormattedValue = (data, key, formatter = (value) => value) => {
    // we convert key to array
    const keyAsArray = isArray(key) ? key : [key];
    // there must be value for every key
    if (keyAsArray.some((k) => !has(data, k))) return null;
    const values = keyAsArray.map((k) => get(data, k));
    // pass variable as array if key is an array or else just pass the OG value
    return formatter(isArray(key) ? values : values[0]);
}
