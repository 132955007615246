export const VARIABLE_STATUS = {
    YET_TO_BE_READ: "YET_TO_BE_READ",
    PENDING: "PENDING",
    FULFILLED: "FULFILLED",
    REJECTED: "REJECTED",
    INVALIDATED: "INVALIDATED"
}

export const VARIABLE_WRITE_STATUS = {
    SUBMITTING: "SUBMITTING",
    ERROR: "ERROR",
    SUBMITTED: "SUBMITTED",
    PENDING: "PENDING"
}