import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export function getAnimalHistoryOfChanges({FarmID, ClientID, LocalUserID, ObjID, signal} = {}) {
    const {_FarmID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.getAnimalHistoryOfChanges({
            farmID: _FarmID,
            clientID: _ClientID
        }),
        queryParams: {
            ObjID: ObjID
        },
        signal
    })
}
