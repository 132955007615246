import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export function getDeviceData({Start, End, Placements, Type}, {FarmID} = {}) {
    const {_FarmID} = getUserApiData({FarmID});
    return invokeApig({
        ...Paths.getDeviceData({farmID: _FarmID}),
        queryParams: {
            Start, End, Placements, Type
        }
    })
}
