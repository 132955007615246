import { get, isNil } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { ReactLocalStorage } from "../../../../utils/ReactLocalStorage";
import { getTextAfterSign, getTextBeforeSign } from "../../../../utils/TextUtils";
import { interpolateLink } from "../../../../utils/URLUtils";
import Rotate from "../../../farm-map/components/farm-map-controls/Rotate";
import ALink from "../../alink/ALink";
import Button from "../../button/Button";
import HelperIcon from "./helper-icon/HelperIcon";
import HelperImage from "./helper-image/HelperImage";
import HelperNote from "./helper-note/HelperNote";

const IconAdapter = ({children, type}) => {
    return <HelperIcon type={type} className={[...children].join(" ")} />;
};

/**
 * parsuje <a>link/do/pod/strony#nazwa przycisku</a> na przycisk feturkowy
 * @param children
 * @param params
 * @return {JSX.Element}
 * @constructor
 */
const LinkAdapter = ({children, params}) => {
    const link = `/${interpolateLink(
        getTextBeforeSign(children[0], "#"),
        params
    )}`.trim();
    const text = getTextAfterSign(children[0], "#");
    return (
        <ALink uppercase={false} bolded={true} href={link}>
            {text}
        </ALink>
    );
};

const FastHint = ({children, title, type}) => {
    return (
        <HelperNote type={type} title={title}>
            {children}
        </HelperNote>
    );
};

const ImageAdapter = ({children, mobileView}) => {
    const theme = ReactLocalStorage.getPrivate("theme", "normal");
    let fileName = children[0];
    if (theme === "dark") fileName += `_${theme}`;
    fileName = fileName.replace(/\s/g, "");
    if (mobileView) {
        try {
            const http = new XMLHttpRequest();
            http.open(
                "GET",
                `https://res-fetura.s3.eu-central-1.amazonaws.com/documents/helper/${
                    fileName + "_mob"
                }.png`,
                false
            );
            http.send();
            if (http.statusText === "OK") fileName += "_mob";
        } catch (e) {
            fileName = children[0];
            console.error(e);
        }
    }
    let imageLink = `https://res-fetura.s3.eu-central-1.amazonaws.com/documents/helper/${fileName}.png`;
    return <HelperImage src={imageLink} alt={fileName} size={"match-font"} />;
};

const ButtonAdapter = ({children, icon, color, buttonStyle}) => {
    const buttonIcon = !!icon ? <i className={icon} /> : undefined;
    return (
        <Button
            className="mt-0 mb-0 ms-1 me-1"
            icon={buttonIcon}
            type={"button"}
            buttonColor={color}
            buttonStyle={buttonStyle}>
            {children}
        </Button>
    );
};

const ScrollToElement = ({children}) => {
    const elements = children[0];
    const getElementClassName = (el) => get(el, "className", "");
    const onScrollElement = () => {
        const foundedElement = document.getElementById(items[0]);
        if (!isNil(foundedElement)) {
            const openCollapsedTags = (element) => {
                let shouldCheck = true;
                let elClassName = getElementClassName(element);
                elClassName += "";
                if (elClassName.includes("helper-sidebar")) shouldCheck = false;
                if (elClassName.includes("helper-header-box") && shouldCheck) {
                    const collapsedElement = [...element.childNodes].find(
                        (e) => {
                            let eClassName = getElementClassName(e);
                            eClassName += "";
                            return (
                                eClassName.includes("column-helper") &&
                                !eClassName.includes("expanded")
                            );
                        }
                    );
                    if (!isNil(collapsedElement)) collapsedElement.click();
                }
                if (shouldCheck) openCollapsedTags(element.parentNode);
            };
            openCollapsedTags(foundedElement);
            setTimeout(() => {
                if (foundedElement) {
                    foundedElement.scrollIntoView({behavior: "smooth"});
                    foundedElement.classList.add("highlight-animation");
                }
            }, 350);
            setTimeout(() => {
                if (foundedElement)
                    foundedElement.classList.remove("highlight-animation");
            }, 4350);
        }
    };
    const items = elements.split("#");
    return (
        <ALink onClick={onScrollElement} uppercase={false} bolded={true}>
            {items[1]}
        </ALink>
    );
};

const UnsortedList = ({children, type = "disc"}) => {
    return (
        <ul className="helper-tutorial" style={{"list-style-type": type}}>
            {children}
        </ul>
    );
};

const SpanAdapter = ({children}) => {
    const items = children[0].split(" ");
    return <span className={items[1]}>{items[0]}</span>;
};

const IconTable = ({children}) => {
    const tableItems = children[0].split("&");
    return (
        <table className="fetura-helper-icon-table" border="1">
            {tableItems.map((item) => {
                const tableContent = item.split(":");
                return (
                    <tr>
                        <td>
                            <HelperIcon className={tableContent[0]} />
                        </td>
                        <td>{tableContent[1]}</td>
                    </tr>
                );
            })}
        </table>
    );
};

class HelperTranslate extends React.Component {
    render() {
        const {
            t,
            translationKey,
            values = {},
            params = {},
            FarmID,
            ClientUserID,
            LocalUserID,
            mobileView,
        } = this.props;
        return (
            <Trans
                i18nKey={translationKey}
                values={values}
                t={t}
                components={{
                    bold: <strong />,
                    strong: <strong />,
                    ul: <UnsortedList />,
                    li: <li />,
                    ol: <ol />,
                    i: <i />,
                    br: <br className="d-block mt-2 mb-2 no-content" />,
                    icon: <IconAdapter />,
                    a: (
                        <LinkAdapter
                            params={{
                                FarmID,
                                ClientUserID,
                                LocalUserID,
                                ...params,
                            }}
                        />
                    ),
                    scroll: <ScrollToElement />,
                    hint: <FastHint />,
                    hr: <hr />,
                    image: <ImageAdapter mobileView={mobileView} />,
                    btn: <ButtonAdapter />,
                    icontable: <IconTable />,
                    span: <SpanAdapter />,
                    rotate: <Rotate onRotate={() => {}} />,
                }}
            />
        );
    }
}

HelperTranslate.propTypes = {
    translationKey: PropTypes.string.isRequired,
    values: PropTypes.object,
    params: PropTypes.object,
    mobileView: PropTypes.bool,
};
export default compose(
    withTranslation(), // potrzebne zeby rerender poszedl na zmianie jezyka bo Trans tylko interpoluje komponenty
    connect((state) => ({
        FarmID: state.location.farm,
        ClientID: state.user.user.ClientID,
        LocalUserID: state.user.user.LocalUserID,
    }))
)(HelperTranslate);
