import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import {doTare} from "../../../../../IOT/device-functions/VehicleWeightFunctions";

export default ({ t, devices, roleUtils, placementId, data }, updateShadowOn = "map") => {
    const isAuthorized = roleUtils.hasRoleByPlacementID({
        roles: [RoleTypes.DEVICE_SILO_WRITE],
        placementId
    });
    const allowedDevices = devices.filter(({ device }) => device.DevType === DevTypes.VEHICLE_WEIGHT_R320);
    console.log(allowedDevices, devices, roleUtils, placementId, data);
    let result = {
        key: "vehicle-weight-tare-control-panel",
        icon: {
            iconName: "fas fa-scale-unbalanced-flip",
            name: t("settings.doTare"),
            description: t("settings.doTare")
        },
        show: allowedDevices.length > 0 && isAuthorized
    }
    const tarePayload = {};
    return {
        ...result,
        submit: () => {
            return new Promise((resolve, reject) => {
                doTare(allowedDevices.map(({ device }) => device), tarePayload, {
                    onSuccess: resolve,
                    onFailure: reject
                })
            }).then(() => {
                console.log("onSuccess yet to be implemented")
            })
        }
    }
}
