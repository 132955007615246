import {collectErrorCodes} from "../utils/BraintreeUtils";
import {findIndex} from "lodash";

export const initialValue = {
    fetching: false,
    fetched: false,
    error: null,
    clientToken: null,
    subscription: null,
    customer: null,
    hasSubscription: false,
    subscriptionList: [],
    paymentMethodList: [],
    dropinInstance: null,
    dropinLoaded: null,
    paymentMethodRequestable: true,
    errorCode: null,
    selectedSubscription: null,
    transactions: [],
    selectedPlans: [],
};

export default function braintreeReducer(state = initialValue, action) {
    switch (action.type) {
        case "BT_DELETE_CUSTOMER_PENDING":
        case "BT_CREATE_PAYMENT_METHOD_PENDING":
        case "BT_DELETE_PAYMENT_METHOD_PENDING":
        case "BT_CREATE_SUBSCRIPTION_PENDING":
        case "BT_CANCEL_SUBSCRIPTION_PENDING":
        case "BT_FIND_CUSTOMER_PENDING":
        case "BT_SEARCH_SUBSCRIPTION_PENDING":
        case "BT_TRANSACTION_HISTORY_PENDING":
        case "BT_UPDATE_PAYMENT_METHOD_PENDING":
        case "BT_CREATE_CUSTOMER_PENDING": {
            return {...state, fetching: true, fetched: false}
        }

        case "BT_GENERATE_CLIENT_TOKEN_PENDING": {
            return {...state, fetching: true, clientToken: null}
        }
        case "BT_GENERATE_CLIENT_TOKEN_REJECTED": {
            return {...state, fetching: false, error: action.payload, clientToken: null}
        }
        case "BT_GENERATE_CLIENT_TOKEN_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                clientToken: action.payload
            }
        }
        case "BT_CREATE_PAYMENT_METHOD_REJECTED":
        case "BT_DELETE_PAYMENT_METHOD_REJECTED": {
            return {...state, fetching: false, error: action.payload}
        }
        case "BT_CREATE_PAYMENT_METHOD_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: false,
                paymentMethodList: [...state.paymentMethodList,
                    {
                        cardType: action.payload.paymentMethod.cardType,
                        expirationDate: action.payload.paymentMethod.expirationDate,
                        imageUrl: action.payload.paymentMethod.imageUrl,
                        maskedNumber: action.payload.paymentMethod.maskedNumber,
                        token: action.payload.paymentMethod.token
                    }]
            }
        }
        case "BT_DELETE_PAYMENT_METHOD_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: false,
                paymentMethodList: [...state.paymentMethodList].filter(met => (met.token !== action.paymentMethodToken))
            }
        }
        case "BT_CREATE_SUBSCRIPTION_REJECTED": {
            return {
                ...state,
                fetching: false,
                error: action.payload,
                errorCode: collectErrorCodes(action.payload, ''),
                subscription: null
            }
        }
        case "BT_UPDATE_SUBSCRIPTION_REJECTED": {
            return {
                ...state,
                fetching: false,
                error: action.payload,
                errorCode: collectErrorCodes(action.payload, ''),
            }
        }
        case "BT_CREATE_SUBSCRIPTION_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: false,
                subscription: action.payload,
                hasSubscription: state.hasSubscription + 1,
                subscriptionList: [
                    ...state.subscriptionList,
                    {
                        id: action.payload.subscription.id,
                        status: action.payload.subscription.status,
                        planId: action.payload.subscription.planId,
                        price: action.payload.subscription.price.substr(0, action.payload.subscription.price.indexOf('.')).concat(" ").concat(action.payload.subscription.statusHistory[0].currencyIsoCode),
                        firstBillingDate: action.payload.subscription.firstBillingDate,
                        nextBillingDate: action.payload.subscription.nextBillingDate
                    }
                ]
            }
        }
        case "BT_UPDATE_SUBSCRIPTION_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: false,
                subscription: action.payload
            }
        }
        case "BT_CANCEL_SUBSCRIPTION_REJECTED": {
            return {...state, fetching: false, error: action.payload, subscription: null}
        }
        case "BT_CANCEL_SUBSCRIPTION_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: false,
                subscription: action.payload,
                hasSubscription: state.hasSubscription - 1,
                subscriptionList: [...state.subscriptionList].filter(sub => (sub.id !== action.payload.subscription.id))
            }
        }
        case "BT_FIND_CUSTOMER_REJECTED":
        case "BT_DELETE_CUSTOMER_REJECTED": {
            return {...state, fetching: false, error: action.error, customer: null}
        }
        case "BT_FIND_CUSTOMER_FULFILLED": {
            if (action.payload.name !== "notFoundError") {
                const paymentMethods = action.payload.paymentMethods.map((pm) => ({
                    maskedNumber: pm.maskedNumber,
                    cardType: pm.cardType,
                    imageUrl: pm.imageUrl,
                    expirationDate: pm.expirationDate,
                    token: pm.token,
                    data: pm
                }));
                return {
                    ...state,
                    fetching: false,
                    fetched: true,
                    error: false,
                    customer: action.payload,
                    paymentMethodList: paymentMethods,
                }
            } else {
                return {
                    ...state,
                    fetching: false,
                    fetched: true,
                    error: action.payload.name,
                    customer: null,
                    paymentMethodList: []
                }
            }
        }
        case "BT_SEARCH_SUBSCRIPTION_FULFILLED": {
            let subList = action.payload.map(s => {
                const farms = action.meta.farms.find(farm => {
                    return s.id.slice(0, -20) === farm.FarmID;
                });
                return ({
                    ...s,
                    id: s.id,
                    farmName: farms ? farms.FarmName : s.id,
                    planId: s.planId,
                    price: s.nextBillAmount.substr(0, s.nextBillAmount.indexOf('.')).concat(" ").concat(s.statusHistory[0].currencyIsoCode),
                    firstBillingDate: s.firstBillingDate,
                    nextBillingDate: s.nextBillingDate,
                    status: s.status
                })
            });
            const selectedSubscription = subList.length === 1 ? subList[0] : state.selectedSubscription;
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: false,
                hasSubscription: subList.length,
                subscriptionList: subList,
                selectedSubscription
            }
        }
        case "BT_CREATE_CUSTOMER_REJECTED": {
            return {...state, fetching: false, error: action.payload, customer: null}
        }
        case "BT_CREATE_CUSTOMER_FULFILLED":
        case "BT_DELETE_CUSTOMER_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: false,
                customer: action.payload,
                subscription: null
            }
        }
        case "BT_FINALIZE_PAYMENT_FAILURE": {
            return {...state, error: action.payload}
        }
        case "BT_STORE_DROPIN_INSTANCE": {
            return {...state, dropinInstance: action.payload, error: null, dropinLoaded: true}
        }
        case "BT_INITIALIZE_DROPIN": {
            return {...state, dropinInstance: null, error: null, dropinLoaded: false}
        }
        case "BT_SET_PAYMENT_METHOD_REQUESTABLE": {
            return {...state, paymentMethodRequestable: action.payload}
        }
        case "BT_CHANGE_SELECTED_SUBSCRIPTION": {
            return {
                ...state,
                selectedSubscription: action.payload.subscription ? action.payload.subscription : null
            }
        }
        case "BT_UPDATE_SUBSCRIPTION_PENDING": {
            return {...state, fetching: true, fetched: false, selectedSubscription: null}
        }
        case "BT_TRANSACTION_HISTORY_FULFILLED": {
            return {...state, fetching: false, fetched: true, error: false, transactions: action.payload || []}
        }
        case "BT_TRANSACTION_HISTORY_REJECTED": {
            return {...state, fetching: false, fetched: true, error: false, transactions: []}
        }
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }

        case "BT_UPDATE_SELECTED_PLANS": {
            const index = findIndex(state.selectedPlans, (c) => `${c} active` === action.payload);
            let selected = state.selectedPlans.slice(0);
            if (index >= 0) {
                selected.splice(index, 1);
            } else {
                selected.push(action.payload);
            }
            const payloadResult = action.payload.split("-");
            selected = selected.filter((p) => payloadResult[1] === "basic" ? p !== `${payloadResult[0]}-extended` : p !== `${payloadResult[0]}-basic`);
            return {...state, selectedPlans: selected}
        }

        case "BT_CLEAR_SELECTED_PLANS": {
            return {...state, selectedPlans: []}
        }
        default:
            return state
    }
}
