import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {setFeedingEfficiencyNotificationFailure, setFeedingEfficiencyNotificationSuccess} from "./efficiency";

export function setNuitriProEfficiency({
                                           DevIDs = [],
                                           PlcmntIDs = [],
                                           Efficiency1,
                                           Efficiency2,
                                           AllDevices = false
                                       }, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.setNutriProEfficiency({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        body: {
            DevIDs,
            PlcmntIDs,
            Efficiency1,
            Efficiency2,
            AllDevices
        }
    })
}

export const setNutriProEfficiencyNotificationSuccess = setFeedingEfficiencyNotificationSuccess;
export const setNutriProEfficiencyNotificationFailure = setFeedingEfficiencyNotificationFailure;