export default class AnimalModItem {
    constructor({invokedBy, timestamp, title, changes, comment, objectId, id, animalId, subgroupName}) {
        this.invokedBy = invokedBy;
        this.timestamp = timestamp;
        this.title = title;
        this.changes = changes;
        this.comment = comment;
        this.objectId = objectId;
        this.id = id;
        this.animalId = animalId;
        this.subgroupName = subgroupName;
    }

}