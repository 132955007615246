import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export async function getAllDirectories({FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return await invokeApig({
        ...Paths.getAllDirectories({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID})
    });
}
