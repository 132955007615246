import React from "react";
import { get, last } from "lodash";
import "./_printable-results-grid.scss";
import { withTranslation } from "react-i18next";
import { NameSpaces } from "@wesstron/utils/Api/constants/nameSpaces";
import { ReactLocalStorage } from "../../../utils/ReactLocalStorage";

class PrintableResultsGrid extends React.Component {

    prepareData(grid) {
        const _data = {};
        let index = 0;
        const resultRows = ReactLocalStorage.getPrivate("resultRowsOptions", []);
        // push all the way in until there is a full width row
        for (let i = 0; i < grid.length; i++) {
            if (grid[i].isFullWidth && i !== 0) index++;
            if (!Array.isArray(_data[index])) _data[index] = [];
            const row = resultRows.find((row) => row.name === grid[i]?.name) || {};
            const isRowChecked = row.hasOwnProperty("isChecked") ? row.isChecked : true;
            if (isRowChecked) _data[index].push(grid[i]);
        }
        const filteredItems = Object.values(_data).filter((rowGrid) => rowGrid.length > 1);
        const dataItems = [];
        filteredItems.forEach((items, index) => {
            if (index === 0 || items.length >= 20 || (index > 0 && [...last(dataItems), ...items].length >= 20)) {
                dataItems.push(items);
            } else {
                dataItems[dataItems.length - 1] = [...dataItems[dataItems.length - 1], ...items];
            }
        })
        return dataItems;
    }

    formatTitle = (title) => {
        const { data: { displayMode } } = this.props;
        switch (displayMode) {
            case "weeks": {
                let dates = title.split(" - ");
                return `${dates[0]}\n${dates[1]}`
            }
            default:
                return `${title}`;
        }
    }

    render() {
        const { data: { headerNames, resultsGrid, range, displayMode }, t } = this.props;
        console.log(this.props);
        let sliceIndex1, sliceIndex2;
        if (range) {
            sliceIndex1 = range[0] - 1;
            sliceIndex2 = range[1];
        }
        const _headerNames = headerNames.slice(sliceIndex1, sliceIndex2);
        const tableHeaders = (
            <thead>
                <tr>
                    <th>{t("designation")}</th>
                    {
                        _headerNames.map((header, index) =>
                            <th key={index} className={displayMode === "groups" ? "rotated" : null}>
                                {
                                    !["technologyGroups", "groups"].includes(displayMode) &&
                                    <>
                                        {this.formatTitle(header.title)}
                                        <br />
                                    </>
                                }
                                {header.name}
                            </th>
                        )
                    }
                    <th>
                        {t("summary")}
                        <br />
                        {t("yearly")}
                    </th>
                </tr>
            </thead>
        );
        const data = this.prepareData(resultsGrid);
        return (
            <>
                {
                    data.map((rowGrid, index) => ((
                        <table className={`printable-fetura-results-grid${index > 0 ? " break-page" : ""}`}>
                            {tableHeaders}
                            <tbody>
                                {
                                    rowGrid.map(({ isFullWidth, name, calculatedData, allSummary }, rowIndex) => {
                                        if (isFullWidth && rowIndex === rowGrid.length - 1) return null;
                                        return (
                                            <tr key={`${name}_${rowIndex}`}>
                                                <td className={!isFullWidth ? "parameter-row" : "full-width-row"}
                                                    colSpan={isFullWidth ? 9 : 1}>
                                                    {t(name)}
                                                </td>
                                                {
                                                    !isFullWidth && calculatedData.slice(sliceIndex1, sliceIndex2).map((data) => (
                                                        <td className={"parameter-row text-center"}>
                                                            {get(data, "result.summary", 0)}
                                                        </td>
                                                    ))
                                                }
                                                {
                                                    !isFullWidth &&
                                                    <td className="parameter-row text-center">
                                                        {allSummary || 0}
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    )))
                }
            </>
        );
    }
}

export default withTranslation(NameSpaces.TRANSLATION)(PrintableResultsGrid);
