import { createSelector } from 'reselect';
import { get, set, uniqBy, isNil } from "lodash";
import { getAlarmParams } from "../utils/IotAlarmUtils";
import { formatLocationName } from "../utils/global-formatters/formatLocationName";
import i18next from "i18next";
import { getPlacementArray } from '../utils/DeviceLocationUtils';

const getBuildings = (state) =>
    state.farms.buildings;

const getDevices = (state) =>
    state.farmDevices.devices;

const mapOfPlacementsToMergeTogetherSelector = createSelector([getBuildings], items => {
    const map = {};
    items.forEach(({ BgID, Sectors }) => {
        map[BgID] = BgID;
        (Sectors || []).forEach(({ SID, Chambers }) => {
            map[SID] = SID;
            (Chambers || []).forEach(({ CID, Boxes }) => {
                map[CID] = CID;
                (Boxes || []).forEach(({ BID }) => {
                    map[BID] = CID;
                })
            });
        });
    });
    return map;
});

const devicesMapSelector = createSelector([getDevices], items => {
    const map = new Map();
    items.forEach(device => {
        map.set(device.DevID, device);
    });
    return map;
});
const getAlarms = (state) =>
    state.iotAlarms.alarms;

const getFetching = (state) =>
    state.iotAlarms.fetching || {};

const getFetched = (state) =>
    state.iotAlarms.fetched || {};

export const getLanguage = (state) =>
    state.language.locale


export const getAlarmsData = createSelector(
    [getAlarms, devicesMapSelector, mapOfPlacementsToMergeTogetherSelector, getLanguage],
    (rawAlarms, deviceMap, mergePlacements, _langSelectorUsedToTriggerCalculation) => {
        const result = [];
        const alarms = {
            byDevice: [],
            byPlacement: {}
        }
        for (let alarm of rawAlarms) {
            //                                                          add new codes here V if want to merge alerts by placement
            const shouldGroupByPlacement = alarm.alarms.every(({ alarm: { alert: code } }) => [9001].includes(code));
            const code = alarm.newest.alarm.alert;
            const device = deviceMap.get(alarm.DevID);
            const placementIds = device ? getPlacementArray(device, alarm.Index) : [];
            // device id used to delete alarms - index does not matter for backend
            // const deviceIdWithIndex = isNil(alarm.Index) ? alarm.DevID : `${alarm.DevID}_${alarm.Index}`;
            const deviceId = alarm.DevID;
            const gatewayId = alarm._gw;
            let name = get(device, "Name", "?");
            if (!isNil(alarm.Index)) {
                name = `${name} {${alarm.Index}}`;
            }
            if (shouldGroupByPlacement) {
                const tmpPlacementIds = placementIds.length === 0 ? ["unknown"] : placementIds;
                for (let placementId of tmpPlacementIds) {
                    // we check if given placement id should be merged to other placement id i.e: we want standing merged with its chamber
                    const mergeId = mergePlacements[placementId] || placementId;
                    const shouldAppendLocation = mergeId !== placementId;
                    const key = `${code}.${mergeId}`;
                    const tmp = get(alarms.byPlacement, key) || {};
                    const location = shouldAppendLocation ? `${formatLocationName(placementId, { nameDeep: 1, standingNameType: "long" })}` : "";
                    const title = location ? `${name} (${location})` : name;
                    // if we have a title we append the name with a comma
                    (tmp.title) && (tmp.title += `, ${title}`);
                    // if we dont have a title we set name as our title
                    (!tmp.title) && (tmp.title = title);
                    // we want most recent time
                    tmp.timestamp = Math.max(tmp.timestamp || 0, alarm.newest.time);
                    // ideally there should be only one alarm text
                    (!tmp.text) && (tmp.text = getAlarmParams(code, alarm.newest.alarm.info));
                    (!tmp.subtitle) && (tmp.subtitle = formatLocationName(mergeId !== "unknown" ? mergeId : null, {
                        nameDeep: 2,
                        missingLocationText: i18next.t("missingLocation")
                    }));
                    // this is formatted date used by modal
                    (!tmp._passThrough) && (tmp._passThrough = { alarms: [], deviceIds: [] });
                    tmp._passThrough.deviceIds.push({
                        DevID: deviceId,
                        GatewayID: gatewayId
                    });
                    tmp._passThrough.deviceIds = uniqBy(tmp._passThrough.deviceIds, ({
                        DevID,
                        GatewayID
                    }) => `${DevID}_${GatewayID}`);
                    if (tmp._passThrough.deviceIds.length > 1) {
                        tmp.title = i18next.t("farms.tabs.generalTab.manyDevicesX", { count: tmp._passThrough.deviceIds.length });
                    }
                    tmp._passThrough.alarms.push({
                        timestamp: alarm.newest.time,
                        devices: [title],
                        text: getAlarmParams(alarm.newest.alarm.alert, alarm.newest.alarm.info)
                    })
                    set(alarms.byPlacement, key, tmp);
                }
            } else {
                alarms.byDevice.push({
                    title: name,
                    timestamp: alarm.newest.time,
                    text: alarm.alarms.length > 1 ? {
                        key: "farms.tabs.generalTab.manyAlertsX",
                        params: { count: alarm.alarms.length }
                    } : getAlarmParams(code, alarm.newest.alarm.info),
                    subtitle: placementIds.length > 1 ? i18next.t("farms.tabs.generalTab.manyLocationsX", { count: placementIds.length }) : formatLocationName(placementIds, {
                        nameDeep: 2,
                        missingLocationText: i18next.t("missingLocation")
                    }),
                    _passThrough: {
                        deviceIds: [{ DevID: deviceId, GatewayID: gatewayId }],
                        alarms: alarm.alarms.map((a) => ({
                            timestamp: a.time,
                            places: placementIds.map((placementId) => formatLocationName(placementId, {
                                nameDeep: 2,
                                missingLocationText: i18next.t("missingLocation")
                            })),
                            text: getAlarmParams(a.alarm.alert, a.alarm.info)
                        }))
                    }
                })
            }
        }
        result.push(...alarms.byDevice);
        for (let placementValue of Object.values(alarms.byPlacement)) {
            result.push(...Object.values(placementValue))
        }
        result.sort((o1, o2) => o2.timestamp - o1.timestamp);
        return result;

    })

export const isFetching = createSelector(
    [getFetching],
    (fetching) => {
        return Object.values(fetching).some((isFetching) => isFetching);
    }
);

export const isFetched = createSelector(
    [getFetched],
    (fetched) => {
        return Object.values(fetched).every((isFetched) => isFetched)
    }
);

export default getAlarms;
