import ViewContainerModal from "../../basics/view-containter-modal/ViewContainerModal";
import {useCallback} from "react";
import Card from "../../basics/card/Card";
import "../../../views/control-lists-view/control-lists/renovation-sows-to-insemination/_renovation-sows-to-insemination-control-list-grid.scss";
import FatteningAnimalsAmountDetailedTable from "./tables/FatteningAnimalsAmountDetailedTable";
import FatteningAnimalsAmountTable from "./tables/FatteningAnimalsAmountTable";

export default function FatteningAnimalsModal({name, show, onHide}) {

    const handleHide = useCallback(() => {
        onHide({name: null, type: null});
    }, [onHide]);

    return (
        <ViewContainerModal addHeight name={name} show={show} onHide={handleHide}>
            <Card>
                {
                    show === 1 &&
                    <FatteningAnimalsAmountTable/>
                }
                {
                    show === 2 &&
                    <FatteningAnimalsAmountDetailedTable/>
                }
            </Card>
        </ViewContainerModal>
    )
}