import React, {useMemo, useRef} from "react";
import {useTranslation} from "react-i18next";
import {parseVehicleWeight} from "../parsers";
import DeviceDefaultContent from "./DeviceDefaultContent";
import {createKeysAndLabels} from "./utils";


const VehicleWeightContent = (props) => {
    const {t} = useTranslation();
    const {keys, labels} = useMemo(() => {
        return createKeysAndLabels(
            {
                key: "text",
                label: t("weight")
            }
        )
    }, [t]);

    const controls = useRef({VEHICLE_WEIGHT: true});

    return <DeviceDefaultContent {...props} enableSpecificControls={controls.current} showAddr={false}
                                 parseDataFn={parseVehicleWeight}
                                 name={t("vehicleWeight")}
                                 paramKeys={keys}
                                 paramLabel={labels}/>
}


export default React.memo(VehicleWeightContent);