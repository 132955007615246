import _ from "lodash";

const sortBy = require('sort-by');

export const initialValue = {
    farms: [], //lista ferm
    buildings: [], //lista budynkow
    fetching: false, //pobieranie budynkow
    fetched: false, //czy pobrano budynki
    adding: false, //czy aplikacja jest w trakcie dodawania lokacji
    updating: false, //czy jest w trakcie aktualizowania lokacji
    deleting: false, //czy jest w trakcie usuwania lokacji
    dbLoaded: false, //czy loki jest zaladowany
    testSwitch: null,
    subscriptionFilter: {
        active: true,
        month: true,
        week: true,
        inactive: true
    }
};

export default function farmsReducer(state = initialValue, action) {
    switch (action.type) {
        /**
         * Przy wejściu na farme pobierana jest lista Farm, wrzucamy ją do stora
         */
        case "GET_FARMS": {
            return {
                ...state,
                farms: action.payload
            }
        }
        case "GET_USER_REJECTED": {
            return {
                ...state,
                farms: []
            }
        }
        case "CHANGE_FARM": {
            return {
                ...state,
                buildings: []
            }
        }
        case "GET_ALL_BUILDINGS": {
            const building = _.cloneDeep(action.payload.sort(sortBy('BName')));
            return {
                ...state,
                fetching: false,
                fetched: true,
                buildings: building

            }
        }
        case "FETCH_BUILDINGS_BY_FARM_ID_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true,

            }
        }
        case "FETCH_BUILDINGS_BY_FARM_ID_PENDING": {
            return {...state, fetching: false}
        }

        case "FETCH_BUILDINGS_BY_FARM_ID_REJECTED": {
            return {...state, fetching: false, fetched: false, error: action.payload}
        }
        case "USER_LOGOUT_FULFILLED":
            return initialValue;
        case "ADD_LOCATION_PENDING": {
            return {
                ...state,
                adding: true
            }
        }
        case "ADD_LOCATION_REJECTED":
        case "ADD_LOCATION_FULFILLED": {
            return {
                ...state,
                adding: false
            }
        }
        case "UPDATE_LOCATION_PENDING": {
            return {
                ...state,
                updating: true
            }
        }
        case "UPDATE_LOCATION_REJECTED":
        case "UPDATE_LOCATION_FULFILLED": {
            return {
                ...state,
                updating: false
            }
        }
        case "DELETE_LOCATION_PENDING": {
            return {
                ...state,
                deleting: true
            }
        }
        case "DELETE_LOCATION_REJECTED":
        case "DELETE_LOCATION_FULFILLED": {
            return {
                ...state,
                deleting: false
            }
        }
        case "CHANGE_TEST_SWITCH":
            return {...state, testSwitch: action.payload};
        case "CHANGE_SUBSCRIPTION_FILTER_VALUE":
            return {...state,
                subscriptionFilter: {
                    ...state.subscriptionFilter,
                    [action.payload.field]: !state.subscriptionFilter[action.payload.field]
                }
            };
        default:
            return state
    }
}
