import React from "react";
import "./_card.scss"
import PropTypes from "prop-types";
import {isFunction} from "lodash";

const types = [50, 100, 200, 300, 400, 500, 600, 700, 800];

export default class Card extends React.Component {

    constructor(props) {
        super(props);
        const {onResize} = this.props;
        if (isFunction(onResize)) {
            this.ref = React.createRef();
        }
    }


    createObserver() {
        try {
            const {onResize} = this.props;
            let observer = new ResizeObserver(onResize);
            observer.observe(this.ref.current);
            return observer;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    componentDidMount() {
        const {onResize} = this.props;
        if (isFunction(onResize)) {
            this.observer = this.createObserver();
        }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    onContextMenu = e => {
        if (this.props.onContextMenu) {
            e.persist();
            e.preventDefault();
            this.props.onContextMenu(e);
        }
    };

    render() {
        const {
            cyData,
            children,
            onClick,
            colorDensity,
            type,
            shadow,
            className,
            style,
            color,
            forwardRef,
            id,
            selected,
            disabled,
            transparent
        } = this.props;
        const cName = ["fetura-card", disabled ? "disabled" : "", !disabled && onClick ? "pointer" : "", `type-${type}`, transparent ? '' : `color${color ? `-${color}` : ""}-${colorDensity}`, className, selected ? 'selected' : '', !shadow || transparent ? 'no-shadow' : '', transparent ? 'transparent' : ''].filter(o => o).join(" ");
        return (
            <div
                cy-data={cyData}
                style={style}
                className={cName}
                onClick={onClick} ref={this.ref || forwardRef} id={id} onContextMenu={this.onContextMenu}>
                {children}
            </div>
        );
    }

}

Card.propTypes = {
    type: PropTypes.oneOf(["col", "container", "row"]),
    colorDensity: PropTypes.oneOf(types),
    shadow: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    forwardRef: PropTypes.object,
    id: PropTypes.string,
    onContextMenu: PropTypes.func,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    transparent: PropTypes.bool,
    onResize: PropTypes.func,
    color: PropTypes.oneOf(["", "info", "primary"])
};

Card.defaultProps = {
    colorDensity: 50,
    type: "col",
    shadow: true,
    className: "",
    style: {},
    color: ""
};
