import _ from "lodash";

/**
 * W zwiazku z tym ze animalsy pobieramy glownie z lokiego zmienilem ten reducer aby byl uzyteczny
 * tzn
 * animals {array} lista wszystkich zwierzat na fermie - raczej bezuzyteczne i w przyszlosci do wywalenia
 * fetching - flaga czy pobierane sa jakies zwierzeta
 * time {number} czas pobrania najnowszego zwierzaka - DO TEGO LACZYMY SIE JESLI CHCEMY MIEC TRIGGER DO POBIERANIA ZWIERZAT Z LOKIEGO
 * @type {{animals: [], fetching: boolean, time: number}}
 */
export const initialValue = {
    fetching: false, //flaga czy sa pobierane zwierzeta (chyba bezuzyteczna)
    time: 0 //czas DtaModTime gdy pytyamy o zwierzeta - do tego najepiej sie podpiac gdy bierzemy prosto z lokiego -
};
export default function animalsReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_ANIMAL_MODIFICATION_TIME": {
            return {
                ...state,
                time: _.get(action.payload, "DtaModTime", 0)
            }
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        case "LIST_ANIMAL_PENDING": {
            return {
                ...state, fetching: true
            }
        }
        case "LIST_ANIMAL_FULFILLED":
        case "LIST_ANIMAL_REJECTED": {
            return {
                ...state,
                fetching: false
            }
        }
        default:
            return state
    }
}
