import {
    getCageUnit,
    getFeedingUnit,
    getScaleUnit,
    getVehicleWeightUnit,
    getWaterFlowUnit
} from "../../../utils/SettingsUtils";
import {convertEnergyUnitTo, convertVolumeUnitTo, convertWeightUnitTo} from "../../../utils/UnitUtils";
import {Defaults} from "./drawer-content/utils";
import {IntervalTimes} from "../../../constans/intervals";
import {isEqual, isNil, get, isFunction} from "lodash";
import {temperatureFormatter} from "../../../views/new-settings-view/climate/manage-sk3/settings/utils";
import {UnitTypes} from "../../../constans/unitTypes";
import {volumeFormatter} from "../../../utils/device-rows/SiloRadarUtils";

// todo: podpiac odpowiednie jednostki z ustaiwen

const isLive = (data) => (+new Date() - (data?._read || 0)) <= IntervalTimes.DEVICE_OUTDATED_DATA;

const parse = (data, deviceKey, params) => {
    const mandatoryKeys = Object.keys(params);
    const {array, isLive, isWarning} = prepareData(data, deviceKey, mandatoryKeys);
    const result = {isLive, isWarning};
    mandatoryKeys.forEach((key) => {
        const {name, fmt, func} = params[key];
        const value = func(array, key);
        if (value === null) {
            result[name] = Defaults.NOT_FOUND_TEXT;
        } else if (isFunction(fmt)) {
            result[name] = fmt(value);
        } else {
            result[name] = value;
        }
    });
    return result;
}

const prepareData = (data, type, mandatoryKeys = []) => {
    let arr = Array.isArray(data) ? data : [data];
    const beforeSize = arr.length;
    const isAlert = arr.some(o => o?.Alerts?.length);
    const isWarning = arr.some(o => o?.Warnings?.length);
    arr = arr.filter((o) => {
        if (!o) return false;
        if (o._type !== type) return false;
        if (!mandatoryKeys.length) return true;
        return mandatoryKeys.every(key => !isNil(o[key]))
    });
    const afterSize = arr.length;
    return {
        array: arr,
        isLive: beforeSize !== afterSize ? false : arr.every(o => isLive(o)),
        isAlert,
        isWarning
    }
}

/**
 * checks if given commonKey has same value between many data points and returns its value
 * difference between values => null returned
 * no data => null returned
 * @param data
 * @param commonKey
 * @return {null|*}
 */
const getCommonValue = (data, commonKey) => {
    if (data.length === 0) return null;
    const firstValue = get(data[0], commonKey);
    if (data.every((d) => isEqual(get(d, commonKey), firstValue))) {
        return isNil(firstValue) ? null : firstValue;
    }
    return null;
}


const _getSum = (data, commonKey) => {
    let sum = 0;
    let cnt = 0;
    data.forEach((d) => {
        const value = d[commonKey];
        if (isFinite(value)) {
            cnt++;
            sum += value;
        }
    })
    return {sum, cnt};
}
const getSummary = (data, commonKey) => {
    const {sum, cnt} = _getSum(data, commonKey);
    if (cnt === 0) return null;
    return sum;
}

const getAverage = (data, commonKey) => {
    const {sum, cnt} = _getSum(data, commonKey);
    if (cnt === 0) return null;
    return sum / cnt;
}

export const parseFeed = (_data) => {
    const {array, isLive, isWarning} = prepareData(_data, "FEED", ["Consumed", "PlannedConsumption"]);
    const unit = getFeedingUnit();
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isWarning
            }
        case 1:
            return {
                text: `${convertWeightUnitTo(array[0].Consumed, {
                    showUnit: false,
                    unit: unit,
                    fixed: unit ? 2 : 0
                })}/${convertWeightUnitTo(array[0].PlannedConsumption, {
                    showUnit: true,
                    unit: unit,
                    fixed: unit ? 2 : 0
                })}`,
                isLive,
                isWarning
            }
        default:
            return {
                text: `${convertWeightUnitTo(array.reduce((a, b) => a + b.Consumed, 0), {
                    showUnit: false,
                    unit: unit,
                    fixed: unit ? 2 : 0
                })}`,
                isLive,
                isWarning
            }
    }

}

export const parseClimate = (_data) => {
    const {array, isLive, isAlert, isWarning} = prepareData(_data, "CLIMATE", ["Temperature"]);
    const workType = getCommonValue(array, "WorkType");
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isAlert,
                isWarning,
                workType
            }
        case 1:
            return {
                text: `${temperatureFormatter(array[0].Temperature)}`,
                isLive,
                isAlert,
                isWarning,
                workType
            }
        default:
            return {
                text: `${temperatureFormatter(array.reduce((a, b) => a + b.Temperature, 0) / array.length)}`,
                isLive,
                isAlert,
                isWarning,
                workType
            }
    }
}

export const parseWater = (_data) => {
    const {array, isLive, isAlert, isWarning} = prepareData(_data, "WATER", ["Consumed"]);
    const unit = getWaterFlowUnit();
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isAlert,
                isWarning
            }
        case 1:
            return {
                text: `${convertVolumeUnitTo(array[0].Consumed, {
                    showUnit: true,
                    unit: unit,
                    fixed: 2
                })}`,
                isLive,
                isAlert,
                isWarning
            }
        default:
            return {
                text: `${convertVolumeUnitTo(array.reduce((a, b) => a + b.Consumed, 0), {
                    showUnit: true,
                    unit: unit,
                    fixed: 2
                })}`,
                isLive,
                isAlert,
                isWarning
            }
    }
}

export const parseElectric = (_data) => {
    const {array, isLive, isAlert, isWarning} = prepareData(_data, "ELECTRIC", ["Consumed"]);
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isAlert,
                isWarning
            }
        case 1:
            return {
                text: `${convertEnergyUnitTo(array[0].Consumed, {
                    showUnit: true,
                    unit: UnitTypes.MEDIUM,
                    fixed: 2
                })}`,
                isLive,
                isAlert,
                isWarning
            }
        default:
            return {
                text: `${convertEnergyUnitTo(array.reduce((a, b) => a + b.Consumed, 0), {
                    showUnit: false,
                    unit: UnitTypes.MEDIUM,
                    fixed: 2
                })}`,
                isLive,
                isAlert,
                isWarning
            }
    }
}

export const parseRadar = (_data, {showCapacity = false} = {}) => {
    const {array, isLive, isAlert, isWarning} = prepareData(_data, "SILORADAR", ["Volume"]);
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isAlert,
                isWarning
            }
        case 1:
            const show = showCapacity ? !!array[0].Capacity : false;
            return {
                text: `${volumeFormatter(array[0].Volume, {
                    showUnit: !show
                })}${show ? "/" + volumeFormatter(array[0].Capacity, {
                    showUnit: show
                }) : ""} `,
                isLive,
                isAlert,
                isWarning
            }
        default:
            return {
                text: `${volumeFormatter(array.reduce((a, b) => a + b.Volume, 0), {
                    showUnit: true
                })}`,
                isLive,
                isAlert,
                isWarning
            }
    }
}

export const parseRelay = (_data) => {
    const {array, isLive, isAlert, isWarning} = prepareData(_data, "RELAY", ["State"]);
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isAlert,
                isWarning
            }
        default:
            return {
                text: [array.filter((s) => s.State).length, Array.isArray(_data) ? _data.length : 1].join("/"),
                isLive,
                isAlert,
                isWarning
            }
    }
}

export const parseVehicleWeight = (_data) => {
    const {array, isLive, isAlert, isWarning} = prepareData(_data, "VEHICLE_WEIGHT", ["Weight"]);
    const unit = getVehicleWeightUnit();
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isAlert,
                isWarning
            }
        case 1:
            return {
                text: convertWeightUnitTo(array[0].Weight, {
                    showUnit: true,
                    unit: unit,
                    fixed: unit
                }),
                isLive,
                isAlert,
                isWarning
            }
        default:
            return {
                text: `${convertWeightUnitTo(array.reduce((a, b) => a + b.Weight, 0), {
                    showUnit: true,
                    unit: unit,
                    fixed: unit
                })}`,
                isLive,
                isAlert,
                isWarning
            }
    }
}


export const parseSilo = (_data, {showCapacity = false} = {}) => {
    const {array, isLive, isAlert, isWarning} = prepareData(_data, "SILO", ["Weight"]);
    const unit = getScaleUnit();
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isAlert,
                isWarning
            }
        case 1:
            const show = showCapacity ? !!array[0].Capacity : false;
            return {
                text: `${convertWeightUnitTo(array[0].Weight, {
                    showUnit: !show,
                    unit: unit,
                    fixed: unit
                })}${show ? "/" + convertWeightUnitTo(array[0].Capacity, {
                    showUnit: show,
                    unit: unit,
                    fixed: unit
                }) : ""} `,
                isLive,
                isAlert,
                isWarning
            }
        default:
            return {
                text: `${convertWeightUnitTo(array.reduce((a, b) => a + b.Weight, 0), {
                    showUnit: true,
                    unit: unit,
                    fixed: unit
                })}`,
                isLive,
                isAlert,
                isWarning
            }
    }
}

export const parseIpsum = (...args) => {
    const unit = getFeedingUnit();
    return parse(args[0], "IPSUM", {
        Weight: {
            func: getAverage,
            name: "text",
            fmt: (v) => `${convertWeightUnitTo(v, {
                showUnit: true,
                unit: unit,
                fixed: unit
            })}`
        },
        FeederStatus: {
            func: getCommonValue,
            name: "textFeederStatus"
        },
        DispenserStatus: {
            func: getCommonValue,
            name: "textDispenserStatus"
        }
    })
};

export const parseNutriProV2 = (...args) => {
    const unit = getFeedingUnit();
    return parse(args[0], "NUTRIPROV2", {
        Consumed: {
            func: getSummary,
            name: "text",
            fmt: (v) => `${convertWeightUnitTo(v, {
                showUnit: true,
                unit: unit,
                fixed: unit
            })}`
        },
        FeederStatus: {
            func: getCommonValue,
            name: "textFeederStatus"
        },
        DispenserAStatus: {
            func: getCommonValue,
            name: "textDispenserAStatus"
        },
        DispenserBStatus: {
            func: getCommonValue,
            name: "textDispenserBStatus"
        }
    })
};

export const parseNutriPro = (...args) => {
    const unit = getFeedingUnit();
    return parse(args[0], "NUTRIPRO", {
        Consumed: {
            func: getSummary,
            name: "text",
            fmt: (v) => `${convertWeightUnitTo(v, {
                showUnit: true,
                unit: unit,
                fixed: unit
            })}`
        },
        FeederStatus: {
            func: getCommonValue,
            name: "textFeederStatus"
        },
        DispenserStatus: {
            func: getCommonValue,
            name: "textDispenserStatus"
        }
    })
};

export const parseCage = (_data) => {
    const {array, isLive, isAlert, isWarning} = prepareData(_data, "CAGE", ["AverageWeight"]);
    const unit = getCageUnit();
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isAlert,
                isWarning,
                cageState: []
            }
        case 1:
            const {Entrance, Middle, Exit} = array[0];
            return {
                text: `${convertWeightUnitTo(array[0].AverageWeight, {
                    showUnit: true,
                    unit: unit,
                    fixed: unit
                })}`,
                isLive,
                isAlert,
                isWarning,
                cageState: [Entrance, Middle, Exit]
            }
        default:
            return {
                text: `${convertWeightUnitTo(array.reduce((a, b) => a + b.AverageWeight, 0) / array.length, {
                    showUnit: true,
                    unit: unit,
                    fixed: unit
                })}`,
                isLive,
                isAlert,
                isWarning,
                cageState: []
            }
    }
}

export const parseGroup = (_data) => {
    const {array, isLive, isAlert, isWarning} = prepareData(_data, "GROUP");
    switch (array.length) {
        case 0:
            return {
                text: Defaults.NOT_FOUND_TEXT,
                isLive,
                isAlert,
                isWarning
            }
        default:
            return {
                text: array.some(o => o.AnimalID) ? '✓' : '×',
                isLive,
                isAlert,
                isWarning
            }
    }
}
