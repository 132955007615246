import Collision from "./Collision";
import {
    LEFT_BOTTOM,
    LEFT_MIDDLE,
    LEFT_TOP,
    MIDDLE_BOTTOM,
    MIDDLE_MIDDLE,
    MIDDLE_TOP,
    RIGHT_BOTTOM,
    RIGHT_MIDDLE,
    RIGHT_TOP
} from "./Align";
import {isBetween} from "../../../utils/MathUtils";

export default class CircleParser {

    getSVGTextName() {
        return "circle"
    }

    static getMemoKey({cx, cy, r}){
        return cx+'_'+cy+'_'+r;
    }


    constructor({cx, cy, r} = {}) {
        this.cx = +cx;
        this.cy = +cy;
        this.r = +r;
    }

    getParams() {
        return {r: this.r, cx: this.cx, cy: this.cy}
    }

    getCenter() {
        return {x: this.cx, y: this.cy}
    }

    getRect() {
        let minY = this.cy - this.r, minX = this.cx - this.r, maxY = this.cy + this.r, maxX = this.cx + this.r;
        return {
            minX,
            maxY,
            maxX,
            minY,
            x: minX,
            y: minY,
            width: Math.abs(maxX - minX),
            height: Math.abs(maxY - minY)
        }
    }


    getRectPoints() {
        const rect = this.getRect();
        return [
            {x: rect.minX, y: rect.minY, position: "topLeft"},
            {x: rect.maxX, y: rect.minY, position: "topRight"},
            {x: rect.maxX, y: rect.maxY, position: "bottomRight"},
            {x: rect.minX, y: rect.maxY, position: "bottomLeft"},
        ]
    }

    getMiddleCrossPoints() {
        const center = this.getCenter();
        const rect = this.getRect();
        return [
            {x: center.x, y: rect.minY, position: "top"},
            {x: center.x, y: rect.maxY, position: "bottom"},
            {x: rect.minX, y: center.y, position: "left"},
            {x: rect.maxX, y: center.y, position: "right"},
        ]
    }

    getCompassRosePoints() {
        return [...this.getRectPoints(), ...this.getMiddleCrossPoints()];
    }

    scale(n, cx, cy) {
        return this._scaleN(n, 1, 1, cx, cy);
    }

    scaleWithSign(n, signX = 1, signY = 1, cx, cy) {
        return this._scaleN(n, signX > 0 ? 1 : -1, signY > 0 ? 1 : -1, cx, cy);
    }


    _scaleN(scale, signX = 1, signY = 1, cx, cy) {
        let center = this.getCenter()
        cx = typeof cx !== 'undefined' ? cx : center.x;
        cy = typeof cy !== 'undefined' ? cy : center.y;
        this.cx = cx + (this.cx - cx) * signX * scale;
        this.cy = cy + (this.cy - cy) * signY * scale;
        this.r = Math.abs(this.r * scale);
        return this;
    }

    setRadius(radius) {
        this.r = radius;
    }

    translate(moveX, moveY) {
        this.cx += moveX;
        this.cy += moveY;
        return this;
    }

    getVertices() {
        const vertices = [];
        const angle = 12.25;
        const max = 360 / angle;
        for (let i = 0; i < max; i++) {
            const [x, y] = [this.r * Math.sin(i * angle), this.r * Math.cos(i * angle)]
            vertices.push({
                x: x + this.cx,
                y: y + this.cy,
                index: i
            })
        }
        return vertices;
    }

    getPoly() {
        return this.getVertices();
    }

    collidesWith(object) {
        return Collision.isCollision(this, object);
    }

    getArea() {
        return Math.PI * Math.pow(this.r, 2);
    }

    getPointByAngle(angle) {
        const radians = Math.PI * angle / 180;
        return {
            x: this.r * Math.cos(radians) + this.cx,
            y: this.r * Math.sin(radians) + this.cy
        }
    }

    getTextPosition() {
        const leftTop = this.getPointByAngle(-135);
        const rightBottom = this.getPointByAngle(45);
        return {
            x1: leftTop.x,
            x2: rightBottom.x,
            y1: leftTop.y,
            y2: rightBottom.y,
            width: rightBottom.x - leftTop.x,
            height: rightBottom.y - leftTop.y
        }
    }

    isRect() {
        return false;
    }

    isRotatedRect() {
        return false;
    }

    /**
     *
     * @param oldCanvasW
     * @param oldCanvasH
     * @param newCanvasW
     * @param newCanvasH
     * @param align 0-8 (stick to, 0 - top left, 1 - top middle ... 8 bottom right)
     */
    transformCanvasResize(oldCanvasW, oldCanvasH, newCanvasW, newCanvasH, align = LEFT_TOP) {
        const diffX = newCanvasW - oldCanvasW;
        const diffY = newCanvasH - oldCanvasH;

        let addX = 0;
        let addY = 0;
        switch (align) {
            case RIGHT_BOTTOM:
            case RIGHT_MIDDLE:
            case RIGHT_TOP:
                addX = diffX;
                break;
            case MIDDLE_BOTTOM:
            case MIDDLE_MIDDLE:
            case MIDDLE_TOP:
                addX = diffX / 2;
                break;
            default:
                break;
        }
        switch (align) {
            case RIGHT_BOTTOM:
            case LEFT_BOTTOM:
            case MIDDLE_BOTTOM:
                addY = diffY;
                break;
            case LEFT_MIDDLE:
            case MIDDLE_MIDDLE:
            case RIGHT_MIDDLE:
                addY = diffY / 2;
                break;
            default:
                break;
        }
        if (addY === addX && addX === 0) {
            return this;
        } else {
            this.translate(addX, addY);
            return this;
        }
    }

    paste(obj) {
        if (obj instanceof CircleParser) {
            this.r = obj.r;
            return this;
        }
        return this;
    }

    getPath2D() {
        const circle = new Path2D();
        circle.moveTo(this.cx + this.r, this.cy);
        circle.arc(this.cx, this.cy, this.r, 0, 2 * Math.PI);
        return circle;
    }

    isVisibleInViewport(viewPortX1, viewPortX2, viewPortY1, viewPortY2) {
        const rect = this.getRect();
        const isHorizontalBetween = isBetween(rect.minX, viewPortX1, viewPortX2) || isBetween(rect.maxX, viewPortX1, viewPortX2);
        if (!isHorizontalBetween) return false;
        return isBetween(rect.maxY, viewPortY1, viewPortY2) || isBetween(rect.minY, viewPortY1, viewPortY2);
    }


    getInsideRectParams() {
        const rect = this.getRect();
        return {
            rect: {
                x1: rect.x,
                x2: rect.maxX,
                y1: rect.y,
                y2: rect.maxY,
                width: rect.width,
                height: rect.height
            }
        }
    }

    useDecimals() {
        this.cx = Math.round(this.cx);
        this.cy = Math.round(this.cy);
        this.r = Math.round(this.r);
        return this;
    }

    getCustomSetter() {
        return {
            radius: this.r
        }
    }


    setUsingCustomSetter({radius} = {}) {
        this.r = radius;
        return this;
    }

}

