import {findIndex} from "lodash";

export const initialValue = {
    files: [],
    loading: false,
    path: "",
    selected: null,
    uploadFiles: [],
    view: "THUMBNAILS",
    sort: {column: "FileName", type: "ASC"},
    directoryPicker: {path: "", files: [], loading: false},
    menu: {event: null, file: null, fromLastFiles: false},
    show: {
        file: null,
        fromLastFile: false
    },
    lastModified: {
        files: [],
        loading: false
    },
    directories: {
        files: [],
        loading: false
    },
    ocr: {
        files: [],
        loading: false
    }
};

export default function feedingReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_CREATE_PROTOCOLS_PENDING":
        case "GET_FILES_PENDING": {
            return {...state, loading: action.meta.showLoading}
        }
        case "GET_CREATE_PROTOCOLS_REJECTED":
        case "GET_FILES_REJECTED": {
            return {...state, loading: false, files: []}
        }
        case "GET_FILES_FULFILLED": {
            let selected = null;
            if (state.selected) {
                selected = action.payload.find(item => item.FileID === state.selected.FileID) || null;
            }
            let files = action.payload.sort((a, b) => b.Type === "DIR" ? 1 : -1);
            return {
                ...state,
                loading: false,
                files,
                selected,
                directoryPicker: {...state.directoryPicker, files: files.filter(item => item.Type === "DIR")}
            } // sortowanie pierwsze katalogi
        }
        case "CHANGE_FILE_PATH": {
            return {...state, path: action.payload, directoryPicker: {...state.directoryPicker, path: action.payload}}
        }
        case "CHANGE_SELECTED_FILE": {
            return {...state, selected: action.payload}
        }
        case "START_FILE_UPLOAD": {
            const uploadFiles = state.uploadFiles.slice(0);
            const {file, xhr} = action.payload;
            uploadFiles.push({file, progress: 0, xhr});
            return {...state, uploadFiles};
        }
        case "UPDATE_FILE_UPLOAD_PROGRESS": {
            const uploadFiles = state.uploadFiles.slice(0);
            const {file, event} = action.payload;
            let index = findIndex(uploadFiles, o => o.file === file);
            uploadFiles[index] = {...uploadFiles[index], progress: event.loaded / event.total * 100};
            return {...state, uploadFiles};
        }
        case "CANCEL_FILE_UPLOAD": {
            const uploadFiles = state.uploadFiles.slice(0);
            let index = findIndex(uploadFiles, o => o.file === action.payload);
            uploadFiles.splice(index, 1);
            return {...state, uploadFiles};
        }
        case "ERROR_FILE_UPLOAD": {
            const uploadFiles = state.uploadFiles.slice(0);
            let index = findIndex(uploadFiles, o => o.file === action.payload);
            uploadFiles[index] = {...uploadFiles[index], error: true, progress: 100};
            return {...state, uploadFiles};
        }
        case "CHANGE_FILE_VIEW": {
            return {...state, view: action.payload};
        }
        case "SET_FILE_SORT": {
            return {...state, sort: action.payload};
        }
        case "REMOVE_FILE_FROM_LIST": {
            let selected = null;
            if (state.selected && state.selected.FileID !== action.payload) {
                selected = state.selected;
            }
            return {...state, files: state.files.filter(item => item.FileID !== action.payload), selected}
        }
        case "ADD_FILE_TO_LIST": {
            const files = state.files.slice(0);
            files.push(action.payload);
            return {...state, files}
        }
        case "CHANGE_FOLDER_PICKER_PATH": {
            return {...state, directoryPicker: {...state.directoryPicker, path: action.payload}};
        }
        case "GET_FOLDER_PICKER_FILES_PENDING": {
            return {...state, directoryPicker: {...state.directoryPicker, loading: true}};
        }
        case "GET_FOLDER_PICKER_FILES_FULFILLED": {
            return {...state,
                directoryPicker: {
                    ...state.directoryPicker,
                    loading: false,
                    files: action.payload.filter(item => item.Type === "DIR")
                }
            };
        }
        case "GET_FOLDER_PICKER_FILES_REJECTED": {
            return {...state, directoryPicker: {...state.directoryPicker, loading: false, files: []}};
        }
        case "RESTORE_DIRECTORY_PICKER_PATH": {
            return {...state,
                directoryPicker: {
                    ...state.directoryPicker,
                    path: state.path,
                    files: state.files.filter(item => item.Type === "DIR")
                }
            }
        }
        case "SELECT_FILE_FOR_MENU": {
            return {...state, menu: action.payload};
        }
        case "SHOW_FILE": {
            return {
                ...state,
                show: {...state.show, file: action.payload.file, fromLastFile: action.payload.fromLastFiles}
            };
        }
        case "GET_LAST_MODIFIED_FILES_PENDING": {
            return {...state, lastModified: {...state.lastModified, loading: true}};
        }
        case "GET_LAST_MODIFIED_FILES_REJECTED": {
            return {...state, lastModified: {...state.lastModified, loading: false, files: []}};
        }
        case "GET_LAST_MODIFIED_FILES_FULFILLED": {
            return {...state, lastModified: {...state.lastModified, files: action.payload, loading: false}};
        }
        case "GET_ALL_DIRECTORIES_PENDING": {
            return {...state, directories: {...state.directories, loading: true}};
        }
        case "GET_ALL_DIRECTORIES_REJECTED": {
            return {...state, directories: {...state.directories, loading: false, files: []}};
        }
        case "GET_ALL_DIRECTORIES_FULFILLED": {
            return {...state, directories: {...state.directories, loading: false, files: action.payload}};
        }
        case "REMOVE_FILE_UPLOAD": {
            const uploadFiles = state.uploadFiles.slice(0);
            uploadFiles.splice(action.payload, 1);
            return {...state, uploadFiles};
        }
        case "GET_FILES_OCR_PENDING":
            return {...state, ocr: {...state.ocr, loading: true}};
        case "GET_FILES_OCR_FULFILLED":
            return {...state, ocr: {...state.ocr, files: action.payload, loading: false}};
        case "GET_FILES_OCR_REJECTED":
            return {...state, ocr: {...state.ocr, loading: false}};
        case "CLEAR_FILES":
            return {...state, files: []}
        case "GET_CREATED_PROTOCOLS_FULFILLED":
            return {...state, files: action.payload.items, path: action.payload.path}
        case "USER_LOGOUT":
        case "CHANGE_FARM":
            return initialValue;
        default:
            return state;
    }
}
