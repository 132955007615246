import { cloneDeep } from "lodash";
import { SettingTypes } from "../constans/settingTypes";
import store from "../store/store";
import { getModificationTime, insertInto, setModificationTime } from "../utils/LokiUtils";
import lokiDB from "./lokiDB";

class Settings {
    /**********************************
     ***         Settings           ***
     **********************************/

    getModificationTime(farmID) {
        return getModificationTime("settings", "FarmID", farmID);
    }

    insertIntoSettings(values) {
        insertInto(values, lokiDB.settings, "SetID");
        setModificationTime("settings", values[values.length - 1].DtaModTime, "FarmID", values[values.length - 1].FarmID);
    }

    getLookSettings() {
        try {
            return lokiDB.settings.findOne({ look: { $type: "object" } }).look;
        } catch (e) {
            console.error(e);
            return {
                // TODO wstawic domyslne wartosci dla wygladu
            }
        }
    }

    setLookSettings(values) {
        try {
            let res = this.getLookSettings();
            res.look = values;
            this.insertIntoSettings([res]);
            return res.look
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * Potrzebne do workera, nie używać normalnie
     * @return {*}
     */
    getAllSettingsWithoutFarmID() {
        try {
            return [...lokiDB.settings.find({ DtaDelTime: { $type: "undefined" } }), ...lokiDB.settings.find({
                SetType: SettingTypes.FEEDING_FORAGE,
                DtaDelTime: { $type: 'number' }
            })];
        } catch (e) {
            return [];
        }
    }

    getAllSettings(FarmID, { showDeleted = false } = {}) {
        try {
            let findObj = {};
            if (!showDeleted) {
                findObj = {
                    ...findObj,
                    DtaDelTime: { $type: "undefined" }
                }
            }
            const farmSettings = lokiDB.settings.find({ ...findObj, FarmID });
            const notificationSettings = lokiDB.settings.find({
                ...findObj,
                SetType: SettingTypes.NOTIFICATIONS
            });
            const removedForages = lokiDB.settings.find({
                ...findObj, FarmID, SetType: SettingTypes.FEEDING_FORAGE,
                DtaDelTime: { $type: "number" }
            });
            return [
                ...farmSettings,
                ...notificationSettings,
                ...removedForages
            ];
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getSettingByID(SetID, { showDeleted = false } = {}) {
        try {
            const params = showDeleted ? {} : { DtaDelTime: { $type: "undefined" } };
            const setting = lokiDB.settings.findOne({ SetID: SetID, ...params });
            if (!setting) return undefined;
            return !setting || !SetID ? undefined : setting;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async deleteFromSettings(values) {
        values.map(item => {
            let data = lokiDB.settings.find({ SetID: item.SetID });
            if (data.length > 0) {
                let loki = cloneDeep(data[0].$loki);
                let meta = cloneDeep(data[0].meta);
                data[0] = cloneDeep(item);
                data[0].$loki = loki;
                data[0].meta = meta;
                lokiDB.settings.remove(data[0]);
            }
        });
        let cached = this.getModificationTime(values[0].FarmID);
        console.log(cached);
        if (!cached.table) {
            cached.DtaModTime = values[values.length - 1].DtaModTime;
            cached.table = 'settings';
            lokiDB.cachedTablesParams.remove(cached)
        } else {
            cached.DtaModTime = values[values.length - 1].DtaModTime;
            lokiDB.cachedTablesParams.update(cached);
        }
        // await lokiDB.saveDB();

    }


    getSettingByIndexAndType(index, type, farmID) {
        let activeFarmID = farmID || store.getState().location.farm;
        let allSettings = lokiDB.settings.find({ FarmID: activeFarmID, SetType: type, DtaDelTime: { $type: 'undefined' } });
        return allSettings.find(item => item.SetData.Index === index);
    }

    getFarmMap(FarmID) {
        let map = lokiDB.settings.findOne({
            SetID: `farm_map_${FarmID}`,
            DtaDelTime: { $type: "undefined" }
        });
        if (!map || !map.SetData.Levels) return null;
        return map;
    }

}

const settingsDB = new Settings();
export default settingsDB;
