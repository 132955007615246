import React, {Component} from 'react';
import moment from "moment";
import Input from "../../input/Input";
import PropTypes from "prop-types";
import {debounce, isNil} from "lodash";

class InputFilter extends Component {

    state = {
        value: this.props.value
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.value === prevState.value && this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value
            })
        }
    }

    /**
     * Metoda, która wysyła zmianę do filtrów na doubounce, żeby zmiana była wykrywana tylko, gdy użytkownik nie zmienia
     * gwałtownie wartości przytrzymując strzałkę w górę lub w dół. Zwiększa znacząco płynność działania grida.
     */
    sendFilter = debounce((value) => {
        this.props.onChange(value);
    }, 300);

    onChange = value => {
        const {type, allowFloat, maxWidth} = this.props;
        if (value === "") value = null;
        if ((type === "date" || type === "datetime-local") && !(value instanceof moment)) {
            let date = moment(value)
            value = date.isValid() ? date : this.state.value;
        }
        if (this.props.type === "number") {
            let val = parseFloat(value);
            if (isNaN(val)) {
                value = null;
            } else {
                value = allowFloat ? val : Math.floor(val)
            }
        }
        if (maxWidth && value !== null) {
            value += "";
            value = value && value.length > maxWidth ? value.slice(0, maxWidth) : value;
        }
        this.setState({
            value
        });
        this.sendFilter(value);
    }

    onKeyDown = e => {
        if (this.props.type === "date") {
            if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
                let value = this.state.value ? this.state.value.clone() : moment();
                if (e.key === "ArrowUp") {
                    value.add(1, "day");
                } else if (e.key === "ArrowDown") {
                    value.subtract(1, "day");
                }
                this.onChange(value);
                e.stopPropagation();
                e.preventDefault();
            }
        }
        return false;
    }

    getValue(value) {
        if (isNil(value) || value === "") return "";
        const {type} = this.props;
        switch (type) {
            case "date":
                return value.format(moment.HTML5_FMT.DATE);
            case "datetime-local":
                return value.format(moment.HTML5_FMT.DATETIME_LOCAL);
            default:
                return value;
        }
    }

    render() {
        const {value} = this.state;
        const {type, placeholder, onFocus} = this.props;
        return (
            <Input type={type} value={this.getValue(value)} onChange={this.onChange} onKeyDown={this.onKeyDown}
                   placeholder={placeholder} onFocus={onFocus}/>
        );
    }

}

InputFilter.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(moment)]),
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(["text", "number", "date", "datetime-local"]).isRequired,
    placeholder: PropTypes.string,
    allowFloat: PropTypes.bool,
    maxWidth: PropTypes.number,
    onFocus: PropTypes.func,
}

InputFilter.defaultProps = {
    allowFloat: true
}

export default InputFilter;
