import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getActiveAnimals} from "../../../selectors/animalSelector";
import Select from "../../basics/select/Select";
import {useTranslation} from "react-i18next";
import {enhancedComparer} from "../../../utils/TextUtils";
import {isVirtualPlacement, normalizePlacement} from "../../../utils/LocationUtils";
import store from "../../../store/store";
import {notify} from "reapop";
import AnimalsWithNoLocation from "./AnimalsWithNoLocation";

const SearchAnimal = ({onSearch, mobile}) => {
    const {t} = useTranslation();
    const animals = useSelector(getActiveAnimals);

    const options = useMemo(() => {
        const _options = animals.map(({AnmNo1, PlcmntID}) => {
            return {
                name: AnmNo1,
                value: normalizePlacement(PlcmntID, {revertVirtualPlacements: false})
            }
        });
        _options.sort((a1, a2) => enhancedComparer(a1.name, a2.name, {numeric: true}))
        return _options;
    }, [animals]);

    const onChange = useCallback((value) => {
        // show user a toast informing about outdated placement
        if (isVirtualPlacement(value)) {
            store.dispatch(notify({
                title: t("lastKnownLocation"),
                dismissible: true,
                status: "info",
                position: "top-center",
                dismissAfter: 2000
            }));
        }
        onSearch(normalizePlacement(value));
    }, [onSearch, t]);

    return (
        <div className={"d-flex"}>
            <AnimalsWithNoLocation mobile={mobile}/>
            <div className={"w-100"}>
                <Select clearButton={false}
                        mobile={false}
                        placeholder={mobile ? t("farms.tabs.mapTab.searchAnimals") : t("farms.tabs.mapTab.searchLocationByAnimal")}
                        onChange={onChange}
                        options={options}/>
            </div>
        </div>
    )

}

export default SearchAnimal;