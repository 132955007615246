import {useSelector} from "react-redux";
import {getSelectedAnimalForDocuments} from "../../../selectors/animalDocumentsSelectors";
import {useMemo} from "react";
import {getOtherWeightsSelector} from "../../../selectors/settingsSelector";
import {localMomentToUTC} from "../../../utils/DateTimeUtils";
import moment from "moment";
import {getBirthDateFromAnimalGroup} from "../../../utils/AnimalsUtils";
import {fixAnimalTimespan} from "./charts/utils";

export default function useSingleAnimalDomain() {

    const animal = useSelector(getSelectedAnimalForDocuments);

    // czas urodzenia świni (czas lokalny)
    const birthTime = useMemo(() => {
        return getBirthDateFromAnimalGroup([animal]);
    }, [animal]);
    // czas końca swini (czas w utc)
    const end = useMemo(() => animal.DtaDthTime || animal.DtaDelTime || +moment.utc().startOf("day"), [animal]);
    // tablica wagi świń w poszczególnych tygodniach
    const weeks = useSelector(getOtherWeightsSelector).length;

    return useMemo(() => {
        const birthUTC = localMomentToUTC(birthTime);
        // czas urodzenia,               czas do kiedy mamy wyrysowany wykres wag,          czas końca grupy
        return fixAnimalTimespan([+birthUTC, Math.max(+birthUTC.startOf("day").add(weeks, "week"), end)])
    }, [weeks, end, birthTime]);
}