import React from "react";
import PropTypes from "prop-types";
import "./_header.scss";
import {isEqual} from "lodash";
import Button from "../button/Button";
import Menu from "../menu/Menu";
import MenuItem from "../menu/MenuItem";
import {isMobile} from "../../../utils/MobileUtils";
import {connect} from "react-redux";
import Tooltip from "../tooltip/Tooltip";

function mapStateToProps(state) {
    return {
        lang: state.language.lang.lang
    }
}

export class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: props.active === undefined ? 0 : props.active,
            maxItems: props.tabs.length,
            widthsOfItems: [],
            event: null,
            mobile: isMobile()
        };
        this.tabContainer = React.createRef();
        this.tabs = [];
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !isEqual(this.state, nextState) || !isEqual(this.props.text, nextProps.text) || !isEqual(this.props.lang, nextProps.lang) || !isEqual(this.props.tabs, nextProps.tabs);
    }

    getTabOffset = () => {
        try {
            return (document.getElementsByClassName("menu-button-container").item(0)?.current?.clientWidth || 0);
        } catch (e) {
            console.error(e);
            return 0;
        }
    };

    onTabsContainerResize = e => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            try {
                let tabContainerWidth = this.tabContainer ? this.tabContainer?.current?.clientWidth : 0;
                let width = 0;
                let maxItems = 0;
                for (let i = 0; i < this.tabs.length; i++) {
                    let w = this.tabs[i]?.current?.clientWidth;
                    width += w;
                    if (width < (tabContainerWidth - this.getTabOffset())) {
                        maxItems++;
                    }
                }
                this.setState({
                    maxItems,
                    timestamp: e.timestamp
                })
            } catch (e) {
                console.error(e);
            }
        }, 100);
    };

    componentDidMount() {
        window.addEventListener("resize", this.onTabsContainerResize);
        this.onTabsContainerResize({timestamp: 0});
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.active !== nextProps.active) {
            this.setState({
                active: nextProps.active
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onTabsContainerResize);
        clearTimeout(this.timer);
    }

    onTabClick = (key) => {
        this.setState({
            active: key
        });
        this.props.onTabChange(key);
    };

    onTabsButtonClick = e => {
        this.setState({
            event: e
        })
    };

    renderTabs = () => {
        const {tabs} = this.props;
        const {maxItems, event, timestamp} = this.state;
        let renderedTabs = [], menuTabs = [];
        for (let i = 0; i < tabs.length; i++) {
            this.tabs[i] = React.createRef();
            renderedTabs.push((
                <Button
                    timestamp={timestamp}
                    type={"button"}
                    buttonStyle={"text"}
                    className={(this.state.active === i ? "active" : "")}
                    onClick={() => this.onTabClick(i)}
                    buttonRef={this.tabs[i]}
                    key={i}
                    disabled={tabs[i].disabled}
                >
                    <span>
                        {tabs[i].name}
                        {tabs[i].badge && <div className={`info-badge ${tabs[i].badge}`}>{tabs[i].badge}</div>}
                    </span>
                </Button>
            ));
        }
        if (maxItems !== tabs.length) {
            for (let i = maxItems; i < tabs.length; i++) {
                menuTabs.push((
                    <MenuItem timestamp={timestamp} key={`menuitem_${i}`} selected={this.state.active === i}>
                        <Button disabled={tabs[i].disabled} buttonColor={"menu-color"} stopPropagation={false}
                                type={"button"} onClick={() => this.onTabClick(i)}>
                            {tabs[i].name}
                            {tabs[i].badge && <div className={`info-badge ${tabs[i].badge}`}>{tabs[i].badge}</div>}
                        </Button>
                    </MenuItem>
                ));
            }
        }
        return (
            <React.Fragment>
                {renderedTabs}
                {
                    menuTabs.length !== 0 &&
                    <>
                        <div className={"menu-button-container"}>
                            <Button buttonStyle={"text"} className={"menu-button"}
                                    icon={<i className="fas fa-ellipsis-v"/>}
                                    onClick={this.onTabsButtonClick}/>
                        </div>
                        <Menu event={event} className="header-menu">
                            {menuTabs}
                        </Menu>
                    </>
                }
            </React.Fragment>
        )
    };

    getTooltipContent() {
        const {text} = this.props;
        let array = Array.isArray(text) ? text : [text];
        for (let item of array) {
            if (typeof item !== "object") return item;
            let t = item.props.children.find(child => typeof child !== "object");
            if (t) return t;
        }
    }

    render() {
        const {text, tabs, tabsOnMobile} = this.props;
        const {mobile} = this.state;
        let cName = mobile ? "mobile" : null;
        if (tabsOnMobile) {
            cName += " tabs-on-mobile";
        }
        return (
            <React.Fragment>
                <header className={cName} id={"app-header"}>
                    <h2>
                        {
                            mobile &&
                            <Tooltip tooltipContent={this.getTooltipContent()} placement={"bottom"}>
                                <div>{text}</div>
                            </Tooltip>
                        }
                        {
                            !mobile && <div>{text}</div>
                        }
                    </h2>
                    {
                        tabs.length > 0 &&
                        <div className="tabs" ref={this.tabContainer}>
                            {this.renderTabs()}
                        </div>
                    }
                </header>
            </React.Fragment>
        );
    }

}

Header.propTypes = {
    text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired
    })),
    onTabChange: PropTypes.func,
    active: PropTypes.number,
    tabsOnMobile: PropTypes.bool,
};

Header.defaultProps = {
    tabs: [],
    onTabChange: (key) => {
    }
};

export default connect(mapStateToProps)(Header);
