import React from 'react';

export default function TimeCounterItem({counter, name}) {
    return (
        <div className="time-counter-item">
            <div>{(counter + "").padStart(2, "0")}</div>
            <div>{name}</div>
        </div>
    );
};
