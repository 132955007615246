import React, {Component} from 'react';
import Tooltip from "../../basics/tooltip/Tooltip";
import {withTranslation} from "react-i18next";

class DoneHeaderComponent extends Component {

    getTooltipContent = () => {
        const {t, showDoneTooltip} = this.props;
        const className = "d-flex justify-content-start align-items-center";
        if (showDoneTooltip) {
            return (
                <>
                    <div className={className}><i className="fas fa-fw fa-check success me-1"/> - {t("done")}</div>
                    <div className={className}><i className="fas fa-fw fa-clock info me-1"/> - {t("newReports.dispensersUsage.planned")}</div>
                    <div className={className}><i className="fas fa-fw fa-times error me-1"/> - {t("animalCard.undone")}</div>
                </>
            )
        }
        return "";
    };

    render() {
        const {name} = this.props;
        const tooltipContent = this.getTooltipContent();
        return (
            <div>
                {name}&nbsp;
                <Tooltip
                    tooltipContent={tooltipContent}>
                    <i className="fas fa-info-circle"/>
                </Tooltip>
            </div>
        );
    }
}

export default withTranslation()(DoneHeaderComponent);
