/**
 * @constant
 * @type {{SUCCESS: number, ERROR: number, WARN: number}}
 */
export const CycleStatus = {
    SUCCESS: 0,
    WARN: 1,
    ERROR: 2,
};

export const SelectedItemStatus = {
    PREGNANCY_PENDING: 0,
    PARTURITION_TOO_EARLY: 1,
    PARTURITION_TOO_LATE: 2,
    PARTURITIONED: 3,
    REPETITION: 4,
    NEGATIVE_USG: 5,
    NO_PREGNANCY: 6,
    SELL: 7,
    FALL: 8,
    NO_PARTURITION: 9,
    SELL_OR_FALL_AFTER_POSITIVE_USG: 10,
    NO_INSEMINATION: 11,
    INSEMINATION_NOT_IN_CORRECT_TIME: 12,
    OK: 13, // ogólny parametr,
    PARTURITION_IN_WRONG_TIME: 14,
    NO_INSEMINATION_AFTER_7_DAYS: 15,
    INSEMINATION_AFTER_7_DAYS: 16,
    FALL_WITHOUT_OR_NEGATIVE_USG: 17,
    SELL_WITHOUT_OR_NEGATIVE_USG: 18,
    SEPARATION: 19,
    NO_SEPARATION: 20,
    PARTIAL_SEPARATION: 21
};

export const SelectedItemStatusExplanation = {
    OUT_DECREASED_EFFECTIVENESS: 0
};
