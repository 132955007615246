import React from "react";
import "./_gradients.scss";

// const getGradientColors = (name) => {
//     const colors = [...Object.values(PIG_COLOR), ""];
//     const state = Object.values(PIG_COLOR).map(() => colors);
//     const states = [];
//     const combinations = (current = []) => {
//         const len = current.length;
//         if (len === state.length) {
//             const nullIdx = current.findIndex((o) => o === "");
//             const cur = nullIdx !== -1 ? current.slice(0, nullIdx) : current;
//             const idArr = [...cur].filter(o => !!o);
//             idArr.sort();
//             const id = `${name}-${idArr.join("-")}`;
//             const props = {};
//             for (let c of cur) {
//                 if (props[c]) {
//                     props.duplicated = true;
//                     break;
//                 }
//                 if (c) {
//                     props[c] = true;
//                 }
//             }
//             if (props.duplicated) return;
//             if (idArr.length === 0) return;
//             states.push({
//                 id,
//                 props,
//                 colors: idArr
//             })
//         } else {
//             for (let i = 0; i < (state[len]?.length || 0); i++) {
//                 combinations([...current, state[len][i]]);
//             }
//         }
//     }
//     combinations([]);
//     return uniqBy(states, ({id}) => id);
// };

// const ColorGradient = ({id, colors, showSmalls}) => {
//     const size = colors.length;
//
//     const getStartOffset = useCallback((index) => {
//         return index * 100 / size
//     }, [size]);
//
//     const getStopOffset = useCallback((index) => {
//         return ((index + 1) * 100) / size;
//     }, [size]);
//
//     return (
//         <linearGradient className={showSmalls ? "map-gradient map-gradient__smalls" : "map-gradient"} id={id}>
//             {colors.map((color, index) => (
//                 <>
//                     <stop className={`color-${color}`}
//                           offset={`${(getStartOffset(index) + getStopOffset(index)) / 2}%`}/>
//                     {/*<stop className={`color-${color}`} offset={`${getStopOffset(index)}%`}/>*/}
//                 </>
//             ))}
//         </linearGradient>
//     )
// }

const Gradients = () => {
    // wykomentować jak będzie potrzeba zaznaczenia całej komory
    // const gradients = useMemo(() => getGradientColors("color"), []);
    return (
        <>
            <radialGradient id="farm-map-light">
                <stop
                    offset="0%"
                    className={"farm-map-light-1"}
                />
                <stop
                    offset="100%"
                    className={"farm-map-light-2"}
                />
            </radialGradient>
            {/*{*/}
            {/*    gradients.map(({id, colors}) => (*/}
            {/*        <ColorGradient key={id} showSmalls={showSmalls} id={id} colors={colors}/>*/}
            {/*    ))*/}
            {/*}*/}
        </>
    )
};

export default React.memo(Gradients);