import memoizeOne from "memoize-one";
import { isEqual } from "lodash";
import { createSelector } from "reselect";

const getConnectionTime = (state) =>
    state.mqtt.startTime;

export const getConnectionStatus = (state) =>
    state.mqtt.connected;

export const getConnectingStatus = (state) =>
    state.mqtt.connecting;

export const getReconnectingStatus = (state) =>
    state.mqtt.reconnecting;

export const getSubscriptions = (state) =>
    state.mqtt.subscriptions;

export const getStatus = (state) =>
    state.mqtt.status;

const getEndTime = (state) =>
    state.mqtt.endTime;

const _getConnectionTimestamp = memoizeOne((connectedSince, noConnectionSince, isConnected, isConnecting, isReconnecting) => {
    console.table({ connectedSince, noConnectionSince, isConnected, isConnecting, isReconnecting });
    return isConnected ? +new Date() : null;
}, isEqual);

export const getStableMQTTConnectionTime = createSelector([getConnectionTime, getEndTime, getConnectionStatus, getConnectingStatus, getReconnectingStatus], _getConnectionTimestamp);

export const isConnectedToLocalMQTT = state => state.mqtt.localConnection;
