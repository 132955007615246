/**
 * @deprecated
 * @param map
 * @param locationKey
 * @return {Map<any, any>}
 */
export function removeDuplicateRFIDs(map = new Map(), locationKey = "UNBINDED") {
    if (!map.has(locationKey)) {
        return map;
    }
    const rfids = map.get(locationKey).map(o => o.RFID);
    for (let locID of map.keys()) {
        if (locID !== locationKey) {
            map.set(locID, map.get(locID).filter(o => !rfids.includes(o.RFID)))
        }
    }
    return map;
}
