import CollapsableContainer from "../containers/CollapsableContainer";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import BetaSign from "../../basics/BetaSign";
import TableGrid from "../../basics/table-grid/TableGrid";
import FatteningAnimalsModal from "./FatteningAnimalsModal";

export default function FatteningAnimalsGrid() {

    const { t } = useTranslation();

    const [show, setShow] = useState(null);

    const [title, setTitle] = useState(null);

    const onSetShow = useCallback(({ name, type }) => {
        setShow(type);
        setTitle(name);
    }, []);

    const data = useMemo(() => {
        return [
            { name: t("animalsAmountInPlaceBasic"), type: 1 },
            { name: t("animalsAmountInPlaceDetail"), type: 2 },
        ];
    }, [t]);

    const headers = useMemo(() => {
        return [
            {
                name: t("designation"),
                field: "name"
            },
            {
                name: "",
                field: "type",
                headerClassName: "index",
                itemClassName: "index",
                component: <i className="fas fa-chevron-right" />
            }
        ]
    }, [t]);

    return (
        <>
            <CollapsableContainer.Card header={<>{t("animalsNumberInLocations")}&nbsp;<BetaSign /></>}
                id="cy-fattening-card" defaultExpanded>
                <TableGrid data={data} headers={headers} onRowClick={onSetShow} />
                <FatteningAnimalsModal name={title} show={show} onHide={onSetShow} />
            </CollapsableContainer.Card>
        </>
    )
}