import React, {Component} from 'react';
import Card from "../basics/card/Card";
import Button from "../basics/button/Button";
import "./_cookie-acceptance.scss";
import {withCookies} from "react-cookie";
import moment from "moment";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import HelperTranslate from "../basics/helper/mini-components/HelperTranslate";

const CookieAcceptanceName = "CANIHAZCOOKIEZ";

class CookieAcceptance extends Component {

    constructor(props) {
        super(props);
        const {cookies} = this.props;
        this.state = {
            show: !cookies.get(CookieAcceptanceName)
        }
    }

    handleAcceptCookies = () => {
        const {cookies} = this.props;
        cookies.set(CookieAcceptanceName, true, {
            path: "/",
            expires: moment().add(1, "year").toDate()
        });
        this.setState({
            show: false
        })
    }

    render() {
        const {show} = this.state;
        const {tReady, t} = this.props;
        if (!show || !tReady) return null;
        return (
            <Card className={"small cookie-acceptance"}>
                <div>
                    <HelperTranslate translationKey={"cookies.acceptanceText"}/>
                </div>
                <Button
                    buttonColor={"primary"}
                    type={"button"}
                    onClick={this.handleAcceptCookies}
                >
                    {t("ok")}
                </Button>
            </Card>
        );
    }
}

export default compose(
    withCookies,
    withTranslation()
)(CookieAcceptance);
