import {useCallback} from "react";
import {show as showModal} from "redux-modal/lib/actions";
import {ModalName as DeviceChartDetailsModalName} from "../../modals-new/device-chart-details-modal/DeviceChartDetailsModal";
import {skipData} from "./charts/utils";
import {useDispatch} from "react-redux";
import moment from "moment";
import {utcMomentToLocal} from "../../../utils/DateTimeUtils";

/**
 * funkcja do otwierania modala ze szczegółami na wykresach
 * usuwa lokalizacje w których nie ma świń jeśli przekażemy `groupInfo`
 * @param type {string|"forage"|"water"|"climate"}
 * @param locations {array<string>}
 * @param groupInfo {object|null}
 * @return {function({time?: *}): void}
 */
const useOpenDetailsModal = (type, locations, groupInfo) => {

    const dispatch = useDispatch();

    return useCallback(({time}) => {
        const dayLOCAL = utcMomentToLocal(moment.utc(time));
        const locationsWithAnimalsInside = groupInfo ?
            locations
                .filter((locationId) => {
                    const animals = groupInfo.getNumberByTimeAndLocation(+dayLOCAL, locationId);
                    return !skipData(animals);
                })
            :
            locations;
        dispatch(showModal(DeviceChartDetailsModalName, {type, locations: locationsWithAnimalsInside, time}))
    }, [dispatch, locations, type, groupInfo]);
}

export default useOpenDetailsModal;