import {CycleActions} from "../constans/cycleActions";
import {
    getTimeFromInseminationToPartuition,
    getTimeFromInseminationToPregnancy
} from "./SettingsUtils";
import i18next from "i18next";

/**
 * Funkcja tlumacząca powód błędu, który wystąpił w danym cyklu (wierszu).
 * @param reason
 * @param additionalData
 * @returns {string|*}
 */
export function getCycleReason(reason, additionalData) {
    switch (reason) {
        case CycleActions.USG_BEFORE_X_DAYS:
        case CycleActions.USG_AFTER_X_DAYS: {
            const timeFromInseminationToPregnancy = getTimeFromInseminationToPregnancy();
            return i18next.t(`cycleReasons.usg${reason === CycleActions.USG_BEFORE_X_DAYS ? "Before" : "After"}XDays`, {count: timeFromInseminationToPregnancy});
        }
        case CycleActions.PARTURITION_BEFORE_X_DAYS:
            return `${i18next.t("cycleReasons.parturitionBeforeXDays", {count: getTimeFromInseminationToPartuition()})} ${i18next.t("cycleReasons.parturitionBeforeDays", {count: additionalData.difference})}`;
        case CycleActions.PARTURITION_AFTER_X_DAYS:
            // bylo zawsze na sztywno ale Adrian kazal zmienic - https://redmine.wesstron.local/issues/7389
            return `${i18next.t("cycleReasons.parturitionAfterXDays", {count: additionalData.timeBetweenBothEvents})} ${i18next.t("cycleReasons.parturitionAfterDays", {count: additionalData.difference})}`;
        case CycleActions.FIRST_INSEMINATION_FROM_BIRTH:
            return `${i18next.t("cycleReasons.sowInseminationAge", {count: additionalData.timeBetweenBirthToFirstInsemination})} ${i18next.t("cycleReasons.minimalSowAge", {count: additionalData.minAgeForFirstMatingInDays})}`;
        case CycleActions.NO_INSEMINATION_BEFORE_EV:
            return i18next.t("cycleReasons.noInseminationFromBirth");
        case CycleActions.NO_USG_BEFORE_EV:
            return i18next.t("cycleReasons.noUsgBeforeEv");
        case CycleActions.NO_PARTURITION_AFTER_EV:
            return i18next.t("cycleReasons.noParturitionAfterEv");
        case CycleActions.NO_SEPARATION_AFTER_EV:
            return i18next.t("cycleReasons.noSeparationAfterEv");
        case CycleActions.NO_PARTURITION_AFTER_X_DAYS:
            return i18next.t("cycleReasons.noParturitionAfterXDays", {count: getTimeFromInseminationToPartuition()});
        case CycleActions.SEPARATION_CNT_BIGGER_THAN_PARTURITION_CNT:
            return i18next.t("cycleReasons.separationCntBiggerThanParturitionCnt");
        case CycleActions.SEPARATION_CNT_BIGGER_THAN_BIRTH_CNT:
            return i18next.t("cycleReasons.negativeBalance",{balance: additionalData.balance});
        case CycleActions.PIGLET_CNT_BIGGER_THAN_NIPPLES:
            return i18next.t("cycleReasons.pigletCntBiggerThanNipples");
        case CycleActions.NO_SEPARATION_BUT_SOW_CYCLES:
            return i18next.t("cycleReasons.noSeparationButSowCycles", {lastSeparation: additionalData.lastSeparation});
        case CycleActions.LAST_SEPARATION_DIFF_THAN_IN_REALITY:
            return i18next.t("cycleReasons.lastSeparationDiffrentThanInReality");
        case CycleActions.NOT_IN_STAND:
            return i18next.t("animalIsNotOnStand");
        case CycleActions.SHOULD_BE_TRANSFERED:
            return i18next.t("animalShouldBeTransfered", {count: additionalData.days, from: i18next.t(`SType.${additionalData.from - 1}`), to: i18next.t(`SType.${additionalData.destination - 1}`)});
        case CycleActions.HAS_NEGATIVE_PIGLETS_AMOUNT:
            return i18next.t("cycleReasons.hasNegativePigletBalance", {balance: additionalData.balance});
        case CycleActions.HAS_NOT_SEPARATED_ALL_PIGLETS_YET:
            return i18next.t("cycleReasons.hasNotSeparatedAllPiglets");
        case CycleActions.HAS_NOT_FIRST_MATING_BUT_IS_ON_MATING:
            return i18next.t("cycleReasons.hasNotFirstMatingButIsOnFirstMating", {count: additionalData.days});
        case CycleActions.IS_IDLE_AFTER_NEGATIVE_USG_ON_MATING:
            return i18next.t("cycleReasons.isIdleAfterNegativeUsgOnMating", {count: additionalData.days});
        case CycleActions.IS_ON_SOWS_ROOM_TOO_LONG_WITHOUT_PARTURITION:
            return i18next.t("cycleReasons.isOnDeliveryAndHasParturitrionWithoutSeparation");
        case CycleActions.IS_ON_DELIVERY_AND_HAS_PARTURITION_WITHOUT_SEPARATION:
            return i18next.t("cycleReasons.isOnSowsRoomTooLongWithoutParturition");
        default:
            return '';
    }
}
