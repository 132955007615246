/**
 * Funkcja iniciujaca dane dla widokow krzywych
 * @param chamber - obiekt komory
 * @param options
 * @param options.dispensers - lista urzadzen
 * @return {function(...[*]=)}
 */
import {getFeedingForPig, getFeedingForPigLoading} from "../selectors/feedingSelector";
import settingsDB from "../database/settingsDB";
import moment from "moment";
import {getSchedule} from "../demo/feeding/fakeFunctions";


export function setViewType(viewType){
    return function (dispatch) {
        dispatch({
            type: "SET_VIEW_TYPE",
            payload: viewType
        })
    }
}
export function setFeedingEvent(e){
    return function (dispatch) {
        dispatch({
            type: "SET_FEEDING_EVENT",
            payload: e
        })
    }
}

export function setChamberFeedingData(data){
    return function (dispatch) {
        dispatch({
            type: "SET_CHAMBER_FEEDING_DATA",
            payload: data
        })
    }
}

export function feedingInit(chamber, options = {}) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_INITIALIZE",
            payload: {
                id: chamber.CID,
                chamber: chamber,
                options: options
            }
        })
    }
}

export function feedingDestroy(chamber) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_DESTROY",
            payload: {
                id: chamber.CID
            }
        })
    }
}

export function feedingSelect(chamber, dataId) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_SELECT",
            payload: {
                chamberId: chamber.CID,
                id: dataId
            }
        })
    }
}

export function feedingSelectAll(chamber) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_SELECT_ALL",
            payload: {
                chamberId: chamber.CID
            }
        })
    }
}

export function feedingUnselectAll(chamber) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_SELECT_NONE",
            payload: {
                chamberId: chamber.CID
            }
        })
    }
}

export function feedingFilter(chamber, feedingFilter) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_FILTER",
            payload: {
                chamberId: chamber.CID,
                feedingFilter: feedingFilter
            }
        })
    }
}

export function feedingSort(chamber, feedingSort) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_SORT",
            payload: {
                chamberId: chamber.CID,
                feedingSort: feedingSort
            }
        })
    }
}

export function feedingUpdateAnimals(chamber, options = {}) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_UPDATE_ANIMALS",
            payload: {
                chamber: chamber,
                chamberId: chamber.CID,
                options: options
            }
        })

    }
}

export function getFeedState(data) {
    return function (dispatch) {
        dispatch({
            type: "GET_FEED_STATE",
            payload: data
        })

    }
}

export function getFeedStateDelta(data) {
    return function (dispatch) {
        dispatch({
            type: "GET_FEED_STATE_DELTA",
            payload: data
        })

    }
}

export function getFeedStateRFID(data) {
    return function (dispatch) {
        dispatch({
            type: "GET_FEED_RFID_STATE",
            payload: data
        })

    }
}

export function getFeedStateRFIDDelta(data) {
    return function (dispatch) {
        dispatch({
            type: "GET_FEED_RFID_STATE_DELTA",
            payload: data
        })

    }
}

export function feedingCheckConnection(chamber) {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_CHECK_LAST_CONNECTION",
            payload: {
                chamber: chamber,
                chamberId: chamber.CID
            }
        })

    }
}

export function clearFeeding() {
    return function (dispatch) {
        dispatch({
            type: "FEEDING_CLEAR"
        })

    }
}

export function setFeedingForPig(data) {
    return function (dispatch) {
        let tmp = {};
        for (let row of data) {
            console.log(row);
            if (Object.keys(row).length > 1) {
                tmp[row.AnmID || row.PlcmntID] = row;
            }
        }
        dispatch({
            type: "SET_FEEDING_FOR_PIG",
            payload: tmp
        })
    }
}

export function setFeedingForPigLoading(id, data, loading) {
    return function (dispatch, getState) {
        let state = getState();
        let currentLoading = getFeedingForPigLoading(state);
        let tmp = {};
        for (let row of data) {
            let isLoading = Object.keys(row).length > 3;
            if (isLoading) {
                tmp[row.AnmID] = {};
                for (let id in currentLoading[row.AnmID]) {
                    tmp[row.AnmID][id] = false;
                }
            } else {
                let currentFeeding = getFeedingForPig(state, row.AnmID);
                tmp[row.AnmID] = {
                    ...(currentLoading[row.AnmID] || {}),
                    [id]: currentFeeding ? false : loading
                }
            }
        }
        dispatch({
            type: "SET_FEEDING_FOR_PIG_LOADING",
            payload: tmp
        })
    }
}

export function setSyncingStatus(status, setDate = false) {
    return function (dispatch) {
        dispatch({
            type: "SET_SYNCING_STATUS",
            payload: status,
            meta: {
                setDate
            }
        })
    }
}


export function setSyncingAmount(amount) {
    return function (dispatch) {
        dispatch({
            type: "SET_SYNCING_AMOUNT",
            payload: amount
        })
    }
}

export function setFakeFeedingData(AnmID, FarmID, curve, startTime, stage, doseCorrection, dosesToSkip) {
    return function (dispatch, getState) {
        let state = getState();
        let currentData = getFeedingForPig(state, AnmID);
        console.log(currentData);
        if (!curve) {
            curve = settingsDB.getSettingByID(currentData.curve.id);
        }
        currentData.curve.number = curve.SetData.Index + 1;
        currentData.curve.id = curve.SetID;
        if (startTime !== null) {
            currentData.curve.start = startTime;
            currentData.curve.day = moment().startOf("day").diff(startTime, "days") + 1;
            let schedule = getSchedule(FarmID, curve, currentData.curve.day);
            currentData.schedule.id = schedule.SetID;
            currentData.schedule.number = schedule.SetType.Index;
            let currentDay = curve.SetData.Days[currentData.curve.day];
            currentData.lastWeekHistory[0].plannedUsage = currentDay.ForageAmount;
        }
        if (stage !== null) {
            currentData.curve.stage = stage;
            if (stage === 1) {
                currentData.curve.day += curve.SetData.InseminationJumpTo - 1;
            }
        }
        if (doseCorrection !== null) {
            currentData.curve.correction = doseCorrection;
        }
        if (dosesToSkip) {
            currentData.skipDoses = dosesToSkip;
        }
        dispatch(setFeedingForPig([currentData]));
    }
}
