import EventTypes from '@wesstron/utils/Api/constants/eventTypes';
import moment from 'moment';
import React, {useCallback, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import EstimatedWeight from '../../../beans/estimated-weight/EstimatedWeight';
import {getOtherWeightsSelector} from '../../../selectors/settingsSelector';
import {utcDateFormatter, utcMomentToLocal} from '../../../utils/DateTimeUtils';
import {isBetween} from '../../../utils/MathUtils';
import {getAnimalUnit} from '../../../utils/SettingsUtils';
import {convertWeightUnitTo, getUnit} from '../../../utils/UnitUtils';
import Chart from "../../basics/chart/Chart";
import CompareWeightsTooltip from "../../charts/CompareWeightsTooltip";
import CollapsableContainer from '../containers/CollapsableContainer';
import {calculateAutoDomainForWeightingChart} from '../ideal-charts/charts/utils';

const emptyArray = [];

function WeightingChart({weightDict, birthTime, start, end, chartDomain = emptyArray, referenceLines}) {

    const forwardRef = useRef(null);

    const {t} = useTranslation();

    const animalUnit = useMemo(() => getAnimalUnit(), []);

    const weightUnit = useMemo(() => getUnit("weight", animalUnit), [animalUnit]);

    const weightFormatter = useCallback((value) => {
        if (value === undefined || value === null || isNaN(value)) return null;
        return convertWeightUnitTo(value, {
            showUnit: false,
            unit: animalUnit,
            rawValue: true,
            fixed: 2
        })
    }, [animalUnit]);

    const weightArray = useSelector(getOtherWeightsSelector);

    const dataSet = useMemo(() => {
        const ds = [];
        const [chartStart, chartEnd] = chartDomain;
        const diff = Math.abs(moment.utc(chartStart).diff(moment.utc(chartEnd), "day"));
        const estimate = new EstimatedWeight(0, []);
        estimate.setWeightTable(weightArray, 1200);
        estimate.setBirthDate(birthTime);
        for (let i = 0; i <= diff; i++) {
            const dayUTC = moment.utc(chartStart).add(i, "day");
            const dayLOCAL = utcMomentToLocal(dayUTC);
            const estimated = estimate.getWeightByDate(+dayLOCAL);
            const isGroupActive = isBetween(+dayUTC, start, end);
            if (!isGroupActive) {
                ds.push({
                    time: +dayUTC,
                    estimated
                });
                continue;
            }
            ds.push({
                time: +dayUTC,
                estimated
            });
            // getting events by utc day from dict
            const eventsForCurrentDay = weightDict[+dayUTC] ?? null;
            if (Array.isArray(eventsForCurrentDay)) {
                for (const event of eventsForCurrentDay) {
                    ds.push({
                        ...event,
                        time: +dayUTC,
                        estimated
                    });
                }
            }
        }
        return ds;
    }, [chartDomain, birthTime, start, end, weightArray, weightDict]);

    const chartDomainY = useMemo(() => {
        return calculateAutoDomainForWeightingChart(dataSet);
    }, [dataSet]);


    const dataDef = useMemo(() => {
        return [
            {
                dataKey: "estimated",
                color: "purple",
                strokeDasharray: "5 5",
                type: "linear",
                chartType: "Line",
                unit: weightUnit,
                name: t("expectedWeight"),
                yAxisId: "lineAxis",
                formatter: weightFormatter
            },
            {
                dataKey: EventTypes.WEIGHTING,
                color: "blue",
                chartType: "Scatter",
                opacity: 1,
                unit: weightUnit,
                name: t("weighting"),
                yAxisId: "scatterAxis",
                formatter: weightFormatter
            },
            {
                dataKey: EventTypes.SELL,
                color: "green",
                chartType: "Scatter",
                opacity: 1,
                unit: weightUnit,
                name: t("animalDocuments.sellGrid"),
                yAxisId: "scatterAxis",
                formatter: weightFormatter
            },
            {
                dataKey: EventTypes.FALL,
                color: "red",
                chartType: "Scatter",
                opacity: 1,
                unit: weightUnit,
                name: t("menu.fall"),
                yAxisId: "scatterAxis",
                formatter: weightFormatter
            },
            {
                dataKey: EventTypes.INSERTION,
                color: "orange",
                chartType: "Scatter",
                opacity: 1,
                unit: weightUnit,
                name: t("insertion"),
                yAxisId: "scatterAxis",
                formatter: weightFormatter
            }
        ];
    }, [t, weightFormatter, weightUnit]);

    return (
        <CollapsableContainer.Card id="cy-weighting-card" header={t("weighting")} defaultExpanded={true}
                                   forwardRef={forwardRef}>
            <div className={"h-0 mh-15rem"}>
                <Chart
                    data={dataSet}
                    dataDef={dataDef}
                    syncId={"idealChart"}
                    isAnimationActive={false}
                    tooltipContent={<CompareWeightsTooltip/>}
                    syncMethod={"value"}
                    saveAsExcell={t("weighting")}
                    Yaxis={{
                        name: t("weight"),
                        yAxisId: "lineAxis",
                        type: "number",
                        domain: chartDomainY,
                        formatter: weightFormatter
                    }}
                    thirdYaxis={{yAxisId: "scatterAxis", type: "number", domain: chartDomainY}}
                    Xaxis={{
                        name: "",
                        domain: chartDomain,
                        dataKey: "time",
                        formatter: utcDateFormatter,
                        type: "number"
                    }}
                    type={"Composed"}
                    referenceLines={referenceLines}
                />
                {
                    referenceLines && referenceLines.length === 0 &&
                    <div className="chart-template-no-data">
                        <h5>{t("chartDisplayNoBirthTime")}</h5>
                        <div>{t("chartDisplayNoBirthTimeText")}</div>
                    </div>
                }
            </div>
        </CollapsableContainer.Card>
    )
}

export default React.memo(WeightingChart);