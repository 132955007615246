import {
    editSowCycle,
    editSowCycleNotificationFailure,
    editSowCycleNotificationSuccess
} from "../../../api/cycles/editSowCycle";

export function submit(values, dispatch, props) {
    return editSowCycle({ AnmID: values.AnmID, eventsToUpdate: [values], eventsToDelete: [] }).then((res) => {
        props.handleHide();
        editSowCycleNotificationSuccess(res);
    }).catch((e) => {
        editSowCycleNotificationFailure(e);
    });
}

export function validate(values, props) {
    const error = {
        EvData: {}
    };
    const { t } = props;

    if (values.EvData && (!values.EvData.Treatment || !values.EvData.Treatment.length)) {
        error.EvData.Treatment = { _error: t("required") };
    }

    return error;
}