import DevTypes from "@wesstron/utils/Api/constants/devTypes";
import RoleTypes from "@wesstron/utils/Api/constants/roleTypes";
import { setSingleRelay } from "../../../../../IOT/device-functions/RelayFunctions";
import { changeMapShadowDelta } from "../../../../../actions/mapShadowActions";
import store from "../../../../../store/store";
import { getAlias } from "../../../../../utils/DevicesUtils";

export default ({ t, devices, roleUtils, placementId, data }, updateShadowOn = "map") => {
    if (placementId && !roleUtils.hasRoleByPlacementID({
        roles: [RoleTypes.DEVICE_CONFIG],
        placementId
    })) return null;
    const items = devices.filter(({ device }) => device.DevType === DevTypes.MODBUS_RELAY);
    const result = [];
    const useAlias = items.length > 1;
    const dispatch = store.dispatch;
    for (const { device, index } of items) {
        const key = `${device.DevID}_${index}`;
        const currentState = !!data[key]?.State;
        result.push({
            key: `light-control-panel-${key}`,
            icon: {
                iconName: currentState ? "fas fa-lightbulb-on color-yellow" : "fas fa-lightbulb opacity-50",
                name: useAlias ? getAlias(device, index) : currentState ? t("settings.turnOffLight") : t("settings.turnOnLight"),
                description: t("settings.findByTurningOnLED")
            },
            show: true,
            submit: () => {
                return new Promise((resolve, reject) => {
                    setSingleRelay(device, { relayIndex: index, state: !currentState }, {
                        onFailure: reject,
                        onSuccess: resolve
                    })
                }).then(() => {
                    switch (updateShadowOn) {
                        case "map": {
                            dispatch(changeMapShadowDelta({
                                [key]: {
                                    State: !currentState
                                }
                            }));
                            break;
                        }
                        case "device": {
                            console.log("not yet implemented");
                            break;
                        }
                        default:
                            break;
                    }

                })
            }
        })
    }
    return result;
}
