import {Fade} from "react-bootstrap";
import React, {forwardRef, useCallback, useEffect, useMemo} from "react";
import {transformOptions} from "./utils";
import SelectItem from "./SelectItem";
import {useTranslation} from "react-i18next";
import memoizeOne from "memoize-one";
import {isEqual} from "lodash";

const OptionListDesktop = forwardRef(({
                                          options,
                                          menuOpened,
                                          renderInPortal,
                                          setFilterText,
                                          value,
                                          hovered,
                                          customRenderer,
                                          onChange,
                                          onHoverChange,
                                          inputBBox
                                      }, ref) => {
    const isEmpty = options.length === 0;

    const {t} = useTranslation();

    const transformed = useMemo(() => {
        return transformOptions(options, false, value, hovered, customRenderer, {onSelect: onChange, onHoverChange})
    }, [options, value, hovered, customRenderer, onChange, onHoverChange]);

    const handleExit = useCallback(() => {
        setFilterText("");
    }, [setFilterText]);

    const memoStyles = useMemo(() => memoizeOne((s) => s, isEqual), [])

    const styles = useMemo(() => {
        return memoStyles({
            top: inputBBox.top,
            left: inputBBox.left,
            width: inputBBox.width
        })
    }, [inputBBox, memoStyles]);


    const preventHandler = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);


    useEffect(() => {
        const backdrop = document.getElementsByClassName("modal")[0];
        if (menuOpened && backdrop) backdrop.addEventListener("click", preventHandler)
        return () => {
            if (backdrop) backdrop.removeEventListener("click", preventHandler)
        }
    }, [menuOpened, preventHandler])

    // todo: pomyśleć o virtualize
    return (
        <Fade className={"no-pointer-events"} in={menuOpened} mountOnEnter unmountOnExit onExited={handleExit}>
            <div
                className={renderInPortal ? "select-menu static" : "select-menu"}
                ref={ref}
                style={styles}
                tabIndex={1}>
                {
                    transformed.map((o) => (
                        <SelectItem {...o} key={o.key}/>
                    ))
                }
                {
                    isEmpty &&
                    <p className="empty-list">
                        <i>{t("select.noRecords")}</i>
                    </p>
                }
            </div>
        </Fade>
    );
})
export default OptionListDesktop;