import React from "react";
import SVGPaths from "../components/SVGPaths";
import {customPigColors, getRotatedData, transformProps, validateClassName} from "./utils";

function StandingVertical(props) {
    return _RegularStandingTemplate(transformProps(props), "vertical");
}

function StandingHorizontal(props) {
    return _RegularStandingTemplate(transformProps(props), "horizontal");
}

function _RegularStandingTemplate({id, animal, device, down, right, className, scale}, orientation) {
    const height = 240;
    const width = 200;
    const {angle, viewBox, toAbsolute} = getRotatedData(orientation, down, right, width, height);
    return (
        <pattern className={className} id={id} x="0" y="0" width="100%" height="100%"
                 viewBox={viewBox}
                 patternUnits="objectBoundingBox">
            <SVGPaths.StandingFloor className="bg" translate={toAbsolute(0, 0)} rotate={[angle, 0, 0]}/>
            <SVGPaths.StandingTrough className="trough" translate={toAbsolute(`${width / 2}-50%`, 4)}
                                     rotate={[angle, 0, 0]}/>
            {
                !!animal &&
                <SVGPaths.Pig scale={scale} className="animal" translate={toAbsolute(`${width / 2}-50%`, 25)}
                              rotate={[angle, 0, 0]}/>
            }
            <SVGPaths.Standing className="stand" translate={toAbsolute(`${width / 2}-50%`, 0)}
                               rotate={[angle, 0, 0]}/>
            {
                !!device &&
                <SVGPaths.Dispenser className="device" translate={toAbsolute(45, 5)} rotate={[angle, 0, 0]}/>
            }
        </pattern>
    )
}

function _SmallStandingTemplate({id, animal, device, down, right, className}, orientation) {
    const height = 240;
    const width = 70;
    const {angle, viewBox, toAbsolute} = getRotatedData(orientation, down, right, width, height);
    return (
        <pattern className={className} id={id} x="0" y="0" width="100%" height="100%" viewBox={viewBox}
                 patternUnits="objectBoundingBox">
            <SVGPaths.StandingSmallFloor className="bg" translate={toAbsolute(0, 0)} rotate={[angle, 0, 0]}/>
            <SVGPaths.StandingSmallTrough className="trough" translate={toAbsolute(`${width / 2}-50%`, 3)}
                                          rotate={[angle, 0, 0]}/>
            {
                !!animal &&
                <SVGPaths.Pig className="animal" translate={toAbsolute(`${width / 2}-50%`, 25)}
                              rotate={[angle, 0, 0]}/>
            }
            <SVGPaths.StandingSmall className="stand" translate={toAbsolute(0, 0)}
                                    rotate={[angle, 0, 0]}/>
            {
                !!device &&
                <SVGPaths.Dispenser className="device" translate={toAbsolute(0, 3)} rotate={[angle, 0, 0]}/>
            }
        </pattern>
    )
}

function StandingSmallVertical(props) {
    return _SmallStandingTemplate(transformProps(props), "vertical");
}

function StandingSmallHorizontal(props) {
    return _SmallStandingTemplate(transformProps(props), "horizontal");
}

function _GroupTemplate({id, animal, device, down, right, className}, orientation) {
    const height = 240;
    const width = 70;
    const {angle, viewBox, toAbsolute} = getRotatedData(orientation, down, right, width, height);
    return (
        <pattern className={className} id={id} x="0" y="0" width="100%" height="100%" viewBox={viewBox}
                 patternUnits="objectBoundingBox">
            <SVGPaths.StandingSmallFloor className="bg" translate={toAbsolute(0, 0)} rotate={[angle, 0, 0]}/>
            <SVGPaths.GroupTrough className="trough" translate={toAbsolute(`${width / 2}-50%`, 3)}
                                  rotate={[angle, 0, 0]}/>

            {
                !!animal &&
                <SVGPaths.Pig className="animal" translate={toAbsolute(`${width / 2}-50%`, 35)}
                              rotate={[angle, 0, 0]}/>
            }
            <SVGPaths.GroupDispenser className="stand" translate={toAbsolute(0, 0)}
                                     rotate={[angle, 0, 0]}/>
            <SVGPaths.Dispenser className="device" translate={toAbsolute(0, 5)} rotate={[angle, 0, 0]}/>
        </pattern>
    )
}


function StandingGroupHorizontal(props) {
    return _GroupTemplate(transformProps(props), "horizontal");
}

function StandingGroupVertical(props) {
    return _GroupTemplate(transformProps(props), "vertical");
}

const defaultStates = [["animal", ""], ["device", ""], ["error", "warning", "feed-0", "feed-1", "feed-2", "feed-3", "", ...customPigColors]];

const verticalStates = [["down", "up"], ...defaultStates];

const horizontalStates = [["left", "right"], ...defaultStates];

const groupVerticalStates = [["up"], ["device"], ["error", "warning", ""]];

const groupHorizontalStates = [["left"], ["device"], ["error", "warning", ""]];

const getDefArray = (name, state = []) => {
    const all = state.length;
    const states = [];
    const combinations = (current = []) => {
        const len = current.length;
        if (len === all) {
            const id = [name, ...current].filter(o => !!o).join("-");
            const className = ["def-standing", ...current.filter(validateClassName)].join(" ");
            const props = {className};
            for (let c of current) {
                if (c) {
                    props[c] = true;
                }
            }
            states.push({
                id,
                props
            })
        } else {
            for (let i = 0; i < (state[len]?.length || 0); i++) {
                combinations([...current, state[len][i]]);
            }
        }
    }
    combinations([]);
    return states;
};

function Standing() {
    return (
        <>
            {
                getDefArray("ue-standing", verticalStates).map(({id, props}, i) => (
                    <StandingVertical key={`stand_1_${i}`} id={id} {...props}/>
                ))
            }
            {
                getDefArray("ue-standing", horizontalStates).map(({id, props}, i) => (
                    <StandingHorizontal key={`stand_2_${i}`} id={id} {...props}/>
                ))
            }
            {
                getDefArray("ue-standing-small", verticalStates).map(({id, props}, i) => (
                    <StandingSmallVertical key={`stand_3_${i}`} id={id} {...props}/>
                ))
            }
            {
                getDefArray("ue-standing-small", horizontalStates).map(({id, props}, i) => (
                    <StandingSmallHorizontal key={`stand_4_${i}`} id={id} {...props}/>
                ))
            }
            {
                getDefArray("ue-standing-group", groupVerticalStates).map(({id, props}, i) => (
                    <StandingGroupVertical key={`stand_5_${i}`} id={id} {...props}/>
                ))
            }
            {
                getDefArray("ue-standing-group", groupHorizontalStates).map(({id, props}, i) => (
                    <StandingGroupHorizontal key={`stand_6_${i}`} id={id} {...props}/>
                ))
            }
        </>
    )

}

export default React.memo(Standing);
