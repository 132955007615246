import React, {useEffect, useMemo} from "react";
import {notify} from "reapop";
import i18n from "../../../i18n";
import store from "../../../store/store";
import memoizeOne from "memoize-one";

const utils = {
    // get farm map item and level
    getItemById: ({id, items}) => {
        if (!id) return null;
        for (let i = 0; i < items.length; i++) {
            const item = items[i].find((o) => o.id === id)
            if (item) return {item, level: i};
        }
        return null;
    },
    // set view at selected area
    setZoom: memoizeOne(({level, x1, y1, x2, y2, angle, setLevel, setPositionAt}) => {
        requestAnimationFrame(() => {
            setLevel(level);
            setPositionAt(x1, y1, x2, y2, angle ? angle : 0);
        })
    }, (newArgs, lastArgs) => newArgs[0].modTime === lastArgs[0].modTime),
    // show toast with not found error
    showError: memoizeOne((id, modTime) => {
        store.dispatch(notify({
            title: i18n.t("farms.tabs.mapTab.notFoundOnFarmMap"),
            dismissible: true,
            status: "error",
            position: "top-center",
            dismissAfter: 2000
        }));
    }),
    // pick only valid props for svg.path/svg.circle
    pickParams: (item) => {
        const keys = ["d", "cx", "cy", "r"];
        const params = {};
        const setKeyValue = (getKey, setKey) => {
            if (item[getKey] !== undefined) {
                params[setKey] = item[getKey];

            }
        }
        for (let key of keys) {
            setKeyValue(key, key);
            setKeyValue(`_${key}`, key);
        }
        return params;
    }
}

const SelectedItem = ({itemId, allLevel, setLevel, setPositionAt, animate, modTime}) => {

    // get farm map item and level linked to given id
    const result = useMemo(() => utils.getItemById({id: itemId, items: allLevel}), [allLevel, itemId]);

    const params = useMemo(() => result?.item ? utils.pickParams(result?.item) : {}, [result]);

    // show toast
    useEffect(() => {
        if (!result && itemId) {
            utils.showError(itemId, modTime);
        } else {
            utils.showError.clear();
        }
    }, [result, itemId, modTime])


    // set zoom
    useEffect(() => {
        if (result && animate) {
            const {level, item} = result;
            utils.setZoom({
                modTime,
                level,
                x1: item._view.minX,
                y1: item._view.minY,
                x2: item._view.maxX,
                y2: item._view.maxY,
                angle: item.angle,
                setLevel,
                setPositionAt
            })
        }
    }, [result, setPositionAt, setLevel, animate, modTime]);


    if (!result) return null;

    return (
        <svg>
            <g>
                {
                    React.createElement(result.item.nodeName, {
                        ...params,
                        className: "map-selected-item fill"
                    })
                }
                {
                    React.createElement(result.item.nodeName, {
                        ...params,
                        strokeWidth: result.item.rect.width < 30 ? 0.5 : 2,
                        className: "map-selected-item outline"
                    })
                }
            </g>
        </svg>
    );
};


export default React.memo(SelectedItem);