import {get} from "lodash";
import moment from "moment";
import React from "react";
import {Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {Field, reduxForm} from "redux-form";
import {connectModal, show} from "redux-modal";
import {
    getDetailedLocationDays,
    getMergedInsertionEventsForSubgroupAnimal,
    getSelectedAnimalForDocuments,
    makeGetAnimalsInGroup,
} from "../../../selectors/animalDocumentsSelectors";
import {isService} from "../../../selectors/userSelector";
import {getGroupBasicInfoBoxData} from "../../../utils/GroupDocumentsUtils";
import {getAnimalUnit} from "../../../utils/SettingsUtils";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import Button from "../../basics/button/Button";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import {ModalName as ConfirmRemoveAnimalModalName} from "../confirm-remove-animal/ConfirmRemoveAnimal";
import {submit, validate} from "./EditGroupBasicsModalSubmit";
import {getManageSubgroups} from "../../../selectors/groupSelector";
import ConfirmModificationField from "../../custom-fields/ConfirmModificationField";
import { checkIfUserIsService } from "../../../utils/NewRolesUtils";

export const ModalName = "edit-group-basics-modal-name";

function makeMapStateToProps() {
    const getAnimalsInGroup = makeGetAnimalsInGroup();
    const params = {
        getDeletedAnimals: true,
    }
    return (state) => ({
        animals: getAnimalsInGroup(state, params),
        group: getSelectedAnimalForDocuments(state),
        clients: state.dictionary.clients.WData,
        suppliers: state.dictionary.clients.WData,
        initialValues: get(state, `form.${ModalName}.initial`, {}),
        isService: isService(state),
        mergedInsertions: getMergedInsertionEventsForSubgroupAnimal(state),
        canManageSubgroups: getManageSubgroups(state),
        locationDays: getDetailedLocationDays(state)
    });
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch),
    };
}

class EditGroupBasicsModal extends React.Component {
    constructor(props) {
        super(props);
        const {initialize} = this.props;
        const {
            herdNumber,
            weight,
            name,
            firstInsertionTime,
            supplierID,
            firstBirthTime,
        } = this.getInitialGroupData();
        initialize({
            GrNo1: name,
            HerdNumber: herdNumber,
            SupplierID: supplierID,
            FirstInsertionTime: moment(firstInsertionTime).startOf("day").format(moment.HTML5_FMT.DATE),
            FirstBirthTime: moment(firstBirthTime).startOf("day").format(moment.HTML5_FMT.DATE),
            Weight: `${convertWeightUnitTo(weight || 0, {unit: getAnimalUnit(), rawValue: true, fixed: 2})}`
        });
    }

    getInitialGroupData = () => {
        const {group, locationDays, animals, clients, mergedInsertions: {insertions}} = this.props;
        const {
            herdNumber,
            firstInsertionTime,
            weight,
            name,
            supplierID,
            firstBirthTime,
        } = getGroupBasicInfoBoxData({
            group,
            locationDays,
            animals,
            clients,
            insertions
        });
        return {
            herdNumber,
            firstInsertionTime,
            weight,
            name,
            supplierID,
            firstBirthTime,
        };
    };

    getSuppliers = () => {
        const {suppliers} = this.props;
        return suppliers.map((supplier) => ({
            name: supplier.Value,
            value: supplier.ID,
        }));
    };

    onRemoveGroupClick = () => {
        const {show, handleHide, group} = this.props;
        show(ConfirmRemoveAnimalModalName, {group});
        handleHide();
    };

    checkIfRemoveShouldBeDisabled = () => {
        if(checkIfUserIsService()) return false
        return true;
    }

    render() {
        const {t, show, handleHide, handleSubmit, dirty, submitting, group} = this.props;
        const isGroupEmpty = !group?.AnmIDs.length
        const shouldRemoveBeDisabled = this.checkIfRemoveShouldBeDisabled();
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("animalDocuments.groupEdition")}
                        onCloseClick={handleHide}
                    />
                    <ModalBody>
                        <Field
                            component={ReduxLabeledInput}
                            name={"GrNo1"}
                            label={t("groupNumber")}
                            required
                        />

                        {!isGroupEmpty && (
                            <>
                                <Field
                                    component={ReduxLabeledSelect}
                                    name="SupplierID"
                                    label={t("supplier")}
                                    options={this.getSuppliers()}
                                />
                                <Field
                                    component={ReduxLabeledInput}
                                    name={"HerdNumber"}
                                    label={t("herdNumber")}
                                />
                                <Field
                                    component={ReduxLabeledInput}
                                    name={"FirstInsertionTime"}
                                    type={"date"}
                                    label={t("insertionDate")}
                                    required
                                />
                                <Field
                                    component={ReduxLabeledInput}
                                    name={"FirstBirthTime"}
                                    type={"date"}
                                    label={t("birthDate")}
                                    required
                                />
                                <Field
                                    component={ReduxLabeledInput}
                                    name={"Weight"}
                                    label={t("animalDocuments.insertionWeight")}
                                    type={"number"}
                                    unit={getUnit("weight", getAnimalUnit())}
                                />
                            </>
                        )}

                        <ConfirmModificationField name={group?.GrNo1} group />
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton
                        confirmText={t("save")}
                        hasButtonDisabled={!dirty}
                        formName={ModalName}
                        submitting={submitting}
                        onCloseClick={handleHide}
                        optionalButtons={
                            !shouldRemoveBeDisabled
                                ? [
                                      <Button
                                          icon={<i className="fas fa-trash" />}
                                          buttonColor={"error"}
                                          onClick={this.onRemoveGroupClick}
                                          text={t("removeGroup")}
                                          type={"button"}
                                      />,
                                  ]
                                : []
                        }
                    />
                </form>
            </Modal>
        );
    }
}

export default compose(
    connectModal({name: ModalName}),
    withTranslation(),
    connect(makeMapStateToProps, mapDispatchToProps),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate,
    })
)(EditGroupBasicsModal);
