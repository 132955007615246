import React from "react";
import "./_compare-insertion-tooltip.scss"
import { useTranslation } from "react-i18next";
import DesktopCalendarTaskDrawerFileItem from "../calendar/drawer/file-list/DesktopCalendarTaskDrawerFileItem";
import { getFarmID } from "../../selectors/farmSelector";
import { useSelector } from "react-redux";
import { utcDateFormatter } from "../../utils/DateTimeUtils";
import { formatLocationName } from "../../utils/global-formatters/formatLocationName";

export default function CompareWeightsTooltip({ label, payload }) {

    const { t } = useTranslation();

    const FarmID = useSelector(getFarmID);

    return (
        <div className="compare-insertion-tooltip recharts-default-tooltip">
            <div>{utcDateFormatter(label)}</div>
            <ul>
                {
                    !!payload && payload.map((item, i) => {
                        return (
                            <li key={i} style={{ color: item.fill }}>
                                <span>{item.name}: {item.formatter ? item.formatter(item.value) : item.value}{item.unit}</span>
                                {
                                    item.payload.AnmCnt &&
                                    <>
                                        <br />
                                        <span>{t("objectDescription.ANIMAL.AnmCnt._title")}: {item.payload.AnmCnt}</span>
                                    </>
                                }
                                {
                                    item.payload.PlcmntID &&
                                    <>
                                        <br />
                                        {formatLocationName(item.payload.PlcmntID)}
                                    </>
                                }
                                {
                                    (item.payload.Files || []).map((file) =>
                                        <DesktopCalendarTaskDrawerFileItem file={file} FarmID={FarmID} />
                                    )
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}
