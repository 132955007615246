const initialState = {
    projects: [],
    fetching: false
};

export default function projectsReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_PROJECTS_PENDING": {
            return {...state, fetching: true};
        }
        case "GET_PROJECTS_FULFILLED": {
            return {...state, fetching: false, projects: action.payload};
        }
        case "GET_PROJECTS_REJECTED": {
            return {...state, fetching: false};
        }
        case "USER_LOGOUT_FULFILLED":
            return initialState;
        default: {
            return state;
        }
    }
}
