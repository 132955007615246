import React from "react";
import "./_tree-select.scss"
import Input from "../input/Input";
import {Fade} from "react-bootstrap";
import PropTypes from "prop-types";
import TreeSelectItem from "./TreeSelectItem";
import {get, isEqual, isNil, debounce} from "lodash"
import {myID} from "../../../libs/generateID";
import {isMobile} from "../../../utils/MobileUtils";
import ReactDOM from "react-dom";

export default class TreeSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false,
            inputValue: props.value ? get(props.value.object, props.value.name, "") : ""
        };
        this.menu = React.createRef();
        this.container = React.createRef();
        this.mobile = isMobile();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.value, this.props.value)) {
            if (isNil(this.props.value)) {
                this.setState({
                    inputValue: ""
                })
            } else {
                this.setState({
                    inputValue: get(this.props.value.object, this.props.value.name, "")
                })
            }
        }
        if (this.state.menuOpened !== prevState.menuOpened) {
            this.setStyle();
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('scroll', this.changeStyle);
        this.observer = new ResizeObserver(this.changeStyle);
        this.observer.observe(this.container.current);
        this.setStyle();
    }

    setStyle = () => {
        if (!this.mobile && this.container.current && this.menu.current) {
            const rect = this.container.current.getBoundingClientRect();
            const scrollTop = rect.top + rect.height + window.scrollY;
            this.menu.current.style.width = `${rect.width}px`;
            this.menu.current.style.left = `${rect.left}px`;
            this.menu.current.style.top = `${scrollTop}px`;
        }
    };

    changeStyle = debounce(() => {
        this.setStyle();
    }, 300);

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
        document.removeEventListener('scroll', this.changeStyle);
        this.observer.disconnect();
    }

    handleClickOutside = (event) => {
        const {menuOpened} = this.state;
        if (menuOpened) {
            if (!this.container.current) return;
            if (!this.menu.current) return;
            if (!this.container.current.contains(event.target) && !this.menu.current.contains(event.target)) {
                event.preventDefault();
                event.stopPropagation();
                this.setState({
                    menuOpened: false
                })
            }
        }
    };

    onClickInput = () => {
        this.setState(state => ({
            menuOpened: !state.menuOpened
        }))
    };

    onSelectItem = (value) => {
        if (value) {
            this.setState(state => ({
                inputValue: get(value.object, value.name, ""),
                menuOpened: isMobile() ? false : state.menuOpened
            }));
        } else {
            this.setState(state => ({
                inputValue: "",
                menuOpened: isMobile() ? false : state.menuOpened
            }))
        }
        this.props.onChange(value);
    };

    onClear = () => {
        this.setState({
            inputValue: "",
            menuOpened: false
        });
        this.props.onChange(null);
    }

    createTree = (options, level = 0) => {
        let divs = [];
        for (let option of options) {
            let children = option.children ? this.createTree(option.children, level + 1) : [];
            let div = (
                <TreeSelectItem option={option} level={level} onSelect={this.onSelectItem}
                                key={get(option.object, option.key, myID())}
                                selected={this.props.value && isEqual(this.props.value.object, option.object)}
                                expanded={option.key === "BgID" ? this.props.expanded : false}>
                    {children}
                </TreeSelectItem>
            );
            divs.push(div);
        }
        return divs;
    };

    render() {
        const {inputValue, menuOpened} = this.state;
        const {clearButton, error, disabled, placeholder, options} = this.props;
        const {mobile} = this;
        return (
            <div className={mobile ? "tree-select mobile" : "tree-select"} ref={this.container}>
                <Input placeholder={placeholder} type="text" readOnly
                       onClick={!disabled ? this.onClickInput : null}
                       value={inputValue}
                       error={error} disabled={disabled}/>
                <div className="tree-select-icon">
                    {
                        !!clearButton &&
                        <i className="fas fa-times pointer me-1" onClick={!disabled ? this.onClear : null}/>

                    }
                    <i className={menuOpened ? "fas fa-caret-up" : "fas fa-caret-down"}
                       onClick={this.onClickInput}/>
                </div>
                {
                    ReactDOM.createPortal((
                        <Fade in={menuOpened} unmountOnExit>
                            <div className={!mobile ? "tree-select-menu" : "tree-select-menu mobile"}
                                 ref={this.menu}>
                                {
                                    mobile &&
                                    <div className="close-button" onClick={this.onClickInput}>
                                        <i className="fas fa-times"/>
                                    </div>
                                }
                                {this.createTree(options)}
                            </div>
                        </Fade>
                    ), document.getElementById("root") || document.createElement("div"))
                }
            </div>
        );
    }

}

TreeSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired, // sciezka do nazwy
        object: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired, // obiekt, ktory zawiera opcjonalne kolejne tablice
        children: PropTypes.array, // kolejne podobiekty
        key: PropTypes.string
    })).isRequired,
    expanded: PropTypes.bool,
    placeholder: PropTypes.string,
    clearButton: PropTypes.bool,
    onChange: PropTypes.func,
    initialValue: PropTypes.shape({
        name: PropTypes.string.isRequired, // sciezka do nazwy
        object: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired, // obiekt, ktory zawiera opcjonalne kolejne tablice
    }),
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    disabled: PropTypes.bool
};

TreeSelect.defaultProps = {
    expanded: false,
    onChange: () => {
    },
    initialValue: null,
    clearButton: false
};
