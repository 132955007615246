import React, {useCallback, useMemo} from "react";
import {get, isFunction} from "lodash";

const SelectItem = ({onSelect, option, mobile, customRenderer, onHoverChange, index, active, hovered}) => {

    const handleSelect = useCallback(() => {
        isFunction(onSelect)
        onSelect(option);
    }, [onSelect, option]);

    const content = useMemo(() => {
        if (customRenderer && !mobile) return customRenderer(option);
        if (option.name) return get(typeof option.value === 'object' ? option.value : option, option.name, option.name || "");
        if (typeof option.value === "object") return "";
        return option.value || "";
    }, [option, customRenderer, mobile]);

    const handleMouseEnter = useCallback(() => {
        if (isFunction(onHoverChange)) onHoverChange(index);
    }, [onHoverChange, index]);

    const className = useMemo(() => {
        let cName = "select-item";
        if (active) cName += " active";
        if (hovered) cName += " hovered";
        return cName;
    }, [active, hovered]);

    if (mobile) return (
        <option value={index}>{content}</option>
    );
    return (
        <div className={className} onClick={handleSelect}
             onMouseEnter={handleMouseEnter}>
            {content || ""}
        </div>
    );

}

export default SelectItem;