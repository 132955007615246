import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {parseNutriPro} from "../parsers";
import DeviceDefaultContent from "./DeviceDefaultContent";
import {createKeysAndLabels} from "./utils";


const NutriProContent = (props) => {
    const {t} = useTranslation();
    const {keys, labels} = useMemo(() => {
        return createKeysAndLabels(
            {
                key: "text",
                label: t("deviceRows.feeding.dispenserRow.foodAmount")
            },
            {
                key: "textDispenserStatus",
                label: t("dispenserSensor")
            },
            {
                key: "textFeederStatus",
                label: t("chainFeederSensor")
            }
        )
    }, [t])
    return <DeviceDefaultContent {...props} parseDataFn={parseNutriPro}
                                 name={t("newSettings.devices.addDevicesView.dispenserNRFIpsum")}
                                 paramKeys={keys}
                                 paramLabel={labels}/>
}


export default React.memo(NutriProContent);