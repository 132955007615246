import React from "react";
import {memoize, isArray, isObject} from "lodash";
import PathParser from "../../svg-editor/utils/PathParser";

const getFontSize = memoize(({x1, x2, y1, y2}, text, disableMinCheck = true) => {
    let fontSize = 50;
    const sizeMin = disableMinCheck ? x2 - x1 : Math.min(x2 - x1, y2 - y1);
    fontSize = Math.min(sizeMin, fontSize * 1.2);
    // dziwna liczba to srednia szerokosc tekstu do wysokosci
    const currentWidth = (0.5279276315789471 * fontSize * (Math.max(text.length + 1, 5)));
    if (currentWidth > sizeMin) {
        fontSize = fontSize / (currentWidth / sizeMin);
    }
    return Math.floor(fontSize * 10) / 10;
}, (...args) => {
    const {x1, x2, y1, y2} = args[0]
    const width = x2 - x1;
    const height = y2 - y1;
    const len = args[1].length;
    return `${width}x${height}_${len}_${!!args[2]}`;
})


const getRectRotated = memoize((w, h, rotate) => {
    return PathParser.createFromRect(0, 0, w, h).rotate(rotate).getRect();
}, (...args) => args.join("_"))

export const getTextParametersStanding = memoize((text, rect, rotate = 0) => {
    if (!text) return {};
    const tmp = getRectRotated(rect.x2 - rect.x1, rect.y2 - rect.y1, rotate);
    let {minX: x1, maxX: x2, minY: y1, maxY: y2} = tmp;
    x1 += rect.x1;
    x2 += rect.x1;
    y1 += rect.y1;
    y2 += rect.y1;
    const maxWidth = x2 - x1, maxHeight = y2 - y1;
    // this is lazy scaling to make sure text fits inside new rect
    const scale = Math.sqrt((maxWidth * maxHeight) / ((rect.x2 - rect.x1) * (rect.y2 - rect.y1)));
    return {
        x: x1,
        y: y1,
        height: maxHeight,
        width: maxWidth,
        fontSize: getFontSize({
            x1,
            x2,
            y1,
            y2
        }, {length: Math.max(4, text.length + (maxWidth < 10 ? 0 : 2))}) / scale,
        transform: `rotate(${-rotate}, ${x1 + maxWidth / 2}, ${y1 + maxHeight / 2})`
    }
}, (text, rect, rotate) => text ? `${text.length}_${rect.y2}_${rect.y1}_${rect.x2}_${rect.x1}_${rotate}` : "null")

/**
 * funkcja od liczenia foreignObject zeby ladnie wyswietlalo teksty na mapie
 * @param text
 * @param rectForShowingContent
 * @param cameraRotate
 * @param maxFontSize
 * @param disableVerticalCheck
 * @param forceVertical
 * @param disableMinCheck
 * @return {{}|{transform: string, x: (*|null|number), width: number, y: (*|null|number), fontSize: *, type: string, height: number, maxWidth: number}}
 */
const getTextParameters = (text, rectForShowingContent, cameraRotate = 0, {
    maxFontSize,
    disableVerticalCheck = false,
    forceVertical = false,
    disableMinCheck = false
} = {}) => {
    const _maxVerticalSize = 125;
    const _maxHorizontalSize = 450;
    if (!text) return {};
    // to use current width rotation must be made
    const tmp = getRectRotated((rectForShowingContent.x2 - rectForShowingContent.x1), (rectForShowingContent.y2 - rectForShowingContent.y1), cameraRotate);
    let {minX: x1, maxX: x2, minY: y1, maxY: y2} = tmp;
    x1 += rectForShowingContent.x1;
    x2 += rectForShowingContent.x1;
    y1 += rectForShowingContent.y1;
    y2 += rectForShowingContent.y1;
    const maxWidth = x2 - x1, maxHeight = y2 - y1;
    // this is lazy scaling to make sure text fits inside new rect
    const scale = Math.sqrt((maxWidth * maxHeight) / ((rectForShowingContent.x2 - rectForShowingContent.x1) * (rectForShowingContent.y2 - rectForShowingContent.y1)));
    // use horizontal template with 2 params in column
    let type = "horizontal";
    // but if the row is too narrow and the rect is vertical 1 param per column will be used (forceVertical can be used too)
    if ((maxWidth < _maxVerticalSize && (maxHeight > maxWidth)) || forceVertical) {
        type = "vertical";
    } else if (maxWidth > _maxHorizontalSize && (maxHeight < 50)) {
        type = "horizontal-long";
    }
    let fontSize = 0;
    const getLength = (t) => {
        let length = t?.length || 0;
        if (isObject(t)) {
            length += t.text.length;
            if (t.icon) {
                length += 2;
            }
        }
        return {length: Math.max(length, 5)};
    }
    if (isArray(text)) {
        fontSize = Number.MAX_SAFE_INTEGER;
        text.forEach((t, index) => {
            const fontSizeForPartialText = getFontSize({
                x1: 0,
                x2: (() => {
                    switch (type) {
                        case "horizontal":
                            return index ? maxWidth / 2 : maxWidth;
                        case "horizontal-long":
                            return Math.floor(maxWidth / (text.length || 1));
                        case "vertical":
                        default:
                            return maxWidth;
                    }
                })(),
                y1: 0,
                y2: (() => {
                    if (disableVerticalCheck) return maxHeight;
                    switch (type) {
                        case "horizontal-long":
                            return maxHeight;
                        case "vertical":
                            return Math.floor(maxHeight / (text.length || 1));
                        case "horizontal":
                        default:
                            return Math.floor(maxHeight / (Math.floor(text.length / 2) + 1));
                    }
                })()
            }, getLength(t), disableMinCheck);
            fontSize = Math.min(fontSize, fontSizeForPartialText);
        })
    } else {
        fontSize = getFontSize({x1, x2, y2, y1}, text, disableMinCheck);
    }
    fontSize /= scale;
    if (maxFontSize) {
        fontSize = Math.min(fontSize, maxFontSize);
    }
    return {
        // pass it directly to foreignObject
        x: x1,
        y: y1,
        fontSize: fontSize,
        height: maxHeight,
        width: maxWidth,
        transform: `rotate(${-cameraRotate}, ${x1 + maxWidth / 2}, ${y1 + maxHeight / 2})`,
        // use this on div inside foreignObject
        maxWidth: Math.min(maxWidth / scale, _maxVerticalSize),
        type: type
    }
}

const Text = ({text = "", rect = {}, rotate = 0}) => {
    if (!text) return null; // nie renderujemy jesli nie ma tekstu
    const isSingleText = isArray(text) ? text.length <= 1 : true;
    const {
        x,
        y,
        fontSize,
        height,
        width,
        transform,
        type
    } = getTextParameters(text, rect, rotate, {
        disableVerticalCheck: true,
        forceVertical: isArray(text) && text.length <= 2
    });
    if (isSingleText) {
        return (
            <foreignObject x={x} y={y} fontSize={fontSize} height={height} width={width} transform={transform}>
                <div>
                    <div className="text">
                        {!isArray(text) && text}
                        {isArray(text) && text.map((t, i) => (
                            <div key={i}>
                                {
                                    isObject(t) ? <i className={t.icon}/> : null
                                }
                                {
                                    isObject(t) ? t.text : t
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </foreignObject>
        )
    }
    let fontSizeForParams = 0;
    const getFontSizeForParams = () => {
        if (!fontSizeForParams) {
            const paramsText = text.slice(1);
            fontSizeForParams = getTextParameters(paramsText, rect, rotate, {
                maxFontSize: 0.9 * (height / text.length),
                forceVertical: isArray(text) && text.length <= 2,
                disableMinCheck: true
            }).fontSize;
        }
        return fontSizeForParams;
    }
    return (
        <foreignObject x={x} y={y} fontSize={fontSize} height={height} width={width} transform={transform}>
            <div>
                <div className={`text text-rich text-rich-${type}`}>
                    {isArray(text) && text.map((t, i) => (
                        <div key={i} style={i === 0 ? null : {fontSize: getFontSizeForParams()}}>
                            {
                                isObject(t) ? <i style={{fontSize: Math.min(15, getFontSizeForParams())}}
                                                 className={t.icon}/> : null
                            }
                            {
                                isObject(t) ? t.text : t
                            }
                        </div>
                    ))}
                </div>
            </div>
        </foreignObject>
    )

}

export default React.memo(Text);