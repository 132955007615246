import salesDB from "../database/salesDB";

export function getSales(FarmID) {
    return function (dispatch) {
        dispatch({
            type: "GET_SALES",
            payload: salesDB.getSales(FarmID),
            meta: {
                DtaModTime: salesDB.getModificationTime()?.DtaModTime || 0
            }
        });
    };
}
