import React from "react";
import "./_view-container.scss"
import PropTypes from "prop-types";
import _ from "lodash";

export default class ViewContainer extends React.Component {

    getClassName() {
        const {addPaddingForButtons, addHeight} = this.props;
        let className = "view-container";
        let addPadding = _.isFunction(addPaddingForButtons) ? !!addPaddingForButtons() : !!addPaddingForButtons;
        if (addPadding) className += " padding-for-buttons";
        if (addHeight) className += " min-vh-100";
        return className;
    }

    render() {
        const {children} = this.props;
        return (
            <div className={this.getClassName()}>
                {children}
            </div>
        );
    }

}

ViewContainer.propTypes = {
    addPaddingForButtons: PropTypes.bool
};

ViewContainer.defaultProps = {
    addPaddingForButtons: false
};