const initialValue = {
    selected: {},
    expanded: {}
};

export default function mainTabReducer(state = initialValue, action) {
    switch (action.type) {
        case "MAIN_TAB_TOGGLE": {
            const {key, id, type} = action.payload;
            const newValue = {...state[type] || {}};
            if (!newValue[key]) newValue[key] = {};
            newValue[key] = {...newValue[key]}
            if (newValue[key].hasOwnProperty(id)) {
                delete newValue[key][id];
            } else {
                newValue[key][id] = true;
            }
            return {...state, [type]: newValue};
        }
        case "MAIN_TAB_SET_IDS": {
            const {key, ids, select, type} = action.payload;
            const newValue = {...state[type] || {}};
            if (!newValue[key]) newValue[key] = {};
            newValue[key] = {...newValue[key]}
            ids.forEach(id => {
                if (select) {
                    newValue[key][id] = true;
                } else {
                    delete newValue[key][id];
                }
            })
            return {...state, [type]: newValue};
        }

        case "MAIN_TAB_CLEAR": {
            if(!action.payload) return initialValue;
            const {key} = action.payload;
            const newState = {...state};
            Object.keys(newState).forEach(mainKey => {
                const newValue = {...state[mainKey] || {}};
                delete newValue[key];
                newState[mainKey] = newValue;
            })
            return {...newState};
        }

        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED":
            return initialValue;
        default:
            return state
    }
}
