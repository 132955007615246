import React from "react";
import {OverlayTrigger, Tooltip as TP} from "react-bootstrap";
import PropTypes from "prop-types"
import {myID} from "../../../libs/generateID";
import "./_tooltip.scss"
import {isNil} from "lodash";

// const popperConfig = {
//     modifiers: [
//         {
//             name: 'preventOverflow',
//             options: {
//                 rootBoundary: 'document',
//             },
//         },
//     ],
// };

export default class Tooltip extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id || `tp${myID()}`
        }
    }


    render() {
        const {children, placement, tooltipContent, type, show} = this.props;
        const {id} = this.state;
        if (isNil(tooltipContent)) return children;
        return (
            <OverlayTrigger
                // popperConfig={popperConfig}
                placement={placement}
                // rootClose={true}
                show={show}
                container={document.getElementById("root")}
                overlay={
                    <TP
                        id={id} className={type}>
                        {tooltipContent}
                    </TP>
                }
            >
                {children}
            </OverlayTrigger>
        );
    }

}

Tooltip.propTypes = {
    tooltipContent: PropTypes.node.isRequired,
    id: PropTypes.string,
    placement: PropTypes.oneOf(["auto-start", "auto", "auto-end", "top-start", "top", "top-end", "right-start", "right", "right-end", "bottom-end", "bottom", "bottom-start", "left-end", "left", "left-start"]),
    type: PropTypes.oneOf(["primary", "secondary", "success", "info", "warning", "error", ""])
};

Tooltip.defaultProps = {
    placement: "top"
};
