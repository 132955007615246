export const REGON_ERRORS = {
    WRONG_LENGTH: 0,
    WRONG_REGON: 1,
};

/**
 * Funckja normalizująca input NIPu
 * @param value
 * @returns {*}
 */
export function normalizeNIP(value) {
    if (!value) {
        return value;
    }

    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 3) {
        return onlyNums;
    }
    if (onlyNums.length > 3 && onlyNums.length <= 5) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}`;
    }
    if (onlyNums.length > 5 && onlyNums.length <= 7) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(
            3,
            5
        )}-${onlyNums.slice(5, 7)}`;
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
        5,
        7
    )}-${onlyNums.slice(7, 10)}`;
}
export function normalizeFarmNumber(value) {
    if (!value) {
        return value;
    }

    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length > 9) {
        return `${onlyNums.slice(0, 9)}-${onlyNums.slice(9, 12)}`;
    } else return onlyNums;
}
export function checkNip(value) {
    let weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    value = value.replace(/-/g, "");
    let splitted = value.split("");
    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += weights[i] * +splitted[i];
    }
    return sum % 11 === +splitted[9];
}

export function normalizeRFID(value) {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length > 15) {
        return onlyNums.slice(0, 15);
    }
    return onlyNums;
}

export function normalizePhoneNumber(value) {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, "");
    return "+" + onlyNums;
}

export function checkREGON(value) {
    if (value.length === 9 || value.length === 14) {
        let weights =
            value.length === 9
                ? [8, 9, 2, 3, 4, 5, 6, 7]
                : [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
        let sum = 0;
        for (let i = 0; i < weights.length; i++) {
            let weight = weights[i];
            sum += weight * +value.charAt(i);
        }
        let mod = sum % 11;
        if (mod === 10) mod = 0;
        if (+value.charAt(value.length - 1) !== mod)
            return REGON_ERRORS.WRONG_REGON;
        return null;
    }
    return REGON_ERRORS.WRONG_LENGTH;
}

export function normalizePostCode(value) {
    if (!value) return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 1) return onlyNums;
    return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 5)}`;
}
