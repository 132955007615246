import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { getGroupTimespan, groupHasPorker } from "../../../selectors/animalDocumentsSelectors";
import { getFatteningDaysForPiglet, getFatteningDaysForPorker } from "../../../selectors/settingsSelector";
import { localMomentToUTC } from "../../../utils/DateTimeUtils";
import { fixAnimalTimespan } from "./charts/utils";
import moment from "moment";

/*
    zwraca przedział czasu, który ma być wyświetlany na wykresie dla grupy tuczników/warchlaków (utc przesunięty do początku dnia)
 */
const useDomain = () => {
    const props = useRef({ getDeletedAnimals: true })
    // najstarszy czas urodzenia świni w grupie (czas lokalny)
    // const birthTime = useSelector(getGroupBirthTime);
    // czas końca grupy technologicznej (czas w utc)
    const { end, start, groupEnded } = useSelector(state => getGroupTimespan(state, props.current));

    // ilość dni tuczenia
    const pigletsFatteningDays = useSelector(getFatteningDaysForPiglet);
    const porkerFatteningDays = useSelector(getFatteningDaysForPorker);
    const hasPorker = useSelector((state) => groupHasPorker(state,props.current));

    return useMemo(() => {
        const startUTC = localMomentToUTC(start);
        const endUTC = localMomentToUTC(end);
        // czas urodzenia,               czas do kiedy mamy wyrysowany wykres wag,          czas końca grupy
        let endTime = groupEnded ? endUTC : startUTC.clone().startOf("day").add(hasPorker ? porkerFatteningDays : pigletsFatteningDays, "days");
        if (!groupEnded && endTime.isBefore(moment.utc(), "day")) {
            endTime = moment.utc().startOf("day");
        }
        return fixAnimalTimespan([+startUTC, +endTime])
    }, [pigletsFatteningDays, porkerFatteningDays, end, start, groupEnded, hasPorker]);
}

export default useDomain;