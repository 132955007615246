import {memoize} from "lodash";

export const sizes = [
    {
        className: "xs",
        scale: [0.5]
    },
    {
        className: "sm",
        scale: [0.7]
    },
    {
        className: "md",
        scale: [1]
    }
];

const PIG_CUSTOM_COLOR = {
    INFO: "info",
    SUCCESS: "success"
}

export const PIG_COLOR = {
    WARN: "warning",
    ERROR: "error",
    ...PIG_CUSTOM_COLOR
}

export const customPigColors = Object.values(PIG_CUSTOM_COLOR);

/**
 * this applies parent transforms onto children
 * @param orientation
 * @param down
 * @param right
 * @param width
 * @param height
 * @return {{viewBox: string, angle: number, toAbsolute: (function(*, *): string[])}}
 * @private
 */
export function getRotatedData(orientation, down, right, width, height) {
    let matchRotX = 0;
    let matchRotY = 0;
    let angle;
    let w = width || 0;
    let h = height || 0;
    switch (orientation) {
        case "horizontal": {
            if (right) {
                angle = 90;
                matchRotX = 0;
                matchRotY = -height;
            } else {
                angle = 270;
                matchRotX = -width;
                matchRotY = 0;
            }
            [w, h] = [h, w];
            break;
        }
        case "vertical":
        default: {
            if (down) {
                angle = 180;
                matchRotX = -width;
                matchRotY = -height;
            } else {
                angle = 0;
            }
        }
    }
    return {
        angle,
        toAbsolute: (x, y) => {
            return [`${matchRotX}+${x}`, `${matchRotY}+${y}`]
        },
        viewBox: `0 0 ${w} ${h}`
    }
}

export function transformProps(props) {
    const keys = Object.keys(props);
    const newProps = {...props};
    for (let key of keys) {
        if (key.startsWith("animal")) {
            newProps.animal = true;
            newProps.scale = sizes.find(({className}) => key.endsWith(className))?.scale || [1]
        }
    }
    return newProps;
}

export const validateClassName = memoize((key) => {
    const pigColors = Object.values(PIG_COLOR);
    return pigColors.includes(key) || key.startsWith("feed")
})