import {useEffect, useState} from "react";

export default function useThumbnail(file) {
    const [thumbnailData, setThumbnailData] = useState();

    useEffect(() => {
        if (!file.type.startsWith("image/")) {
            setThumbnailData(null);
            return;
        }

        async function readFile() {
            const reader = new FileReader();
            reader.addEventListener("load", event => {
                setThumbnailData(event.target.result);
            });
            reader.readAsDataURL(file);
        }
        readFile();
    }, [file]);

    return thumbnailData;
};
