import React from "react";
import Tooltip from "../../basics/tooltip/Tooltip";
import {checkIfUserIsFarmAdmin, checkIfUserIsOwner, checkIfUserIsService} from "../../../utils/NewRolesUtils";
import {compose} from "redux";
import {connect} from "react-redux";
import {makeGetEmployeeList, makeGetServiceList} from "../../../selectors/userSelector";
import {withTranslation} from "react-i18next";
import {NameSpaces} from "@wesstron/utils/Api/constants/nameSpaces";

function makeMapStateToProps() {
    const getEmployeeList = makeGetEmployeeList();
    const getServiceList = makeGetServiceList();
    return state => ({
        employees: getEmployeeList(state),
        service: getServiceList(state)
    })
}

class CycleRenderer extends React.Component {

    isService = checkIfUserIsService();

    employeesValueFormatter = value => {
        if (typeof value === "object") {
            return value.Name;
        }
        const {employees, service, t} = this.props;
        let serviceUser = service.find(item => item.LocalUserID === value) || null;
        if (serviceUser) {
            if (this.isService) return serviceUser.Name;
            return t("newSettings.users.changeRolesView.userTypes.V");
        }
        let employee = employees.find(item => item.LocalUserID === value);
        if (employee) {
            return `${employee.firstname || ""} ${employee.surname || ""}`
        }
    }

    getOperators = (operators, isAdvanced) => {
        let names = [];
        if (Array.isArray(operators)) {
            names = isAdvanced ? [...new Set(operators)] : [operators[0]];
            return names.map(o => this.employeesValueFormatter(o));
        }
        return "";
    };

    getClassName() {
        const {className, isInterrupted} = this.props;
        let name = "cycle-grid-content";
        if (className) name += ` ${className}`;
        if (isInterrupted) name += ` is-interrupted`;
        return name;
    }

    render() {
        const {children, comment, operators, isAdvanced, type, onDoubleClick, deletionMode, expected} = this.props;
        const _operators = this.isService || checkIfUserIsFarmAdmin() || checkIfUserIsOwner() ? this.getOperators(operators, isAdvanced) : "";
        return (
            <div onDoubleClick={onDoubleClick} className={this.getClassName()}>
                {children}
                {
                    (comment && !deletionMode) &&
                    <Tooltip tooltipContent={comment} type={type || "error"}>
                        <div className={`box ${type || "error"}`}/>
                    </Tooltip>
                }
                {
                    ((_operators || expected) && !deletionMode) &&
                    <Tooltip tooltipContent={
                        <div>
                            {
                                (_operators || []).filter(o => o).map((o, index) => <div key={index}>{o}</div>)
                            }
                            {expected}
                        </div>
                    } type={"info"}>
                        <div className={`box info`}/>
                    </Tooltip>
                }
            </div>
        );
    }

}

export default compose(
    connect(makeMapStateToProps),
    withTranslation(NameSpaces.TRANSLATION)
)(CycleRenderer)
