const initialValue = {
    index: 0,
    section: "feeding",
    translationKey: "Edycja harmonogramu karmienia",
    loading: false,
    url: null,
    subtitles: [],
    playing: false
};

export default function helpReducer(state = initialValue, action) {
    switch (action.type) {
        case "SET_SELECTED_HELP_VIDEO":
            return {...state, ...action.payload};
        case "GET_HELP_VIDEO_PENDING":
            return {...state, loading: true};
        case "GET_HELP_VIDEO_REJECTED":
            return {...state, loading: false, url: null, subtitles: []};
        case "GET_HELP_VIDEO_FULFILLED":
            return {...state, loading: false, url: action.payload.video, subtitles: action.payload.subtitles};
        case "SET_HELP_VIDEO_PLAYING": {
            return {...state, playing: action.payload};
        }
        case "CHANGE_FARM":
        case "USER_LOGOUT_FULFILLED": {
            return initialValue;
        }
        default:
            return state
    }
}
