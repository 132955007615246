import { NameSpaces } from "@wesstron/utils/Api/constants/nameSpaces";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { show } from "redux-modal";
import { EventTypes } from "../../../constans/eventTypes";
import {getAnimalEvents, getSelectedAnimalForDocuments} from "../../../selectors/animalDocumentsSelectors";
import { isOnline } from "../../../selectors/onlineSelector";
import { isAnimalRemoved } from "../../../utils/AnimalsUtils";
import ALink from "../../basics/alink/ALink";
import InfoBox from "../../basics/info-box/InfoBox";
import HeatList from "../../grids/animal-documents/HeatList";
import SowCardGridMinimal from "../../grids/animal-documents/SowCardGridMinimal";
import SowCardGridMobile from "../../grids/animal-documents/SowCardGridMobile";
import AddMissingSowEventModal, {
    ModalName as AddMissingSowEventModalName
} from "../../modals-new/add-missing-sow-event-modal/AddMissingSowEventModal";
import AnimalDocumentsComments from "../comments/AnimalDocumentsComments";
import PrintableCard from "../print/PrintableCard";
import SowCardTypeChanger from "../sow-card-type-changer/SowCardTypeChanger";
import SowResults from "../sow-results/SowResults";
import SowTreatment from "../sow-treatment/SowTreatment";
import TransferGrid from "../transfer/TransferGrid";
import FatMeasurementSingleChart from '../fatMeasurement/FatMeasurementSingleChart'
import WeightingSingleChart from "../weighting/WeightingSingleChart";
import AnimalModificationList from "../animal-modification-list/AnimalModificationList";
import AnimalParameters from "../parameters/AnimalParameters";
import { checkIfUserIsService } from "../../../utils/NewRolesUtils";

function mapStateToProps(state) {
    return {
        animalEvents: getAnimalEvents(state),
        animal: getSelectedAnimalForDocuments(state),
        online: isOnline(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({ show }, dispatch)
    };
}

export const SowCardViewTypes = {
    CYCLES: "cycles",
    LIST: "list"
};

class SowCard extends Component {

    state = {
        viewType: SowCardViewTypes.CYCLES,
        deletionMode: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { online } = this.props;
        const { deletionMode } = this.state;
        if (prevProps.online && !online && deletionMode) {
            this.setState({
                deletionMode: false
            });
        }
    }

    onDeletionModeButtonClick = () => {
        this.setState((state) => ({
            deletionMode: !state.deletionMode
        }));
    };

    onAddMissingEventClick = () => {
        const { show, animal } = this.props;
        show(AddMissingSowEventModalName, { animal });
    };

    shouldShowInfoBox = () => {
        const { animalEvents } = this.props;
        return !animalEvents.some((e) => [EventTypes.SOW_CYCLES, EventTypes.SEPARATION, EventTypes.INSEMINATION].includes(e.EvCode));
    };

    onCardTypeChange = (value) => {
        this.setState({
            viewType: value
        });
    };

    render() {
        const { viewType, deletionMode } = this.state;
        const { t, animal, mobile } = this.props;
        return (
            <PrintableCard>
                {({ print, printing }) => (
                    <>
                        {
                            this.shouldShowInfoBox() &&
                            <InfoBox boxColor="warning">
                                <i className="fas fa-exclamation-triangle ms-2 me-2" />
                                {t("sowCard.missingEventInfo")}
                                <ALink bolded={true}
                                    onClick={this.onAddMissingEventClick}>{t("sowCard.fillMissingData").toUpperCase()}</ALink>
                            </InfoBox>
                        }
                        {
                            mobile &&
                            <SowCardTypeChanger viewType={viewType} deletionMode={deletionMode} print={print}
                                onChange={this.onCardTypeChange} />
                        }
                        {
                            (mobile && viewType === SowCardViewTypes.CYCLES || !mobile) &&
                            <SowCardGridMinimal print={print} printing={printing} deletionMode={deletionMode}
                                onDeletionModeChange={this.onDeletionModeButtonClick} />
                        }
                        {
                            (mobile && viewType === SowCardViewTypes.LIST) &&
                            <SowCardGridMobile print={print} printing={printing} />
                        }
                        {
                            (!isAnimalRemoved(animal)) &&
                            <SowResults print={print} printing={printing} />
                        }
                        <WeightingSingleChart />
                        <FatMeasurementSingleChart />
                        <AnimalDocumentsComments />
                        <SowTreatment />
                        <HeatList />
                        <TransferGrid />
                        <AnimalModificationList />
                        {
                            checkIfUserIsService() &&
                            <AnimalParameters />
                        }
                        <AddMissingSowEventModal />
                    </>
                )}
            </PrintableCard>
        );
    }
}

export default compose(
    withTranslation(NameSpaces.TRANSLATION),
    connect(mapStateToProps, mapDispatchToProps)
)(SowCard);
