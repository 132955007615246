import React, {useCallback, useMemo} from "react";
import {isFiniteNumber} from "../../../utils/MathUtils";
import {findIndex, isEqual} from "lodash";
import {transformOptions} from "./utils";
import SelectItem from "./SelectItem";

const OptionListMobile = ({onChange, value, disabled, options}) => {

    const handleChange = useCallback((e) => {
        const index = +e.target.value;
        if (!isFiniteNumber(index)) return;
        const opt = options[index];
        onChange(opt)
    }, [options, onChange]);

    const index = useMemo(() => {
        return findIndex(options, (item) => isEqual(value, item.value));
    }, [options, value]);

    const transformed = useMemo(() => {
        return transformOptions(options, false, value, null, null, {onSelect: onChange})
    }, [options, value, onChange]);

    return (
        <select
            onChange={handleChange}
            value={index}
            disabled={disabled}>
            <option/>
            {
                transformed.map((o) => (
                    <SelectItem {...o} mobile={true} key={o.key}/>
                ))
            }
        </select>
    )

}

export default OptionListMobile;