import React, {Component} from 'react';
import moment from "moment";

class TwitterTime extends Component {
    render() {
        const {children, title} = this.props;
        const time = moment(title, moment.ISO_8601);
        const isValid = time.isValid();
        return (
            <time title={isValid ? title : ""}>
                {isValid ? moment().diff(time, "hour") <= 24 ? children : time.format("dddd Do MMMM HH:mm") : ""}
            </time>
        );
    }
}

export default TwitterTime;
