import React from "react";

const DataList = (props) => {
    const {dataList, id} = props;
    return (
        <datalist id={id}>
            {
                dataList.map((value, i) => (
                    <option value={value} key={i}/>
                ))
            }
        </datalist>
    )

};


export default React.memo(DataList);