import Animation from "./Animation";
import {pick} from "lodash";

export const makeAnimations = (setState) => {
    const animations = new Animation(setState);
    // used when user clicks on map element
    animations.add("NORMAL", [
        [(cam, dst) => ({...dst})],
        [() => ({animate: "none"}), 1000]
    ]);
    // used on initial farm zoom (no animation)
    animations.add("INSTANT", [
        [(cam, dst) => ({...dst, animate: "none"})]
    ]);
    // used (with `INSTANT`) on inventory mini map to show a whole map to the user and then zoom into element with a delay
    animations.add("DELAYED_SMOOTH_ZOOM", [
        [(cam, dst) => ({zoom: 0.2, ...pick(dst, ["x", "y"]), animate: "none"}), 0],
        [() => ({animate: "none"}), 1000],
        [(cam, dst) => ({...pick(dst, ["x", "y"]), animate: "smooth"}), 1000],
        [(cam, dst) => ({...pick(dst, ["zoom"]), animate: "smooth"}), 1000],
        [() => ({animate: "none"}), 1000]
    ]);
    // used on a search bar
    animations.add("ZOOM_AND_MOVE", [
        [(cam) => ({zoom: Math.max(1, cam.zoom)}), 1000],
        [(cam, dst) => pick(dst, ["x", "y"]), 1000],
        [(cam, dst) => pick(dst, ["zoom"]), 1000],
        [() => ({animate: "none"}), 1000]
    ]);
    return animations;
}